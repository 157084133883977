import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BrandIdentityApproachStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const BrandIdentityApproach = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <BrandIdentityApproachStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_37')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_38')}
          </h1>
        </div>
      </div>
      <div className="cards">
        <div className="cards-item" />
        <div className="cards-item">
          <p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'brands_identity_11',
              )}
            </b>
          </p>
        </div>
        <div className="cards-item" />
        <div className="cards-item">
          <p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'brands_identity_12',
              )}
            </b>
          </p>
        </div>
        <div className="cards-item" />
        <div className="cards-item">
          <p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'brands_identity_13',
              )}
            </b>
          </p>
        </div>
      </div>
      <div className="btn-container">
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#000"
          borderColor="#000"
          borderOnHover="#000"
          background="linear-gradient(225deg, #FFBD14 0%, #DB9000 100%)"
          hoverBackground="#000"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'usage_attitude_11',
          )}
        />
      </div>
    </BrandIdentityApproachStyles>
  );
};
