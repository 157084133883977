import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesBonusesStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import bonuses from '../../../assets/images/opportunities/bonuses.png';
import bonuses1 from '../../../assets/images/opportunities/bonuses1.png';
import bonuses2 from '../../../assets/images/opportunities/bonuses2.png';
import bonuses3 from '../../../assets/images/opportunities/bonuses3.png';
import bonuses4 from '../../../assets/images/opportunities/bonuses4.png';
import bonuses5 from '../../../assets/images/opportunities/bonuses5.png';
import bonuses6 from '../../../assets/images/opportunities/bonuses6.png';

export const OpportunitiesBonuses = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesBonusesStyles>
      <div className="container">
        <h1>
          {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_86')}
        </h1>
        <div className="bonuses-wrapper">
          <img src={bonuses} loading="lazy" className="bonuses-bg" alt="" />

          <div className="bonuses-item">
            <img src={bonuses1} loading="lazy" alt="" />
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_87',
              )}
            </p>
          </div>
          <div className="bonuses-item">
            <img src={bonuses2} loading="lazy" alt="" />
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_88',
              )}
            </p>
          </div>
          <div className="bonuses-item">
            <img src={bonuses3} loading="lazy" alt="" />
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_92',
              )}
            </p>
          </div>
          <div className="bonuses-item">
            <img src={bonuses4} loading="lazy" alt="" />
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_90',
              )}
            </p>
          </div>
          <div className="bonuses-item">
            <img src={bonuses5} loading="lazy" alt="" />
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_91',
              )}
            </p>
          </div>
          <div className="bonuses-item">
            <img src={bonuses6} loading="lazy" alt="" />
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_89',
              )}
            </p>
          </div>
        </div>
      </div>
    </OpportunitiesBonusesStyles>
  );
};
