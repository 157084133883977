import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import bannerBgDesktop from '../../assets/images/uxBannerDesktop.jpg';

interface SeoPops {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogImage?: string;
  ogDescription?: string;
  noIndexPage?: boolean;
  keywords?: string;
  noFollow?: boolean;
  lang?: string;
  canonicalHref?: string;
}

function Seo({
  title, description, ogTitle, ogDescription, noFollow = false, noIndexPage = false, keywords, lang = 'en', canonicalHref, ogImage,
}: SeoPops) {
  return (
    <Helmet>
      <title>
        {title
        || '4Service: Mystery shopping provider, Market research agency'}
      </title>
      <meta
        name="description"
        content={
          description
          || 'Marketing research for B2B/B2C: Mystery shopper, Retail audit, NPS, staff motivation and service quality check.'
        }
      />

      <meta
        property="og:title"
        content={
          ogTitle
          || title
          || '4Service: Mystery shopping provider, Market research agency'
        }
      />
      <meta
        property="og:description"
        content={
          ogDescription
          || description
          || 'Marketing research for B2B/B2C: Mystery shopper, Retail audit, NPS, staff motivation and service quality check.'
        }
      />
      {/* Specify og:image and og:url if needed */}
      {/* <meta property="og:image" content={ogImage || bannerBgDesktop} /> */}
      {canonicalHref && (
        <meta property="og:url" content={canonicalHref} />
      )}

      <meta
        property="twitter:title"
        content={
          ogTitle
          || title
          || '4Service: Mystery shopping provider, Market research agency'
        }
      />
      <meta
        property="twitter:description"
        content={
          ogDescription
          || description
          || 'Marketing research for B2B/B2C: Mystery shopper, Retail audit, NPS, staff motivation and service quality check.'
        }
      />
      {/* <meta property="twitter:image" content={ogImage || bannerBgDesktop} /> */}

      <meta name="robots" content={`${noIndexPage ? 'noindex' : 'index'}, ${noFollow ? 'nofollow' : 'follow'}`} />

      {lang && (
        <>
          <html lang={lang} />
          <meta name="lang" content={lang} />
        </>
      )}

      {keywords && (
        <meta name="keywords" content={keywords} />
      )}

      {canonicalHref && (
        <link rel="canonical" href={canonicalHref} />
      )}
    </Helmet>
  );
}

export default Seo;
