import React, { ReactNode } from 'react';
import { NavigationButtonsStyles } from './NavigationButtonsStyles';
import ArrowRightButtonIcon from '../../assets/icons/ArrowRightButtonIcon';
import { aboutUsHistoryData } from '../../constants';

interface Props {
  onClickToPrevButton: () => void;
  onClickToNextButton: () => void;
  disabledPrev?: boolean;
  disabledNext?: boolean;
  buttonsIconsColor?: string;
  borderColor?: string;
  customButton?: (
    onClickButton: () => void,
    disabledPrev: boolean | undefined
  ) => ReactNode;
}

function NavigationButtons({
  onClickToPrevButton,
  onClickToNextButton,
  disabledPrev,
  disabledNext,
  buttonsIconsColor,
  borderColor,
  customButton,
}: Props) {
  return (
    <NavigationButtonsStyles className="buttonsWrapper">
      {customButton ? (
        <>
          {customButton(onClickToPrevButton, disabledPrev)}
          {customButton(onClickToNextButton, disabledNext)}
        </>
      ) : (
        <>
          <button
            type="button"
            style={borderColor ? { borderColor } : {}}
            onClick={onClickToPrevButton}
            disabled={disabledPrev}
            className={disabledPrev ? 'disabled' : ''}
            aria-label="btn"
          >
            <ArrowRightButtonIcon color={buttonsIconsColor || '#FFF'} />
          </button>
          <button
            type="button"
            style={borderColor ? { borderColor } : {}}
            onClick={onClickToNextButton}
            disabled={disabledNext}
            className={disabledNext ? 'disabled' : ''}
            aria-label="btn"
          >
            <ArrowRightButtonIcon color={buttonsIconsColor || '#FFF'} />
          </button>
        </>
      )}
    </NavigationButtonsStyles>
  );
}

export default NavigationButtons;
