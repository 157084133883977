import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { UsageAttitudeExperienceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const UsageAttitudeExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <UsageAttitudeExperienceStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_9')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_10')}
          </h1>
          <div className="btn-container">
            <ButtonWithArrowOnHover
              onClick={() => {
                const elem = document.getElementById('form');
                elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
              }}
              arrowColor="#fff"
              textColor="#000"
              borderColor="#000"
              borderOnHover="#000"
              background="linear-gradient(135.55deg, #5498FF 0%, #7E6DE4 100.95%)"
              hoverBackground="#000"
              hoverTextColor="#fff"
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_11',
              )}
            />
          </div>
        </div>
      </div>
    </UsageAttitudeExperienceStyles>
  );
};
