import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { UsageAttitudeSolutionsStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import solutions1 from '../../../assets/images/usageAttitude/solutions1.png';
import solutions2 from '../../../assets/images/usageAttitude/solutions2.png';

export const UsageAttitudeSolutions = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <UsageAttitudeSolutionsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_27')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_28')}
          </h1>
        </div>
      </div>
      <div className="cards">
        <div className="cards-item">
          <div className="cards-text">
            <p>
              <b>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'usage_attitude_29',
                )}
              </b>
            </p>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_30',
              )}
            </p>
          </div>
        </div>
        <div className="cards-item">
          <div className="cards-text">
            <p>
              <b>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'usage_attitude_31',
                )}
              </b>
            </p>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_32',
              )}
            </p>
          </div>
          <img src={solutions1} alt="" />
        </div>
        <div className="cards-item">
          <div className="cards-text">
            <p>
              <b>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'usage_attitude_33',
                )}
              </b>
            </p>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_34',
              )}
            </p>
          </div>
          <img src={solutions2} alt="" />
        </div>
        <div className="cards-item">
          <div className="cards-text">
            <p>
              <b>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'usage_attitude_35',
                )}
              </b>
            </p>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_36',
              )}
            </p>
          </div>
        </div>
      </div>
    </UsageAttitudeSolutionsStyles>
  );
};
