import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ReactComponent as Clients1 } from '../../../assets/images/sociologicalResearch/clients1.svg';
import { ReactComponent as Clients2 } from '../../../assets/images/sociologicalResearch/clients2.svg';
import { ReactComponent as Clients3 } from '../../../assets/images/sociologicalResearch/clients3.svg';
import { SociologicalResearchClientsStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const SociologicalResearchClients = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchClientsStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_25')}</h1>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_26')}</p>
        </div>
        <div className="cards">
          <div className="cards__item">
            <Clients1 />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_27')}</p>
          </div>
          <div className="cards__item">
            <Clients2 />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_28')}</p>
          </div>
          <div className="cards__item">
            <Clients3 />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_29')}</p>
          </div>
        </div>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#F05A25"
          borderColor="#F05A25"
          borderOnHover="#F05A25"
          background="transparent"
          className="btn"
          hoverBackground="#F05A25"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'sociological_research_3',
          )}
        />
      </div>
    </SociologicalResearchClientsStyles>
  );
};
