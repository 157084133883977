import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import { UsageAttitudeSliderStyles } from './styles';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { sliderData } from './utils';
import { SwiperSlide } from 'swiper/react';

export const UsageAttitudeSlider = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <UsageAttitudeSliderStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_12')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_45')}
          </h1>
        </div>
      </div>
      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          centeredSlides
          loop={false}
          initialSlide={window.innerWidth > 1500 ? 1 : 0}
          offsetLeft={
            window.innerWidth > 1400 ? (window.innerWidth - 1400) / 2 : 10
          }
          disabledNext
          disabledPrev
          disabledPrevIfSlideActive={window.innerWidth > 1500 ? 1 : 0}
          buttonBorderColor="#5498FF"
          buttonsIconsColor="#000"
        >
          {sliderData(interfaceLanguage).map((item, index) => (
            <SwiperSlide className="slide" key={item.name}>
              <div className="slide-img">
                <img src={item.img} alt={item.name} />
              </div>
              <div className="slide-text">
                <h5>{item.name}</h5>
                <p>{item.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </UsageAttitudeSliderStyles>
  );
};
