import styled from 'styled-components';

export const SociologicalResearchControlStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .texts {
    width: 100%;
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    p {
      color: #000;
      font-size: 28px;
      font-weight: 400;
      line-height: 125%;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .cards {
    display: flex;
    gap: 30px;
    justify-content: space-around;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
    }

    &__item {
      box-sizing: border-box;
      max-width: 370px;
      width: calc(33% - 15px);
      height: 240px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 991px) {
        width: calc(50% - 15px);
      }

      @media (max-width: 768px) {
        width: 100%;
        align-items: flex-start;
        height: auto;
      }

      h1 {
        position: relative;
        font-weight: 700;
        font-size: 96px;
        line-height: 115%;
        text-align: center;
        background: linear-gradient(180deg, #f05a25 0%, #faaf3b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (max-width: 768px) {
          font-size: 48px;
        }

        &::before {
          position: absolute;
          content: '';
          border-radius: 50%;
          transform: translate(-50%);
          top: -10px;
          width: 12px;
          left: 50%;
          height: 12px;
          background: #656565;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 28px;
        line-height: 125%;
        text-align: center;
        color: #000000;

        @media (max-width: 768px) {
          text-align: start;
          font-size: 18px;
        }
      }
    }
  }
`;
