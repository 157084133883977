import styled from 'styled-components';

export const CaseItemStyles = styled.div`
  border: 2px solid #0000ff;
  border-radius: 10px;

  overflow: hidden;

  a {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &:hover {
    .textContainer {
      .hashtagAndButtonWrapper {
        .button {
          background: rgba(0, 0, 255, 1);
        }
      }
    }
  }

  .headContainer {
    min-height: 270px;
    position: relative;

    @media (max-width: 1000px) {
      min-height: 155px;
    }

    @media (max-width: 575px) {
      min-height: 171px;
    }

    .captions {
      padding: 28px 30px;
      position: relative;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      @media (max-width: 1000px) {
        padding: 16px 17px;
      }

      @media (max-width: 575px) {
        padding: 19px 20px;
      }

      .caption {
        padding: 5px 21px;
        font-weight: 700;
        font-size: 18px;
        line-height: 115%;
        text-align: center;
        color: #ffffff;
        background: #eb2e4b;
        border-radius: 50px;
        white-space: pre-wrap;

        @media (max-width: 1000px) {
          padding: 3px 12px;
          font-size: 10.3792px;
          line-height: 115%;
        }

        @media (max-width: 575px) {
          padding: 5px 10px;
        }
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .textContainer {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    padding: 35px 45px 50px;

    @media (max-width: 1000px) {
      padding: 20px 25px 29px;
    }

    @media (max-width: 575px) {
      padding: 30px 30px 35px 30px;
    }

    p {
      margin-bottom: 24px;

      font-weight: 700;
      font-size: 30px;
      line-height: 115%;

      color: #fff;

      @media (max-width: 1000px) {
        font-size: 16px;
      }

      @media (max-width: 575px) {
        font-size: 14px;
      }
    }

    .hashtagAndButtonWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      .hashtagsContainer {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;

        span {
          font-weight: 600;
          font-size: 18px;
          line-height: 115%;
          color: #0000ff;
          word-break: break-word;

          @media (max-width: 1000px) {
            font-size: 8px;
          }

          @media (max-width: 700px) {
            font-size: 12px;
          }
        }
      }

      .button {
        position: relative;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 255, 0.5);
        border-radius: 100px;
        padding: 5px 21px;
        color: #fff;
        transition: 0.5s all;

        span {
          font-weight: 700;
          font-size: 13px;
          line-height: 115%;
          transform: translateX(-5px);
        }

        svg {
          position: absolute;
          right: 10px;
          transform: translateY(1px);
        }
      }
    }
  }
`;
