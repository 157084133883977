import methodology1 from '../../../assets/images/usageAttitude/methodology1.png';
import methodology2 from '../../../assets/images/usageAttitude/methodology2.png';
import methodology3 from '../../../assets/images/usageAttitude/methodology3.png';
import methodology4 from '../../../assets/images/usageAttitude/methodology4.png';
import methodology5 from '../../../assets/images/usageAttitude/methodology5.png';
import methodology6 from '../../../assets/images/usageAttitude/methodology6.png';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const sliderData = (
  lang: string,
): { name: string; description: string; img: string }[] => [
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_46'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_47'),
    img: methodology1,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_48'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_49'),
    img: methodology2,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_50'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_51'),
    img: methodology3,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_52'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_53'),
    img: methodology4,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_54'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_55'),
    img: methodology5,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_56'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_57'),
    img: methodology6,
  },
];
