import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesPartnershipStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import partnership1 from '../../../assets/images/opportunities/partnership-1.png';
import partnership2 from '../../../assets/images/opportunities/partnership-2.png';
import partnership3 from '../../../assets/images/opportunities/partnership-3.png';
import partnership4 from '../../../assets/images/opportunities/partnership-4.png';

export const OpportunitiesPartnership = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesPartnershipStyles>
      <div className="container">
        <div className="text-wrapper">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_48')}
          </h1>
          <div className="text-info">
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_49',
              )}
            </p>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_50',
              )}
            </p>
          </div>
        </div>

        <div className="circles-wrapper">
          <img
            src={partnership1}
            loading="lazy"
            className="partnership"
            alt=""
          />
          <img
            src={partnership2}
            loading="lazy"
            className="partnership"
            alt=""
          />
          <img
            src={partnership3}
            loading="lazy"
            className="partnership"
            alt=""
          />
          <img
            src={partnership4}
            loading="lazy"
            style={{ display: 'none' }}
            className="partnership"
            alt=""
          />
          <div className="partnership">
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_51',
              )}
            </p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_52',
              )}
            </b>
          </div>
          <div className="partnership">
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_51',
              )}
            </p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_53',
              )}
            </b>
          </div>
          <div className="partnership">
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_51',
              )}
            </p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_54',
              )}
            </b>
          </div>
          <div className="partnership">
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_51',
              )}
            </p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_55',
              )}
            </b>
          </div>
          <div className="partnership">
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_51',
              )}
            </p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_56',
              )}
            </b>
          </div>
          <div className="partnership">
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_51',
              )}
            </p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_57',
              )}
            </b>
          </div>
        </div>
      </div>
    </OpportunitiesPartnershipStyles>
  );
};
