import React, { CSSProperties } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import FormErrorMessage from '../../formErrorMessage/FormErrorMessage';
import { TextAreaFieldStyles } from './TextAreaFieldStyles';

interface TextAreaFieldProps {
  name: string;
  value: string | undefined;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (key: string, value: string) => void;
  error?: string | undefined;
  showError?: boolean;
  extraBlockStyles?: CSSProperties;
  label?: string;
  onClick?: () => void;
  onBlur?: () => void;
  onKeyUp?: () => void;
  onKeyDown?: (e: any) => void;
  disabled?: boolean;
  required?: boolean;
  withFlickerHoverAnim?: boolean;
  colorTheme?: 'lightTheme' | 'darkTheme' | 'greenTheme';
  className?: string;
}

export default function TextAreaField({
  name,
  placeholder,
  label,
  error,
  showError = true,
  value,
  onChange,
  onClick,
  onBlur,
  onKeyUp,
  onKeyDown,
  extraBlockStyles,
  disabled,
  required,
  withFlickerHoverAnim,
  colorTheme,
  className = '',
}: TextAreaFieldProps) {
  const { touched } = useFormikContext<any>();

  return (
    <TextAreaFieldStyles
      style={extraBlockStyles}
      className={`${className} ${colorTheme}`}
    >
      <label htmlFor={name}>
        {label && (
          <span>
            {label}
            {required && <span className="required"> *</span>}
          </span>
        )}
        <div
          className={error && touched[name] ? 'inputWrap error' : 'inputWrap'}
        >
          <div className="inputContainer">
            <textarea
              id={name}
              value={value}
              onChange={(event) => onChange(name, event.target.value)}
              onKeyUp={onKeyUp}
              onBlur={onBlur}
              placeholder={placeholder}
              disabled={disabled}
              onClick={onClick}
              onKeyDown={onKeyDown}
              className={
                withFlickerHoverAnim
                && disabled
                && typeof value === 'string'
                && value.length === 0
                  ? 'disabled animActive'
                  : disabled
                    ? 'disabled'
                    : withFlickerHoverAnim
                    && typeof value === 'string'
                    && value.length === 0
                      ? 'animActive'
                      : ''
              }
              autoComplete="off"
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="cursor" />
            {showError && (
              <div className="formErrorContainer">
                {error && <span>{error}</span>}
                {!error && touched && (
                  <ErrorMessage name={name} component={FormErrorMessage} />
                )}
              </div>
            )}
          </div>
        </div>
      </label>
    </TextAreaFieldStyles>
  );
}
