import styled from 'styled-components';

export const CustomerProfilingSegmentationSegmentationStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .item {
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-left: 4px solid #AE19A4;
    position: relative;

    @media (max-width: 991px) {
      border-left: none;
      border-top: 4px solid #AE19A4;
      padding: 25px 15px 0;
      margin-top: 25px;
    }

    h4 {
      font-weight: 600;
      font-size: 32px;
      line-height: 125%;
      color: #0F0E0C;

      @media (max-width: 768px) {
        font-size: 22px;
      }
    }

    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 125%;
      color: #0F0E0C;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 266px;
      height: 100%;
      background: radial-gradient(41.73% 55.82% at 100% 50.22%, rgba(250, 61, 194, 0.15) 0%, rgba(174, 25, 164, 0) 100%);
      top: 50%;
      transform: translate(0, -50%);
      right: 100%;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      width: 100%;
      background: radial-gradient(80% 107.02% at 50% 100%, rgba(250, 61, 194, 0.15) 0%, rgba(174, 25, 164, 0) 100%);
      height: 46px;
      left: 50%;
      transform: translate(-50%);
      bottom: 100%;

      @media (max-width: 991px) {
        display: block;
      }
    }

    &-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 25px;

      @media (max-width: 768px) {
        gap: 15px 25px;
      }
    }

    &-chips {
      box-sizing: border-box;
      background: #D9D9D9;
      border-radius: 10px;
      width: fit-content;
      padding: 10px 35px;
      border: 3px solid #AE19A4;

      font-weight: 400;
      font-size: 20px;
      line-height: 125%;
      text-align: center;
      color: #0F0E0C;

      @media (max-width: 768px) {
        font-size: 16px;
        padding: 8px 20px;
      }
    }

    &-dot {
      position: absolute;
      width: 20px;
      height: 20px;
      background: linear-gradient(180deg, #FA3DC2 0%, #AE19A4 100%);
      border-radius: 50%;
      top: 50%;
      transform: translate(0, -50%);
      left: -70px;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }
`;
