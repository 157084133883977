import React from 'react';

export const FacilityCheckAuditLine = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="71"
    height="9"
    viewBox="0 0 71 9"
    fill="none"
  >
    <circle cx="4.5" cy="4.5" r="4.5" fill="url(#paint0_linear_6585_21538)" />
    <circle cx="4.5" cy="4.5" r="4.5" fill="url(#paint1_linear_6585_21538)" />
    <path d="M8 5H71" stroke="#132653" strokeWidth="2" />
    <defs>
      <linearGradient
        id="paint0_linear_6585_21538"
        x1="0"
        y1="0"
        x2="9.49589"
        y2="0.550973"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38C806" />
        <stop offset="1" stopColor="#00FFA3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6585_21538"
        x1="8.3"
        y1="10.3696"
        x2="1.44288"
        y2="9.36207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#042A40" />
        <stop offset="1" stopColor="#9E00FF" />
      </linearGradient>
    </defs>
  </svg>
);
