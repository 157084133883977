import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesClientsStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import client1 from '../../../assets/images/opportunities/client1.png';
import client2 from '../../../assets/images/opportunities/client2.png';
import client3 from '../../../assets/images/opportunities/client3.png';
import client4 from '../../../assets/images/opportunities/client4.png';
import client5 from '../../../assets/images/opportunities/client5.png';
import client6 from '../../../assets/images/opportunities/client6.png';
import client7 from '../../../assets/images/opportunities/client7.png';
import client8 from '../../../assets/images/opportunities/client8.png';
import client9 from '../../../assets/images/opportunities/client9.png';
import client10 from '../../../assets/images/opportunities/client10.png';
import client11 from '../../../assets/images/opportunities/client11.png';
import client12 from '../../../assets/images/opportunities/client12.png';
import client13 from '../../../assets/images/opportunities/client13.png';
import client14 from '../../../assets/images/opportunities/client14.png';
import client15 from '../../../assets/images/opportunities/client15.png';
import client16 from '../../../assets/images/opportunities/client16.png';
import client17 from '../../../assets/images/opportunities/client17.png';
import client18 from '../../../assets/images/opportunities/client18.png';
import client19 from '../../../assets/images/opportunities/client19.png';
import client20 from '../../../assets/images/opportunities/client20.png';
import client21 from '../../../assets/images/opportunities/client21.png';
import client22 from '../../../assets/images/opportunities/client22.png';
import client23 from '../../../assets/images/opportunities/client23.png';
import client24 from '../../../assets/images/opportunities/client24.png';

const logos = [
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client8,
  client9,
  client10,
  client11,
  client12,
  client13,
  client14,
  client15,
  client16,
  client17,
  client18,
  client19,
  client20,
  client21,
  client22,
  client23,
  client24,
];

export const OpportunitiesClients = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesClientsStyles>
      <h1
        dangerouslySetInnerHTML={{
          __html: getTranslationsByLangOrEng(
            interfaceLanguage,
            'opportunities_27',
          ),
        }}
      />
      <div className="container">
        {logos.map((logo) => (
          <img src={logo} loading="lazy" alt="logo" />
        ))}
      </div>
    </OpportunitiesClientsStyles>
  );
};
