import styled from 'styled-components';

export const UploadFileButtonStyles = styled.div`
  position: relative;

  input {
    display: none;
  }

  .indicator {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 25px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
