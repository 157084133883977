import styled from 'styled-components';

export const CxCraftingStyles = styled.div`
  padding: 100px 0;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  & .container {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  h3 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 115%;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  .crafting-list {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .crafting-item {
      display: flex;
      align-items: center;
      gap: 120px;
      position: relative;

      @media (max-width: 768px) {
        gap: 20px;
      }

      svg {
        flex-shrink: 0;
      }

      &:not(:last-child)::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: #fff;
        bottom: -20px;
      }

      .crafting-text {
        color: #fff;
        font-size: 40px;
        font-weight: 500;
        line-height: 115%;

        @media (max-width: 768px) {
          font-size: 22px;
        }
      }
    }
  }
`;
