import styled from 'styled-components';
import banner from '../../assets/images/mol/baner.png';

export const MolCongratulationsStyles = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.9));
  padding-bottom: 100px;
  .container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    width: 100%;
  }

  .table-wrapper {
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    overflow: scroll;
    width: 100%;
  }

  .banner {
    position: relative;
    height: 700px;
    background-image: url(${banner});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @media screen and (max-width: 991px) {
      background-size: cover;
    }
  }

  h1 {
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
  }
  table {
    width: 100%;
    table-layout: fixed;
    min-width: 1100px;
    border-radius: 10px;
  }
  .tbl-header {
    border-radius: 10px;
    position: relative;
    margin-bottom: 1px;

    table {
      background: linear-gradient(
        to right,
        rgba(37, 196, 129, 0.6),
        rgba(37, 183, 196, 0.6)
      );
    }
  }
  .tbl-content {
    margin-top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;

    table {
      background: linear-gradient(to right, #25c481, #25b7c4);
      min-height: 300px;
    }
  }
  th {
    padding: 20px 15px;
    text-align: left;
    font-weight: 700;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
  }
  td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);

    a {
      color: #25b7c4;
      background: #fff;
      border-radius: 5px;
      padding: 5px 10px;
    }
  }

  @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
  body {
    background: -webkit-linear-gradient(left, #25c481, #25b7c4);
    background: linear-gradient(to right, #25c481, #25b7c4);
    font-family: 'Roboto', sans-serif;
  }
  section {
    margin: 50px;
  }

  .made-with-love {
    margin-top: 40px;
    padding: 10px;
    clear: left;
    text-align: center;
    font-size: 10px;
    font-family: arial;
    color: #fff;
  }
  .made-with-love i {
    font-style: normal;
    color: #f50057;
    font-size: 14px;
    position: relative;
    top: 2px;
  }
  .made-with-love a {
    color: #fff;
    text-decoration: none;
  }
  .made-with-love a:hover {
    text-decoration: underline;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .custom-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .custom-select-label {
      cursor: pointer;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 5px;

      &-active {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .custom-multi-select {
    position: absolute;
    padding: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    left: 50%;
    transform: translate(-50%);
    top: 40px;
    max-height: 300px;
    overflow: auto;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 5px;

      li {
        display: inline-block;
        cursor: pointer;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-right: 5px;

        &.selected {
          background-color: #25c481;
          color: #fff;
        }
      }
    }
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
