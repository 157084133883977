import React, { useEffect, useState } from 'react';
import { OurClientsStyles } from './OurClientsStyles';
import Marquee from 'react-fast-marquee';
import { partnersBlackTheme, partnersWhiteTheme } from '../../constants';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';

function OurClients({
  gradientWidth,
  theme,
}: {
  gradientWidth?: number;
  theme: 'black' | 'white';
}) {
  const [data, setData] = useState<{ static: string; hover: string; alt: string }[]>();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    theme === 'black'
      ? setData([...partnersBlackTheme, ...partnersBlackTheme])
      : setData([...partnersWhiteTheme, ...partnersWhiteTheme]);
  }, []);

  return (
    <OurClientsStyles>
      <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'our_clients_1')}</h2>

      {data && (
        <Marquee
          speed={window.innerWidth > 1000 ? 100 : 120}
          gradientWidth={gradientWidth}
        >
          {data.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="logoWrapper" key={index}>
              <img src={item.static} alt={item.alt} />
              <img src={item.hover} alt={item.alt} />
            </div>
          ))}
        </Marquee>
      )}
    </OurClientsStyles>
  );
}

export default OurClients;
