import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import opportunities1 from '../../../assets/images/opportunities/opportunities-1.png';
import opportunities2 from '../../../assets/images/opportunities/opportunities-2.png';
import opportunities3 from '../../../assets/images/opportunities/opportunities-3.png';
import opportunities4 from '../../../assets/images/opportunities/opportunities-4.png';
import opportunities5 from '../../../assets/images/opportunities/opportunities-5.png';
import opportunities6 from '../../../assets/images/opportunities/opportunities-6.png';

export const cardsData = (
  lang: string,
): { name: string; description: string; img: string }[] => [
  {
    name: getTranslationsByLangOrEng(lang, 'opportunities_36'),
    description: getTranslationsByLangOrEng(lang, 'opportunities_37'),
    img: opportunities1,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'opportunities_38'),
    description: getTranslationsByLangOrEng(lang, 'opportunities_39'),
    img: opportunities2,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'opportunities_40'),
    description: getTranslationsByLangOrEng(lang, 'opportunities_41'),
    img: opportunities3,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'opportunities_42'),
    description: getTranslationsByLangOrEng(lang, 'opportunities_43'),
    img: opportunities4,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'opportunities_44'),
    description: getTranslationsByLangOrEng(lang, 'opportunities_45'),
    img: opportunities5,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'opportunities_46'),
    description: getTranslationsByLangOrEng(lang, 'opportunities_47'),
    img: opportunities6,
  },
];
