import React, { FC } from 'react';

export const Retail: FC<{ color: string; height: number; width: number }> = ({
  color,
  height,
  width,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 57 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.02 36.451C20.22 36.451 21.24 35.4276 21.24 34.2237V23.99C21.24 22.7861 20.22 21.7627 19.02 21.7627C17.82 21.7627 16.8 22.7861 16.8 23.99V34.2237C16.8 35.4878 17.82 36.451 19.02 36.451Z"
      fill={color}
    />
    <path
      d="M28.5 36.451C29.7 36.451 30.72 35.4276 30.72 34.2237V23.99C30.72 22.7861 29.7 21.7627 28.5 21.7627C27.3 21.7627 26.28 22.7861 26.28 23.99V34.2237C26.28 35.4878 27.3 36.451 28.5 36.451Z"
      fill={color}
    />
    <path
      d="M37.98 36.451C39.18 36.451 40.2 35.4276 40.2 34.2237V23.99C40.2 22.7861 39.18 21.7627 37.98 21.7627C36.78 21.7627 35.76 22.7861 35.76 23.99V34.2237C35.76 35.4878 36.72 36.451 37.98 36.451Z"
      fill={color}
    />
    <path
      d="M54.78 13.2757H50.76L40.62 0.814671C39.84 -0.148497 38.46 -0.268893 37.5 0.513681C36.54 1.29625 36.42 2.68081 37.2 3.64398L45.06 13.2757H11.94L19.8 3.64398C20.58 2.68081 20.46 1.29625 19.5 0.513681C18.54 -0.268893 17.16 -0.148497 16.38 0.814671L6.24 13.2757H2.22C1.02 13.2757 0 14.299 0 15.503C0 16.7069 1.02 17.7303 2.22 17.7303H4.68L8.76 43.1339C8.94 44.2174 9.84 45 10.98 45H46.02C47.1 45 48.06 44.2174 48.24 43.1339L52.32 17.7303H54.78C55.98 17.7303 57 16.7069 57 15.503C57 14.299 55.98 13.2757 54.78 13.2757ZM44.16 40.5453H12.84L9.18 17.7303H47.82L44.16 40.5453Z"
      fill={color}
    />
  </svg>
);
