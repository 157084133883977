import styled from 'styled-components';

export const AdHocBenefitStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .texts {
    max-width: 770px;
    align-self: flex-end;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .content {
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 50px;

    p {
      color: #fff;
      font-size: 28px;
      font-weight: 400;
      line-height: 125%;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (max-width: 768px) {
      gap: 25px;
    }
  }

  .cards {
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: space-around;
    flex-wrap: wrap;
    position: relative;
    padding: 50px 0;

    @media (max-width: 768px) {
      padding: 0;
    }

    &__item {
      max-width: 220px;
      height: 210px;
      width: 100%;
      gap: 30px;
      border-radius: 10px;
      padding: 25px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 768px) {
        transform: none !important;
        top: inherit !important;
      }

      &::after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%) rotate(79.56deg);
        width: 115%;
        height: 155%;
        background: radial-gradient(
          67.96% 80.43% at 50% 50%,
          rgba(92, 183, 241, 0.4) 0%,
          rgba(250, 26, 46, 0.4) 50%,
          rgba(252, 198, 102, 0.4) 100%
        );
        filter: blur(95px);
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(
          180deg,
          #fcc666 30%,
          #fa1a2e 50%,
          #5cb7f1 70%
        );
        border-radius: 10px;
        padding: 3px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      &-one {
        transform: rotate(29.56deg);
      }

      &-two {
        transform: rotate(-29.56deg);
        top: 100px;
      }

      &-three {
        top: 50px;
        transform: rotate(19.56deg);
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        justify-content: center;
        width: 100%;
        position: relative;
        z-index: 4;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          color: #fff;
          text-align: center;
        }

        span {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: #fff;
        }

        div {
          width: 100%;
          height: 1px;
          background: #fff;
        }
      }
    }
  }

  .btn {
    align-self: flex-end;

    @media (max-width: 768px) {
      align-self: center;
    }
  }
`;
