import styled from 'styled-components';

export const CxComprehensiveStyle = styled.div`
  padding: 100px 0;
  /* flex-direction: column;
    display: flex;
    gap: 30px; */

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  .comprehensive-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 950px;

    h3 {
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }

    h4 {
      color: #02ba95;
      font-size: 40px;
      font-weight: 500;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }

    ul {
      list-style-type: disc;
      max-width: 750px;

      li {
        color: #fff;
        margin-left: 25px;
        font-size: 28px;
        font-weight: 500;
        line-height: 115%;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }

  .sliderWrapper {
    margin-top: 50px;
  }

  .slide {
    max-width: 770px;
    height: 570px;
    border-radius: 10px;
    border: 2px solid #02ba95;
    padding: 30px 45px;
    background: linear-gradient(
          to bottom right,
          rgba(2, 186, 149, 0.16) 0%,
          rgba(0, 255, 163, 0) 50%
        )
        bottom right / 50% 50% no-repeat,
      linear-gradient(
          to bottom left,
          rgba(2, 186, 149, 0.16) 0%,
          rgba(0, 255, 163, 0) 50%
        )
        bottom left / 50% 50% no-repeat,
      linear-gradient(
          to top left,
          rgba(2, 186, 149, 0.16) 0%,
          rgba(0, 255, 163, 0) 50%
        )
        top left / 50% 50% no-repeat,
      linear-gradient(
          to top right,
          rgba(2, 186, 149, 0.16) 0%,
          rgba(0, 255, 163, 0) 50%
        )
        top right / 50% 50% no-repeat,
      rgba(52, 57, 55, 0.38);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (max-width: 991px) {
      max-width: 570px;
    }

    @media (max-width: 768px) {
      max-width: 320px;
      padding: 10px 15px;
      height: 470px;
    }

    .card-description {
      color: #00ffa3;
      text-align: center;
      font-size: 18px;
      font-style: italic;
      font-weight: 500;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .card-info {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .card-label {
        color: #00ffa3;
        font-size: 40px;
        font-style: italic;
        font-weight: 700;
        line-height: 115%;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      .card-name {
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        line-height: 115%;
        max-width: 250px;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }

    .card-text {
      color: #fff;
      font-size: 28px;
      font-weight: 500;
      line-height: 115%;
      margin-top: 100px;

      @media (max-width: 768px) {
        font-size: 18px;
        margin-top: 50px;
      }
    }
  }
`;
