import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { SociologicalResearchScienceStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const SociologicalResearchScience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchScienceStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_38')}</h1>
        </div>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#F05A25"
          borderColor="#F05A25"
          borderOnHover="#F05A25"
          background="transparent"
          className="btn"
          hoverBackground="#F05A25"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'sociological_research_3',
          )}
        />
      </div>
    </SociologicalResearchScienceStyles>
  );
};
