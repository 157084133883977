import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';
import { BrandIdentityIntroStyles } from './styles';

export const BrandIdentityIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandIdentityIntroStyles>
      <Banner
        bgColor="linear-gradient(225deg, #FFBD14 0%, #DB9000 100%)"
        title={getTranslationsByLangOrEng(
          interfaceLanguage,
          'brands_identity_1',
        )}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'brands_identity_2',
        )}
        buttonKey="brands_identity_3"
      />
    </BrandIdentityIntroStyles>
  );
};
