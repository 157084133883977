import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import sample1 from '../../../assets/images/sociologicalResearch/sample-1.png';
import sample2 from '../../../assets/images/sociologicalResearch/sample-2.png';
import sample3 from '../../../assets/images/sociologicalResearch/sample-3.png';
import sample4 from '../../../assets/images/sociologicalResearch/sample-4.png';
import { SociologicalResearchSampleStyles } from './styles';

export const SociologicalResearchSample = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchSampleStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_54')}</h1>
        </div>
        <div className="cards">
          <div className="cards__item">
            <img src={sample1} alt="" />
            <div className="cards__item-text">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_55')}</p>
            </div>
          </div>
          <div className="cards__item">
            <div className="cards__item-text">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_56')}</p>
            </div>
            <img src={sample2} alt="" />
          </div>
          <div className="cards__item">
            <img src={sample3} alt="" />
            <div className="cards__item-text">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_57')}</p>
            </div>
          </div>
          <div className="cards__item">
            <div className="cards__item-text">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_58')}</p>
            </div>
            <img src={sample4} alt="" />
          </div>
        </div>
      </div>
    </SociologicalResearchSampleStyles>
  );
};
