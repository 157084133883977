import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ConsumerJourneyMapDiscoverStyles } from './styles';

export const ConsumerJourneyMapDiscover = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ConsumerJourneyMapDiscoverStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_10')}
          </h2>
          <ul className="list">
            <li className="list-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_11')}</li>
            <li className="list-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_12')}</li>
          </ul>
        </div>
      </div>
    </ConsumerJourneyMapDiscoverStyles>
  );
};
