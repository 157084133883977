import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesResourcesStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const OpportunitiesResources = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesResourcesStyles>
      <h1>
        {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_58')}
      </h1>
      <h2>
        {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_59')}
      </h2>
      <div className="container">
        <div className="card">
          <h3>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_60')}
          </h3>
          <ul>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_61',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_62',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_63',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_64',
              )}
            </li>
          </ul>
        </div>
        <div className="card">
          <h3>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_65')}
          </h3>
          <ul>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_66',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_67',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_68',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_69',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_70',
              )}
            </li>
          </ul>
        </div>
        <div className="card">
          <h3>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_71')}
          </h3>
          <ul>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_72',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_73',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_74',
              )}
            </li>
          </ul>
        </div>
        <div className="card">
          <h3>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_75')}
          </h3>
          <ul>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_76',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_77',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_78',
              )}
            </li>
          </ul>
        </div>
        <div className="card">
          <h3>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_79')}
          </h3>
          <ul>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_80',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_81',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_82',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_83',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_84',
              )}
            </li>
            <li>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_85',
              )}
            </li>
          </ul>
        </div>
      </div>
    </OpportunitiesResourcesStyles>
  );
};
