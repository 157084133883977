import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ConsumerJourneyMapBenefitStyles } from './styles';

export const ConsumerJourneyMapBenefit = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ConsumerJourneyMapBenefitStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_13')}
          </h2>
          <ul className="list">
            <li className="list-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_14')}</li>
            <li className="list-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_15')}</li>
            <li className="list-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_16')}</li>
          </ul>
        </div>
      </div>
    </ConsumerJourneyMapBenefitStyles>
  );
};
