import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BrandAwarenessUsageSolutionsStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import solutionItem1 from '../../../assets/images/brandAwarenessUsage/brand-solutions-1.png';
import solutionItem2 from '../../../assets/images/brandAwarenessUsage/brand-solutions-2.png';

export const BrandAwarenessUsageSolutions = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandAwarenessUsageSolutionsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_27')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_21')}
          </h2>
          <div className="cards">
            <div className="cards__item">
              <img src={solutionItem1} alt="" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_22')}</p>
            </div>
            <div className="cards__item">
              <img src={solutionItem2} alt="" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_23')}</p>
            </div>
          </div>
          <h3>
            {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_24')}
          </h3>
        </div>
      </div>
    </BrandAwarenessUsageSolutionsStyles>
  );
};
