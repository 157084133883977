import styled from 'styled-components';

export const BrandAwarenessUsageAboutStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }
  .cards {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 40px;

    @media (max-width: 991px) {
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 20px;
    }

    &-item {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      width: calc(50% - 20px);

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    &-img {
      width: 60px;
      height: 60px;
      background: #ffffff;
      border-radius: 50%;
      border: 1px solid #BBBAFF;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 35px;
      }
    }
  }

  .about-img {
    width: 100%;
  }
`;
