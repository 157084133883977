import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { UsageAttitudeStyles } from './styles';
import OurClients from '../../components/ourClients/OurClients';
import ProductsTabs from '../../components/productsTabs/ProductsTabs';
import { productsTabsData } from '../../constants';
import {
  UsageAttitudeAbout,
  UsageAttitudeApproach,
  UsageAttitudeExperience,
  UsageAttitudeIntro,
  UsageAttitudeNeeds,
  UsageAttitudeSlider,
  UsageAttitudeSolutions,
  UsageAttitudeWhomOne,
  UsageAttitudeWhomTwo,
} from '../../components';

export const UsageAttitude = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <UsageAttitudeStyles>
      <UsageAttitudeIntro />
      <UsageAttitudeAbout />
      <UsageAttitudeExperience />
      <UsageAttitudeSolutions />
      <UsageAttitudeApproach />
      <UsageAttitudeWhomOne />
      <UsageAttitudeWhomTwo />
      <UsageAttitudeSlider />
      <UsageAttitudeNeeds />
      <OurClients theme="white" />
      <ProductsTabs
        data={productsTabsData(interfaceLanguage)}
        theme="lightTheme"
      />
    </UsageAttitudeStyles>
  );
};
