import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CxSolutionIntroStyles } from './CxSolutionIntroStyles';
import logo from '../../../assets/images/cxSolution/cx-solution-intro.png';
import intro from '../../../assets/images/cxSolution/cx-solution-circle.png';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CxSolutionIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const [rotation, setRotation] = useState<number>(0);

  useEffect(() => {
    const rotateImage = () => {
      setRotation((prevRotation) => {
        if (prevRotation === 359) {
          return 0;
        }
        return prevRotation + 1;
      });
    };

    const intervalId = setInterval(rotateImage, 50);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <CxSolutionIntroStyles>
      <div className="container">
        <div className="intro-text">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_1')}{' '}
            <span>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_2')}
            </span>
          </h1>
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_3')}
          </p>
        </div>
        <div className="intro-img">
          <img src={logo} className="intro-logo" alt="logo" />
          <img
            src={intro}
            className="intro-circle"
            style={{ transform: `rotate(${rotation}deg)` }}
            alt=""
          />
        </div>
      </div>
    </CxSolutionIntroStyles>
  );
};
