import styled from 'styled-components';

export const СxSolutionStyles = styled.div`
  font-family: 'Montserrat';
  background-color: #000;
  position: relative;
  overflow: hidden;

  .container {
    max-width: 1190px;
    margin: 0 auto;
  }
`;
