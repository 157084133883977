import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { UsageAttitudeNeedsStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const BrandIdentityNeeds = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <UsageAttitudeNeedsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_23')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_24')}
          </h1>
          <div className="cards-container">
            <div className="cards-item" />
            <div className="cards-item">
              <span
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'brands_identity_9',
                  ),
                }}
              />
            </div>
            <div className="cards-item">
              <span
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'brands_identity_10',
                  ),
                }}
              />
            </div>
            <div className="cards-item">
              <ButtonWithArrowOnHover
                onClick={() => {
                  const elem = document.getElementById('form');
                  elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}
                arrowColor="#000"
                textColor="#fff"
                borderColor="#000"
                borderOnHover="#000"
                background="#fff"
                hoverBackground="linear-gradient(225deg, #FFBD14 0%, #DB9000 100%)"
                hoverTextColor="#000"
                text={getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'usage_attitude_11',
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </UsageAttitudeNeedsStyles>
  );
};
