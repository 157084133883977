import styled from 'styled-components';

export const UsageAttitudeWhomStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      list-style-type: disc;
      margin-left: 25px;
      color: #0f0e0c;
      font-size: 20px;
      line-height: 125%;
    }
  }
`;
