import styled from 'styled-components';

export const ProductsTabsStyles = styled.div`
  #tabs {
    .contentContainer {
      @media (min-width: 601px) {
        height: 300px;

        padding: 30px 100px;
        box-sizing: border-box;

        @media (max-width: 1100px) {
          height: auto;
          min-height: 200px;
          padding: 30px;
        }
      }
    }
  }
`;
