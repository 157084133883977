import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { MolCongratulationsStyles } from './MolCongratulationsStyles';
import data from './data.json';

const countries = ['Romania', 'Hungary', 'Czech Republic', 'Serbia'];
const topics = [
  'Additional services',
  'Amenities at the gas station',
  'App discounts',
  'General customer experience',
  'Cleanliness and comfort',
  'Cleanliness and food quality',
  'Cleanliness and quality of coffee',
  'Quality of fuel and service',
  'Cleanliness and service',
  'Coffee and Food Quality',
  'Comparison with MOL',
  'Convenience',
  'Customer service',
  'App discounts',
  'Food quality',
  'Food quality and service',
  'Fuel Quality',
  'Gas station amenities and cleanliness',
  'Gas station amenities and food quality',
  'General Customer Experience',
  'Parking facilities',
  'Price of gasoline',
  'Quality and price of gasoline',
  'Quality and Service',
  'Quality of Coffee',
  'Quality of Coffee and Food',
  'Quality of Coffee and service',
  'Quality of Coffee',
  'Quality of Food',
  'Quality of Food and service',
  'Quality of Coffee and Fuel',
  'Quality of gasoline',
  'Quality of gasoline and staff work',
  'Quality of gasoline',
  'Quality of service',
  'Convenience',
];

type TItem = {
  url: string;
  city: string;
  date: string;
  country: string;
  text: string;
  translateToEng: string;
  topic: string;
};

type TCSelect = {
  options: string[];
  selectedValues: string[];
  onChange: Dispatch<SetStateAction<string[]>>;
  label: string;
};

function filterDataByCountries(data: TItem[], countries: string[]) {
  if (countries.length === 0) {
    return data;
  }
  return data.filter((item) => countries.includes(item.country));
}
function filterDataByTopics(data: TItem[], topics: string[]) {
  if (topics.length === 0) {
    return data;
  }
  return data.filter((item) => topics.some((topic) => item.topic.includes(topic)));
}

const CustomMultiSelect = ({
  options,
  selectedValues,
  onChange,
  label,
}: TCSelect) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOptionClick = (value: string) => {
    const isSelected = selectedValues.includes(value);
    const updatedValues = isSelected
      ? selectedValues.filter(
        (selectedValue: string) => selectedValue !== value,
      )
      : [...selectedValues, value];
    onChange(updatedValues);
  };

  return (
    <div className="custom-select-container">
      <div
        className={
          open
            ? 'custom-select-label custom-select-label-active'
            : 'custom-select-label'
        }
        onClick={() => setOpen(!open)}
      >
        {label}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          fill="none"
          viewBox="0 0 512 512"
        >
          <path
            fill="#fff"
            d="M3.9 54.9C10.5 40.9 24.5 32 40 32h432c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6v-79.1L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
          />
        </svg>
      </div>
      {open && (
        <div className="custom-multi-select">
          <ul>
            {options.map((option: string) => (
              <li
                key={option}
                onClick={() => handleOptionClick(option)}
                className={selectedValues.includes(option) ? 'selected' : ''}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const MolCongratulations = () => {
  const [items, setItems] = useState<TItem[]>(data as TItem[]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  useEffect(() => {
    let filteredData = filterDataByCountries(
      data as TItem[],
      selectedCountries,
    );
    filteredData = filterDataByTopics(filteredData, selectedTopics);
    setItems(filteredData);
  }, [selectedCountries, selectedTopics]);

  return (
    <MolCongratulationsStyles>
      <div className="banner" />
      <div className="table-wrapper">
        <div className="tbl-header">
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>Date</th>
                <th>City</th>
                <th>
                  <CustomMultiSelect
                    label="Country"
                    options={countries}
                    selectedValues={selectedCountries}
                    onChange={setSelectedCountries}
                  />
                </th>
                <th>URL</th>
                <th style={{ width: '23%' }}>Text</th>
                <th style={{ width: '23%' }}>Translate to Eng</th>
                <th style={{ width: '15%' }}>
                  <CustomMultiSelect
                    label="Topic"
                    options={topics}
                    selectedValues={selectedTopics}
                    onChange={setSelectedTopics}
                  />
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="tbl-content">
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              {items.map((item) => (
                <tr key={item.url}>
                  <td>{item.date}</td>
                  <td className="text-truncate">{item.city}</td>
                  <td>{item.country}</td>
                  <td>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      Link
                    </a>
                  </td>
                  <td style={{ width: '23%' }}>{item.text}</td>
                  <td style={{ width: '23%' }}>{item.translateToEng}</td>
                  <td style={{ width: '15%' }}>{item.topic}</td>
                </tr>
              ))}
              {!items.length && (
                <tr>
                  <td style={{ fontSize: 30, textAlign: 'center' }}>
                    NO ITEMS
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </MolCongratulationsStyles>
  );
};
