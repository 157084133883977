import React, { useEffect, useState } from 'react';
import Seo from '../seo/Seo';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { AdminApi } from '../../api/adminApi';
import { IMetaData } from '../../enteties/IMetaData';
import { setPagesMetaData } from '../../state/slices/metaDataSlice';

interface MetaFromApiProps {
  slug: string;
}

function MetaFromApi({ slug }: MetaFromApiProps) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { pagesMetaData } = useAppSelector((state) => state.metaData);
  const dispatch = useAppDispatch();

  const [metaData, setMetaData] = useState<IMetaData>();

  const notCanonical = window.location.pathname.includes(`/${interfaceLanguage}/blog`)
    || interfaceLanguage === 'en';
  let path = window.location.pathname.replace(`/${interfaceLanguage}/`, '/en/');

  if (window.location.pathname === `/${interfaceLanguage}`) {
    path = '/en';
  }

  const canonical = `${window.location.protocol}//${window.location.host}${path}`;

  useEffect(() => {
    if (!pagesMetaData.main) {
      AdminApi.getMetaBySlug('main').then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          dispatch(setPagesMetaData({ ...pagesMetaData, main: res.data }));
        }
      });
    }

    if (!pagesMetaData[slug]) {
      AdminApi.getMetaBySlug(slug).then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setMetaData(res.data);

          dispatch(setPagesMetaData({ ...pagesMetaData, [slug]: res.data }));
        }
      });
    } else {
      setMetaData(pagesMetaData[slug]);
    }
  }, []);

  return (
    <Seo
      title={
        metaData?.meta[interfaceLanguage]?.values?.metaTitle
        || pagesMetaData.main?.meta[interfaceLanguage]?.values?.metaTitle
      }
      description={
        metaData?.meta[interfaceLanguage]?.values?.metaDescription
        || pagesMetaData.main?.meta[interfaceLanguage]?.values?.metaDescription
      }
      keywords={
        metaData?.meta[interfaceLanguage]?.values?.keywords
        || pagesMetaData.main?.meta[interfaceLanguage]?.values?.keywords
      }
      lang={interfaceLanguage}
      ogDescription={
        metaData?.meta[interfaceLanguage]?.values?.ogDescription
        || pagesMetaData.main?.meta[interfaceLanguage]?.values?.ogDescription
      }
      ogTitle={
        metaData?.meta[interfaceLanguage]?.values?.ogTitle
        || pagesMetaData.main?.meta[interfaceLanguage]?.values?.ogTitle
      }
      canonicalHref={notCanonical ? '' : canonical}
    />
  );
}

export default MetaFromApi;
