import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CustomerProfilingSegmentationSegmentationStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CustomerProfilingSegmentationSegmentation = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CustomerProfilingSegmentationSegmentationStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_29')}
          </h2>
          <div className="item">
            <div className="item-dot" />
            <h4>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_30')}</h4>
            <div className="item-list">
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_31')}</div>
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_32')}</div>
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_33')}</div>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_34')}</p>
          </div>
          <div className="item">
            <div className="item-dot" />
            <h4>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_35')}</h4>
            <div className="item-list">
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_36')}</div>
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_37')}</div>
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_38')}</div>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_39')}</p>
          </div>
          <div className="item">
            <div className="item-dot" />
            <h4>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_40')}</h4>
            <div className="item-list">
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_41')}</div>
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_42')}</div>
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_43')}</div>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_44')}</p>
          </div>
          <div className="item">
            <div className="item-dot" />
            <h4>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_45')}</h4>
            <div className="item-list">
              <div className="item-chips">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_46')}</div>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_47')}</p>
          </div>
        </div>
      </div>
    </CustomerProfilingSegmentationSegmentationStyles>
  );
};
