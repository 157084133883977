import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CxGetStartedStyles } from './CxGetStartedStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CxGetStarted = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <CxGetStartedStyles>
      <div className="container">
        <h3>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_71')}
        </h3>
        <div className="cx-plan">
          <div className="cx-plan-item">
            <h2>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_72')}
            </h2>
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_73')}
            </p>
          </div>
          <div className="cx-plan-item">
            <h2>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_74')}
            </h2>
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_75')}
            </p>
          </div>
          <div className="cx-plan-item">
            <h2>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_76')}
            </h2>
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_77')}
            </p>
          </div>
          <div className="cx-plan-item">
            <h2>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_78')}
            </h2>
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_79')}
            </p>
          </div>
          <div className="cx-plan-item">
            <h2>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_80')}
            </h2>
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_81')}
            </p>
          </div>

          <div className="cx-elapse cx-elapse-first" />
          <div className="cx-elapse cx-elapse-second" />
        </div>
      </div>
    </CxGetStartedStyles>
  );
};
