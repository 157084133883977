import styled from 'styled-components';

export const BannerStyle = styled.div<{ image?: string, bgColor: string}>`
  h1 {
    font-weight: 700;
    font-size: 100px;
    line-height: 97%;
    text-align: center;
    color: #000000;
    position: relative;
    z-index: 5;
    transform: translateY(55px);
    padding: 10px;

    white-space: pre-wrap;

    @media (max-width: 1000px) {
      font-size: 55px;
      padding: 24px 0;
    }

    @media (max-width: 600px) {
      font-size: 46px;
      padding: 15px 0;
      transform: translateY(30px);
    }
  }

  .content {
    padding: 88px 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background: ${({ bgColor }) => bgColor};
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: url(${({ image }) => (image || '')}) no-repeat;
      background-position: center center;
      background-size: cover;
    }

    @media (max-width: 1000px) {
      padding: 50px 30px 72px;
    }

    @media (max-width: 600px) {
      padding: 45px 30px 149px;
    }

    button {
      z-index: 5;
    }
  }

  .bannerDescription {
    margin-bottom: 49px;
    position: relative;
    z-index: 5;

    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #ffffff;

    white-space: pre-wrap;

    @media (max-width: 1000px) {
      font-size: 23px;
      line-height: 28px;
    }

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 29px;
    }
  }
`;
