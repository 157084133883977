import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { sliderData } from './utils';
import { SwiperSlide } from 'swiper/react';
import { SociologicalResearchSliderSociologicalStyles } from './styles';
import { el } from 'date-fns/locale';

export const SociologicalResearchSliderSociological = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchSliderSociologicalStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_37')}</h1>
        </div>
      </div>
      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          centeredSlides
          loop
          buttonBorderColor="#F05A25"
          buttonsIconsColor="#000"
        >
          {sliderData(interfaceLanguage).map((item, index) => (
            <SwiperSlide className="slide" key={item.link}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <img src={item.img} alt="" />
              </a>
            </SwiperSlide>
          ))}
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </SociologicalResearchSliderSociologicalStyles>
  );
};
