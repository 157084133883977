import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { SociologicalResearchLicenseStyles } from './styles';

export const SociologicalResearchLicense = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchLicenseStyles>
      <div className="container">
        <div className="content">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_49')}</h1>
          <ul>
            <li>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_50')}</li>
            <li>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_51')}</li>
            <li>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_52')}</li>
            <li>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_53')}</li>
          </ul>
        </div>
      </div>
    </SociologicalResearchLicenseStyles>
  );
};
