import styled from 'styled-components';

export const OpportunitiesProductsStyles = styled.div`
  padding: 100px 0;
  background: #000;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    text-align: center;
    color: #ffffff;
    max-width: 1190px;
    margin: 0 auto;

    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
    }

    span {
      color: #0000ff;
    }
  }

  .container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 25px;
    flex-wrap: wrap;

    .product-card {
      display: flex;
      flex-direction: column;
      max-width: 370px;
      width: 100%;

      .product-text {
        display: flex;
        flex-direction: column;
        padding: 35px;
        gap: 10px;
        background: #0000ff;
        height: calc(260px - 70px);

        @media (max-width: 768px) {
          padding: 20px;
          height: calc(220px - 40px);
          order: 2;
        }

        h6 {
          font-weight: 600;
          font-size: 26px;
          line-height: 120%;
          color: #ffffff;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 22px;
          }
        }
        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 120%;
          color: #ffffff;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      img {
        width: 100%;
        height: 210px;
        object-fit: cover;

        @media (max-width: 768px) {
          height: 170px;
          order: 1;
        }
      }
    }
  }
`;
