import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ConsumerJourneyMapMapStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import map from '../../../assets/images/consumerJourneyMap/map.png';

export const ConsumerJourneyMapMap = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ConsumerJourneyMapMapStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_27')}
          </h2>
        </div>
      </div>
      <div className="full-container">
        <div className="img-container">
          <img src={map} alt="map" />
        </div>
      </div>
    </ConsumerJourneyMapMapStyles>
  );
};
