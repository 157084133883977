import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesDescriptionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const OpportunitiesDescription = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesDescriptionStyles>
      <div className="container">
        <h1
          dangerouslySetInnerHTML={{
            __html: getTranslationsByLangOrEng(
              interfaceLanguage,
              'opportunities_19',
            ),
          }}
        />
        <h2>
          {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_20')}
        </h2>
        <button
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          type="button"
        >
          {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_21')}
        </button>
      </div>
    </OpportunitiesDescriptionStyles>
  );
};
