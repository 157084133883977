import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditMethodTwoStyles } from './FacilityCheckAuditMethodTwoStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import FacilityCheckAuditMethodTwoImg from '../../../assets/images/FacilityCheckAuditMethodTwo.png';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import { FacilityCheckAuditLine } from '../../../assets/icons/FacilityCheckAuditLine';

export const FacilityCheckAuditMethodTwo = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditMethodTwoStyles>
      <img src={FacilityCheckAuditMethodTwoImg} className="building" alt="" />
      <div className="container">
        <h5>{`${getTranslationsByLangOrEng(
          interfaceLanguage,
          'facility_check_audit_15',
        )} 2`}
        </h5>
        <div className="content">
          <h4>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_57',
            )}
          </h4>
          <h6>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_58',
            )}
          </h6>
          <div className="customList">
            <div className="customList-item">
              <FacilityCheckAuditLine />
              <div className="customList-text">
                <h6>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_59',
                  )}
                </h6>
                <ul>
                  <li>
                    {getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'facility_check_audit_60',
                    )}
                  </li>
                  <li>
                    {getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'facility_check_audit_61',
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="customList-item">
              <FacilityCheckAuditLine />
              <div className="customList-text">
                <h6>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_62',
                  )}
                </h6>
                <ul>
                  <li>
                    {getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'facility_check_audit_63',
                    )}
                  </li>
                  <li>
                    {getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'facility_check_audit_64',
                    )}
                  </li>
                  <li>
                    {getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'facility_check_audit_65',
                    )}
                  </li>
                  <li>
                    {getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'facility_check_audit_66',
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="customList-item">
              <FacilityCheckAuditLine />
              <div className="customList-text">
                <h6>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_67',
                  )}
                </h6>
                <ul>
                  <li>
                    {getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'facility_check_audit_68',
                    )}
                  </li>
                  <li>
                    {getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'facility_check_audit_69',
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#000"
            textColor="#000"
            borderColor="#000"
            borderOnHover="#000"
            background="none"
            className="centered-btn"
            hoverBackground="#FFF"
            hoverTextColor="#000"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_69',
            )}
          />
        </div>
      </div>
    </FacilityCheckAuditMethodTwoStyles>
  );
});
