import styled from 'styled-components';

export const BrandIdentityOpportunitiesStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .opportunities {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;

    &__list {
      width: calc(45% - 10px);
      display: flex;
      flex-direction: column;
      gap: 20px;

      h3 {
        font-weight: 600;
        font-size: 28px;
        line-height: 125%;
        color: #0F0E0C;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
        
        li {
          margin-left: 20px;
          list-style-type: disc;
          font-size: 20px;
        }
      }
    }
  }
`;
