import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditMethodOneStyles } from './FacilityCheckAuditMethodOneStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const FacilityCheckAuditMethodOne = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditMethodOneStyles>
      <div className="container">
        <h5>{`${getTranslationsByLangOrEng(
          interfaceLanguage,
          'facility_check_audit_15',
        )} 1`}
        </h5>
        <div className="content">
          <h4>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_44',
            )}
          </h4>
          <div className="blocks">
            <div className="blocks-textblock">
              <div className="blocks-text">
                <h6>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_45',
                  )}
                </h6>
                <p>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_46',
                  )}
                </p>
                <p>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_47',
                  )}
                </p>
              </div>
              <div className="blocks-text">
                <h6>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_48',
                  )}
                </h6>
                <p>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_49',
                  )}
                </p>
              </div>
              <div className="blocks-text">
                <h6>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_50',
                  )}
                </h6>
                <p>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_51',
                  )}
                </p>
                <p>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_52',
                  )}
                </p>
                <p>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_53',
                  )}
                </p>
              </div>
              <div className="blocks-text">
                <h6>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_54',
                  )}
                </h6>
                <p>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_55',
                  )}
                </p>
                <p>
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'facility_check_audit_56',
                  )}
                </p>
              </div>
            </div>
            <div className="blocks-one">
              <div className="absolute">1.</div>
            </div>
            <div className="blocks-two">
              <div className="absolute">2.</div>
            </div>
            <div className="blocks-three">
              <div className="absolute">3.</div>
            </div>
            <div className="blocks-four">
              <div className="absolute">4.</div>
            </div>
          </div>
        </div>
      </div>
    </FacilityCheckAuditMethodOneStyles>
  );
});
