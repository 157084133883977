import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { BrandIdentityStyles } from './styles';
import OurClients from '../../components/ourClients/OurClients';
import ProductsTabs from '../../components/productsTabs/ProductsTabs';
import { productsTabsData } from '../../constants';
import {
  BrandIdentityAbout,
  BrandIdentityApproach,
  BrandIdentityCharts,
  BrandIdentityExperience,
  BrandIdentityIntro,
  BrandIdentityNeeds,
  BrandIdentityOpportunities,
  BrandIdentitySlider,
} from '../../components';

export const BrandIdentity = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandIdentityStyles>
      <BrandIdentityIntro />
      <BrandIdentityAbout />
      <BrandIdentityExperience />
      <BrandIdentityOpportunities />
      <BrandIdentityApproach />
      <BrandIdentityCharts />
      <BrandIdentitySlider />
      <BrandIdentityNeeds />
      <OurClients theme="white" />
      <ProductsTabs
        data={productsTabsData(interfaceLanguage)}
        theme="lightTheme"
      />
    </BrandIdentityStyles>
  );
};
