import React from 'react';
import { FacilityCheckAuditSliderStyles } from './FacilityCheckAuditSliderStyles';
import FacilityCheckAuditGraf1 from '../../../assets/images/FacilityCheckAuditGraf1.png';
import FacilityCheckAuditGraf2 from '../../../assets/images/FacilityCheckAuditGraf2.png';
import { useWindowSize } from 'usehooks-ts';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { SwiperSlide } from 'swiper/react';

const sliderData = [FacilityCheckAuditGraf1, FacilityCheckAuditGraf2];

export const FacilityCheckAuditSlider = React.memo(() => {
  const { width } = useWindowSize();

  return (
    <FacilityCheckAuditSliderStyles>
      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          buttonsIconsColor="#000"
          offsetRight={
            window.innerWidth > 1170 ? (window.innerWidth - 1170) / 2 : 10
          }
          centeredSlides
          loop={false}
          initialSlide={0}
          disabledNext
          disabledPrev
          buttonBorderColor="#9E00FF"
        >
          {sliderData.map((item) => (
            <SwiperSlide className="slide" key={item.toString()}>
              <img src={item} alt="" />
            </SwiperSlide>
          ))}
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </FacilityCheckAuditSliderStyles>
  );
});
