import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import { ConsumerJourneyMapExperienceStyles } from './styles';

export const ConsumerJourneyMapExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ConsumerJourneyMapExperienceStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_9')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_10')}
          </h2>
          <div className="btn-container">
            <ButtonWithArrowOnHover
              onClick={() => {
                const elem = document.getElementById('form');
                elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
              }}
              arrowColor="#fff"
              textColor="#000"
              borderColor="#FDA272"
              borderOnHover="#000"
              background="linear-gradient(180deg, #FDA272 0%, #F15967 100%)"
              hoverBackground="#000"
              hoverTextColor="#fff"
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_11',
              )}
            />
          </div>
        </div>
      </div>
    </ConsumerJourneyMapExperienceStyles>
  );
};
