import React, { FC } from 'react';

export const Tobacco: FC<{ color: string; height: number; width: number }> = ({
  color,
  height,
  width,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 66 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.6 22.1738H4.4C1.97267 22.1738 0 24.1621 0 26.6087V29.5653C0 32.0118 1.97267 34.0001 4.4 34.0001H61.6C64.0273 34.0001 66 32.0118 66 29.5653V26.6087C66 24.1621 64.0273 22.1738 61.6 22.1738ZM55.7333 25.1304V31.0435H19.0667V25.1304H55.7333ZM2.93333 29.5653V26.6087C2.93333 25.7956 3.59333 25.1304 4.4 25.1304H16.1333V31.0435H4.4C3.59333 31.0435 2.93333 30.3783 2.93333 29.5653ZM63.0667 29.5653C63.0667 30.3783 62.4067 31.0435 61.6 31.0435H58.6667V25.1304H61.6C62.4067 25.1304 63.0667 25.7956 63.0667 26.6087V29.5653Z"
      fill={color}
    />
    <path
      d="M35.9331 10.348H39.7317C40.4357 14.5389 44.0437 17.7394 48.3997 17.7394H63.0664C63.8731 17.7394 64.5331 17.0742 64.5331 16.2611C64.5331 11.368 60.5877 7.39143 55.7331 7.39143H51.9344C51.2304 3.20049 47.6151 0 43.2664 0H28.5997C27.7931 0 27.1331 0.665229 27.1331 1.47829C27.1331 6.37141 31.0784 10.348 35.9331 10.348ZM61.4164 14.7829H48.3997C45.6717 14.7829 43.3691 12.898 42.7164 10.348H50.5997H55.7331C58.4611 10.348 60.7637 12.2328 61.4164 14.7829ZM43.2664 2.95657C45.9944 2.95657 48.2971 4.84139 48.9497 7.39143H41.0664H35.9331C33.2051 7.39143 30.9024 5.50661 30.2497 2.95657H43.2664Z"
      fill={color}
    />
  </svg>
);
