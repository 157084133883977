import styled from 'styled-components';

export const CustomerProfilingSegmentationAboutStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .cards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 25px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &-item {
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      color: #000000;

      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 10px;
      width: calc(50% - 20px);
      height: 260px;
      border: 5px solid #AE19A4;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      @media (max-width: 768px) {
        font-size: 18px;
        height: 200px;
        width: 100%;
      }
    }
  }
`;
