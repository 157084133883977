import styled from 'styled-components';

export const ThankYouPartnerStyles = styled.div`
  padding-top: 175px;
  padding-bottom: 220px;
  color: #fff;
  flex-grow: 1;
  overflow: hidden;
  background: radial-gradient(
    53.12% 87.93% at 50% 17.01%,
    rgba(0, 0, 255, 0.62) 0%,
    #000000 100%
  );

  @media (max-width: 1000px) {
    padding-top: 110px;
    padding-bottom: 130px;
  }

  @media (max-width: 500px) {
    padding-top: 30px;
    padding-bottom: 52px;
  }

  .container {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    img {
      @media (max-width: 1200px) {
        max-width: 500px;
      }
      @media (max-width: 991px) {
        max-width: 400px;
      }
      @media (max-width: 768px) {
        order: 2;
        max-width: 300px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 50px;

      @media (max-width: 768px) {
        align-items: center;
      }

      h1 {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 59px;
        line-height: 97%;
        color: #ffffff;

        @media (max-width: 1200px) {
          font-size: 40px;
        }
        @media (max-width: 991px) {
          font-size: 30px;
        }

        @media (max-width: 768px) {
          text-align: center;
        }
      }

      button {
        border: 1px solid #0000ff;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        cursor: pointer;
        transition: 0.3s ease all;
        padding: 14px 25px;
        gap: 10px;
        width: fit-content;
        background: #0000ff;
        border-radius: 100px;

        &:hover {
          background: #fff;
          color: #0000ff;
        }

        @media (max-width: 991px) {
          padding: 14px 48px;
        }
      }
    }
  }
`;
