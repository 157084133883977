import styled from 'styled-components';

export const FacilityCheckAuditSolutionOneStyles = styled.div`
  padding: 90px 0 50px 0;
  width: 100%;

  @media (max-width: 900px) {
    padding: 50px 0;
  }
`;
