import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { CustomerProfilingSegmentationQuoteStyles } from './styles';

export const CustomerProfilingSegmentationQuote = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CustomerProfilingSegmentationQuoteStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_21')}
          </h2>

          <div className="quote">“</div>
        </div>
      </div>
    </CustomerProfilingSegmentationQuoteStyles>
  );
};
