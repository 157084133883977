import styled from 'styled-components';

export const SociologicalResearchTeamStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (max-width: 768px) {
      gap: 30px;
    }
  }

  .cards {
    width: 100%;
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 4px solid #f05a25;
      width: calc(50% - 22px);
      border-radius: 10px;
      position: relative;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 100%;
        max-width: 370px;
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 300px;
        gap: 15px;
        padding: 20px;

        @media (max-width: 768px) {
          height: 200px;
        }

        h6 {
          margin: 0;
          font-weight: 700;
          font-size: 32px;
          line-height: 125%;
          text-align: center;
          color: #000000;

          @media (max-width: 768px) {
            font-size: 22px;
          }
        }

        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 125%;
          text-align: center;
          color: #000000;

          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .texts {
    width: 100%;
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 768px) {
      gap: 10px;
    }

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 125%;
      color: #000000;

      @media (max-width: 768px) {
        font-size: 18px;
      }

      span {
        background: linear-gradient(180deg, #f05a25 0%, #faaf3b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
`;
