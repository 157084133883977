import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { SwiperSlide } from 'swiper/react';
import { SociologicalResearchVideosliderStyles } from './styles';

export const SociologicalResearchVideoslider = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchVideosliderStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_36')}</h1>
        </div>
      </div>
      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          centeredSlides
          loop
          buttonBorderColor="#F05A25"
          buttonsIconsColor="#000"
        >
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/S9Ip_gq0NmU?si=5bhjlHAmQ7Oe5Asg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/PkoEDREUaY8?si=Tp9Xu2O6RfnhDORC" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/GrIpt1jpeVc?si=7pY5KpzPQEhmK-8p" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/PuLpkKVEHe4?si=ulAj1D6UC7gwhr68" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/2xA39JRfbtY?si=k2s2YEdAIS7Ik5tL" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </SociologicalResearchVideosliderStyles>
  );
};
