import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const sliderData = (
  lang: string,
): { tool: string; description: string }[] => [
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_27'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_28'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_29'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_30'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_31'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_32'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_33'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_34'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_35'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_36'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_37'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_38'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_39'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_40'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_41'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_42'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_43'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_44'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_45'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_46'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_47'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_48'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_49'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_50'),
  },
  {
    tool: getTranslationsByLangOrEng(lang, 'cx_solution_51'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_52'),
  },
];
