import React, { useEffect, useState } from 'react';
import { VacancyStyles } from './styles';
import { NotFoundCareer } from '../../components';
import { useLocation, useParams } from 'react-router-dom';
import { ApiVacancy } from '../../api/entities/ApiVacancies';
import { AdminApi } from '../../api/adminApi';
import { Geo } from '../../assets/icons/vacancies/Geo';
import ButtonWithArrowOnHover from '../../components/buttonWithArrowOnHover/ButtonWithArrowOnHover';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { activateBanner } from '../../state/slices/vacanciesSlice';

export const Vacancy = () => {
  const { vacancy } = useParams();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const dispatch = useAppDispatch();

  const [target, setTarget] = useState<ApiVacancy>();

  useEffect(() => {
    if (vacancy) {
      AdminApi.getVacancy(vacancy).then((res) => {
        if (res.statusCode === 200) {
          setTarget(res.data);
        }
      });
    }
  }, []);

  return (
    <VacancyStyles>
      <div className="container">
        {target && (
          <div className="vacancy">
            <div className="vacancy__header">
              <div className="vacancy__utils">
                <Geo />
                <p>{target.country}</p>
                <p>{target.type}</p>
              </div>
              <h1>{target.name}</h1>
            </div>
            <div className="vacancy__body">
              <div className="vacancy__description">
                <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'career_16')}</h6>
                <ul>
                  {target.tasks.map((task) => (
                    <li key={task}>{task}</li>
                  ))}
                </ul>
              </div>
              <div className="vacancy__description">
                <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'career_17')}</h6>
                <ul>
                  {target.requirements.map((requirement) => (
                    <li key={requirement}>{requirement}</li>
                  ))}
                </ul>
              </div>
              <div className="vacancy__description">
                <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'career_18')}</h6>
                <ul>
                  {target.offers.map((offer) => (
                    <li key={offer}>{offer}</li>
                  ))}
                </ul>
              </div>
              <ButtonWithArrowOnHover
                onClick={() => {
                  dispatch(activateBanner({ activeBanner: true, vacancyId: target.id }));
                }}
                arrowColor="#fff"
                textColor="#fff"
                borderColor="#fff"
                borderOnHover="#fff"
                background="transparent"
                hoverBackground="transparent"
                hoverTextColor="#fff"
                text={getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'career_19',
                )}
              />
            </div>
          </div>
        )}
      </div>
      <NotFoundCareer />
    </VacancyStyles>
  );
};
