import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import methodology1 from '../../../assets/images/adHoc/methodology-1.png';
import methodology2 from '../../../assets/images/adHoc/methodology-2.png';
import methodology3 from '../../../assets/images/adHoc/methodology-3.png';
import { AdHocMethodologyStyles } from './styles';

export const AdHocMethodology = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <AdHocMethodologyStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_19')}</h1>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_20')}</p>
        </div>
        <div className="cards">
          <div className="cards__item">
            <img src={methodology1} alt="" />
            <div className="cards__item-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_21')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_22')}</p>
            </div>
          </div>
          <div className="cards__item">
            <img src={methodology2} alt="" />
            <div className="cards__item-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_10')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_11')}</p>
            </div>
          </div>
          <div className="cards__item">
            <img src={methodology3} alt="" />
            <div className="cards__item-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_12')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_13')}</p>
            </div>
          </div>
        </div>
      </div>
    </AdHocMethodologyStyles>
  );
};
