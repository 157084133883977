import styled from 'styled-components';

export const Poll4OpinionStyles = styled.div`
  background: #fff;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 70px 10px;
    z-index: 5;

    @media (max-width: 768px) {
      gap: 30px;
      padding: 30px 10px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 125%;
    text-align: center;
    color: #0f0e0c;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .pool {
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      z-index: 2;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        38.43deg,
        rgba(0, 0, 255, 0.6) 0%,
        rgba(0, 0, 255, 0) 101.83%
      );
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 25px;
      border: 2px solid #ffffff;
      border-radius: 100px;
      gap: 25px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: 25px;
      z-index: 5;

      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        color: #ffffff;
      }

      &:hover {
        background: #fff;

        span {
          color: #000;
        }

        svg {
          path {
            fill: #000;
          }
        }
      }
    }
  }
`;
