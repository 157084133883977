import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface vacanciesState {
  activeBanner: boolean,
  vacancyId: number | null,
}

const initialState: vacanciesState = {
  activeBanner: false,
  vacancyId: null,
};

type TUpdateBannerPayload = {
  activeBanner: boolean;
  vacancyId?: number | null;
}

export const vacanciesSlice = createSlice({
  name: 'vacancies',
  initialState,
  reducers: {
    deactivateBanner: (state) => {
      state.activeBanner = false;
      state.vacancyId = null;
    },
    activateBanner: (state, action: PayloadAction<TUpdateBannerPayload>) => {
      state.activeBanner = action.payload.activeBanner;
      if (action.payload.vacancyId !== undefined) {
        state.vacancyId = action.payload.vacancyId;
      }
    },
  },
  extraReducers: {},
});

const { actions, reducer } = vacanciesSlice;
export const { deactivateBanner, activateBanner } = actions;
export default reducer;
