import styled from 'styled-components';
import fixedBG from './assets/fixed2.png';
import fixedBGMob from './assets/fixed2mob.png';

export const YearGoodDeedsStyles = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
  background: linear-gradient(180deg, #77afd7 0%, #f6e54e 100%);
  background-attachment: fixed;
  position: relative;

  ._container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

  .progressBar {
    display: flex;
    flex-direction: column;
    position: fixed;
    transform: translate(-50%);
    max-width: 1200px;
    width: 100%;
    bottom: 25px;
    left: 50%;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10.350000381469727px);
    padding: 10px 15px;
    gap: 10px;
    z-index: 99;

    @media screen and (max-width: 800px) {
      display: none !important;
    }

    .months {
      display: flex;
      align-items: center;
      justify-content: space-around;

      span {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 152%;
      }
    }

    .bar {
      border-radius: 1000px;
      background: rgba(255, 255, 255, 0.5);
      height: 4px;

      .fill {
        border-radius: 1000px;
        background: #77afd7;
        height: 4px;
      }
    }
  }

  .intro {
    &__container {
      margin: 0 auto -5px;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .content {
    &__container {
      padding: 200px 0 0;
      background: url(${fixedBG}) no-repeat;
      background-size: contain;
      background-position: top center;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media screen and (max-width: 800px) {
        gap: 20px;
        padding: 100px 0 0;
        background: url(${fixedBGMob}) no-repeat;
        background-size: contain;
        background-position: top center;
      }
    }

    .bottomImg {
      position: relative;

      img {
        width: 100%;
        display: block;
        z-index: 2;
        background: linear-gradient(to top, white 30%, transparent 100%);
      }

      &::before {
        position: absolute;
        content: '';
        height: 545px;
        left: -500px;
        bottom: 0;
        width: 500px;
        background: #fff;
      }

      &::after {
        position: absolute;
        content: '';
        height: 545px;
        right: -500px;
        bottom: 0;
        width: 500px;
        background: #fff;
      }
    }

    .card {
      width: 100%;
      max-width: 470px;
      box-shadow: 0px 11px 15.2px 0px rgba(0, 0, 0, 0.14);
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      background: #fff;

      img {
        width: 100%;
        object-fit: contain;
      }

      &:nth-child(2n + 1) {
        align-self: flex-start;
        margin-left: 100px;

        @media screen and (max-width: 800px) {
          align-self: center;
          margin-left: 0;
        }
      }

      &:nth-child(2n) {
        align-self: flex-end;
        margin-right: 100px;

        @media screen and (max-width: 800px) {
          align-self: center;
          margin-right: 0;
        }
      }

      &__text {
        padding: 25px;
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 125%;

        p,
        ul {
          margin-top: 10px;
        }

        li {
          list-style-type: disc;
          margin-left: 15px;
        }

        b {
          font-weight: 700;
        }
      }
    }
  }
`;
