import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CxSolutionPartnersStyles } from './CxSolutionPartnersStyles';
import { partnersGreenTheme } from './utils';
import Marquee from 'react-fast-marquee';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CxSolutionPartners = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [data, setData] = useState<{ static: string; hover: string; alt: string }[]>();

  useEffect(() => {
    setData([
      ...partnersGreenTheme,
      ...partnersGreenTheme,
      ...partnersGreenTheme,
    ]);
  }, []);

  return (
    <CxSolutionPartnersStyles>
      <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_19')}</h1>
      <div className="partner-quee">
        {data && (
          <Marquee speed={window.innerWidth > 1000 ? 100 : 120}>
            {data.map((item, index) => (
              <div className="logoWrapper" key={item.alt}>
                <img src={item.static} alt={item.alt} />
                <img src={item.hover} alt={item.alt} />
              </div>
            ))}
          </Marquee>
        )}
      </div>
      <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_20')}</h2>
    </CxSolutionPartnersStyles>
  );
};
