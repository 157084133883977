import styled from 'styled-components';

export const OpportunitiesPartnershipStyles = styled.div`
  padding: 100px 0;
  background: #fff;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  .text-wrapper {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    h1 {
      font-weight: 600;
      font-size: 60px;
      line-height: 115%;
      color: #0f0e0c;

      @media (max-width: 768px) {
        font-size: 32px;
        text-align: center;
      }
    }

    .text-info {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 125%;
        color: #292929;

        @media (max-width: 768px) {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }

  .circles-wrapper {
    position: relative;
    width: 100%;
    margin-top: 20px;
    height: 700px;

    .partnership {
      position: absolute;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 768px) {
        max-width: 150px;
        max-height: 150px;
      }

      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 125%;
        text-align: center;
        color: #000000;

        @media (max-width: 991px) {
          font-size: 14px;
        }
      }

      b {
        font-weight: 700;
        font-size: 18px;
        line-height: 125%;
        text-align: center;
        color: #000000;

        @media (max-width: 991px) {
          font-size: 14px;
        }
      }

      &:nth-child(1) {
        left: 40%;
        top: 5%;

        @media (max-width: 768px) {
          top: 0;
          left: 60%;
        }
      }
      &:nth-child(2) {
        left: 80%;
        top: 25%;

        @media (max-width: 768px) {
          top: 50%;
          left: 11%;
        }
      }
      &:nth-child(3) {
        left: 15%;
        top: 75%;

        @media (max-width: 768px) {
          top: 70%;
          left: 60%;
        }
      }
      &:nth-child(4) {
        left: 55%;
        top: 95%;
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        box-sizing: border-box;
        padding: 30px;
        gap: 10px;
        width: 260px;
        height: 260px;
        background: #ffffff;
        border: 3px solid #0000ff;
        border-radius: 50%;

        @media (max-width: 991px) {
          width: 200px;
          height: 200px;
        }

        @media (max-width: 768px) {
          width: 150px;
          height: 150px;
        }
      }
      &:nth-child(10) {
        p,
        b {
          color: #fff !important;
        }

        background: #0000ff;
      }

      &:nth-child(5) {
        top: 40%;
        left: 0;

        @media (max-width: 768px) {
          top: 0;
          left: 10%;
        }
      }
      &:nth-child(6) {
        top: 28%;
        left: 23%;

        @media (max-width: 768px) {
          top: 25%;
          left: 8%;
        }
      }
      &:nth-child(7) {
        top: 57%;
        left: 41%;

        @media (max-width: 768px) {
          top: 25%;
          left: 58%;
        }
      }
      &:nth-child(8) {
        top: 75%;
        left: 70%;

        @media (max-width: 768px) {
          top: 50%;
          left: 58%;
        }
      }
      &:nth-child(9) {
        top: 40%;
        left: 80%;

        @media (max-width: 768px) {
          top: 62%;
          left: 11%;
        }
      }
      &:nth-child(10) {
        top: 25%;
        left: 55%;

        @media (max-width: 768px) {
          top: 86%;
          left: 11%;
        }
      }
    }
  }
`;
