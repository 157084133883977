import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { BrandAwarenessUsageStyles } from './styles';
import OurClients from '../../components/ourClients/OurClients';
import ProductsTabs from '../../components/productsTabs/ProductsTabs';
import { productsTabsData } from '../../constants';
import {
  BrandAwarenessUsageAbout,
  BrandAwarenessUsageAttribute,
  BrandAwarenessUsageBrands,
  BrandAwarenessUsageClientMethod,
  BrandAwarenessUsageExperience,
  BrandAwarenessUsageIntro,
  BrandAwarenessUsageNeeds,
  BrandAwarenessUsageOpportunities,
  BrandAwarenessUsageOurMethod,
  BrandAwarenessUsageSlider,
  BrandAwarenessUsageSolutions,
} from '../../components';

export const BrandAwarenessUsage = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandAwarenessUsageStyles>
      <BrandAwarenessUsageIntro />
      <BrandAwarenessUsageAbout />
      <BrandAwarenessUsageExperience />
      <BrandAwarenessUsageOpportunities />
      <BrandAwarenessUsageSolutions />
      <BrandAwarenessUsageOurMethod />
      <BrandAwarenessUsageClientMethod />
      <BrandAwarenessUsageAttribute />
      <BrandAwarenessUsageBrands />
      <BrandAwarenessUsageSlider />
      <BrandAwarenessUsageNeeds />
      <OurClients theme="white" />
      <ProductsTabs
        data={productsTabsData(interfaceLanguage)}
        theme="lightTheme"
      />
    </BrandAwarenessUsageStyles>
  );
};
