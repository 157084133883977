import React from 'react';

function LinkedinIcon({
  width = 29,
  height = 29,
  color = '#fff',
  circleColor = 'FFF',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill={color}
        d="M8.864 12.544h1.872V19H8.864v-6.456zm.936-.9c-.344 0-.624-.1-.84-.3a.973.973 0 01-.324-.744c0-.296.108-.544.324-.744.216-.2.496-.3.84-.3s.624.096.84.288a.923.923 0 01.324.72c0 .312-.108.572-.324.78-.216.2-.496.3-.84.3zm6.333.804c.8 0 1.444.24 1.932.72.496.48.744 1.192.744 2.136V19h-1.872v-3.408c0-.512-.112-.892-.336-1.14-.224-.256-.548-.384-.972-.384-.472 0-.848.148-1.128.444-.28.288-.42.72-.42 1.296V19H12.21v-6.456h1.788v.756c.248-.272.556-.48.924-.624a3.145 3.145 0 011.212-.228z"
      />
      <circle cx="14.5" cy="14.5" r="14" stroke={circleColor} />
    </svg>
  );
}

export default LinkedinIcon;
