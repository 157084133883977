import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesTargetStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { cardsData } from './utils';

export const OpportunitiesTarget = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesTargetStyles>
      <h1>
        {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_35')}
      </h1>
      <div className="container">
        {cardsData(interfaceLanguage).map((card) => (
          <div className="card">
            <p>{card.name}</p>
            <img src={card.img} loading="lazy" alt="" />
            <h6>{card.description}</h6>
          </div>
        ))}
      </div>
    </OpportunitiesTargetStyles>
  );
};
