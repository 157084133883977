import React, { lazy, Suspense, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { availableLanguages } from './constants/LANGUAGES';
import { setInterfaceLanguage } from './state/slices/languageSlice';
import { MoonLoader } from 'react-spinners';
import { useCookies } from 'react-cookie';
import './App.css';

import { Layout } from './components/layout/Layout';
import Cases from './pages/cases/Cases';
import {
  AdHocMain,
  BrandAwarenessUsage,
  BrandIdentity,
  ConsumerJourneyMap,
  CustomerProfilingSegmentation,
  FacilityCheckAudit,
  FourOpinion,
  MarketResearch,
  Methodologies,
  MolCongratulations,
  NotFound,
  Opportunities,
  Products,
  ServiceDesign,
  SociologicalResearch,
  ThankYouPartner,
  UsageAttitude,
  YearGoodDeeds,
  СxSolution,
  Career,
  Vacancy,
} from './pages';

const Main = lazy(() => import('./pages/main/Main'));
const AboutUs = lazy(() => import('./pages/aboutUs/AboutUs'));
const SocialResponsibility = lazy(
  () => import('./pages/socialResponsibility/SocialResponsibility'),
);
const MysteryShopping = lazy(
  () => import('./pages/mysteryShopping/MysteryShopping'),
);
const NetPromotionScore = lazy(
  () => import('./pages/netPromotionScore/NetPromotionScore'),
);
const CSI = lazy(() => import('./pages/CSI/CSI'));
const MethodsCJM = lazy(() => import('./pages/methodsCJM/MethodsCJM'));
const MethodsHr = lazy(() => import('./pages/methodsHR/MethodsHR'));
const Bht = lazy(() => import('./pages/BHT/BHT'));
const QualitativeResearches = lazy(
  () => import('./pages/qualitativeResearches/QualitativeResearches'),
);
const QuantitativeResearches = lazy(
  () => import('./pages/quantitativeResearch/QuantitativeResearches'),
);

const Wantent = lazy(() => import('./pages/wantent/Wantent'));
const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy/PrivacyPolicy'));
const Terms = lazy(() => import('./pages/terms/Terms'));
const ThankYou = lazy(() => import('./pages/thankYou/ThankYou'));
const UxTesting = lazy(() => import('./pages/uxTesting/UXTesting'));
const CookieBar = lazy(() => import('./components/cookieBar/CookieBar'));
const PriceMonitoring = lazy(
  () => import('./pages/priceMonitoring/PriceMonitoring'),
);
const Blog = lazy(() => import('./pages/blog/Blog'));
const Post = lazy(() => import('./pages/blog/post/Post'));
const Contacts = lazy(() => import('./pages/contacts/Contacts'));
const AdTesting = lazy(() => import('./pages/adTesting/AdTesting'));
const AutomotiveIndustryPriceAnalysis = lazy(
  () => import(
    './pages/automotiveIndustryPriceAnalysis/AutomotiveIndustryPriceAnalysis'
  ),
);
const RetailAudit = lazy(() => import('./pages/retailAudit/RetailAudit'));

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const dispatch = useAppDispatch();

  const [cookies] = useCookies<string>(['cookieAccept']);

  useEffect(() => {
    const splittedPathName = pathname.split('/');

    if (
      splittedPathName[1] !== interfaceLanguage
      && availableLanguages.includes(splittedPathName[1])
    ) {
      // @ts-ignore
      dispatch(setInterfaceLanguage(splittedPathName[1]));
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname.substr(-1) === '/') {
      const newPath = pathname.slice(0, -1);
      navigate(newPath);
    }
  }, [pathname, navigate]);

  useEffect(() => {
    if (document.body.style.overflowY === 'hidden') {
      document.body.style.overflowY = 'scroll';
    }
  }, [pathname]);

  useEffect(() => {
    document.documentElement.lang = interfaceLanguage;
  }, [interfaceLanguage]);

  return (
    <Suspense
      fallback={(
        <div className="spinner">
          <MoonLoader color="#000" size={100} />
        </div>
      )}
    >
      {/* {!cookies.cookieAccept && <CookieBar />} */}
      <Routes>
        {availableLanguages.map((lang) => (
          <Route key={lang} path={`/${lang}`} element={<Layout />}>
            <Route path="" element={<Main />} />
            <Route path="about" element={<AboutUs />} />
            <Route
              path="social-responsibility"
              element={<SocialResponsibility />}
            />
            <Route path="mystery-shopping" element={<MysteryShopping />} />
            <Route path="net-promoter-score" element={<NetPromotionScore />} />
            <Route path="customer-satisfaction-index" element={<CSI />} />
            <Route path="customer-journey-map" element={<MethodsCJM />} />
            <Route path="hr-research" element={<MethodsHr />} />
            <Route path="brand-health-tracking" element={<Bht />} />
            <Route
              path="qualitative-research"
              element={<QualitativeResearches />}
            />
            <Route
              path="quantitative-research"
              element={<QuantitativeResearches />}
            />
            <Route path="retail-audit" element={<RetailAudit />} />
            <Route
              path="facility-check-audit"
              element={<FacilityCheckAudit />}
            />
            <Route path="wantent" element={<Wantent />} />
            <Route path="ux-testing" element={<UxTesting />} />
            <Route path="ad-testing" element={<AdTesting />} />
            <Route path="price-monitoring" element={<PriceMonitoring />} />
            <Route
              path="automotive-industry-price-analysis"
              element={<AutomotiveIndustryPriceAnalysis />}
            />
            <Route path="cx" element={<СxSolution />} />
            <Route path="u&a" element={<UsageAttitude />} />
            <Route path="partnership" element={<Opportunities />} />
            <Route path="brand-awareness-usage" element={<BrandAwarenessUsage />} />
            <Route path="consumer-journey-map" element={<ConsumerJourneyMap />} />
            <Route path="customer-profiling-and-segmentation" element={<CustomerProfilingSegmentation />} />
            <Route path="brand-identity-and-positioning" element={<BrandIdentity />} />
            <Route path="sociological-research" element={<SociologicalResearch />} />
            <Route path="adhoc" element={<AdHocMain />} />
            <Route path="service-design" element={<ServiceDesign />} />
            <Route path="4opinion" element={<FourOpinion />} />
            <Route path="career" element={<Career />} />
            <Route path="career/:vacancy" element={<Vacancy />} />

            <Route path="blog" element={<Blog />} />
            <Route path="blog/:postName" element={<Post />} />
            <Route
              path="blog/2023-is-the-year-of-good-deeds"
              element={<YearGoodDeeds />}
            />

            <Route path="cases" element={<Cases />} />
            <Route path="cases/:postName" element={<Post />} />

            <Route path="contacts" element={<Contacts />} />

            <Route path="methodologies" element={<Methodologies />} />
            <Route path="market-research" element={<MarketResearch />} />
            <Route path="products" element={<Products />} />

            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-conditions" element={<Terms />} />
            <Route path="thank-you" element={<ThankYou />} />
            <Route path="thank-you-partner" element={<ThankYouPartner />} />
            <Route path="404" element={<NotFound />} />
          </Route>
        ))}

        <Route path="reviews-mol" element={<MolCongratulations />} />
        <Route path="/" element={<Navigate to={`/${interfaceLanguage}`} />} />
        <Route path="/*" element={<Navigate to={`/${interfaceLanguage}/404`} />} />
      </Routes>
    </Suspense>
  );
}

export default App;
