import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditReportsStyles } from './FacilityCheckAuditReportsStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const FacilityCheckAuditReports = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditReportsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_18',
          )}
        </h5>
        <div className="content">
          <h4>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_19',
            )}
          </h4>
          <p>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_20',
            )}
          </p>
        </div>
      </div>
      <div className="cards">
        <div className="card">
          <span>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_21',
            )}
          </span>{' '}
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_22',
          )}
        </div>
        <div className="card">
          <span>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_23',
            )}
          </span>{' '}
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_24',
          )}
        </div>
      </div>
    </FacilityCheckAuditReportsStyles>
  );
});
