import styled from 'styled-components';

export const OpportunitiesDescriptionStyles = styled.div`
  padding: 100px 0;
  background: #000;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    text-align: center;
    color: #ffffff;
    max-width: 1190px;
    margin: 0 auto;

    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
    }

    span {
      color: #0000ff;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
  }

  button {
    margin-top: 25px;
    border: 1px solid #0000ff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease all;
    padding: 14px 25px;
    gap: 10px;
    width: fit-content;
    background: #0000ff;
    border-radius: 100px;

    &:hover {
      background: #fff;
      color: #0000ff;
    }

    @media (max-width: 991px) {
      padding: 14px 48px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;
