import styled from 'styled-components';

export const NotFoundStyles = styled.div`
    font-family: 'Montserrat';
    background: #000;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
        max-width: 1190px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        margin: 0 auto;
        position: relative;
        gap: 80px;
    }

    .not-found {
        &__info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 35px;
            width: 100%;
            position: relative;

            @media (max-width: 991px) {
                gap: 15px;
            }

            h1 {
                font-weight: 700;
                font-size: 289px;
                line-height: 115%;
                text-align: center;
                background: linear-gradient(0deg, rgba(0, 0, 255, 0) 7.98%, #0000FF 119.43%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                position: absolute;
                left: 50%;
                top: -290px;
                transform: translate(-50%);

                @media (max-width: 768px) {
                    font-size: 124px;
                    top: -90px;
                }
            }

            h2 {
                font-weight: 700;
                font-size: 56px;
                line-height: 115%;
                text-align: center;
                color: #FFFFFF;
                position: relative;
                z-index: 4;

                @media (max-width: 768px) {
                    font-size: 36px;
                }
            }

            h3 {
                font-weight: 500;
                font-size: 28px;
                line-height: 115%;
                text-align: center;
                color: #FFFFFF;
                position: relative;
                z-index: 4;

                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }
        }

        &__btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 100px;

            @media (max-width: 991px) {
                gap: 50px;
            }

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 25px;
            }
        }
    }

    .products {
        padding: 14px 50px;
        background: #0000FF;
        border-radius: 100px;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;
        text-align: center;

        @media (max-width: 768px) {
            order: 2;
        }
    }

    .contacts {
        padding: 14px 50px;
        background: #CBCBCB;
        border-radius: 100px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #0F0E0C;

        @media (max-width: 768px) {
            order: 1;
        }
    }
`;
