import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { VacanciesCareerStyles } from './styles';
import { ApiVacancy } from '../../../api/entities/ApiVacancies';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { AdminApi } from '../../../api/adminApi';
import { Geo } from '../../../assets/icons/vacancies/Geo';
import { Link } from 'react-router-dom';
import { Arrow } from '../../../assets/icons/vacancies/Arrow';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const VacanciesCareer = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [vacancies, setVacancies] = useState<ApiVacancy[]>([]);
  const [limit, setLimit] = useState<number>(3);

  const vacanciesCount = useRef<number>(0);

  useEffect(() => {
    AdminApi.getVacancies({}, limit, 0).then((res) => {
      if (res.statusCode === 200) {
        vacanciesCount.current = res.data.count;
        setVacancies(res.data.data);
      }
    });
  }, [limit]);

  return (
    <VacanciesCareerStyles id="vacancies">
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'career_15')}</h2>
        <div className="list">
          {vacancies.map((el) => (
            <div className="vacancy" key={el.id}>
              <h5>{el.name}</h5>
              <div className="vacancy__body">
                <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'career_16')}</h6>
                <ul>
                  {el.tasks.map((task) => (
                    <li key={task}>{task}</li>
                  ))}
                </ul>
              </div>
              <div className="vacancy__utils">
                <Geo />
                <p>{el.country}</p>
                <p>{el.type}</p>
              </div>

              <Link to={`/${interfaceLanguage}/career/${el.id}`} className="vacancy__link">
                <Arrow />
              </Link>
            </div>
          ))}
          {vacanciesCount.current >= 3 && vacancies.length !== vacanciesCount.current && (
            <ButtonWithArrowOnHover
              onClick={() => {
                setLimit(50);
              }}
              arrowColor="#fff"
              textColor="#fff"
              borderColor="#fff"
              borderOnHover="#fff"
              background="transparent"
              hoverBackground="transparent"
              hoverTextColor="#fff"
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'career_4',
              )}
            />
          )}
        </div>
      </div>
    </VacanciesCareerStyles>
  );
};
