import React from 'react';

function InstagramIcon({
  width = 29,
  height = 29,
  color = '#fff',
  circleColor = 'FFF',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 29 29"
    >
      <circle cx="14.5" cy="14.5" r="14" stroke={circleColor} />
      <path
        stroke={color}
        d="M7.5 11A3.5 3.5 0 0111 7.5h7a3.5 3.5 0 013.5 3.5v7a3.5 3.5 0 01-3.5 3.5h-7A3.5 3.5 0 017.5 18v-7z"
      />
      <path stroke={color} d="M11.5 14.5a3 3 0 116 0 3 3 0 01-6 0z" />
      <path fill={color} d="M17 11a1 1 0 112 0 1 1 0 01-2 0z" />
    </svg>
  );
}

export default InstagramIcon;
