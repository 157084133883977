import React from 'react';
import { CaseItemStyles } from './CaseItemStyles';
import { Link } from 'react-router-dom';
import ArrowRightSmall from '../../assets/icons/ArrowRightSmall';
import ArrowRight from '../../assets/icons/ArrowRight';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { useAppSelector } from '../../state/hooks';

import defaultImage from '../../assets/images/image-not-found.jpg';

interface Props {
  title: string;
  image?: string;
  link: string;
  // captions: string[],
  // hashtags: string[],
  className?: string;
}

function CaseItem({
  title, link, image, className,
}: Props) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CaseItemStyles
      className={className ? `${className} caseItem` : 'caseItem'}
    >
      <Link to={link}>
        <div className="headContainer">
          <img src={image || defaultImage} alt={title} />
        </div>

        <div className="textContainer">
          <p>{title.length > 120 ? `${title.slice(0, 120)}...` : title}</p>
          <div className="hashtagAndButtonWrapper">
            <div className="button">
              <span>
                {getTranslationsByLangOrEng(interfaceLanguage, 'more')}
              </span>
              <ArrowRightSmall color="#FFF" height={12} width={12} />
            </div>
          </div>
        </div>
      </Link>
    </CaseItemStyles>
  );
}

export default CaseItem;
