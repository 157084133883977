import React from 'react';
import { MoonLoader } from 'react-spinners';
import { LoaderStyles } from './LoaderStyles';

function Loader() {
  return (
    <LoaderStyles className="loader">
      <MoonLoader color="#FFF" size={100} />
    </LoaderStyles>
  );
}

export default Loader;
