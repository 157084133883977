import styled from 'styled-components';

export const ConsumerJourneyMapSliderStyles = styled.div`
  width: 100%;
  margin-top: 100px !important;

  @media (max-width: 991px) {
    margin-top: 50px !important;
  }

  .sliderWrapper {
    margin-top: 50px;
  }

  .slide {
    max-width: 770px;
    height: 550px;
    width: 100%;
    border-radius: 10px 10px;
    border: 5px solid #F15967;
    position: relative;
    overflow: hidden;
    background: #fff;
    flex-shrink: 0;

    @media (max-width: 768px) {
      max-width: 320px;
      height: 380px;
    }

    &-img {
      height: 50%;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;

        background: linear-gradient(278.4deg, rgba(253, 162, 114, 0.14) 3.59%, rgba(241, 89, 103, 0.48) 100.57%);
        border-radius: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: cover;
        z-index: 1;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      padding: 30px;
      gap: 20px;
      height: 70%;

      @media (max-width: 768px) {
        padding: 10px 15px;
        gap: 10px;
      }

      h5 {
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        color: #000;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 115%;
        color: #000000;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .btn-wrapper {
    max-width: 1190px;
    margin: 50px auto 0;
  }
`;
