import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const AdHocSampleStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .texts {
    width: 100%;
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (max-width: 768px) {
      gap: 25px;
    }
  }

  .cards {
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: space-around;
    flex-wrap: wrap;

    &__item {
      max-width: 260px;
      width: 100%;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #fff;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(180deg, #FCC666 30%, #FA1A2E 50%, #5CB7F1 70%);
        border-radius: 10px;
        padding: 3px;
        -webkit-mask: 
          linear-gradient(#fff 0 0) content-box, 
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

      img {
        height: 130px;
      }

      &-text {
        height: 260px;
        display: flex;
        flex-direction: column;
        gap: 19px;
        justify-content: center;
        padding: 0 15px;

        h6 {
          color: #0F0E0C;
          font-size: 20px;
          font-weight: 700;
          margin: 0;
          line-height: 125%;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          color: #000000;
        }
      }
    }
  }
`;
