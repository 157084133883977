import React, { FC, useCallback, useRef } from 'react';

type TCustomCheckbox = {
  name: string;
  value: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export const CustomCheckbox: FC<TCustomCheckbox> = ({ name, value, onChange }) => {
  const inputRef = useRef(null);

  return (
    <div className="checkbox">
      <label className="checkbox__wrapper" htmlFor={name}>
        {value && <div className="checkbox__mark" />}
        <input
          className="checkbox__default"
          type="checkbox"
          id={name}
          checked={value}
          ref={inputRef}
          onChange={onChange}
        />
      </label>
    </div>
  );
};
