import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { ConsumerJourneyMapStyles } from './styles';
import {
  ConsumerJourneyMapAbout,
  ConsumerJourneyMapBenefit,
  ConsumerJourneyMapDiscover,
  ConsumerJourneyMapExperience,
  ConsumerJourneyMapExperts,
  ConsumerJourneyMapIntro,
  ConsumerJourneyMapMap,
  ConsumerJourneyMapSlider,
  ConsumerJourneyMapSolution,
  ConsumerJourneyMapTrustedBy,
} from '../../components';

export const ConsumerJourneyMap = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ConsumerJourneyMapStyles>
      <ConsumerJourneyMapIntro />
      <ConsumerJourneyMapAbout />
      <ConsumerJourneyMapExperience />
      <ConsumerJourneyMapDiscover />
      <ConsumerJourneyMapMap />
      <ConsumerJourneyMapExperts />
      <ConsumerJourneyMapSlider />
      <ConsumerJourneyMapBenefit />
      <ConsumerJourneyMapSolution />
      <ConsumerJourneyMapTrustedBy />
    </ConsumerJourneyMapStyles>
  );
};
