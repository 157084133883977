import styled from 'styled-components';

export const SociologicalResearchSliderSociologicalStyles = styled.div`
  width: 100%;
  padding: 100px 0;

  @media (max-width: 991px) {
     padding: 50px 0;
  }

  .sliderWrapper {
    margin-top: 50px;
  }

  .texts {
    max-width: 770px;
    width: 100%;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .slide {
    max-width: 770px;
    height: 430px;
    width: 100%;
    padding: 8px 0;
    position: relative;
    background: #fff;
    flex-shrink: 0;

    @media (max-width: 768px) {
      max-width: 320px;
      height: 200px;
    }

    a {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 4px solid #F05A25;
      display: block;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .btn-wrapper {
    max-width: 1190px;
    margin: 50px auto 0;
  }
`;
