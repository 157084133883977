import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { HoldingCareerStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import holding1 from '../../../assets/images/career/holding-1.png';
import holding2 from '../../../assets/images/career/holding-2.png';
import holding3 from '../../../assets/images/career/holding-3.png';
import { Link } from 'react-router-dom';

export const HoldingCareer = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <HoldingCareerStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'career_20')}</h2>
        <div className="cards">
          <div className="cards-item">
            <img src={holding1} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'career_21')}</h6>
              <div className="cards-utils">
                <Link to={`/${interfaceLanguage}/about`}>{getTranslationsByLangOrEng(interfaceLanguage, 'career_22')}</Link>
              </div>
            </div>
          </div>
          <div className="cards-item">
            <img src={holding2} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'career_23')}</h6>
              <div className="cards-utils">social-responsibility
                <Link to={`/${interfaceLanguage}/social-responsibility`}>{getTranslationsByLangOrEng(interfaceLanguage, 'career_24')}</Link>
              </div>
            </div>
          </div>
          <div className="cards-item">
            <img src={holding3} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'career_25')}</h6>
              <div className="cards-utils">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    const elem = document.getElementById('vacancies');
                    elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
                  }}
                >
                  {getTranslationsByLangOrEng(interfaceLanguage, 'career_26')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HoldingCareerStyles>
  );
};
