import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const SociologicalResearchClientsStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .texts {
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    p {
      color: #000;
      font-size: 28px;
      font-weight: 400;
      line-height: 125%;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .btn {
    align-self: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .cards {
    display: flex;
    gap: 30px;
    justify-content: space-around;
    flex-wrap: wrap;

    &__item {
      box-sizing: border-box;
      max-width: 370px;
      width: calc(33% - 15px);
      height: 240px;
      border-radius: 10px;
      border: 3px solid #f05a25;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;
      align-items: center;

      @media (max-width: 991px) {
        width: calc(50% - 15px);
      }

      @media (max-width: 768px) {
        width: 100%;
        height: 160px;
      }

      svg {
        max-width: 85px;

        @media (max-width: 768px) {
          max-width: 55px;
        }
      }

      p {
        color: #000;
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        line-height: 125%;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
`;
