import styled from 'styled-components';

export const FacilityCheckAuditSliderStyles = styled.div`
  .sliderWrapper {
    margin-top: 30px;
  }

  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    height: auto;
    width: 80%;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid var(--Gradient-Check-Audit, #9e00ff);
    border-radius: 5px;
    max-width: 1190px;

    img {
      width: 100%;
    }
  }
`;
