import styled from 'styled-components';

export const CustomerProfilingSegmentationExperienceStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }
`;
