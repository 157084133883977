import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { SociologicalResearchPrinciplesStyles } from './styles';
import principles1 from '../../../assets/images/sociologicalResearch/principles-1.png';
import principles2 from '../../../assets/images/sociologicalResearch/principles-2.png';
import principles3 from '../../../assets/images/sociologicalResearch/principles-3.png';

export const SociologicalResearchPrinciples = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchPrinciplesStyles>
      <div className="container">
        <div className="content">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_39')}</h1>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_40')}</p>
          <div className="logos">
            <img src={principles1} alt="" />
            <img src={principles2} alt="" />
            <img src={principles3} alt="" />
          </div>
        </div>
      </div>
    </SociologicalResearchPrinciplesStyles>
  );
};
