import styled from 'styled-components';

export const OpportunitiesCXStyles = styled.div`
  padding: 100px 0;
  background: #0000ff;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    text-align: center;
    color: #fff;
    max-width: 1190px;
    margin: 0 auto;

    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
    }

    span {
      color: #0000ff;
    }
  }

  .container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-around;
    flex-wrap: wrap;

    .count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 10px 20px;
      max-width: 350px;
      width: 100%;
      background: #ffffff;
      border-radius: 5px;
      height: 150px;

      h2 {
        font-weight: 600;
        font-size: 50px;
        line-height: 125%;
        text-align: center;
        color: #0d0d0d;

        @media (max-width: 768px) {
          font-size: 60px;
        }
      }
      p {
        font-weight: 600;
        font-size: 22px;
        line-height: 125%;
        text-align: center;
        color: #0d0d0d;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
`;
