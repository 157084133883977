import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { NotFoundCareerStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import { activateBanner } from '../../../state/slices/vacanciesSlice';

export const NotFoundCareer = () => {
  const dispatch = useAppDispatch();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <NotFoundCareerStyles>
      <div className="container">
        <div className="not-found__text">
          <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'career_5')}</h2>
          <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'career_6')}</h6>
        </div>
        <ButtonWithArrowOnHover
          onClick={() => {
            dispatch(activateBanner({ activeBanner: true }));
          }}
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#fff"
          borderOnHover="#fff"
          background="transparent"
          hoverBackground="transparent"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'career_7',
          )}
        />
      </div>
    </NotFoundCareerStyles>
  );
};
