import styled from 'styled-components';

export const Expert4OpinionStyles = styled.div`
  background: #fff;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 70px 10px;
    z-index: 5;

    @media (max-width: 768px) {
      gap: 30px;
      padding: 30px 10px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 125%;
    text-align: center;
    color: #0f0e0c;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 80px;
    width: 100%;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }

    img {
      max-width: 500px;
      width: 100%;
      flex-shrink: 0;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;

    &-item {
      display: flex;
      align-items: center;
      gap: 25px;
      position: relative;

      h6 {
        margin: 0;
        font-weight: 700;
        font-size: 96px;
        line-height: 125%;
        color: rgba(0, 0, 255, 0.5);

        @media (max-width: 768px) {
          font-size: 64px;
        }
      }

      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 125%;
        color: #000000;

        @media (max-width: 768px) {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
`;
