import styled from 'styled-components';

export const AdHocExperienceStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #FFF;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    h6 {
      font-size: 28px;
      font-weight: 400;
      line-height: 125%;
      background: linear-gradient(91deg, #FCC666 -9.82%, #FA1A2E 10.3%, #5CB7F1 30.41%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .gradientimg {
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(106deg, rgba(252, 198, 102, 0.80) -12.03%, rgba(250, 26, 46, 0.60) 51.54%, #5CB7F1 115.1%);
      z-index: 2;
    }

    img {
      width: 100%;
    }
  }

  .content {
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
`;
