import styled from 'styled-components';

export const FooterStyles = styled.footer`
  padding: 30px 0 91px;

  @media (max-width: 700px) {
    padding: 33px 0 45px;
  }

  .footerContent {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .text16 {
      text-align: center;
      width: 100%;
    }

    @media (max-width: 700px) {
      display: flex;
      flex-direction: column;
    }

    .footerBtns {
      display: flex;
      flex-direction: column;
      gap: 25px;

      @media (max-width: 700px) {
        order: 1;
        gap: 15px;
      }

      .footerBtn {
        width: 100%;

        a {
          padding: 0 30px;
        }
      }
    }

    .logoAndPartnersWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 700px) {
        flex-direction: row;
        justify-content: flex-start;
        order: 2;
      }

      .logoContainer {
        position: relative;
        width: fit-content;
        height: 118px;

        @media (max-width: 900px) {
          width: 64px;
          height: 64px;
        }

        svg:first-child {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @media (max-width: 900px) {
            width: 22px;
          }
        }

        svg:last-child {
          animation: 42s linear infinite rotate;

          @media (max-width: 900px) {
            width: 64px;
            height: 64px;
          }
        }

        @media (max-width: 768px) {
          margin-right: 32px;
        }

        @keyframes rotate {
          from {
            transform: rotate(0);
          }

          to {
            transform: rotate(360deg);
          }
        }
      }

      .partnersContainer {
        display: grid;
        gap: 6px;

        @media (max-width: 768px) {
          height: min-content;
          gap: 14px;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: rgba(255, 255, 255, 0.6);
        }

        div {
          display: flex;
          gap: 20px;

          img {
            height: 40px;

            @media (max-width: 900px) {
              height: 23px;
            }

            @media (max-width: 700px) {
              height: 30px;
            }
          }
        }
      }
    }

    .navAndPrivacyPolicyWrapper {
      max-width: 700px;
      width: 100%;

      @media (max-width: 700px) {
        order: 3;
      }

      .privacyPolicyWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        @media (max-width: 700px) {
          justify-content: flex-start;
        }

        .socialLinksContainer {
          display: flex;
          gap: 16px;

          svg:not(:last-child) {
            margin-right: 15px;
          }

          @media only screen and (max-width: 900px) and (min-width: 701px) {
            svg {
              width: 20px;
              height: 20px;
            }
          }

          @media (max-width: 700px) {
            margin-right: 15px;
          }

          .iconWrapper {
            height: 29px;
            border-radius: 100%;
            transition: 0.3s all;
          }
        }

        .privacyPolicyContainer {
          display: flex;
          align-items: center;

          @media (max-width: 700px) {
            display: grid;
          }

          a {
            position: relative;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.35em;
            transition: 0.3s all;

            @media only screen and (max-width: 900px) and (min-width: 701px) {
              font-size: 7px;
            }

            span {
              position: relative;

              &::after {
                content: '  >';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                transition: 0.3s all;
                font-size: 12px;
                //position: absolute;
                right: -5px;

                @media only screen and (max-width: 900px) and (min-width: 701px) {
                  font-size: 7px;
                }

                @media (max-width: 700px) {
                  font-size: 9px;
                }
              }
            }

            @media (max-width: 700px) {
              font-size: 10px;
            }
          }

          a:first-child {
            margin-right: 30px;

            @media (max-width: 900px) {
              margin-right: 0;
            }
          }
        }
      }
    }

    .footerNavWrapper {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 26px;

      @media (max-width: 700px) {
        flex-wrap: wrap;
        gap: 25px 10px;
      }

      .footer-link {
        font-weight: 700 !important;
        font-size: 16px !important;
        margin-bottom: 5px !important;
        line-height: 150% !important;
        cursor: pointer;
      }

      .otherList {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.022em;

        @media (max-width: 900px) {
          font-size: 8px;
        }

        @media (max-width: 700px) {
          width: calc(50% - 10px);
        }

        .active {
          a {
            color: #0000ff;
          }
        }

        li:not(:last-child) {
          margin-bottom: 5px;
        }

        li:hover {
          text-decoration: underline;
        }

        @media (max-width: 700px) {
          font-size: 11px;
        }
      }

      .MethodsListWrapper,
      .ProductsListWrapper,
      .MarketResearchListWrapper {
        display: flex;
        flex-direction: column;

        font-weight: 700;
        font-size: 16px;
        line-height: 150%;

        @media (max-width: 900px) {
          font-size: 8px;
        }

        @media (max-width: 700px) {
          font-size: 11px;
          width: calc(50% - 10px);
        }

        .active {
          a {
            color: #0000ff;
          }

          color: #0000ff;
        }

        span {
          margin-bottom: 5px;
        }

        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 115%;
          transition: 0.3s all;

          @media (max-width: 900px) {
            font-size: 6px;
          }

          @media (max-width: 700px) {
            font-size: 11px;
          }
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.darkTheme {
    background: #000;

    .footerContent {
      .navAndPrivacyPolicyWrapper {
        .privacyPolicyWrapper {
          .privacyPolicyContainer {
            a {
              color: rgba(255, 255, 255, 0.5);
            }

            a:hover {
              color: #fff;

              &::after {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .socialLinksWrapper {
      display: flex;
      flex-direction: column;

      .contacts {
        display: grid;

        margin-bottom: 8px;
        gap: 4px;

        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 115%;
          color: rgba(255, 255, 255, 0.6);

          @media (max-width: 1000px) {
            font-size: 8px;
          }

          @media (max-width: 700px) {
            font-size: 11px;
          }
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: rgba(255, 255, 255, 0.6);

          @media (max-width: 1000px) {
            font-size: 8px;
          }

          @media (max-width: 700px) {
            font-size: 11px;
          }
        }
      }
    }

    .socialLinksContainer {
      display: flex;
      align-items: center;

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: #0000ff;
        }
      }
    }

    .footerNavWrapper {
      color: #fff;

      .otherList {
        a {
          color: #ffffff;
        }
      }

      .MethodsListWrapper,
      .ProductsListWrapper,
      .MarketResearchListWrapper {
        color: #ffffff;

        a {
          color: rgba(255, 255, 255, 0.7);

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  &.greenTheme {
    background: #000;

    .footerContent {
      .navAndPrivacyPolicyWrapper {
        .privacyPolicyWrapper {
          .privacyPolicyContainer {
            a {
              color: rgba(255, 255, 255, 0.5);
            }

            a:hover {
              color: #fff;

              &::after {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .socialLinksWrapper {
      display: flex;
      flex-direction: column;

      .contacts {
        display: grid;

        margin-bottom: 8px;
        gap: 4px;

        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 115%;
          color: rgba(255, 255, 255, 0.6);

          @media (max-width: 1000px) {
            font-size: 8px;
          }

          @media (max-width: 700px) {
            font-size: 11px;
          }
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: rgba(255, 255, 255, 0.6);

          @media (max-width: 1000px) {
            font-size: 8px;
          }

          @media (max-width: 700px) {
            font-size: 11px;
          }
        }
      }
    }

    .socialLinksContainer {
      display: flex;
      align-items: center;

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: #02ba95;
        }
      }
    }

    .footerNavWrapper {
      color: #fff;

      .otherList {
        a {
          color: #ffffff;
        }
      }

      .MethodsListWrapper,
      .ProductsListWrapper,
      .MarketResearchListWrapper {
        color: #ffffff;

        a {
          color: rgba(255, 255, 255, 0.7);

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  &.lightTheme {
    background: #fff;

    .iconWrapper {
      &:hover {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .footerContent {
      .navAndPrivacyPolicyWrapper {
        .privacyPolicyWrapper {
          .privacyPolicyContainer {
            a {
              color: rgba(0, 0, 0, 0.5);
            }

            a:hover {
              color: rgba(0, 0, 0, 1);

              &::after {
                color: rgba(0, 0, 0, 1);
              }
            }
          }
        }
      }
    }

    .socialLinksWrapper {
      display: flex;
      flex-direction: column;

      .contacts {
        display: grid;

        margin-bottom: 8px;
        gap: 4px;

        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 115%;
          color: rgba(0, 0, 0, 0.7);

          @media (max-width: 1000px) {
            font-size: 8px;
          }

          @media (max-width: 700px) {
            font-size: 11px;
          }
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.7);

          @media (max-width: 1000px) {
            font-size: 8px;
          }

          @media (max-width: 700px) {
            font-size: 11px;
          }
        }
      }
    }

    .partnersContainer {
      span {
        color: rgba(0, 0, 0, 0.7) !important;
      }
    }

    .socialLinksContainer {
      .iconWrapper {
        &:hover {
          background-color: #02ba95;
        }
      }
    }

    .footerNavWrapper {
      color: #000000;

      .otherList {
        a {
          color: #000000;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .MethodsListWrapper,
      .ProductsListWrapper,
      .MarketResearchListWrapper {
        color: #000000;

        a {
          color: rgba(0, 0, 0, 0.7);

          &:hover {
            color: #000;
          }
        }
      }
    }
  }

  &.darkTheme {
    .footer-link {
      color: #fff !important;
    }
  }

  &.lightTheme {
    .footer-link {
      color: #000 !important;
    }
  }
`;
