import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { NotFoundStyles } from './styles';
import { Link } from 'react-router-dom';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';

export const NotFound = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <NotFoundStyles>
      <div className="container">
        <div className="not-found__info">
          <h1>404</h1>
          <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'not_found_1')}</h2>
          <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'not_found_2')}</h3>
        </div>
        <div className="not-found__btns">
          <Link className="products" to={`/${interfaceLanguage}/products`}>
            {getTranslationsByLangOrEng(interfaceLanguage, 'main_4')}
          </Link>
          <Link className="contacts" to={`/${interfaceLanguage}/contacts`}>
            {getTranslationsByLangOrEng(interfaceLanguage, 'not_found_3')}
          </Link>
        </div>
      </div>
    </NotFoundStyles>
  );
};
