import styled from 'styled-components';

export const History4OpinionStyles = styled.div`
  background: linear-gradient(172.16deg, #0000ff -73.81%, #000000 95.18%);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 70px 10px;
    z-index: 5;

    @media (max-width: 768px) {
      gap: 30px;
      padding: 30px 10px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 125%;
    text-align: center;
    color: #ffffff;
    max-width: 770px;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  h5 {
    font-weight: 700;
    font-size: 32px;
    line-height: 115%;
    text-align: center;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  .cards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      gap: 25px;
      max-width: 570px;
      width: 100%;
      height: 200px;
      background: linear-gradient(
        52.5deg,
        rgba(0, 0, 255, 0.36) 0%,
        rgba(0, 0, 255, 0) 102.74%
      );
      border: 2px solid #ffffff;
      border-radius: 10px;

      @media (max-width: 768px) {
        gap: 15px;
        height: 150px;
      }
    }
  }
`;
