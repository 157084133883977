import styled from 'styled-components';
import needs1 from '../../../assets/images/brandIdentity/needs-1.png';
import needs2 from '../../../assets/images/brandIdentity/needs-2.png';

export const UsageAttitudeNeedsStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .cards {
    &-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 25px;
      width: 100%;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    &-item {
      background: #ffffff;
      border-radius: 5px;
      border: 1px solid #FFBD14;

      span {
        font-size: 20px;
        line-height: 125%;
        color: #0f0e0c;
      }

      &:nth-child(1),
      &:nth-child(4) {
        width: calc(50% - 15px);
        height: 235px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
      &:nth-child(1) {
        background: url(${needs1}) no-repeat;
        background-position: center center;
        background-size: cover;
      }
      &:nth-child(4) {
        background: url(${needs2}) no-repeat;
        background-position: center center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: calc(50% - 75px);
        height: calc(235px - 60px);
        padding: 30px;

        @media (max-width: 768px) {
          width: calc(100% - 30px);
          padding: 15px;
        }
      }
    }
  }
`;
