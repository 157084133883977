import styled from 'styled-components';

export const OpportunitiesCasesStyles = styled.div`
  padding: 100px 0;
  background: #000;

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 50px;

    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }

  .container {
    max-width: 1190px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 991px) {
      flex-direction: column;
      gap: 15px;
    }
  }

  .slide {
    width: 770px;
    height: auto;
    border: 2px solid #0000ff;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: 900px) {
      width: 440px;
    }

    @media (max-width: 575px) {
      width: 280px;
    }

    a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .headContainer {
      height: 270px;
      position: relative;

      @media (max-width: 900px) {
        height: 155px;
      }

      @media (max-width: 575px) {
        height: 171px;
      }

      .captions {
        padding: 28px 30px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        @media (max-width: 900px) {
          padding: 16px 17px;
        }

        @media (max-width: 575px) {
          padding: 19px 20px;
        }

        .caption {
          padding: 5px 21px;
          font-weight: 700;
          font-size: 18px;
          line-height: 115%;
          text-align: center;
          color: #ffffff;
          background: #eb2e4b;
          border-radius: 50px;
          white-space: nowrap;

          @media (max-width: 900px) {
            padding: 3px 12px;
            font-size: 10.3792px;
            line-height: 115%;
          }

          @media (max-width: 575px) {
            padding: 5px 10px;
          }
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 270px;
        object-fit: cover;

        @media (max-width: 900px) {
          height: 155px;
        }

        @media (max-width: 575px) {
          height: 171px;
          width: 280px;
        }
      }
    }

    .textContainer {
      padding: 35px 45px 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      @media (max-width: 900px) {
        padding: 20px 25px 29px;
      }

      @media (max-width: 575px) {
        padding: 30px 30px 35px 30px;
      }

      p {
        margin-bottom: 24px;
        color: #fff;
        font-weight: 700;
        font-size: 30px;
        line-height: 115%;

        @media (max-width: 900px) {
          font-size: 16px;
        }

        @media (max-width: 575px) {
          font-size: 14px;
        }
      }

      .hashtagAndButtonWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;

        .hashtagsContainer {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;

          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 115%;
            color: #0000ff;

            @media (max-width: 900px) {
              font-size: 10px;
            }
          }
        }

        .button {
          position: relative;
          display: flex;
          align-items: center;
          background: rgba(0, 0, 255, 0.5);
          border-radius: 100px;
          padding: 5px 26px;
          color: #fff;

          span {
            font-weight: 700;
            font-size: 13px;
            line-height: 115%;
            transform: translateX(-2px);
          }

          svg {
            height: 10px;
            position: absolute;
            right: 10px;
            transform: translate(-4px, 1px);
          }
        }
      }
    }
  }

  .lastSlide {
    width: 770px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border: 2px solid #0000ff;
    border-radius: 10px;
    overflow: hidden;

    padding: 80px 65px 68px;
    box-sizing: border-box;

    @media (max-width: 900px) {
      padding: 46px 37px 29px;
      width: 440px;
    }

    @media (max-width: 575px) {
      padding: 27px 30px 35px;
      width: 280px;
    }

    .description {
      font-weight: 600;
      font-size: 60px;
      line-height: 73px;
      color: #fff;

      @media (max-width: 900px) {
        font-size: 32px;
        line-height: 39px;
      }

      @media (max-width: 575px) {
        font-size: 26px;
        line-height: 32px;
      }
    }

    a {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 600px) {
        width: 100%;

        .lastSlideButton {
          width: 100%;

          button {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .lastSlideButton {
      white-space: nowrap;

      @media (max-width: 600px) {
        .hashtag {
          font-size: 8px;
          line-height: 10px;
        }

        .description {
          font-size: 26px;
          line-height: 32px;
        }
      }

      button {
        @media (max-width: 600px) {
          padding: 0;

          span {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
`;
