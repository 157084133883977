import styled from 'styled-components';

export const CareerStyles = styled.div`
  font-family: 'Montserrat';
  position: relative;
  overflow: hidden;

  .container {
    max-width: 1190px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
`;
