import styled from 'styled-components';

export const ConsumerJourneyMapSolutionStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .full-container {
    max-width: 1190px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin: 25px auto 0;
    position: relative;
    gap: 25px;

    @media (max-width: 991px) {
      gap: 15px;
    }
  }

  .cards {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;
    width: 100%;

    &-item {
      box-sizing: border-box;
      border-radius: 10px;
      border: 2px solid #F15967;
      background: #fff;
      max-width: 270px;
      height: 330px;
      width: 100%;

      h6 {
        font-weight: 700;
        font-size: 20px;
        line-height: 125%;
        text-align: center;
        color: #0F0E0C;
        padding: 20px 15px;
        margin: 0;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        color: #0F0E0C;
        padding: 0 15px;
      }

      img {
        height: 130px;
        width: 100%;
      }
    }
  }
`;
