import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import aboutImg from '../../../assets/images/brandAwarenessUsage/about.png';
import aboutItem1 from '../../../assets/images/brandAwarenessUsage/brand-awareness-usage-1.png';
import aboutItem2 from '../../../assets/images/brandAwarenessUsage/brand-awareness-usage-2.png';
import aboutItem3 from '../../../assets/images/brandAwarenessUsage/brand-awareness-usage-3.png';
import aboutItem4 from '../../../assets/images/brandAwarenessUsage/brand-awareness-usage-4.png';
import { BrandAwarenessUsageAboutStyles } from './styles';

export const BrandAwarenessUsageAbout = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <BrandAwarenessUsageAboutStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_5')}
        </h5>
        <div className="content">
          <img src={aboutImg} className="about-img" alt="content" />
          <div className="cards">
            <div className="cards-item">
              <div className="cards-img">
                <img src={aboutItem1} alt="" />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'brand_awareness_6',
                  ),
                }}
              />
            </div>
            <div className="cards-item">
              <div className="cards-img">
                <img src={aboutItem2} alt="" />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'brand_awareness_7',
                  ),
                }}
              />
            </div>
            <div className="cards-item">
              <div className="cards-img">
                <img src={aboutItem3} alt="" />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'brand_awareness_8',
                  ),
                }}
              />
            </div>
            <div className="cards-item">
              <div className="cards-img">
                <img src={aboutItem4} alt="" />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'brand_awareness_9',
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </BrandAwarenessUsageAboutStyles>
  );
};
