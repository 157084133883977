import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../state/hooks';
import { FacilityCheckAuditStyles } from './FacilityCheckAuditStyles';
import ProductsTabs from '../../components/productsTabs/ProductsTabs';
import { productsTabsData } from '../../constants';
import OurClients from '../../components/ourClients/OurClients';
import {
  FacilityCheckAuditAbout,
  FacilityCheckAuditBanner,
  FacilityCheckAuditMethodOne,
  FacilityCheckAuditMethodTwo,
  FacilityCheckAuditReports,
  FacilityCheckAuditSlider,
  FacilityCheckAuditSolutionOne,
  FacilityCheckAuditSolutionTwo,
  FacilityCheckAuditWho,
  FacilityCheckAuditWhy,
} from '../../components';

export const FacilityCheckAudit = React.memo(() => {
  const params = useLocation();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    const elem = document.getElementById(`${params.hash.slice(1)}`);

    if (elem) {
      setTimeout(() => {
        elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, 200);
    }
  }, []);

  return (
    <FacilityCheckAuditStyles>
      <FacilityCheckAuditBanner />
      <FacilityCheckAuditAbout />
      <FacilityCheckAuditSolutionOne />
      <FacilityCheckAuditSolutionTwo />
      <FacilityCheckAuditMethodOne />
      <FacilityCheckAuditMethodTwo />
      <FacilityCheckAuditWhy />
      <FacilityCheckAuditWho />
      <FacilityCheckAuditReports />
      <FacilityCheckAuditSlider />
      <OurClients theme="white" />
      <ProductsTabs
        data={productsTabsData(interfaceLanguage)}
        theme="lightTheme"
      />
    </FacilityCheckAuditStyles>
  );
});
