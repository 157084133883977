import styled from 'styled-components';

export const FacilityCheckAuditBannerStyles = styled.div`
  .container {
    max-width: 1190px;
    width: 100%;
    padding: 0 10px;
    margin: auto;
    box-sizing: border-box;
    display: block !important;
  }

  .content {
    padding: 88px 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1000px) {
      padding: 50px 30px 72px;
    }

    @media (max-width: 600px) {
      padding: 45px 30px 149px;
    }
  }
`;
