import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CxComprehensiveStyle } from './CxComprehensiveStyle';
import { sliderData } from './utils';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { useWindowSize } from 'usehooks-ts';
import { SwiperSlide } from 'swiper/react';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CxComprehensive = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { width, height } = useWindowSize();

  return (
    <CxComprehensiveStyle>
      <div className="container">
        <div className="comprehensive-text">
          <h3>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_21')}
          </h3>
          <h4>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_22')}
          </h4>
          <ul>
            <li>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_23')}
            </li>
            <li>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_24')}
            </li>
          </ul>
        </div>
      </div>

      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          centeredSlides
          loop={false}
          initialSlide={window.innerWidth > 1500 ? 1 : 0}
          offsetLeft={
            window.innerWidth > 1400 ? (window.innerWidth - 1180) / 2 : 10
          }
          disabledNext
          disabledPrev
          disabledPrevIfSlideActive={window.innerWidth > 1500 ? 1 : 0}
          buttonBorderColor="#02BA95"
          buttonsIconsColor="#fff"
        >
          {sliderData(interfaceLanguage).map((item, index) => (
            <SwiperSlide className="slide" key={item.tool}>
              <div className="card-description">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_25',
                )}
              </div>
              <div className="card-info">
                <div className="card-label">
                  {getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'cx_solution_26',
                  )}
                </div>
                <div className="card-name">{item.tool}</div>
              </div>
              <div className="card-text">{item.description}</div>
            </SwiperSlide>
          ))}
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </CxComprehensiveStyle>
  );
};
