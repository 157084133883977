import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesProductsStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import productItem1 from '../../../assets/images/opportunities/product-item-1.gif';
import productItem2 from '../../../assets/images/opportunities/product-item-2.gif';
import productItem3 from '../../../assets/images/opportunities/product-item-3.gif';
import productItem4 from '../../../assets/images/opportunities/product-item-4.gif';
import productItem5 from '../../../assets/images/opportunities/product-item-5.gif';
import productItem6 from '../../../assets/images/opportunities/product-item-6.gif';

export const OpportunitiesProducts = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesProductsStyles>
      <h1
        dangerouslySetInnerHTML={{
          __html: getTranslationsByLangOrEng(
            interfaceLanguage,
            'opportunities_6',
          ),
        }}
      />
      <div className="container">
        <div className="product-card">
          <img src={productItem1} loading="lazy" alt="" />
          <div className="product-text">
            <h6>
              {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_7')}
            </h6>
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_8')}
            </p>
          </div>
        </div>
        <div className="product-card">
          <div className="product-text">
            <h6>
              {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_9')}
            </h6>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_10',
              )}
            </p>
          </div>
          <img src={productItem2} loading="lazy" alt="" />
        </div>
        <div className="product-card">
          <img src={productItem3} loading="lazy" alt="" />
          <div className="product-text">
            <h6>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_11',
              )}
            </h6>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_12',
              )}
            </p>
          </div>
        </div>
        <div className="product-card">
          <img src={productItem4} loading="lazy" alt="" />
          <div className="product-text">
            <h6>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_13',
              )}
            </h6>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_14',
              )}
            </p>
          </div>
        </div>
        <div className="product-card">
          <div className="product-text">
            <h6>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_15',
              )}
            </h6>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_16',
              )}
            </p>
          </div>
          <img src={productItem5} loading="lazy" alt="" />
        </div>
        <div className="product-card">
          <img src={productItem6} loading="lazy" alt="" />
          <div className="product-text">
            <h6>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_17',
              )}
            </h6>
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_18',
              )}
            </p>
          </div>
        </div>
      </div>
    </OpportunitiesProductsStyles>
  );
};
