import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditSolutionOneStyles } from './FacilityCheckAuditSolutionOneStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const FacilityCheckAuditSolutionOne = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditSolutionOneStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_10',
          )}
        </h5>
        <div className="content">
          <h4>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_11',
            )}
          </h4>
          <p>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_12',
            )}
          </p>
        </div>
      </div>
    </FacilityCheckAuditSolutionOneStyles>
  );
});
