import styled from 'styled-components';

export const VacanciesCareerStyles = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px 10px;
    gap: 50px;

    @media (max-width: 768px) {
      padding: 30px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0;
      font-weight: 600;
      font-size: 60px;
      line-height: 115%;
      color: #ffffff;
      max-width: 670px;

      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    width: 100%;

    @media (max-width: 768px) {
      gap: 15px;
    }
  }

  .vacancy {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 25px;
    gap: 25px;
    background: #000000;
    border: 2px solid #0000ff;
    border-radius: 10px;
    position: relative;

    @media (max-width: 768px) {
      gap: 15px;
      padding: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background: #0000ff;
      right: 20px;
      top: 25px;

      @media (max-width: 768px) {
        right: 15px;
        top: 15px;
      }
    }

    h5 {
      margin: 0;
      font-weight: 600;
      font-size: 32px;
      line-height: 120%;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    &__body {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }

      h6 {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #ffffff;
        width: 25%;
        flex-shrink: 0;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      ul {
        margin: 0;
        width: 100%;

        @media (max-width: 768px) {
          margin-left: 20px;
        }

        li {
          margin: 0;
          font-weight: 400;
          font-size: 18px;
          line-height: 120%;
          color: #ffffff;
          list-style-type: disc;
          max-width: 780px;

          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }

    &__link {
      display: flex;
      box-sizing: border-box;
      width: 48px;
      height: 48px;
      background: #000000;
      border: 2px solid #0000ff;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 20px;
      bottom: 25px;

      @media (max-width: 768px) {
        right: 15px;
        bottom: 15px;
      }

      &:hover {
        background: #0000ff;
      }
    }

    &__utils {
      display: flex;
      align-items: flex-end;
      gap: 25px;
      margin-top: 25px;

      @media (max-width: 768px) {
        margin-top: 15px;
        gap: 10px;
      }

      p {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 125%;
        color: rgba(255, 255, 255, 0.6);

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
