import React from 'react';
import { ProductsTabsStyles } from './ProductsTabsStyles';
import MainTabsSection from '../mainPageComponents/mainTabsSection/MainTabsSection';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';

function ProductsTabs({
  data,
  theme,
}: {
  theme: 'lightTheme' | 'darkTheme';
  data: {
    caption: string;
    title: string;
    description: string;
    background: string;
    link: string;
  }[];
}) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ProductsTabsStyles>
      <MainTabsSection
        colorTheme={theme}
        tabsData={data}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'products_tabs_1',
        )}
        signature={getTranslationsByLangOrEng(
          interfaceLanguage,
          'products_tabs_2',
        )}
      />
    </ProductsTabsStyles>
  );
}

export default ProductsTabs;
