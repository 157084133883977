import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditWhyStyles } from './FacilityCheckAuditWhyStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const FacilityCheckAuditWhy = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditWhyStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_25',
          )}
        </h5>
        <div className="content">
          <h4>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_26',
            )}
          </h4>
          <p>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_27',
            )}
          </p>
        </div>
      </div>
      <div className="cards">
        <div className="card">
          <span>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_28',
            )}
          </span>{' '}
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_29',
          )}
        </div>
        <div className="card">
          <span>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_30',
            )}
          </span>{' '}
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_31',
          )}
        </div>
      </div>
    </FacilityCheckAuditWhyStyles>
  );
});
