import React from 'react';
import { BrandAwarenessUsageOurMethodStyles } from './styles';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import img from '../../../assets/images/brandAwarenessUsage/our-method.png';

export const BrandAwarenessUsageOurMethod = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandAwarenessUsageOurMethodStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'main_14')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_25')}
          </h1>
          <img src={img} alt="methodology" className="our-methodology" />
        </div>
      </div>
    </BrandAwarenessUsageOurMethodStyles>
  );
};
