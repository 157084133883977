import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditBannerStyles } from './FacilityCheckAuditBannerStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';

export const FacilityCheckAuditBanner = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditBannerStyles>
      <Banner
        bgColor="linear-gradient(278deg, #042A40 4.83%, #9E00FF 72.7%)"
        title={`${getTranslationsByLangOrEng(
          interfaceLanguage,
          'facility_check_audit_1',
        )}\n${getTranslationsByLangOrEng(
          interfaceLanguage,
          'facility_check_audit_2',
        )}`}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'facility_check_audit_3',
        )}
        buttonKey="facility_check_audit_4"
      />
    </FacilityCheckAuditBannerStyles>
  );
});
