import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import { BrandAwarenessUsageNeedsStyles } from './styles';

export const BrandAwarenessUsageNeeds = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandAwarenessUsageNeedsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_23')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_24')}
          </h2>
          <div className="cards-container">
            <div className="cards-item" />
            <div className="cards-item">
              <span
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'usage_attitude_25',
                  ),
                }}
              />
            </div>
            <div className="cards-item">
              <span
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'usage_attitude_26',
                  ),
                }}
              />
            </div>
            <div className="cards-item">
              <ButtonWithArrowOnHover
                onClick={() => {
                  const elem = document.getElementById('form');
                  elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}
                arrowColor="#000"
                textColor="#fff"
                borderColor="#000"
                borderOnHover="#000"
                background="#fff"
                hoverBackground="linear-gradient(135.55deg, #BBBAFF 0%, #8E50CC 100.95%)"
                hoverTextColor="#000"
                text={getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'usage_attitude_11',
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </BrandAwarenessUsageNeedsStyles>
  );
};
