import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesAgencyStyles } from './styles';
import cert from '../../../assets/images/opportunities/certificate.png';
import logo4s from '../../../assets/images/opportunities/4slogo.png';
import agency1 from '../../../assets/images/opportunities/certificate-logo-1.png';
import agency2 from '../../../assets/images/opportunities/certificate-logo-2.png';
import agency3 from '../../../assets/images/opportunities/certificate-logo-3.png';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const OpportunitiesAgency = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesAgencyStyles>
      <div className="container">
        <div className="info">
          <img src={logo4s} alt="" loading="lazy" className="logo4s" />
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_34')}
          </h1>
          <div className="logos-wrapper">
            <div className="logos-item">
              <img loading="lazy" src={agency1} alt="" />
            </div>
            <div className="logos-item">
              <img loading="lazy" src={agency2} alt="" />
            </div>
            <div className="logos-item">
              <img loading="lazy" src={agency3} alt="" />
            </div>
          </div>
        </div>
        <img loading="lazy" src={cert} className="certificate" alt="" />
      </div>
    </OpportunitiesAgencyStyles>
  );
};
