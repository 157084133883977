import requirements1 from '../../../assets/images/adHoc/requirements-1.png';
import requirements2 from '../../../assets/images/adHoc/requirements-2.png';
import requirements3 from '../../../assets/images/adHoc/requirements-3.png';
import requirements4 from '../../../assets/images/adHoc/requirements-4.png';
import requirements5 from '../../../assets/images/adHoc/requirements-5.png';
import requirements6 from '../../../assets/images/adHoc/requirements-6.png';

import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const sliderData = (
  lang: string,
): { name: string; description: string; img: string }[] => [
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_46'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_47'),
    img: requirements1,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_48'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_49'),
    img: requirements2,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_50'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_51'),
    img: requirements3,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_52'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_53'),
    img: requirements4,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_54'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_55'),
    img: requirements5,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'usage_attitude_56'),
    description: getTranslationsByLangOrEng(lang, 'usage_attitude_57'),
    img: requirements6,
  },
];
