import styled from 'styled-components';

export const OpportunitiesAgencyStyles = styled.div`
  padding: 100px 0;
  background: #fff;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  .container {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      flex-direction: column;
      gap: 50px;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 50px;
      max-width: 600px;

      @media (max-width: 991px) {
        align-items: center;
      }

      .logo4s {
        max-width: 225px;
      }

      h1 {
        font-weight: 600;
        font-size: 60px;
        line-height: 115%;
        color: #0f0e0c;

        @media (max-width: 768px) {
          font-size: 32px;
        }
      }

      .logos-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: 768px) {
          gap: 10px;
        }

        .logos-item {
          background: #e9e9e9;
          width: 170px;
          height: 170px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 768px) {
            width: 100px;
            height: 100px;
          }

          img {
            @media (max-width: 768px) {
              max-width: 60px;
            }
          }
        }
      }
    }

    .certificate {
      max-width: 470px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;
