import React from 'react';

function FacebookIcon({
  width = 29,
  height = 29,
  color = '#fff',
  circleColor = 'FFF',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill={color}
        d="M14.916 12.688h1.656v1.44h-1.608V19h-1.872v-4.872h-.996v-1.44h.996V12.4c0-.736.216-1.32.648-1.752.44-.432 1.056-.648 1.848-.648.28 0 .544.032.792.096.256.056.468.14.636.252l-.492 1.356a1.28 1.28 0 00-.756-.228c-.568 0-.852.312-.852.936v.276z"
      />
      <circle cx="14.5" cy="14.5" r="14" stroke={circleColor} />
    </svg>
  );
}

export default FacebookIcon;
