import styled from 'styled-components';

export const ConsumerJourneyMapMapStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .full-container {
    max-width: 1190px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin: 25px auto 0;
    position: relative;
    gap: 25px;

    @media (max-width: 991px) {
      gap: 15px;
    }
  }

  .img-container {
    position: relative;
    overflow: auto;

    img {
        width: 100%;
        min-width: 800px;
    }
  }
`;
