import styled from 'styled-components';

export const OpportunitiesClientsStyles = styled.div`
  padding: 100px 0;
  background: #fff;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    text-align: center;
    color: #000;
    max-width: 1190px;
    margin: 0 auto;

    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
    }

    span {
      color: #0000ff;
    }
  }

  .container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;
