import styled from 'styled-components';

export const MainTabsSectionStyles = styled.div`
  padding: 65px 0 100px;

  @media (max-width: 1100px) {
    padding: 65px 0 90px;
  }

  @media (max-width: 600px) {
    padding: 93px 0 52px;
  }

  &#tabs {
    &.lightTheme {
      background: #fff;

      p,
      span,
      h2,
      div,
      li,
      a {
        color: #000;
      }

      .contentContainer {
        p,
        span,
        h2,
        div,
        li {
          color: #fff;
        }
      }
    }
  }

  &#tabs {
    &.darkTheme {
      background: #000;
    }
  }

  .captionWrapper {
    @media (max-width: 600px) {
      margin-bottom: 95px;
    }

    .description {
      display: none;

      @media (max-width: 600px) {
        display: block;
        padding-left: 20px;

        font-weight: 600;
        font-size: 26px;
        line-height: 120%;
      }
    }

    h2 {
      @media (max-width: 600px) {
        margin-bottom: 15px;
        color: #0000ff;
      }
    }
  }

  .link {
    cursor: pointer;
  }

  .title {
    font-weight: 600;
  }

  .listWrapperDesktop {
    display: flex;

    max-width: 1440px;
    margin: auto;
    padding-right: 50px;

    @media (max-width: 600px) {
      display: none;
    }

    &.active {
      justify-content: flex-start;
    }

    @media (max-width: 1400px) {
    }

    .contentContainer {
      position: relative;
      max-width: 705px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // height: 755px;
      padding: 100px 100px 130px 135px;
      box-sizing: border-box;
      color: #fff;
      transition: 0.5s all;

      @media (max-width: 1100px) {
        height: 520px;
      }

      @media (max-width: 1100px) {
        padding: 65px 38px 70px 46px;
      }

      .bg {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;

        animation: 0.5s linear size;
      }

      .textContainer {
        position: relative;
        z-index: 1;
        display: grid;
        gap: 15px;
        margin-bottom: 100px;
        opacity: 0;

        animation: 0.5s linear 0.5s forwards textContainer;

        @media (max-width: 1100px) {
          margin-bottom: 21px;
        }

        span {
          font-weight: 700;
          font-size: 40px;
          line-height: 115%;

          @media (max-width: 1100px) {
            font-size: 22px;
            line-height: 27px;
          }
        }

        p {
          font-size: 21px;
          line-height: 120%;

          @media (max-width: 1100px) {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }

      .imageContainer {
        width: 100%;
        position: relative;
        z-index: 1;
        opacity: 0;

        animation: 0.5s linear 0.5s forwards imageContainer;

        img {
          width: 100%;
          object-fit: contain;

          @media (max-width: 1100px) {
            max-height: 310px;
          }
        }
      }
    }

    .tabsWrapper {
      max-width: 567px;
      padding-left: 30px;
      width: 100%;

      @media (max-width: 1000px) {
        max-width: 328px;
        padding-left: 20px;
      }

      .description {
        font-weight: 600;
        font-size: 40px;
        line-height: 120%;
        margin-bottom: 69px;
        display: inline-block;

        @media (max-width: 1000px) {
          font-size: 23px;
          margin-bottom: 40px;
        }

        @media (max-width: 710px) {
          font-size: 26px;
        }
      }
    }

    ul {
      flex-grow: 1;
      display: grid;
      gap: 18px;
      width: 100%;
      height: min-content;
      box-sizing: border-box;

      @media (max-width: 1100px) {
        gap: 10px;
      }

      li {
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 4px;
          width: 100%;
          height: 54px;

          border-bottom: 1px solid #575757;

          @media (max-width: 1100px) {
            height: 40px;
          }
        }

        &.active {
          span {
            background: linear-gradient(
                214.3deg,
                #ed1c2a -8269.66%,
                #ee2752 4023.48%,
                #444a49 16818.38%
              ),
              #ffffff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          svg {
            margin-right: 8px;
          }
        }

        span {
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          color: #ffffff;

          @media (max-width: 1100px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .listWrapperMobile {
    display: none;

    padding: 10px;

    @media (max-width: 600px) {
      display: block;
    }

    ul {
      display: grid;
      gap: 24px;

      li {
        border-bottom: 1px solid #575757;
        padding: 10px 15px 0 0;
        position: relative;

        &.active {
          padding: 10px 15px 10px 30px;
          transition: 0.5s all;
        }

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          animation: 0.3s linear forwards size;
        }

        .captionContainer {
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .contentContainer {
          position: relative;
          z-index: 1;

          display: flex;
          flex-direction: column;

          color: #fff;
          max-height: 0;
          overflow: hidden;

          p {
            margin-bottom: 20px;

            opacity: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 115%;
            color: #fff;
          }

          .readButtonWrapper {
            display: flex;
            justify-content: flex-end;
            transform: translateX(-100%);
            opacity: 0;
          }

          .readButton {
            display: flex;
            align-items: center;
            gap: 4px;

            span {
              font-weight: 700;
              font-size: 20px;
              line-height: 115%;
            }

            svg {
              width: 12px;
              height: 10px;
            }
          }
        }

        &.active {
          border-bottom: none;

          .contentContainer {
            max-height: 700px;

            transition: 2s max-height;

            p {
              transition: 0.5s opacity 0.3s;
              opacity: 1;
            }

            .readButtonWrapper {
              transform: translateX(-10px);
              opacity: 1;
              transition: opacity 0.1s, transform 0.5s;
            }
          }

          .captionContainer {
            margin-bottom: 10px;

            span {
              font-weight: 700;
              font-size: 18px;
              line-height: 95%;
            }

            svg {
              transform: rotate(90deg);
              transition: 0.5s all;
            }
          }
        }

        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 95%;
          color: #ffffff;
        }
      }
    }

    .activeListItem {
      position: relative;
      display: flex;
      flex-direction: column;
      color: #fff;
      overflow: hidden;
      max-height: 41px;
      height: 100%;

      .captionWithArrow {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .activeListItemCaption {
          font-weight: 700;
          font-size: 18px;
          line-height: 95%;
        }
      }
    }
  }

  &.darkTheme {
    color: #fff;
  }

  @keyframes size {
    from {
      width: 0;
      height: 0;
    }

    to {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes textContainer {
    from {
      opacity: 0;
      transform: translateY(-30%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes imageContainer {
    from {
      opacity: 0;
      transform: translateY(30%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes scale {
    from {
      transform: scaleY(0);
    }

    to {
      transform: scaleY(1);
    }
  }
`;
