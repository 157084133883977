import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { Banner4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import bannerEU from '../../../assets/images/4opinion/banner-eu.png';
import bannerSNG from '../../../assets/images/4opinion/banner-sng.png';

export const Banner4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const getGeoBanner = (lang: string): string => {
    switch (lang) {
      case 'ru-kz':
      case 'kk-kz':
      case 'az':
        return bannerSNG;
      default:
        return bannerEU;
    }
  };

  return (
    <Banner4OpinionStyles banner={getGeoBanner(interfaceLanguage)}>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_1')}</h1>
        <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_2')}</h3>
        <ButtonWithArrowOnHover
          link="https://4opinion.eu/"
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#fff"
          borderOnHover="#fff"
          background="transparent"
          hoverBackground="transparent"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'four_opinion_3',
          )}
        />
      </div>
    </Banner4OpinionStyles>
  );
};
