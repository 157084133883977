import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { CustomerProfilingSegmentationAboutStyles } from './styles';

export const CustomerProfilingSegmentationAbout = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CustomerProfilingSegmentationAboutStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_4')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_3')}
          </h2>
          <div className="cards">
            <div className="cards-item">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_4')}</div>
            <div className="cards-item">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_5')}</div>
            <div className="cards-item">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_6')}</div>
            <div className="cards-item">{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_7')}</div>
          </div>
        </div>
      </div>
    </CustomerProfilingSegmentationAboutStyles>
  );
};
