import styled from 'styled-components';
import intro from '../../../assets/images/opportunities/intro.png';

export const OpportunitiesIntroStyles = styled.div`
  padding: 150px 0;
  background: url(${intro}) no-repeat;
  background-position: center center;
  background-size: cover;

  @media (max-width: 991px) {
    padding: 100px 0;
  }

  .container {
    max-width: 1190px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 600;
      font-size: 100px;
      line-height: 97%;
      text-transform: uppercase;
      color: #ffffff;
      word-wrap: break-word;

      @media (max-width: 991px) {
        font-size: 80px;
      }
      @media (max-width: 768px) {
        font-size: 50px;
      }
    }

    h2 {
      max-width: 670px;
      align-self: center;
      font-weight: 500;
      font-size: 40px;
      line-height: 97%;
      color: #ffffff;
      margin-top: 15px;

      @media (max-width: 991px) {
        font-size: 30px;
      }
      @media (max-width: 768px) {
        font-size: 26px;
      }
    }

    .top {
      align-self: center;
    }
    .middle {
      align-self: flex-end;
    }
    .bottom {
    }
  }
`;
