import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { SociologicalResearchStyles } from './styles';
import {
  SociologicalResearchAbout,
  SociologicalResearchClients,
  SociologicalResearchControl,
  SociologicalResearchField,
  SociologicalResearchIntro,
  SociologicalResearchLicense,
  SociologicalResearchPartners,
  SociologicalResearchPrinciples,
  SociologicalResearchSample,
  SociologicalResearchScience,
  SociologicalResearchSlider,
  SociologicalResearchSliderSociological,
  SociologicalResearchTeam,
  SociologicalResearchVideoslider,
  SociologicalResearchWork,
} from '../../components';

export const SociologicalResearch = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchStyles>
      <SociologicalResearchIntro />
      <SociologicalResearchAbout />
      <SociologicalResearchClients />
      <SociologicalResearchPrinciples />
      <SociologicalResearchField />
      <SociologicalResearchControl />
      <SociologicalResearchPartners />
      <SociologicalResearchWork />
      <SociologicalResearchLicense />
      <SociologicalResearchSlider />
      <SociologicalResearchSample />
      <SociologicalResearchTeam />
      <SociologicalResearchScience />
      <SociologicalResearchSliderSociological />
      <SociologicalResearchVideoslider />
    </SociologicalResearchStyles>
  );
};
