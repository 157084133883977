import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CustomerProfilingSegmentationMethodologyStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { useWindowSize } from 'usehooks-ts';
import mob from '../../../assets/images/customerProfilingSegmentation/methodology-mob.png';
import des from '../../../assets/images/customerProfilingSegmentation/methodology-des.png';

export const CustomerProfilingSegmentationMethodology = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { width, height } = useWindowSize();
  return (
    <CustomerProfilingSegmentationMethodologyStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_22')}
          </h2>
          <div className="text-block">
            <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_23')}</h3>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_24')}</p>
          </div>
          <div className="text-block">
            <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_25')}</h3>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_26')}</p>
          </div>
          <div className="text-block">
            <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_27')}</h3>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_28')}</p>
          </div>
        </div>
      </div>
      <img className="alert" src={width > 991 ? des : mob} alt="alert" />
    </CustomerProfilingSegmentationMethodologyStyles>
  );
};
