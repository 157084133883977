import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const SociologicalResearchPrinciplesStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    width: 100%;
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 20px;

    @media (max-width: 768px) {
      gap: 15px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (max-width: 768px) {
        gap: 5px;
      }

      h1 {
        font-weight: 700;
        font-size: 96px;
        line-height: 115%;
        background: linear-gradient(180deg, #f05a25 0%, #faaf3b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (max-width: 768px) {
          font-size: 48px;
        }
      }

      p {
        font-weight: 400;
        font-size: 28px;
        line-height: 125%;
        color: #000000;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }

  .texts {
    width: 100%;
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }
`;
