import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { ThankYouPartnerStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import img from '../../assets/images/thankYouPartner/thank-you.png';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';

export const ThankYouPartner = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const navigate = useNavigate();

  return (
    <ThankYouPartnerStyles>
      <div className="container">
        <img src={img} alt="" />
        <div className="info">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'thank_you_page')}
          </h1>
          <button
            onClick={() => navigate(`/${interfaceLanguage}`)}
            type="button"
          >
            {getTranslationsByLangOrEng(interfaceLanguage, 'back_main')}
          </button>
        </div>
      </div>
    </ThankYouPartnerStyles>
  );
};
