import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ConsumerJourneyMapAboutStyles } from './styles';

export const ConsumerJourneyMapAbout = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ConsumerJourneyMapAboutStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_4')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_5')}
          </h2>
          <div className="cards">
            <div className="cards-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_6')}</div>
            <div className="cards-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_7')}</div>
            <div className="cards-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_8')}</div>
            <div className="cards-item">{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_9')}</div>
          </div>
        </div>
      </div>
    </ConsumerJourneyMapAboutStyles>
  );
};
