import styled from 'styled-components';

export const ConsumerJourneyMapBenefitStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 25px;

    &-item {
      font-weight: 400;
      font-size: 28px;
      line-height: 125%;
      color: #0F0E0C;
      padding-left: 35px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: linear-gradient(180deg, #FDA272 0%, #F15967 100%);
        left: 10px;
        top: 10px;
      }

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
`;
