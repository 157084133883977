import styled from 'styled-components';

export const FacilityCheckAuditSolutionTwoStyles = styled.div`
  padding: 100px 0 50px 0;
  width: 100%;

  @media (max-width: 900px) {
    padding: 50px 0;
  }

  .container {
    .circlesContainer {
      margin-top: 45px;
      display: grid;
      grid-template-columns: 400px 400px;

      @media (max-width: 1000px) {
        justify-content: flex-end;

        grid-template-columns: 236px 236px;
      }

      @media (max-width: 600px) {
        justify-content: center;

        grid-template-columns: 156px 156px;
      }

      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 400px;
        height: 400px;
        background: var(
          --Gradient-Check-Audit,
          linear-gradient(93deg, #9e00ff 0%, #042a40 100.06%)
        ) !important;
        border: 2px solid #000000;
        border-radius: 50%;
        box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.15);
        background: #ffffff;

        &:nth-child(2) {
          margin-left: -30px;
        }

        @media (max-width: 1000px) {
          width: 236px;
          height: 236px;

          &:nth-child(2) {
            margin-left: -18px;
          }
        }

        @media (max-width: 600px) {
          width: 156px;
          height: 156px;

          &:nth-child(2) {
            position: relative;
            margin-left: auto;
            transform: translateX(-9px);
          }

          &:nth-child(1) {
            transform: translateX(7px);
          }
        }

        span {
          max-width: 260px;

          text-align: center;
          font-weight: 400;
          font-size: 40px;
          line-height: 125%;
          color: #fff;

          @media (max-width: 1000px) {
            max-width: 154px;

            font-size: 32px;
          }

          @media (max-width: 600px) {
            max-width: 102px;

            font-size: 20px;
          }
        }
      }
    }
  }
`;
