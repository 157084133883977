import styled from 'styled-components';

export const FacilityCheckAuditMethodTwoStyles = styled.div`
  padding: 90px 0 50px 0;
  width: 100%;
  position: relative;

  .building {
    position: absolute;
    top: 20%;

    @media (max-width: 1000px) {
      opacity: 0.2;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .content {
    gap: 30px;

    h6 {
      margin: 0;
      color: #0f0e0c;
      font-size: 28px;
      font-weight: 600;
      line-height: 125%;
    }

    .customList {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &-item {
        display: flex;
        align-items: flex-start;
        gap: 30px;

        svg {
          flex-shrink: 0;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 15px;

        h6 {
          color: #0f0e0c;
          font-size: 20px;
          font-weight: 600;
          line-height: 125%;
        }

        ul {
          list-style-type: disc;
          margin-left: 20px;
        }

        li {
          color: #0f0e0c;
          font-size: 20px;
          line-height: 125%;
        }
      }
    }

    .centered-btn {
      margin: 0 auto;
    }
  }
`;
