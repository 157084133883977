import styled from 'styled-components';

export const OpportunitiesCareerStyles = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px 10px;
    gap: 50px;

    @media (max-width: 768px) {
      padding: 30px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0;
      font-weight: 600;
      font-size: 60px;
      line-height: 115%;
      color: #ffffff;
      max-width: 570px;

      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
  }

  .cards {
    max-width: 870px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-end;
    gap: 30px;

    &-item {
      max-width: 270px;
      height: 210px;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      border-width: 2px 0px 0px 2px;
      border-style: solid;
      border-color: #ffffff;
      padding: 20px 10px 5px 20px;

      &::before {
        position: absolute;
        content: '';
        left: 20px;
        bottom: 5px;
        border: 3px solid #0500ff;
        width: 92px;
        height: 9px;
        border-radius: 100px;
      }

      p {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 115%;
        color: #ffffff;

        span {
          color: #0000FF;
        }
      }
    }
  }
`;
