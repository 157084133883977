import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { CustomerProfilingSegmentationExpertsStyles } from './styles';
import experts1 from '../../../assets/images/consumerJourneyMap/experts-1.png';
import experts2 from '../../../assets/images/consumerJourneyMap/experts-2.png';
import experts3 from '../../../assets/images/consumerJourneyMap/experts-3.png';

export const CustomerProfilingSegmentationExperts = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CustomerProfilingSegmentationExpertsStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_17')}
          </h2>
        </div>
      </div>
      <div className="full-container">
        <div className="cards">
          <div className="cards-item">
            <img src={experts1} alt="experts" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_18')}</p>
          </div>
          <div className="cards-item">
            <img src={experts2} alt="experts" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_19')}</p>
          </div>
          <div className="cards-item">
            <img src={experts3} alt="experts" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_20')}</p>
          </div>
        </div>
      </div>
    </CustomerProfilingSegmentationExpertsStyles>
  );
};
