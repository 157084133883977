import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const SociologicalResearchIntroStyles = styled.div`
  width: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(${bg}) no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      94deg,
      rgba(242, 91, 37, 0.5) 0.21%,
      rgba(208, 140, 41, 0.8) 124.77%
    );
    z-index: 2;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    padding: 180px 10px 90px !important;
    z-index: 3;

    @media (max-width: 991px) {
      padding: 120px 10px 60px !important;
    }

    @media (max-width: 768px) {
      gap: 25px;
      padding: 90px 10px 40px !important;
    }

    h1 {
      color: #fff;
      text-align: center;
      font-size: 80px;
      font-weight: 700;
      line-height: 97%;

      @media (max-width: 991px) {
        font-size: 54px;
      }

      @media (max-width: 768px) {
        font-size: 34px;
      }
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 36px;
      font-weight: 400;

      @media (max-width: 991px) {
        font-size: 26px;
      }

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
`;
