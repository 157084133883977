import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import ArrowRight from '../../../assets/icons/ArrowRight';
import ArrowWithoutStick from '../../../assets/icons/ArrowWithoutStick';
import { MainTabsSectionStyles } from './MainTabsSectionStyles';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../state/hooks';

interface Props {
  signature: string;
  description: string;
  link?: string;

  tabsData: {
    caption: string;
    title: string;
    description: string;
    background: string;
    image?: string;
    imageTablet?: string;
    link: string;
    anotherSite?: boolean;
    onlyInLanguages?: string[];
  }[];
  colorTheme: 'darkTheme' | 'lightTheme';
}

function MainTabsSection({
  tabsData,
  signature,
  description,
  colorTheme,
  link,
}: Props) {
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState<number>();
  const [descriptionHeight, setDescriptionHeight] = useState<number>();

  const descriptionRef = useRef<any>();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    setDescriptionHeight(descriptionRef.current.offsetHeight);
  }, []);

  const handleOpen = () => {
    if (link) {
      navigate(`/${interfaceLanguage}/${link}`);
    }
  };

  return (
    <MainTabsSectionStyles className={colorTheme} id="tabs">
      <div className="container">
        <div
          className="captionWrapper"
          style={
            descriptionHeight
              ? {
                height:
                    window.innerWidth > 1000
                      ? descriptionHeight + 69
                      : descriptionHeight + 40,
              }
              : {}
          }
        >
          <h2 className={link ? 'link' : ''} onClick={handleOpen}>{signature}</h2>

          <div className="description">{description}</div>
        </div>
      </div>

      <div
        className={
          activeItem || activeItem === 0
            ? 'listWrapperDesktop active'
            : 'listWrapperDesktop'
        }
      >
        <div className="contentContainer">
          {(activeItem || activeItem === 0)
            // eslint-disable-next-line array-callback-return
            && tabsData.map((item, index) => {
              if (index === activeItem) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>
                    <div
                      className="bg"
                      style={{ background: tabsData[activeItem].background }}
                    />
                    <div className="textContainer">
                      <span>{tabsData[activeItem].title}</span>
                      <p>{tabsData[activeItem].description}</p>
                    </div>
                    {item.image && (
                      <div className="imageContainer">
                        {window.innerWidth > 1101 ? (
                          <img
                            src={tabsData[activeItem].image}
                            alt={tabsData[activeItem].caption}
                          />
                        ) : (
                          <img
                            src={tabsData[activeItem].imageTablet}
                            alt={tabsData[activeItem].caption}
                          />
                        )}
                      </div>
                    )}
                  </Fragment>
                );
              }
            })}
        </div>

        <div
          className="tabsWrapper"
          style={
            descriptionHeight
              ? {
                marginTop:
                    window.innerWidth > 1000
                      ? -descriptionHeight - 69
                      : -descriptionHeight - 40,
              }
              : {}
          }
        >
          <span className="description" ref={descriptionRef} id={description}>
            {description}
          </span>

          <ul>
            {tabsData.map((item, index) => {
              if (
                (item.onlyInLanguages
                  && item.onlyInLanguages.includes(interfaceLanguage))
                || !item.onlyInLanguages
              ) {
                return (
                  <li
                    onMouseEnter={() => setActiveItem(index)}
                    key={item.caption}
                    className={activeItem === index ? 'active' : ''}
                  >
                    {item.anotherSite ? (
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <span>{item.caption}</span>
                        {activeItem === index ? (
                          <ArrowWithoutStick
                            color={
                              colorTheme === 'lightTheme' ? '#000' : '#FFF'
                            }
                          />
                        ) : (
                          <ArrowRight
                            color={
                              colorTheme === 'lightTheme' ? '#000' : '#FFF'
                            }
                          />
                        )}
                      </a>
                    ) : (
                      <Link to={`/${interfaceLanguage}${item.link}`}>
                        <span>{item.caption}</span>
                        {activeItem === index ? (
                          <ArrowWithoutStick
                            color={
                              colorTheme === 'lightTheme' ? '#000' : '#FFF'
                            }
                          />
                        ) : (
                          <ArrowRight
                            color={
                              colorTheme === 'lightTheme' ? '#000' : '#FFF'
                            }
                          />
                        )}
                      </Link>
                    )}
                  </li>
                );
              }

              return null;
            })}
          </ul>
        </div>
      </div>

      <div className="listWrapperMobile">
        <ul>
          {tabsData.map((item, index) => {
            if (
              (item.onlyInLanguages
                && item.onlyInLanguages.includes(interfaceLanguage))
              || !item.onlyInLanguages
            ) {
              return (
                <li
                  onClick={() => setActiveItem(index)}
                  key={item.caption}
                  className={activeItem === index ? 'active' : ''}
                >
                  {activeItem === index && (
                    <div
                      className="bg"
                      style={{ background: item.background }}
                    />
                  )}
                  <div className="captionContainer">
                    <span>{item.caption}</span>
                    <ArrowRight
                      color={colorTheme === 'lightTheme' ? '#000' : '#FFF'}
                    />
                  </div>
                  <div className="contentContainer">
                    <p>{item.description}</p>
                    <div className="readButtonWrapper">
                      {item.anotherSite ? (
                        <a
                          href={item.link}
                          target="_blank"
                          className="readButton"
                          rel="noreferrer"
                        >
                          <span>Read</span>
                          <ArrowWithoutStick />
                        </a>
                      ) : (
                        <Link
                          to={`/${interfaceLanguage}${item.link}`}
                          className="readButton"
                        >
                          <span>Read</span>
                          <ArrowWithoutStick />
                        </Link>
                      )}
                    </div>
                  </div>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    </MainTabsSectionStyles>
  );
}

export default MainTabsSection;
