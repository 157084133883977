import styled from 'styled-components';

export const SubscribeBannerStyles = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    height: 100%;
  }

  .banner {
    background: #0000ff;
    width: 70%;
    height: 100%;
    max-width: 1020px;
    max-height: 545px;
    display: flex;
    position: relative;

    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      max-width: 360px;
      max-height: 600px;
      width: 100%;
    }

    &__img {
      padding: 25px;
      position: relative;
      width: 35%;

      @media screen and (max-width: 991px) {
        width: calc(100% - 30px);
        order: 2;
        padding: 15px;
        height: 100%;
        max-height: 140px;
      }

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 2;
        background: linear-gradient(
          333.92deg,
          #0000ff 0%,
          rgba(0, 0, 255, 0.54) 0.01%,
          rgba(0, 0, 255, 0) 93.85%
        );
      }

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
        display: block;
        background: linear-gradient(
          333.92deg,
          #0000ff 0%,
          rgba(0, 0, 255, 0.36) 93.85%
        );
      }

      &:hover {
        &::after {
          display: none;
        }
      }

      h2 {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 40px;
        line-height: 125%;
        color: #ffffff;
        position: relative;
        z-index: 3;

        @media screen and (max-width: 991px) {
          font-size: 20px;
        }
      }
      
      &-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        z-index: 1;
      }

      &-link {
        position: absolute;
        cursor: pointer;
        left: 30px;
        bottom: 50px;
        z-index: 3;

        @media screen and (max-width: 991px) {
          left: 300px;
          bottom: 25px;
        }

        &::before {
          display: none;
          position: absolute;
          content: "";
          width: 50px;
          height: 2px;
          left: 0;
          background: #fff;
          top: calc(50% - 1px);
          transform: translate(0, -50%);

          @media screen and (max-width: 991px) {
            width: 300px;
            display: block;
            top: 50%;
            left: -270px;
          }
        }

        @media screen and (min-width: 991px) {
          &:hover {
            width: 80px;
            left: 60px;

            &::before {
              display: block;
              left: -30px;
            }
          }
        }

        img {
          width: 47px;
          height: 23px;
          position: relative;
          display: block;
        }
      }
    }

    &__title {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 40px;
      line-height: 100%;
      color: #ffffff;
      z-index: 3;
      max-width: 400px;

      @media screen and (max-width: 991px) {
        font-size: 26px;
        margin-top: 35px;
      }
    }

    &__close {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 5;
      cursor: pointer;

      @media screen and (max-width: 991px) {
        top: 15px;
        left: 15px;
        right: inherit;

        svg {
          width: 13px;
          height: 13px;
        }
      }
    }

    &__success {
      padding: 25px;
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (max-width: 991px) {
        width: calc(100% - 30px);
        padding: 15px;
        order: 1;
        max-height: 440px;
        height: 100%;
        gap: 25px;
      }

      h2 {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 40px;
        line-height: 100%;
        color: #ffffff;

        @media screen and (max-width: 991px) {
          font-size: 26px;
          margin-top: 40px;
        }
      }
      p {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;

        @media screen and (max-width: 991px) {
          max-width: 250px;
        }
      }
    }

    &__info {
      width: 65%;
      padding: 25px;

      @media screen and (max-width: 991px) {
        width: calc(100% - 30px);
        padding: 15px;
        order: 1;
        max-height: 440px;
        height: 100%;
      }
    }

    &__form {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
      }

      button {
        cursor: pointer;
        outline: none;
        background: none;
        box-sizing: border-box;
        padding: 14px 25px;
        border: 2px solid #FFFFFF;
        border-radius: 100px;
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 14px;
        line-height: 125%;
        letter-spacing: -0.022em;
        color: #FFFFFF;
        width: 210px;

        @media screen and (max-width: 991px) {
          width: 100%;
          padding: 10px 20px;
        }

        &:hover {
          background: #fff;
          color: #0000ff;
        }
      }
    }

    &__terms {
      display: flex;
      gap: 8px;
      width: 50%;

      @media screen and (max-width: 991px) {
        width: 100%;
      }

      p {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 12px;
        line-height: 120%;
        color: #FFFFFF;
      }
    }

    &__fields {
      display: flex;
      flex-direction: column;
      gap: 25px;

      &-email {
        background: none;
        border: none;
        outline: none;
        border-bottom: 2px solid #fff;
        height: 42px;

        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 28px;
        line-height: 115%;
        color: #FFFFFF;

        &::placeholder {
          color: #FFFFFF;
        }

        @media screen and (max-width: 991px) {
          height: 24px;
          font-size: 16px;
          border-bottom: 1px solid #fff;
        }
      }
    }

    &__language {
      display: flex;
      flex-direction: column;
      gap: 10px;
      

      p {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        color: #FFFFFF;
      }

      &-list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 991px) {
         flex-direction: column;
         align-items: flex-start;
         gap: 15px;
        }
      }
    }
  }

  .checkbox {
    flex-shrink: 0;
    
    &__wrapper {
      cursor: pointer;
      box-sizing: border-box;
      border: 2px solid #ffffff;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    &__mark {
      width: 10px;
      height: 10px;
      background: #fff;
      flex-shrink: 0;
      border-radius: 50%;
    }

    &__default {
     display: none;
    }
  }

  .radio {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #FFFFFF;
    }

    &__wrapper {
      cursor: pointer;
      box-sizing: border-box;
      border: 2px solid #ffffff;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    &__mark {
      width: 10px;
      height: 10px;
      background: #fff;
      flex-shrink: 0;
      border-radius: 50%;
    }

    &__default {
     display: none;
    }
  }
`;
