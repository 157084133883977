import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { SociologicalResearchWorkStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const SociologicalResearchWork = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <SociologicalResearchWorkStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_30')}</h1>
        </div>
        <div className="content">
          <div className="item">
            <div className="item-dot" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_31')}</p>
          </div>
          <div className="item">
            <div className="item-dot" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_32')}</p>
          </div>
          <div className="item">
            <div className="item-dot" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_33')}</p>
          </div>
          <div className="item">
            <div className="item-dot" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_34')}</p>
          </div>
          <div className="item">
            <div className="item-dot" />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_35')}</p>
          </div>
        </div>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#F05A25"
          borderColor="#F05A25"
          borderOnHover="#F05A25"
          background="transparent"
          className="btn"
          hoverBackground="#F05A25"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'sociological_research_3',
          )}
        />
      </div>
    </SociologicalResearchWorkStyles>
  );
};
