import styled from 'styled-components';

export const OpportunitiesTargetStyles = styled.div`
  padding: 100px 0;
  background: #fff;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    align-self: flex-end;
    color: #000;
    max-width: 970px;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
    }

    span {
      color: #0000ff;
    }
  }

  .container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;

    .card {
      box-sizing: border-box;
      max-width: 370px;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 25px;
      gap: 26px;
      background: rgba(255, 255, 255, 0.4);
      border: 3px solid #0000ff;
      backdrop-filter: blur(11.65px);

      @media (max-width: 768px) {
        max-width: 320px;
      }

      &:hover {
        background: #0000ff;
        p,
        h6 {
          color: #fff;
        }
      }

      img {
        width: 100%;
      }

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 125%;
        color: #0000ff;
        margin: 0;
      }

      h6 {
        font-weight: 600;
        font-size: 20px;
        line-height: 125%;
        color: #292929;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
`;
