import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { UsageAttitudeApproachStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const UsageAttitudeApproach = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <UsageAttitudeApproachStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_37')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_38')}
          </h1>
        </div>
      </div>
      <div className="cards">
        <div className="cards-item" />
        <div className="cards-item">
          <p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_39',
              )}
            </b>
          </p>
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_40')}
          </p>
        </div>
        <div className="cards-item" />
        <div className="cards-item">
          <p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_41',
              )}
            </b>
          </p>
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_42')}
          </p>
        </div>
        <div className="cards-item" />
        <div className="cards-item">
          <p>
            <b>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_43',
              )}
            </b>
          </p>
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_44')}
          </p>
        </div>
      </div>
      <div className="btn-container">
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#000"
          borderColor="#000"
          borderOnHover="#000"
          background="linear-gradient(135.55deg, #5498FF 0%, #7E6DE4 100.95%)"
          hoverBackground="#000"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'usage_attitude_11',
          )}
        />
      </div>
    </UsageAttitudeApproachStyles>
  );
};
