import styled from 'styled-components';

export const Benefits4OpinionStyles = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    padding: 100px 10px 70px;
    z-index: 5;

    @media (max-width: 768px) {
      gap: 30px;
      padding: 40px 10px 30px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 125%;
    text-align: center;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .cards {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: space-around;

    &-item {
      box-sizing: border-box;
      max-width: 370px;
      height: 325px;
      width: 100%;
      background: linear-gradient(
        52.5deg,
        rgba(0, 0, 255, 0.36) 0%,
        rgba(0, 0, 255, 0) 102.74%
      );
      border: 2px solid #0000ff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

      @media (max-width: 768px) {
        max-width: 350px;
        height: 225px;
        }

      h5 {
        font-weight: 700;
        font-size: 32px;
        line-height: 115%;
        text-align: center;
        color: #ffffff;
        max-width: 290px;

        @media (max-width: 768px) {
          font-size: 22px;
        }
      }

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 115%;
        text-align: center;
        color: #ffffff;
        max-width: 290px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
`;
