import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { AdHocIntroStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const AdHocIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <AdHocIntroStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_1')}</h1>
        <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_2')}</p>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#000"
          textColor="#FFF"
          borderColor="#FFF"
          borderOnHover="#FFF"
          background="none"
          hoverBackground="#FFF"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_3')}
        />
      </div>
    </AdHocIntroStyles>
  );
};
