import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { SociologicalResearchTeamStyles } from './styles';
import team1 from '../../../assets/images/sociologicalResearch/team-1.png';
import team2 from '../../../assets/images/sociologicalResearch/team-2.png';
import team3 from '../../../assets/images/sociologicalResearch/team-3.png';
import team4 from '../../../assets/images/sociologicalResearch/team-4.png';

export const SociologicalResearchTeam = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchTeamStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_59')}</h1>
          <p><span>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_60')}</span></p>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_61')}</p>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_62')}</p>
        </div>
        <div className="cards">
          <div className="cards__item">
            <img src={team1} alt="" />
            <div className="cards__item-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_63')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_64')}</p>
            </div>
          </div>
          <div className="cards__item">
            <img src={team2} alt="" />
            <div className="cards__item-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_65')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_66')}</p>
            </div>
          </div>
          <div className="cards__item">
            <img src={team3} alt="" />
            <div className="cards__item-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_67')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_68')}</p>
            </div>
          </div>
          <div className="cards__item">
            <img src={team4} alt="" />
            <div className="cards__item-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_69')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_70')}</p>
            </div>
          </div>
        </div>
      </div>
    </SociologicalResearchTeamStyles>
  );
};
