import styled from 'styled-components';
import resources from '../../../assets/images/opportunities/resources.png';

export const OpportunitiesResourcesStyles = styled.div`
  padding: 100px 0;
  background: #fff;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    text-align: center;
    color: #000;
    max-width: 1190px;
    margin: 0 auto;

    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
    }

    span {
      color: #0000ff;
    }
  }

  h2 {
    font-weight: 400;
    font-size: 40px;
    line-height: 115%;
    text-align: center;
    color: #000;
    max-width: 1190px;
    margin: 0 auto;
    margin-top: 20px;

    @media (max-width: 991px) {
      font-size: 30px;
    }
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 125%;
    color: #000000;

    @media (max-width: 991px) {
      font-size: 26px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .container {
    margin-top: 50px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'first second third'
      'fourth fifth fifth';
    width: 100%;

    @media (max-width: 991px) {
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 0px 0px;
      grid-template-areas:
        'first second'
        'third fourth'
        'fifth fifth';
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .card {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 40px;
      gap: 10px;
      min-height: 326px;
      background: #ffffff;
      border: 3px solid #0000ff;

      @media (max-width: 1200px) {
        padding: 20px;
      }

      @media (max-width: 991px) {
        padding: 20px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        li {
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          color: #000000;
          list-style-type: disc;
          margin-left: 20px;
          width: 320px;
        }
      }

      &:nth-child(1) {
        grid-area: first;
      }
      &:nth-child(2) {
        grid-area: second;
      }
      &:nth-child(3) {
        grid-area: third;
      }
      &:nth-child(4) {
        grid-area: fourth;
      }
      &:nth-child(5) {
        grid-area: fifth;
        background: url(${resources}) no-repeat;
        background-position: center center;
        background-size: cover;

        h3,
        li {
          color: #fff;
        }
      }
    }
  }
`;
