import { en } from './en';
import { de } from './de';
import { fr } from './fr';
import { uk } from './uk';
import { ro } from './ro';
import { ru } from './ru';
import { kz } from './kz';
import { it } from './it';
import { ge } from './ge';
import { az } from './az';
import { es } from './es';
import { pt } from './pt';

export const translations: {[key: string]: { [key: string]: string}} = {
  en,
  de,
  fr,
  uk,
  it,
  ro,
  'ru-kz': ru,
  'kk-kz': kz,
  ge,
  az,
  es,
  pt,
};
