import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { UsageAttitudeWhomStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const UsageAttitudeWhomOne = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <UsageAttitudeWhomStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_12')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_13')}
          </h1>
          <div className="list-wrapper">
            <p>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_14',
              )}
            </p>
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'usage_attitude_15',
                  ),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'usage_attitude_16',
                  ),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'usage_attitude_17',
                  ),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'usage_attitude_18',
                  ),
                }}
              />
            </ul>
          </div>
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#fff"
            textColor="#000"
            borderColor="#000"
            borderOnHover="#000"
            background="linear-gradient(135.55deg, #5498FF 0%, #7E6DE4 100.95%)"
            hoverBackground="#000"
            hoverTextColor="#fff"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'usage_attitude_11',
            )}
          />
        </div>
      </div>
    </UsageAttitudeWhomStyles>
  );
};
