import styled from 'styled-components';

export const CustomerProfilingSegmentationMethodologyStyles = styled.div`
  position: relative;

  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
      margin-bottom: 500px !important;
    }
  }

  .text-block {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 125%;
      color: #0F0E0C;

      @media (max-width: 768px) {
        font-size: 22px;
      }
    }

    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 125%;
      color: #0F0E0C;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .alert {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 500px;
    transition: all 0.3s ease-in-out;

    @media (max-width: 1200px) {
      max-width: 300px;
    }

    @media (max-width: 991px) {
      bottom: -75%;
      top: inherit;
      max-width: 400px;
      left: 50%;
      transform: translate(-50%);
    }

    @media (max-width: 768px) {
      bottom: -90%;
    }
  }
`;
