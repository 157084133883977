import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { AdHocBenefitStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const AdHocBenefit = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <AdHocBenefitStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_30')}</h1>
        </div>
        <div className="content">
          <div className="cards">
            <div className="cards__item cards__item-one">
              <div className="cards__item-text">
                <span />
                <div />
                <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_27')}</p>
              </div>
            </div>
            <div className="cards__item cards__item-two">
              <div className="cards__item-text">
                <span />
                <div />
                <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_28')}</p>
              </div>
            </div>
            <div className="cards__item cards__item-three">
              <div className="cards__item-text">
                <span />
                <div />
                <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_29')}</p>
              </div>
            </div>
          </div>
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#fff"
            textColor="#fff"
            borderColor="#FCC666"
            borderOnHover="transparent"
            background="transparent"
            className="btn"
            hoverBackground="linear-gradient(6deg, rgba(252, 198, 102, 0.80) -12.03%, rgba(250, 26, 46, 0.60) 51.54%, #5CB7F1 115.1%)"
            hoverTextColor="#fff"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'sociological_research_3',
            )}
          />
        </div>
      </div>
    </AdHocBenefitStyles>
  );
};
