function FourOpinionUser() {
  return (
    <svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 21.2067C21.8561 21.2067 26.6033 16.4594 26.6033 10.6033C26.6033 4.74727 21.8561 0 16 0C10.1439 0 5.39667 4.74727 5.39667 10.6033C5.39667 16.4594 10.1439 21.2067 16 21.2067Z" fill="#9090FF" />
      <path d="M0 40.6012C0 32.2572 6.7634 25.4954 15.1059 25.4954H16.8925C25.2366 25.4954 32 32.2572 32 40.6012H0Z" fill="#9090FF" />
    </svg>
  );
}

export default FourOpinionUser;
