import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { BrandAwarenessUsageAttributeStyles } from './styles';
import img from '../../../assets/images/brandAwarenessUsage/attribute.png';

export const BrandAwarenessUsageAttribute = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <BrandAwarenessUsageAttributeStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_9')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_36')}
          </h2>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_37')}</p>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_38')}</p>
          <img src={img} alt="attribute" className="attribute-img" />
        </div>
      </div>
    </BrandAwarenessUsageAttributeStyles>
  );
};
