import React from 'react';

function TelegramIcon({ width = 25, height = 25, color = '#000' }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 0.5C5.9 0.5 0.5 5.9 0.5 12.5C0.5 19.1 5.9 24.5 12.5 24.5C19.1 24.5 24.5 19.1 24.5 12.5C24.5 5.9 19.1 0.5 12.5 0.5ZM18.38 7.82L16.34 17.66C16.22 18.38 15.74 18.5 15.26 18.14L12.14 15.74C11.42 16.46 10.7 17.06 10.58 17.3C10.34 17.42 10.22 17.66 9.98 17.66C9.62 17.66 9.62 17.42 9.5 17.18L8.42 13.58L5.18 12.5C4.46 12.26 4.46 11.78 5.3 11.42L17.54 6.74C18.02 6.62 18.62 6.86 18.38 7.82ZM15.5 8.9L8.66 13.22L9.74 16.82L9.98 14.42L15.86 9.14C16.22 8.78 15.98 8.78 15.5 8.9Z" fill="white" />
    </svg>
  );
}

export default TelegramIcon;
