import styled from 'styled-components';

export const CxSolutionIntroStyles = styled.div`
  padding: 100px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  & .container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: center;
      gap: 60px;
    }
  }

  .intro-text {
    display: flex;
    flex-direction: column;
    gap: 60px;
    max-width: 870px;

    @media (max-width: 991px) {
      order: 2;
    }

    h1 {
      color: #fff;
      font-size: 64px;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      position: relative;

      @media (max-width: 768px) {
        font-size: 32px;
      }

      &::after {
        position: absolute;
        content: '';
        width: 80%;
        height: 1px;
        background: #fff;
        left: 0%;
        bottom: -30px;
        /* transform: translate(-50%); */
      }

      span {
        color: #02ba95;
      }
    }

    p {
      max-width: 570px;
      color: #fff;
      font-size: 28px;
      font-weight: 400;
      line-height: 125%;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .intro-img {
    max-width: 440px;
    width: 100%;
    position: relative;

    @media (max-width: 991px) {
      order: 1;
    }
    @media (max-width: 768px) {
      max-width: 320px;
    }

    &::before {
      position: absolute;
      content: '';
      width: 282px;
      height: 246px;
      border-radius: 50%;
      background: #02ba95;
      filter: blur(118.05000305175781px);
      right: 0;
      top: 10%;

      @media (max-width: 991px) {
        width: 182px;
        height: 146px;
      }
    }
    &::after {
      position: absolute;
      content: '';
      width: 282px;
      height: 246px;
      border-radius: 50%;
      background: #02ba95;
      filter: blur(118.05000305175781px);
      left: 10%;
      bottom: 10%;

      @media (max-width: 991px) {
        width: 182px;
        height: 146px;
      }
    }

    .intro-logo {
      position: relative;
      z-index: 2;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .intro-circle {
      position: absolute;
      z-index: 3;
      right: -60px;
      bottom: -30px;

      @media (max-width: 991px) {
        max-width: 200px;
        right: 0px;
        bottom: 0px;
      }

      @media (max-width: 768px) {
        max-width: 140px;
        right: 40px;
        bottom: 0px;
      }
    }
  }
`;
