import styled from 'styled-components';

export const TextAreaFieldStyles = styled.div`
  label {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 8px;
    }
  }

  .inputWrap {
    display: flex;
    align-items: center;

    .inputContainer {
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr;
      background: inherit;
    }

    textarea {
      height: 100px !important;
      box-sizing: border-box;
      border-radius: 4px;
      background: inherit;
      border: none;
      outline: none;
      font-weight: 400;
      resize: none;

      font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      font-size: 16px;
      line-height: 20px;

      @media only screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 9px;
        line-height: 11px;
        height: 16px;
      }

      &.animActive {
        &:hover:not(:focus) {
          border-radius: 0;
          box-sizing: border-box;
          border-left: 1px solid transparent;
          animation: 1.2s steps(1) infinite inputAnim;
        }
      }

      &::placeholder {
        font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
          'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          'Helvetica Neue', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        @media only screen and (min-width: 600px) and (max-width: 1000px) {
          font-size: 9px;
          line-height: 11px;
        }
      }
    }
  }

  &.darkTheme,
  &.greenTheme {
    textarea {
      color: #ffffff;

      &::placeholder {
        color: #ffffff;
      }

      &.animActive {
        &:hover:not(:focus) {
          &::placeholder {
            color: #ffffff;
          }
        }
      }

      &.disabled {
        background-color: #fafafa;
      }
    }
  }

  &.lightTheme {
    textarea {
      color: #000;

      &::placeholder {
        color: #000;
      }

      &.animActive {
        &:hover:not(:focus) {
          &::placeholder {
            color: #000;
          }
        }
      }

      &.disabled {
        background-color: #fafafa;
      }
    }
  }

  .error {
    textarea {
      color: #ff0000;

      &::placeholder {
        color: #ff0000;
      }

      &:focus::placeholder {
        color: #ff0000;
      }

      &.animActive {
        &:hover:not(:focus) {
          animation: 1.2s steps(1) infinite inputAnimError;

          &::placeholder {
            color: #ff0000;
          }
        }
      }
    }
  }

  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: #ccc;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 174, 255, 0.04) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(0, 174, 255, 0.03) 100%
    );
  }

  .formErrorContainer {
    font-size: 11px;
    line-height: 16px;
    color: #ff0000;
  }

  .required {
    color: #ff0000;
  }

  @keyframes inputAnim {
    0% {
      border-left: 1px solid transparent;
    }

    50% {
      border-left: 1px solid #fff;
    }

    100% {
      border-left: 1px solid transparent;
    }
  }

  @keyframes inputAnimError {
    0% {
      border-left: 1px solid transparent;
    }

    50% {
      border-left: 1px solid #ff0000;
    }

    100% {
      border-left: 1px solid transparent;
    }
  }
`;
