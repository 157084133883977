import React from 'react';
import { BrandIdentityChartsStyles } from './styles';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import chart1 from '../../../assets/images/brandIdentity/chart-1.png';
import chart2 from '../../../assets/images/brandIdentity/chart-2.png';
import chart3 from '../../../assets/images/brandIdentity/chart-3.png';
import chart4 from '../../../assets/images/brandIdentity/chart-4.png';

export const BrandIdentityCharts = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandIdentityChartsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_37')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_25')}
          </h2>
          <div className="charts">
            <div className="charts-item">
              <img src={chart1} alt="" />
              <div className="charts-content">
                <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_26')}</h6>
                <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_27')}</p>
                <div className="charts-legend">
                  <div className="charts-legend-item">Awateness</div>
                  <div className="charts-legend-item">Ever used</div>
                  <div className="charts-legend-item">Consideration</div>
                </div>
              </div>
            </div>
            <div className="charts-item">
              <div className="charts-content">
                <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_28')}</h6>
                <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_29')}</p>
                <div className="charts-legend">
                  <div className="charts-legend-item">Retation rate</div>
                  <div className="charts-legend-item">Advocacy</div>
                  <div className="charts-legend-item">Trust</div>
                </div>
              </div>
              <img src={chart2} alt="" />
            </div>
            <div className="charts-item">
              <img src={chart1} alt="" />
              <div className="charts-content">
                <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_30')}</h6>
                <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_31')}</p>
                <div className="charts-legend">
                  <div className="charts-legend-item">Value associations</div>
                  <div className="charts-legend-item">Uniqueness</div>
                  <div className="charts-legend-item">Proud to own</div>
                </div>
              </div>
            </div>
            <div className="charts-item">
              <div className="charts-content">
                <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_32')}</h6>
                <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_33')}</p>
                <div className="charts-stats">
                  <div className="charts-stats-item"><b>&gt;30</b> Very poor brand health</div>
                  <div className="charts-stats-item"><b>30-45</b> Poor brand health</div>
                  <div className="charts-stats-item"><b>40-60</b> Average brand health</div>
                  <div className="charts-stats-item"><b>61-75</b> Poor brand health</div>
                  <div className="charts-stats-item"><b>&lt;75</b> Excelent brand health</div>
                </div>
              </div>
              <img src={chart4} alt="" />
            </div>
          </div>
        </div>
      </div>
    </BrandIdentityChartsStyles>
  );
};
