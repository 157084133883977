import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { Poll4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import pollEU from '../../../assets/images/4opinion/poll-eu.png';
import pollSNG from '../../../assets/images/4opinion/poll-sng.png';
import WhatsappIcon from '../../../assets/icons/WhatsappIcon';
import TelegramIcon from '../../../assets/icons/TelegramIcon';

export const Poll4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const getGeoPool = (lang: string): string => {
    switch (lang) {
      case 'ru-kz':
      case 'kk-kz':
      case 'az':
        return pollSNG;
      default:
        return pollEU;
    }
  };

  const getGeoUrl = (lang: string): string => {
    switch (lang) {
      case 'ru-kz':
      case 'kk-kz':
      case 'az':
        return 'https://whatsapp.com/channel/0029VaG21G59cDDUAdLXq83l';
      default:
        return 'https://t.me/+k7C3kzcCo_Q2OWJi';
    }
  };

  const isSng = (lang: string): boolean => {
    switch (lang) {
      case 'ru-kz':
      case 'kk-kz':
      case 'az':
        return true;
      default:
        return false;
    }
  };

  return (
    <Poll4OpinionStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_32')}</h2>
        <div className="pool">
          <img src={getGeoPool(interfaceLanguage)} alt="" />
          <a href={getGeoUrl(interfaceLanguage)} className="pool-btn">
            {isSng(interfaceLanguage) ? (
              <WhatsappIcon />
            ) : (
              <TelegramIcon />
            )}
            <span>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_33')}</span>
          </a>
        </div>
      </div>
    </Poll4OpinionStyles>
  );
};
