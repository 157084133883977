import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { OpportunitiesStyles } from './styles';
import {
  OpportunitiesAgency,
  OpportunitiesBonuses,
  OpportunitiesCX,
  OpportunitiesCases,
  OpportunitiesClients,
  OpportunitiesDescription,
  OpportunitiesIntro,
  OpportunitiesMap,
  OpportunitiesPartnership,
  OpportunitiesProducts,
  OpportunitiesProgram,
  OpportunitiesResources,
  OpportunitiesTarget,
} from '../../components/opportunitiesComponents';

export const Opportunities = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesStyles>
      <OpportunitiesIntro />
      <OpportunitiesProgram />
      <OpportunitiesPartnership />
      <OpportunitiesTarget />
      <OpportunitiesAgency />
      <OpportunitiesCX />
      <OpportunitiesClients />
      <OpportunitiesMap />
      <OpportunitiesResources />
      <OpportunitiesBonuses />
      <OpportunitiesProducts />
      <OpportunitiesDescription />
      <OpportunitiesCases />
    </OpportunitiesStyles>
  );
};
