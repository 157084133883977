import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { CasesStyles } from './CasesStyles';
import CaseItem from '../../components/caseItem/CaseItem';
import ButtonWithArrowOnHover from '../../components/buttonWithArrowOnHover/ButtonWithArrowOnHover';
import EmptyHeaderBlockOnBlackPage from '../../components/emptyHeaderBlockOnBlackPage/EmptyHeaderBlockOnBlackPage';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { useLocation } from 'react-router-dom';
import { IPost } from '../../enteties/IPost';
import workWithResponse from '../../functions/workWithResponse';
import { AdminApi } from '../../api/adminApi';
import Loader from '../../components/loader/Loader';
import MetaFromApi from '../../components/metaFromApi/MetaFromApi';
import { LanguageIDs } from '../../constants/LANGUAGES';

function Cases() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [activePage, setActivePage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(6);
  const [totalPostsCount, setTotalPostsCount] = useState<number>(0);

  const getPosts = useCallback(
    async (page: number) => {
      setIsLoading(true);

      if (loadingError) {
        setLoadingError(false);
      }

      try {
        await workWithResponse(() => AdminApi.getPosts(
          interfaceLanguage,
          { type: '1' },
          limit,
          page * limit,
        )).then(({ error, data: postsData }) => {
          if (!error && postsData) {
            if (page) {
              setPosts((prev) => [...prev, ...postsData.data].filter(
                (el) => el.languageID === LanguageIDs[interfaceLanguage],
              ));
            } else {
              setPosts(postsData.data);
            }

            setTotalPostsCount(postsData.count);
          }
        });
      } catch (e) {
        console.warn(e);
      } finally {
        setIsLoading(false);
      }
    },
    [interfaceLanguage, activePage],
  );

  useEffect(() => {
    getPosts(0);
  }, [interfaceLanguage]);

  useEffect(() => {
    getPosts(activePage);
  }, [location.search, activePage]);

  return (
    <CasesStyles length={posts.length}>
      <MetaFromApi slug="cases" />

      <EmptyHeaderBlockOnBlackPage />
      <div className="titleWrapper">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'cases_14')}</h1>
      </div>

      <div className="container">
        {posts.length ? (
          <>
            <div className="casesList">
              {posts.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  <CaseItem
                    title={item.contentTitle}
                    // hashtags={item.hashtags}
                    link={item.slug}
                    // captions={item.captions}
                    image={item.coverImage}
                  />
                </Fragment>
              ))}
            </div>

            {isLoading && <Loader />}

            {(activePage + 1) * limit < totalPostsCount && (
              <ButtonWithArrowOnHover
                onClick={() => setActivePage((activePageNumber) => activePageNumber + 1)}
                arrowColor="#000"
                hoverTextColor="#000"
                textColor="#FFF"
                className="moreButton"
                text={getTranslationsByLangOrEng(interfaceLanguage, 'more')}
                hoverBackground="#CBCBCB"
                borderColor="#CBCBCB"
                background="transparent"
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </CasesStyles>
  );
}

export default Cases;
