import React from 'react';
import { FooterStyles } from './FooterStyles';
import {
  emailsData,
  marketResearch,
  menuMethodsItems,
  menuOtherItems,
  menuProductsItems,
  numbersData,
  partnerFooterIcons,
  shopperBtn,
  shoppersPlatformUrls,
  socialMediasMenu,
  takeSurveys,
} from '../../constants';
import { Link, useLocation } from 'react-router-dom';
import LightItUpTextIcon from '../../assets/icons/LightItUpTextIcon';
import FooterLogoIcon from '../../assets/icons/FooterLogoIcon';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../buttonWithArrowOnHover/ButtonWithArrowOnHover';

interface Props {
  colorTheme: 'darkTheme' | 'lightTheme' | 'greenTheme';
}

const iconColor = (colorTheme: 'darkTheme' | 'lightTheme' | 'greenTheme') => {
  switch (colorTheme) {
    case 'darkTheme':
      return {
        color: '#FFF',
        circle: 'rgba(255, 255, 255, 0.4)',
      };
    case 'greenTheme':
      return {
        color: '#FFF',
        circle: '#02BA95',
      };
    case 'lightTheme':
      return {
        color: '#000',
        circle: '#0000FF',
      };
    default:
      return {
        color: '#000',
        circle: '#0000FF',
      };
  }
};

const btnColor = (colorTheme: 'darkTheme' | 'lightTheme' | 'greenTheme') => {
  switch (colorTheme) {
    case 'darkTheme':
      return '#fff';
    case 'greenTheme':
      return '#fff';
    case 'lightTheme':
      return '#000';
    default:
      return '#fff';
  }
};

function Footer({ colorTheme }: Props) {
  const { pathname } = useLocation();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <FooterStyles className={colorTheme} id="footer">
      <div className="container">
        <div className="footerContent">
          <div className="logoAndPartnersWrapper">
            <div className="logoContainer">
              <FooterLogoIcon />
              <LightItUpTextIcon
                color={colorTheme === 'lightTheme' ? '#000' : '#FFF'}
              />
            </div>
            <div className="partnersContainer">
              <span>
                {getTranslationsByLangOrEng(interfaceLanguage, 'footer_1')}
              </span>
              <div>
                {partnerFooterIcons.map((iconWithAlt, index) => (
                  <img
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    src={iconWithAlt.icon}
                    alt={iconWithAlt.alt}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="footerBtns">
            <ButtonWithArrowOnHover
              link={shopperBtn(interfaceLanguage)}
              arrowColor={btnColor(colorTheme)}
              textColor={btnColor(colorTheme)}
              borderColor="#0000FF"
              borderOnHover="#0000FF"
              background="transparent"
              hoverBackground="#0000FF"
              hoverTextColor={btnColor(colorTheme)}
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'footer_btn_1',
              )}
              className="footerBtn"
              withoutArrow
            />
            <ButtonWithArrowOnHover
              link={takeSurveys(interfaceLanguage)}
              arrowColor={btnColor(colorTheme)}
              textColor={btnColor(colorTheme)}
              borderColor="#0000FF"
              borderOnHover="#0000FF"
              background="transparent"
              hoverBackground="#0000FF"
              hoverTextColor={btnColor(colorTheme)}
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'footer_btn_2',
              )}
              className="footerBtn"
              withoutArrow
            />
          </div>
          <div className="navAndPrivacyPolicyWrapper">
            <div className="footerNavWrapper">
              <ul className="otherList">
                {menuOtherItems(interfaceLanguage).map((item, index) => {
                  if (item.shoppersPlatform) {
                    return (
                      <li
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        className={
                          item.url === '/' && pathname === '/'
                            ? 'active'
                            : pathname.startsWith(item.url) && item.url !== '/'
                              ? 'active'
                              : ''
                        }
                      >
                        <a
                          href={
                            shoppersPlatformUrls[interfaceLanguage]
                              ? shoppersPlatformUrls[interfaceLanguage]
                              : shoppersPlatformUrls.en
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.name}
                        </a>
                      </li>
                    );
                  }

                  if (item.toOtherSite) {
                    return (
                      <li
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        className={
                          item.url === '/' && pathname === '/'
                            ? 'active'
                            : pathname.startsWith(item.url) && item.url !== '/'
                              ? 'active'
                              : ''
                        }
                      >
                        <a
                          href={`${item.url}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.name}
                        </a>
                      </li>
                    );
                  }

                  return (
                    <li
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      className={
                        item.url === '/' && pathname === '/'
                          ? 'active'
                          : pathname.startsWith(item.url) && item.url !== '/'
                            ? 'active'
                            : ''
                      }
                    >
                      <Link to={`/${interfaceLanguage}${item.url}`}>
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <div className="MethodsListWrapper">
                <Link className="footer-link" to={`/${interfaceLanguage}/products`}>
                  {getTranslationsByLangOrEng(interfaceLanguage, 'footer_2')}
                </Link>
                <ul>
                  {menuMethodsItems(interfaceLanguage).map((item, index) => (
                    <li
                      className={pathname.startsWith(item.url) ? 'active' : ''}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <Link className="item-link" to={`/${interfaceLanguage}${item.url}`}>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="MarketResearchListWrapper">
                <Link className="footer-link" to={`/${interfaceLanguage}/market-research`}>
                  {getTranslationsByLangOrEng(interfaceLanguage, 'footer_7')}
                </Link>
                <ul>
                  {marketResearch(interfaceLanguage).map((item, index) => (
                    <li
                      className={pathname.startsWith(item.url) ? 'active' : ''}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <Link to={`/${interfaceLanguage}${item.url}`}>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="ProductsListWrapper">
                <Link className="footer-link" to={`/${interfaceLanguage}/products`}>
                  {getTranslationsByLangOrEng(interfaceLanguage, 'footer_3')}
                </Link>
                <ul>
                  {menuProductsItems(interfaceLanguage).map((item, index) => {
                    if (
                      (item.onlyInLanguages
                        && item.onlyInLanguages.includes(interfaceLanguage))
                      || !item.onlyInLanguages
                    ) {
                      return item.otherSiteLink ? (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index}>
                          <a
                            target="_blank"
                            className={
                              pathname.startsWith(item.url) ? 'active' : ''
                            }
                            href={item.url}
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </li>
                      ) : (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index}>
                          <Link
                            className={
                              pathname.startsWith(item.url) ? 'active' : ''
                            }
                            to={`/${interfaceLanguage}${item.url}`}
                          >
                            {item.name}
                          </Link>
                        </li>
                      );
                    }

                    return null;
                  })}
                </ul>
              </div>
            </div>

            <div className="privacyPolicyWrapper">
              <div className="socialLinksWrapper">
                <div className="contacts">
                  <span>
                    {getTranslationsByLangOrEng(interfaceLanguage, 'footer_4')}
                  </span>

                  {(interfaceLanguage !== 'kk-kz' && interfaceLanguage !== 'ge')
                    && numbersData(interfaceLanguage).map((number) => (
                      <a href={`tel:${number}`}>{number}</a>
                    ))}

                  <a href={`mailto:${emailsData(interfaceLanguage)}`}>
                    {emailsData(interfaceLanguage)}
                  </a>
                </div>

                <div className="socialLinksContainer">
                  {socialMediasMenu.map((item, index) => {
                    const Icon = item.icon;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <a aria-label="link" href={item.url} className="iconWrapper" key={index}>
                        <Icon
                          color={iconColor(colorTheme).color}
                          circleColor={iconColor(colorTheme).circle}
                          width={29}
                          height={29}
                        />
                      </a>
                    );
                  })}
                </div>
              </div>

              <div className="privacyPolicyContainer">
                <Link to={`/${interfaceLanguage}/privacy-policy`}>
                  <span>
                    {getTranslationsByLangOrEng(interfaceLanguage, 'footer_6')}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FooterStyles>
  );
}

export default Footer;
