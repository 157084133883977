import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CxDiscoverStyles } from './CxDiscoverStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CxDiscover = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CxDiscoverStyles>
      <div className="container">
        <h3>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_4')}
        </h3>
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_5')}
        </h5>

        <div className="discover-wrapper">
          <div className="discover-text">CX</div>
          <div className="discover-fill-5" />
          <div className="discover-fill-4" />
          <div className="discover-fill-3" />
          <div className="discover-fill-2" />
          <div className="discover-fill-1" />

          <div className="discover-item discover-item-one">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_6')}
          </div>
          <div className="discover-item discover-item-two">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_7')}
          </div>
          <div className="discover-item discover-item-three">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_8')}
          </div>
          <div className="discover-item discover-item-four">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_9')}
          </div>
          <div className="discover-item discover-item-five">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_10')}
          </div>
          <div className="discover-item discover-item-six">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_11')}
          </div>
          <div className="discover-item discover-item-seven">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_12')}
          </div>
          <div className="discover-item discover-item-eight">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_13')}
          </div>
          <div className="discover-item discover-item-nine">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_14')}
          </div>
          <div className="discover-item discover-item-ten">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_15')}
          </div>
          <div className="discover-item discover-item-eleven">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_16')}
          </div>
          <div className="discover-item discover-item-twelve">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_17')}
          </div>
          <div className="discover-item discover-item-thirteen">
            {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_18')}
          </div>
        </div>
      </div>
    </CxDiscoverStyles>
  );
};
