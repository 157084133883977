import React, {
  Dispatch, FC,
  SetStateAction, useMemo, useRef, useState,
} from 'react';
import * as Yup from 'yup';
import { CVBannerStyles } from './styles';
import { v4 as uuidv4 } from 'uuid';
import {
  Form, Formik,
  FormikHelpers, FormikProps,
} from 'formik';
import { CloseIcon } from '../../../assets/icons/Close';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { deactivateBanner } from '../../../state/slices/vacanciesSlice';
import { UploadFileButton } from '../../uploadFileButton/UploadFileButton';
import { Api } from '../../../api';
import { AdminApi } from '../../../api/adminApi';

interface FormValues {
  email: string;
  name: string;
  surname: string;
  phone: string;
  cvUrl: string;
}

const initialValues: FormValues = {
  email: '',
  name: '',
  surname: '',
  phone: '',
  cvUrl: '',
};

export const CVBanner = () => {
  const dispatch = useAppDispatch();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { activeBanner, vacancyId } = useAppSelector((state) => state.vacancies);

  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef<any>();

  const handleClose = () => {
    dispatch(deactivateBanner());
  };

  const validationSchema = useMemo(
    () => Yup.object({
      phone: Yup.string().required(
        getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
      ),
      name: Yup.string().required(
        getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
      ),
      email: Yup.string()
        .email(
          getTranslationsByLangOrEng(interfaceLanguage, 'validation_email'),
        )
        .required(
          getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
        ),
    }),
    [interfaceLanguage],
  );

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setLoading(true);
    if (vacancyId) {
      AdminApi.sendVacancyCV(vacancyId, values).then((res) => {
        if (res.statusCode === 201) {
          handleClose();
        }
      });
    } else {
      AdminApi.sendBasicCV(values).then((res) => {
        if (res.statusCode === 201) {
          handleClose();
        }
      });
    }
    setSubmitting(false);
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="banner__form">
        <h3 className="banner__title">{getTranslationsByLangOrEng(interfaceLanguage, 'career_8')}</h3>
        <div className="banner__fields">
          <input
            type="name"
            className="banner__fields-item"
            value={values.name}
            onChange={(e) => setFieldValue('name', e.target.value)}
            placeholder={getTranslationsByLangOrEng(interfaceLanguage, 'career_9')}
          />
          <input
            type="surname"
            className="banner__fields-item"
            value={values.surname}
            onChange={(e) => setFieldValue('surname', e.target.value)}
            placeholder={getTranslationsByLangOrEng(interfaceLanguage, 'career_10')}
          />
          <input
            type="email"
            className="banner__fields-item"
            value={values.email}
            onChange={(e) => setFieldValue('email', e.target.value)}
            placeholder={getTranslationsByLangOrEng(interfaceLanguage, 'career_11')}
          />
          <input
            type="phone"
            className="banner__fields-item"
            value={values.phone}
            onChange={(e) => setFieldValue('phone', e.target.value)}
            placeholder={getTranslationsByLangOrEng(interfaceLanguage, 'career_12')}
          />
        </div>
        <div className="banner__buttons">
          <UploadFileButton
            label={getTranslationsByLangOrEng(interfaceLanguage, 'career_13')}
            formats=".doc,.docx,.pdf"
            fieldName="resume"
            fileIndicator
            onChange={(file) => {
              AdminApi.saveCVFile({ file: file as File }).then((res) => {
                if (res.statusCode === 201) {
                  setFieldValue('cvUrl', res.data.url);
                } else {
                  alert('Something went wrong');
                }
              });
            }}
          />
          <button type="submit">{getTranslationsByLangOrEng(interfaceLanguage, 'career_14')}</button>
        </div>
      </div>
    </Form>
  );

  return (
    <CVBannerStyles className={activeBanner ? 'active' : ''}>
      <div className="banner">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {renderForm}
        </Formik>

        <div className="banner__close" onClick={handleClose}>
          <CloseIcon />
        </div>
      </div>
    </CVBannerStyles>
  );
};
