import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { Expert4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import expertEU from '../../../assets/images/4opinion/expert-eu.png';
import expertSNG from '../../../assets/images/4opinion/expert-sng.png';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const Expert4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const getGeoExperts = (lang: string): string => {
    switch (lang) {
      case 'ru-kz':
      case 'kk-kz':
      case 'az':
        return expertSNG;
      default:
        return expertEU;
    }
  };

  return (
    <Expert4OpinionStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_20')}</h2>
        <div className="content">
          <img src={getGeoExperts(interfaceLanguage)} alt="" />
          <div className="list">
            <div className="list-item">
              <h6>01</h6>
              <ButtonWithArrowOnHover
                link="https://4opinion.eu/"
                arrowColor="#0000FF"
                textColor="#000"
                borderColor="#0000FF"
                borderOnHover="#0000FF"
                background="transparent"
                hoverBackground="transparent"
                hoverTextColor="#000"
                text={getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'four_opinion_21',
                )}
              />
            </div>
            <div className="list-item">
              <h6>02</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_22')}</p>
            </div>
            <div className="list-item">
              <h6>03</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_23')}</p>
            </div>
          </div>
        </div>
      </div>
    </Expert4OpinionStyles>
  );
};
