import styled from 'styled-components';

export const SociologicalResearchWorkStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .btn {
    align-self: flex-end;
  }

  .texts {
    max-width: 770px;
    width: 100%;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .content {
    max-width: 770px;
    width: 100%;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .item {
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-left: 4px solid #F05A25;
    position: relative;

    @media (max-width: 991px) {
      border-left: none;
      border-top: 4px solid #F05A25;
      padding: 25px 15px 0;
      margin-top: 25px;
    }

    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 125%;
      color: #0F0E0C;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 266px;
      height: 100%;
      background: radial-gradient(46.78% 38.53% at 100% 50.22%, rgba(240, 90, 37, 0.15) 0%, rgba(250, 175, 59, 0.00) 100%);
      top: 50%;
      transform: translate(0, -50%);
      right: 100%;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      width: 100%;
      background: radial-gradient(80.78% 107.53% at 50% 100.22%, rgba(240, 90, 37, 0.15) 0%, rgba(250, 175, 59, 0.00) 100%);
      height: 46px;
      left: 50%;
      transform: translate(-50%);
      bottom: 100%;

      @media (max-width: 991px) {
        display: block;
      }
    }

    &-dot {
      position: absolute;
      width: 20px;
      height: 20px;
      background: linear-gradient(180deg, #F05A25 0%, #FAAF3B 100%);
      border-radius: 50%;
      top: 50%;
      transform: translate(0, -50%);
      left: -70px;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }
`;
