import methodology1 from '../../../assets/images/cxSolution/cx-methodology-1.png';
import methodology2 from '../../../assets/images/cxSolution/cx-methodology-2.png';
import methodology3 from '../../../assets/images/cxSolution/cx-methodology-3.png';
import methodology4 from '../../../assets/images/cxSolution/cx-methodology-4.png';
import methodology5 from '../../../assets/images/cxSolution/cx-methodology-5.png';
import methodology6 from '../../../assets/images/cxSolution/cx-methodology-6.png';
import methodology7 from '../../../assets/images/cxSolution/cx-methodology-7.png';
import methodology8 from '../../../assets/images/cxSolution/cx-methodology-8.png';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const sliderData = (
  lang: string,
): { name: string; description: string; img: string }[] => [
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_10'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_58'),
    img: methodology1,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_59'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_60'),
    img: methodology2,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_61'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_62'),
    img: methodology3,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_6'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_63'),
    img: methodology4,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_64'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_65'),
    img: methodology5,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_66'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_67'),
    img: methodology6,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_17'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_68'),
    img: methodology7,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_69'),
    description: getTranslationsByLangOrEng(lang, 'cx_solution_70'),
    img: methodology8,
  },
];
