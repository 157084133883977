import React from 'react';

function FooterLogoIcon({ width = 38, height = 43 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 38 43"
    >
      <path
        fill="url(#paint0_linear_1801_3947)"
        fillRule="evenodd"
        d="M26.24 42.17h-5.001c-.416 0-.815-.162-1.11-.451a1.53 1.53 0 01-.459-1.091V2.62a1.62 1.62 0 01.49-1.164 1.676 1.676 0 011.184-.481h4.793c.443 0 .869.173 1.182.481.314.308.49.727.49 1.163v38.009a1.519 1.519 0 01-.46 1.09 1.57 1.57 0 01-1.109.452zM11.404 9.78h5c.867 0 1.57.691 1.57 1.543v19.589c0 .852-.703 1.542-1.57 1.542h-5c-.867 0-1.569-.69-1.569-1.542V11.323c0-.852.702-1.542 1.569-1.542zM1.569 19.802h5c.867 0 1.57.691 1.57 1.543v9.568c0 .852-.703 1.542-1.57 1.542h-5c-.867 0-1.569-.69-1.569-1.542v-9.568c0-.852.702-1.543 1.569-1.543zm29.505 4.65h5.001c.866 0 1.569.69 1.569 1.543v4.916c0 .852-.703 1.543-1.569 1.543h-5c-.867 0-1.57-.69-1.57-1.543v-4.916c0-.852.703-1.543 1.57-1.543z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1801_3947"
          x1="37.662"
          x2="-4.1"
          y1="-0.693"
          y2="45.273"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED1C2A" />
          <stop offset="0.505" stopColor="#EE2752" />
          <stop offset="1" stopColor="#444A49" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FooterLogoIcon;
