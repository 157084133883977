import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CxMethodologyStyles } from './CxMethodologyStyles';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { useWindowSize } from 'usehooks-ts';
import { SwiperSlide } from 'swiper/react';
import { sliderData } from './utils';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CxMethodology = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { width, height } = useWindowSize();

  return (
    <CxMethodologyStyles>
      <div className="container">
        <h3>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_57')}
        </h3>
      </div>
      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          centeredSlides
          loop={false}
          initialSlide={window.innerWidth > 1500 ? 1 : 0}
          offsetLeft={
            window.innerWidth > 1400 ? (window.innerWidth - 1400) / 2 : 10
          }
          disabledNext
          disabledPrev
          disabledPrevIfSlideActive={window.innerWidth > 1500 ? 1 : 0}
          buttonBorderColor="#02BA95"
          buttonsIconsColor="#fff"
        >
          {sliderData(interfaceLanguage).map((item, index) => (
            <SwiperSlide className="slide" key={item.name}>
              <div className="slide-img">
                <img src={item.img} alt={item.name} />
              </div>
              <div className="slide-text">
                <h5>{item.name}</h5>
                <p>{item.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </CxMethodologyStyles>
  );
};
