import styled from 'styled-components';

export const FacilityCheckAuditStyles = styled.div`
  font-family: 'Montserrat';
  font-weight: 400;

  .container {
    max-width: 1190px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 900px) {
      flex-direction: column;
      gap: 15px;
    }

    h5 {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      background: var(
        --Gradient-Check-Audit,
        linear-gradient(93deg, #9e00ff 0%, #042a40 100.06%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .content {
      max-width: 770px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      h4 {
        color: #0f0e0c;
        font-size: 40px;
        font-weight: 700;
        line-height: 115%;
      }

      p {
        color: #0f0e0c;
        font-size: 28px;
        line-height: 125%;

        span {
          color: #0f0e0c;
          font-family: Montserrat;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 125%;
        }
      }
    }
  }
`;
