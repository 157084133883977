import styled from 'styled-components';

export const BrandAwarenessUsageAttributeStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 125%;
    color: #0F0E0C;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .attribute-img {
    width: 100%;
  }
`;
