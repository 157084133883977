import partner1 from '../../../assets/images/cxSolution/cx-solution-partner1.png';
import partner2 from '../../../assets/images/cxSolution/cx-solution-partner2.png';
import partner3 from '../../../assets/images/cxSolution/cx-solution-partner3.png';
import partner4 from '../../../assets/images/cxSolution/cx-solution-partner4.png';
import partner5 from '../../../assets/images/cxSolution/cx-solution-partner5.png';
import partner6 from '../../../assets/images/cxSolution/cx-solution-partner6.png';
import partner7 from '../../../assets/images/cxSolution/cx-solution-partner7.png';

export const partnersGreenTheme: {
  static: string;
  hover: string;
  alt: string;
}[] = [
  {
    static: partner1,
    hover: partner1,
    alt: 'Socar',
  },
  {
    static: partner2,
    hover: partner2,
    alt: 'Karcher',
  },
  {
    static: partner3,
    hover: partner3,
    alt: 'BAT',
  },
  {
    static: partner4,
    hover: partner4,
    alt: 'Mersedes',
  },
  {
    static: partner5,
    hover: partner5,
    alt: 'Jameson',
  },
  {
    static: partner6,
    hover: partner6,
    alt: 'MEATRO',
  },
  {
    static: partner7,
    hover: partner7,
    alt: 'Toyota',
  },
];
