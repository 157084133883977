import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BrandAwarenessUsageIntroStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';

export const BrandAwarenessUsageIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandAwarenessUsageIntroStyles>
      <Banner
        bgColor="linear-gradient(135.55deg, #BBBAFF 0%, #8E50CC 100.95%)"
        title={`${getTranslationsByLangOrEng(
          interfaceLanguage,
          'brand_awareness_1',
        )}\n${getTranslationsByLangOrEng(
          interfaceLanguage,
          'brand_awareness_2',
        )}`}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'brand_awareness_3',
        )}
        buttonKey="brand_awareness_4"
      />
    </BrandAwarenessUsageIntroStyles>
  );
};
