import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditAboutStyles } from './FacilityCheckAuditAboutStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const FacilityCheckAuditAbout = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditAboutStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_5',
          )}
        </h5>
        <div className="content">
          <h4>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_6',
            )}
          </h4>
          <p>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_7',
            )}
          </p>
          <p>
            <span>
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'facility_check_audit_8',
              )}
            </span>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_7',
            )}
          </p>
        </div>
      </div>
    </FacilityCheckAuditAboutStyles>
  );
});
