import React from 'react';

function ArrowWithoutStick({ color = '#FFF' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="16"
      fill="none"
      viewBox="0 0 9 16"
    >
      <path
        fill={color}
        d="M7 7a1 1 0 000 2V7zm1.707 1.707a1 1 0 000-1.414L2.343.929A1 1 0 00.93 2.343L6.586 8 .929 13.657a1 1 0 101.414 1.414l6.364-6.364zM7 9H8V8 7h-.001-.001-.002-.001-.003-.001-.001-.002-.001-.002-.001-.001-.001-.001-.003-.003-.001-.003-.001-.001-.005-.002-.002-.001-.001-.002-.002-.006-.001-.001-.001-.004-.001-.001-.001-.004-.001-.004-.001-.004-.004-.004-.002-.001-.001-.002-.001-.002-.001-.003-.001H7.9 7.9h-.002-.001-.002-.001-.002-.001-.002-.003-.002-.001-.002-.001-.002-.002-.001-.002-.003-.002-.003-.002-.003-.002-.002-.001-.002-.002-.002-.001-.002-.002-.002-.003-.002-.002-.002-.003-.002-.002-.002-.002-.002-.003-.002-.002-.002-.002-.002-.002-.002H7.8h-.002-.002-.002-.02-.002-.002-.002-.002-.002-.002-.002-.002-.002-.002-.007-.002-.002-.002-.002-.002-.005-.002-.002-.002-.007-.002-.002-.007-.002-.002-.005-.002-.002-.005-.002-.002-.005-.002-.005-.002-.005-.002-.002-.005-.002-.005-.005-.002-.005-.002-.005-.005-.002-.005-.005-.002-.005-.005-.002-.003-.002-.005-.005-.005-.005-.002-.003-.002-.003-.002-.003-.002-.003-.002-.003-.002-.003-.002-.003-.002-.003-.005-.005-.005-.005-.005-.003-.002-.003-.005-.005-.005-.003-.005-.005-.005-.003-.005-.005-.003-.005-.005-.003-.005-.005-.003-.005-.005-.003-.005-.005-.003-.005-.008-.005-.003-.005-.008-.005-.003-.005-.008-.005-.003-.005-.008-.005-.003-.005-.008H7.3h-.003-.005-.008-.005-.003-.005-.005-.003-.005-.008-.005-.003-.005-.005-.008-.005-.005-.003-.005-.005-.005-.003-.005-.005-.005-.003-.005-.005-.005-.005-.005-.003-.002-.003-.002-.003-.002-.003-.002-.003-.002-.003-.002-.003-.002-.003-.002-.003-.002H7.1h-.005-.005-.005-.002-.003-.002-.005-.005-.002-.003-.002-.005-.002-.003-.002-.005-.002-.005-.002-.005-.005-.002-.005-.002-.005-.002-.002-.005H7v2z"
      />
    </svg>
  );
}

export default ArrowWithoutStick;
