import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { CustomerProfilingSegmentationStyles } from './style';
import {
  CustomerProfilingSegmentationTrustedBy,
  CustomerProfilingSegmentationIntro,
  CustomerProfilingSegmentationSlider,
  CustomerProfilingSegmentationAbout,
  CustomerProfilingSegmentationSolution,
  CustomerProfilingSegmentationExperience,
  CustomerProfilingSegmentationExperts,
  CustomerProfilingSegmentationQuote,
  CustomerProfilingSegmentationMethodology,
  CustomerProfilingSegmentationSegmentation,
} from '../../components';

export const CustomerProfilingSegmentation = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CustomerProfilingSegmentationStyles>
      <CustomerProfilingSegmentationIntro />
      <CustomerProfilingSegmentationAbout />
      <CustomerProfilingSegmentationExperience />
      <CustomerProfilingSegmentationSegmentation />
      <CustomerProfilingSegmentationExperts />
      <CustomerProfilingSegmentationMethodology />
      <CustomerProfilingSegmentationQuote />
      <CustomerProfilingSegmentationSlider />
      <CustomerProfilingSegmentationSolution />
      <CustomerProfilingSegmentationTrustedBy />
    </CustomerProfilingSegmentationStyles>
  );
};
