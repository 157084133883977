export const ru: { [key: string]: string } = {
  subscribe_with_click: 'Подпишитесь одним кликом',
  subscribe: 'Подписаться на рассылку',
  form_title: 'Мы свяжемся с вами в ближайшее время',
  form_required: 'Поля, отмеченные *, обязательны для заполнения',
  form_name_placeholder: 'Имя',
  form_email_placeholder: 'Электронная почта',
  form_phone_placeholder: 'Номер телефона',
  form_company_placeholder: 'Компания',
  form_submit: 'Отправить',
  form_1: 'Даю согласие на обработку персональных данных согласно',
  form_2: 'Политика конфиденциальности',
  validation_privacy_policy: 'Пожалуйста, примите Политику конфиденциальности',
  validation_phone: 'Номер телефона недействителен',
  validation_email: 'Неверный формат электронной почты',
  validation_required: 'Обязательные поля не заполнены',
  submit_error: 'Произошла ошибка при сохранении формы',
  footer_1: 'Наши партнеры:',
  footer_2: 'Методы',
  footer_3: 'Продукты',
  footer_4: 'Контакты:',
  footer_5: 'Выходные данные',
  footer_6: 'Политика конфиденциальности',
  about_us_1: 'Корпоративная социальная ответственность 4Service',
  about_us_2:
    'От сердца и от души каждого члена нашей команды. Мы поддерживаем искры доброты в каждом нашем сотруднике и стремимся сделать мир вокруг нас немного лучше.',
  about_us_3: 'Корпоративная социальная ответственность 4Service',
  about_us_4:
    'От сердца и от души каждого члена нашей команды. Мы поддерживаем искры доброты в каждом нашем сотруднике и стремимся сделать мир вокруг нас немного лучше.',
  about_us_5: 'Деловая игра Service Mania',
  about_us_6: 'Наши информационные инициативы для детей и студентов',
  about_us_7: 'Благотворительность/волонтерство',
  about_us_8:
    'Детские дома, дома престарелых и волонтерство в украинской армии. Самая трогательная часть нашей деятельности',
  about_us_9: 'Благополучие животных',
  about_us_10:
    'Любой желающий может прийти к нам в офис с питомцем. Мы помогаем приютам, собираем средства на еду, а иногда и пытаемся найти дом для бездомных животных.',
  about_us_11: 'Группа 4Service',
  about_us_12:
    'Международная исследовательская компания, занимающаяся управлением клиентским опытом.',
  about_us_13:
    'Реализуем комплексные проекты для увеличения продаж и развития бизнеса клиентоориентированных компаний.',
  about_us_14:
    '4Service — международный исследовательский холдинг со штаб-квартирой в Вене (Австрия). Компания была основана в 2001 году, чтобы улучшать качество жизни своих клиентов и людей, которые взаимодействуют с бизнесами, пользуясь их товарами и услугами. Название «Forservice» было выбрано неслучайно — компания воплощает высокие стандарты обслуживания клиентов.',
  about_us_15:
    'История 4Service началась в Киеве (Украина), где компания стала ведущим поставщиком услуг тайных покупок. В качестве исследовательского холдинга мы успешно работаем в 52 странах, открывая новые возможности комплексного подхода к анализу и улучшению всей цепочки клиентского опыта.',
  about_us_16: 'основание во имя сервиса',
  about_us_17: 'первый офис за пределами Украины',
  about_us_18: 'перенос штаб-квартиры в Вену',
  about_us_19: 'элитный член MSPA, топ-10 агентств в Европе',
  about_us_20: 'открытие офисов в 9 странах',
  about_us_21: 'первые цифровые проекты, Voicer',
  about_us_22: 'партнеры Академии DesignThinkers',
  about_us_23: 'первые глобальные CХ-проекты',
  about_us_24: 'Миссия',
  about_us_25:
    'Наша миссия — улучшать обслуживание клиентов по всему миру! Нас вдохновляют качественные изменения, которые мы наблюдаем в людях и компаниях, с которыми работаем.',
  about_us_26: 'Более 20 лет опыта работы с клиентами',
  about_us_27: '95 стран',
  about_us_28: 'Мы собираем и делимся лучшим отраслевым опытом со всего мира',
  about_us_29: 'Главный офис в Вене',
  about_us_30:
    'стран. Мы собираем и делимся лучшим отраслевым опытом со всего мира',
  about_us_31: 'оценок качества обслуживания',
  about_us_32:
    'мы входим в топ-10 европейских агентств по управлению клиентским опытом',
  about_us_33: 'представительств и штаб-квартира в Австрии',
  about_us_34: 'активных проектов',
  about_us_35: 'Ваше будущее место работы',
  about_us_36: 'Наши офисы',
  about_us_37:
    '4Service™ работает над созданием комфортного рабочего пространства для своих сотрудников. Для своего удобства и максимальной продуктивности каждый сотрудник может сам выбрать формат работы: в офисе, удаленно или гибридный. Дополнительно компания позаботилась о наличии комнат для отдыха, бесплатной психологической поддержки и доброжелательной атмосферы. А еще мы дружелюбны не только к людям, но и к домашним питомцам.',
  about_us_38: 'Блестящая команда',
  about_us_39:
    'Отзывы наших клиентов и взаимное развитие — приоритет для нашей многонациональной команды. Путь нашего клиента во время сотрудничества является таким же важным для нашего бизнеса, как запуск новых продуктов или достижение финансовых целей. Мы работаем, чтобы создавать положительное влияние на сообщества и предлагать нашим клиентам инновационные решения, ориентированные на людей.',
  about_us_40: 'Все',
  about_us_41: 'Управление',
  about_us_42: 'Всемирная команда',
  about_us_43: 'Владельцы продукта',
  about_us_44: 'Наши партнеры',
  about_us_45:
    'Реализуем комплексные проекты по увеличению продаж и развитию бизнеса клиентоориентированных компаний',
  about_us_46:
    'является одним из ведущих институтов профессионального обучения, ориентированных на дизайн. Он представлен в 25 странах. Здесь учатся, развиваются и поддерживаются творческие мультидисциплинарные команды и сообщества. Дизайн-мышление позволяет разрабатывать инновационные идеи, предлагать изменения и решать сложные проблемы.',
  about_us_47:
    'Их миссия - предоставлять пространство и время для вдохновения и вызовов командам и отдельным лицам, чтобы они могли "учиться, делая" и укреплять свою способность к креативному решению проблем, а также помогать организациям создавать необходимую среду и мышление для запуска смелых инноваций.',
  about_us_48:
    'является представителем Торговой ассоциации компаний, занимающихся индустрией Mystery Shopping. Ассоциация работает на региональной основе по всему миру, ее отделения расположены в Америке, Европе, Африке, Азии и в Тихоокеанском регионе. Компании-члены объединяются, чтобы совместными усилиями укреплять индустрию Mystery Shopping.',
  about_us_49:
    'Это некоммерческая организация, функционирующая на основе членства. Ее участники образуют сообщество, убежденное, что анализ данных, исследования и идеи могут улучшить жизнь общества, организаций и каждого человека.',
  about_us_50:
    'Штаб-квартира ESOMAR находится в Амстердаме, Нидерланды. ESOMAR присутствует в более чем 130 странах через своих членов с 1947 года.',
  about_us_51:
    ' - профессиональное сообщество частных специалистов и компаний, объединенных целью развития и взаимодействия в сфере контакт-центра и обслуживания клиентов. Философия UCCA — повышение культуры индустрии контакт-центров на основе современных технологий и высоких стандартов.',
  about_us_52:
    'изучает лучший мировой опыт в сфере образования, чтобы адаптировать и использовать его на украинском рынке. Именно поэтому Академия ДТЭК сотрудничает с ведущими международными бизнес-школами: INSEAD, IE Business School и Stanford University.',
  about_us_53: ' ',
  about_us_54:
    'Академия ДТЭК ориентирована на развитие клиентоориентированной и инновационной культуры в D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, цифровой программе DX School, Effective Leader: Pre-MBA, Change Energy.',
  about_us_55: 'Сергей Пашковский',
  about_us_56: 'Виктория Скорбота',
  about_us_57: 'Артем Фомин',
  about_us_58: 'Макс Папка',
  about_us_59: 'Менеджер по международным продажам',
  bht_1: 'Отслеживание',
  bht_2: 'здоровья бренда',
  bht_3: 'Бренды обладают невероятной силой',
  bht_4: 'Узнайте, насколько хорошо работает ваш бренд в целом',
  bht_5: 'О',
  bht_6: 'Что такое отслеживание здоровья бренда (Brand Health Tracking)?',
  bht_7:
    'Это тип исследования рынка, которое проводится с определенной периодичностью (каждые две недели, каждый месяц и т. д.) с целью получения информации о',
  bht_8:
    'статусе бренда на рынке на основе таких показателей, как известность, спрос, имидж, характеристики его потребителей, здоровье бренда и т. д.',
  bht_9: 'Покупают бренд чаще всего',
  bht_10: 'Лояльный',
  bht_11: 'Покупают бренд раз в 3 месяца',
  bht_12: 'Постоянные клиенты',
  bht_13: 'Покупают бренд раз в 6 месяцев',
  bht_14: 'Клиенты в краткосрочной перспективе',
  bht_15: 'Покупают бренд раз в год',
  bht_16: 'Клиенты в долгосрочной перспективе',
  bht_17: 'Знакомы с брендом, но ни разу не пользовались/не покупали',
  bht_18: 'Знакомы, но не являются клиентами',
  bht_19: 'Не ознакомлены: никогда не слышали о бренде',
  bht_20: 'Не ознакомлены',
  bht_21: 'Профиль покупателей бренда',
  bht_22:
    'Оценка последовательности усилий, приложенных в долгосрочной перспективе для создания бренда',
  bht_23: 'Измерение уровня знаний клиентов о бренде',
  bht_24: 'Демонстрация эффективности рекламных и информационных кампаний',
  bht_25:
    'Определение влияния периода активной рекламы на продажи/маркетинговую деятельность конкурентов',
  bht_26:
    'Обеспечение постоянного контроля владельца бизнеса в периоды непрерывных рыночных изменений',
  bht_27: 'Какие задачи может решить ОЗБ:',
  bht_28: 'Запросить исследование',
  bht_29: 'Решаемая задача',
  bht_30: 'Например,',
  bht_31:
    'если компании требуется только один сеанс ВЗБ в квартал при измерении долей рынка, опрос респондентов следует проводить чаще, измеряя узнаваемость бренда по рекламе среди клиентов.',
  bht_32: 'CATI',
  bht_33: 'Интервью',
  bht_53: 'один на один',
  bht_34: 'CAWI',
  bht_35: 'Решаемая задача',
  bht_36: 'Собирая информацию',
  bht_37:
    'с помощью различных методов, отслеживание здоровья бренда обычно делится на три этапа в зависимости от целевой аудитории и сложности ее охвата:',
  bht_38:
    'Мы используем как традиционные, так и инновационные методы сбора данных.',
  bht_39:
    'Мы работаем с двумя моделями: одна с простым набором показателей, а другая нацелена на более сложные отрасли, такие как пищевая промышленность и сфера услуг. Более сложная модель предполагает углубленное исследование, включающее в себя до 10 расчетных показателей.',
  bht_40: 'Мы предлагаем',
  bht_41: 'контроль исходных данных',
  bht_42: 'Мы покрываем',
  bht_43: 'всю страну',
  bht_44: 'Мы предлагаем решение',
  bht_45:
    'для нединамичных рынков/рынков с ограниченным количеством игроков (Brand Point Analyze)',
  bht_46: 'Почему мы?',
  bht_47: 'Почему выбирают нас?',
  bht_50: 'Плохой результат',
  bht_51: 'Хороший результат',
  bht_52: 'Средний результат',
  cases_1:
    'Задача: как повысить качество обслуживания клиентов при покупке электромобилей в автосалонах Hyundai?',
  cases_2: 'Карта пути клиента',
  cases_3: 'Идея',
  cases_4: 'Качественные исследования',
  cases_5:
    'Вызов: Что нужно изменить в магазинах (как в процессе обслуживания, так и в интерьере, в восприятии бренда), чтобы вызвать интерес покупателей к бренду Oppo?',
  cases_6: 'Онлайн CJM',
  cases_7: 'Тестирование пользовательского интерфейса',
  cases_8: 'Интервью',
  cases_9:
    'Вызов: Что нужно изменить в магазинах, чтобы вызвать интерес покупателей к бренду Oppo?',
  cases_10: 'Качественная часть',
  cases_11: 'Идея',
  cases_12: 'Количественная часть',
  cases_13: 'Карта пути клиента',
  cases_14: 'Кейсы',
  cases_15: 'Все',
  cases_16: 'Давайте создадим ваш отличный кейс вместе',
  csi_1: 'Индекс',
  csi_2: 'удовлетворенности клиента',
  csi_3: 'Определяем причины удовлетворенности',
  csi_4: 'и неудовлетворенности потребителей',
  csi_5: 'Исследование удовлетворенности клиентов',
  csi_6:
    'определение факторов, причин и мотивов, влияющих на решение покупателя о покупке, а также на поведение целевой аудитории.',
  csi_7: 'Опрос удовлетворенности клиентов',
  csi_8:
    'показывает, насколько клиент удовлетворен или не удовлетворен определенным аспектом услуги или продуктом в целом.',
  csi_9: 'Опрос удовлетворенности',
  csi_10:
    'проводится среди существующих клиентов компании и пользователей определенных продуктов/услуг.',
  csi_11: 'Что такое CSI?',
  csi_12:
    'Удовлетворенность клиентов измеряет степень удовлетворения ожиданий клиентов в отношении покупки продукта или услуги, предоставляемых вашей компанией.',
  csi_13: 'Счастливый человек',
  csi_14: 'О программе',
  csi_15: 'Определить любые скрытые факторы,',
  csi_16: 'влияющие на удовлетворенность клиентов',
  csi_17: 'Определить причины',
  csi_18: 'роста/падения спроса на товары и услуги',
  csi_19: 'Оптимизируем',
  csi_20: 'бизнес-процессы компании',
  csi_21: 'Адаптировать их',
  csi_22: 'для нужд современного потребителя',
  csi_23: 'Сформировать эффективное рекламное сообщение',
  csi_24: 'и позиционировать товар под запрос клиента',
  csi_25: 'Задачи для решения',
  csi_26: 'Какие задачи могут решить опросы об удовлетворенности клиентов',
  csi_27: 'Человек с телефоном',
  csi_28: 'Мы строим математическую модель',
  csi_29:
    'факторов, которые могут повлиять на удовлетворенность/лояльность клиентов (регрессионный анализ)',
  csi_30: 'Оптимизируем',
  csi_31: 'бизнес-процессы компании',
  csi_32: 'Мы строим расчетный индекс удовлетворенности клиентов',
  csi_33: '(CSI) и сравниваем его с декларативным',
  csi_34: 'Мы проводим исследование',
  csi_35: 'для сегментов B2B и B2C.',
  csi_36: 'Наши анкеты разрабатываются командой',
  csi_37: 'аналитиков специально для индивидуальных проектов наших клиентов',
  csi_38: 'Почему мы',
  csi_39: 'Почему выбирают нас?',
  csi_40: 'Методы сбора информации:',
  main_1:
    'бизнесов улучшают свои финансовые показатели, изучая опыт своих клиентов',
  main_2:
    'Консультации и услуги в сфере клиентского опыта, взгляд 360° на ваш бизнес',
  main_3:
    'Откройте для себя наши решения и инструменты, которые помогут реализовать ваши бизнес-задачи',
  main_4: 'Продукты',
  main_5:
    'Качественное исследование — это сбор и анализ нечисловых данных для понимания концепций, мнений или опыта',
  main_6:
    'Количественные методы исследования — это объективное измерение и числовой анализ данных, собранных с помощью опросов и анкетирования',
  main_7:
    'Тайный покупатель — это метод исследования рынка для измерения и мониторинга взаимодействия между покупателем и компанией или организацией в рамках заранее определенного сценария',
  main_8:
    'Система управления обратной связью. Голос вашего клиента в одной системе Customer Feedback Management System',
  main_9:
    'Платформа для анализа контента на основе ИИ, предоставляющая информацию от аудитории',
  main_10:
    'Программное обеспечение для управления производительностью. Геймифицированное приложение для увеличения продаж',
  main_11:
    'Ценовой мониторинг — это процесс сбора, обработки и анализа цен конкурентов',
  main_12:
    'Сервис-дизайн — это понимание потребности и желания людей, которые будут пользоваться продуктом или услугой, проводить с ними время',
  main_13:
    'Наша аналитическая команда применяет самые современные и фундаментальные подходы, эффективность которых подтверждена опытом многих клиентов',
  main_14: 'Методологии',
  main_15: 'Карта путешествия клиента',
  main_16:
    'Составление карты путешествия клиента (CJM) — это определение целевой аудитории, понимание их потребностей и среды, а затем создание плана для обеспечения привлекательного опыта',
  main_17: 'CJM',
  main_18: 'Чистая оценка продвижения',
  main_19:
    'Показатель Net Promoter Score (NPS) — это золотой стандарт показателей качества обслуживания клиентов, основанный на одном вопросе: насколько вероятно, что вы порекомендуете организацию/продукт/услугу?',
  main_20: 'NPS',
  main_21: 'Отслеживание здоровья бренда',
  main_22:
    'Исследование Отслеживание здоровья бренда (ОЗБ) может измерить, как ваш бренд работает с точки зрения узнаваемости и использования, позиционирования бренда и эффективности бренда',
  main_23: 'ВНТ',
  main_24: 'Исследования человеческих ресурсов',
  main_25:
    'Исследования человеческих ресурсов используются для оценки практики и эффективности работы с персоналом и предлагают подробный анализ текущего развития и управления',
  main_26: 'Кадровые исследования',
  main_27: 'Индекс удовлетворенности клиентов',
  main_28:
    'Индекс удовлетворенности клиентов (CSI) – это показатель, показывающий, насколько ваши клиенты удовлетворены продуктами/услугами компании.',
  main_29: 'CSI.',
  main_30: 'UX-тестирование',
  main_31:
    'Юзабилити-тестирование (UX) — это наблюдение за тем как реальные люди взаимодействуют с веб-сайтом, приложением или другим продуктом, их поведением и реакцией на него',
  main_32: 'Почему мы',
  main_33:
    'Наша миссия — помочь компаниям создавать положительный клиентский опыт',
  main_34: 'Качество — прежде всего',
  main_35: 'Мы контролируем качество',
  main_36:
    'данных исследований. Все наборы данных являются доступными для клиентов',
  main_37: 'Экономическая эффективность',
  main_38: 'Наш опыт, ресурсы и оптимизация',
  main_39: 'бизнес-процессов — это то, что сэкономит ваши деньги и время',
  main_40: 'Умные решения',
  main_41: 'Мы используем разные техники',
  main_42: 'и инструменты, чтобы решать конкретные проблемы',
  main_43: 'Упор на качество',
  main_44: 'Мы контролируем качество',
  main_45:
    'когда дело доходит до исследовательских данных. Мы делаем все наборы данных доступными для клиентов',
  main_46: 'Эффективно и экономно',
  main_47: 'Наш опыт, ресурсы и оптимизация',
  main_48: 'бизнес-процессы позволяют нам экономить ваши деньги и время',
  main_49: 'Умные решения',
  main_50: 'Мы используем разные техники',
  main_51: 'и инструменты для решения ваших конкретных проблем',
  main_52: 'Cash & Carry',
  main_53: 'Электроника',
  main_54: 'Люкс/Премиум',
  main_55: 'Рестораны и гостиничный бизнес',
  main_56: 'Розничная торговля',
  main_57: 'Автомобиле- строение',
  main_58: 'Мода',
  main_59: 'Банки',
  main_60: 'Путешествия и отдых',
  main_61: 'Часы и украшения',
  main_62: 'Бытовая техника',
  main_63: 'Блог',
  main_64: 'Перейдите в блог, чтобы прочитать больше интересных бизнес-историй',
  main_65: 'Розничный аудит',
  banner_1: 'СX консалтинг и услуги, позволяющие увидеть ваш бизнес на 360°',
  banner_2: 'Будьте в курсе последних исследований!',
  banner_3:
    'Я даю согласие на обработку персональных данных в соответствии с Политикой конфиденциальности',
  banner_4: '*Выберите рынок, по которому вы хотели бы получать информацию',
  banner_5: 'Спасибо за вашу подписку!',
  banner_6: 'Мы поделимся с вами только самым интересным контентом!',
  banner_7: 'Отправить',
  banner_8: 'Украина',
  banner_9: 'Казахстан',
  cjm_1: 'Карта',
  cjm_2: 'пути клиента',
  cjm_3: 'Погрузитесь в мир клиентов и разделите их опыт',
  cjm_4: 'Что такое карта пути клиента?',
  cjm_5:
    'CJM (Customer Journey Map) предоставляет визуализированную историю взаимодействия потребителя с продуктом, услугой, компанией или брендом по различным каналам и в течение определенного периода времени.',
  cjm_6:
    'Это позволяет компаниям объективно анализировать опыт взаимодействия с клиентами, фиксировать и устранять возникающие барьеры и давать рекомендации по улучшению продукта.',
  cjm_7: 'Определение ЦА, сегментация',
  cjm_8: 'Выявление этапов взаимодействия с клиентом',
  cjm_9: 'Выявление основных каналов взаимодействия с клиентами',
  cjm_10: 'Исследование клиентского опыта',
  cjm_11: 'Анализ барьеров и гипотеза по оптимизации',
  cjm_12: 'Выполнение и проверка гипотез',
  cjm_13: 'Создание карты пути клиента состоит из следующих шагов:',
  cjm_14: 'Карта пути клиента продемонстрирует:',
  cjm_15:
    'CJM предоставляет визуализированную историю взаимодействия потребителя с продуктом, услугой, компанией или брендом по различным каналам и в течение определенного периода времени.',
  cjm_16: 'Мы обеспечиваем командную работу',
  cjm_17: 'между аналитиками и дизайнерами',
  cjm_18: 'Предлагаем индивидуальный',
  cjm_19: 'подход к каждому клиенту',
  cjm_20: 'Мы владеем инструментами',
  cjm_21:
    'для увеличения удовлетворенности клиентов услугой/продуктом и, в конечном счете, повышения их лояльности к компании',
  cjm_22: 'Мы гарантируем',
  cjm_23: 'оптимизацию пути потребителя и учет бизнес-процессов компании',
  cjm_24: 'Мы предлагаем нашим клиентам',
  cjm_25:
    'возможность использования комбинированных подходов: CATI, CAWI, фокус-группы, глубинные интервью, экспертные интервью, этнография и др.',
  cjm_26: 'Почему выбирают нас?',
  methods_hr_1: 'Исследования управления персоналом',
  methods_hr_2:
    'Получите объективную информацию о своей команде и узнайте, как улучшить работу отдела кадров',
  methods_hr_3:
    'Несколько причин, по которым вам может понадобиться HR-исследование',
  methods_hr_4: 'опрошенных сотрудников',
  methods_hr_5: 'реализованных HR-проектов',
  methods_hr_6: 'проведенных обучений персонала',
  methods_hr_7:
    'Ведущий европейский холдинг в области клиентского опыта и исследований сотрудников',
  methods_hr_8: 'Кадровые исследования 4service в цифрах',
  methods_hr_9:
    'Выявление проблем и слабых сторон на каждом этапе жизни сотрудников вашей компании: поиск/подбор/ садаптация/карьерный рост/переаттестация',
  methods_hr_10: 'Чтобы знать, какие случаи заслуживают прибавок и бонусов',
  methods_hr_11:
    'Вам необходимо прогнозировать или предлагать решения неудовлетворенности сотрудников',
  methods_hr_12:
    'Благодаря HR-исследованиям возможно внедрение новых методов оценки кадровой политики, современных программ и практик',
  methods_hr_13: 'Для улучшения психического здоровья сотрудников компании',
  methods_hr_14:
    'Если вы хотите повысить производительность с помощью целеустремленного подхода',
  methods_hr_15:
    'Если у вас есть международная и/или удаленная команда, и вам нужно ею управлять',
  methods_hr_16:
    'Чтобы понять, насколько привлекателен ваш бренд работодателя для потенциальных сотрудников',
  methods_hr_17: 'Удовлетворенность сотрудников',
  methods_hr_18: 'Исследование HR-брендинга',
  methods_hr_19: 'Культурное разнообразие',
  methods_hr_20: 'Компенсации и зарплатные планы',
  methods_hr_21: 'Вовлеченность и лояльность команды',
  methods_hr_22: 'Сотрудники защищенного класса',
  methods_hr_23: 'Области исследований',
  methods_hr_24: 'Решение 1',
  methods_hr_25: 'eNPS, удовлетворенность сотрудников, лояльность сотрудников',
  methods_hr_26: 'Что мы измеряем:',
  methods_hr_27: 'Лояльность:',
  methods_hr_28:
    'лояльность сотрудников, прогнозирование будущего поведения, определение веса факторов, влияющих на лояльность',
  methods_hr_29: 'Участие:',
  methods_hr_30:
    'вовлеченность сотрудников в работу, заинтересованность в результатах и достижениях',
  methods_hr_31: 'Вовлечение:',
  methods_hr_32: 'идентификация ценностей сотрудников и ценностей компании',
  methods_hr_33: 'Доверие 360:',
  methods_hr_34:
    'доверие к руководству, коллегам, подчиненным, доверие к стратегиям и политике работодателя',
  methods_hr_35: 'Удовлетворение:',
  methods_hr_36:
    'определение уникальных факторов для каждой компании, оценка удовлетворенности по каждому ключевому фактору, влияющему на лояльность',
  methods_hr_37: 'Решение 2',
  methods_hr_38: 'Бренд работодателя',
  methods_hr_39: 'Внутренний анализ',
  methods_hr_40:
    'бренда работодателя (опрос сотрудников, стейкхолдеров, внутренних рекрутеров и HR-специалистов)',
  methods_hr_41: 'Внешний анализ работодателя',
  methods_hr_42:
    '(анализ EVP конкурентов, опрос внешних рекрутеров, опрос соискателей)',
  methods_hr_43: 'Узнаваемость бренда и репутация',
  methods_hr_44: 'анализ',
  methods_hr_45: 'Присутствие работодателя',
  methods_hr_47: 'Справочник работодателя',
  methodshr_48: 'коллекция',
  methods_hr_49: 'Конкурентный бенчмаркинг',
  methods_hr_50: 'по ключевым факторам, таким как:',
  methods_hr_51: 'Зарплата, ',
  methods_hr_52: 'рабочая среда, атмосфера, характер работы',
  methods_hr_53: 'Анализ потребностей и ожиданий',
  methods_hr_54: 'соискателей',
  methods_hr_55: 'Подробные интервью',
  methods_hr_56: 'используются кабинетные и количественные исследования',
  methods_hr_57: 'Решение 3',
  methods_hr_58: 'Карта пути сотрудника/тайный сотрудник',
  methods_hr_59: 'Построение пути поиска работы,',
  methods_hr_60:
    'определение основных этапов поиска, каналов коммуникации, критериев отбора и оценивания соискателей',
  methods_hr_61: 'Мониторинг всех этапов и каналов связи',
  methods_hr_62:
    'с помощью тайных соискателей (запись на собеседование, прохождение собеседования, стажировка в компании)',
  methods_hr_63: 'Идентификация',
  methods_hr_64: 'слабых мест в процессах найма и адаптации',
  methods_hr_65: 'Рекомендации по улучшению,',
  methods_hr_66: 'сравнительный анализ с лидерами области',
  mystery_shopping_1: 'Тайный',
  mystery_shopping_2: 'покупатель',
  mystery_shopping_3: 'Оцените свой сервис с реальными клиентами',
  mystery_shopping_4:
    'Проверьте стандарты, чтобы ваш персонал стал ближе к вашему клиенту',
  mystery_shopping_5:
    'Мы являемся элитным членом ведущей профессиональной ассоциации поставщиков услуг Mystery Shopping Providers MSPA Europe',
  mystery_shopping_6: 'опыта',
  mystery_shopping_7: 'посещений ежемесячно',
  mystery_shopping_8: 'тайных покупателей по всему миру',
  mystery_shopping_9: 'активных проектов',
  mystery_shopping_10: 'офисов, штаб-квартира в Австрии',
  mystery_shopping_11: 'поставщиков услуг тайного покупателя в Европе',
  mystery_shopping_12: 'рейтингов качества обслуживания',
  mystery_shopping_13: 'Анкета',
  mystery_shopping_14: 'Программное обеспечение',
  mystery_shopping_15: 'Профиль покупателя',
  mystery_shopping_16: 'Подтверждение и возражение',
  mystery_shopping_17: 'Аналитика',
  mystery_shopping_18:
    'Этими 5 шагами наша компания 4Service гарантирует качество услуги тайного покупателя',
  mystery_shopping_19: 'Программа',
  mystery_shopping_20: '5 элементов программы Mystery Shopping от 4Service',
  mystery_shopping_21: 'Анкета:',
  mystery_shopping_22: 'основа вашей программы',
  mystery_shopping_23:
    'Эта часть программы доступна для просмотра всем заинтересованным лицам',
  mystery_shopping_24: 'Для топ-менеджеров.',
  mystery_shopping_25: 'Показать все важные шаги и детали процесса',
  mystery_shopping_26: 'Для сотрудников.',
  mystery_shopping_27: 'Отражение ожиданий работодателей',
  mystery_shopping_28: 'Для тайных покупателей.',
  mystery_shopping_29:
    'Четко и структурировано, чтобы избежать путаницы и предвзятости',
  mystery_shopping_30: 'Программное обеспечение Shopmetrics:',
  mystery_shopping_31: ' удобное и инновационное',
  mystery_shopping_32: 'Преимущество:',
  mystery_shopping_33: 'Интегрируется',
  mystery_shopping_34: 'в ваши бизнес-процессы и программное обеспечение',
  mystery_shopping_35: 'Регламент CDPR',
  mystery_shopping_36: 'Инструментарий управления конфиденциальностью',
  mystery_shopping_37: 'Подробный',
  mystery_shopping_38: 'пакет соответствия законодательным требованиям',
  mystery_shopping_39: 'Автоматизированное',
  mystery_shopping_40: 'построение отчетов',
  mystery_shopping_41: 'Управление',
  mystery_shopping_42: 'уровнями доступа',
  mystery_shopping_43: 'Готовность в течение часа',
  mystery_shopping_44: 'для большинства анкет',
  mystery_shopping_45: 'Профиль покупателя:',
  mystery_shopping_46: 'более 200 000 тайных покупателей по всему миру',
  mystery_shopping_47:
    'Основные критерии: максимальная близость к вашей целевой аудитории, наличие предыдущего опыта работы с вашей компанией или конкурентами',
  mystery_shopping_48: 'Обучение покупателей:',
  mystery_shopping_49: 'Вводный обучающий тест',
  mystery_shopping_50: 'Скайп/телефонный звонок',
  mystery_shopping_51: 'Оценочный тест (необязательно)',
  mystery_shopping_52: 'Подтверждение и возражение',
  mystery_shopping_53: 'Система',
  mystery_shopping_54: 'подтверждений',
  mystery_shopping_55: '100% отчетов',
  mystery_shopping_56: 'проверяются группой валидаторов',
  mystery_shopping_57: 'Дополнительные звонки',
  mystery_shopping_58: 'для разъяснения',
  mystery_shopping_59: 'Аудиозаписи,',
  mystery_shopping_60: 'чтобы после визита отметить самые важные моменты',
  mystery_shopping_61: 'Система профилактики',
  mystery_shopping_62: 'мошенничества сотрудников',
  mystery_shopping_63: 'Система',
  mystery_shopping_64: 'возражений',
  mystery_shopping_65: 'Сотрудник может оспорить любую оценку',
  mystery_shopping_66: 'покупателя прямо на сайте',
  mystery_shopping_67: 'Каждое возражение',
  mystery_shopping_68:
    'рассматривается и дается ответ местной командой валидаторов',
  mystery_shopping_69: 'Вы можете посмотреть статистику',
  mystery_shopping_70: 'возражений',
  mystery_shopping_71: 'Аналитика.',
  mystery_shopping_72: 'Отчет подается лично и содержит:',
  mystery_shopping_73: 'Болевые точки',
  mystery_shopping_74: 'и проблемы компании',
  mystery_shopping_75: 'Динамику',
  mystery_shopping_76: 'по подразделениям и отделам',
  mystery_shopping_77: 'Корреляцию',
  mystery_shopping_78:
    'между соблюдением стандартов и удовлетворенностью клиентов',
  mystery_shopping_79: 'Сравнение',
  mystery_shopping_80: 'с конкурентами',
  mystery_shopping_81: 'Практические',
  mystery_shopping_82: 'рекомендации по улучшению сервиса',
  mystery_shopping_83: 'Что нас отличает?',
  mystery_shopping_84: 'Мы проводим сессии по разработке идей',
  mystery_shopping_85:
    'Мы не только рекомендуем, что делать, но и вместе с вами ищем практические пути решения сервисных проблем.',
  mystery_shopping_86: 'Сессии идей — мощный инструмент, который помогает нам:',
  mystery_shopping_87: 'Разработать направления улучшения продукта или услуги',
  mystery_shopping_88:
    'Изучить новые возможности потока доходов и бизнес-стратегий',
  mystery_shopping_89: 'Найти решение сложных клиентоориентированных задач',
  mystery_shopping_90: 'Превратить болевые точки клиентов в привлекательность',
  mystery_shopping_91: 'Мы проводим видео-интервью',
  mystery_shopping_92:
    'Мы не просто собираем анкеты - мы также получаем живые комментарии от покупателей',
  mystery_shopping_93: 'Мы делаем это, чтобы:',
  mystery_shopping_94:
    'Наши фасилитаторы проведут глубинные интервью и обеспечат эффективную обратную связь.',
  mystery_shopping_95: 'Взгляните на обслуживание с точки зрения клиента',
  mystery_shopping_96: 'Усовершенствуйте свою контент-маркетинговую стратегию',
  mystery_shopping_97: 'Прогнозируйте поведение клиентов',
  mystery_shopping_98: 'Мы разрабатываем карту пути клиента',
  mystery_shopping_99:
    'Карта пути клиента помогает нам стать на место клиентов, чтобы понять их потребности и проблемы.',
  mystery_shopping_100: 'Создание пути клиента – лучший способ:',
  mystery_shopping_101: 'Взгляните на обслуживание с точки зрения клиента',
  mystery_shopping_102: 'Усовершенствуйте свою контент-маркетинговую стратегию',
  mystery_shopping_103: 'Предсказывать поведение клиентов',
  mystery_shopping_104: 'Выявлять пробелы в обслуживании или коммуникации',
  mystery_shopping_105: 'Мы предлагаем различные виды тайных покупок',
  mystery_shopping_106: 'Типы тайных покупок',
  mystery_shopping_107: 'Таинственный звонящий',
  mystery_shopping_108:
    'Звонит вашим менеджерам и/или в колл-центр, чтобы определить качество предоставляемых услуг по телефону',
  mystery_shopping_109: 'Люксовый тайный шоппинг',
  mystery_shopping_110:
    'Услуга проверки в элитных заведениях особой категорией тайных покупателей',
  mystery_shopping_111: 'Посещения конкурентов',
  mystery_shopping_112:
    'Самый простой способ понять, как работает ваш конкурент',
  mystery_shopping_113: 'Таинственный сотрудник',
  mystery_shopping_114:
    'Поможет вам понять весь путь вашего сотрудника (процесс найма, адаптации, офисных процессов и т.д.)',
  mystery_shopping_115: 'Мотивационные визиты',
  mystery_shopping_116:
    'Полезный инструмент для стимулирования предложения дополнительных услуг и/или товаров, продаж конкретных товаров',
  mystery_shopping_117: 'Тайный покупатель онлайн',
  mystery_shopping_118:
    'Моделирует и оценивает качество обслуживания клиентов в вашем интернет-магазине, на веб-сайте или в приложении',
  mystery_shopping_119: 'Проверка продаж и рекламных акций',
  mystery_shopping_120:
    'Помогает определить, как рекламные акции отображаются во всех местах и насколько эффективно они работают',
  mystery_shopping_121: 'Покупки и повторные посещения',
  mystery_shopping_122:
    'Помогает отразить весь процесс от покупки до возврата, чтобы улучшить качество обслуживания клиентов',
  mystery_shopping_123: '20+ лет',
  nps_1: 'Индекс потребительской',
  nps_2: 'лояльности NPS',
  nps_3: 'Измеряйте, отслеживайте и повышайте лояльность клиентов',
  nps_4: 'Они отвечают с оценкой от 0 до 6.',
  nps_5:
    'Возможно, у них был плохой опыт — они вряд ли будут покупать у вас снова, и даже могут советовать другим не покупать у вас.',
  nps_6: 'Это респонденты, выбравшие оценку от 7 до 8 баллов.',
  nps_7:
    'Они удовлетворены вашим сервисом, но не настолько, чтобы считаться промоутерами.',
  nps_8: 'Это респонденты, которые оценивают ваш бизнес в 9 или 10 баллов.',
  nps_9:
    'Это означает, что они порекомендуют вас другу или коллеге и будут высказываться в вашу пользу.',
  nps_10: 'Что такое NPS?',
  nps_11: 'Он измеряет восприятие клиентов на основе одного простого вопроса:',
  nps_12:
    'Насколько вероятно, что вы порекомендуете [Организацию/Продукт/Услугу] другу или коллеге?',
  nps_13: 'Нужен ли он моей компании?',
  nps_14: 'NPS — ваш устойчивый рост',
  nps_15: 'Понять динамику лояльности клиентов',
  nps_16: 'Мотивировать своих сотрудников',
  nps_17: 'Понимать связь с разными продуктами',
  nps_18: 'Сравнивать, как клиенты относятся к вам и вашим конкурентам',
  nps_19: 'Что я могу сделать с NPS?',
  nps_20: 'Для какого бизнеса актуален NPS?',
  nps_21: 'Методы сбора информации:',
  nps_22: 'Наши рекомендации по работе с NPS',
  nps_23:
    'Это означает, что мы разрабатываем рекомендации, которые помогут превратить недоброжелателей в промоутеров',
  nps_24: 'Работаем по методу `замкнутого цикла`',
  nps_25:
    'NPS — это отслеживающее исследование, с помощью которого мы следим за изменениями в лояльности клиентом',
  nps_26: 'Следим за динамикой',
  nps_27:
    'Предлагаем всегда оценивать конкурентов в вашей нише для более глубокого анализа',
  nps_28: 'Оценка конкурентов',
  price_monitoring_1: 'Мониторинг цен',
  price_monitoring_1_1: 'мониторинге цен',
  price_monitoring_2: 'и ритейл-мониторинг',
  price_monitoring_3:
    'Увеличьте потенциал продаж и оптимизируйте свою ценовую политику за счет мониторинга цен конкурентов',
  price_monitoring_4:
    '4Service уже более 20 лет предоставляет услуги по мониторингу цен по всему миру',
  price_monitoring_5:
    'Наша команда штатных квалифицированных специалистов в сжатые сроки отслеживает цены, что позволяет нам быстро реагировать на изменения цен конкурентов',
  price_monitoring_6:
    'Задачи, которые наша компания поможет вам решить с помощью мониторинга цен',
  price_monitoring_7: 'Оценка цен конкурентов',
  price_monitoring_8: 'и политики в отношении продуктов',
  price_monitoring_9: 'Мониторинг рекламных акций',
  price_monitoring_10: 'и специальных предложений',
  price_monitoring_11: 'Мониторинг рыночных условий',
  price_monitoring_12: 'и рыночной среды',
  price_monitoring_13: 'Проверка и контроль торговых точек',
  price_monitoring_14: 'на наличие специального оборудования и товаров',
  price_monitoring_15: 'Управление продуктовым',
  price_monitoring_16: 'потоком',
  price_monitoring_17: 'Оптимизация условий',
  price_monitoring_18: 'для лучшего сотрудничества с поставщиками и дилерами',
  price_monitoring_19: 'Измерение пространства на полке,',
  price_monitoring_20: 'оценка позиционирования товаров, наличия на складе',
  price_monitoring_21: 'Ключевые индикаторы и критерии мониторинга',
  price_monitoring_22: 'Все данные собираются в одном интерфейсе, включая:',
  price_monitoring_23: 'название',
  price_monitoring_24: 'продукта и категорию бренда',
  price_monitoring_25: 'наличие',
  price_monitoring_26: 'рекламных материалов',
  price_monitoring_27: 'цену',
  price_monitoring_28: 'и наличие рекламных предложений',
  price_monitoring_29: 'размер',
  price_monitoring_30: 'полок',
  price_monitoring_31: 'расположение',
  price_monitoring_32: 'продукта',
  price_monitoring_33: 'внешний вид',
  price_monitoring_34: 'артикула',
  price_monitoring_35: 'тип',
  price_monitoring_36: 'упаковки',
  price_monitoring_37: 'любую другую',
  price_monitoring_38: 'общую информацию',
  price_monitoring_39: 'Предпроектный анализ:',
  price_monitoring_40: 'Изучаем',
  price_monitoring_41:
    'ценовую и продуктовую политику вашей компании, деловую и отраслевую практику',
  price_monitoring_42: 'Мы предоставляем',
  price_monitoring_43:
    'оперативное обучение, тестирование и сертифицирование аудиторов',
  price_monitoring_44: 'Мы настраиваем',
  price_monitoring_45: 'и обновляем программное обеспечение',
  price_monitoring_46: 'Полевые работы:',
  price_monitoring_47: 'Посещения магазинов',
  price_monitoring_48: 'торговых точек',
  price_monitoring_49: 'Отчетность:',
  price_monitoring_50: 'Наша аналитическая группа',
  price_monitoring_51:
    'разрабатывает онлайн-отчеты в личных кабинетах компании',
  price_monitoring_52: 'Мы предоставляем',
  price_monitoring_53:
    'полный фотоотчет, отчет PPT, отчет Excel с указанием типа населенного пункта, названия сети, адреса торговой точки',
  price_monitoring_54: 'Как это',
  price_monitoring_55: 'работает',
  price_monitoring_56: 'размер индивидуальных скидок',
  price_monitoring_57: 'расчет стоимости',
  price_monitoring_58: 'размер индивидуальных бонусов',
  price_monitoring_59: 'программы лояльности',
  price_monitoring_60: 'и т. д.',
  price_monitoring_61:
    'Мы используем специализированное программное обеспечение:',
  price_monitoring_62:
    'PRADATA, Shopmetrics и предлагаем всестороннюю техническую поддержку',
  price_monitoring_63: 'Быстрый запуск',
  price_monitoring_64: 'и масштабирование (с первого дня)',
  price_monitoring_65: 'Все данные подтверждены',
  price_monitoring_66: 'по фотографиям и GPS-меткам',
  price_monitoring_67: 'У нас есть нужное количество',
  price_monitoring_68: 'полевых агентов в любой стране',
  price_monitoring_69: 'Можем работать с большим',
  price_monitoring_70: 'количеством данных',
  price_monitoring_71: 'Мониторинг отдельных цен',
  price_monitoring_72:
    'Если у вас есть сложные продукты - мы можем помочь вам определить вашу стратегию ценообразования',
  price_monitoring_73:
    'Мы можем отслеживать отдельные цены конкурентов, например:',
  price_monitoring_74: 'Низкая',
  price_monitoring_75: 'стоимость',
  price_monitoring_76: 'Высокая',
  price_monitoring_77: 'эффективность',
  price_monitoring_78: 'Отчет Excel',
  price_monitoring_79: 'Отчет PPT',
  price_monitoring_80: 'Отчет BI',
  price_monitoring_81:
    'Данные о ценах конкурентов помогают вам принимать оптимальные ценовые решения',
  price_monitoring_82:
    'Предоставляем полный фотоотчет, отчет PPT, отчет Excel с указанием локации',
  qualitative_research_1: 'Качественные',
  qualitative_research_2: 'исследования',
  qualitative_research_3:
    'Собирайте, анализируйте и интерпретируйте данные, наблюдая за действиями и словами ваших клиентов',
  qualitative_research_4: 'Ценности',
  qualitative_research_5: 'Поведение',
  qualitative_research_6: 'Фон',
  qualitative_research_7: 'Ожидания',
  qualitative_research_8: 'Окружающая среда',
  qualitative_research_9: 'Страхи/барьеры',
  qualitative_research_10: 'Какова цель выбора клиента?',
  qualitative_research_11: 'Человек с телефоном',
  qualitative_research_12: 'бренд',
  qualitative_research_13: 'категория',
  qualitative_research_14: 'реклама',
  qualitative_research_15: 'продукт',
  qualitative_research_16:
    'Уникальность качественных методов в том, что они позволяют понять причины и мотивацию потребительского поведения',
  qualitative_research_17:
    'Анализ отношения потребителя к тому или иному явлению',
  qualitative_research_18: 'Индивидуальных взглядах и опыте',
  qualitative_research_19:
    'Темах, которые невозможно исследовать с помощью опросов',
  qualitative_research_20: 'Деликатных темах',
  qualitative_research_21: 'Труднодоступной аудитории',
  qualitative_research_22: 'Сложной географии',
  qualitative_research_23: 'Глубинные интервью полезны, когда речь идет о:',
  qualitative_research_24: 'Глубинное интервью',
  qualitative_research_25:
    'Глубинное интервью — это метод качественного исследования, который включает в себя проведение индивидуальных интервью с респондентами для изучения их точки зрения на конкретную идею, программу или ситуацию. Подробное интервью подробно исследует индивидуальный опыт/восприятие/практику.',
  qualitative_research_26: 'Цели:',
  qualitative_research_27: 'Характеристике социальных и культурных норм',
  qualitative_research_28: 'Труднодоступной аудитории',
  qualitative_research_29:
    'Обмене информацией и сравнениях, исследованных путем опросов',
  qualitative_research_30:
    'Темах, которые нельзя исследовать с помощью опросов',
  qualitative_research_31:
    'Углубленные фокус-группы полезны, когда речь идет о:',
  qualitative_research_32: 'Фокус-группы',
  qualitative_research_33:
    'Глубокое изучение отдельных встреч и возникающих дискуссий под руководством модератора группы.',
  qualitative_research_34:
    'Получение информации об общем опыте и социальных нормах через целенаправленное групповое обсуждение и данные из рассказов.',
  qualitative_research_36: 'Преимущества:',
  qualitative_research_37:
    'Изучения образа жизни и поведения потребителя в различных реальных условиях (дома, в торговой точке, на работе и т.д.)',
  qualitative_research_38:
    'Поиска идей для разработки новых продуктов, упаковки, улучшения обслуживания и т. д.',
  qualitative_research_39:
    'Углубленное этнографическое исследование полезно для:',
  qualitative_research_40: 'Этнографическое исследование',
  qualitative_research_41:
    'Этнография – это тип качественного исследования, которое включает в себя погружение в жизнь определенного сообщества или организации и наблюдение за их поведением и взаимодействием с близкого расстояния.',
  qualitative_research_42:
    'Этнография — это гибкий метод исследования, который позволяет вам получить глубокое представление об общей культуре, традициях и социальной динамике группы. Однако это также связано с некоторыми практическими и этическими проблемами.',
  qualitative_research_43: 'Методы:',
  qualitative_research_44:
    'Этнография – это изучение в реальных условиях потребителя и его взаимодействия с товаром/услугой',
  qualitative_research_45: 'Преимущества работы с нами',
  qualitative_research_46:
    'Мы можем найти респондентов из любой целевой аудитории. И мы контролируем качество рекрутинга',
  qualitative_research_47: 'Рекрутинг любой сложности',
  qualitative_research_48:
    'Наши модераторы говорят на нескольких языках и обладают навыками фасилитаторов',
  qualitative_research_49: 'Эксперты-модераторы',
  qualitative_research_50: 'Работа онлайн/офлайн',
  qualitative_research_51: 'Мы предоставляем аудио/видеозаписи',
  qualitative_research_52: ' ',
  qualitative_research_53:
    'Мы можем провести качественное исследование в любой локации',
  quantitative_research_1: 'Исследование удовлетворенности клиентов',
  quantitative_research_2: 'с определенным продуктом или услугой (SCI, NPS)',
  quantitative_research_3: 'Проведение сегментации',
  quantitative_research_4: 'опросы различных рынков',
  quantitative_research_5: 'Исследование рынка',
  quantitative_research_6: 'Управление NPS',
  quantitative_research_7: 'Анализ конкурентов',
  quantitative_research_8: 'Количественныe',
  quantitative_research_9: 'исследования',
  quantitative_research_10:
    'Методология исследования, которая используется для проверки теорий об отношении и поведении людей на основе объективных, числовых и статистических данных',
  quantitative_research_11: 'лет',
  quantitative_research_12: 'опыт CX по всему миру',
  quantitative_research_13: 'панель',
  quantitative_research_14: 'респондентов',
  quantitative_research_15: 'страны',
  quantitative_research_16: 'мы запускаем проекты по всему миру',
  quantitative_research_17: 'Кто мы',
  quantitative_research_18:
    '4Service — глобальный холдинг маркетинговых исследований и CX. Мы предлагаем экспертные услуги по сбору данных для самых разных предприятий',
  quantitative_research_19: '4Service в цифрах',
  quantitative_research_20: 'Компьютерная помощь',
  quantitative_research_21: 'Веб-интервью',
  quantitative_research_22:
    'Метод анкетирования в Интернете. Респондент заполняет электронную анкету без помощи интервьюера. Анкету можно отправить в электронном виде или разместить на сайте.',
  quantitative_research_23: 'Компьютерное веб-собеседование',
  quantitative_research_24: 'В большинстве случаев',
  quantitative_research_25:
    'данный метод является наиболее экономичным с точки зрения материальных и временных затрат',
  quantitative_research_26: 'Этот опрос предоставляет',
  quantitative_research_27:
    'возможность взять интервью у труднодоступной аудитории',
  quantitative_research_28: 'Он предоставляет широкие возможности',
  quantitative_research_29:
    'для демонстрации видео- и аудиоматериалов, а также изображений',
  quantitative_research_30: 'Респонденты могут быть',
  quantitative_research_31: 'в любой точке мира',
  quantitative_research_32: 'Это позволяет исследовать',
  quantitative_research_33:
    'конкретное поведение интернет-аудитории. Клиент может получить доступ к основным результатам в режиме реального времени через веб-интерфейс',
  quantitative_research_34: 'Доступно для любой конфиденциальной информации',
  quantitative_research_35:
    'деликатная, личная тема или вопрос, на который респонденты, возможно, не хотели отвечать во время разговора с интервьюером',
  quantitative_research_36: 'выборка, более 150 000 человек',
  quantitative_research_37:
    'У нас есть собственная выборка респондентов по всему миру',
  quantitative_research_38: 'Мы работаем со сложными запросами',
  quantitative_research_39:
    'Используя дополнительные инструменты (социальные сети, реклама), мы можем найти конкретную аудиторию и сложный профиль респондента',
  quantitative_research_40: 'Мы мотивируем наших респондентов',
  quantitative_research_41:
    'Мы разработали рамки для поощрения заполнения длинных опросов, предоставляя материальные стимулы',
  quantitative_research_42: 'Личные интервью',
  quantitative_research_43: 'проведено с помощью планшета',
  quantitative_research_44:
    'Это один из основных методов сбора количественных данных, во время которого интервьюер общается непосредственно с респондентом по строго структурированному опроснику.',
  quantitative_research_45:
    'Личные интервью могут проводиться по месту работы или жительства респондента, на улице или в специально отведенных местах продаж с использованием планшета',
  quantitative_research_46:
    'Личные интервью, проведенные с использованием планшета',
  quantitative_research_47: 'Определение уровня',
  quantitative_research_48: 'популярности и узнаваемости бренда',
  quantitative_research_49: 'Сегментация потребителей',
  quantitative_research_50: 'на основе их покупательского поведения',
  quantitative_research_51: 'Анализ эффективности',
  quantitative_research_52: 'рекламной кампании',
  quantitative_research_53: 'Удовлетворенность клиентов',
  quantitative_research_54: 'измерение',
  quantitative_research_55: 'Определение лучшей цены',
  quantitative_research_56: 'для продукта или услуги',
  quantitative_research_57: 'Анализ',
  quantitative_research_58: 'предпочтений потребителей',
  quantitative_research_59: 'Исследование',
  quantitative_research_60: 'как покупают потребители',
  quantitative_research_61: 'Оценка',
  quantitative_research_62: 'емкости рынка',
  quantitative_research_63: 'Программное обеспечение DigSee',
  quantitative_research_64:
    'Мы используем специальное программное обеспечение с аудиозаписью и GPS',
  quantitative_research_65: 'Интервьюеры и супервайзеры',
  quantitative_research_66:
    'мы можем проводить интервью на любом языке и переводить на предпочитаемый клиентом язык',
  quantitative_research_67: 'Выборка',
  quantitative_research_68:
    'Аналитический отдел разрабатывает систему набора респондентов для получения наилучшего результата',
  quantitative_research_69: 'Компьютерная помощь',
  quantitative_research_70: 'Телефонное интервью',
  quantitative_research_71:
    'Методология сбора количественной информации посредством телефонных интервью с использованием четко структурированной анкеты.',
  quantitative_research_72: 'Компьютерное телефонное интервью',
  quantitative_research_73: 'У нас есть собственные колл-центры',
  quantitative_research_74:
    'Мы используем специальное программное обеспечение с аудиозаписью и GPS',
  quantitative_research_75: 'Квалифицированные операторы',
  quantitative_research_76:
    'Наши операторы говорят на разных языках, обучены, квалифицированы и постоянно совершенствуют свои навыки',
  quantitative_research_77: 'Базы данных',
  quantitative_research_78:
    'Мы работаем как с собственными базами данных, так и с базами ваших клиентов',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Мы гарантируем качество и подписываем SLA (Соглашение об уровне обслуживания)',
  quantitative_research_81: 'Постановка целей',
  quantitative_research_82: 'Определение методологии',
  quantitative_research_83: 'Сегментация и выборка',
  quantitative_research_84: 'Определение каналов сбора данных',
  quantitative_research_85: 'Создание логичной для респондента анкеты',
  quantitative_research_86: 'Процесс сбора данных',
  quantitative_research_87: 'Обеспечение качества',
  quantitative_research_88: 'Методы анализа',
  quantitative_research_89: 'Обработка данных',
  quantitative_research_90: 'Дизайн опроса',
  quantitative_research_91:
    'Отслеживание местоположения интервьюеров с помощью GPS',
  quantitative_research_92: 'Проверка данных',
  quantitative_research_93: 'Разработка и тестирование анкеты',
  quantitative_research_94: 'Проверка данных',
  quantitative_research_95: 'Аудиозаписи с соблюдением требований GDPR',
  quantitative_research_96: 'Подготовка и обучение рекрутеров',
  quantitative_research_97: 'Обеспечение качества',
  quantitative_research_98: 'Факторный анализ',
  quantitative_research_99: 'Сегментация',
  quantitative_research_100: 'Карта восприятия',
  quantitative_research_101: 'Корреляция',
  quantitative_research_102: 'Модель конверсии',
  quantitative_research_103: 'Жаккард',
  quantitative_research_104: 'Кластерный анализ',
  quantitative_research_105: 'и т. д.',
  quantitative_research_106: 'Аналитические отчеты',
  quantitative_research_107:
    'Мы используем SPSS® и другие инструменты для создания аналитических отчетов с использованием различных методологий',
  quantitative_research_108: 'Какие бизнес-задачи можно решить:',
  quantitative_research_109: 'Какие бизнес-задачи можно решить:',
  social_responsibility_1: 'Корпоративная социальная ответственность 4Service',
  social_responsibility_2:
    'Из глубины сердца и души каждого участника нашей команды. Мы прилагаем максимум усилий, чтобы сохранить искру доброты в каждом из наших сотрудников и сделать мир вокруг нас немного лучше',
  social_responsibility_3: 'Образовательная деятельность / Поддержка молодежи',
  social_responsibility_4:
    'Наши информационные инициативы для детей и студентов',
  social_responsibility_5: 'Деловая игра Service Mania',
  social_responsibility_6:
    'Важной частью наших обучающих мероприятий является бизнес-игра Service Mania.',
  social_responsibility_7:
    'Service Mania учит мыслить стратегически, чтобы легко справляться с необычными ситуациями.',
  social_responsibility_8: 'Презентация исследования / Дия.Бизнес',
  social_responsibility_9:
    'Взгляд бизнеса и клиента на ситуацию с сервисом: презентация результатов нашего исследования от Виктории Скорботы, директора по развитию 4Service Украина.',
  social_responsibility_10: 'Сессии по дизайну услуг',
  social_responsibility_11:
    'Методология проектирования услуг — важная часть 4Service. Пару лет назад мы стали партнером DesignThinkers Academy и не только используем лучшие практики в своей работе, но и активно обучаем лучшие компании',
  social_responsibility_12: 'Благотворительность/волонтерство',
  social_responsibility_13:
    'Детские дома, дома престарелых и волонтерство в украинской армии. Самая трогательная часть нашей деятельности',
  social_responsibility_14: '#HelpEasyWith4ServiceGroup',
  social_responsibility_15:
    'Мы работаем в партнерстве с Благотворительным фондом `Благомай` и стараемся дарить праздник и помогать тем, кто остро в этом нуждается',
  social_responsibility_16:
    'Сбор средств для детских домов и домов престарелых',
  social_responsibility_17:
    'Мы в 4Service инициировали несколько сборов средств для домов престарелых и детских домов на покупку предметов первой необходимости',
  social_responsibility_18: '#СпасемУкраину',
  social_responsibility_19:
    'Война затронула многих наших сотрудников и близких друзей. Каждый из нас помогает, чем может',
  social_responsibility_20: 'Благополучие животных',
  social_responsibility_21:
    'Каждый может прийти к нам в офис со своим питомцем. Мы помогаем приютам, собираем средства на еду, а также время от времени ищем приют для бездомных животных',
  social_responsibility_22: 'Поддержка приютов для животных',
  social_responsibility_23:
    'Бездомные животные нуждаются в помощи так же, как и все уязвимые члены нашего общества.',
  social_responsibility_24:
    'Наша компания взяла под свою опеку приют для бездомных животных `Сириус` (Киевская область, Украина).',
  social_responsibility_25: 'Креативные инициативы в помощь бездомным животным',
  social_responsibility_26:
    'С помощью которых мы рассказываем нашим клиентам и партнерам об актуальности проблемы приютов для животных',
  social_responsibility_27:
    'Офис, в котором разрешено присутствие с домашними животными',
  social_responsibility_28:
    'У многих членов нашей команды есть домашние животные. Мы создали здоровую среду, в которой каждый может прийти в офис со своим питомцем и чувствовать себя комфортно',
  ux_testing_1: 'UX-тестирование',
  ux_testing_1_1: 'UX-тестировании',
  ux_testing_2:
    'Тестирование и улучшение ваших веб-сайтов и приложений: человеческий подход и использование искусственного интеллекта',
  ux_testing_3: 'Что такое UX-тестирование',
  ux_testing_4:
    'UX-тестирование помогает понять, как люди взаимодействуют с вашим продуктом, приложением или веб-сайтом',
  ux_testing_5: 'Навигация не очень понятна',
  ux_testing_6: 'Я понятия не имею, как сделать заказ',
  ux_testing_7: 'Недостаточно фото в каталоге',
  ux_testing_8: 'Вы:',
  ux_testing_9: 'Откроете для себя возможности для улучшения',
  ux_testing_10: 'Выявите проблемы в дизайне и взаимодействии с клиентом',
  ux_testing_11:
    'Узнаете, с какими трудностями сталкивается пользователь при взаимодействии с сайтом',
  ux_testing_12: 'Наблюдайте за путешествием клиента',
  ux_testing_13:
    'Распознавайте, когда и почему ваша целевая аудитория покидает сайт',
  ux_testing_14:
    'Определите, какие разделы вызывают наименьший и наибольший интерес и эмоции',
  ux_testing_15: 'UX-тестирование пользователями',
  ux_testing_16:
    '4Service предоставляет вам реальное человеческое понимание, необходимое для создания клиентоориентированных веб-сайтов и приложений с использованием качественных и количественных методов',
  ux_testing_17: 'Я не понимаю, как пользоваться сайтом. Где спрятано меню?',
  ux_testing_18:
    'Мне кажется, что между буквами слишком много места, текст не читается',
  ux_testing_19: 'Изображения накладываются друг на друга. Так и должно быть?',
  ux_testing_20:
    'UX-тестирование помогает понять, как люди взаимодействуют с вашим продуктом, приложением или веб-сайтом',
  ux_testing_21: 'Дизайн исследования UX-тестирования пользователями:',
  ux_testing_22: 'Откройте для себя возможности стать лучше',
  ux_testing_23:
    'Определение типа и метода тестирования (качественное/количественное)',
  ux_testing_24: 'Составление гипотез и пользовательских сценариев',
  ux_testing_25: 'Анализ и отчет о результатах тестирования',
  ux_testing_26: 'Два решения',
  ux_testing_27: 'Мы предлагаем два подхода к успешному UX-тестированию',
  ux_testing_28: 'UX-тестирование пользователями',
  ux_testing_29: 'UX-тестирование искусственным интеллектом и человеком',
  ux_testing_30: 'Наши сильные стороны:',
  ux_testing_31: 'Мы можем проанализировать конкурентную среду',
  ux_testing_32:
    'Можем привлекать узких специалистов для проведения и участия в интервью',
  ux_testing_33:
    'Мы можем проводить интервью на разных языках и переводить их на нужный язык',
  ux_testing_34: 'с существующим удобством использования',
  ux_testing_35: 'показатели или стандарты',
  ux_testing_36: 'ваша производительность',
  ux_testing_37: 'против конкурента',
  ux_testing_38: 'версии',
  ux_testing_39: 'того же продукта',
  ux_testing_40: 'Рейтинг удовлетворенности',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Показатели успеха',
  ux_testing_43: 'Частота ошибок',
  ux_testing_44: 'Время выполнения задачи',
  ux_testing_45: 'UX-тестирование пользователями',
  ux_testing_46: 'Количественный метод',
  ux_testing_47:
    'Мы привлекаем не менее 100 человек для участия в UX-тестировании на этом этапе',
  ux_testing_48: 'Актуально, когда вам нужно сравнить ваш сайт или приложение:',
  ux_testing_49: 'Количественный метод измерения:',
  ux_testing_50: 'Наблюдение',
  ux_testing_51:
    'Проведение времени с пользователем или группой пользователей, наблюдение за их взаимодействием с продуктом, когда они используют его в повседневной жизни',
  ux_testing_52: 'Углубленное интервью',
  ux_testing_53:
    'Позволяют вам узнать об отношении, убеждениях, желаниях и опыте пользователей, которые заходят на ваш сайт. Мы рекомендуем провести 15-20 интервью',
  ux_testing_54: 'Этнографическое исследование',
  ux_testing_55:
    'Проводятся в среде респондентов, где они будут использовать продукт. Наблюдения позволяют хорошо понимать психологию пользователя и эмоциональные проблемы, с которыми он сталкивается.',
  ux_testing_56: 'UX-тестирование пользователями',
  ux_testing_57: 'Качественный метод',
  ux_testing_58:
    'Этот метод помогает понять мотивы и логику поведения пользователей',
  ux_testing_59: 'Мы используем:',
  ux_testing_60:
    'Мы разрабатываем карту пути клиента, чтобы проиллюстрировать результаты нашего исследования',
  ux_testing_61: '30 участников (мужчины/женщины)',
  ux_testing_62:
    'Респонденты предварительно проверяются и приглашаются принять участие в опросе',
  ux_testing_63: 'Удаленное онлайн-собеседование на платформе Wantent',
  ux_testing_64: 'ИИ + человек',
  ux_testing_65: 'ИИ + UX-тестирование с участием человека',
  ux_testing_66:
    'Сочетание уникальной технологии искусственного интеллекта Wantent и реальных пользователей',
  ux_testing_67: 'Большой шрифт в заголовке привлек внимание',
  ux_testing_68:
    'Пользователи смотрят на `громко говорящие` цифры, но не читают информацию рядом с ними',
  ux_testing_69:
    'UX-тестирование помогает понять, как люди взаимодействуют с вашим продуктом, приложением или веб-сайтом',
  ux_testing_70:
    'Искусственный интеллект + дизайн исследования тестирования с участием человека',
  ux_testing_71: 'Как это работает',
  ux_testing_72:
    'Wantent — это решение, основанное на искусственном интеллекте, которое соответствует требованиям рынка.',
  ux_testing_73:
    'Wantent оценивает эффективность веб-сайтов и приложений, применяя технологии машинного обучения для анализа эмоциональных реакций и вовлеченности аудитории.',
  ux_testing_74:
    'Для интерпретации результатов мы используем методы сторителинга, разнообразия и эмпатии, чтобы стимулировать производительность и рост.',
  ux_testing_75:
    'Внимание и эмоции пользователя во время переходов по разделам',
  ux_testing_76:
    'Статистику по группам участников (внимание и сосредоточенность на протяжении всего сеанса)',
  ux_testing_77: 'Анализ конверсий страниц',
  ux_testing_78: 'Внимание и эмоции во время выполнения задач',
  ux_testing_79: 'Выводы по восприятию',
  ux_testing_80: 'сайта разными группами участников',
  ux_testing_81: 'Анализ легкости',
  ux_testing_82: 'навигации по страницам и простоте поиска важных элементов',
  ux_testing_83: 'Анализ восприятия',
  ux_testing_84: 'информации, представленной на сайте',
  ux_testing_85: 'Определение областей',
  ux_testing_86:
    'для улучшений отдельных страниц (навигация, информационные блоки и т.д.)',
  ux_testing_87: 'Мы подробно анализируем',
  ux_testing_87_about: 'методе',
  ux_testing_88: 'Ключевые результаты исследования',
  ux_testing_89:
    'Рекомендации по улучшению структуры сайта и отдельных страниц',
  ux_testing_90:
    'Мы тщательно выбираем целевую аудиторию для тестирования удобства использования.',
  ux_testing_91:
    'Мы проводим исследования в разных странах, на разных языках и среди разных групп пользователей',
  ux_testing_92: 'Мы можем тестировать приложения',
  ux_testing_93: 'и сайты конкурентов для проведения сравнительного анализа',
  ux_testing_94: 'Мы комбинируем разные методы исследования.',
  ux_testing_95:
    'Наши квалифицированные фасилитаторы сопровождают участника в процессе тестирования.',
  ux_testing_96: 'Мы проводим тестирование',
  ux_testing_97: 'на разных устройствах и версиях ПО',
  ux_testing_98: 'Почему выбирают нас?',
  wantent_1: '4Сервис х',
  wantent_2: 'Wantent',
  wantent_3:
    'Уникальный партнерский проект от 4Service и Wantent © Сочетание искусственного интеллекта и человеческого подхода к анализу контента',
  wantent_4: 'измерения успеха до того, как контент выйдет в эфир',
  wantent_5: 'снижения рисков, связанных с проблемами разнообразия',
  wantent_6: 'глубоких идей и рекомендаций по улучшению контента',
  wantent_7: 'объективных решений по контенту и маркетинговой стратегии',
  wantent_8:
    'Wantent — это решение, основанное на искусственном интеллекте, которое соответствует требованиям рынка.',
  wantent_9:
    'Wantent — это решение на основе искусственного интеллекта для сопоставления контента с рынком. Wantent оценивает эффективность видеоконтента, применяя технологии машинного обучения для анализа эмоционального отклика и вовлеченности аудитории.',
  wantent_10:
    'Wantent помогает определить соответствие контента рынку и максимизировать эффективность контента за счет',
  wantent_11: 'Настройка сценария и подбор участников',
  wantent_12:
    'Индивидуальная разработка проекта и привлечение аудитории по всему миру',
  wantent_13: 'Сбор реакции пользователей',
  wantent_14: 'Подробная и точная обратная связь от аудитории',
  wantent_15: 'Анализ восприятия контента',
  wantent_16:
    'Анализ пользовательского поведения и эмоциональных реакций с помощью ИИ',
  wantent_17: 'Предоставление информации для окончательного подведения итогов',
  wantent_18: 'Рекомендации по улучшению',
  wantent_19: 'Wantent максимизирует эффективность вашего контента',
  wantent_20: 'Предварительное тестирование концепции рекламы',
  wantent_21: 'Тестирование креативной концепции',
  wantent_22:
    'Проверка соответствия будущего креатива разработанной стратегии и понимание реакции целевой аудитории для возможных корректировок креативных концепций/месседжей',
  wantent_23: 'Тестирование элементов бренда на видимость (постобработка)',
  wantent_24: 'Подтверждение эффективности рекламных/спонсорских инвестиций',
  wantent_25: 'Тестирование рекламы',
  wantent_26: 'A/B-тестирование',
  wantent_27:
    'Сравнение эффективности креативов, повышение вовлеченности и внимания на этапе производства',
  wantent_28: 'Запуск тестирования рекламы',
  wantent_29:
    'Определение эффективности сообщений и создание рекомендаций по улучшению звука и изображения',
  wantent_30: 'Решения для ТВ',
  wantent_31: 'Пилоты, промо, новые форматы, тестирование телеведущих',
  wantent_32:
    'Проведение сравнительного анализа, чтобы определить наиболее привлекательный контент и усилить его',
  wantent_33: 'Тестирование телешоу и полнометражных фильмов',
  wantent_34:
    'Анализ вовлеченности зрителей, уровня внимания, эмоционального отклика при просмотре длительного контента в естественных условиях. Мы автоматически находим пики оттока аудитории и моменты отвлечения, чтобы оценить и улучшить монтаж видео',
  wantent_35: 'UI/UX-тестирование',
  wantent_36: 'Прототипы сайтов и тестирование мобильных приложений',
  wantent_37:
    'Анализ реакции и эмоций пользователей с помощью технологии Wantent:',
  wantent_38: 'уровень внимания',
  wantent_39: 'эмоциональные реакции',
  wantent_40: 'тепловые карты направления взгляда участников',
  wantent_41:
    'Анализ удобства использования веб-сайта и приложений на основе отзывов участников:',
  wantent_42: 'Шкала удобства использования системы (SUS)',
  wantent_43: 'Оценка усилий клиента (простота взаимодействия)',
  wantent_44: 'Чистый показатель продвижения (NPS)',
  wantent_45: 'Соответствие GDPR и конфиденциальности пользователей',
  wantent_46:
    'Wantent полностью соответствует политикам GDPR и CCPA и соответствует принципам сбора, хранения, обработки и защиты персональных данных участников',
  wantent_47: 'Никита Лобынцев',
  wantent_48: 'СDO, Reface (медиа и развлечения)',
  wantent_49:
    'Каждый день мы экспериментируем с множеством новых идей для контента с помощью технологий машинного обучения. Wantent помогает нам понять и оценить риски и преимущества, узнать отзывы, поведение и восприятие пользователей, чтобы установить причинно-следственную связь различных аспектов наших решений.',
  wantent_50: 'Александр Смирнов',
  wantent_51: 'совладелец TABASCO (Реклама)',
  wantent_52:
    'Wantent предоставляет действительно уникальную услугу, которая помогает нам узнать очень мелкие детали того, что нравится и не нравится потребителям. Нет способа обмануть, нет способа лгать - Wantent всегда знает правду, и тем самым мы, как маркетологи, получаем бесценные (хахаха - мы знаем точную цену и она доступна) рекомендации по улучшению наших коммуникаций. ',
  wantent_53: 'Отзывы',
  terms_1: 'Положения и условия',
  terms_2: 'Соглашение об условиях использования',
  terms_3:
    'Пожалуйста, внимательно прочитайте настоящее Соглашение об условиях использования (`условия использования`, `соглашение`) перед использованием веб-сайта',
  terms_4:
    '("веб-сайт"), которым управляет 4Service Holdings GmbH ("4Service", "нас", "мы", "наш").',
  terms_5:
    'Условия использования. Используя этот веб-сайт, вы подтверждаете, что прочитали и ознакомились с настоящим Соглашением и согласны соблюдать его условия. Если вы не хотите соблюдать условия настоящего Соглашения, вам рекомендуется соответственно покинуть веб-сайт. 4Service разрешает использование и доступ к этому веб-сайту, его продуктам и услугам только тем, кто принял его условия.',
  terms_6: 'Политика конфиденциальности',
  terms_7:
    'Прежде чем продолжить использование нашего веб-сайта, мы рекомендуем вам ознакомиться с нашей Политикой конфиденциальности в отношении сбора данных о пользователях. Это поможет вам лучше понять нашу практику.',
  terms_8: 'Возрастное ограничение',
  terms_9:
    'Вам должно быть не менее 16 (шестнадцати) лет, прежде чем вы сможете использовать этот веб-сайт. Используя этот веб-сайт, вы гарантируете, что вам исполнилось 16 (шестнадцать) лет, и вы можете юридически соблюдать настоящее Соглашение. 4Service не несет ответственности за обязательства, связанные с искажением возраста.',
  terms_10: 'Интеллектуальная собственность',
  terms_11:
    'Вы соглашаетесь с тем, что все материалы, продукты и услуги, представленные на этом веб-сайте, являются собственностью 4Service, ее аффилированных лиц, директоров, должностных лиц, сотрудников, агентов, поставщиков или лицензиаров, включая все авторские права, коммерческие секреты, товарные знаки, патенты и другая интеллектуальная собственность. Вы также соглашаетесь с тем, что вы не будете каким-либо образом воспроизводить или распространять интеллектуальную собственность 4Service, включая электронную, цифровую регистрацию или регистрацию новых товарных знаков.',
  terms_12: 'Доступ к нашему веб-сайту',
  terms_13:
    'Доступ к нашему сайту разрешен на временной основе, и мы оставляем за собой право отозвать или изменить услуги, которые мы предоставляем на нашем сайте, без предварительного уведомления. Мы не несем ответственности, если по какой-либо причине наш сайт недоступен в любое время или в течение какого-либо периода. Время от времени мы можем ограничивать доступ к некоторым частям нашего сайта или всему нашему сайту пользователям, которые зарегистрировались у нас. Вы несете ответственность за принятие всех мер, необходимых для доступа к нашему сайту, включая использование оборудования, совместимого с нашим сайтом. Вы также несете ответственность за то, чтобы все лица, получающие доступ к нашему сайту через ваше интернет-соединение, знали об этих условиях и соблюдали их.',
  terms_14: 'Применимое законодательство',
  terms_15:
    'Посещая этот веб-сайт, вы соглашаетесь с тем, что законы Австрийской Республики, без учета принципов коллизионного права, будут регулировать настоящие условия и любые споры любого рода, которые могут возникнуть между вами и 4Service.',
  terms_16: 'Споры',
  terms_17:
    'Любой спор, каким-либо образом связанный с вашим посещением этого веб-сайта, подлежит рассмотрению в арбитраже Коммерческим судом Вены.',
  terms_18: 'Возмещение',
  terms_19:
    'Вы соглашаетесь возместить ущерб 4Service и его аффилированным лицам и защитить 4Service от юридических претензий и требований, которые могут возникнуть в результате вашего использования или неправильного использования нашего веб-сайта. Мы оставляем за собой право выбирать нашего собственного адвоката.',
  terms_20: 'Срок хранения',
  terms_21:
    'С учетом целей обработки срок сохранности персональных данных Пользователей (срок хранения) составляет 24 месяца с даты получения от вас в установленном порядке согласия на обработку данных.',
  terms_22: 'Ограничение ответственности',
  terms_23:
    '4Service не несет ответственности за любые убытки, которые могут возникнуть у вас в результате неправильного использования вами нашего веб-сайта. 4Service оставляет за собой право без предварительного уведомления редактировать, изменять и изменять настоящее Соглашение в любое время, обновляя эту публикацию. Ваше дальнейшее использование веб-сайта сейчас или после публикации любых изменений или модификаций будет означать принятие вами таких изменений или модификаций. Если какая-либо часть настоящего соглашения будет объявлена незаконной, недействительной или не имеющей законной силы, эта часть будет считаться отделимой и не повлияет на действительность и применимость любых остальных положений. Возможные доказательства использования сайта в незаконных целях будут переданы в правоохранительные органы. Настоящее Соглашение является договоренностью между 4Service и пользователем.',
  terms_24:
    'Пожалуйста, направляйте все вопросы и проблемы, связанные с конфиденциальностью/использованием, по следующему адресу:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Вена',
  terms_28: 'Австрия',
  terms_29: 'Обзор',
  terms_30:
    'Когда личные данные, которые вы нам предоставляете, не являются неточными, вы имеете право попросить нас действительно исправить их (статья 16 GDPR).',
  thank_you_1: 'Спасибо!',
  thank_you_2: 'Мы свяжемся с вами в течение 24 часов',
  thank_you_3:
    'PS Наши менеджеры уже рассматривают ваш запрос, чтобы составить лучшее предложение',
  thank_you_4: 'Меню',
  thank_you_5: 'Женщина печатает',
  privacy_policy_1:
    'Форма уведомления о конфиденциальности для субъектов данных (пользователей веб-сайта)',
  privacy_policy_2:
    'Эта форма уведомления о конфиденциальности (уведомление о конфиденциальности) предназначена для физических лиц (субъектов данных), чьи персональные данные собираются 4Service Holdings GmbH в соответствии с требованиями GDPR в рамках посещения веб-сайта',
  privacy_policy_3:
    '(здесь и далее Веб-сайт и Пользователи Веб-сайта/Пользователи соответственно).',
  privacy_policy_4:
    'Форма уведомления о конфиденциальности для субъектов данных (пользователей веб-сайта) 4Service Holdings GmbH применяется во всех компаниях группы 4Service, которые находятся на законных основаниях под надзором или контролем 4Service Holdings GmbH.',
  privacy_policy_5: 'Контакты',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Компания/мы/нас)',
  privacy_policy_8: 'Адрес:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Вена, Австрия.',
  privacy_policy_10: 'Электронная почта:',
  privacy_policy_12: 'Кто мы?',
  privacy_policy_13:
    'Физическое лицо (вы), которое посещает Веб-сайт с любой целью. Для целей настоящего Документа вы также должны быть указаны как Пользователь.',
  privacy_policy_14: 'Личные данные, которые мы получаем от вас',
  privacy_policy_15:
    'Когда вы заходите на наш сайт, мы отправляем вам уведомление о конфиденциальности, в котором предлагаем вам дать согласие на обработку следующей информации:',
  privacy_policy_16:
    'IP-адрес, имя пользователя, фамилия, адрес, номер телефона (стационарный или мобильный), адрес электронной почты, адрес, название компании, страна, адрес электронной почты, имя, фамилия, номер телефона, провинция, штат и почтовый индекс, данные об использовании, данные о взаимодействии с внешними социальными сетями или платформами, информация о регистрации и аутентификации на веб-сайте',
  privacy_policy_17: 'географическое положение.',
  privacy_policy_18:
    'В случае, если вы выражаете свою волю и даете нам такое согласие, мы начинаем обрабатывать такую информацию от вас.',
  privacy_policy_19: 'Законность (согласие)',
  privacy_policy_20:
    'Когда вы заходите на наш сайт, мы отправляем вам уведомление о конфиденциальности, в котором предлагаем вам дать согласие на обработку следующей информации:',
  privacy_policy_21:
    'Правовым основанием для обработки персональных данных является согласие, которое мы получим от Вас, заполнив форму согласия по следующей ссылке:',
  privacy_policy_22:
    'Если вы заполните форму согласия, это означает, что вы понимаете и принимаете все условия, указанные в этом Уведомлении о конфиденциальности.',
  privacy_policy_23: 'Отзыв согласия',
  privacy_policy_24:
    'Вы имеете право в любое время отозвать согласие, которое вы предоставили нам ранее. Отзыв согласия не влияет на законность обработки, основанной на согласии до его отзыва. Вы можете отозвать свое согласие, направив нам соответствующий запрос на следующий адрес электронной почты remove@4service-group.com, при этом форма запроса доступна по следующей ссылке:',
  privacy_policy_25: 'Форма вывода средств для пользователя',
  privacy_policy_26: 'Цели обработки',
  privacy_policy_27:
    'Мы обрабатываем ваши персональные данные в следующих целях:',
  privacy_policy_28:
    '– улучшить клиентские сервисы (позволяет более эффективно реагировать на запросы клиентов); – персонализация опыта Пользователей (позволяет определить, кому больше интересны услуги); — улучшать Сайт (позволяет повышать качество товаров и услуг, удобство их использования, разрабатывать новые Сервисы, улучшать наши продукты и услуги); - связываться с Пользователем с помощью информационных бюллетеней, маркетинговых или рекламных материалов и другой информации, которая включает в себя наши новости, обновления, информацию об услугах с пометкой об инструкции, как отказаться от получения последующих электронных писем; – проводить статистические и иные виды исследований и анализов на основе обезличенных данных; — оказывать персонализированные услуги Пользователю и выполнять соглашения и договоры; – участие Пользователя в различных проектах, реализуемых нами через Сайт, ответы на запросы, адресованные Пользователем через Сайт, исследования, ведение учетных записей и записей и продвижение услуг.',
  privacy_policy_29:
    'Субъекты, которым могут быть переданы персональные данные',
  privacy_policy_30:
    'Во время обработки ваших персональных данных мы передаем ваши персональные данные субъектам, которые действуют в качестве обработчиков данных Компании. Обработчики Компании действуют исключительно на основании инструкций Компании. С особенностями действий, которые осуществляют обработчики Компании, и со списком таких обработчиков можно ознакомиться в Политике конфиденциальности и защиты данных по следующей ссылке: Политика конфиденциальности и защиты данных',
  privacy_policy_31: 'Страны, в которые могут быть переданы личные данные',
  privacy_policy_32:
    'Компания передает ваши персональные данные на основании решения о достаточности, как это предусмотрено GDPR и Комиссией ЕС. Дополнительную информацию о передаче персональных данных в США можно найти в Политике конфиденциальности и защиты данных по следующей ссылке: Политика конфиденциальности и защиты данных.',
  privacy_policy_33: 'Срок сохранности',
  privacy_policy_34:
    'С учетом целей обработки срок сохранности персональных данных Пользователей (срок хранения) составляет 24 месяца с даты получения от вас в установленном порядке согласия на обработку данных.',
  privacy_policy_35: 'Право доступа',
  privacy_policy_36:
    'Вы имеете право знать, обрабатываются ли личные данные, касающиеся вас, и 2) если да, получить доступ к таким данным с множеством дополнительных условий, указанных в статье 15 GDPR',
  privacy_policy_37: 'Право на исправление',
  privacy_policy_38:
    'Если личные данные, которые вы нам предоставляете, не являются неточными, вы имеете право попросить нас действительно исправить их (статья 16 GDPR).',
  privacy_policy_39: 'Право на удаление (право на забвение)',
  privacy_policy_40:
    'Вы имеете право потребовать от нас удаления ваших личных данных без неоправданной задержки, и мы будем обязаны удалить ваши личные данные без неоправданной задержки, если применимы основания, указанные в статье 17 GDPR.',
  privacy_policy_41: 'Право на ограничение обработки',
  privacy_policy_42:
    'Вы имеете право ограничить обработку своих персональных данных за несколькими исключениями в рамках GDPR, в частности, указанными в статье 18 GDPR.',
  privacy_policy_43:
    'Мы обязаны сообщить вам, какие данные собираются, как они используются, как долго они будут храниться и будут ли они переданы третьим лицам. Эта информация должна сообщаться кратко и доступно.',
  privacy_policy_44: 'Право на перенос данных',
  privacy_policy_45:
    'Вам разрешено получать и повторно использовать ваши личные данные в своих целях в различных службах.',
  privacy_policy_46: 'Право на возражение',
  privacy_policy_47:
    'Вы имеете право возражать против обработки персональных данных, которые обрабатываются Компанией. Мы должны прекратить обработку персональных данных, если мы не продемонстрируем веские законные основания для обработки, которая преобладает над интересами, правами и свободами человека, или если обработка предназначена для установления или защиты юридических требований.',
  privacy_policy_48:
    'Право не подвергаться решению, основанному исключительно на автоматизированной обработке',
  privacy_policy_49:
    'Вы имеете право возражать против любого автоматического профилирования, которое происходит без вашего согласия. При этом вы имеете право обрабатывать Ваши персональные данные с участием человека.',
  privacy_policy_50: 'Жалобы',
  privacy_policy_51:
    'В случае, если вы хотите подать жалобу на то, как ваши личные данные обрабатываются Компанией (или обработчиками, описанными выше), или на то, как была обработана ваша жалоба, вы имеете право подать жалобу напрямую с надзорным органом и Компанией.',
  privacy_policy_52: 'Подробности для каждого из этих контактов:',
  privacy_policy_53: 'Надзорный орган:',
  privacy_policy_54: 'Австрийский орган по защите данных',
  privacy_policy_55: 'Австрийская датская служба',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Вена',
  privacy_policy_58: 'Австрия/Европа',
  privacy_policy_59: 'Компания:',
  privacy_policy_60: '4Service Holdings GmbH (Компания/мы/нас)',
  privacy_policy_61: 'Адрес: Tegetthoffstraße 7, 1010 Вена, Австрия.',
  privacy_policy_62: 'Электронная почта:',
  privacy_policy_63: 'Политика конфиденциальности и защиты данных',
  privacy_policy_64:
    'Подробнее о том, как и почему мы используем ваши данные, читайте здесь: Политика конфиденциальности и защиты данных',
  privacy_policy_65: 'Одобрение',
  privacy_policy_66:
    'Компания разработала все внутренние документы для определения ролей сотрудников в отношении обработки персональных данных в Компании, в частности, ответственность за утверждение и проверку легитимности этого документа несет Управляющий директор.',
  contacts_1: 'Для клиентов',
  contacts_2:
    'Для всех видов бизнеса, агентств по исследованию рынка и крупных компаний, деловых партнеров и СМИ.',
  contacts_3:
    'Адрес: 050008 Казахстан, г.Алматы, Алмалинский район, ул.Торекулова 68, БЦ Сириус, офис 910',
  contacts_4: 'Для покупателей',
  contacts_5:
    'Для местных жителей и путешественников, водителей и студентов, профессиональных тайных покупателей и домоседов.',
  contacts_6: 'Свяжитесь с нами из вашей страны:',
  contacts_7: 'Италия',
  contacts_8: 'США',
  contacts_9: 'Великобритания',
  contacts_10: 'Швейцария',
  contacts_11: 'Нидерланды',
  contacts_12: 'Словакия',
  contacts_13: 'Словения',
  contacts_14: 'Румыния',
  contacts_15: 'Азербайджан',
  contacts_16: 'Украина',
  contacts_17: 'Казахстан',
  contacts_18: 'Другие страны',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'ВНТ',
  menu_5: 'UX-тестирование',
  menu_6: 'Кадровые исследования',
  menu_7: 'Качественные исследования',
  menu_8: 'Количественные исследования',
  menu_9: 'Тайный покупатель',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Ценовой мониторинг',
  menu_14: 'О нас',
  menu_15: 'Контакты',
  menu_16: 'Блог',
  menu_17: 'Методы',
  menu_18: 'Продукты',
  menu_19: 'Вход / Регистрация в кабинете',
  menu_20: 'КСО',
  menu_21: 'Анализ цен на автомобили',
  nps_passives: 'Пассивные',
  nps_detractors: 'Недоброжелатели',
  nps_promoters: 'Промоутеры',
  nps_a_score: 'оценка',
  area: 'Сфера',
  products_tabs_1:
    'Для достижения максимальной эффективности мы также проводим в комплексе:',
  products_tabs_2: 'Продукты',
  products_tabs_3:
    'Качественное исследование — это сбор и анализ нечисловых данных для понимания концепций, мнений или опыта',
  products_tabs_4:
    'Количественные методы исследования — это объективное измерение и числовой анализ данных, собранных с помощью опросов, анкетирований',
  our_clients_1: 'Наши клиенты',
  preview_cases_section_1: 'Кейсы',
  preview_cases_section_2:
    'Перейдите к кейсам, чтобы прочитать больше интересных бизнес-историй',
  blog_1: 'Блог с захватывающим контентом',
  blog_2: 'Нет доступных сообщений по вашему запросу',
  about: 'О',
  why_us: 'Почему мы',
  about_us: 'О нас',
  key_features: 'Ключевые особенности',
  solutions: 'Решение',
  monitoring: 'Мониторинг',
  how: 'Как',
  how_it_works: 'Как это работает',
  when_to_use: 'Когда использовать',
  mystery_shopping: 'Тайный покупатель',
  voicer: 'Голос клиента',
  wantent: 'Тестирование Видео-контента',
  play4sales: 'Play4Sales',
  price_monitoring: 'Ценовой мониторинг',
  service_design: 'Сервис-дизайн',
  qualitative_research: 'Качественные исследования',
  quantitative_research: 'Количественные исследования',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Автомобили',
  hashtag_retayl: '#Розница',
  hashtag_all_posts: '#все_сообщения',
  hashtag_feedback: '#обратная связь',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Кейсы',
  hashtag_market_research: '#исследование_рынка',
  more: 'Больше',
  speak_to_an_expert: 'Обратитесь к эксперту',
  book_consultancy: 'Заказ консультации',
  read_all: 'Читать все',
  find_out_more: 'Узнайте больше',
  address: 'Адрес',
  mon_fri: 'Пн-Пт',
  phone_number: 'Номер телефона',
  flag: 'флаг',
  scroll_to_left: 'прокрутить влево',
  online: 'онлайн',
  tel: 'Тел',
  email: 'E-mail',
  light_it_up: 'Зажигай',
  about_us_60: 'Алина Андреева',
  about_us_61: 'Директор по развитию бизнеса',
  about_us_62: 'Юлия Кравченко',
  about_us_63: 'Старший менеджер проекта',
  about_us_64: 'Ольга Аксонова',
  about_us_65: 'Менеджер по развитию бизнеса',
  about_us_66: 'Желевский Дмитрий',
  about_us_67: 'Операционный директор',
  about_us_roles_1: 'Управление',
  about_us_roles_2: 'Менеджмент',
  about_us_roles_3: 'Владельцы продукта',
  ad_testing_1: 'Тестирование рекламы',
  ad_testing_2:
    'Рекламная кампания — это огромные инвестиции. Убедитесь, что ваши объявления эффективны и обеспечивают максимальную рентабельность инвестиций',
  ad_testing_3: 'заблокировать',
  ad_testing_4: 'Отрасли, которые мы обслуживаем',
  ad_testing_5:
    '4Service – глобальный исследовательский холдинг, специализирующийся на изучении клиентского опыта',
  ad_testing_6:
    '4Service — глобальный холдинг маркетинговых и CX-исследований. Мы предлагаем экспертные услуги по сбору данных для самых разных предприятий',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Розничная торговля',
  ad_testing_9: 'Электроника',
  ad_testing_10: 'Люкс/Премиум',
  ad_testing_11: 'Рестораны и гостиничный бизнес',
  ad_testing_12: 'Автомобили',
  ad_testing_13: 'Путешествия и отдых',
  ad_testing_14: 'Мода',
  ad_testing_15: 'Банки',
  ad_testing_16: 'Часы и украшения',
  ad_testing_17: 'Бытовая техника',
  ad_testing_18: 'Член Esomar',
  ad_testing_19:
    'Мы провели исследование по тестированию рекламы для различных предприятий в разных отраслях',
  ad_testing_20: 'Вам необходимо тестирование рекламы, если вы:',
  ad_testing_21: 'Продвигаете новый продукт или услугу',
  ad_testing_22: 'Касаетесь обсуждения деликатной темы',
  ad_testing_23: 'Собираетесь охватить новую целевую аудиторию или рынок',
  ad_testing_24: 'Обсуждаете несколько вариантов дизайна',
  ad_testing_25:
    'Нуждаетесь в проверке концепции для управленческих команд или инвесторов',
  ad_testing_26: 'Вы получите ответы на вопросы',
  ad_testing_27:
    'Какие рекламные каналы лучше всего подходят для этих объявлений?',
  ad_testing_28:
    'Используем ли мы правильные визуальные эффекты, чтобы донести сообщение?',
  ad_testing_29: 'Находят ли отклик ваши рекламные тексты и сообщения?',
  ad_testing_30:
    'Какие рекламные каналы лучше всего подходят для этих объявлений?',
  ad_testing_31: 'Что в первую очередь привлекает их внимание?',
  ad_testing_32: 'Легко ли понять размещение и макет объявления?',
  ad_testing_33: 'Мы оцениваем',
  ad_testing_34: 'Брендинг',
  ad_testing_35: 'как ваш бренд связан с рекламой и насколько он запомнится',
  ad_testing_36: 'Ключевые показатели',
  ad_testing_37: 'Интеграция',
  ad_testing_38: 'Признание',
  ad_testing_39: 'Подходит',
  ad_testing_40: 'Креатив',
  ad_testing_41:
    'Выделяется ли ваша реклама и привлекает ли она внимание людей',
  ad_testing_42: 'Ключевые показатели',
  ad_testing_43: 'РАСПОЗНАВАНИЕ',
  ad_testing_44: 'ЗАИНТЕРЕСОВАННОСТЬ',
  ad_testing_45: 'МТО',
  ad_testing_46: 'МОТИВАЦИЯ',
  ad_testing_47: 'Впечатления клиентов, эмоциональное взаимодействие',
  ad_testing_48:
    'С помощью технологий искусственного интеллекта и экспертов-аналитиков мы анализируем реальные эмоции людей и получаем представление об их восприятии рекламы',
  ad_testing_49: 'Рейтинг',
  ad_testing_50: 'Мужской',
  ad_testing_51: 'Женский',
  ad_testing_52: 'Высокая вовлеченность',
  ad_testing_53: 'Внимание',
  ad_testing_54: 'Что понравилось и откликнулось аудитории?',
  ad_testing_55: 'Актриса',
  ad_testing_56: 'Равенство',
  ad_testing_57: 'Музыкальный фон',
  ad_testing_58: 'Положительные эмоции',
  ad_testing_59: 'Люди разных рас и внешности',
  ad_testing_60: 'Снимки на улице',
  ad_testing_61: 'Что НЕ понравилось аудитории и не нашло отклика?',
  ad_testing_62: 'Голос диктора не соответствовал происходящему на экране',
  ad_testing_63: 'Низкое внимание к продукту',
  ad_testing_64: 'Слишком динамично',
  ad_testing_65: 'Трудно понять',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Впечатления',
  ad_testing_68: 'Показы',
  ad_testing_69: 'Конверсия',
  ad_testing_70: 'Медиа',
  ad_testing_71:
    'Мы измеряем эффективность рекламной кампании в сочетании с медийной активностью, вычисляя влияние инвестиций в медиа в различных каналах на отслеживаемые результаты.',
  ad_testing_72:
    'Мы проводим рекламные исследования в любом формате, на любой платформе или канале',
  ad_testing_73: 'видеоконтент',
  ad_testing_74: 'наружная реклама',
  ad_testing_75: 'телевизионная реклама',
  ad_testing_76: 'полиграфия',
  ad_testing_77: 'цифровые',
  ad_testing_78: 'рекламные кампании',
  ad_testing_79: 'расположение рекламных материалов в точках продаж',
  ad_testing_80: 'аниматика',
  ad_testing_81:
    'С нашей помощью вы можете протестировать свою рекламу на любом этапе',
  ad_testing_82: 'Концепция',
  ad_testing_83: 'у каких идей больше возможностей?',
  ad_testing_84: 'Ранняя стадия производства',
  ad_testing_85: 'какая аниматика пойдет в производство?',
  ad_testing_86: 'Производство',
  ad_testing_87: 'как отредактировать видео, чтобы оно удерживало внимание?',
  ad_testing_88: 'Пре-медиа',
  ad_testing_89:
    'какие каналы связи будут работать и какой результат можно ожидать?',
  ad_testing_90: 'Пост-тестирование рекламы',
  ad_testing_91: 'Производство',
  ad_testing_92:
    'увлекает ли моя реклама? Снижается ли эффективность креативности со временем?',
  ad_testing_93: 'Предпечатная подготовка',
  ad_testing_94:
    'насколько эффективна моя реклама в цифрах? Как она сравнивается с конкурентной?',
  ad_testing_95: 'Предварительное тестирование рекламы',
  ad_testing_96: 'Чтобы предоставить вам качественные данные, мы используем',
  ad_testing_97: 'Методологию количественных исследований',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Методологию качественных исследований',
  ad_testing_100: 'Фокус-группы',
  ad_testing_101: 'ИИ',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Аналитические отчеты, которые вы получаете',
  ad_testing_104:
    'Наша команда аналитиков, медиа экспертов, маркетологов подготовит и представит вам индивидуальный отчет с подробными рекомендациями',
  ad_testing_age: 'возраст',
  ad_testing_ad: 'Объявление',
  automotive_industry_price_analysis_1:
    'Анализ цен в автомобильной промышленности',
  automotive_industry_price_analysis_2:
    'Анализ конкурентных цен поможет предотвратить упущенную выгоду, увеличить долю рынка и отслеживать вашу позицию на рынке. Позвольте нам помочь вам в выборе успешной конкурентной стратегии!',
  automotive_industry_price_analysis_3: 'Запросить коммерческое предложение',
  automotive_industry_price_analysis_4: 'Наша методология',
  automotive_industry_price_analysis_5:
    'Салон автомобилей для стильных и элегантных людей.',
  automotive_industry_price_analysis_6:
    'Метод Mystery Shopping является единственным подходом, который точно отражает реальный опыт клиента и предоставляет достоверный обзор.',
  automotive_industry_price_analysis_7:
    'Мы предлагаем точные цифры и надежные многосторонние данные',
  automotive_industry_price_analysis_8:
    'У нас глобальное покрытие, охватывающее каждую страну мира.',
  automotive_industry_price_analysis_9:
    'Наши внутренние ресурсы включают в себя тайных покупателей и аудиторов',
  automotive_industry_price_analysis_10: 'Мы проверяем качество наших данных',
  automotive_industry_price_analysis_11: 'Наши преимущества:',
  automotive_industry_price_analysis_12:
    'Мониторинг цен на конкретные модели конкурентов (B2B и B2C)',
  automotive_industry_price_analysis_13: 'Мониторинг уровня скидки',
  automotive_industry_price_analysis_14:
    'Отслеживание торговых запасов вашего бренда и конкурентов в розничной торговле',
  automotive_industry_price_analysis_15:
    'Мониторинг цен на автомобили в электронной коммерции',
  automotive_industry_price_analysis_16: 'Анализ ценовой коммуникации',
  automotive_industry_price_analysis_17:
    'Отслеживание стоимости доставки у розничных продавцов',
  automotive_industry_price_analysis_18:
    'Отслеживание рекламных акций и распродаж в розничных магазинах',
  automotive_industry_price_analysis_19:
    'Определение сумм ежемесячных платежей',
  automotive_industry_price_analysis_20: 'Расчет лизинговых платежей',
  automotive_industry_price_analysis_21:
    'Анализ конкретных факторов ценообразования для электромобилей (ЭМ)',
  automotive_industry_price_analysis_22:
    'Проведение сравнения цен между прямыми продажами потребителю и продажами через розничные магазины',
  automotive_industry_price_analysis_23:
    'Разработка моделей ценообразования по подписке',
  automotive_industry_price_analysis_24:
    '4Service – это международная холдинговая компания, уже более 20 лет специализирующаяся на создании и улучшении клиентского опыта (CX)',
  automotive_industry_price_analysis_25:
    'Вы сможете использовать данные о готовности потребителей платить за разные функции, чтобы оптимизировать свое предложение и определить важнейшие характеристики вашей целевой аудитории.',
  automotive_industry_price_analysis_26:
    'Анализ цен в автомобильной индустрии от 4Service включает в себя:',
  automotive_industry_price_analysis_27:
    'Самые низкие цены не всегда являются ключом к конкурентоспособности автомобильных брендов. Позвольте нам помочь вам создать долгосрочную стратегию ценообразования',
  automotive_industry_price_analysis_28:
    'клиентов понимают, что цены растут во всех брендах',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'клиентов заявили, что все равно купят автомобиль, который им нравится, даже если бренд проинформирует их о предстоящем повышении цен,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'людей считают лизинг и ежемесячные платежи наиболее предпочтительной моделью при покупке автомобиля для них',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'дилеров упоминают о возможности увеличения цен в процессе общения/продажи',
  automotive_industry_price_analysis_35: '47,6',
  automotive_industry_price_analysis_36: 'Ключевые показатели',
  automotive_industry_price_analysis_37:
    'Вы определите цену своего продукта относительно других автомобилей в линейке',
  automotive_industry_price_analysis_38:
    'Вы будете использовать данные о готовности потребителей платить за различные функции, чтобы оптимизировать ваше предложение и выявить наиболее важные функции для ваших целевых клиентов',
  automotive_industry_price_analysis_39:
    'Вы сможете более подробно понять свои преимущества в отношении конкурентов.',
  automotive_industry_price_analysis_40:
    'Вы сможете определить подходящую сумму ежемесячного платежа и ставки аренды.',
  automotive_industry_price_analysis_41:
    'Какие преимущества для вашего бренда или дилера вы получите:',
  automotive_industry_price_analysis_42: 'Вы подаете заявку',
  automotive_industry_price_analysis_43:
    'Наш менеджер связывается с вами для определения основных целей',
  automotive_industry_price_analysis_44:
    'Мы проводим маркетинговое исследование',
  automotive_industry_price_analysis_45:
    'Мы регулярно предоставляем вам отчеты и рекомендации в онлайн-формате',
  automotive_industry_price_analysis_46: 'Автомобиль с ярлыком распродажи',
  automotive_industry_price_analysis_47: 'Мы запускаем проект за 1 день!',
  automotive_industry_price_analysis_48:
    'Мы предлагаем полный набор отчетов, включая фотоотчеты, отчеты в формате PPT и отчеты в формате Excel, сегментированные по типу местности',
  retail_audit_1: 'Аудит торговых сетей / Аудит розничной торговли',
  retail_audit_2:
    'Соберите подробные данные о вашем магазине или бренде, проанализируйте преграды роста и разработайте эффективные стратегии их преодоления',
  retail_audit_3: 'Запросить коммерческое предложение',
  retail_audit_4:
    '4Service проводит аудит магазинов на месте от имени брендов, чтобы оценить эффективность их промоакций, команд-исполнителей и маркетинговых тактик для достижения целевого рынка',
  retail_audit_5:
    'Мы тщательно наблюдаем за проведением рекламных кампаний и обеспечиваем соблюдение рекламных руководств',
  retail_audit_6: 'Топ-10',
  retail_audit_7: 'CX-агентств в Европе',
  retail_audit_8: 'лет опыта',
  retail_audit_9: 'покрытие стран',
  retail_audit_10: '1,6 миллиона',
  retail_audit_11: 'оценок в год',
  retail_audit_12: 'Что мы отслеживаем?',
  retail_audit_13: 'Наличие материалов для точек продаж (POS)',
  retail_audit_14:
    'Присутствие и активация представителей бренда, обеспечение соблюдения запланированного графика',
  retail_audit_15:
    'Оценка вовлечения представителей бренда в поддерживающие кампании',
  retail_audit_16:
    'Оценка взаимодействия с потребителем, обеспечение соблюдения промоушн-программ и брендовых сообщений',
  retail_audit_17: 'Меры по предотвращению мошенничества',
  retail_audit_18:
    'Ваш бизнес может использовать результаты аудита маркетинга в торговой точке для:',
  retail_audit_19: 'Оценки соответствия бюджету и процессам',
  retail_audit_20: 'Сравнения фактических результатов с заданными целями',
  retail_audit_21: 'Улучшения эффективности маркетинговых кампаний',
  retail_audit_22: 'Стратегического планирования на будущее',
  retail_audit_23: 'Выявления возможностей по снижению затрат',
  retail_audit_24: 'Повышения объемов продаж и конверсии',
  retail_audit_25: 'Оптимизации инвестиционной отдачи (ROI)',
  retail_audit_26:
    'ОНИ ВЫБРАЛИ 4SERVICE В КАЧЕСТВЕ ПАРТНЕРА ПО ИНВЕСТИЦИЯМ В ТОРГОВЛЮ',
  retail_audit_27:
    'Ведущий международный производитель табачных изделий стал партнером 4Service с целью усиления своих брендов и улучшения работы в магазинах. Наши команды провели аудит маркетинга на месте и реализовали систему реального отчета о впечатлениях потребителей',
  retail_audit_28:
    'увеличение точности планирования агентства по маркетингу в торговых точках',
  retail_audit_29: 'увеличение доставки сообщений бренда',
  retail_audit_30:
    'увеличение вовлеченности представителей бренда (активация потребителей)',
  retail_audit_31:
    'увеличение точности сбора данных и соблюдения требований GDPR',
  retail_audit_32: 'Какие ценные инсайты мы обычно получаем:',
  retail_audit_33:
    'Отсутствие последовательности или даже конфликты между условиями SLA, приводящие к значительным излишним затратам для клиента',
  retail_audit_34:
    'Путаница с видами предоставляемых сервисов, которая приводит к неправильному расчету стоимости услуг',
  retail_audit_35:
    'Полевые работы не проводятся (включая ситуации, когда на запланированную локацию или POS не назначается персонал)',
  retail_audit_36:
    'Расчеты производятся на основании оценки, а не фактических показателей, и не согласовываются',
  retail_audit_37:
    'Промо-инструкция используется неправильно, с предоставлением некорректных данных',
  retail_audit_38: 'Как мы работаем?',
  retail_audit_39:
    'Наш аудитор посещает определенные точки продаж (POS) в соответствии с планом активации агентства.',
  retail_audit_40:
    'Он оценивает ключевые показатели эффективности (KPI), как указано в брифе, такие как присутствие в точках продажи, участие представителей бренда, соблюдение рекомендаций по продвижению и меры по предотвращению мошенничества.',
  retail_audit_41: 'Валидация:',
  retail_audit_42: 'Мы собираем и систематизируем полученные данные.',
  retail_audit_43: 'Готовим подробный отчет.',
  retail_audit_44:
    'Наша группа проверки тщательно проверяет все опросы на точность, включая соответствие брифу проекта и любые дополнительные комментарии.',
  retail_audit_45: 'Система онлайн-отчетности:',
  retail_audit_46:
    'Заполненные и проверенные анкеты загружаются на наш онлайн-портал в течение 36 часов.',
  retail_audit_47:
    'Клиенты имеют доступ к личному аккаунту на портале, что позволяет им отслеживать прогресс в любое время.',
  retail_audit_48:
    'Предоставляется окончательный отчет, содержащий результаты и рекомендации.',
  retail_audit_49: 'Обратитесь к эксперту',
  retail_audit_50: 'Наши сильные стороны:',
  facility_check_audit_1: 'Объект',
  facility_check_audit_2: 'Проверка аудита',
  facility_check_audit_3: 'Поднять уровень брендового опыта в деталях',
  facility_check_audit_4: 'Исследуйте наш подход',
  facility_check_audit_5: 'О нас',
  facility_check_audit_6: 'Кто мы такие',
  facility_check_audit_7:
    'Как мировой лидер в сфере клиентского опыта, 4Service предоставляет широкий спектр услуг, чтобы помочь брендам создавать и предоставлять исключительные впечатления для своих клиентов.',
  facility_check_audit_8: 'Наша услуга по проверке объектов',
  facility_check_audit_9:
    'разработана, чтобы помочь вам убедиться, что ваши физические локации и присутствие бренда в розничных магазинах соответствуют вашим брендовым стандартам и создают положительное впечатление у клиентов.',
  facility_check_audit_10: 'Решения 1',
  facility_check_audit_11: 'Проверка объектов',
  facility_check_audit_12:
    'это тщательное исследование, которое оценивает соответствие ваших розничных точек или площадок стандартам вашего бренда. Мы не только проверяем физические атрибуты, но и оцениваем, как эти элементы влияют на восприятие вашего бренда ваши клиентами.',
  facility_check_audit_13: 'Два решения',
  facility_check_audit_14: 'Два метода внедрения',
  facility_check_audit_15: 'Метод',
  facility_check_audit_16: 'Проверка объектов экспертными аудиторами',
  facility_check_audit_17: 'Подтверждение проверки объекта',
  facility_check_audit_18: 'Отчеты',
  facility_check_audit_19:
    'Вы получите отчеты, сопровождаемые визуальными доказательствами и статистикой, доступные в течение 48 часов',
  facility_check_audit_20:
    'Хорошо проведенная проверка объектов может принести ряд преимуществ вашему бренду, включая',
  facility_check_audit_21: 'Улучшение конверсии клиентов',
  facility_check_audit_22:
    'Положительный клиентский опыт может привести к увеличению продаж и повторным покупкам. Наши аудиты могут помочь вам выявить и устранить любые области, которые могут влиять на удовлетворенность клиентов.',
  facility_check_audit_23: 'Улучшение имиджа бренда',
  facility_check_audit_24:
    'Ваши физические локации являются ключевой точкой контакта для клиентов, и они могут сыграть значительную роль в формировании имиджа вашего бренда. Наши аудиты могут помочь вам убедиться, что ваши локации соответствуют вашим брендовым ценностям и создают положительное впечатление у клиентов.',
  facility_check_audit_25: 'Почему мы?',
  facility_check_audit_26: 'Почему важна проверка объектов?',
  facility_check_audit_27:
    'Хорошо проведенная проверка объектов может принести ряд преимуществ вашему бренду, включая',
  facility_check_audit_28: 'Улучшение имиджа бренда',
  facility_check_audit_29:
    'Ваши физические локации являются ключевой точкой контакта для клиентов, и они могут сыграть значительную роль в формировании имиджа вашего бренда. Наши аудиты могут помочь вам убедиться, что ваши местоположения соответствуют вашим брендовым ценностям и создают положительное впечатление у клиентов.',
  facility_check_audit_30: 'Улучшение конверсии клиентов',
  facility_check_audit_31:
    'Положительный клиентский опыт может привести к увеличению продаж и повторным покупкам. Наши аудиты могут помочь вам выявить и устранить любые области, которые могут влиять на удовлетворенность клиентов.',
  facility_check_audit_32: 'Кому это нужно',
  facility_check_audit_33: 'Кто нуждается в проверке объектов?',
  facility_check_audit_34:
    'Проверка объектов является ценной услугой для любого бренда с физическими локациями и присутствием в реальном мире. Она особенно важна для брендов, которые хотят',
  facility_check_audit_35: 'Обеспечить соответствие брендовым стандартам',
  facility_check_audit_36:
    'Наши аудиты могут помочь вам выявить любые области, где ваши местоположения могут отклоняться от ваших брендовых стандартов.',
  facility_check_audit_37: 'Улучшить клиентский опыт',
  facility_check_audit_38:
    'Наши аудиты могут помочь вам выявить возможности для улучшения клиентского опыта на ваших локациях.',
  facility_check_audit_39: 'Построить более сильный бренд',
  facility_check_audit_40:
    'Наши аудиты могут помочь вам убедиться, что ваши физические локации создают положительное впечатление у клиентов.',
  acility_check_audit_41:
    'Тайный покупатель делает все необходимые фотографии в указанном месте.',
  facility_check_audit_42:
    'Затем тайный покупатель заполняет анкету и прикрепляет фотографии.',
  facility_check_audit_43:
    'Валидатор получает фотографии и проверяет их на соответствие стандартам.',
  facility_check_audit_44: 'Проверка объектов экспертными аудиторами',
  facility_check_audit_45: 'Этап подготовки',
  facility_check_audit_46: 'Совместное утверждение графика аудита',
  facility_check_audit_47:
    'Дилеры готовятся и информируются о предстоящем аудите',
  facility_check_audit_48: 'Подготовка аудиторов',
  facility_check_audit_49:
    'Наши опытные тренеры 4Service готовят аудиторов в соответствии с руководствами бренда',
  facility_check_audit_50: 'Этап проверки объекта',
  facility_check_audit_51:
    'Аудиторы, вооруженные специальными письмами о разрешении, посещают места',
  facility_check_audit_52:
    'Тщательное изучение каждого пункта, следуя рекомендациям',
  facility_check_audit_53:
    'Визуальная документация, совместно с предварительным оцениванием высокого качества',
  facility_check_audit_54: 'Завершающий отчет в формате PPT',
  facility_check_audit_55:
    'Всеобъемлющий отчет PowerPoint, объясняющий результаты',
  facility_check_audit_56:
    'Иммерсивная онлайн/офлайн презентация результатов аудита',
  facility_check_audit_57: 'Валидация аудита объекта',
  facility_check_audit_58: 'Этапы',
  facility_check_audit_59: 'Этап проверки объекта',
  facility_check_audit_60:
    'Представители бренда используют приложение 4Service для создания фотографий в локации',
  facility_check_audit_61:
    'Соблюдение строгих визуальных и описательных критериев',
  facility_check_audit_62: 'Этап валидации',
  facility_check_audit_63:
    'Скурпулезные валидаторы тщательно проверяют каждый отчет',
  facility_check_audit_64:
    'Комплексное сравнение фотографий со стандартами бренда',
  facility_check_audit_65:
    'Аннотации и аналитика интегрируются в отчет для ясности',
  facility_check_audit_66:
    'Прямое общение с аудиторами для уточнений, при необходимости',
  facility_check_audit_67: 'Фаза отчетности',
  facility_check_audit_68:
    'Отчеты, сопровождаемые визуальными доказательствами и статистикой, доступные в течение двух дней',
  facility_check_audit_69: 'Свяжитесь с нами сегодня',
  cx_solution_1: 'Мы не просто находим идеи,',
  cx_solution_2: 'Мы ведем ваш бизнес к новым вершинам',
  cx_solution_3:
    'Эксперты с более чем 20-летним опытом работы с клиентами разрабатывают комплексные решения и предоставляют вам конкретный план действий, способствующий вашему росту',
  cx_solution_4:
    'Откройте для себя уникальный набор услуг в одном будущем-доказательном решении',
  cx_solution_5: 'Все в одном решении CX',
  cx_solution_6: 'Тестирование рекламы',
  cx_solution_7: 'Искусственный интеллект и нейромаркетинг',
  cx_solution_8: 'Голос клиента',
  cx_solution_9: 'Кабинетное исследование',
  cx_solution_10: 'Карта клиентского пути',
  cx_solution_11: 'Тайный шопинг',
  cx_solution_12: 'Консультирование CX',
  cx_solution_13: 'Прослушивание социальных медиа',
  cx_solution_14: 'Количественные и качественные исследования',
  cx_solution_15: 'Дизайн услуг',
  cx_solution_16: 'Маркетинговая стратегия',
  cx_solution_17: 'Тестирование UX/UI',
  cx_solution_18: 'Аудиторские аспекты',
  cx_solution_19: 'Мы поможем вам достичь успеха',
  cx_solution_20:
    'Мы настраиваем и комбинируем инструменты и услуги на основе целей и задач вашего бизнеса, чтобы достичь определенных результатов',
  cx_solution_21: 'Комплексные исследования',
  cx_solution_22:
    'Дайте силы вашему принятию решений, обеспечивая конкурентное преимущество на рынке, ориентированном на данные.',
  cx_solution_23:
    'Качественные исследования - это сбор и анализ нецифровых данных для понимания концепций, мнений или опыта',
  cx_solution_24:
    'Методы количественного исследования - это объективное измерение и числовой анализ данных, собранных через интервью, анкеты и опросы',
  cx_solution_25:
    'Мы используем особый набор инструментов в зависимости от ваших конкретных требований',
  cx_solution_26: 'Инструменты',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Компьютерное интервью с веб-ассистентом - важный инструмент в нашем арсенале, позволяющий нам проводить опросы и собирать данные через онлайн-платформы с помощью специализированного программного обеспечения, обеспечивая эффективный и точный сбор данных для потребностей наших клиентов в маркетинговых исследованиях',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Компьютерное телефонное интервью - метод, который мы используем для проведения опросов и сбора ценных данных по телефону, применяя специализированное программное обеспечение для упрощения процесса и обеспечения точности в наших исследовательских усилиях на рынке',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Лицом к лицу - неотъемлемая часть нашего подхода, облегчающая прямое взаимодействие и персонализированные взаимодействия с клиентами и заказчиками, тем самым способствуя доверию, пониманию и эффективному общению, необходимых для построения долгосрочных отношений и обеспечения успеха бизнеса',
  cx_solution_33: 'Подготовительное исследование',
  cx_solution_34:
    'Подготовительное исследование включает в себя всесторонний сбор данных и анализ, служащий фундаментальным этапом в нашем исследовательском процессе для сбора информации и информирования стратегического принятия решений для наших клиентов',
  cx_solution_35: 'SWOT-анализ',
  cx_solution_36:
    'SWOT-анализ является стратегическим инструментом оценки, который помогает нашим клиентам выявить внутренние сильные и слабые стороны, а также внешние возможности и угрозы, обеспечивая ценные инсайты для принятия решений и разработки эффективных стратегий бизнеса',
  cx_solution_37: 'Этнографическое исследование',
  cx_solution_38:
    'Изучение людей и культур через погружение и наблюдение в их естественных средах обитания, часто приводящее к глубокому пониманию линий поведения, привычек, убеждений и социальной динамики',
  cx_solution_39: 'Глубинные интервью',
  cx_solution_40:
    'Глубинные интервью - это метод качественного исследования, включающий тщательные персональные дискуссии с участниками для погружения в их перспективы, опыт и мнения, что предоставляет богатые инсайты и понимание для достижения целей исследования и принятия решений для наших клиентов',
  cx_solution_41: 'Сессии идей',
  cx_solution_42:
    'Сессии идей - это коллективные сессии мозгового штурма, которые мы организуем, объединяя разнообразные перспективы и экспертизу для генерации креативных идей и решений для вызовов или возможностей наших клиентов, стимулируя инновации и продвигая стратегические инициативы вперед',
  cx_solution_43: 'Покупательские персоны',
  cx_solution_44:
    'Покупательские персоны - это подробные профили, которые мы разрабатываем, представляя целевых клиентов наших заказчиков на основе исследований и анализа данных, позволяя нам лучше понимать их потребности, предпочтения и поведение, и адаптировать наши стратегии и коммуникации для эффективного взаимодействия и резонанса с ними, в конечном итоге способствуя успеху бизнеса',
  cx_solution_45: 'Тайный шопинг',
  cx_solution_46:
    'Тайный шопинг - это стратегическая услуга, которую мы предлагаем, измеряя и контролируя взаимодействие между клиентом и компанией в предопределенном сценарии',
  cx_solution_47: 'Сопровождение проверки',
  cx_solution_48:
    'Сопровождение проверки - это специализированная форма тайного шопинга, при которой наши оценщики сопровождают клиентов на протяжении всего их сервисного опыта, обеспечивая всесторонние инсайты в каждом аспекте клиентского пути, что позволяет нашим клиентам глубоко понять свое обслуживание и предпринять логические меры для улучшения общей удовлетворенности клиентов и их лояльности',
  cx_solution_49: 'Сбор и анализ обратной связи',
  cx_solution_50:
    'Сбор и анализ обратной связи - это критический процесс, который мы проводим. Он включает систематический сбор и анализ отзывов клиентов из различных каналов, таких как опросы, отзывы и социальные медиа, что позволяет нам извлекать ценные инсайты, выявлять тренды и делать основанные на полученных данных рекомендации для наших клиентов по улучшению их продуктов, услуг и общего клиентского опыта',
  cx_solution_51: 'Фокус-группы',
  cx_solution_52:
    'Фокус-группы - это динамичные исследовательские сессии, которые мы проводим, объединяя разнообразную группу участников для глубинного обсуждения конкретных тем или продуктов, что позволяет нам собирать качественные инсайты, выявлять взаимодействия, предпочтения и восприятия, и исследовать идеи, концепции, информируя наших клиентов о разработках стратегий дальнейших решений.',
  cx_solution_53: '20 лет создания исключительных клиентских опытов',
  cx_solution_54:
    'Будьте уверены, что ваше сообщение завораживает и резонирует с вашей аудиторией.',
  cx_solution_55:
    'Мы посвящаем себя достижению результатов, которые превзойдут ваши ожидания',
  cx_solution_56: 'Мы находим комплексные решения, способствующие вашему росту',
  cx_solution_57:
    'Поднимите уровень вашего клиентского опыта с нашей методологией ',
  cx_solution_58:
    'Карта пути клиента - это определение целевой аудитории, понимание их потребностей и окружения, а затем создание плана для обеспечения привлекательного опыта',
  cx_solution_59: 'ОЗБ',
  cx_solution_60:
    'Исследование состояния бренда может измерить, как ваш бренд выступает по уровню осведомленности и использования, позиционированию бренда и его результативности',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'Индекс удовлетворенности клиентов - это показатель того, насколько ваши клиенты довольны вашими продуктами / услугами компании.',
  cx_solution_63:
    'Мониторинг цен - это процесс сбора, обработки и анализа цен конкурентов',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Оценка удовлетворенности Клиента - это золотой стандарт метрик клиентского опыта, основанный на одном вопросе',
  cx_solution_66: 'HR research',
  cx_solution_67:
    'Исследование в области управления человеческими ресурсами используется для оценки практик и производительности управления человеческими ресурсами и предоставляет детальный анализ текущего развития и управления',
  cx_solution_68:
    'Тестирование удобства использования - это то, как реальные люди взаимодействуют с веб-сайтом, приложением или другим продуктом, и наблюдение за их поведением и реакциями на него',
  cx_solution_69: 'Другое',
  cx_solution_70: 'Эксперты выбирают лучшую методологию для вашего бизнеса',
  cx_solution_71: 'Давайте начнем',
  cx_solution_72: '1 - ПЕРВОНАЧАЛЬНАЯ ВСТРЕЧА',
  cx_solution_73:
    'Во время начальной встречи мы определяем и фокусируемся на ключевых задачах. Результат',
  cx_solution_74: '2 - РАЗРАБОТКА КОНЦЕПЦИИ',
  cx_solution_75:
    'Отдел аналитики 4Service агрегирует данные, координирует структуру отчета. Затем мы представляем результаты клиенту, дополняя их исследованиями и рекомендациями по внедрению.',
  cx_solution_76: '3 - ПОЛЕВЫЕ ИССЛЕДОВАНИЯ',
  cx_solution_77:
    'Для проведения исследования мы набираем участников, проводим интервью и другие основные этапы исследования. Клиент получает транскрибированные аудио-видео интервью, а также краткие сводки.',
  cx_solution_78: '4 - АНАЛИТИКА',
  cx_solution_79:
    'Отдел аналитики 4Service агрегирует данные, координирует структуру отчета. Затем мы представляем результаты клиенту, дополняя их исследованиями и рекомендациями по внедрению.',
  cx_solution_80: '5 - ВОРКШОП',
  cx_solution_81:
    'Проведение обсуждения с клиентом относительно плана внедрения',
  cx_solution_82: 'Узнайте больше о наших лучших практиках',
  cx_solution_83:
    'Карта пути клиента позволяет быстро исследовать все коммуникационные каналы, выявить потенциальные недостатки на ключевых точках контакта и полноценно понять области, которые могут отклониться от запланированного курса.',
  cx_solution_84: 'Когда это используется?',
  cx_solution_85:
    'Создание персонализированной рекламной кампании на основе реальных данных о группах аудитории',
  cx_solution_86:
    'Выявление триггеров различных сегментов целевой аудитории и удовлетворение их потребностей',
  cx_solution_87:
    'Точное фокусирование на каждой группе и получение максимальной конверсии',
  cx_solution_88:
    'Техника сопровождения позволяет оценить возможности оптимальных способов достижения ваших целей.',
  cx_solution_89: 'Тестирование нового веб-сайта или продукта перед запуском',
  cx_solution_90: 'Проверка разработанных процессов перед внедрением изменений',
  cx_solution_91: 'Оценка веб-сайта или продукта в условиях снижения продаж',
  cx_solution_92: 'Запуск нового продукта, отвечающего предпочтениям клиентов',
  cx_solution_93:
    'Изучение конкурирующих компаний для обеспечения лидирующего положения на рынке',
  cx_solution_94:
    'Мы предлагаем всесторонний подход с обзором 360° к созданию модели обслуживания, ориентированной на клиента.',
  cx_solution_95: 'Обучение и развитие персонала',
  cx_solution_96: 'Повышение стандартов обслуживания',
  cx_solution_97: 'Увеличение потока клиентов и продаж',
  cx_solution_98: 'Установка KPI и системы мотивации для сотрудников',
  cx_solution_99: 'Исследуйте наши истории успеха',
  cx_solution_100: 'Автомобильная отрасль',
  cx_solution_101: 'Табачная отрасль',
  cx_solution_102: 'Розничная торговля',
  cx_solution_103: 'Качественная фаза',
  cx_solution_104: 'Количественная фаза (сегментация)',
  cx_solution_105: 'Фокус-группы',
  cx_solution_106: 'Охват аудитории онлайн по всему миру',
  cx_solution_107: 'Смешанные техники проведения интервью',
  cx_solution_108: 'Только открытые вопросы',
  cx_solution_109: 'Поддержка респондентов 24/7',
  cx_solution_110: 'Сохранение важных деталей и реальной картины пути',
  cx_solution_111: 'Маркетинговые исследования',
  cx_solution_112: 'Диагностика компании и конкурентов',
  cx_solution_113: 'Обновленные модели обслуживания и новые бизнес-процессы',
  cx_solution_114: 'Продвижение клиентоориентированного обслуживания',
  cx_solution_115:
    'Внедрение изменений и контроль над развитием стратегий обслуживания',
  usage_attitude_1: 'Использование и связь (U&A)',
  usage_attitude_2:
    'Получите всесторонние идеи о том, как целевая аудитория  воспринимает, использует и взаимодействует с вашим брендом.',
  usage_attitude_3: 'Поговорите с экспертом',
  usage_attitude_4: 'О',
  usage_attitude_5:
    '<b>Изучите</b> привычки потребителей, чтобы выявить глубинные предпочтения и влияния',
  usage_attitude_6:
    '<b>Узнайте `что`</b> стоит за выбором потребителя с нашим детальным анализом',
  usage_attitude_7:
    '<b>Понимайте, как</b> клиенты взаимодействуют с вашими продуктами для разработки стратегических идей',
  usage_attitude_8:
    '<b>Получите идеи о</b> поведении, привычках и предпочтениях потребителей для принятия обоснованных решений',
  usage_attitude_9: 'Опыт',
  usage_attitude_10:
    'Эксперты 4Service с более чем 20-ти летним опытом работы с клиентами создают уникальные решения, способствующие росту вашего бизнеса',
  usage_attitude_11: 'Свяжитесь с нами',
  usage_attitude_12: 'Для кого',
  usage_attitude_13: 'Кто выигрывает от использования бренда и связи с ним?',
  usage_attitude_14: 'Различные отрасли и секторы услуг заинтересованы в:',
  usage_attitude_15: '<b>Понимании</b> потребностей и проблем клиентов',
  usage_attitude_16:
    '<b>Выявлении</b> возможностей для увеличения потребления клиентами',
  usage_attitude_17:
    '<b>Разработке</b> новых категорий продуктов или маркетинговых стратегий',
  usage_attitude_18:
    '<b>Получении</b> глубокого понимания конкурентного окружения',
  usage_attitude_19:
    'Методология выбирается индивидуально в зависимости от запроса клиента',
  usage_attitude_20:
    '<b>Качественное исследование</b> включает сбор и анализ нечисловых данных для понимания концепций, мнений или опыта.',
  usage_attitude_21:
    'Методы <b>количественного исследования</b> предполагают объективное измерение и цифровой анализ данных, полученных через опросы, анкеты и исследования.',
  usage_attitude_22:
    '<b>После сбора данных</b> эксперты анализируют информацию и делают выводы, чтобы предоставить вам лучшие рекомендации.',
  usage_attitude_23: 'Кто в этом нуждается',
  usage_attitude_24: 'Ваша история успеха начинается здесь',
  usage_attitude_25:
    '<b>Вы получите исследование</b> с ценными идеями, которые обеспечат стратегическое принятие решений, гарантируя, что действия основаны на глубоком понимании поведения потребителей.',
  usage_attitude_26:
    '<b>Исследование обеспечивает прочную основу для уточнения</b> и оптимизации стратегий бренда, более тесно соответствующих предпочтениям и отношениям потребителей.',
  usage_attitude_27: 'Решения',
  usage_attitude_28: 'Исследуйте многочисленные возможности с нашими решениями',
  usage_attitude_29: 'Предпочтение продукта внутри категории',
  usage_attitude_30:
    'Определите топ предпочтений потребителей в различных категориях, включая продукты питания, напитки, одежду, электронику и многое другое.',
  usage_attitude_31: 'Поведение потребления / использования',
  usage_attitude_32:
    'Проанализируйте частоту потребления или использования конкретных продуктов/услуг и определите случаи и моменты, связанные с их использованием.',
  usage_attitude_33: 'Поведение при покупке',
  usage_attitude_34:
    'Исследуйте каналы, которые клиенты используют для своих покупок, и определите источники информации, на которые они полагаются.',
  usage_attitude_35: 'Мотивации и потребности потребителей',
  usage_attitude_36:
    'Изучите мотивации и преграды, влияющие на потребление. Оцените факторы, влияющие на решения покупателей. Исследуйте восприятие категории продуктов и проанализируйте, как воспринимается цена.',
  usage_attitude_37: 'Наш подход',
  usage_attitude_38: 'Исследование использования бренда и отношения к нему',
  usage_attitude_39: 'Информация',
  usage_attitude_40:
    'Проведение опросов с использованием как количественных, так и качественных методов',
  usage_attitude_41: 'Начнем',
  usage_attitude_42:
    'Установка целей и определение методов на основе индивидуальных запросов',
  usage_attitude_43: 'Анализ',
  usage_attitude_44: 'Сложные техники, используемые нашими экспертами',
  usage_attitude_45:
    'Мы используем особенный набор инструментов в соответствии с вашими конкретными требованиями',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Компьютерное телефонное интервью - метод, который мы используем для проведения опросов и сбора ценных данных по телефону, используя специализированное программное обеспечение для оптимизации процесса и обеспечения точности в наших исследованиях рынка.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Компьютерное веб-интервью - важный инструмент в нашем арсенале, который позволяет проводить опросы и собирать данные через онлайн-платформы с помощью специализированного программного обеспечения, обеспечивая эффективный и точный сбор данных для потребностей наших клиентов в исследованиях рынка.',
  usage_attitude_50: 'САРІ',
  usage_attitude_51:
    'Компьютерное личное интервью - метод, который мы используем для оптимизации процессов сбора данных, чтобы сделать интервью более гладкими и обеспечить точные и проницательные результаты для ваших бизнес-потребностей.',
  usage_attitude_52: 'Фокус-группы',
  usage_attitude_53:
    'Фокус-группы – это динамические исследовательские сессии, которые мы проводим, объединяя разнообразную группу участников для углубленного обсуждения конкретных тем или продуктов, что позволяет нам собирать качественную информацию, раскрывать отношение, предпочтения и восприятия, а также исследовать идеи и концепции, наконец, информировать наших клиентов о процессах принятия решений и разработке стратегии.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Личное общение является неотъемлемой частью нашего подхода, облегчая непосредственное взаимодействие и персонализированное общение с клиентами и заказчиками, тем самым укрепляя доверие, понимание и эффективную коммуникацию, необходимую для построения длительных отношений и стимулирования бизнес-групп.',
  usage_attitude_56: 'Этнографическое исследование',
  usage_attitude_57:
    'Изучение людей и культур через погружение и наблюдение в их природной среде, что часто ведет к глубокому пониманию поведения, убеждений и социальной динамики.',
  thank_you_page:
    'Благодарим за ваше время! Наш менеджер скоро свяжется с вами.',
  back_main: 'Вернуться на главную',
  opportunities_1: 'Узнайте больше о наших кейсах',
  opportunities_2: 'Откройте',
  opportunities_3: 'для себя',
  opportunities_4: 'возможности',
  opportunities_5:
    'международного бизнеса вместе с исследовательским холдингом 4Service',
  opportunities_6:
    'Какими <span>продуктами</span> мы можем укрепить ваш бизнес?',
  opportunities_7: 'СХ',
  opportunities_8: 'Комплексные кастомизированные решения для бизнеса',
  opportunities_9: 'Все виды Mystery Shopping',
  opportunities_10:
    'Мы реализуем тайный сотрудник, тайный звонящий, тайный покупатель класса люкс, тайный покупатель автомобилей и другие виды',
  opportunities_11: 'Кастомизированные it-решения для бизнеса',
  opportunities_12:
    'Собственные it-решения с ИИ, платформы для аналитики обратной связи и траскрибации звонков',
  opportunities_13: 'Исследование рынка',
  opportunities_14:
    'Под любые запросы бизнеса (Отслеживание здоровья бренда, Cегментация, NPS, eNPS, CJM, Тестирование продуктов и рекламы и пр.)',
  opportunities_15: 'Аудиты',
  opportunities_16:
    'Мы реализуем разного рода аудиты сервиса, а также мониторинг цен (офлайн, для специфических ниш и прочие)',
  opportunities_17: 'Сервис дизайн',
  opportunities_18:
    '4Service - сертифицированные партнеры DesignThinkers Academy, предоставляем корпоративное обучение для команд и регулярно проводим воркшопы для бизнеса',
  opportunities_19:
    'Мы делимся опытом, но <span>уважаем и не нарушаем</span> ваш бизнес-процесс.',
  opportunities_20:
    'Мы знаем, с какими трудностями сталкивается бизнес на пути к развитию и мы знаем как преодолеть эти барьеры благодаря международному успешному опыту. Вы получаете не только опыт, но и ресурсы.',
  opportunities_21: 'Приглашаем к сотрудничеству!',
  opportunities_22:
    'Мы предлагаем <span>программу партнерства</span> для вашего бизнеса',
  opportunities_23:
    'Под международно известным брендом 4Service вы сможете положительно влиять на привлечение новых клиентов.',
  opportunities_24:
    'Наши партнеры получают всестороннюю поддержку, включая обучение, маркетинговые материалы и операционную помощь.',
  opportunities_25:
    'Партнерство с нами воплотит ваши планы без нарушения вашего текущего бизнес-процесса.',
  opportunities_26: 'Наши <span>офисы</span>',
  opportunities_27: 'Наши клиенты',
  opportunities_28: 'У нас более 20-ти лет опыта в СХ',
  opportunities_29: 'Стран охвачено',
  opportunities_30: 'тыс',
  opportunities_31: 'Уникальных проектов',
  opportunities_32: 'млн',
  opportunities_33: 'Визитов тайных покупателей',
  opportunities_34: 'входит в ТОП 10 Европейских СХ агентств',
  opportunities_35: 'Ваши возможности в работе с 4Service',
  opportunities_36: 'Увеличение прибыли',
  opportunities_37: 'Вы получите доступ к международным заказам',
  opportunities_38: 'Уникальный СХ подход',
  opportunities_39: 'Поддержка от экспертов с 20ти-летним опытом',
  opportunities_40: 'Сопровождение',
  opportunities_41: 'Наша команда сопроводит вас к результатам',
  opportunities_42: 'Продажи',
  opportunities_43: 'Мы поможем вам наладить систему продаж',
  opportunities_44: 'Доступ к ПО',
  opportunities_45:
    'Вы получите доступ ко всем сертифицированным it-инструментам',
  opportunities_46: 'Обучение',
  opportunities_47:
    'Мы не только обучим ваш персонал, но и продолжим консультировать вас ',
  opportunities_48: 'Кому подойдет партнерство?',
  opportunities_49: 'Ваш бизнес связан с маркетинговыми исследованиями?',
  opportunities_50: 'Или вы планируете попробовать себя в новой сфере',
  opportunities_51: 'Планируете:',
  opportunities_52: 'Внедрить инновации',
  opportunities_53: 'Работать с международными брендами',
  opportunities_54: 'Расширить продуктовый портфель',
  opportunities_55: 'Укрепить свои позиции на рынке?',
  opportunities_56: 'Обучить ваших сотрудников',
  opportunities_57: 'Масштабировать ваш бизнес',
  opportunities_58: 'Наши ресурсы = Ваши ресурсы',
  opportunities_59:
    'С учетом нашего обширного опыта, мы готовы на первом этапе сотрудничества делиться им с вами',
  opportunities_60: 'Анализ данных:',
  opportunities_61: 'Международная аналитическая команда',
  opportunities_62: 'Методики обработки данных - SPSS, Power BI',
  opportunities_63: 'Инновационный методики и широкий спектр методологий',
  opportunities_64: 'Отчетность в любом формате',
  opportunities_65: 'Программное обеспечение:',
  opportunities_66: 'Собственные кастомизированные it-разработки',
  opportunities_67: 'DigSee Sure для CAPI',
  opportunities_68: 'SurveyMonkey для CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (колл-центр)',
  opportunities_71: 'Маркетинговая поддержка:',
  opportunities_72: 'PR-поддержка',
  opportunities_73: 'Сопровождение в продвижении и создании контента',
  opportunities_74: 'Налаживание процессов лидогенерации',
  opportunities_75: 'IT-поддержка: ',
  opportunities_76:
    'Техническая поддержка с установкой, настройкой и использованием нашего программного обеспечения.',
  opportunities_77: 'Дистанционная помощь',
  opportunities_78: 'Контроль безопасности',
  opportunities_79: 'Операционное сопровождение:',
  opportunities_80: 'Сопровождение в налаживании полевых процессов',
  opportunities_81: 'Система антифрода',
  opportunities_82: 'Рекрутинг любой сложности',
  opportunities_83:
    'Операционное управление командой проектного и полевого отделов',
  opportunities_84: 'Контроль качества рекрутинга',
  opportunities_85: 'UpWork',
  opportunities_86: 'Какие бонусы вы получите?',
  opportunities_87: 'Увеличение клиентской базы',
  opportunities_88: 'Укрепление имиджа на рынке',
  opportunities_89: 'Диверсификация рисков потери клиентов',
  opportunities_90: 'Оптимизация технологических процессов',
  opportunities_91: 'Международный опыт',
  opportunities_92: 'Больше продаж текущим клиентам',
  parthership: 'Партнерство',
  minimum_number_characters: 'минимальное количество 2 символа',
  request_details: 'Запросить подробности',
  footer_7: 'Исследование рынка',
  main_66:
    'Сбор подробных данных о вашем магазине или бренде, анализ препятствий для роста и создание эффективных стратегий для их преодоления',
  methods_hr_48: 'коллекция',
  menu_22: 'Контрольный аудит объекта',
  menu_23: 'Сервис-дизайн',
  menu_24: 'CX',
  facility_check_audit_41:
    'Ваш сотрудник или тайный покупатель фотографирует место.',
  main_67:
    'Анализ конкурентных цен оптимизирует предложения, повышает прибыль и увеличивает долю рынка, используя данные о готовности потребителей платить для определения ключевых характеристик',
  main_68:
    'Убедитесь, что ваши торговые точки соответствуют стандартам бренда, оцените физические характеристики и их влияние на восприятие покупателями',
  main_69: 'CX-решение',
  main_70:
    'Откройте для себя наше уникальное 360-градусное решение в области CX: мы разрабатываем инструменты и услуги в соответствии с вашими целями для развития вашего бизнеса',
  main_71: 'Речевая аналитика',
  main_72:
    'Наш передовой инструмент анализа речи транскрибирует устную речь в текст, предоставляя бесценные сведения для компаний, чтобы повысить качество обслуживания клиентов, контролировать производительность, обеспечить соответствие нормативным требованиям и стимулировать рост',
  brand_awareness_1: 'Узнаваемость бренда',
  brand_awareness_2: 'и использование',
  brand_awareness_3:
    'Раскройте потенциал узнаваемости и использования вашего бренда для достижения успеха в бизнесе',
  brand_awareness_4: 'Поговорите с экспертом',
  brand_awareness_5: 'О',
  brand_awareness_6:
    '<b>Оцените степень</b> узнаваемости и знакомства с вашим брендом',
  brand_awareness_7:
    '<b>Определите уровень узнаваемости бренда </b> на вашем целевом рынке',
  brand_awareness_8: '<b>Следите за показателями</b> удержания и лояльности',
  brand_awareness_9:
    '<b>Выберите оптимальную стратегию</b> для улучшения необходимых показателей',
  brand_awareness_10:
    'Откройте для себя многочисленные возможности с нашими решениями',
  brand_awareness_11: 'Узнаваемость бренда',
  brand_awareness_12: 'Использование бренда',
  brand_awareness_13:
    '<b>Узнайте</b> об узнаваемости вашего бренда среди потребительских сегментов',
  brand_awareness_14:
    '<b>Отслеживайте</b> уровень узнаваемости среди вашей аудитории',
  brand_awareness_15:
    '<b>Изучайте</b> спонтанные мнения потребителей, которые собираются совершить покупку',
  brand_awareness_16:
    '<b>Поймите</b> основные источники, способствующие повышению узнаваемости вашего бренда',
  brand_awareness_17:
    '<b>Учитывайте</b> количество потребителей, которые сталкивались с продуктами или услугами вашего бренда',
  brand_awareness_18:
    '<b>Определите</b> потребителей, которые остались с вашим брендом после первой попытки',
  brand_awareness_19:
    '<b>Разблокируйте</b> вероятность того, что ваш бренд будут рекомендовать',
  brand_awareness_20:
    '<b>Раскройте</b> процент потребителей, которые рассматривают ваш бренд для дальнейшего использования',
  brand_awareness_21:
    'Кто получает выгоду от узнаваемости и использования бренда?',
  brand_awareness_22: 'Компании, которые выводят на рынок новый продукт',
  brand_awareness_23: 'Компании, которые обновляют уже существующий бренд',
  brand_awareness_24:
    'Любой бизнес, который стремится понять и улучшить то, как его бренд узнают и используют потребители',
  brand_awareness_25:
    'Наши методологии предоставляют ценную информацию для оптимизации узнаваемости и использования вашего бренда',
  brand_awareness_26:
    'Методология подбирается индивидуально, исходя из запроса клиента',
  brand_awareness_27: 'Методология воронки использования бренда',
  brand_awareness_28:
    'Коэффициент проб показывает долю аудитории, которая знакома с брендом и попробовала его продукты или услуги.',
  brand_awareness_29:
    'Коэффициент повторных обращений показывает процент аудитории, которая знакома с брендом и попробовала его продукты или услуги, и продолжила совершать покупки или пользоваться предложениями в течение последних 12 месяцев.',
  brand_awareness_30:
    'Коэффициент удержания показывает долю лиц, которые постоянно пользовались продуктами или услугами бренда, то есть процент тех, кто пользовался этими предложениями в течение последних 6 месяцев.',
  brand_awareness_31:
    'Коэффициент удержания иллюстрирует процент клиентов, которые, воспользовавшись продуктами/услугами бренда в течение последних 6 месяцев, продолжают выбирать и демонстрировать лояльность к бренду в течение длительного времени.',
  brand_awareness_32: 'Методология исследования осведомленности о бренде',
  brand_awareness_33:
    'Top of mind (TOM) - первый бренд, который спонтанно вспомнил респондент.',
  brand_awareness_34:
    'Общая спонтанная осведомленность - это общее количество спонтанных упоминаний, включая TOM.',
  brand_awareness_35:
    'Вспомогательная осведомленность показывает, насколько хорошо ваш бренд узнается пользователями, когда они получают соответствующую подсказку.',
  brand_awareness_36: 'Карта эффективности атрибутов бренда',
  brand_awareness_37:
    'Этот инструмент оценивает и визуально представляет важность различных атрибутов бренда, предоставляя представление о том, как каждый из них влияет на общую эффективность вашего бренда и его восприятие потребителями.',
  brand_awareness_38:
    'Раскройте ключи к усилению влияния вашего бренда с помощью нашего комплексного анализа.',
  brand_awareness_39: 'Карта восприятия брендов',
  brand_awareness_40:
    'Этот инструмент предлагает визуальное представление потребительского восприятия в условиях конкурентного рынка. Этот подход имеет решающее значение для четкого определения различий между брендами, подчеркивая ключевые рыночные атрибуты.',
  brand_awareness_41:
    'Он упрощает наблюдение и интерпретацию взаимосвязей между брендами и этими определяющими характеристиками.',
  brand_awareness_42:
    'Близость конкретного бренда к определенным атрибутам означает его сильную ассоциацию с этими характеристиками. Аналогично, близость между двумя брендами указывает на их сходство, демонстрируя общие образы и, как следствие, принадлежность к одному сегменту рынка.',
  consumer_journey_map_1: 'Карта пути потребителя',
  consumer_journey_map_2:
    'Выявляйте и преодолевайте барьеры в вашем бизнесе с помощью нашей карты путешествия потребителя',
  consumer_journey_map_3: 'ДОВЕРЯЮТ',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Раскройте потенциал CJM (карты путешествия потребителя) для оптимизации взаимодействия между вашими потребителями и брендом',
  consumer_journey_map_6:
    'Определите, какие шаги делает потребитель, прежде чем купить товар',
  consumer_journey_map_7:
    'Получите инсайты от ваших реальных, а не воображаемых клиентов',
  consumer_journey_map_8: 'Получите полное понимание опыта ваших клиентов',
  consumer_journey_map_9:
    'Определите упущенные возможности и сравните с вашими конкурентами',
  consumer_journey_map_10: 'Откройте для себя наш подход CJM',
  consumer_journey_map_11:
    'Мы создаем путь взаимодействия потребителя с продуктом, услугой, компанией или брендом через различные каналы и в течение определенного периода времени.',
  consumer_journey_map_12:
    'Мы очерчиваем этапы, которые проходят ваши потребители, прежде чем совершить покупку, ожидания, эмоции и мотивации клиента на каждом этапе.',
  consumer_journey_map_13: 'Получайте выгоду вместе с нами',
  consumer_journey_map_14:
    'Мы предоставляем команду опытных профессионалов, которые разбираются в тонкостях глобального бизнес-успеха',
  consumer_journey_map_15:
    'Мы стремимся достичь результатов, которые превзойдут ваши ожидания',
  consumer_journey_map_16:
    'Мы находим комплексные решения, которые способствуют вашему росту',
  consumer_journey_map_17:
    'Мы специализируемся на предоставлении индивидуальных решений CJM',
  consumer_journey_map_18: 'Запишитесь на консультацию',
  consumer_journey_map_19: 'Решения на основе данных',
  consumer_journey_map_20:
    'Базируйте свои решения на реальных данных, а не на предположениях',
  consumer_journey_map_21: 'Оптимизируйте свою CJM',
  consumer_journey_map_22:
    'Постройте более прочные отношения с клиентами, удовлетворяя их потребности и ожидания на каждом этапе их путешествия',
  consumer_journey_map_23: 'Будьте лидером',
  consumer_journey_map_24: 'Сравнивайте себя с конкурентами и опережайте рынок',
  consumer_journey_map_25: 'Рекомендации',
  consumer_journey_map_26:
    'Внедряйте наши рекомендации CJM, чтобы достичь успеха, повысить лояльность клиентов, увеличить продажи и максимизировать свой бизнес-потенциал',
  consumer_journey_map_27:
    'Откройте для себя настоящий путь потребителя с помощью нашей карты потребительского путешествия',
  consumer_journey_map_28:
    'Эксперты с более чем 20-летним опытом работы проводят комплексное исследование',
  consumer_journey_map_29:
    'Качественное исследование предусматривает сбор и анализ нечисловых данных для понимания концепций, мнений или опыта.',
  consumer_journey_map_30:
    'Количественные методы исследования предусматривают объективное измерение и числовой анализ данных, полученных с помощью опросов, анкетирования и исследований.',
  consumer_journey_map_31:
    'После сбора данных эксперты анализируют информацию и делают выводы, чтобы предоставить вам лучшие рекомендации.',
  boarding_1: 'Карта путешествия клиента',
  boarding_2:
    'Составление карты путешествия клиента (Customer Journey Mapping, CJM) - это определение целевой аудитории, понимание ее потребностей и окружения, а затем создание плана по предоставлению увлекательного опыта',
  boarding_3: 'Чистая оценка продвижения',
  boarding_4:
    'Чистая оценка продвижения (NPS) - это золотой стандарт показателей клиентского опыта, основанный на одном вопросе: Насколько вероятно, что вы порекомендуете организацию/продукт/услугу?',
  boarding_5: 'Отслеживание здоровья бренда',
  boarding_6:
    'Отслеживание здоровья бренда (ОЗБ) исследования позволяют определить, насколько ваш бренд осведомлен и используется, как он позиционируется и как работает',
  boarding_7: 'Исследование человеческих ресурсов',
  boarding_8:
    'Исследование человеческих ресурсов используется для оценки практики и эффективности работы HR и предлагает подробный анализ текущего развития и управления',
  boarding_9: 'UX-тестирование',
  boarding_10:
    'Тестирование юзабилити (UX) - это наблюдение за тем, как реальные люди взаимодействуют с веб-сайтом, приложением или другим продуктом, и наблюдение за их поведением и реакцией на него',
  boarding_11: 'Индекс удовлетворенности клиентов',
  boarding_12:
    'Удовлетворенность клиентов измеряет степень удовлетворения их ожиданий в отношении приобретения продукта или услуги, предоставляемых вашей компанией',
  boarding_13: 'Тестирование рекламы',
  boarding_14:
    'Рекламная кампания - это огромные инвестиции. Убедитесь, что ваша реклама эффективна и обеспечивает максимальную рентабельность инвестиций',
  boarding_15: 'Полевой аудит/ аудит розничной торговли',
  boarding_16:
    'Сбор подробных данных о вашем магазине или бренде, анализ препятствий для роста и создание эффективных стратегий для их преодоления',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Программное обеспечение для управления эффективностью. Геймифицированное приложение для увеличения продаж',
  boarding_19: 'Voicer',
  boarding_20:
    'Система управления отзывами. Голос вашего клиента в одной системе. Система управления отзывами клиентов',
  boarding_21: 'Качественные исследования',
  boarding_22:
    'Качественное исследование - это сбор и анализ нечисловых данных для понимания концепций, мнений или опыта',
  boarding_23: 'Количественные исследования',
  boarding_24:
    'Количественные методы исследования - это объективное измерение и числовой анализ данных, собранных с помощью опросов, анкет и голосований',
  boarding_25: 'Тайный покупатель',
  boarding_26:
    'Тайный покупатель - это метод маркетинговых исследований, позволяющий измерять и отслеживать взаимодействие между клиентом и компанией или организацией в рамках заранее определенного сценария',
  boarding_27: 'Wantent',
  boarding_28:
    'Платформа для анализа контента на основе искусственного интеллекта, позволяющая получать информацию от аудитории',
  boarding_29: 'Мониторинг цен ',
  boarding_30:
    'Мониторинг цен - это процесс сбора, обработки и анализа информации о ценах конкурентов',
  boarding_31: 'Сервис дизайн',
  boarding_32:
    'Сервис дизайн - это то, как понять потребности и желания людей, которые будут пользоваться продуктом или услугой, проведя с ними время',
  boarding_33: 'Анализ цен в автомобильной промышленности',
  boarding_34:
    'Анализ конкурентных цен оптимизирует предложения, повышает прибыль и увеличивает долю рынка, используя данные о готовности потребителей платить для определения ключевых характеристик',
  boarding_35: 'Аудит проверки объекта',
  boarding_36:
    'Убедитесь, что ваши торговые точки соответствуют стандартам бренда, оцените физические характеристики и их влияние на восприятие покупателями',
  boarding_37: 'CX-решение',
  boarding_38:
    'Откройте для себя наше уникальное 360-градусное решение в области CX: мы разрабатываем инструменты и услуги в соответствии с вашими целями для развития вашего бизнеса',
  boarding_39: 'Речевая аналитика',
  boarding_40:
    'Наш передовой инструмент анализа речи транскрибирует устную речь в текст, предоставляя бесценные сведения для компаний, чтобы повысить качество обслуживания клиентов, контролировать производительность, обеспечить соответствие нормативным требованиям и стимулировать рост',
  boarding_41: 'Использование и отношение к бренду',
  boarding_42:
    'Использование и отношение - это наш подход к пониманию того, как аудитория взаимодействует с вашим брендом, путем анализа практических моделей использования и психологических установок, что позволяет разрабатывать индивидуальные маркетинговые и продуктовые стратегии',
  boarding_43: 'Осведомленность о бренде и его использовании',
  boarding_44:
    'Оценка узнаваемости и использования бренда включает в себя оценку узнаваемости, знакомства, частоты взаимодействия и удержания на целевом рынке для повышения видимости и вовлеченности бренда',
  boarding_45: 'Идентичность и позиционирование бренда',
  boarding_46:
    'Идентификация и позиционирование бренда - это комплексный анализ, который выявляет потребительское восприятие, различные взгляды аудитории и представления о том, как воспринимается ваш бренд',
  boarding_47: 'Профилирование и сегментация потребителей',
  boarding_48:
    'Профилирование и сегментирование клиентов подразумевает разделение целевого рынка на отдельные группы на основе общих характеристик, что позволяет создавать персонализированные стратегии, которые резонируют с конкретными сегментами аудитории',
  boarding_49: 'Карта путешествия потребителя',
  boarding_50:
    'Карта путешествия потребителя - это визуальное представление этапов, точек соприкосновения, эмоций и мотиваций, которые испытывает потребитель при взаимодействии с вашим продуктом или брендом',
  boarding_51:
    'Узнайте об инновационных методах, которые лежат в основе нашего стремления предоставлять практические знания и добиваться впечатляющих результатов для вашего бизнеса',
  boarding_52:
    'Ознакомьтесь с нашим набором продуктов, содержащих множество данных, учитывающих специфику вашей отрасли, что позволит вам принимать взвешенные решения и быть на шаг впереди',
  boarding_53:
    'Погрузитесь вглубь рыночных тенденций, поведения потребителей и конкурентной среды с помощью наших комплексных решений для проведения маркетинговых исследований',
  boarding_54: 'Специальные исследования',
  boarding_55:
    'Специальные исследования - это индивидуальный подход, разработанный для решения конкретного вопроса, с использованием сочетания существующих методик или нового подхода для создания индивидуального решения.',
  boarding_56: 'Социологические исследования',
  boarding_57:
    'Социологические исследования раскрывают закономерности человеческого поведения и социальных взаимодействий, предоставляя ценные сведения для разработки инновационных стратегий и создания значимых связей с целевой аудиторией.',
  not_found_1: 'Упс! Мы не можем найти эту страницу.',
  not_found_2: 'Почему бы не посмотреть наши товары?',
  not_found_3: 'Заказать консультацию',
  customer_profiling_segmentation_1: 'Профилирование и сегментация клиентов',
  customer_profiling_segmentation_2:
    'Мы поможем вам определить вашу целевую аудиторию, что сделает вашу стратегию более эффективной',
  customer_profiling_segmentation_3:
    'Используйте возможности сегментации рынка, чтобы гарантировать, что ваши сообщения всегда эффективно достигают целевых групп потребителей',
  customer_profiling_segmentation_4: 'Понимайте потребителей глубже',
  customer_profiling_segmentation_5:
    'Найдите лучшую целевую аудиторию для вашего продукта',
  customer_profiling_segmentation_6:
    'Исследуйте новые сегменты для привлечения',
  customer_profiling_segmentation_7: 'Выявляйте барьеры для покупки',
  customer_profiling_segmentation_8:
    'Мы специализируемся на предоставлении индивидуальных решений для сегментации',
  customer_profiling_segmentation_9: 'Уникальный подход',
  customer_profiling_segmentation_10:
    'Попробуйте уникальное решение для сегментации, созданное специально для ваших потребностей',
  customer_profiling_segmentation_11: 'Целевые Персоны',
  customer_profiling_segmentation_12:
    'Созданные целевые персоны на основе глубокого анализа рынка и всесторонних исследований',
  customer_profiling_segmentation_13: 'Новые сегменты',
  customer_profiling_segmentation_14:
    'Определите самые ценные сегменты аудитории и воспользуйтесь преимуществами персонализированного таргетинга',
  customer_profiling_segmentation_15: 'Рекомендации',
  customer_profiling_segmentation_16:
    'Внедряйте наши рекомендации, чтобы добиться успеха, повысить лояльность клиентов, увеличить продажи и максимизировать свой бизнес-потенциал',
  customer_profiling_segmentation_17:
    'Эксперты с более чем 20-летним опытом работы проводят комплексное исследование',
  customer_profiling_segmentation_18:
    'Качественное исследование предусматривает сбор и анализ нечисловых данных для понимания концепций, мнений или опыта.',
  customer_profiling_segmentation_19:
    'Количественные методы исследования предусматривают объективное измерение и числовой анализ данных, полученных с помощью опросов, анкетирования и исследований.',
  customer_profiling_segmentation_20:
    'После сбора данных эксперты анализируют информацию и делают выводы, чтобы предоставить вам лучшие рекомендации.',
  customer_profiling_segmentation_21:
    'Мы не просто проводим измерения, мы предоставляем вам план действий с четкими пошаговыми действиями',
  customer_profiling_segmentation_22: 'Наша методология',
  customer_profiling_segmentation_23: 'Индекс Жаккарда',
  customer_profiling_segmentation_24:
    'Когда респондентов спрашивают напрямую, их ответы о предпочтениях компании могут не совпадать с их фактическим поведением. Вместо прямого опроса мы используем статистический анализ Жаккарда, чтобы понять взаимосвязь между атрибутами компании и потребительским выбором.',
  customer_profiling_segmentation_25: 'Драйверы',
  customer_profiling_segmentation_26:
    'Мы определяем драйверы (факторы, влияющие на определенный результат) и классифицируем их на ключевые, средние и не важные, исходя из их значимости.',
  customer_profiling_segmentation_27: 'Сегментация',
  customer_profiling_segmentation_28:
    'Вы получаете сегментацию с четкими характеристиками выбора клиентов.',
  customer_profiling_segmentation_29: 'Наш подход к сегментации',
  customer_profiling_segmentation_30: 'Демографический',
  customer_profiling_segmentation_31: 'возраст',
  customer_profiling_segmentation_32: 'пол',
  customer_profiling_segmentation_33: 'доход',
  customer_profiling_segmentation_34:
    'Это помогает адаптировать продукты, услуги и маркетинг к конкретным группам, повышая их релевантность и эффективность',
  customer_profiling_segmentation_35: 'Психографический',
  customer_profiling_segmentation_36: 'стиль жизни',
  customer_profiling_segmentation_37: 'ценности',
  customer_profiling_segmentation_38: 'поведение',
  customer_profiling_segmentation_39:
    'Обеспечивает персонализированный маркетинг, более глубокие связи с клиентами, повышает лояльность и продажи для компании',
  customer_profiling_segmentation_40: 'Поведенческий',
  customer_profiling_segmentation_41: 'действия',
  customer_profiling_segmentation_42: 'мотивация',
  customer_profiling_segmentation_43: 'частота покупок',
  customer_profiling_segmentation_44:
    'Позволяет осуществлять таргетированный маркетинг, повышать уровень удовлетворенности клиентов, увеличивать их лояльность и прибыльность для компании',
  customer_profiling_segmentation_45: 'Географическое',
  customer_profiling_segmentation_46: 'расположение',
  customer_profiling_segmentation_47:
    ' Обеспечивает целевой маркетинг, адаптированные продукты, эффективную дистрибуцию и увеличение проникновения на рынок для компании.',
  brands_identity_1: 'Идентичность и позиционирование бренд',
  brands_identity_2:
    'Преобразуйте свой бренд с помощью нашего глубокого анализа, выявляющего восприятие потребителей и уточняющего вашу стратегию для достижения успеха',
  brands_identity_3: 'Поговорите с экспертом',
  brands_identity_4:
    '<b>Откройте для себя всеобъемлющий</b> анализ идентичности бренда',
  brands_identity_5:
    '<b>Изучите</b> собранные сведения о восприятии потребителей',
  brands_identity_6: '<b>Понимание разнообразия</b> мнения целевой аудитории',
  brands_identity_7:
    '<b>Получите представление о</b> поведении, привычках и предпочтениях потребителей для принятия обоснованных решений',
  brands_identity_8:
    'Эксперты 4Service с более чем 20-летним опытом работы с клиентами создают уникальные решения, которые помогают развивать ваш бизнес',
  brands_identity_9:
    '<b>Вы получите доступ к пакету исследований, содержащему все необходимые данные.</b> Он служит диагностическим инструментом, выделяя сильные стороны и области, которые могут потребовать внимания',
  brands_identity_10:
    '<b>Наши услуги предоставляют предприятиям необходимые инструменты</b> для принятия обоснованных решений, совершенствования стратегий и достижения успеха в соответствующих отраслях.',
  brands_identity_11: 'Повысить авторитет вашего бренда на конкурентном рынке',
  brands_identity_12:
    'Предприятия, стремящиеся расширить свое присутствие на рынке',
  brands_identity_13:
    'Предприятия, стремящиеся к повышению эффективности своей стратегии',
  brands_identity_14: 'Фирменный стиль',
  brands_identity_15: 'Позиционирование бренда',
  brands_identity_16:
    '<b>Узнайте о восприятии вашего бренда</b> в разных сегментах потребителей.',
  brands_identity_17:
    '<b>Определите качества, приписываемые</b> к вашему бренду и конкурентам.',
  brands_identity_18:
    '<b>Оцените сильные</b> и слабые стороны для стратегического совершенствования.',
  brands_identity_19:
    '<b>Оцените соответствие имиджа</b> вашего бренда продвигаемым ценностям.',
  brands_identity_20:
    '<b>Оцените воспринимаемую достоверность</b> вашего бренда.',
  brands_identity_21:
    '<b>Исследуйте архетипы</b> и символическое восприятие вашего бренда.',
  brands_identity_22:
    '<b>Определите уникальные преимущества</b> и отличительные черты вашего бренда по сравнению с конкурентами.',
  brands_identity_23:
    '<b>Определите ключевые атрибуты, влияющие на</b> вероятность покупки потребителями ваших товаров/услуг.',
  brands_identity_24:
    '<b>Определите, как ваш бренд позиционируется</b> в сознании потребителей, и выявите аспекты, которые необходимо донести до них для привлечения желаемых сегментов рынка.',
  brands_identity_25:
    'Методология подбирается индивидуально, исходя из запроса клиента',
  brands_identity_26: 'Осведомленность о бренде и его использование',
  brands_identity_27:
    'Оценка уровня бренда предполагает измерение уровней осведомленности и потребления, которые являются основными компонентами оценки.',
  brands_identity_28: 'Продвижение бренда и доверие к нему',
  brands_identity_29:
    'Этот показатель измеряет клиентскую поддержку (рекомендацию) и постоянную способность бренда выполнять обещания, создавать положительный клиентский опыт и строить доверие.',
  brands_identity_30: 'Эффективность имиджа бренда',
  brands_identity_31:
    'Оценка эффективности бренда включает оценку его ассоциаций с шестью ключевыми ценностями (3 рациональными и 3 эмоциональными), его отличия в пределах категории и способности вызывать гордость у потребителей.',
  brands_identity_32: 'Индекс здоровья бренда',
  brands_identity_33:
    'Индекс здоровья бренда дает вам полное представление о том, как ваш бренд работает в конкурентной среде.',
  sociological_research_1: 'Социологические исследования',
  sociological_research_2:
    'Мы проводим глубокий анализ общественных явлений и процессов, который помогает принимать осознанные решения с помощью уникальных аналитических выводов и рекомендаций',
  sociological_research_3: 'Связаться с нами',
  sociological_research_4: 'Наши клиенты',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI - это метод сбора данных, в котором используется компьютер для проведения опросов по телефону. Программное обеспечение CATI позволяет автоматизировать процессы сбора данных, введения ответов и управления опросом. Использование CATI облегчает контроль над опросом, позволяет быстро анализировать и обрабатывать полученные данные, а также обеспечивает высокий уровень стандартизации опроса.',
  sociological_research_7: 'СATI',
  sociological_research_8:
    'CAWI - это метод сбора данных, в котором респонденты заполняют опросные анкеты или отвечают на вопросы через веб-интерфейс, а данные автоматически собираются и обрабатываются с помощью специализированного программного обеспечения. Метод CAWI позволяет проводить опросы больших масштабов, быстро собирать и анализировать данные.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI - это метод сбора данных для опроса лицом к лицу, в котором исследователь использует планшет или мобильный телефон для ввода ответов респондентов на вопросы, что позволяет автоматизировать сбор данных, снижая ошибки ввода и упрощая анализ результатов.',
  sociological_research_11: 'Наши эксперты используют различные инструменты',
  sociological_research_12: 'Выборка',
  sociological_research_13:
    'Мы рассчитываем и применяем выборки любой сложности',
  sociological_research_14:
    'В работе мы используем простые, систематические, стратифицированные и кластерные выборки',
  sociological_research_15:
    'Для национальных репрезентативных исследований face-to-face мы применяем процедуры стратифицированного многоступенчатого отбора',
  sociological_research_16:
    'Отбор населенных пунктов по регионам осуществляется в соответствии с размером и типом населенного пункта, их количество определяется пропорционально их объему в разрезе каждой области',
  sociological_research_17: 'Мы проводим',
  sociological_research_18: 'Исследование общественного мнения',
  sociological_research_19: 'Социально-экономические исследования',
  sociological_research_20: 'Кабинетное исследование',
  sociological_research_21: 'Оценка качества социальных услуг',
  sociological_research_22: 'Изучение отдельного случая',
  sociological_research_23: 'Исследование персонала',
  sociological_research_24:
    'Исследование специфических аудиторий (уязвимые группы, в т.ч. ВПЛ, военные, молодежь и т.д.)',
  sociological_research_25: 'Мы работаем с каждым клиентом индивидуально',
  sociological_research_26: 'Наши клиенты:',
  sociological_research_27: 'Благотворительные Фонды',
  sociological_research_28: 'Общественные организации и союзы',
  sociological_research_29: 'Государственные структуры',
  sociological_research_30: 'Наш подход к работе',
  sociological_research_31:
    'Мы используем научный подход в работе и глубокое понимание текущей реальности, а также различные методы исследования для сбора данных.',
  sociological_research_32:
    'Мы привлекаем узкопрофильных экспертов с нужным опытом для решения конкретного запроса и имеем пул с экспертами по разным направлениям.',
  sociological_research_33:
    'Наш собственный аналитический отдел анализирует собранные данные, используя уникальный набор инструментов в зависимости от специфики задачи.',
  sociological_research_34:
    'Мы генерируем отчеты с подробными выводами и рекомендациями, учитывая потребности и ожидания вашей целевой аудитории.',
  sociological_research_35:
    'Мы предоставляем консультации и поддержку при внедрении рекомендаций, помогая вам осуществить положительные изменения.',
  sociological_research_36: 'Наши исследования на ТВ',
  sociological_research_37: 'Ознакомьтесь с нашими отчетами',
  sociological_research_38:
    'Наш научный подход в работе гарантирует нашим заказчикам точные и достоверные социологические данные по всей Украине и Европе с минимальной погрешностью',
  sociological_research_39:
    'Мы работаем с соблюдением всех принципов и стандартов этики и правил проведения социологических исследований.',
  sociological_research_40:
    'Мы придерживаемся кодекса профессиональной этики социолога Социологической Ассоциации Украины и Хельсинкской декларации этических принципов для проведения исследований. Члены ESOMAR, Украинской Ассоциации Маркетинга, Всеукраинской Ассоциации Контакт-Центров Украины.',
  sociological_research_41: 'Поле',
  sociological_research_42: 'онлайн-панель',
  sociological_research_43: 'постоянных интервьюеров',
  sociological_research_44: 'супервайзеров',
  sociological_research_45: 'Контроль качества поля',
  sociological_research_46: '100% фиксация GPS координат (CAPI)',
  sociological_research_47: 'Валидация анкет (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: '100% записи интервью (CAPI, CATI)',
  sociological_research_49:
    'Мы используем лицензированное программное обеспечение:',
  sociological_research_50:
    'SPSS для анализа статистических данных, обработка массивов данных',
  sociological_research_51:
    'DigSee Sure для CAPI: логика анкеты, геолокация, аудиозапись интервью, демонстрация видео-материалов',
  sociological_research_52:
    'SurveyMonkey для CAWI: логика анкеты, удобный формат, визуализация',
  sociological_research_53:
    'VoIPTime (контакт-центр): программирование анкет любой сложности, передача аудиозаписи заказчику',
  sociological_research_54: 'Выборка',
  sociological_research_55:
    'Мы рассчитываем и применяем выборки любой сложности',
  sociological_research_56:
    'В работе мы используем простые, систематические, стратифицированные и кластерные выборки',
  sociological_research_57:
    'Для национальных репрезентативных исследований face-to-face мы применяем процедуры стратифицированного многоступенчатого отбора',
  sociological_research_58:
    'Отбор населенных пунктов по регионам осуществляется в соответствии с размером и типом населенного пункта, их количество определяется пропорционально их объему в разрезе каждой области',
  sociological_research_59: 'Команда',
  sociological_research_60:
    'Наши социологи входят в Ассоциацию Социологов Украины',
  sociological_research_61:
    'Мы имеем 12 штатных аналитиков с опытом выполнения как количественных, так и качественных проектов в различных отраслях.',
  sociological_research_62:
    'Наша мощная аналитическая команда профессиональных специалистов с опытом исследовательской работы более 10 лет. Мы имеем в штате ученых, кандидатов наук, MBI.',
  sociological_research_63: 'Ирина Максимюк',
  sociological_research_64:
    'Руководитель Департамента исследований. Более 15 лет опыта в маркетинговых исследованиях, управление исследованиями на всех стадиях, подготовку аналитических отчетов, проведение презентаций',
  sociological_research_65: 'Андрей Билоскурский',
  sociological_research_66:
    'Руководитель отдела программинга и обработки данных. 20 лет опыта в маркетинговых, социологических и маркетинговых исследованиях',
  sociological_research_67: 'Елена Сомова',
  sociological_research_68:
    'Специалист по програмингу Аспирант по социологии в ДонГУУ. 5 лет работы в маркетинговых исследованиях Член правления Демократического Альянса Женщин, руководитель отдела фандрайзинга.',
  sociological_research_69: 'Анна Падалка',
  sociological_research_70:
    'Руководитель отдела социологических исследований департамента исследований, имеет 13 летний опыт работы с социологическими и социальными проектами международного и национального уровня.  Кандидат социологических наук, Руководитель Исследовательского Комитета Всеукраинской ассоциации контакт-центров Украины.',
  ad_hoc_1: 'Специальные исследования',
  ad_hoc_2:
    'Откройте для себя наши альтернативные исследования для уникальных случаев',
  ad_hoc_3: 'Свяжитесь с нами',
  ad_hoc_4: 'Наш подход',
  ad_hoc_5:
    'Наша команда экспертов тщательно анализирует собранные данные, предлагая вам комплексные решения, способствующие вашему росту.',
  ad_hoc_6:
    'Мы разрабатываем уникальное исследование с учетом ваших конкретных требований, используя разнообразные методики, включая опросы, интервью, фокус-группы, анализ данных и многое другое.',
  ad_hoc_7: 'Почему стоит выбрать специальное исследование?',
  ad_hoc_8: 'Гибкость',
  ad_hoc_9:
    'Нет двух одинаковых исследовательских проектов. Специальные исследования мы можем гибко адаптировать и развивать уникальный подход по мере необходимости, гарантируя, что ваши выводы останутся актуальными и действенными.',
  ad_hoc_10: 'Точность',
  ad_hoc_11:
    'Приспосабливая наши методики к вашим уникальным требованиям, мы получаем точные и целенаправленные результаты, которые позволяют получить ценные сведения.',
  ad_hoc_12: 'Инновации',
  ad_hoc_13:
    'Мы используем инновации и творческий подход в процессе исследований, изучая новые методы и подходы для выявления скрытых возможностей и проблем.',
  ad_hoc_14: 'Своевременность',
  ad_hoc_15:
    'Специальные исследования позволяют быстро внедрять их, обеспечивая своевременный доступ к важнейшим сведениям, что позволяет опережать события в вашей отрасли.',
  ad_hoc_16: 'Наша экспертиза',
  ad_hoc_17:
    'Эксперты 4Service с более чем 20-летним опытом изучения рынка и работы с клиентами создают уникальные решения, которые помогают развивать ваш бизнес',
  ad_hoc_18:
    'Мы используем отдельный набор инструментов в соответствии с вашими конкретными требованиями',
  ad_hoc_19: 'Ни одна методика не работает на вас?',
  ad_hoc_20: 'Запрос на специальное исследование',
  ad_hoc_21: 'Индивидуальный подход к решению конкретной проблемы',
  ad_hoc_22:
    'Мы используем как сочетание различных частей методологий, так и совершенно новый подход',
  ad_hoc_23: 'Индивидуальный подход',
  ad_hoc_24: 'Мы тщательно подбираем решение непосредственно под вашу проблему',
  ad_hoc_25: 'Выше всяких похвал',
  ad_hoc_26:
    'Мы разработаем план действий с рекомендациями, которые помогут вашему бизнесу достичь поставленных целей',
  ad_hoc_27: 'У вас нестандартная проблема',
  ad_hoc_28: 'Ни одна из методик не подходит для решения вашей проблемы',
  ad_hoc_29: 'Вы хотите запросить сразу несколько методик или их частей',
  ad_hoc_30: 'Кому полезны специальные исследования?',
  career: 'Карьера',
};
