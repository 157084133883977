import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';
import bg from '../../../assets/images/customerProfilingSegmentation/banner.png';
import { CustomerProfilingSegmentationIntroStyles } from './styles';

export const CustomerProfilingSegmentationIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <CustomerProfilingSegmentationIntroStyles>
      <Banner
        bgColor="linear-gradient(262.39deg, rgba(250, 61, 194, 0.58) 26.88%, rgba(174, 25, 164, 0.87) 91.69%)"
        title={getTranslationsByLangOrEng(
          interfaceLanguage,
          'customer_profiling_segmentation_1',
        )}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'customer_profiling_segmentation_2',
        )}
        buttonKey="usage_attitude_11"
        image={bg}
      />
    </CustomerProfilingSegmentationIntroStyles>
  );
};
