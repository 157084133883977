import styled from 'styled-components';

export const OpportunitiesStyles = styled.div`
  font-family: 'Montserrat';
  position: relative;
  overflow: hidden;
  background: #fff;

  .container {
    max-width: 1190px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;
  }
`;
