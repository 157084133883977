import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { sliderData } from './utils';
import { SwiperSlide } from 'swiper/react';
import { SociologicalResearchSliderStyles } from './styles';

export const SociologicalResearchSlider = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchSliderStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_11')}</h1>
        </div>
      </div>
      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          // centeredSlides
          loop
          buttonBorderColor="#F05A25"
          buttonsIconsColor="#000"
        >
          {sliderData(interfaceLanguage).map((item, index) => (
            <SwiperSlide className="slide" key={item.name}>
              <div className="slide-img">
                <img src={item.img} alt={item.name} />
              </div>
              <div className="slide-text">
                <h5>{item.name}</h5>
                <p>{item.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </SociologicalResearchSliderStyles>
  );
};
