import React from 'react';
import {
  Automotive,
  Other,
  Retail,
  Tobacco,
} from '../../../assets/icons/cxSolution';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

type TTab = {
  name: string;
  key: string;
  icon: React.ElementType;
};

export const automotive = (lang: string) => [
  getTranslationsByLangOrEng(lang, 'cx_solution_11'),
  getTranslationsByLangOrEng(lang, 'cx_solution_103'),
  getTranslationsByLangOrEng(lang, 'cx_solution_104'),
  getTranslationsByLangOrEng(lang, 'cx_solution_105'),
];
export const tobacco = (lang: string) => [
  getTranslationsByLangOrEng(lang, 'cx_solution_106'),
  getTranslationsByLangOrEng(lang, 'cx_solution_107'),
  getTranslationsByLangOrEng(lang, 'cx_solution_108'),
  getTranslationsByLangOrEng(lang, 'cx_solution_109'),
  getTranslationsByLangOrEng(lang, 'cx_solution_110'),
];
export const retail = (lang: string) => [
  getTranslationsByLangOrEng(lang, 'cx_solution_111'),
  getTranslationsByLangOrEng(lang, 'cx_solution_112'),
  getTranslationsByLangOrEng(lang, 'cx_solution_113'),
  getTranslationsByLangOrEng(lang, 'cx_solution_114'),
  getTranslationsByLangOrEng(lang, 'cx_solution_115'),
];

export const tabs: (lang: string) => TTab[] = (lang) => [
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_100'),
    key: 'automotive',
    icon: Automotive,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_101'),
    key: 'tobacco',
    icon: Tobacco,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_102'),
    key: 'retail',
    icon: Retail,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'cx_solution_69'),
    key: 'other',
    icon: Other,
  },
];
