import React from 'react';

function WhatsappIcon({ width = 25, height = 25, color = '#000' }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.4987 14.3653C18.45 14.3419 16.6274 13.4444 16.3035 13.3278C16.1713 13.2804 16.0297 13.234 15.879 13.234C15.6329 13.234 15.4262 13.3567 15.2651 13.5976C15.0831 13.8682 14.5319 14.5124 14.3616 14.7049C14.3394 14.7303 14.309 14.7606 14.2909 14.7606C14.2745 14.7606 13.9925 14.6445 13.9071 14.6074C11.952 13.7581 10.468 11.7158 10.2645 11.3714C10.2354 11.3219 10.2342 11.2994 10.2339 11.2994C10.2411 11.2732 10.3068 11.2073 10.3408 11.1733C10.44 11.0751 10.5476 10.9456 10.6516 10.8204C10.7009 10.761 10.7503 10.7016 10.7987 10.6456C10.9497 10.47 11.0169 10.3336 11.0948 10.1756L11.1357 10.0936C11.3259 9.71558 11.1634 9.39659 11.1109 9.29358C11.0678 9.20738 10.2982 7.35007 10.2164 7.15494C10.0197 6.68408 9.75967 6.46484 9.39839 6.46484C9.36487 6.46484 9.39839 6.46484 9.25781 6.47077C9.08662 6.47799 8.15439 6.60072 7.74222 6.86054C7.30512 7.13611 6.56567 8.01452 6.56567 9.55933C6.56567 10.9497 7.44798 12.2624 7.8268 12.7617C7.83621 12.7743 7.8535 12.7998 7.87858 12.8365C9.32932 14.9552 11.1378 16.5253 12.9712 17.2577C14.7362 17.9628 15.572 18.0442 16.0472 18.0442H16.0473C16.2469 18.0442 16.4068 18.0286 16.5478 18.0147L16.6372 18.0062C17.2469 17.9521 18.5869 17.2578 18.8917 16.4108C19.1318 15.7437 19.1951 15.0148 19.0353 14.7502C18.9259 14.5704 18.7374 14.4799 18.4987 14.3653Z" fill="white" />
      <path d="M12.7219 0C5.95113 0 0.442677 5.46706 0.442677 12.187C0.442677 14.3604 1.02433 16.4879 2.1262 18.35L0.017189 24.5712C-0.0220968 24.6871 0.00712402 24.8154 0.0929196 24.9027C0.154852 24.966 0.238862 25.0001 0.324657 25.0001C0.357531 25.0001 0.390648 24.9951 0.422953 24.9848L6.90998 22.9234C8.68514 23.8719 10.6915 24.3725 12.722 24.3725C19.4921 24.3726 25 18.9061 25 12.187C25 5.46706 19.4921 0 12.7219 0ZM12.7219 21.834C10.8113 21.834 8.96071 21.2823 7.36996 20.2384C7.31647 20.2033 7.25438 20.1853 7.19188 20.1853C7.15884 20.1853 7.12573 20.1903 7.0935 20.2005L3.8439 21.2335L4.89293 18.1386C4.92686 18.0384 4.90989 17.928 4.84739 17.8426C3.63603 16.1874 2.99569 14.2318 2.99569 12.187C2.99569 6.8669 7.35884 2.53864 12.7218 2.53864C18.0842 2.53864 22.4469 6.8669 22.4469 12.187C22.4469 17.5064 18.0844 21.834 12.7219 21.834Z" fill="white" />
    </svg>
  );
}

export default WhatsappIcon;
