import styled from 'styled-components';

export const Prefooter4OpinionStyles = styled.div`
  background: linear-gradient(180deg, #0000FF -76.27%, #000000 100%);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 70px 10px;
    z-index: 5;

    @media (max-width: 768px) {
      gap: 30px;
      padding: 30px 10px;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    text-align: center;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
`;
