export const es: { [key: string]: string } = {
  subscribe_with_click: 'Suscríbase con un clic',
  subscribe: 'Suscríbase con un clic',
  minimum_number_characters: 'número mínimo de 2 caracteres',
  request_details: 'Solicitar información',
  form_title: 'Nos pondremos en contacto con usted en breve',
  form_required: 'Los campos marcados con * son obligatorios',
  form_name_placeholder: 'Nombre',
  form_email_placeholder: 'Correo electrónico',
  form_phone_placeholder: 'Número de teléfono',
  form_company_placeholder: 'Empresa',
  form_submit: 'Enviar',
  form_1:
    'Doy mi consentimiento para el tratamiento de datos personales de acuerdo con la',
  form_2: 'Política de privacidad',
  validation_privacy_policy: 'Acepte la política de privacidad',
  validation_phone: 'El número de teléfono no es válido',
  validation_email: 'Formato de correo electrónico no válido',
  validation_required: 'Los campos obligatorios no están rellenados',
  submit_error: 'Se ha producido un error al guardar el formulario',
  footer_1: 'Nuestros socios:',
  footer_2: 'Métodos',
  footer_3: 'Productos',
  footer_4: 'Contactos:',
  footer_5: 'Impresión',
  footer_6: 'Política de Privacidad',
  footer_7: 'Investigación de Mercado',
  about_us_1: 'Responsabilidad Social Corporativa de 4Service',
  about_us_2:
    'Desde el corazón y el alma de cada miembro de nuestro equipo. Hacemos todo lo posible para mantener una chispa de amabilidad en cada uno de nuestros empleados y hacer que el mundo que nos rodea sea un poco mejor.',
  about_us_3: 'Responsabilidad Social Corporativa de 4Service',
  about_us_4:
    'Desde el corazón y el alma de cada miembro de nuestro equipo. Hacemos todo lo posible para mantener una chispa de amabilidad en cada uno de nuestros empleados y hacer que el mundo que nos rodea sea un poco mejor.',
  about_us_5: 'Juego de negocios Service Mania',
  about_us_6: 'Nuestras iniciativas de alcance para niños y estudiantes',
  about_us_7: 'Caridad / Voluntariado',
  about_us_8:
    'Orfanatos, hogares de cuidado para personas mayores y voluntariado para el ejército ucraniano. La parte más conmovedora de nuestras actividades.',
  about_us_9: 'Bienestar animal',
  about_us_10:
    'Cualquiera puede entrar en nuestra oficina con una mascota. Ayudamos a refugios, recaudamos fondos para alimentos y a veces también tratamos de encontrar hogar para animales sin hogar.',
  about_us_11: 'Grupo 4Service',
  about_us_12:
    'Es una empresa de investigación internacional que gestiona la experiencia del cliente.',
  about_us_13:
    'Implementamos proyectos complejos para aumentar las ventas y desarrollar negocios orientados al cliente.',
  about_us_14:
    'Es un holding de investigación internacional con sede en Viena, Austria. La empresa fue establecida en 2001 con el objetivo de mejorar la calidad de vida de sus clientes y de las personas que interactúan con el negocio, utilizan sus servicios y consumen sus productos. Después de todo, por eso se eligió el nombre “Forservice” para una empresa que representa servicio al cliente y buena calidad.',
  about_us_15:
    'Este fue el inicio de la historia de 4Service en Kyiv, Ucrania, como el proveedor líder de servicios de compras misteriosas. Ha continuado operando con éxito como empresa de investigación en 52 países con nuevas oportunidades en un enfoque holístico para analizar y mejorar toda la cadena de experiencia del cliente.',
  about_us_16: 'nacido en el nombre del servicio',
  about_us_17: 'primera oficina ubicada fuera de Ucrania',
  about_us_18: 'reubicación de la sede a Viena',
  about_us_19: 'miembro élite de MSPA, agencia Top-10 en Europa',
  about_us_20: 'establecimiento de oficinas en 9 países',
  about_us_21: 'primeros proyectos digitales, Voicer',
  about_us_22: 'socios de DesignThinkers Academy',
  about_us_23: 'primeros proyectos globales de CX',
  about_us_24: 'Misión',
  about_us_25:
    'Nuestra misión es mejorar el servicio al cliente en todo el mundo. Nuestra inspiración son los cambios cualitativos que vemos en las personas y empresas con las que trabajamos.',
  about_us_26: 'Más de 20 años de experiencia en Customer Experience',
  about_us_27: '95 países',
  about_us_28:
    'Reunimos y compartimos la mejor experiencia de la industria de todo el mundo',
  about_us_29: 'Oficina central en Viena',
  about_us_30:
    'países. Reunimos y compartimos la mejor experiencia de la industria de todo el mundo',
  about_us_31: 'calificaciones de calidad del servicio',
  about_us_32: 'estamos en el top-10 de agencias de CX en Europa',
  about_us_33: 'oficinas representativas / sede en Austria',
  about_us_34: 'proyectos activos',
  about_us_35: 'Tu futuro lugar de trabajo',
  about_us_36: 'Nuestras oficinas',
  about_us_37:
    '4Service ™ se esfuerza por crear un espacio de trabajo cómodo para sus empleados. Nuestros empleados trabajan en el sitio, de forma remota o híbrida, para la mejor conveniencia y productividad de cada empleado. Habitaciones para relajación, apoyo psicológico gratuito y un ambiente acogedor. Somos amigables con las personas y las mascotas.',
  about_us_38: 'Equipo que ilumina',
  about_us_39:
    'Nuestro equipo multinacional está comprometido a escuchar, recibir comentarios de nuestros clientes y crecer mutuamente. El mapa del viaje del cliente de nuestros clientes es tan importante para nuestro negocio como el lanzamiento de nuevos productos o alcanzar nuestras metas financieras. Nuestras actividades están destinadas a tener un impacto positivo en las comunidades que nos rodean y a ofrecer soluciones innovadoras y centradas en las personas para nuestros clientes.',
  about_us_40: 'Todo',
  about_us_41: 'Gestión',
  about_us_42: 'Equipo Global',
  about_us_43: 'Propietarios de productos',
  about_us_44: 'Nuestros socios',
  about_us_45:
    'Implementamos proyectos complejos para aumentar las ventas y desarrollar los negocios de empresas orientadas al cliente.',
  about_us_46:
    'es uno de los principales Institutos de Capacitación Profesional “impulsados por el diseño”, representados en 25 países y entrenan, desarrollan y facilitan equipos y comunidades multidisciplinarios creativos para impulsar un cambio positivo. Design Thinking le permite desarrollar y ofrecer ideas innovadoras, cambios y soluciones a problemas complicados.',
  about_us_47:
    'Su misión es proporcionar espacio y tiempo para que los equipos e individuos se sientan inspirados y desafiados, para “aprender haciendo” y fortalecer su capacidad para la resolución creativa de problemas y ayudar a las organizaciones a construir el entorno y la mentalidad necesarios para iniciar una innovación audaz.',
  about_us_48:
    'es la Asociación Comercial representante de las empresas involucradas en la industria de las Compras Misteriosas. La Asociación opera de manera regional en todo el mundo, con capítulos ubicados en América, Europa / África y Asia / Pacífico. Las empresas miembros se unen como un cuerpo común con el propósito de fortalecer la industria de las Compras Misteriosas a través de esfuerzos y acciones combinados.',
  about_us_49:
    'es una organización sin fines de lucro. Los miembros forman una comunidad convencida del potencial de la analítica de datos, la investigación y la información para ayudar a mejorar las sociedades, organizaciones y la vida de todos.',
  about_us_50:
    'ESOMAR tiene su sede en Ámsterdam, Países Bajos. ESOMAR está presente en más de 130 países a través de sus miembros desde 1947.',
  about_us_51:
    '(UCCA) es una comunidad profesional de expertos y empresas privadas unidas con el objetivo de desarrollo e interacciones en el área de centros de contacto y servicio al cliente. La Filosofía de la UCCA es elevar la cultura de la industria de centros de contacto, basada en tecnologías modernas y altos estándares.',
  about_us_52: 'La Asociación de Contact Center de Ucrania fue fundada en 2010',
  about_us_53:
    'estudia la mejor experiencia mundial en el campo de la educación para adaptarla y utilizarla en el mercado ucraniano. Por eso, la Academia DTEK coopera con las principales escuelas de negocios internacionales: INSEAD, IE Business School y la Universidad de Stanford.',
  about_us_54:
    'La Academia DTEK se centra en el desarrollo de una cultura orientada al cliente e innovadora en D.Client School, ID School, Educación Ejecutiva: Energía de Innovación, Poder de las Comunicaciones, programa digital DX School, Líder Eficaz: Pre-MBA, Energía de Cambio.',
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Gerente de Ventas Internacionales',
  bht_1: 'Salud de Marca',
  bht_2: 'Seguimiento',
  bht_3: 'Las marcas son increíblemente poderosas',
  bht_4: 'Descubre qué tan bien está desempeñándose tu marca en general',
  bht_5: 'Acerca de',
  bht_6: '¿Qué es el Seguimiento de Salud de Marca?',
  bht_7:
    'Este es un tipo de investigación de mercado que se lleva a cabo con cierta regularidad (cada dos semanas, cada mes, etc.) para obtener información sobre el',
  bht_8:
    'estado de una marca en el mercado, basado en indicadores como prominencia, demanda, imagen, las características de sus consumidores, salud de marca, etc.',
  bht_9: 'Comprar la marca más a menudo',
  bht_10: 'Leales',
  bht_11: 'Compró la marca durante 3 meses',
  bht_12: 'Clientes regulares',
  bht_13: 'Compró la marca durante 6 meses',
  bht_14: 'Clientes a corto plazo',
  bht_15: 'Compró la marca durante un año',
  bht_16: 'Clientes a largo plazo',
  bht_17: '¿Conocen la marca? pero nunca la han usado/comprado',
  bht_18: 'Conocedores pero no clientes',
  bht_19: 'Desconocidos: nunca han oído hablar de la marca',
  bht_20: 'Desconocido',
  bht_21: 'Perfil de compradores de la marca',
  bht_22:
    'Evaluar la consistencia de los esfuerzos a largo plazo para construir una marca',
  bht_23: 'Medir el nivel de conocimiento del cliente sobre la marca',
  bht_24:
    'Mostrar la efectividad de las campañas publicitarias y de información',
  bht_25:
    'Determinar qué impacto tiene un período de alta publicidad en las actividades de ventas/marketing de los competidores',
  bht_26:
    'Proporcionar un control constante al propietario del negocio en períodos de cambios continuos en el mercado',
  bht_27: '¿Qué tareas puede resolver el BHT?',
  bht_28: 'Solicitar una investigación',
  bht_29: 'Tarea a resolver',
  bht_30: 'Por ejemplo,',
  bht_31:
    'si una empresa necesita solo una sesión de BHT por trimestre durante la medición de cuotas de mercado, se debe realizar una encuesta de encuestados con más frecuencia al medir el conocimiento de la marca por parte de los clientes a partir de la publicidad.',
  bht_32: 'CATI',
  bht_33: 'Cara a Cara',
  bht_53: 'Entrevistas',
  bht_34: 'CAWI',
  bht_35: 'Tarea a resolver',
  bht_36: 'Al recopilar información',
  bht_37:
    'a través de varios métodos, el seguimiento de marcas generalmente se divide en tres etapas dependiendo del público objetivo y cuán difícil sea alcanzarlos:',
  bht_38:
    'Utilizamos tanto métodos tradicionales como contemporáneos para recopilar datos:',
  bht_39:
    'Trabajamos con dos modelos: el primero con un conjunto simple de indicadores y el segundo se dirige a industrias más complejas, como las de alimentos y servicios. Un modelo más complejo implica un estudio en profundidad que incluye hasta 10 índices estimados.',
  bht_40: 'Ofrecemos',
  bht_41: 'un control de datos iniciales',
  bht_42: 'Cubrimos',
  bht_43: 'todo el país',
  bht_44: 'Proporcionamos una solución',
  bht_45:
    'para mercados no dinámicos/mercados con un número limitado de jugadores (Análisis de Punto de Marca)',
  bht_46: '¿Por qué nosotros?',
  bht_47: '¿Por qué elegirnos?',
  bht_50: 'Resultado pobre',
  bht_51: 'Buen resultado',
  bht_52: 'Resultado promedio',
  cases_1:
    'Desafío: ¿Cómo mejorar la experiencia de compra de vehículos eléctricos para los clientes en los concesionarios Hyundai?',
  cases_2: 'Mapeo del Viaje del Cliente',
  cases_3: 'Ideación',
  cases_4: 'Investigación cualitativa',
  cases_5:
    'Desafío: ¿Qué debería cambiar en las tiendas (tanto en el proceso de servicio, en el interior, en la percepción de la marca) para atraer el interés de los clientes en la marca Oppo?',
  cases_6: 'CJM en línea',
  cases_7: 'Pruebas de UI',
  cases_8: 'Entrevistas',
  cases_9:
    'Desafío: ¿Qué debería cambiar en las tiendas para atraer el interés de los clientes en la marca Oppo?',
  cases_10: 'Parte cualitativa',
  cases_11: 'Ideación',
  cases_12: 'Parte cuantitativa',
  cases_13: 'Mapa del Viaje del Cliente',
  cases_14: 'Casos',
  cases_15: 'Todos',
  cases_16: 'Hagamos juntos tu gran caso',
  csi_1: 'Índice de',
  csi_2: 'satisfacción del cliente',
  csi_3: 'Determinar las razones de la',
  csi_4: 'satisfacción e insatisfacción de los consumidores',
  csi_5: 'Investigación sobre la satisfacción del cliente',
  csi_6:
    'es la determinación de los factores, causas y motivos que influyen en la decisión de compra de un cliente, así como en el comportamiento del público objetivo.',
  csi_7: 'Una encuesta de satisfacción del cliente',
  csi_8:
    'muestra cuán satisfecho o insatisfecho está un cliente con un aspecto particular del servicio o del producto en general.',
  csi_9: 'La encuesta de satisfacción',
  csi_10:
    'se realiza entre los clientes existentes de la empresa y los usuarios de ciertos productos/servicios.',
  csi_11: '¿Qué es el CSI?',
  csi_12:
    'La satisfacción del cliente mide el grado en que se han satisfecho las expectativas del cliente con respecto a la compra de un producto o servicio proporcionado por tu empresa.',
  csi_13: 'Hombre feliz',
  csi_14: 'Acerca de',
  csi_15: 'Identificar cualquier factor oculto',
  csi_16: 'que pueda afectar la satisfacción del cliente',
  csi_17: 'Determinar las razones',
  csi_18:
    'detrás de cualquier aumento/disminución en la demanda de bienes y servicios',
  csi_19: 'Optimizar los',
  csi_20: 'procesos de negocio de la empresa',
  csi_21: 'Adaptarlos',
  csi_22: 'a las necesidades del consumidor moderno',
  csi_23: 'Formular un mensaje publicitario efectivo',
  csi_24: 'y posicionar un producto según la solicitud del cliente',
  csi_25: 'Tareas a resolver',
  csi_26:
    'Qué tareas pueden resolver las encuestas de satisfacción del cliente',
  csi_27: 'Hombre con teléfono',
  csi_28: 'Construimos un modelo matemático',
  csi_29:
    'de factores que pueden influir en la satisfacción del cliente/lealtad del cliente (análisis de regresión)',
  csi_30: 'Optimizar los',
  csi_31: 'procesos de negocio de la empresa',
  csi_32: 'Construimos un índice de satisfacción del cliente calculado',
  csi_33: '(CSI) y lo comparamos con el índice declarativo',
  csi_34: 'Realizamos una investigación',
  csi_35: 'tanto para segmentos B2B como B2C.',
  csi_36: 'Nuestros cuestionarios son desarrollados por un equipo',
  csi_37:
    'de analistas especialmente para los proyectos individuales de nuestros clientes',
  csi_38: '¿Por qué nosotros?',
  csi_39: '¿Por qué elegirnos?',
  csi_40: 'Métodos de recopilación de información:',
  main_1:
    'de nuestros clientes mejoran su rendimiento financiero aprendiendo de la experiencia de sus clientes',
  main_2:
    'Servicios de investigación y consultoría de CX: una vista 360° de su negocio',
  main_3:
    'Descubra nuestra gama de soluciones y herramientas para apoyar su negocio',
  main_4: 'Productos',
  main_5:
    'La investigación cualitativa es la recolección y análisis de datos no numéricos para comprender conceptos, opiniones o experiencias',
  main_6:
    'Los métodos de investigación cuantitativa son mediciones objetivas y análisis numérico de los datos recogidos a través de encuestas, cuestionarios y sondeos',
  main_7:
    'Mystery shopping es una técnica de investigación de mercado para medir y monitorear las interacciones entre un cliente y una empresa u organización durante un escenario predefinido',
  main_8:
    'Sistema de gestión de retroalimentación. La voz de su cliente en un solo sistema: Sistema de Gestión de Retroalimentación del Cliente',
  main_9:
    'Plataforma de inteligencia de contenido impulsada por IA que proporciona información de la audiencia',
  main_10:
    'Software de gestión de rendimiento. Aplicación gamificada para aumentar las ventas',
  main_11:
    'El monitoreo de precios es el proceso de recolección, procesamiento y análisis de los precios de los competidores',
  main_12:
    'El diseño de servicios consiste en comprender las necesidades y deseos de las personas que utilizarán un producto o servicio pasando tiempo con ellas',
  main_13:
    'Nuestro equipo de análisis aplica los enfoques más actualizados y fundamentales que hemos demostrado ser exitosos con muchos clientes',
  main_14: 'Metodologías',
  main_15: 'Mapa de recorrido del cliente',
  main_16:
    'El Customer Journey Mapping (CJM) se trata de definir un público objetivo, comprender sus necesidades y entorno, y luego crear un plan para ofrecer una experiencia atractiva',
  main_17: 'CJM',
  main_18: 'Puntuación Neta del Promotor',
  main_19:
    'La Puntuación Neta del Promotor (NPS) es el estándar de oro de las métricas de experiencia del cliente, basado en una pregunta: ¿Qué tan probable es que recomiende la organización/producto/servicio?',
  main_20: 'NPS',
  main_21: 'Seguimiento de salud de marca',
  main_22:
    'La investigación de seguimiento de la salud de la marca (BHT) puede medir cómo está desempeñándose su marca en términos de conciencia y uso, posicionamiento y rendimiento de la marca',
  main_23: 'BHT',
  main_24: 'Investigación de recursos humanos',
  main_25:
    'La investigación de recursos humanos se utiliza para evaluar las prácticas y el rendimiento de RR.HH. y ofrece un análisis detallado sobre el desarrollo y gestión actual',
  main_26: 'Investigación de RR.HH.',
  main_27: 'Índice de satisfacción del cliente',
  main_28:
    'El Índice de Satisfacción del Cliente (CSI) es una puntuación que indica qué tan satisfechos están sus clientes con los productos/servicios de la empresa.',
  main_29: 'CSI',
  main_30: 'Pruebas de UX',
  main_31:
    'Las pruebas de usabilidad (UX) analizan cómo interactúan las personas reales con un sitio web, aplicación u otro producto, observando su comportamiento y reacciones',
  main_32: '¿Por qué nosotros?',
  main_33:
    'Nuestra misión es ayudar a las empresas a brindar una experiencia de cliente altamente efectiva',
  main_34: 'Enfocándonos en la calidad',
  main_35: 'Estamos controlados por la calidad',
  main_36:
    'cuando se trata de datos de investigación. Ponemos todos los conjuntos de datos a disposición de los clientes',
  main_37: 'Rentabilidad',
  main_38: 'Nuestra experiencia, recursos y procesos comerciales optimizados',
  main_39: 'nos permiten ahorrarle dinero y tiempo',
  main_40: 'Soluciones inteligentes',
  main_41: 'Utilizamos una variedad de técnicas',
  main_42: 'y herramientas para resolver sus problemas particulares',
  main_43: 'Enfocándonos en la calidad',
  main_44: 'Estamos controlados por la calidad',
  main_45:
    'cuando se trata de datos de investigación. Ponemos todos los conjuntos de datos a disposición de los clientes',
  main_46: 'Rentabilidad',
  main_47: 'Nuestra experiencia, recursos y procesos comerciales optimizados',
  main_48: 'nos permiten ahorrarle dinero y tiempo',
  main_49: 'Soluciones inteligentes',
  main_50: 'Utilizamos una variedad de técnicas',
  main_51: 'y herramientas para resolver sus problemas particulares',
  main_52: 'Cash & Carry',
  main_53: 'Electrónica',
  main_54: 'Lujo/Premium',
  main_55: 'Restauración y hospitalidad',
  main_56: 'Retail',
  main_57: 'Automoción',
  main_58: 'Moda',
  main_59: 'Bancos',
  main_60: 'Viajes y ocio',
  main_61: 'Relojes y joyería',
  main_62: 'Hogar y electrodomésticos',
  main_63: 'Blog',
  main_64: 'Vaya al blog para leer más publicaciones interesantes',
  main_65: 'Auditoría de retail',
  main_66:
    'Recopile datos detallados sobre su tienda o marca, analice las barreras de crecimiento y cree estrategias efectivas para superarlas',
  cjm_1: 'Cliente',
  cjm_2: 'Mapa del viaje',
  cjm_3: 'Entra en el mundo de los clientes y comparte su experiencia',
  cjm_4: '¿Qué es el Mapa del Viaje del Cliente?',
  cjm_5:
    'CJM proporciona una historia visualizada de la interacción del consumidor con un producto, servicio, empresa o marca a través de diversos canales y durante un cierto periodo de tiempo.',
  cjm_6:
    'Esto permite a las empresas analizar objetivamente la experiencia de la interacción con el cliente, solucionar y eliminar cualquier barrera que surja y ofrecer recomendaciones para mejorar el producto.',
  cjm_7: 'Determinación del TA, segmentación',
  cjm_8: 'Identificación de las etapas de interacción del cliente',
  cjm_9:
    'Identificación de los principales canales de interacción con el cliente',
  cjm_10: 'Investigación de la experiencia del cliente',
  cjm_11: 'Análisis de barreras e hipótesis sobre optimización',
  cjm_12: 'Ejecución y prueba de hipótesis',
  cjm_13:
    'Diseñar un Mapa del Viaje del Cliente consiste en los siguientes pasos:',
  cjm_14: 'El Mapa del Viaje del Cliente demostrará:',
  cjm_15:
    'CJM proporciona una historia visualizada de la interacción del consumidor con un producto, servicio, empresa o marca a través de diversos canales y durante un cierto periodo de tiempo.',
  cjm_16: 'Aseguramos trabajo en equipo',
  cjm_17: 'entre analistas y diseñadores',
  cjm_18: 'Ofrecemos un enfoque individual',
  cjm_19: 'para cada cliente',
  cjm_20: 'Podemos aumentar la satisfacción general',
  cjm_21:
    'del cliente con el servicio/producto y, en última instancia, aumentar su lealtad hacia la empresa',
  cjm_22: 'Garantizamos la',
  cjm_23:
    'optimización del camino del consumidor y tomamos en cuenta los procesos comerciales de la empresa',
  cjm_24: 'Ofrecemos a nuestros clientes',
  cjm_25:
    'la oportunidad de utilizar enfoques combinados: CATI, CAWI, grupos de enfoque, entrevistas en profundidad, entrevistas con expertos, etnografía, etc.',
  cjm_26: '¿Por qué elegirnos?',
  methods_hr_1: 'Investigación de Recursos Humanos',
  methods_hr_2:
    'Obtén información objetiva sobre tu equipo y descubre cómo mejorar el rendimiento de RRHH',
  methods_hr_3:
    'Algunas razones por las que puedes necesitar investigación de RRHH',
  methods_hr_4: 'del personal entrevistado',
  methods_hr_5: 'proyectos de RRHH implementados',
  methods_hr_6: 'sesiones de formación de personal han sido realizadas',
  methods_hr_7:
    'Principal holding europeo para la experiencia del cliente e investigación de empleados',
  methods_hr_8: 'Investigaciones de RRHH de 4service en números',
  methods_hr_9:
    'Para identificar problemas y debilidades en cada etapa de la vida de los empleados de tu empresa: búsqueda/reclutamiento/integración/desarrollo profesional/recertificaciones',
  methods_hr_10: 'Para saber qué ocasiones merecen aumentos y bonificaciones',
  methods_hr_11:
    'Necesitas predecir o proporcionar soluciones a la insatisfacción de los empleados',
  methods_hr_12:
    'A través de la investigación de RRHH, son posibles nuevos y actuales métodos de evaluación de políticas, programas y prácticas de RRHH',
  methods_hr_13: 'Para mejorar la salud mental de los empleados de la empresa',
  methods_hr_14:
    'Si deseas mejorar la productividad mediante un enfoque orientado a objetivos',
  methods_hr_15:
    'Si tienes un equipo internacional y/o remoto y necesitas gestionarlo',
  methods_hr_16:
    'Para entender cuán atractiva es tu marca de empleador para los empleados potenciales',
  methods_hr_17: 'Satisfacción del Empleado',
  methods_hr_18: 'Satisfacción del Cliente',
  methods_hr_19: 'Optimización de Estrategias de Empresa',
  methods_hr_20: 'Calidad de la Atención al Cliente',
  methods_hr_21: 'Proporción de Retención de Empleados',
  methods_hr_22: 'Investiga en tu personal',
  methods_hr_23:
    'con el fin de aumentar la lealtad y el compromiso hacia tu marca',
  methods_hr_24:
    'Aumentando el compromiso de los empleados, estableces la comunicación correcta y de calidad en la empresa',
  methods_hr_25: 'Resultados de la Investigación de RRHH',
  methods_hr_26: 'Reclutamiento',
  methods_hr_27: 'Desempeño',
  methods_hr_28: 'Efectividad',
  methods_hr_29: 'Estrategia',
  methods_hr_30: 'Optimización',
  methods_hr_31: 'Desarrollo',
  methods_hr_32:
    'Enfocados en la calidad, ofreciendo a nuestros clientes las mejores oportunidades',
  methods_hr_33: 'Para mejorar la lealtad, la satisfacción y la productividad',
  methods_hr_34: 'Contactar',
  methods_hr_35: 'Contáctanos',
  methods_hr_36:
    'Para recibir más información sobre las soluciones que ofrecemos para mejorar el desempeño de tu negocio',
  methods_hr_37: 'Garantía de calidad',
  methods_hr_38: 'Garantía de confidencialidad',
  methods_hr_39: 'Estamos disponibles para ti en cualquier momento',
  methods_hr_40:
    'análisis de marca (encuesta a personal, partes interesadas, reclutadores internos y especialistas en RRHH)',
  methods_hr_41: 'análisis externo de empleadores',
  methods_hr_42:
    '(análisis de EVP de competidores, encuesta a reclutadores externos, encuesta a buscadores de empleo)',
  methods_hr_43: 'conciencia y reputación de marca',
  methods_hr_44: 'análisis',
  methods_hr_45: 'presencia del empleador',
  methods_hr_47: 'referencia del empleador',
  methods_hr_48: 'recolección',
  methods_hr_49: 'benchmarking competitivo',
  methods_hr_50: 'sobre factores clave como',
  methods_hr_51: 'salario,',
  methods_hr_52: 'ambiente de trabajo, atmósfera, naturaleza del trabajo',
  methods_hr_53: 'análisis de necesidades y expectativas',
  methods_hr_54: 'de los buscadores de empleo',
  methods_hr_55: 'se utilizan entrevistas en profundidad,',
  methods_hr_56: 'investigación de escritorio e investigación cuantitativa',
  methods_hr_57: 'Solución 3',
  methods_hr_58: 'Mapa del viaje del empleado/misterioso empleado',
  methods_hr_59: 'construcción del viaje del buscador de empleo identificando',
  methods_hr_60:
    'las etapas principales de la búsqueda, canales de comunicación, criterios de selección y evaluación de los buscadores de empleo',
  methods_hr_61: 'monitoreo de todas las etapas',
  methods_hr_62:
    'y canales de comunicación con buscadores de empleo misteriosos (solo hacer una cita para una entrevista, tener una entrevista, una pasantía en la empresa)',
  methods_hr_63: 'Identificación',
  methods_hr_64:
    'de debilidades en los procesos de reclutamiento e incorporación',
  methods_hr_65: 'Recomendaciones para mejorar,',
  methods_hr_66: 'benchmarking con líderes de la industria',
  mystery_shopping_1: 'Misterio',
  mystery_shopping_2: 'Compras',
  mystery_shopping_3: 'Evalúa tu servicio con clientes reales',
  mystery_shopping_4:
    'Verifica estándares, evalúa a tu personal y acércate más a tu cliente',
  mystery_shopping_5:
    'Somos miembros de élite de la Asociación Profesional de Proveedores de Mystery Shopping MSPA Europa',
  mystery_shopping_6: 'de experiencia',
  mystery_shopping_7: 'visitas mensuales',
  mystery_shopping_8: 'misteriosos compradores en todo el mundo',
  mystery_shopping_9: 'Proyectos activos',
  mystery_shopping_10: 'oficinas, sede en Austria',
  mystery_shopping_11: 'Proveedores europeos de Mystery Shopping',
  mystery_shopping_12: 'calificaciones de calidad de servicio',
  mystery_shopping_13: 'Cuestionario',
  mystery_shopping_14: 'Software',
  mystery_shopping_15: 'Perfil del comprador',
  mystery_shopping_16: 'Validación y objeción',
  mystery_shopping_17: 'Analítica',
  mystery_shopping_18:
    'Con estos 5 pasos, nuestra empresa 4Service garantiza la calidad del servicio de comprador misterioso',
  mystery_shopping_19: 'Programa',
  mystery_shopping_20:
    'Los 5 elementos del programa de Mystery Shopping de 4Service',
  mystery_shopping_21: 'Cuestionario:',
  mystery_shopping_22: 'el núcleo de tu programa',
  mystery_shopping_23:
    'Esta parte del programa es visible para todas las partes interesadas',
  mystery_shopping_24: 'Para altos directivos.',
  mystery_shopping_25:
    'Muestra todos los pasos y detalles importantes del proceso',
  mystery_shopping_26: 'Para empleados.',
  mystery_shopping_27: 'Refleja lo que los empleadores esperan de ellos',
  mystery_shopping_28: 'Para compradores misteriosos.',
  mystery_shopping_29: 'Claro y estructurado para evitar confusiones y sesgos',
  mystery_shopping_30: 'Software Shopmetrics:',
  mystery_shopping_31: 'amigable para el usuario e innovador',
  mystery_shopping_32: 'Beneficios:',
  mystery_shopping_33: 'Se integra',
  mystery_shopping_34: 'con tus procesos y software empresariales',
  mystery_shopping_35: 'Impulsado por GDPR',
  mystery_shopping_36: 'Kit de herramientas para gestión de privacidad',
  mystery_shopping_37: 'Detallado',
  mystery_shopping_38: 'Paquete de cumplimiento legal',
  mystery_shopping_39: 'Generador de',
  mystery_shopping_40: 'reportes',
  mystery_shopping_41: 'Gestión',
  mystery_shopping_42: 'de niveles de acceso',
  mystery_shopping_43: 'La mayoría de los cuestionarios',
  mystery_shopping_44: 'se completan en una hora',
  mystery_shopping_45: 'Perfil de los compradores:',
  mystery_shopping_46:
    'más de 200,000 compradores misteriosos en todo el mundo',
  mystery_shopping_47:
    'Criterio principal: máxima proximidad a tu público objetivo, con experiencia previa con tu empresa o competidores',
  mystery_shopping_48: 'Entrenamiento de compradores:',
  mystery_shopping_49: 'Entrenamiento introductorio de prueba',
  mystery_shopping_50: 'Llamada por Skype/teléfono',
  mystery_shopping_51: 'Prueba de evaluación (opcional)',
  mystery_shopping_52: 'Validación y objeciones',
  mystery_shopping_53: 'Validación',
  mystery_shopping_54: 'Sistema',
  mystery_shopping_55: 'El 100% de los reportes',
  mystery_shopping_56: 'son revisados por el equipo de validación',
  mystery_shopping_57: 'Llamadas adicionales',
  mystery_shopping_58: 'para aclaraciones',
  mystery_shopping_59: 'Grabaciones de audio',
  mystery_shopping_60:
    'después de la visita para anotar los temas más importantes',
  mystery_shopping_61: 'Fraude de empleados',
  mystery_shopping_62: 'sistema de prevención',
  mystery_shopping_63: 'Objeción',
  mystery_shopping_64: 'Sistema',
  mystery_shopping_65: 'Cualquier evaluación del comprador',
  mystery_shopping_66:
    'puede ser objetada por el empleado directamente en el sitio web',
  mystery_shopping_67: 'Cada objeción',
  mystery_shopping_68:
    'es considerada y respondida por el equipo local de validación',
  mystery_shopping_69: 'Puedes ver la estadística',
  mystery_shopping_70: 'de las objeciones',
  mystery_shopping_71: 'Analítica.',
  mystery_shopping_72: 'El informe se presenta en persona y contiene:',
  mystery_shopping_73: 'Puntos de dolor y preocupaciones',
  mystery_shopping_74: 'de la empresa',
  mystery_shopping_75: 'Dinámica',
  mystery_shopping_76: 'por divisiones y secciones',
  mystery_shopping_77: 'Correlación',
  mystery_shopping_78:
    'entre el cumplimiento de los estándares y la satisfacción del cliente',
  mystery_shopping_79: 'Comparación"',
  mystery_shopping_80: 'con los competidores',
  mystery_shopping_81: 'Recomendaciones',
  mystery_shopping_82: 'prácticas para mejorar el servicio',
  mystery_shopping_83: '¿Qué nos hace diferentes?',
  mystery_shopping_84: 'Realizamos sesiones de ideación',
  mystery_shopping_85:
    'No solo recomendamos qué hacer, sino que buscamos juntos formas prácticas de resolver problemas de servicio.',
  mystery_shopping_86:
    'Las sesiones de ideación son una herramienta poderosa que nos ayuda a:',
  mystery_shopping_87:
    'Desarrollar direcciones para mejorar productos o servicios',
  mystery_shopping_88:
    'Explorar nuevas oportunidades de ingresos y estrategias de negocio',
  mystery_shopping_89:
    'Encontrar soluciones a desafíos intrincados centrados en el cliente',
  mystery_shopping_90:
    'Transformar los puntos de dolor de los clientes en deleites',
  mystery_shopping_91: 'Realizamos entrevistas en video',
  mystery_shopping_92:
    'No solo recolectamos cuestionarios - también obtenemos comentarios en vivo de los compradores',
  mystery_shopping_93: 'Hacemos esto para:',
  mystery_shopping_94:
    'Nuestros facilitadores realizarán entrevistas en profundidad y brindarán comentarios efectivos',
  mystery_shopping_95: 'Ver la experiencia desde la perspectiva del cliente',
  mystery_shopping_96: 'Mejorar tu estrategia de marketing de contenido',
  mystery_shopping_97: 'Predecir el comportamiento del cliente',
  mystery_shopping_98: 'Diseñamos el Mapa del Recorrido del Cliente',
  mystery_shopping_99:
    'La creación de un Mapa del Recorrido del Cliente nos ayuda a ponernos en los zapatos del cliente para entender sus necesidades y desafíos.',
  mystery_shopping_100: 'Crear un recorrido del cliente es la mejor manera de:',
  mystery_shopping_101: 'Ver la experiencia desde la perspectiva del cliente',
  mystery_shopping_102: 'Mejorar tu estrategia de marketing de contenido',
  mystery_shopping_103: 'Predecir el comportamiento del cliente',
  mystery_shopping_104:
    'Identificar brechas en el servicio o las comunicaciones',
  mystery_shopping_105: 'Ofrecemos diferentes tipos de Mystery Shopping',
  mystery_shopping_106: 'Tipos de Mystery Shopping',
  mystery_shopping_107: 'Mystery Caller',
  mystery_shopping_108:
    'Llama a tus gerentes y/o centro de llamadas para determinar la calidad del servicio proporcionado por teléfono',
  mystery_shopping_109: 'Mystery shopping de lujo',
  mystery_shopping_110:
    'Evaluación del servicio en ubicaciones de lujo por una categoría especial de compradores misteriosos',
  mystery_shopping_111: 'Visitas competitivas',
  mystery_shopping_112:
    'La forma más fácil de entender cómo opera tu competidor',
  mystery_shopping_113: 'Mystery Employee',
  mystery_shopping_114:
    'Ayudará a comprender todo el recorrido de tu empleado (contratación, adaptación, procesos de oficina, etc.)',
  mystery_shopping_115: 'Visitas motivacionales',
  mystery_shopping_116:
    'Una herramienta útil para estimular la oferta de servicios adicionales y/o productos, ventas de productos específicos',
  mystery_shopping_117: 'Mystery Shopper Online',
  mystery_shopping_118:
    'Simula y mide la experiencia del cliente en tu tienda online, sitio web o app',
  mystery_shopping_119: 'Verificación de ventas y promociones',
  mystery_shopping_120:
    'Ayuda a identificar cómo se muestran las promociones en todas las ubicaciones y cuán efectivas son',
  mystery_shopping_121: 'Visitas de compra y devolución',
  mystery_shopping_122:
    'Ayuda a reflejar todo el proceso desde la compra hasta la devolución para mejorar la experiencia del cliente',
  mystery_shopping_123: '> 20 años',
  nps_1: 'Net Promotion',
  nps_2: 'Puntuación',
  nps_3: 'Mide, rastrea y mejora la lealtad del cliente',
  nps_4: 'Responden con una puntuación de 0 a 6.',
  nps_5:
    'Es posible que hayan tenido una mala experiencia y es poco probable que vuelvan a comprar de ti, e incluso pueden desalentar a otros de comprar de ti.',
  nps_6: 'Estos son los encuestados que puntúan entre 7 y 8.',
  nps_7:
    'Están satisfechos con tu servicio, pero no lo suficientemente contentos como para ser considerados promotores.',
  nps_8: 'Estos son los encuestados que califican tu negocio con un 9 o un 10.',
  nps_9:
    'Esto significa que te recomendarían a un amigo o colega, y por lo tanto están abogando en tu nombre.',
  nps_10: '¿Qué es el NPS?',
  nps_11: 'Mide la percepción del cliente basada en una simple pregunta:',
  nps_12:
    '¿Qué tan probable es que recomiendes [Organización/Producto/Servicio] a un amigo o colega?',
  nps_13: '¿Necesita mi empresa esto?',
  nps_14: 'El NPS es tu crecimiento sostenible',
  nps_15: 'Entiende la dinámica de la lealtad del cliente',
  nps_16: 'Motiva a tu personal',
  nps_17: 'Entiende la relación con diferentes productos',
  nps_18: 'Compara cómo los clientes te tratan a ti y a tus competidores',
  nps_19: '¿Qué puedo hacer con el NPS?',
  nps_20: '¿Para qué tipo de negocio es relevante el NPS?',
  nps_21: 'Métodos de recopilación de información:',
  nps_22: 'Nuestras recomendaciones al trabajar con NPS',
  nps_23:
    'Esto significa que desarrollamos pautas para ayudar a convertir a los detractores en promotores',
  nps_24: 'Trabajamos en un método de "circuito cerrado"',
  nps_25:
    'El NPS es una investigación de seguimiento en la que rastreamos cambios en la lealtad del cliente',
  nps_26: 'Monitoreamos la dinámica',
  nps_27:
    'Ofrecemos evaluar siempre a los competidores en tu nicho para un análisis más profundo',
  nps_28: 'Evaluación de competidores',
  price_monitoring_1: 'Monitoreo de precios',
  price_monitoring_2: 'Monitoreo minorista',
  price_monitoring_3:
    'Aumente el potencial de ventas y optimice su política de precios mediante el monitoreo de precios de la competencia',
  price_monitoring_4:
    '4Service ha estado brindando servicios de monitoreo de precios en todo el mundo por más de 20 años',
  price_monitoring_5:
    'Nuestros equipos de auditores internos calificados monitorean los precios en poco tiempo, lo que nos permite responder rápidamente a los cambios en los precios de la competencia',
  price_monitoring_6:
    'Desafíos que nuestra empresa puede ayudarlo a resolver con el monitoreo de precios',
  price_monitoring_7: 'Evaluación de los precios de la competencia',
  price_monitoring_8: 'y políticas de productos',
  price_monitoring_9: 'Monitoreo de promociones',
  price_monitoring_10: 'y ofertas especiales',
  price_monitoring_11: 'Monitoreo de las condiciones del mercado',
  price_monitoring_12: 'y el entorno del mercado',
  price_monitoring_13: 'Verificación y control de puntos de venta',
  price_monitoring_14: 'para equipos y mercancías especiales',
  price_monitoring_15: 'Gestión de productos',
  price_monitoring_16: 'flujo dentro del comercio',
  price_monitoring_17: 'Optimización de condiciones',
  price_monitoring_18:
    'para una mejor colaboración con proveedores, distribuidores',
  price_monitoring_19: 'Medición de espacio en estantes,',
  price_monitoring_20:
    'evaluación del posicionamiento de los productos, disponibilidad de stock',
  price_monitoring_21: 'Indicadores clave y criterios de monitoreo',
  price_monitoring_22:
    'Todos los datos se compilan en una sola interfaz, incluyendo:',
  price_monitoring_23: 'nombre',
  price_monitoring_24: 'del producto y categoría de marca',
  price_monitoring_25: 'disponibilidad',
  price_monitoring_26: 'de materiales promocionales',
  price_monitoring_27: 'precio',
  price_monitoring_28: 'y disponibilidad de cualquier oferta promocional',
  price_monitoring_29: 'estante',
  price_monitoring_30: 'tamaño',
  price_monitoring_31: 'producto',
  price_monitoring_32: 'mostrar',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'cara',
  price_monitoring_35: 'tipo',
  price_monitoring_36: 'de embalaje',
  price_monitoring_37: 'cualquier otro',
  price_monitoring_38: 'información general',
  price_monitoring_39: 'Análisis previo al proyecto:',
  price_monitoring_40: 'Estudiamos',
  price_monitoring_41:
    'la política de precios y productos de su empresa, las prácticas comerciales y de la industria',
  price_monitoring_42: 'Proporcionamos',
  price_monitoring_43:
    'capacitación, pruebas y certificación rápidas para auditores',
  price_monitoring_44: 'Personalizamos',
  price_monitoring_45: 'y configuramos el software',
  price_monitoring_46: 'Trabajo de campo:',
  price_monitoring_47: 'Visitas a minoristas',
  price_monitoring_48: 'puntos de venta',
  price_monitoring_49: 'Informes:',
  price_monitoring_50: 'Nuestro equipo analítico',
  price_monitoring_51:
    'prepara informes en línea en las cuentas personales de la empresa',
  price_monitoring_52: 'Proporcionamos',
  price_monitoring_53:
    'un informe completo de fotos, informe PPT, informe de Excel en términos de tipo de localidad, nombre de la cadena, dirección del punto de venta',
  price_monitoring_54: 'Cómo',
  price_monitoring_55: 'funciona',
  price_monitoring_56: 'el tamaño de los descuentos individuales',
  price_monitoring_57: 'cálculo de costos',
  price_monitoring_58: 'tamaño de bonos individuales',
  price_monitoring_59: 'programas de lealtad',
  price_monitoring_60: 'etc.',
  price_monitoring_61: 'Utilizamos software especializado:',
  price_monitoring_62:
    'PRADATA, Shopmetrics y ofrecemos soporte técnico integral',
  price_monitoring_63: 'Despliegue rápido',
  price_monitoring_64: 'y escalado (desde el día 1)',
  price_monitoring_65: 'Todos los datos confirmados',
  price_monitoring_66: 'mediante fotos y etiquetado GPS',
  price_monitoring_67: 'Tenemos el número necesario',
  price_monitoring_68: 'de agentes de campo en cualquier país',
  price_monitoring_69: 'Podemos trabajar con grandes',
  price_monitoring_70: 'cantidades de datos',
  price_monitoring_71: 'Monitoreo de precios individuales',
  price_monitoring_72:
    'Si tiene productos complejos, podemos ayudarlo a definir su estrategia de precios',
  price_monitoring_73:
    'Podemos monitorear los precios de la competencia individual, por ejemplo:',
  price_monitoring_74: 'Bajo',
  price_monitoring_75: 'costo',
  price_monitoring_76: 'Alto',
  price_monitoring_77: 'eficiencia',
  price_monitoring_78: 'Informe de Excel',
  price_monitoring_79: 'Informe PPT',
  price_monitoring_80: 'Informe de BI',
  price_monitoring_81:
    'Los datos de precios de la competencia lo ayudan a tomar las mejores decisiones de precios',
  price_monitoring_82:
    'Proporcionamos un informe completo de fotos, informe PPT, informe de Excel en términos de tipo de localidad',
  qualitative_research_1: 'Investigaciones',
  qualitative_research_2: 'cualitativas',
  qualitative_research_3:
    'Recopila, analiza e interpreta datos a través de la observación de acciones y palabras de tus clientes',
  qualitative_research_4: 'Valores',
  qualitative_research_5: 'Comportamiento',
  qualitative_research_6: 'Antecedentes',
  qualitative_research_7: 'Expectativas',
  qualitative_research_8: 'Entorno',
  qualitative_research_9: 'Miedos/Barrearas',
  qualitative_research_10: '¿Cuál es el propósito de la elección del cliente?',
  qualitative_research_11: 'Hombre con teléfono',
  qualitative_research_12: 'marca',
  qualitative_research_13: 'categoría',
  qualitative_research_14: 'publicidad',
  qualitative_research_15: 'producto',
  qualitative_research_16:
    'La singularidad de los métodos cualitativos es que nos permiten entender las causas y motivaciones del comportamiento del consumidor',
  qualitative_research_17:
    'Análisis de la actitud del consumidor hacia un fenómeno particular',
  qualitative_research_18: 'Perspectivas y experiencias individuales',
  qualitative_research_19:
    'Temas que no se pueden investigar a través de encuestas',
  qualitative_research_20: 'Temas sensibles',
  qualitative_research_21: 'Audiencia difícil de alcanzar',
  qualitative_research_22: 'Geografía compleja',
  qualitative_research_23: 'Las entrevistas en profundidad son útiles para:',
  qualitative_research_24: 'Entrevistas en profundidad',
  qualitative_research_25:
    'Una entrevista en profundidad es una técnica de investigación cualitativa que implica realizar entrevistas individuales con los encuestados para explorar sus perspectivas sobre una idea, programa o situación en particular. Una entrevista en profundidad explora experiencias / percepciones / prácticas individuales en detalle.',
  qualitative_research_26: 'Objetivos:',
  qualitative_research_27: 'Caracterizar normas sociales y culturales',
  qualitative_research_28: 'Audiencia difícil de alcanzar',
  qualitative_research_29:
    'Compartir y comparar (Morgan) investigado a través de encuestas',
  qualitative_research_30:
    'Temas que no se pueden investigar mediante encuestas',
  qualitative_research_31: 'Grupos focales en profundidad útiles para:',
  qualitative_research_32: 'Grupos focales',
  qualitative_research_33:
    'Exploración profunda de reuniones individuales, discusiones emergentes, discusiones entre manifestaciones bajo la influencia del moderador del grupo.',
  qualitative_research_34:
    'Generación de ideas sobre experiencias compartidas y normas sociales a través de la discusión grupal, datos narrativos en una discusión enfocada.',
  qualitative_research_36: 'Beneficios:',
  qualitative_research_37:
    'Estudio del estilo de vida y comportamiento del consumidor en diversas condiciones reales (en casa, en el punto de venta, en el trabajo, etc.)',
  qualitative_research_38:
    'Búsqueda de ideas para el desarrollo de nuevos productos, empaques, mejora de servicios, etc.',
  qualitative_research_39:
    'Investigación etnográfica en profundidad útil para:',
  qualitative_research_40: 'Investigación etnográfica',
  qualitative_research_41:
    'La etnografía es un tipo de investigación cualitativa que implica sumergirse en una comunidad u organización particular para observar de cerca su comportamiento e interacciones.',
  qualitative_research_42:
    'La etnografía es un método de investigación flexible que permite obtener una comprensión profunda de la cultura compartida, convenciones y dinámicas sociales de un grupo. Sin embargo, también implica algunos desafíos prácticos y éticos.',
  qualitative_research_43: 'Métodos:',
  qualitative_research_44:
    'La etnografía es un estudio del consumidor en condiciones de vida real e interacción con un producto/servicio.',
  qualitative_research_45: 'Beneficios de trabajar con nosotros',
  qualitative_research_46:
    'Podemos encontrar encuestados de cualquier público objetivo. Y controlamos la calidad del reclutamiento.',
  qualitative_research_47: 'Reclutamiento de cualquier tipo de complejidad',
  qualitative_research_48:
    'Nuestros moderadores son multilingües y tienen habilidades de facilitación.',
  qualitative_research_49: 'Moderadores expertos',
  qualitative_research_50: 'Trabajo en línea/físico',
  qualitative_research_51: 'Proporcionamos grabaciones de audio/video',
  qualitative_research_52: 'grabaciones',
  qualitative_research_53:
    'Podemos realizar investigaciones cualitativas en cualquier localidad.',
  quantitative_research_1: 'Estudio de la satisfacción del cliente',
  quantitative_research_2:
    'con un producto o servicio en particular (SCI, NPS)',
  quantitative_research_3: 'Realización de segmentación',
  quantitative_research_4: 'encuestas de diferentes mercados',
  quantitative_research_5: 'Investigación de mercado',
  quantitative_research_6: 'Gestión de NPS',
  quantitative_research_7: 'Análisis de competidores',
  quantitative_research_8: 'Cuantitativo',
  quantitative_research_9: 'investigaciones',
  quantitative_research_10:
    'Metodología de investigación que se utiliza para probar teorías sobre las actitudes y comportamientos de las personas basándose en datos objetivos, numéricos y estadísticos',
  quantitative_research_11: 'años',
  quantitative_research_12: 'de experiencia en CX en todo el mundo',
  quantitative_research_13: 'panel',
  quantitative_research_14: 'de encuestados',
  quantitative_research_15: 'países',
  quantitative_research_16: 'realizamos proyectos en todo el mundo',
  quantitative_research_17: 'Quiénes somos',
  quantitative_research_18:
    '4Service es una empresa global de investigación de marketing y CX. Ofrecemos servicios de recolección de datos expertos a una amplia variedad de negocios',
  quantitative_research_19: '4Service en Números',
  quantitative_research_20: 'Entrevistas Asistidas por Computadora',
  quantitative_research_21: 'Entrevistas Web',
  quantitative_research_22:
    'Una técnica de cuestionario basada en Internet. El encuestado completa un cuestionario electrónico sin la ayuda de un entrevistador. El cuestionario puede enviarse electrónicamente o publicarse en el sitio web.',
  quantitative_research_23: 'Entrevistas Web Asistidas por Computadora',
  quantitative_research_24: 'En la mayoría de los casos',
  quantitative_research_25:
    'este método es el más rentable en términos de costos materiales y de tiempo',
  quantitative_research_26: 'Esta encuesta proporciona',
  quantitative_research_27:
    'una oportunidad para entrevistar a una audiencia que es difícil de alcanzar',
  quantitative_research_28: 'Proporciona amplias posibilidades',
  quantitative_research_29:
    'para la demostración de material en video y audio, así como imágenes',
  quantitative_research_30: 'Los encuestados pueden estar',
  quantitative_research_31: 'ubicados en cualquier parte del mundo',
  quantitative_research_32: 'Permite investigar',
  quantitative_research_33:
    'el comportamiento específico de la audiencia de Internet. Los resultados principales pueden ser accedidos por el cliente en tiempo real a través de una interfaz web',
  quantitative_research_34:
    'Elegible para cualquier tema o cuestión confidencial,',
  quantitative_research_35:
    'sensible, personal que los encuestados pueden haber dudado en responder al hablar con un entrevistador',
  quantitative_research_36: '150 000+ panel',
  quantitative_research_37:
    'Contamos con nuestro propio panel de encuestados en todo el mundo',
  quantitative_research_38: 'Trabajamos con consultas desafiantes',
  quantitative_research_39:
    'Usando herramientas complementarias (redes sociales, publicidad), podemos encontrar una audiencia específica y un perfil sofisticado del encuestado',
  quantitative_research_40: 'Motivamos a nuestros encuestados',
  quantitative_research_41:
    'Hemos diseñado un marco para fomentar la finalización de encuestas largas proporcionando incentivos materiales',
  quantitative_research_42: 'Entrevistas cara a cara',
  quantitative_research_43: 'realizadas mediante tabletas',
  quantitative_research_44:
    'Este es uno de los métodos principales para recolectar datos cuantitativos, durante el cual el entrevistador se comunica directamente con el encuestado en un cuestionario estrictamente estructurado.',
  quantitative_research_45:
    'Las entrevistas cara a cara pueden realizarse en el lugar de trabajo o residencia de los encuestados, en la calle o en lugares de venta designados utilizando una tableta',
  quantitative_research_46:
    'Entrevistas cara a cara realizadas mediante tabletas',
  quantitative_research_47: 'Determinación del nivel',
  quantitative_research_48: 'de popularidad y reconocimiento de marca',
  quantitative_research_49: 'Segmentación del consumidor',
  quantitative_research_50: 'basada en su comportamiento de compra',
  quantitative_research_51: 'Análisis de la efectividad',
  quantitative_research_52: 'de la campaña publicitaria',
  quantitative_research_53: 'Satisfacción del cliente',
  quantitative_research_54: 'medición',
  quantitative_research_55: 'Determinación del mejor precio',
  quantitative_research_56: 'para el producto o servicio',
  quantitative_research_57: 'Análisis',
  quantitative_research_58: 'de las preferencias del consumidor',
  quantitative_research_59: 'Estudio',
  quantitative_research_60: 'de cómo compran los consumidores',
  quantitative_research_61: 'Evaluación',
  quantitative_research_62: 'de la capacidad del mercado',
  quantitative_research_63: 'Software DigSee',
  quantitative_research_64:
    'Utilizamos software especial con grabación de audio y GPS',
  quantitative_research_65: 'Entrevistadores y supervisores',
  quantitative_research_66:
    'podemos realizar entrevistas en cualquier idioma y traducir al idioma preferido del cliente',
  quantitative_research_67: 'Muestreo',
  quantitative_research_68:
    'El departamento analítico desarrolla un sistema de reclutamiento de encuestados para obtener el mejor resultado posible',
  quantitative_research_69: 'Entrevistas Asistidas por Computadora',
  quantitative_research_70: 'Entrevistas Telefónicas',
  quantitative_research_71:
    'Metodología para recopilar información cuantitativa a través de entrevistas telefónicas utilizando un cuestionario claramente estructurado.',
  quantitative_research_72: 'Entrevistas Telefónicas Asistidas por Computadora',
  quantitative_research_73: 'Contamos con nuestros propios centros de llamadas',
  quantitative_research_74:
    'Utilizamos software especial con grabación de audio y GPS',
  quantitative_research_75: 'Operadores calificados',
  quantitative_research_76:
    'Nuestros operadores hablan diferentes idiomas, están capacitados, bien evaluados y mejoran continuamente sus habilidades',
  quantitative_research_77: 'Bases de datos',
  quantitative_research_78:
    'Trabajamos con nuestras propias bases de datos y las bases de datos de sus clientes también',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Garantizamos calidad y firmamos un SLA (Acuerdo de Nivel de Servicio)',
  quantitative_research_81: 'Establecimiento de objetivos',
  quantitative_research_82: 'Determinación de la metodología',
  quantitative_research_83: 'Segmentación y muestreo',
  quantitative_research_84: 'Definición de los canales de recolección de datos',
  quantitative_research_85:
    'Creación de un cuestionario que sea lógico para el encuestado',
  quantitative_research_86: 'Proceso de recolección de datos',
  quantitative_research_87: 'Aseguramiento de calidad',
  quantitative_research_88: 'Técnicas de análisis',
  quantitative_research_89: 'Procesamiento de datos',
  quantitative_research_90: 'Diseño de encuestas',
  quantitative_research_91:
    'Seguimiento de la ubicación GPS de los entrevistadores',
  quantitative_research_92: 'Verificación de datos',
  quantitative_research_93: 'Programación y prueba de cuestionarios',
  quantitative_research_94: 'Validación de datos',
  quantitative_research_95:
    'Grabaciones de audio cumpliendo con los requisitos del GDPR',
  quantitative_research_96:
    'Capacitación y competencia del personal de investigación',
  quantitative_research_97: 'Aseguramiento de Calidad',
  quantitative_research_98: 'Análisis factorial',
  quantitative_research_99: 'Segmentación',
  quantitative_research_100: 'Mapeo perceptual',
  quantitative_research_101: 'Correlación',
  quantitative_research_102: 'Modelo de conversión',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Análisis de clústeres',
  quantitative_research_105: 'etc.',
  quantitative_research_106: 'Informes analíticos',
  quantitative_research_107:
    'Utilizamos SPSS® y otras herramientas para construir informes analíticos utilizando diferentes metodologías',
  quantitative_research_108: 'Qué desafíos empresariales se pueden resolver:',
  quantitative_research_109: 'Qué desafíos empresariales se pueden resolver:',
  social_responsibility_1: 'Responsabilidad Social Corporativa de 4Service',
  social_responsibility_2:
    'Desde el corazón y el alma de cada miembro de nuestro equipo. Intentamos mantener una chispa de amabilidad en cada uno de nuestros empleados y hacer que el mundo que nos rodea sea un poco mejor',
  social_responsibility_3: 'Actividades educativas / Apoyo a la juventud',
  social_responsibility_4:
    'Nuestras iniciativas de divulgación para niños y estudiantes',
  social_responsibility_5: 'Juego de negocios Service Mania',
  social_responsibility_6:
    'Una parte importante de nuestras actividades de capacitación es el juego de negocios Service Mania.',
  social_responsibility_7:
    'Service Mania te desafía a pensar estratégicamente sobre cómo enfrentar situaciones inusuales.',
  social_responsibility_8: 'Presentación de investigación / Diiya. Negocios',
  social_responsibility_9:
    'Una perspectiva empresarial y del cliente sobre la situación del servicio: una presentación de nuestros hallazgos de investigación de 4Service por Victoria Skorbota, Jefa de Desarrollo en 4Service Ucrania',
  social_responsibility_10: 'Sesiones de Diseño de Servicios',
  social_responsibility_11:
    'La metodología de Diseño de Servicios es una parte importante de 4Service. Hace un par de años nos convertimos en socios de DesignThinkers Academy y no solo utilizamos las mejores prácticas en nuestro trabajo, sino que también impartimos capacitación activa a las mejores empresas',
  social_responsibility_12: 'Caridad / Voluntariado',
  social_responsibility_13:
    'Orfanatos, hogares de ancianos y voluntariado para el ejército ucraniano. La parte más conmovedora de nuestras actividades',
  social_responsibility_14: '#AyudaFácilCon4ServiceGroup',
  social_responsibility_15:
    'Trabajamos en asociación con el Fondo Benéfico Blagomai y tratamos de llevar celebración y ayudar a aquellos que están en grave necesidad',
  social_responsibility_16:
    'Recaudación de fondos para orfanatos y hogares de ancianos',
  social_responsibility_17:
    'En 4Service hemos iniciado varias campañas de recaudación de fondos para hogares de ancianos y orfanatos para comprar necesidades básicas',
  social_responsibility_18: '#SalvaUcrania',
  social_responsibility_19:
    'La guerra ha afectado a muchos de nuestros empleados y amigos cercanos. Cada uno de nosotros ayuda tanto como puede',
  social_responsibility_20: 'Bienestar animal',
  social_responsibility_21:
    'Cualquiera puede venir a nuestra oficina con su mascota. Ayudamos a los refugios, recaudamos fondos para comida y, a veces, también buscamos hogares para animales sin hogar',
  social_responsibility_22: 'Apoyo a refugios de animales',
  social_responsibility_23:
    'Los animales sin hogar necesitan ayuda tanto como todos los miembros vulnerables de nuestra sociedad.',
  social_responsibility_24:
    'Nuestra empresa ha asumido el cuidado del Refugio de Animales Callejeros Sirius (región de Kyiv, Ucrania).',
  social_responsibility_25:
    'Iniciativas creativas para ayudar a los animales callejeros',
  social_responsibility_26:
    '¿Cómo podemos llevar el urgente tema de los refugios de animales a la atención de nuestros clientes y socios?',
  social_responsibility_27: 'Oficina amigable con las mascotas',
  social_responsibility_28:
    'Muchos de nuestros miembros del equipo tienen mascotas. Hemos creado un ambiente saludable donde todos pueden entrar a la oficina con sus mascotas y sentirse cómodos',
  ux_testing_1: 'Pruebas de UX',
  ux_testing_1_1: 'Pruebas de UX',
  ux_testing_2:
    'Enfoque humano y basado en IA para probar y mejorar tus sitios web y aplicaciones',
  ux_testing_3: '¿Qué es la prueba de UX?',
  ux_testing_4:
    'La prueba de UX te ayuda a entender cómo las personas interactúan con tu producto, aplicación o sitio web',
  ux_testing_5: 'La navegación no es muy clara',
  ux_testing_6: 'No tengo idea de cómo hacer un pedido',
  ux_testing_7: 'No hay suficientes fotos en el catálogo',
  ux_testing_8: 'Tú:',
  ux_testing_9: 'Descubre oportunidades de mejora',
  ux_testing_10:
    'Identifica problemas en el diseño y la interacción del cliente',
  ux_testing_11:
    'Descubre qué dificultades encuentra el usuario al interactuar con el sitio',
  ux_testing_12: 'Observa el recorrido del cliente',
  ux_testing_13:
    'Reconoce cuándo y por qué tu público objetivo abandona el sitio',
  ux_testing_14:
    'Define qué secciones despiertan el menor y mayor interés y emoción',
  ux_testing_15: 'Pruebas de UX basadas en el usuario',
  ux_testing_16:
    '4Service te proporciona los verdaderos insights humanos que necesitas para crear sitios web y aplicaciones centrados en el cliente utilizando métodos cualitativos y cuantitativos',
  ux_testing_17: 'No entiendo cómo usar el sitio. ¿Dónde está oculto el menú?',
  ux_testing_18:
    'Creo que hay demasiado espacio entre las letras, el texto es ilegible',
  ux_testing_19:
    'Las imágenes se superponen entre sí. ¿Así es como se supone que debe ser?',
  ux_testing_20:
    'La prueba de UX te ayuda a entender cómo las personas interactúan con tu producto, aplicación o sitio web',
  ux_testing_21:
    'Diseño de investigación de pruebas de UX basadas en el usuario:',
  ux_testing_22: 'Descubre oportunidades de mejora',
  ux_testing_23:
    'Determinar el tipo y método de prueba (cualitativa/cuantitativa)',
  ux_testing_24: 'Redacción de hipótesis y escenarios de usuario',
  ux_testing_25: 'Análisis e informes de resultados de pruebas',
  ux_testing_26: 'Dos soluciones',
  ux_testing_27: 'Ofrecemos dos enfoques para pruebas de UX exitosas',
  ux_testing_28: 'Pruebas de UX basadas en el usuario',
  ux_testing_29: 'Pruebas de UX basadas en IA + humanas',
  ux_testing_30: 'Nuestras fortalezas:',
  ux_testing_31: 'Podemos analizar el panorama competitivo',
  ux_testing_32:
    'Podemos traer a expertos específicos para llevar a cabo y participar en entrevistas',
  ux_testing_33:
    'Podemos realizar entrevistas en diferentes idiomas y traducirlas al idioma requerido',
  ux_testing_34: 'con usabilidad existente',
  ux_testing_35: 'métricas o estándares',
  ux_testing_36: 'tu rendimiento',
  ux_testing_37: 'en comparación con el de un competidor',
  ux_testing_38: 'versiones',
  ux_testing_39: 'del mismo producto',
  ux_testing_40: 'Calificación de satisfacción',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Tasas de éxito',
  ux_testing_43: 'Tasa de error',
  ux_testing_44: 'Tiempo de finalización de tareas',
  ux_testing_45: 'Pruebas de UX basadas en el usuario',
  ux_testing_46: 'Método cuantitativo',
  ux_testing_47:
    'Utilizamos al menos 100 personas para participar en pruebas de UX en esta etapa',
  ux_testing_48:
    'Relevante cuando necesitas una comparación de tu sitio web o aplicación:',
  ux_testing_49: 'Medida del método cuantitativo:',
  ux_testing_50: 'Observación',
  ux_testing_51:
    'Pasar tiempo con un usuario o grupo de usuarios y observar su comportamiento con el producto mientras lo utilizan en su vida diaria',
  ux_testing_52: 'Entrevista en profundidad',
  ux_testing_53:
    'Te permite conocer las actitudes, creencias, deseos y experiencias de los usuarios que visitan tu sitio. Recomendamos realizar de 15 a 20 entrevistas',
  ux_testing_54: 'Investigación etnográfica',
  ux_testing_55:
    'Realizada en el entorno de los encuestados donde usarán el producto. Al observar, comienzas a tener una buena comprensión de la psicología del usuario, los desafíos emocionales que enfrentan.',
  ux_testing_56: 'Pruebas de UX basadas en el usuario',
  ux_testing_57: 'Método cualitativo',
  ux_testing_58:
    'Este método ayuda a comprender las motivaciones y la lógica del comportamiento del usuario',
  ux_testing_59: 'Usamos:',
  ux_testing_60:
    'Estamos desarrollando un mapa del recorrido del cliente para ilustrar los resultados de nuestra investigación',
  ux_testing_61: '30 participantes (hombre/mujer)',
  ux_testing_62:
    'Preseleccionamos a los encuestados e invitamos a participar en la encuesta',
  ux_testing_63: 'Entrevistas remotas en línea en la plataforma Wantent',
  ux_testing_64: 'IA + humana basada',
  ux_testing_65: 'Pruebas de UX basadas en IA + humana',
  ux_testing_66:
    'Una combinación de la tecnología única de inteligencia artificial Wantent y usuarios reales',
  ux_testing_67: 'La gran fuente en el encabezado ha atraído atención',
  ux_testing_68:
    "Los usuarios miran los números 'llamativos', pero no leen la información a su lado",
  ux_testing_69:
    'La prueba de UX te ayuda a entender cómo las personas interactúan con tu producto, aplicación o sitio web',
  ux_testing_70: 'Diseño de investigación de pruebas basadas en IA + humana',
  ux_testing_71: 'Cómo funciona',
  ux_testing_72:
    'Wantent es una solución de ajuste de mercado de contenido basada en IA.',
  ux_testing_73:
    'Wantent evalúa la eficiencia de sitios web y aplicaciones aplicando tecnologías de ML para analizar las reacciones emocionales y el compromiso de la audiencia.',
  ux_testing_74:
    'Para interpretar los resultados, usamos técnicas de narración, diversidad y empatía para impulsar el rendimiento y el crecimiento.',
  ux_testing_75:
    'La atención y las emociones del usuario durante las transiciones entre secciones',
  ux_testing_76:
    'Estadísticas por grupo de participantes (atención y enfoque durante la sesión)',
  ux_testing_77: 'Análisis de conversiones de página',
  ux_testing_78: 'Atención y emociones durante la realización de tareas',
  ux_testing_79: 'Hallazgos sobre la percepción',
  ux_testing_80: 'del sitio web por diferentes grupos de participantes',
  ux_testing_81: 'Análisis de la facilidad',
  ux_testing_82:
    'de navegar por las páginas y la facilidad de encontrar elementos importantes',
  ux_testing_83: 'Un análisis de percepción',
  ux_testing_84: 'de la información proporcionada en el sitio web',
  ux_testing_85: 'Definición de áreas',
  ux_testing_86:
    'de mejora dentro de páginas individuales (navegación, bloques de información, etc.)',
  ux_testing_87: 'Analizamos en detalle',
  ux_testing_88: 'Resultados clave de la investigación',
  ux_testing_89:
    'Recomendaciones para mejorar la estructura del sitio web y de páginas individuales',
  ux_testing_90:
    'Seleccionamos cuidadosamente la audiencia objetivo para las pruebas de usabilidad.',
  ux_testing_91:
    'Realizamos investigaciones en diferentes países, diferentes idiomas y entre diferentes grupos de usuarios',
  ux_testing_92: 'Podemos probar en aplicaciones',
  ux_testing_93:
    'y en los sitios web de los competidores y proporcionar análisis comparativos',
  ux_testing_94: 'Combinamos diferentes métodos de investigación.',
  ux_testing_95:
    'Nuestros facilitadores calificados guían al participante a través del proceso de prueba.',
  ux_testing_96: 'Realizamos pruebas',
  ux_testing_97: 'en una variedad de dispositivos y versiones de software',
  ux_testing_98: '¿Por qué elegirnos?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    'Un proyecto único de asociación entre 4Service y Wantent © Una combinación de inteligencia artificial y un enfoque basado en humanos para el análisis de contenido',
  wantent_4: 'medir el éxito antes de que el contenido se emita',
  wantent_5: 'mitigar los riesgos de problemas de diversidad',
  wantent_6: 'profundas ideas y recomendaciones para mejorar el contenido',
  wantent_7: 'decisiones objetivas sobre contenido y estrategia de marketing',
  wantent_8:
    'Wantent es una solución basada en IA para la adaptación del contenido al mercado.',
  wantent_9:
    'Wantent es una solución basada en inteligencia artificial para adaptar el contenido al mercado. Wantent evalúa la efectividad del contenido de video aplicando tecnologías de ML para analizar la respuesta emocional y el compromiso de la audiencia.',
  wantent_10:
    'Wantent ayuda a definir la adecuación del contenido al mercado y maximizar la eficiencia del contenido a través de',
  wantent_11: 'Configuración de escenarios y reclutamiento de participantes',
  wantent_12: 'diseño de proyectos personalizados y audiencia en todo el mundo',
  wantent_13: 'Recopilación de reacciones de los espectadores',
  wantent_14: 'retroalimentación detallada y clara de la audiencia',
  wantent_15: 'Análisis de la percepción del contenido',
  wantent_16: 'análisis del comportamiento y la reacción emocional mediante IA',
  wantent_17: 'Entrega de ideas para el cierre final',
  wantent_18: 'recomendaciones para mejoras',
  wantent_19: 'Wantent maximiza la eficiencia de su contenido',
  wantent_20: 'Prueba previa de conceptos publicitarios',
  wantent_21: 'Prueba de conceptos creativos',
  wantent_22:
    'Verificación de la consistencia de la creatividad futura con la estrategia desarrollada y comprensión de las reacciones de la audiencia objetivo para posibles ajustes en los conceptos/mensajes creativos',
  wantent_23:
    'Pruebas de visibilidad de los elementos de marca (postproducción)',
  wantent_24:
    'Confirmación de la efectividad de la inversión en publicidad/patrocinio',
  wantent_25: 'Pruebas de anuncios',
  wantent_26: 'Pruebas A/B',
  wantent_27:
    'Comparar la efectividad de las creatividades y aumentar el compromiso y la atención durante la fase de producción',
  wantent_28: 'Pruebas de anuncios lanzadas',
  wantent_29:
    'Definición del rendimiento de los mensajes y creación de recomendaciones para mejorar audio y visuales',
  wantent_30: 'Soluciones para TV',
  wantent_31:
    'Pilotos, promociones, nuevos formatos, pruebas de presentadores de TV',
  wantent_32:
    'Realización de un análisis comparativo para identificar el más atractivo y fortalecerlo',
  wantent_33: 'Pruebas de programas de televisión y películas de ficción',
  wantent_34:
    'Analizar la participación de los espectadores, el nivel de atención, la respuesta emocional durante la visualización de contenido largo en condiciones naturales. Encontramos automáticamente los picos de abandono de la audiencia y momentos de distracción para evaluar y mejorar el montaje de video',
  wantent_35: 'Pruebas de UI/UX',
  wantent_36: 'Pruebas de prototipos de sitios web y aplicaciones móviles',
  wantent_37:
    'Análisis de la reacción y emociones de los usuarios utilizando la tecnología Wantent:',
  wantent_38: 'nivel de atención',
  wantent_39: 'reacciones emocionales',
  wantent_40:
    'mapas de calor de la dirección de la mirada de los participantes',
  wantent_41:
    'Análisis de la usabilidad de sitios web y aplicaciones basado en los comentarios de los participantes:',
  wantent_42: 'Escala de usabilidad del sistema (SUS)',
  wantent_43: 'Puntuación de esfuerzo del cliente (Facilidad de interacción)',
  wantent_44: 'Puntuación de promotores netos (NPS)',
  wantent_45: 'Cumplimiento de GDPR y privacidad del usuario',
  wantent_46:
    'Wantent cumple plenamente con las políticas de GDPR y CCPA y respeta los principios de recolección, almacenamiento, procesamiento y protección de los datos personales de los participantes',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'CDO, Reface (Medios y entretenimiento)',
  wantent_49:
    'Experimentamos con toneladas de nuevas ideas de contenido usando tecnologías de aprendizaje automático todos los días. Wantent nos ayuda a entender y evaluar riesgos y ventajas, descubrir comentarios, comportamiento y percepción de los usuarios para obtener la causalidad de diferentes aspectos de nuestras soluciones.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'co-propietario de TABASCO (Publicidad)',
  wantent_52:
    'Wantent ofrece un servicio realmente único que nos ayuda a conocer detalles muy menores de lo que les gusta y no les gusta a los consumidores. No hay forma de engañar, no hay forma de mentir: Wantent siempre sabe la verdad, y así nosotros, como expertos en marketing, obtenemos guías invaluables (jajaja - conocemos el precio exacto y es asequible) sobre cómo mejorar nuestras comunicaciones.',
  wantent_53: 'Reseñas',
  terms_1: 'Términos y condiciones',
  terms_2: 'Acuerdo de Términos de Uso',
  terms_3:
    'Por favor, lea este Acuerdo de Términos de Uso (“términos de uso”, “acuerdo”) cuidadosamente antes de usar el sitio web',
  terms_4:
    '(“sitio web”) operado por 4Service Holdings GmbH (“4Service”, “nosotros”, “nuestro”).',
  terms_5:
    'Condiciones de uso Al usar este sitio web, certifica que ha leído y revisado este Acuerdo y que acepta cumplir con sus términos. Si no desea estar sujeto a los términos de este Acuerdo, se le aconseja salir del sitio web en consecuencia. 4Service solo otorga el uso y acceso a este sitio web, sus productos y servicios a quienes han aceptado sus términos.',
  terms_6: 'Política de privacidad',
  terms_7:
    'Antes de continuar usando nuestro sitio web, le aconsejamos que lea nuestra Política de privacidad con respecto a la recopilación de datos de usuario. Esto le ayudará a entender mejor nuestras prácticas.',
  terms_8: 'Restricción de edad',
  terms_9:
    'Debe tener al menos 16 (dieciséis) años de edad antes de poder usar este sitio web. Al usar este sitio web, garantiza que tiene al menos 16 (dieciséis) años de edad y puede adherirse legalmente a este Acuerdo. 4Service no asume ninguna responsabilidad por las responsabilidades relacionadas con la representación errónea de edad.',
  terms_10: 'Propiedad intelectual',
  terms_11:
    'Usted acepta que todos los materiales, productos y servicios proporcionados en este sitio web son propiedad de 4Service, sus afiliados, directores, funcionarios, empleados, agentes, proveedores o licenciantes, incluidos todos los derechos de autor, secretos comerciales, marcas comerciales, patentes y otra propiedad intelectual. También acepta que no reproducirá ni redistribuirá la propiedad intelectual de 4Service de ninguna manera, incluidos registros de marcas electrónicas, digitales o nuevas.',
  terms_12: 'Acceso a nuestro sitio web',
  terms_13:
    'El acceso a nuestro sitio está permitido de manera temporal, y nos reservamos el derecho de retirar o modificar el servicio que proporcionamos en nuestro sitio sin previo aviso. No seremos responsables si, por cualquier motivo, nuestro sitio no está disponible en cualquier momento o por cualquier período. De vez en cuando, podemos restringir el acceso a algunas partes de nuestro sitio, o a todo nuestro sitio, a usuarios que se han registrado con nosotros. Usted es responsable de hacer todos los arreglos necesarios para que tenga acceso a nuestro sitio, incluido el uso de equipos que sean compatibles con nuestro sitio. También es responsable de asegurarse de que todas las personas que accedan a nuestro sitio a través de su conexión a internet sean conscientes de estos términos y que cumplan con ellos.',
  terms_14: 'Ley aplicable',
  terms_15:
    'Al visitar este sitio web, acepta que las leyes de la República de Austria, sin tener en cuenta los principios de conflicto de leyes, regirán estos términos y condiciones, o cualquier disputa de cualquier tipo que pueda surgir entre 4Service y usted.',
  terms_16: 'Disputas',
  terms_17:
    'Cualquier disputa relacionada de alguna manera con su visita a este sitio web será arbitrada por el Tribunal Comercial de Viena.',
  terms_18: 'Indemnización',
  terms_19:
    'Usted acepta indemnizar a 4Service y sus afiliados y mantener a 4Service indemne contra reclamaciones legales y demandas que puedan surgir de su uso o mal uso de nuestro sitio web. Nos reservamos el derecho de seleccionar nuestro propio asesor legal.',
  terms_20: 'El período de almacenamiento',
  terms_21:
    'Teniendo en cuenta los propósitos de procesamiento, el período de almacenamiento de los datos personales de los Usuarios (período de almacenamiento) es de 24 meses a partir de la fecha en que se obtenga debidamente el consentimiento para el procesamiento de datos de usted.',
  terms_22: 'Limitación de responsabilidad',
  terms_23:
    '4Service no es responsable de ningún daño que pueda ocurrirle como resultado de su uso indebido de nuestro sitio web. 4Service se reserva el derecho, sin previo aviso, de editar, modificar y cambiar este Acuerdo en cualquier momento actualizando esta publicación. Su uso continuado del sitio web ahora, o tras la publicación de cualquier cambio o modificación, indicará su aceptación de dichos cambios o modificaciones. Si alguna parte de este acuerdo se declara ilegal, nula o inaplicable, esa parte se considerará separable y no afectará la validez y aplicabilidad de las disposiciones restantes. La posible evidencia del uso del sitio web para fines ilegales se proporcionará a las autoridades policiales. Este Acuerdo es un entendimiento entre 4Service y el usuario.',
  terms_24:
    'Por favor, dirija todas las preguntas y preocupaciones relacionadas con la privacidad/uso a la siguiente dirección:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstraße 7',
  terms_27: '1010 Viena',
  terms_28: 'Austria',
  terms_29: 'Revisión',
  terms_30:
    'Cuando los datos personales que nos proporcione no sean inexactos, tiene derecho a solicitarnos que los corrijamos (Artículo 16 del GDPR).',
  thank_you_1: '¡Gracias!',
  thank_you_2: 'Nos pondremos en contacto contigo en un plazo de 24 horas.',
  thank_you_3:
    'P.D. Nuestros gerentes ya están revisando su solicitud para elaborar una mejor oferta.',
  thank_you_4: 'Menú',
  thank_you_5: 'Mujer escribiendo',
  privacy_policy_1:
    'Formulario de aviso de privacidad para los interesados (usuarios del sitio web)',
  privacy_policy_2:
    'Este Formulario de Aviso de Privacidad (Aviso de Privacidad) está destinado a las personas físicas (Sujetos de Datos) cuyos datos personales son recopilados por 4Service Holdings GmbH, conforme a los requisitos del RGPD en el marco de la visita al sitio web',
  privacy_policy_3:
    '(en adelante Sitio web y Usuarios del Sitio web/Usuarios respectivamente).',
  privacy_policy_4:
    'El Formulario de Aviso de Privacidad para los Sujetos de Datos (Usuarios del Sitio Web) de 4Service Holdings GmbH se aplica en todas las empresas del Grupo 4Service, que están bajo la autoridad legal o supervisión de 4Service Holdings GmbH.',
  privacy_policy_5: 'Contactos',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Compañía/nosotros/nos)',
  privacy_policy_8: 'Dirección:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Viena, Austria.',
  privacy_policy_10: 'Correo electrónico:',
  privacy_policy_12: '¿Quiénes somos?',
  privacy_policy_13:
    'Una persona (Usted) que visita el Sitio Web con cualquier propósito. Para el propósito de este Documento, también se le indicará como Usuario.',
  privacy_policy_14: 'Datos personales que recopilamos de usted',
  privacy_policy_15:
    'Cuando ingresa a nuestro Sitio web, le enviamos un Aviso de Privacidad, en el que le solicitamos su consentimiento para procesar la siguiente información:',
  privacy_policy_16:
    'Dirección IP, nombre de usuario, apellido, dirección, número de teléfono (fijo o móvil), dirección de correo electrónico, nombre de la empresa, país, nombre, apellido, número de teléfono, provincia, estado y código postal, datos de uso, datos sobre interacción con redes sociales externas o plataformas, información sobre el registro y autenticación en el sitio web',
  privacy_policy_17: 'posición geográfica.',
  privacy_policy_18:
    'En caso de que exprese su voluntad y nos dé dicho consentimiento, comenzaremos a procesar dicha información suya.',
  privacy_policy_19: 'Legalidad (consentimiento)',
  privacy_policy_20:
    'Cuando ingresa a nuestro Sitio web, le enviamos un Aviso de Privacidad, en el que le solicitamos su consentimiento para procesar la siguiente información:',
  privacy_policy_21:
    'La base legal para el procesamiento de los datos personales es el consentimiento, que obtendremos de usted a través de la cumplimentación del formulario de consentimiento en el siguiente enlace:',
  privacy_policy_22:
    'Si completa el formulario de consentimiento, esto significa que comprende y acepta todas las condiciones especificadas en este Aviso de Privacidad.',
  privacy_policy_23: 'Retiro del consentimiento',
  privacy_policy_24:
    'Tiene derecho a retirar el consentimiento que nos ha proporcionado anteriormente en cualquier momento. La retirada del consentimiento no afectará a la legalidad del tratamiento basado en el consentimiento antes de su retirada. Puede retirar su consentimiento enviándonos la solicitud correspondiente al siguiente correo electrónico withdraw@4service-group.com, donde encontrará el formulario de solicitud en el siguiente enlace:',
  privacy_policy_25: 'Formulario de Retiro para el Usuario',
  privacy_policy_26: 'Finalidades del tratamiento',
  privacy_policy_27:
    'Procesamos sus datos personales para los siguientes fines:',
  privacy_policy_28:
    '– mejorar los servicios al cliente (permite una respuesta más efectiva a las solicitudes de los clientes); – personalización de la experiencia de los Usuarios (permite determinar quién está más interesado en los servicios); – mejorar el Sitio (permite mejorar la calidad de los productos y servicios, la facilidad de uso de estos, desarrollar nuevos Servicios y mejorar nuestros productos y servicios); – comunicarse con el Usuario mediante boletines informativos, materiales de marketing o promocionales y otra información que incluya nuestras noticias, actualizaciones e información sobre los servicios, con la instrucción de cómo rechazar la recepción de correos electrónicos posteriores; – realizar investigaciones estadísticas y otros tipos de análisis basados en datos anónimos; – proporcionar servicios personalizados al Usuario y ejecutar acuerdos y contratos; – participar del Usuario en varios proyectos implementados por nosotros a través del Sitio, responder a consultas realizadas por el Usuario a través del Sitio, investigación, mantenimiento de cuentas y registros y promoción de servicios.',
  privacy_policy_29:
    'Sujetos a los que se pueden transferir los datos personales',
  privacy_policy_30:
    'Durante el tratamiento de sus datos personales, transferimos sus datos personales a las entidades que actúan como procesadores de la Compañía. Los procesadores de la Compañía actúan exclusivamente sobre la base de las instrucciones de la Compañía. Las especificaciones de las acciones que realizan los procesadores de la Compañía y la lista de dichos procesadores se pueden encontrar en la Política de Privacidad y Protección de Datos en el siguiente enlace: Política de Privacidad y Protección de Datos',
  privacy_policy_31:
    'Los países a los que se pueden transferir los datos personales',
  privacy_policy_32:
    'La Compañía transfiere sus datos personales sobre la base de una decisión de adecuación según lo estipulado por el RGPD y la Comisión de la UE. Información adicional sobre la transferencia de datos personales a los EE. UU. se puede encontrar en la Política de Privacidad y Protección de Datos en el siguiente enlace: Política de Privacidad y Protección de Datos.',
  privacy_policy_33: 'El período de almacenamiento',
  privacy_policy_34:
    'Teniendo en cuenta los fines del tratamiento, el período de almacenamiento de los datos personales de los Usuarios (período de almacenamiento) es de 24 meses desde la fecha en que se obtiene debidamente su consentimiento para el tratamiento de los datos.',
  privacy_policy_35: 'Derecho de acceso',
  privacy_policy_36:
    'Tiene derecho a saber si se están procesando datos personales que le conciernen y 2) si es así, acceder a dichos datos con muchas estipulaciones adicionales establecidas en el artículo 15 del RGPD.',
  privacy_policy_37: 'Derecho de rectificación',
  privacy_policy_38:
    'Cuando los datos personales que nos proporcione no sean inexactos, entonces tiene derecho a solicitarnos que los corrijamos (artículo 16 del RGPD).',
  privacy_policy_39: 'Derecho de supresión (derecho al olvido)',
  privacy_policy_40:
    'Tiene derecho a obtener de nosotros la supresión de sus datos personales sin demora indebida y tendremos la obligación de suprimir sus datos personales sin demora indebida cuando se apliquen los motivos indicados en el artículo 17 del RGPD.',
  privacy_policy_41: 'Derecho a la limitación del tratamiento',
  privacy_policy_42:
    'Tiene derecho a limitar el tratamiento de sus datos personales con varias excepciones dentro del alcance del RGPD, particularmente las indicadas en el artículo 18 del RGPD.',
  privacy_policy_43:
    'Estamos obligados a informarle qué datos se están recopilando, cómo se están utilizando, cuánto tiempo se conservarán y si se compartirán con terceros. Esta información debe ser comunicada de manera concisa y en un lenguaje claro.',
  privacy_policy_44: 'Derecho a la portabilidad de los datos',
  privacy_policy_45:
    'Se le permite obtener y reutilizar sus datos personales para sus propios fines en diferentes servicios.',
  privacy_policy_46: 'Derecho a oponerse',
  privacy_policy_47:
    'Tiene derecho a oponerse al tratamiento de los datos personales que están siendo procesados por la Compañía. Debemos dejar de procesar los datos personales a menos que demostremos motivos legítimos convincentes para el procesamiento que prevalezcan sobre los intereses, derechos y libertades del individuo o si el procesamiento es para el establecimiento o ejercicio de la defensa de reclamaciones legales.',
  privacy_policy_48:
    'Derecho a no ser objeto de una decisión basada únicamente en el procesamiento automatizado',
  privacy_policy_49:
    'Tiene derecho a oponerse a cualquier perfilado automatizado que esté ocurriendo sin su consentimiento. Asimismo, tiene derecho a que sus datos personales sean tratados con intervención humana.',
  privacy_policy_50: 'Quejas',
  privacy_policy_51:
    'En caso de que desee presentar una queja sobre cómo se están procesando sus datos personales por la Compañía (o por los procesadores descritos anteriormente), o cómo se ha gestionado su queja, tiene derecho a presentar una queja directamente ante la autoridad de supervisión y la Compañía.',
  privacy_policy_52: 'Los detalles para cada uno de estos contactos son:',
  privacy_policy_53: 'Autoridad de supervisión:',
  privacy_policy_54: 'La Autoridad de Protección de Datos de Austria',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Viena',
  privacy_policy_58: 'Austria / Europa',
  privacy_policy_59: 'Compañía:',
  privacy_policy_60: '4Service Holdings GmbH (Compañía/nosotros/nos)',
  privacy_policy_61: 'Dirección: Tegetthoffstraße 7, 1010 Viena, Austria.',
  privacy_policy_62: 'Correo electrónico:',
  privacy_policy_63: 'Política de privacidad y protección de datos',
  privacy_policy_64:
    'Lea más sobre cómo y por qué usamos sus datos aquí: Política de Privacidad y Protección de Datos',
  privacy_policy_65: 'Aprobación',
  privacy_policy_66:
    'La Compañía ha desarrollado todos los documentos internos para definir los roles entre el personal con respecto al tratamiento de los datos personales dentro de la Compañía, en particular el responsable de aprobar y revisar la legitimidad de este documento es el Director General.',
  contacts_1: 'Para clientes',
  contacts_2:
    'Para todo tipo de negocios, agencias de investigación de mercado y grandes empresas, socios comerciales y medios.',
  contacts_3: 'Tegetthoffstrasse 7, Viena, Austria',
  contacts_4: 'Para compradores',
  contacts_5:
    'Locales y viajeros, conductores y estudiantes, compradores misteriosos profesionales y personas que se quedan en casa.',
  contacts_6: 'Contáctenos desde su país:',
  contacts_7: 'Italia',
  contacts_8: 'EE. UU.',
  contacts_9: 'Reino Unido',
  contacts_10: 'Suiza',
  contacts_11: 'Países Bajos',
  contacts_12: 'Eslovaquia',
  contacts_13: 'Eslovenia',
  contacts_14: 'Rumanía',
  contacts_15: 'Azerbaiyán',
  contacts_16: 'Ucrania',
  contacts_17: 'Kazajistán',
  contacts_18: 'Resto del Mundo',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'Pruebas UX',
  menu_6: 'Investigación de RRHH',
  menu_7: 'Investigación cualitativa',
  menu_8: 'Investigación cuantitativa',
  menu_9: 'Mystery shopping',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Monitoreo de precios',
  menu_14: 'Sobre nosotros',
  menu_15: 'Contactos',
  menu_16: 'Blog',
  menu_17: 'Métodos',
  menu_18: 'Productos',
  menu_19: 'Plataforma de compradores',
  menu_20: 'RSE',
  menu_21: 'Análisis de precios automotriz',
  menu_22: 'Auditoría de verificación de instalaciones',
  menu_23: 'Diseño de servicios',
  menu_24: 'CX',
  nps_passives: 'Pasivos',
  nps_detractors: 'Detractores',
  nps_promoters: 'Promotores',
  nps_a_score: 'una puntuación',
  area: 'Área',
  products_tabs_1:
    'Para lograr la máxima eficiencia, también llevamos a cabo de manera integral:',
  products_tabs_2: 'Productos',
  products_tabs_3:
    'La investigación cualitativa consiste en recopilar y analizar datos no numéricos para entender conceptos, opiniones o experiencias',
  products_tabs_4:
    'Los métodos de investigación cuantitativa son una medición objetiva y análisis numérico de los datos recopilados a través de encuestas, cuestionarios y sondeos',
  our_clients_1: 'Nuestros clientes',
  preview_cases_section_1: 'Casos',
  preview_cases_section_2:
    'Ve a los casos para leer más publicaciones interesantes',
  blog_1: 'Blog de contenido inspirador',
  blog_2: 'No hay publicaciones disponibles para tu solicitud',
  about: 'Sobre',
  why_us: '¿Por qué nosotros?',
  about_us: 'Sobre nosotros',
  key_features: 'Características clave',
  solutions: 'Soluciones',
  monitoring: 'Monitoreo',
  how: 'Cómo',
  how_it_works: 'Cómo funciona',
  when_to_use: 'Cuándo usar',
  mystery_shopping: 'Mystery shopping',
  voicer: 'Voz del cliente',
  wantent: 'Pruebas de contenido en video',
  play4sales: 'Play4Sales',
  price_monitoring: 'Monitoreo de precios',
  service_design: 'Diseño de servicios',
  qualitative_research: 'Investigación cualitativa',
  quantitative_research: 'Investigación cuantitativa',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Automotriz',
  hashtag_retayl: '#Retail',
  hashtag_all_posts: '#Todas_las_publicaciones',
  hashtag_feedback: '#Retroalimentación',
  hashtag_4Service_csr: '#4Service_RSE',
  hashtag_cases: '#Casos',
  hashtag_market_research: '#Investigación_de_mercado',
  more: 'Más',
  speak_to_an_expert: 'Habla con un experto',
  book_consultancy: 'Reserva una consultoría',
  read_all: 'Leer todo',
  find_out_more: 'Descubre más',
  address: 'Dirección',
  mon_fri: 'Lun-Vie',
  phone_number: 'Número de teléfono',
  flag: 'bandera',
  scroll_to_left: 'desplazar a la izquierda',
  online: 'en línea',
  tel: 'Tel',
  email: 'Correo electrónico',
  light_it_up: 'Ilumínalo',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Directora de desarrollo empresarial',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Gerente de proyectos sénior',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Gerente de desarrollo empresarial',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Director de operaciones',
  about_us_roles_1: 'Gestión',
  about_us_roles_2: 'Equipo global',
  about_us_roles_3: 'Propietarios de productos',
  ad_testing_1: 'Pruebas de anuncios',
  ad_testing_2:
    'Una campaña publicitaria es una gran inversión. Asegúrate de que tus anuncios sean poderosos y brinden el máximo retorno de inversión',
  ad_testing_3: 'bloqueo',
  ad_testing_4: 'Industrias a las que servimos',
  ad_testing_5:
    '4Service es un holding global de investigación de experiencia del cliente',
  ad_testing_6:
    '4Service es un holding global de investigación de marketing y CX. Ofrecemos servicios de recolección de datos expertos a una gran variedad de empresas',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Retail',
  ad_testing_9: 'Electrónica',
  ad_testing_10: 'Lujo/Premium',
  ad_testing_11: 'Comida y hospitalidad',
  ad_testing_12: 'Automotriz',
  ad_testing_13: 'Viajes y ocio',
  ad_testing_14: 'Moda',
  ad_testing_15: 'Bancos',
  ad_testing_16: 'Relojes y joyería',
  ad_testing_17: 'Hogar y electrodomésticos',
  ad_testing_18: 'Miembro de Esomar',
  ad_testing_19:
    'Hemos realizado investigaciones de pruebas de anuncios en publicidad para diversas empresas en diferentes áreas',
  ad_testing_20: 'Necesitas pruebas de anuncios si estás',
  ad_testing_21: 'Promoviendo un nuevo producto o servicio',
  ad_testing_22: 'Tocando un tema delicado',
  ad_testing_23: 'Tratando de alcanzar un nuevo mercado o público objetivo',
  ad_testing_24: 'Discutiendo varias opciones de diseño diferentes',
  ad_testing_25:
    'Necesitando una prueba de concepto para equipos directivos o inversores',
  ad_testing_26: 'Obtendrás respuestas a las preguntas',
  ad_testing_27:
    '¿Qué canales publicitarios son los más adecuados para estos anuncios?',
  ad_testing_28:
    '¿Estamos usando los visuales correctos para transmitir el mensaje?',
  ad_testing_29: '¿Resuenan tus textos y mensajes publicitarios?',
  ad_testing_30:
    '¿Qué canales publicitarios son los más adecuados para estos anuncios?',
  ad_testing_31: '¿Qué les llama la atención en primer lugar?',
  ad_testing_32:
    '¿La colocación y el diseño del anuncio son fáciles de entender?',
  ad_testing_33: 'Evaluamos',
  ad_testing_34: 'Branding',
  ad_testing_35:
    'cómo tu marca está vinculada a la publicidad y cuánto será recordada',
  ad_testing_36: 'Métricas clave',
  ad_testing_37: 'Integración',
  ad_testing_38: 'Reconocimiento',
  ad_testing_39: 'Ajuste',
  ad_testing_40: 'Creativo',
  ad_testing_41: 'Cómo tu anuncio destaca y capta la atención de las personas',
  ad_testing_42: 'Métricas clave',
  ad_testing_43: 'RECONOCIMIENTO',
  ad_testing_44: 'AGRADO',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTIVACIÓN',
  ad_testing_47: 'Experiencia del cliente, compromiso emocional',
  ad_testing_48:
    'Con el uso de la tecnología de inteligencia artificial y analistas expertos, analizamos las emociones reales de las personas y obtenemos información sobre sus percepciones de la publicidad',
  ad_testing_49: 'Calificación',
  ad_testing_50: 'Hombre',
  ad_testing_51: 'Mujer',
  ad_testing_52: 'Alto compromiso',
  ad_testing_53: 'Atención',
  ad_testing_54: '¿Qué le gustó y recordó a la audiencia?',
  ad_testing_55: 'Actriz',
  ad_testing_56: 'Igualdad',
  ad_testing_57: 'Fondo musical',
  ad_testing_58: 'Emociones positivas',
  ad_testing_59: 'Personas de diferentes razas y apariencias',
  ad_testing_60: 'Tomas al aire libre',
  ad_testing_61: '¿Qué no le gustó y recordó la audiencia?',
  ad_testing_62:
    'La voz del locutor no coincidía con lo que sucedía en la pantalla',
  ad_testing_63: 'Poca atención al producto',
  ad_testing_64: 'Demasiado dinámico',
  ad_testing_65: 'Difícil de comprender',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Impresiones',
  ad_testing_68: 'Muestras',
  ad_testing_69: 'Conversión',
  ad_testing_70: 'Medios',
  ad_testing_71:
    'Medimos la efectividad de una campaña publicitaria junto con la actividad mediática, calculando el impacto de las inversiones en medios en diferentes canales en los resultados monitoreados',
  ad_testing_72:
    'Realizamos investigaciones publicitarias en cualquier formato, plataforma o canal',
  ad_testing_73: 'contenido en video',
  ad_testing_74: 'exterior',
  ad_testing_75: 'anuncios de televisión',
  ad_testing_76: 'impresión',
  ad_testing_77: 'digital',
  ad_testing_78: 'campañas publicitarias',
  ad_testing_79: 'pantallas y anuncios en puntos de venta',
  ad_testing_80: 'animáticas',
  ad_testing_81:
    'Con nuestra ayuda, puedes probar tu publicidad en cualquier etapa',
  ad_testing_82: 'Concepto',
  ad_testing_83: '¿Qué ideas tienen mayor potencial?',
  ad_testing_84: 'Producción en etapa inicial',
  ad_testing_85: '¿Qué animáticas entrarán en producción?',
  ad_testing_86: 'Producción',
  ad_testing_87: '¿Cómo editar un video para que mantenga la atención?',
  ad_testing_88: 'Pre-medios',
  ad_testing_89:
    '¿Qué canales de comunicación funcionarán y qué resultados se pueden esperar?',
  ad_testing_90: 'Pruebas post-publicitarias',
  ad_testing_91: 'Producción',
  ad_testing_92:
    '¿Es cautivadora mi publicidad? ¿La efectividad de la creatividad disminuye con el tiempo?',
  ad_testing_93: 'Pre-medios',
  ad_testing_94:
    '¿Qué tan efectiva es mi publicidad en números? ¿Cómo se compara con la competencia?',
  ad_testing_95: 'Pruebas pre-publicitarias',
  ad_testing_96: 'Para proporcionarte datos de alta calidad, utilizamos',
  ad_testing_97: 'Metodología de investigación cuantitativa',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Metodología de investigación cualitativa',
  ad_testing_100: 'Grupos focales',
  ad_testing_101: 'IA',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Informes analíticos que recibes',
  ad_testing_104:
    'Nuestro equipo de analistas, expertos en medios, y mercadólogos prepararán y presentarán un informe personalizado con recomendaciones detalladas',
  ad_testing_age: 'edad',
  ad_testing_ad: 'Anuncio',
  automotive_industry_price_analysis_1:
    'Análisis de precios en la industria automotriz',
  automotive_industry_price_analysis_2:
    'El análisis competitivo de precios puede ayudar a prevenir la pérdida de beneficios, aumentar la cuota de mercado y monitorear tu posición en el mercado. ¡Permítenos ayudarte a seleccionar una estrategia competitiva exitosa!',
  automotive_industry_price_analysis_3: 'Solicitar una cotización',
  automotive_industry_price_analysis_4: 'Nuestra metodología',
  automotive_industry_price_analysis_5: 'Personas elegantes en salón de autos',
  automotive_industry_price_analysis_6:
    'El método de Mystery Shopping es el único enfoque que refleja con precisión una experiencia real del cliente y proporciona una visión creíble.',
  automotive_industry_price_analysis_7:
    'Ofrecemos cifras precisas y datos confiables y multilaterales',
  automotive_industry_price_analysis_8:
    'Tenemos cobertura global en todos los países del mundo',
  automotive_industry_price_analysis_9:
    'Nuestros recursos internos incluyen compradores misteriosos y auditores',
  automotive_industry_price_analysis_10:
    'Validamos la calidad de nuestros datos',
  automotive_industry_price_analysis_11: 'Nuestras ventajas:',
  automotive_industry_price_analysis_12:
    'Monitoreo de precios de modelos competitivos específicos (B2B y B2C)',
  automotive_industry_price_analysis_13: 'Monitoreo del nivel de descuento',
  automotive_industry_price_analysis_14:
    'Monitoreo de existencias de minoristas para tu marca y competidores',
  automotive_industry_price_analysis_15:
    'Monitoreo de precios de automóviles en e-commerce',
  automotive_industry_price_analysis_16: 'Análisis de comunicación de precios',
  automotive_industry_price_analysis_17:
    'Seguimiento de los costos de envío de minoristas',
  automotive_industry_price_analysis_18:
    'Monitoreo de promociones y ventas en minoristas',
  automotive_industry_price_analysis_19:
    'Determinación de montos de pagos mensuales',
  automotive_industry_price_analysis_20: 'Cálculo de pagos de leasing',
  automotive_industry_price_analysis_21:
    'Análisis de consideraciones de precios específicas para vehículos eléctricos (EVs)',
  automotive_industry_price_analysis_22:
    'Realización de comparaciones de precios directos al consumidor versus minoristas',
  automotive_industry_price_analysis_23:
    'Desarrollo de modelos de precios de suscripción',
  automotive_industry_price_analysis_24:
    '4Service es un holding global de experiencia del cliente (CX) con más de 20 años de experiencia',
  automotive_industry_price_analysis_25:
    'Usarás datos sobre la disposición de los consumidores a pagar por diferentes características para optimizar tu oferta e identificar las características más importantes para tu público objetivo.',
  automotive_industry_price_analysis_26:
    'El análisis de precios de 4Service en la industria automotriz incluye:',
  automotive_industry_price_analysis_27:
    'Los precios más bajos no siempre son la clave para la competitividad de las marcas de automóviles. Permítenos ayudarte a crear una estrategia de precios a largo plazo',
  automotive_industry_price_analysis_28:
    'de los clientes entienden que los precios están aumentando en todas las marcas',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'de los clientes dijeron que aún comprarían un coche que les guste, incluso si la marca les informara de un aumento próximo en el precio',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'de las personas consideran que el leasing y los pagos mensuales son el modelo más preferido para comprar un coche',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'de los concesionarios mencionaron la posibilidad de aumentos de precios durante el proceso de comunicación/venta',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Cifras clave',
  automotive_industry_price_analysis_37:
    'Averiguarás cómo fijar el precio de tu producto en relación con otros vehículos en la línea.',
  automotive_industry_price_analysis_38:
    'Usarás datos sobre la disposición de los consumidores a pagar por diferentes características para optimizar tu oferta e identificar las características más importantes para tu público objetivo.',
  automotive_industry_price_analysis_39:
    'de los clientes dijeron que aún comprarían un coche que les guste, incluso si la marca les informara de un aumento próximo en el precio',
  automotive_industry_price_analysis_40:
    'Serás capaz de determinar el pago mensual adecuado y las tasas de leasing.',
  automotive_industry_price_analysis_41:
    '¿Qué beneficios obtendrás para tu marca o concesionario?',
  automotive_industry_price_analysis_42: 'Envías una solicitud',
  automotive_industry_price_analysis_43:
    'Nuestro gerente se pone en contacto contigo para determinar los principales objetivos',
  automotive_industry_price_analysis_44:
    'Realizamos una investigación de mercado',
  automotive_industry_price_analysis_45:
    'Te proporcionamos regularmente informes y recomendaciones en formato en línea',
  automotive_industry_price_analysis_46: 'Coche con etiqueta de oferta',
  automotive_industry_price_analysis_47: '¡Lanzamos el proyecto en 1 día!',
  automotive_industry_price_analysis_48:
    'Ofrecemos un conjunto integral de informes que incluyen informes fotográficos, informes en PPT e informes en Excel, segmentados por el tipo de localidad',
  retail_audit_1: 'AUDITORÍA DE MARKETING EN CAMPO/auditoría de retail',
  retail_audit_2:
    'Recoge datos detallados sobre tu tienda o marca, analiza las barreras de crecimiento y crea estrategias efectivas para superarlas',
  retail_audit_3: 'Solicitar una cotización',
  retail_audit_4:
    '4Service realiza auditorías en tiendas en nombre de las marcas para evaluar la efectividad de sus estrategias promocionales, equipos de ejecución en campo y tácticas de marketing para alcanzar al mercado objetivo.',
  retail_audit_5:
    'Observamos meticulosamente la ejecución de campañas promocionales y aseguramos el cumplimiento de las directrices promocionales.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'agencias CX en Europa',
  retail_audit_8: 'años de experiencia',
  retail_audit_9: 'cobertura en países',
  retail_audit_10: '1,6 millones',
  retail_audit_11: 'evaluaciones al año',
  retail_audit_12: '¿Qué monitoreamos?',
  retail_audit_13: 'Presencia de materiales en punto de venta (POS)',
  retail_audit_14:
    'Presencia y activación de embajadores de marca, asegurando el cumplimiento del cronograma planificado',
  retail_audit_15:
    'Evaluación del compromiso de los embajadores de marca en campañas de promoción',
  retail_audit_16:
    'Evaluación de la interacción con el consumidor, asegurando el cumplimiento de las directrices promocionales y el mensaje de la marca',
  retail_audit_17: 'Medidas de prevención de fraude',
  retail_audit_18:
    'Tu negocio puede utilizar los resultados de una auditoría de marketing en campo para:',
  retail_audit_19: 'Evaluar el cumplimiento del presupuesto y los procesos',
  retail_audit_20: 'Comparar los resultados reales con los objetivos previstos',
  retail_audit_21: 'Mejorar el rendimiento de las campañas de marketing',
  retail_audit_22: 'Planificar estratégicamente para el futuro',
  retail_audit_23: 'Identificar oportunidades para reducir costos',
  retail_audit_24: 'Mejorar las ventas y las tasas de conversión',
  retail_audit_25: 'Optimizar el retorno de la inversión (ROI)',
  retail_audit_26:
    'ELLOS ELIGIERON A 4SERVICE COMO SU SOCIO DE INVERSIÓN COMERCIAL',
  retail_audit_27:
    'Un fabricante internacional líder de productos de tabaco se asoció con 4Service para mejorar sus marcas y optimizar su desempeño en tiendas. Nuestros equipos proporcionaron auditoría de marketing en campo e informes en vivo de la experiencia del consumidor.',
  retail_audit_28:
    'aumento en la precisión de la planificación de la agencia de marketing en campo',
  retail_audit_29: 'aumento en la entrega del mensaje de la marca',
  retail_audit_30:
    'aumento en el compromiso de los embajadores de marca (activación del consumidor)',
  retail_audit_31:
    'aumento en la precisión de la recopilación de datos y el cumplimiento del RGPD',
  retail_audit_32: '¿Qué ideas valiosas solemos encontrar?',
  retail_audit_33:
    'La falta de coherencia o incluso conflictos entre los términos del SLA genera cargos no aprobados significativos al cliente',
  retail_audit_34:
    'Cobro incorrecto de tarifas debido a la confusión entre los tipos de servicios proporcionados',
  retail_audit_35:
    'El servicio en campo no se realiza, incluido el hecho de que no se asigna personal a la ubicación planificada | POS',
  retail_audit_36:
    'Cargos realizados sobre una estimación en lugar de datos reales y no reconciliados',
  retail_audit_37:
    'Aplicación incorrecta de las directrices promocionales proporcionando datos fraudulentos',
  retail_audit_38: '¿Cómo trabajamos?',
  retail_audit_39:
    'Nuestro auditor visita los puntos de venta (POS) designados según el plan de activación de la agencia.',
  retail_audit_40:
    'Evalúan los indicadores clave de rendimiento (KPI) según lo especificado en el informe, como la presencia en POS, el compromiso de los embajadores de marca, el cumplimiento de las directrices promocionales y las medidas de prevención de fraude.',
  retail_audit_41: 'Validación:',
  retail_audit_42: 'Recopilamos y consolidamos los datos obtenidos.',
  retail_audit_43: 'Se redacta un informe completo.',
  retail_audit_44:
    'Nuestro equipo de validación revisa minuciosamente todas las encuestas para garantizar su precisión, incluido el cumplimiento del informe del proyecto y cualquier comentario adicional.',
  retail_audit_45: 'Sistema de Reportes Online:',
  retail_audit_46:
    'Los cuestionarios completados y validados se cargan en nuestro portal en línea dentro de las 36 horas.',
  retail_audit_47:
    'Los clientes tienen acceso a un portal de cuenta personal, lo que les permite monitorear el progreso en cualquier momento.',
  retail_audit_48:
    'Se proporciona un informe final que contiene resultados y recomendaciones.',
  retail_audit_49: 'Habla con un experto',
  retail_audit_50: 'Nuestras fortalezas:',
  facility_check_audit_1: 'Instalaciones',
  facility_check_audit_2: 'Auditoría de Verificación',
  facility_check_audit_3: 'Eleva la Experiencia de Marca en Cada Detalle',
  facility_check_audit_4: 'Explora Nuestro Enfoque',
  facility_check_audit_5: 'Acerca de',
  facility_check_audit_6: 'Quiénes somos',
  facility_check_audit_7:
    'Como un holding global de experiencia del cliente, 4Service ofrece un conjunto integral de servicios para ayudar a las marcas a crear y entregar experiencias excepcionales para sus clientes.',
  facility_check_audit_8:
    'Nuestro servicio de Auditoría de Verificación de Instalaciones',
  facility_check_audit_9:
    'está diseñado para ayudarte a garantizar que tus ubicaciones físicas y la presencia de tu marca en las tiendas minoristas cumplan con los estándares de la marca y creen una impresión positiva para los clientes.',
  facility_check_audit_10: 'Soluciones 1',
  facility_check_audit_11: 'Auditoría de Verificación de Instalaciones',
  facility_check_audit_12:
    'es un examen minucioso que evalúa la alineación de tus puntos de venta o stands con los estándares de tu marca. No solo verificamos los atributos físicos, sino que también medimos cómo estos elementos influyen en la percepción que tienen los clientes sobre tu marca.',
  facility_check_audit_13: 'Dos soluciones',
  facility_check_audit_14: 'Dos Métodos de Implementación',
  facility_check_audit_15: 'Método',
  facility_check_audit_16:
    'Auditoría de Verificación de Instalaciones por Auditores Expertos',
  facility_check_audit_17: 'Validación de la Auditoría de Instalaciones',
  facility_check_audit_18: 'Informes',
  facility_check_audit_19:
    'Obtendrás informes, completos con evidencia visual y estadísticas, accesibles dentro de las 48 horas',
  facility_check_audit_20:
    'Una auditoría de verificación de instalaciones bien ejecutada puede proporcionar una serie de beneficios para tu marca, incluyendo:',
  facility_check_audit_21: 'Mejora en la conversión de clientes:',
  facility_check_audit_22:
    'Una experiencia positiva del cliente puede llevar a un aumento en las ventas y en la repetición del negocio. Nuestras auditorías pueden ayudarte a identificar y abordar cualquier área que pueda estar afectando la satisfacción del cliente.',
  facility_check_audit_23: 'Mejora de la imagen de marca:',
  facility_check_audit_24:
    'Tus ubicaciones físicas son un punto clave de contacto para los clientes y pueden desempeñar un papel significativo en la configuración de la imagen de tu marca. Nuestras auditorías pueden ayudarte a garantizar que tus ubicaciones sean coherentes con los valores de tu marca y creen una impresión positiva en los clientes.',
  facility_check_audit_25: '¿Por qué nosotros?',
  facility_check_audit_26:
    '¿Por qué es importante la Auditoría de Verificación de Instalaciones?',
  facility_check_audit_27:
    'Una auditoría de verificación de instalaciones bien ejecutada puede proporcionar una serie de beneficios para tu marca, incluyendo:',
  facility_check_audit_28: 'Mejora de la imagen de marca:',
  facility_check_audit_29:
    'Tus ubicaciones físicas son un punto clave de contacto para los clientes y pueden desempeñar un papel significativo en la configuración de la imagen de tu marca. Nuestras auditorías pueden ayudarte a garantizar que tus ubicaciones sean coherentes con los valores de tu marca y creen una impresión positiva en los clientes.',
  facility_check_audit_30: 'Mejora en la conversión de clientes:',
  facility_check_audit_31:
    'Una experiencia positiva del cliente puede llevar a un aumento en las ventas y en la repetición del negocio. Nuestras auditorías pueden ayudarte a identificar y abordar cualquier área que pueda estar afectando la satisfacción del cliente.',
  facility_check_audit_32: '¿Quién necesita',
  facility_check_audit_33:
    '¿Quién necesita la Auditoría de Verificación de Instalaciones?',
  facility_check_audit_34:
    'La Auditoría de Verificación de Instalaciones es un servicio valioso para cualquier marca con ubicaciones físicas y presencia física. Es especialmente importante para las marcas que desean:',
  facility_check_audit_35:
    'Asegurar el cumplimiento con los estándares de la marca:',
  facility_check_audit_36:
    'Nuestras auditorías pueden ayudarte a identificar cualquier área donde tus ubicaciones puedan estar desviándose de los estándares de tu marca.',
  facility_check_audit_37: 'Mejorar la experiencia del cliente:',
  facility_check_audit_38:
    'Nuestras auditorías pueden ayudarte a identificar oportunidades para mejorar la experiencia del cliente en tus ubicaciones.',
  facility_check_audit_39: 'Construir una marca más fuerte:',
  facility_check_audit_40:
    'Nuestras auditorías pueden ayudarte a garantizar que tus ubicaciones físicas estén creando una impresión positiva para los clientes.',
  facility_check_audit_41:
    'Tu empleado o un comprador misterioso toma una foto del lugar.',
  facility_check_audit_42:
    'Luego, completan un cuestionario y adjuntan la foto.',
  facility_check_audit_43:
    'El validador recibe la foto y la verifica para comprobar que cumpla con los estándares.',
  facility_check_audit_44:
    'Auditoría de Verificación de Instalaciones por Auditores Expertos',
  facility_check_audit_45: 'Etapa de Preparación:',
  facility_check_audit_46:
    'Aprobación colaborativa del calendario de auditoría',
  facility_check_audit_47:
    'Se informa y prepara a los distribuidores sobre la auditoría próxima',
  facility_check_audit_48: 'Entrenamiento del Auditor:',
  facility_check_audit_49:
    'Nuestros experimentados formadores de 4Service capacitan a los auditores con las pautas de la marca',
  facility_check_audit_50: 'Etapa de Auditoría de Instalaciones:',
  facility_check_audit_51:
    'Los auditores, con cartas de autorización especiales, visitan las ubicaciones',
  facility_check_audit_52:
    'Examen minucioso de cada punto, siguiendo meticulosamente las pautas',
  facility_check_audit_53:
    'Documentación visual, junto con evaluaciones de los requisitos de la sede central',
  facility_check_audit_54: 'Informe PPT Conclusivo',
  facility_check_audit_55:
    'Un informe completo en PowerPoint que detalla los hallazgos',
  facility_check_audit_56:
    'Presentación inmersiva en línea o fuera de línea de los resultados auditados',
  facility_check_audit_57: 'Validación de la Auditoría de Instalaciones',
  facility_check_audit_58: 'Pasos:',
  facility_check_audit_59: 'Etapa de Auditoría de Instalaciones:',
  facility_check_audit_60:
    'Los representantes de la marca utilizan la aplicación de 4Service para capturar fotos in situ',
  facility_check_audit_61:
    'Cumplimiento con criterios visuales y descriptivos estrictos',
  facility_check_audit_62: 'Etapa de Validación:',
  facility_check_audit_63:
    'Validadores diligentes revisan minuciosamente cada informe',
  facility_check_audit_64:
    'Comparación exhaustiva de las fotos con los estándares de la marca',
  facility_check_audit_65:
    'Se integran anotaciones y observaciones en el informe para mayor claridad',
  facility_check_audit_66:
    'Comunicación directa con los distribuidores para aclaraciones, si es necesario',
  facility_check_audit_67: 'Fase de Informe:',
  facility_check_audit_68:
    'Informes completos con evidencia visual y estadísticas, accesibles en dos días',
  facility_check_audit_69: 'Contáctanos hoy',
  cx_solution_1: 'No solo encontramos ideas',
  cx_solution_2: 'Llevamos su negocio a nuevas alturas',
  cx_solution_3:
    'Expertos con más de 20 años de experiencia en atención al cliente descubren soluciones complejas y le brindan un plan de acción específico que contribuye a su crecimiento',
  cx_solution_4:
    'Descubra un conjunto único de servicios en una solución preparada para el futuro',
  cx_solution_5: 'Solución CX todo en uno',
  cx_solution_6: 'Prueba de anuncios',
  cx_solution_7: 'IA y Neuromarketing',
  cx_solution_8: 'Voz del cliente',
  cx_solution_9: 'Investigación de escritorio',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Mystery shopping',
  cx_solution_12: 'Consultoría de CX',
  cx_solution_13: 'Escucha de redes sociales',
  cx_solution_14: 'Investigación cuantitativa y cualitativa',
  cx_solution_15: 'Diseño de servicios',
  cx_solution_16: 'Estrategia de marketing',
  cx_solution_17: 'Pruebas de UX/UI',
  cx_solution_18: 'Perspectivas de la audiencia',
  cx_solution_19: 'Nos sentimos honrados de contribuir a su éxito',
  cx_solution_20:
    'Personalizamos y combinamos herramientas y servicios según el propósito y los objetivos de su negocio para ofrecer resultados personalizados',
  cx_solution_21: 'Investigación integral',
  cx_solution_22:
    'Potencie su toma de decisiones, brindando una ventaja competitiva en un mercado centrado en los datos.',
  cx_solution_23:
    'La investigación cualitativa es la recopilación y el análisis de datos no numéricos para comprender conceptos, opiniones o experiencias',
  cx_solution_24:
    'Los métodos de investigación cuantitativa son una medición objetiva y un análisis numérico de los datos recopilados a través de encuestas, cuestionarios y encuestas',
  cx_solution_25:
    'Utilizamos un conjunto distinto de herramientas según sus requisitos específicos',
  cx_solution_26: 'Herramientas',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Las entrevistas web asistidas por computadora son una herramienta vital en nuestro arsenal, lo que nos permite realizar encuestas y recopilar datos a través de plataformas en línea con la ayuda de software especializado, asegurando una recopilación de datos eficiente y precisa para las necesidades de investigación de mercado de nuestros clientes',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Las entrevistas telefónicas asistidas por computadora son un método que utilizamos para realizar encuestas y recopilar datos valiosos por teléfono, empleando software especializado para agilizar el proceso y garantizar la precisión en nuestros esfuerzos de investigación de mercado',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'El cara a cara es fundamental en nuestro enfoque, facilitando interacciones directas y compromisos personalizados con los clientes, fomentando así la confianza, la comprensión y la comunicación efectiva esenciales para construir relaciones duraderas e impulsar el éxito empresarial',
  cx_solution_33: 'Investigación de escritorio',
  cx_solution_34:
    'La investigación de escritorio implica la recopilación y el análisis exhaustivos de datos, sirviendo como un paso fundamental en nuestro proceso de investigación para recopilar información y orientar la toma de decisiones estratégicas de nuestros clientes',
  cx_solution_35: 'Análisis FODA',
  cx_solution_36:
    'El análisis FODA es una herramienta de evaluación estratégica que ayuda a nuestros clientes a identificar fortalezas y debilidades internas, así como oportunidades y amenazas externas, proporcionando valiosos conocimientos para informar la toma de decisiones y desarrollar estrategias comerciales efectivas',
  cx_solution_37: 'Investigación etnográfica',
  cx_solution_38:
    'Estudiar personas y culturas a través de la inmersión y la observación en sus entornos naturales, a menudo conduciendo a profundos conocimientos sobre comportamientos, creencias y dinámicas sociales',
  cx_solution_39: 'Entrevistas en profundidad',
  cx_solution_40:
    'Las entrevistas en profundidad son un método de investigación cualitativa que empleamos, que implica discusiones exhaustivas, uno a uno, con los participantes para profundizar en sus perspectivas, experiencias y opiniones, proporcionando ricos conocimientos y comprensión para los objetivos de investigación y los procesos de toma de decisiones de nuestros clientes',
  cx_solution_41: 'Sesiones de ideación',
  cx_solution_42:
    'Las sesiones de ideación son sesiones de lluvia de ideas colaborativas que facilitamos, reuniendo diversas perspectivas y experiencia para generar ideas creativas y soluciones para los desafíos u oportunidades de nuestros clientes, fomentando la innovación e impulsando iniciativas estratégicas hacia adelante',
  cx_solution_43: 'Personas compradoras',
  cx_solution_44:
    'Las personas compradoras son perfiles detallados que desarrollamos, representando a los clientes objetivo de nuestros clientes en función de la investigación y el análisis de datos, lo que nos permite comprender mejor sus necesidades, preferencias y comportamientos, y adaptar nuestras estrategias y comunicaciones para atraerlos y resonar con ellos de manera efectiva, impulsando finalmente el éxito empresarial',
  cx_solution_45: 'Mystery Shopping',
  cx_solution_46:
    'Mystery Shopping es un servicio estratégico que ofrecemos, midiendo y monitoreando las interacciones entre un cliente y una empresa durante un escenario predefinido',
  cx_solution_47: 'Acompañar la verificación',
  cx_solution_48:
    'Acompañar la verificación es una forma especializada de mystery shopping en la que nuestros evaluadores acompañan a los clientes durante toda su experiencia de servicio, proporcionando información completa sobre todos los aspectos del viaje del cliente, lo que permite a nuestros clientes comprender en profundidad su prestación de servicios y realizar mejoras informadas para mejorar la satisfacción y lealtad general del cliente',
  cx_solution_49: 'Análisis de recopilación de comentarios',
  cx_solution_50:
    'El análisis de recopilación de comentarios es un proceso crítico que llevamos a cabo, que implica la recopilación y el análisis sistemáticos de los comentarios de los clientes de diversos canales, como encuestas, reseñas y redes sociales, lo que nos permite extraer información valiosa, identificar tendencias y hacer recomendaciones basadas en datos para que nuestros clientes mejoren sus productos, servicios y experiencia general del cliente',
  cx_solution_51: 'Grupos focales',
  cx_solution_52:
    'Los grupos focales son sesiones de investigación dinámicas que facilitamos, reuniendo a un grupo diverso de participantes para discutir temas o productos específicos en profundidad, lo que nos permite recopilar información cualitativa, descubrir actitudes, preferencias y percepciones, y explorar ideas y conceptos, en última instancia, informando los procesos de toma de decisiones y desarrollo de estrategias de nuestros clientes',
  cx_solution_53:
    '20 años de creación de experiencias excepcionales para los clientes',
  cx_solution_54:
    'Asegúrese de que su mensaje cautive y resuene en su audiencia.',
  cx_solution_55:
    'Estamos dedicados a ofrecer resultados que superen sus expectativas',
  cx_solution_56:
    'Descubrimos soluciones complejas que contribuyen a su crecimiento',
  cx_solution_57: 'Eleve su experiencia del cliente con nuestra metodología',
  cx_solution_58:
    'El mapeo del viaje del cliente consiste en definir una audiencia objetivo, comprender sus necesidades y entorno, y luego crear un plan para brindar una experiencia atractiva',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'La investigación de seguimiento de la salud de la marca puede medir cómo se está desempeñando su marca en cuanto a conocimiento y uso, posicionamiento de la marca y rendimiento de la marca',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'El índice de satisfacción del cliente es una puntuación que indica cuán satisfechos están sus clientes con los productos/servicios de la empresa.',
  cx_solution_63:
    'El monitoreo de precios es el proceso de recopilación, procesamiento y análisis de los precios de la competencia',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'La puntuación del Net Promoter Score es el estándar de oro de las métricas de experiencia del cliente, basado en una pregunta: ¿Qué tan probable es que recomendaría la organización/producto/servicio?',
  cx_solution_66: 'Investigación de recursos humanos',
  cx_solution_67:
    'La investigación de recursos humanos se utiliza para evaluar las prácticas y el desempeño de los recursos humanos y ofrece un análisis detallado del desarrollo y la gestión actuales',
  cx_solution_68:
    'Las pruebas de usabilidad son la forma en que las personas reales interactúan con un sitio web, una aplicación u otro producto y observan su comportamiento y reacciones',
  cx_solution_69: 'Otro',
  cx_solution_70:
    'Los expertos seleccionan la mejor metodología para su negocio',
  cx_solution_71: 'Empecemos',
  cx_solution_72: '1 - REUNIÓN DE INICIO',
  cx_solution_73:
    'Durante la reunión de inicio, identificamos y nos enfocamos en tareas clave. Resultado: comprensión del objetivo, tareas de investigación, plazos, métodos y áreas de responsabilidad.',
  cx_solution_74: '2 - DESARROLLO DEL CONCEPTO',
  cx_solution_75:
    'El departamento de análisis de 4Service agrega datos, coordina la estructura del informe. Luego presentamos los resultados al cliente, complementados con ideas y recomendaciones para la implementación.',
  cx_solution_76: '3 - TRABAJO DE CAMPO',
  cx_solution_77:
    'Para realizar la investigación, reclutamos participantes, realizamos entrevistas y otras etapas básicas de investigación. El cliente recibe entrevistas transcritas de audio y video, así como resúmenes concisos.',
  cx_solution_78: '4 - ANÁLISIS',
  cx_solution_79:
    'El departamento de análisis de 4Service agrega datos, coordina la estructura del informe. Luego presentamos los resultados al cliente, complementados con ideas y recomendaciones para la implementación.',
  cx_solution_80: '5 - TALLER',
  cx_solution_81:
    'Realizar una discusión con el cliente sobre el plan de implementación',
  cx_solution_82: 'Obtenga más información sobre nuestras mejores prácticas',
  cx_solution_83:
    'El Mapa del Viaje del Cliente permite un examen rápido de todos los canales de comunicación, la identificación de posibles deficiencias en los puntos de contacto cruciales y una comprensión completa de las áreas que pueden desviarse del curso planificado.',
  cx_solution_84: '¿Cuándo usarlo?',
  cx_solution_85:
    'Cree una campaña publicitaria personalizada basada en datos reales sobre grupos de audiencia',
  cx_solution_86:
    'Descubra los desencadenantes de diferentes segmentos de la audiencia objetivo y satisfaga sus necesidades',
  cx_solution_87:
    'Dirigirse con precisión a cada grupo y obtener la máxima conversión',
  cx_solution_88:
    'La técnica de verificación de acompañamiento permite evaluar las posibilidades de formas óptimas de lograr sus objetivos.',
  cx_solution_89: 'Probar un nuevo sitio web o producto antes del lanzamiento',
  cx_solution_90:
    'Verificar los procesos desarrollados antes de implementar cambios',
  cx_solution_91:
    'Evaluar el sitio web o producto en medio de la disminución de las ventas',
  cx_solution_92:
    'Lanzar un nuevo producto adaptado a las preferencias de los clientes',
  cx_solution_93:
    'Estudiar empresas competidoras para asegurar una posición líder en el mercado',
  cx_solution_94:
    'Ofrecemos un enfoque integral de 360° para crear un modelo de servicio orientado al cliente.',
  cx_solution_95: 'Capacitación y desarrollo del personal',
  cx_solution_96: 'Mejorar los estándares de servicio',
  cx_solution_97: 'Aumento del flujo y las ventas de clientes',
  cx_solution_98: 'Establecer KPI y sistema de motivación para el personal',
  cx_solution_99: 'Descubre nuestras historias de éxito',
  cx_solution_100: 'Automotriz',
  cx_solution_101: 'Tabaco',
  cx_solution_102: 'Minorista',
  cx_solution_103: 'Fase cualitativa',
  cx_solution_104: 'Fase cuantitativa (segmentación)',
  cx_solution_105: 'Grupos focales',
  cx_solution_106: 'Cobertura de audiencia en línea en todo el mundo',
  cx_solution_107: 'Mezcla de técnicas durante las entrevistas',
  cx_solution_108: 'Solo preguntas abiertas',
  cx_solution_109: 'Soporte al encuestado 24/7',
  cx_solution_110:
    'Preservación de detalles importantes y una imagen real del camino',
  cx_solution_111: 'Investigación de mercado',
  cx_solution_112: 'Diagnóstico de la empresa y la competencia',
  cx_solution_113:
    'Modelos de servicio actualizados y nuevos procesos comerciales',
  cx_solution_114: 'Promoción de servicio orientado al cliente',
  cx_solution_115:
    'Implementación de cambios y control sobre el desarrollo de estrategias de servicio',
  usage_attitude_1: 'Uso y actitud (U&A)',
  usage_attitude_2:
    'Obtenga información completa sobre cómo la audiencia objetivo percibe, utiliza y se involucra con su marca.',
  usage_attitude_3: 'Hable con un experto',
  usage_attitude_4: 'Acerca de',
  usage_attitude_5:
    '<b>Explore los hábitos de los consumidores</b> para descubrir preferencias e influencias en profundidad',
  usage_attitude_6:
    '<b>Descubra el "por qué"</b> detrás de las elecciones de los consumidores con nuestro análisis detallado',
  usage_attitude_7:
    '<b>Comprender cómo</b> los clientes interactúan con sus productos para obtener información estratégica',
  usage_attitude_8:
    '<b>Obtenga información sobre</b> el comportamiento, los hábitos y las preferencias de los consumidores para tomar decisiones informadas',
  usage_attitude_9: 'Experiencia',
  usage_attitude_10:
    'Los expertos de 4Service con más de 20 años de experiencia en atención al cliente crean soluciones únicas que ayudan a hacer crecer su negocio',
  usage_attitude_11: 'Contáctenos',
  usage_attitude_12: 'Para quién',
  usage_attitude_13: '¿Quién se beneficia del uso y la actitud de la marca?',
  usage_attitude_14:
    'Varias industrias y sectores de servicios están interesados ​​en:',
  usage_attitude_15:
    '<b>Reconociendo</b> las demandas y puntos débiles de los clientes',
  usage_attitude_16:
    '<b>Identificando</b> oportunidades para impulsar el consumo de los clientes',
  usage_attitude_17:
    '<b>Desarrollando</b> nuevas categorías de productos o estrategias de marketing',
  usage_attitude_18:
    '<b>Obteniendo</b> una comprensión profunda del panorama competitivo',
  usage_attitude_19:
    'La metodología se selecciona individualmente según la solicitud del cliente',
  usage_attitude_20:
    'La <b>investigación cualitativa</b> implica recopilar y analizar datos no numéricos para comprender conceptos, opiniones o experiencias.',
  usage_attitude_21:
    'Los métodos de <b>investigación cuantitativa</b> implican medir objetivamente y analizar numéricamente los datos obtenidos a través de encuestas, cuestionarios y encuestas.',
  usage_attitude_22:
    '<b>Después de recopilar los datos</b>, los expertos analizan la información y sacan conclusiones para brindarle las mejores recomendaciones.',
  usage_attitude_23: 'Quién necesita',
  usage_attitude_24: 'Tu historia de éxito comienza aquí',
  usage_attitude_25:
    '<b>Recibirás una investigación</b> con valiosos conocimientos que potencian la toma de decisiones estratégicas, asegurando que las acciones se basen en una comprensión profunda del comportamiento del consumidor.',
  usage_attitude_26:
    '<b>La investigación proporciona una base sólida para refinar</b> y optimizar las estrategias de marca, alineándolas más estrechamente con las preferencias y actitudes de los consumidores.',
  usage_attitude_27: 'Soluciones',
  usage_attitude_28: 'Explore numerosas oportunidades con nuestras soluciones',
  usage_attitude_29: 'Preferencia de producto dentro de la categoría',
  usage_attitude_30:
    'Identifique las principales preferencias de los consumidores en diversas categorías, como alimentos, bebidas, ropa, electrónica y más.',
  usage_attitude_31: 'Comportamiento de consumo / uso',
  usage_attitude_32:
    'Analice la frecuencia de consumo o uso de productos/servicios específicos e identifique las ocasiones y momentos asociados con su uso.',
  usage_attitude_33: 'Comportamiento de compra',
  usage_attitude_34:
    'Examine los canales que los clientes utilizan para sus compras e identifique las fuentes de información en las que confían.',
  usage_attitude_35: 'Motivaciones y necesidades de los consumidores',
  usage_attitude_36:
    'Examine las motivaciones y barreras que influyen en el consumo. Evalúe los factores que influyen en las decisiones de compra de los consumidores. Explore la percepción de la categoría de producto y analice cómo se percibe el precio.',
  usage_attitude_37: 'Nuestro enfoque',
  usage_attitude_38: 'Estudio de uso y actitud de la marca',
  usage_attitude_39: 'Información',
  usage_attitude_40:
    'Realizar encuestas utilizando métodos tanto cuantitativos como cualitativos',
  usage_attitude_41: 'Comenzar',
  usage_attitude_42:
    'Establecer objetivos y determinar métodos basados en solicitudes individuales',
  usage_attitude_43: 'Análisis',
  usage_attitude_44: 'Técnicas sofisticadas empleadas por nuestros expertos',
  usage_attitude_45:
    'Utilizamos un conjunto distinto de herramientas según sus requisitos específicos',
  usage_attitude_46: 'CATI',
  usage_attitude_47:
    'Las entrevistas telefónicas asistidas por computadora son un método que utilizamos para realizar encuestas y recopilar datos valiosos por teléfono, empleando software especializado para agilizar el proceso y garantizar la precisión en nuestros esfuerzos de investigación de mercado.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Las entrevistas web asistidas por computadora son una herramienta vital en nuestro arsenal, lo que nos permite realizar encuestas y recopilar datos a través de plataformas en línea con la ayuda de software especializado, asegurando una recopilación de datos eficiente y precisa para las necesidades de investigación de mercado de nuestros clientes.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Las entrevistas personales asistidas por computadora son un método que utilizamos para optimizar los procesos de recopilación de datos, hacer que las entrevistas sean más fluidas y garantizar resultados precisos e informativos para las necesidades de su negocio.',
  usage_attitude_52: 'Grupos focales',
  usage_attitude_53:
    'Los grupos focales son sesiones de investigación dinámicas que facilitamos, reuniendo a un grupo diverso de participantes para discutir temas o productos específicos en profundidad, lo que nos permite recopilar información cualitativa, descubrir actitudes, preferencias y percepciones, y explorar ideas y conceptos, en última instancia, informando los procesos de toma de decisiones y desarrollo de estrategias de nuestros clientes.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'El cara a cara es fundamental en nuestro enfoque, facilitando interacciones directas y compromisos personalizados con los clientes, fomentando así la confianza, la comprensión y la comunicación efectiva esenciales para construir relaciones duraderas e impulsar el éxito empresarial.',
  usage_attitude_56: 'Investigación etnográfica',
  usage_attitude_57:
    'Estudiar personas y culturas a través de la inmersión y la observación en sus entornos naturales, a menudo conduciendo a profundos conocimientos sobre comportamientos, creencias y dinámicas sociales.',
  thank_you_page:
    '¡Gracias por su tiempo! Nuestro gerente se pondrá en contacto con usted pronto.',
  back_main: 'Volver a la principal',
  opportunities_1: 'Más información sobre nuestros casos',
  opportunities_2: 'Descubre',
  opportunities_3: 'para ti mismo',
  opportunities_4: 'oportunidades',
  opportunities_5:
    'de negocios internacionales junto con el holding de investigación 4Service',
  opportunities_6:
    '¿Con qué <span>productos</span> podemos fortalecer su negocio?',
  opportunities_7: 'CX',
  opportunities_8: 'Soluciones personalizadas integrales para empresas',
  opportunities_9: 'Todos los tipos de Mystery Shopping',
  opportunities_10:
    'Implementamos mystery employee, mystery caller, mystery shopper de lujo, mystery shopper automotriz y otros tipos',
  opportunities_11: 'Soluciones de TI personalizadas para empresas',
  opportunities_12:
    'Soluciones de TI propias con IA, plataformas para análisis de feedback y transcripción de llamadas',
  opportunities_13: 'Investigación de mercado',
  opportunities_14:
    'Para cualquier solicitud empresarial (Seguimiento de la salud de la marca, Segmentación, NPS, eNPS, CJM, Pruebas de productos y publicidad, etc.)',
  opportunities_15: 'Auditorías',
  opportunities_16:
    'Realizamos diversas auditorías de servicios, así como monitoreo de precios (sin conexión, para nichos específicos, etc.)',
  opportunities_17: 'Diseño de servicios',
  opportunities_18:
    '4Service - socios certificados de DesignThinkers Academy, brindando capacitación corporativa para equipos y realizando regularmente talleres para empresas',
  opportunities_19:
    'Compartimos nuestra experiencia, pero <span>respetamos y no interrumpimos</span> su proceso comercial.',
  opportunities_20:
    'Conocemos las dificultades que enfrentan las empresas en su viaje de desarrollo y sabemos cómo superar estas barreras gracias a la experiencia exitosa internacional. Obtiene no solo experiencia sino también recursos.',
  opportunities_21: '¡Lo invitamos a cooperar!',
  opportunities_22:
    'Ofrecemos un <span>programa de asociación</span> para su negocio',
  opportunities_23:
    'Bajo la reconocida marca internacional de 4Service, puede influir positivamente en la atracción de nuevos clientes.',
  opportunities_24:
    'Nuestros socios reciben soporte integral, que incluye capacitación, materiales de marketing y asistencia operativa.',
  opportunities_25:
    'La asociación con nosotros hará realidad sus planes sin interrumpir su proceso comercial actual.',
  opportunities_26: 'Nuestras <span>oficinas</span>',
  opportunities_27: 'Nuestros clientes',
  opportunities_28: 'Tenemos más de 20 años de experiencia en CX',
  opportunities_29: 'Países cubiertos',
  opportunities_30: 'mil',
  opportunities_31: 'Proyectos únicos',
  opportunities_32: 'millones',
  opportunities_33: 'Visitas de compradores misteriosos',
  opportunities_34:
    'está incluida en las 10 principales agencias de CX europeas',
  opportunities_35: 'Tus oportunidades con 4Service',
  opportunities_36: 'Aumento de ganancias',
  opportunities_37: 'Tendrá acceso a pedidos internacionales',
  opportunities_38: 'Enfoque único de CX',
  opportunities_39: 'Soporte de expertos con 20 años de experiencia',
  opportunities_40: 'Soporte',
  opportunities_41: 'Nuestro equipo lo apoyará para lograr resultados',
  opportunities_42: 'Ventas',
  opportunities_43: 'Le ayudaremos a configurar un sistema de ventas',
  opportunities_44: 'Acceso al software',
  opportunities_45: 'Tendrá acceso a todas las herramientas de TI certificadas',
  opportunities_46: 'Capacitación',
  opportunities_47:
    'No solo capacitaremos a su personal, sino que también continuaremos brindando servicios de consultoría',
  opportunities_48: '¿Quién es adecuado para la asociación?',
  opportunities_49:
    '¿Su negocio está relacionado con la investigación de mercado?',
  opportunities_50: '¿O está planeando probarse en un nuevo campo?',
  opportunities_51: 'Estás planeando:',
  opportunities_52: 'Implementar innovaciones',
  opportunities_53: 'Trabajar con marcas internacionales',
  opportunities_54: 'Ampliar su cartera de productos',
  opportunities_55: 'Fortalecer su posición en el mercado',
  opportunities_56: 'Capacitar a sus empleados',
  opportunities_57: 'Escalar su negocio',
  opportunities_58: 'Nuestros recursos = Sus recursos',
  opportunities_59:
    'Dada nuestra amplia experiencia, estamos listos para compartirla con usted en la primera etapa de cooperación',
  opportunities_60: 'Análisis de datos:',
  opportunities_61: 'Equipo de análisis internacional',
  opportunities_62: 'Métodos de procesamiento de datos - SPSS, Power BI',
  opportunities_63:
    'Metodologías innovadoras y una amplia gama de metodologías',
  opportunities_64: 'Informes en cualquier formato',
  opportunities_65: 'Software:',
  opportunities_66: 'Desarrollos propios de TI personalizados',
  opportunities_67: 'DigSee Sure para CAPI',
  opportunities_68: 'SurveyMonkey para CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (centro de llamadas)',
  opportunities_71: 'Soporte de marketing:',
  opportunities_72: 'Soporte de relaciones públicas',
  opportunities_73: 'Soporte en promoción y creación de contenido',
  opportunities_74: 'Configuración de procesos de generación de leads',
  opportunities_75: 'Soporte de TI:',
  opportunities_76:
    'Soporte técnico con instalación, configuración y uso de nuestro software.',
  opportunities_77: 'Asistencia remota',
  opportunities_78: 'Control de seguridad',
  opportunities_79: 'Soporte operativo:',
  opportunities_80: 'Soporte en la configuración de procesos de campo',
  opportunities_81: 'Sistema antifraude',
  opportunities_82: 'Reclutamiento de cualquier complejidad',
  opportunities_83:
    'Gestión operativa del equipo del proyecto y los departamentos de campo',
  opportunities_84: 'Control de calidad de la contratación',
  opportunities_85: 'UpWork',
  opportunities_86: '¿Qué bonos obtendrás?',
  opportunities_87: 'Aumento de la base de clientes',
  opportunities_88: 'Fortalecimiento de la imagen de marca',
  opportunities_89: 'Diversificación de los riesgos de pérdida de clientes',
  opportunities_90: 'Optimización de procesos tecnológicos',
  opportunities_91: 'Experiencia internacional',
  opportunities_92: 'Más ventas a los clientes actuales',
  main_67:
    'El análisis de precios competitivo optimiza las ofertas, aumenta las ganancias y mejora la participación de mercado al aprovechar los datos de disposición a pagar del consumidor para identificar características clave',
  main_68:
    'Asegura que sus puntos de venta se alineen con los estándares de la marca, evaluando atributos físicos y su impacto en la percepción del cliente',
  main_69: 'Solución CX',
  main_70:
    'Descubra nuestra solución única de CX de 360 ​​grados: personalizamos herramientas y servicios según sus objetivos para impulsar su negocio',
  main_71: 'Análisis de voz',
  main_72:
    'Nuestra herramienta avanzada de análisis de voz transcribe el lenguaje hablado en texto, proporcionando información invaluable para que las empresas mejoren las experiencias de los clientes, monitoreen el rendimiento, aseguren el cumplimiento y fomenten el crecimiento',
  parthership: 'Asociación',
  banner_1:
    'Consultoría y servicios de CX, una visión de 360 ​​° de su negocio',
  banner_2: '¡Manténgase actualizado con las últimas investigaciones!',
  banner_3:
    'Doy mi consentimiento para el procesamiento de datos personales de acuerdo con la Política de privacidad',
  banner_4: '* Seleccione el mercado',
  banner_5: '¡Gracias por suscribirse!',
  banner_6: '¡Solo compartiremos el contenido más interesante con usted!',
  banner_7: 'Enviar',
  banner_8: 'Ucraniano',
  banner_9: 'Kazajstán',
  brand_awareness_1: 'Conciencia de marca',
  brand_awareness_2: 'y uso',
  brand_awareness_3:
    'Desbloquee el potencial de su conocimiento y uso de la marca para el éxito empresarial',
  brand_awareness_4: 'Hable con un experto',
  brand_awareness_5: 'Acerca de',
  brand_awareness_6:
    '<b>Evalúe el grado de</b> reconocimiento y familiaridad de su marca',
  brand_awareness_7:
    '<b>Determine el nivel de conciencia de marca</b> en su mercado objetivo',
  brand_awareness_8: '<b>Vigile de cerca</b> las tasas de retención y lealtad',
  brand_awareness_9:
    '<b>Seleccione la estrategia óptima</b> para mejorar los indicadores necesarios',
  brand_awareness_10: 'Explore numerosas oportunidades con nuestras soluciones',
  brand_awareness_11: 'Conciencia de marca',
  brand_awareness_12: 'Uso de la marca',
  brand_awareness_13:
    '<b>Descubra</b> el reconocimiento de su marca entre los segmentos de consumidores',
  brand_awareness_14:
    '<b>Monitoree</b> el nivel de conciencia entre su audiencia',
  brand_awareness_15:
    '<b>Estudie</b> los pensamientos espontáneos de los consumidores a punto de hacer una compra',
  brand_awareness_16:
    '<b>Comprenda</b> las fuentes principales que contribuyen al conocimiento de su marca',
  brand_awareness_17:
    '<b>Considere</b> el número de consumidores que experimentaron los productos o servicios de su marca',
  brand_awareness_18:
    '<b>Identifique</b> a los consumidores que se quedaron con su marca después de probarla inicialmente',
  brand_awareness_19:
    '<b>Desbloquee</b> la probabilidad de que se recomiende su marca',
  brand_awareness_20:
    '<b>Descubra</b> el porcentaje de consumidores que consideran su marca para uso futuro',
  brand_awareness_21:
    '¿Quién se beneficia del conocimiento y el uso de la marca?',
  brand_awareness_22: 'Empresas que lanzan un nuevo producto',
  brand_awareness_23: 'Empresas que revitalizan una marca establecida',
  brand_awareness_24:
    'Cualquier empresa que busque comprender y mejorar cómo su marca es reconocida y utilizada por los consumidores',
  brand_awareness_25:
    'Nuestras metodologías brindan valiosos conocimientos para optimizar el conocimiento y el uso de su marca',
  brand_awareness_26:
    'La metodología se selecciona individualmente según la solicitud del cliente',
  brand_awareness_27: 'Metodología de embudo de uso de marca',
  brand_awareness_28:
    'La tasa de prueba describe la proporción de la audiencia que conoce la marca y ha probado sus productos o servicios.',
  brand_awareness_29:
    'La tasa de repetición indica el porcentaje de la audiencia familiarizada con la marca y que ha probado sus productos o servicios que continuó realizando compras o utilizando las ofertas en los últimos 12 meses.',
  brand_awareness_30:
    'La tasa de retención revela la proporción de individuos que utilizaron constantemente los productos o servicios de la marca, indicando el porcentaje que se involucró con estas ofertas en los últimos 6 meses.',
  brand_awareness_31:
    'La tasa de retención ilustra el porcentaje de clientes que, habiendo utilizado los productos/servicios de la marca en los últimos 6 meses, continúan eligiendo y demostrando lealtad a la marca a lo largo del tiempo.',
  brand_awareness_32: 'Metodología de conocimiento de marca',
  brand_awareness_33:
    'El primero en la mente (TOM) representa la primera marca mencionada espontáneamente por el encuestado.',
  brand_awareness_34:
    'El conocimiento espontáneo total representa el total de menciones espontáneas, incluido TOM.',
  brand_awareness_35:
    'El conocimiento asistido revela qué tan bien su marca es reconocida por los usuarios cuando se le solicita.',
  brand_awareness_36: 'Mapa de rendimiento de atributos de marca',
  brand_awareness_37:
    'Esta herramienta evalúa y representa visualmente la importancia de diferentes atributos de marca, proporcionando información sobre cómo cada uno contribuye al rendimiento general de su marca y la percepción del consumidor.',
  brand_awareness_38:
    'Descubra las claves para mejorar el impacto de su marca con nuestro análisis integral.',
  brand_awareness_39: 'Mapa perceptivo de marca',
  brand_awareness_40:
    'Esta herramienta ofrece una representación visual de las percepciones de los consumidores en un entorno de mercado competitivo. Este enfoque es crucial para delinear claramente las diferencias entre las marcas, enfatizando los atributos clave del mercado.',
  brand_awareness_41:
    'Agiliza la observación e interpretación de las relaciones entre las marcas y estas características definitorias.',
  brand_awareness_42:
    'La proximidad de una marca específica a ciertos atributos indica su fuerte asociación con esas características. Del mismo modo, la cercanía entre dos marcas indica sus similitudes, mostrando imágenes compartidas y, en consecuencia, una afiliación dentro del mismo segmento de mercado.',
  consumer_journey_map_1: 'Mapa del viaje del consumidor',
  consumer_journey_map_2:
    'Descubra y supere las barreras en su negocio con nuestro Mapa del viaje del consumidor',
  consumer_journey_map_3: 'CONFIADO POR',
  consumer_journey_map_4: '4Servicio',
  consumer_journey_map_5:
    'Desbloquee el potencial de CJM para optimizar las interacciones entre sus consumidores y su marca',
  consumer_journey_map_6:
    'Determine qué pasos toma el consumidor antes de comprar un producto',
  consumer_journey_map_7:
    'Obtenga información de sus clientes reales, no imaginarios',
  consumer_journey_map_8:
    'Obtenga una comprensión completa de las experiencias de sus clientes',
  consumer_journey_map_9:
    'Identifique oportunidades perdidas y compárese con sus competidores',
  consumer_journey_map_10: 'Descubre nuestro enfoque de CJM',
  consumer_journey_map_11:
    'Creamos un camino de interacción del consumidor con un producto, servicio, empresa o marca a través de diversos canales y durante un determinado período de tiempo.',
  consumer_journey_map_12:
    'Delineamos las etapas que navegan sus consumidores antes de completar una compra, las expectativas, las emociones y las motivaciones del cliente en cada etapa.',
  consumer_journey_map_13: 'Benefíciese con nosotros',
  consumer_journey_map_14:
    'Proporcionamos un equipo de profesionales experimentados que comprenden las complejidades del éxito empresarial global',
  consumer_journey_map_15:
    'Estamos dedicados a ofrecer resultados que superen sus expectativas',
  consumer_journey_map_16:
    'Descubrimos soluciones complejas que contribuyen a su crecimiento',
  consumer_journey_map_17:
    'Nos especializamos en brindar soluciones personalizadas de CJM',
  consumer_journey_map_18: 'Reservar una consulta',
  consumer_journey_map_19: 'Decisiones basadas en datos',
  consumer_journey_map_20:
    'Base sus decisiones en datos reales en lugar de suposiciones',
  consumer_journey_map_21: 'Optimice su CJM',
  consumer_journey_map_22:
    'Establezca relaciones más sólidas con los clientes al abordar sus necesidades y expectativas en cada etapa de su viaje',
  consumer_journey_map_23: 'Liderar el camino',
  consumer_journey_map_24:
    'Compárese con sus competidores y manténgase a la vanguardia del mercado',
  consumer_journey_map_25: 'Recomendaciones',
  consumer_journey_map_26:
    'Implemente nuestras recomendaciones de CJM para desbloquear el éxito, fomentar la lealtad de los clientes, impulsar las ventas y maximizar el potencial de su negocio',
  consumer_journey_map_27:
    'Descubra el verdadero viaje del consumidor con nuestro Mapa del viaje del consumidor',
  consumer_journey_map_28:
    'Expertos con más de 20 años de experiencia brindan una investigación integral',
  consumer_journey_map_29:
    'La investigación cualitativa implica recopilar y analizar datos no numéricos para comprender conceptos, opiniones o experiencias.',
  consumer_journey_map_30:
    'Los métodos de investigación cuantitativa son una medición objetiva y un análisis numérico de los datos recopilados a través de encuestas, cuestionarios y encuestas.',
  consumer_journey_map_31:
    'Después de recopilar los datos, los expertos analizan la información y sacan conclusiones para brindarle las mejores recomendaciones.',
  boarding_1: 'Mapa del viaje del cliente',
  boarding_2:
    'El mapeo del viaje del cliente (CJM) consiste en definir una audiencia objetivo, comprender sus necesidades y entorno, y luego crear un plan para brindar una experiencia atractiva',
  boarding_3: 'Puntuación del Net Promoter',
  boarding_4:
    'La puntuación del Net Promoter Score (NPS) es el estándar de oro de las métricas de experiencia del cliente, basado en una pregunta: ¿Qué tan probable es que recomendaría la organización/producto/servicio?',
  boarding_5: 'Seguimiento de la salud de la marca',
  boarding_6:
    'La investigación de seguimiento de la salud de la marca (BHT) puede medir cómo se está desempeñando su marca en cuanto a conocimiento y uso, posicionamiento de la marca y rendimiento de la marca',
  boarding_7: 'Investigación de recursos humanos',
  boarding_8:
    'La investigación de recursos humanos se utiliza para evaluar las prácticas y el desempeño de los recursos humanos y ofrece un análisis detallado del desarrollo y la gestión actuales',
  boarding_9: 'Pruebas de usabilidad',
  boarding_10:
    'Las pruebas de usabilidad son la forma en que las personas reales interactúan con un sitio web, una aplicación u otro producto y observan su comportamiento y reacciones',
  boarding_11: 'Índice de satisfacción del cliente',
  boarding_12:
    'La satisfacción del cliente mide el grado en que se han cumplido las expectativas del cliente con respecto a la compra de un producto o servicio proporcionado por su empresa',
  boarding_13: 'Prueba de anuncios',
  boarding_14:
    'Una campaña publicitaria es una gran inversión. Asegúrese de que sus anuncios sean poderosos y brinden el máximo ROI',
  boarding_15: 'Auditoría de campo/Auditoría minorista',
  boarding_16:
    'Recopile datos detallados sobre su tienda o marca, analice las barreras de crecimiento y cree estrategias efectivas para superarlas',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Software de gestión de rendimiento. Aplicación gamificada para aumentar las ventas',
  boarding_19: 'Voicer',
  boarding_20:
    'Sistema de gestión de comentarios. La voz de su cliente en un solo sistema. Sistema de gestión de comentarios de los clientes',
  boarding_21: 'Investigación cualitativa',
  boarding_22:
    'La investigación cualitativa es la recopilación y el análisis de datos no numéricos para comprender conceptos, opiniones o experiencias',
  boarding_23: 'Investigación cuantitativa',
  boarding_24:
    'Los métodos de investigación cuantitativa son una medición objetiva y un análisis numérico de los datos recopilados a través de encuestas, cuestionarios y encuestas',
  boarding_25: 'Mystery Shopping',
  boarding_26:
    'Mystery shopping es una técnica de investigación de mercado para medir y monitorear las interacciones entre un cliente y una empresa u organización durante un escenario predefinido',
  boarding_27: 'Wantent',
  boarding_28:
    'Una plataforma de inteligencia de contenido impulsada por IA que proporciona información de la audiencia',
  boarding_29: 'Monitoreo de precios',
  boarding_30:
    'El monitoreo de precios es el proceso de recopilación, procesamiento y análisis de los precios de la competencia',
  boarding_31: 'Diseño de servicios',
  boarding_32:
    'El diseño de servicios es cómo comprender las necesidades y deseos de las personas que usarán un producto o servicio al pasar tiempo con ellos',
  boarding_33: 'Análisis de precios de la industria automotriz',
  boarding_34:
    'El análisis de precios competitivo optimiza las ofertas, aumenta las ganancias y mejora la participación de mercado al aprovechar los datos de disposición a pagar del consumidor para identificar características clave',
  boarding_35: 'Auditoría de verificación de instalaciones',
  boarding_36:
    'Asegura que sus puntos de venta se alineen con los estándares de la marca, evaluando atributos físicos y su impacto en la percepción del cliente',
  boarding_37: 'Solución CX',
  boarding_38:
    'Descubra nuestra solución única de CX de 360 ​​grados: personalizamos herramientas y servicios según sus objetivos para impulsar su negocio',
  boarding_39: 'Análisis de voz',
  boarding_40:
    'Nuestra herramienta avanzada de análisis de voz transcribe el lenguaje hablado en texto, proporcionando información invaluable para que las empresas mejoren las experiencias de los clientes, monitoreen el rendimiento, aseguren el cumplimiento y fomenten el crecimiento',
  boarding_41: 'Uso y actitud',
  boarding_42:
    'Uso y Actitud es nuestro enfoque para comprender cómo las audiencias interactúan con su marca al analizar patrones de uso prácticos y actitudes psicológicas, lo que permite estrategias de marketing y productos personalizadas',
  boarding_43: 'Conciencia y uso de la marca',
  boarding_44:
    'La conciencia y el uso de la marca implica evaluar el reconocimiento, la familiaridad, la frecuencia de interacción y la retención dentro de su mercado objetivo para mejorar la visibilidad y el compromiso de la marca',
  boarding_45: 'Identidad y posicionamiento de marca',
  boarding_46:
    'La identidad y el posicionamiento de marca son un análisis completo que revela las percepciones de los consumidores, las diversas opiniones de la audiencia y los conocimientos sobre cómo se percibe su marca',
  boarding_47: 'Perfil y segmentación del cliente',
  boarding_48:
    'El perfil y la segmentación de clientes implica dividir su mercado objetivo en grupos distintos basados en características compartidas, lo que permite la creación de estrategias personalizadas que resuenen con segmentos específicos de la audiencia',
  boarding_49: 'Mapa del viaje del consumidor',
  boarding_50:
    'Un mapa del viaje del consumidor es una representación visual de las etapas, puntos de contacto, emociones y motivaciones experimentadas por un consumidor a lo largo de su interacción con su producto o marca',
  boarding_51:
    'Aprenda sobre técnicas innovadoras que respaldan nuestro compromiso de proporcionar información procesable e impulsar resultados impactantes para su negocio',
  boarding_52:
    'Explore nuestro conjunto de productos con una gran cantidad de inteligencia basada en datos adaptada a su industria, lo que le permite tomar decisiones informadas y mantenerse a la vanguardia',
  boarding_53:
    'Sumérjase en las tendencias del mercado, el comportamiento del consumidor y los paisajes competitivos con nuestras soluciones integrales de investigación de mercado',
  boarding_54: 'Investigación ad hoc',
  boarding_55:
    'La investigación ad hoc es un enfoque personalizado diseñado para abordar un problema específico, utilizando una combinación de metodologías existentes o un nuevo enfoque para crear una solución personalizada.',
  boarding_56: 'Investigación sociológica',
  boarding_57:
    'La investigación sociológica descubre los patrones de comportamiento humano e interacción social, proporcionando valiosos conocimientos para impulsar estrategias innovadoras y crear conexiones significativas con las audiencias objetivo.',
  not_found_1: '¡Vaya! No podemos encontrar esa página.',
  not_found_2: '¿Por qué no echa un vistazo a nuestros productos?',
  not_found_3: 'Reservar consulta',
  customer_profiling_segmentation_1: 'Perfil y segmentación del cliente',
  customer_profiling_segmentation_2:
    'Le ayudaremos a identificar su público objetivo, haciendo que su estrategia sea más efectiva',
  customer_profiling_segmentation_3:
    'Utilice el poder de la segmentación del mercado para asegurarse de que sus mensajes siempre lleguen a sus grupos de consumidores objetivo de manera efectiva',
  customer_profiling_segmentation_4:
    'Comprenda a los consumidores más profundamente',
  customer_profiling_segmentation_5:
    'Encuentre la mejor audiencia objetivo para su producto',
  customer_profiling_segmentation_6: 'Explore nuevos segmentos para atraer',
  customer_profiling_segmentation_7: 'Descubra las barreras para la compra',
  customer_profiling_segmentation_8:
    'Nos especializamos en brindar soluciones de segmentación personalizadas',
  customer_profiling_segmentation_9: 'Enfoque único',
  customer_profiling_segmentation_10:
    'Experimente una solución de segmentación única y personalizada creada específicamente para sus necesidades',
  customer_profiling_segmentation_11: 'Personas objetivo',
  customer_profiling_segmentation_12:
    'Personas objetivo creadas basadas en un análisis profundo del mercado e investigación exhaustiva',
  customer_profiling_segmentation_13: 'Nuevos segmentos',
  customer_profiling_segmentation_14:
    'Identifique sus segmentos de audiencia más valiosos y desbloquee los beneficios de una orientación personalizada',
  customer_profiling_segmentation_15: 'Recomendaciones',
  customer_profiling_segmentation_16:
    'Implemente nuestras recomendaciones y desbloquee el éxito, fomente la lealtad de los clientes, impulse las ventas y maximice el potencial de su negocio',
  customer_profiling_segmentation_17:
    'Expertos con más de 20 años de experiencia brindan una investigación integral',
  customer_profiling_segmentation_18:
    'La investigación cualitativa implica recopilar y analizar datos no numéricos para comprender conceptos, opiniones o experiencias.',
  customer_profiling_segmentation_19:
    'Los métodos de investigación cuantitativa son una medición objetiva y un análisis numérico de los datos recopilados a través de encuestas, cuestionarios y encuestas.',
  customer_profiling_segmentation_20:
    'Después de recopilar los datos, los expertos analizan la información y sacan conclusiones para brindarle las mejores recomendaciones.',
  customer_profiling_segmentation_21:
    'No solo tomamos medidas, sino que también le brindamos un plan de acción con acciones claras paso a paso',
  customer_profiling_segmentation_22: 'Nuestra metodología',
  customer_profiling_segmentation_23: 'Índice de Jaccard',
  customer_profiling_segmentation_24:
    'Cuando se les pregunta directamente a los encuestados, sus respuestas sobre las preferencias de la empresa pueden no estar alineadas con su comportamiento real. En lugar de preguntas directas, utilizamos el análisis estadístico de Jaccard para comprender la relación entre los atributos de la empresa y la elección del consumidor.',
  customer_profiling_segmentation_25: 'Conductores',
  customer_profiling_segmentation_26:
    'Identificamos los impulsores (factores que influyen en un determinado resultado) y los clasificamos en impulsores clave, medios e insignificantes según su importancia.',
  customer_profiling_segmentation_27: 'Segmentación',
  customer_profiling_segmentation_28:
    'Obtiene segmentación con características claras de las elecciones de los clientes.',
  customer_profiling_segmentation_29: 'Nuestro enfoque de segmentación',
  customer_profiling_segmentation_30: 'Demográfico',
  customer_profiling_segmentation_31: 'edad',
  customer_profiling_segmentation_32: 'género',
  customer_profiling_segmentation_33: 'ingresos',
  customer_profiling_segmentation_34:
    'Ayuda a adaptar productos, servicios y marketing a grupos específicos, mejorando la relevancia y la efectividad',
  customer_profiling_segmentation_35: 'Psicográfico',
  customer_profiling_segmentation_36: 'estilo de vida',
  customer_profiling_segmentation_37: 'valores',
  customer_profiling_segmentation_38: 'comportamientos',
  customer_profiling_segmentation_39:
    'Permite una comercialización personalizada, conexiones más profundas con los clientes, una mayor lealtad y ventas para la empresa',
  customer_profiling_segmentation_40: 'Comportamental',
  customer_profiling_segmentation_41: 'acciones',
  customer_profiling_segmentation_42: 'motivación',
  customer_profiling_segmentation_43: 'frecuencia de compra',
  customer_profiling_segmentation_44:
    'Permite una comercialización dirigida, una mayor satisfacción del cliente, una mayor retención y una mayor rentabilidad para la empresa',
  customer_profiling_segmentation_45: 'Geográfico',
  customer_profiling_segmentation_46: 'ubicación',
  customer_profiling_segmentation_47:
    'Permite una comercialización dirigida, productos personalizados, una distribución eficiente y una mayor penetración en el mercado para la empresa.',
  brands_identity_1: 'Identidad y posicionamiento de marca',
  brands_identity_2:
    'Transforme su marca con nuestro análisis en profundidad, revelando información sobre las percepciones de los consumidores y refinando su estrategia para el éxito',
  brands_identity_3: 'Hable con un experto',
  brands_identity_4:
    '<b>Descubra un análisis integral</b> de identidad de marca',
  brands_identity_5:
    '<b>Explore las ideas</b> recopiladas sobre las percepciones de los consumidores',
  brands_identity_6:
    '<b>Comprenda las diversas</b> opiniones de la audiencia objetivo',
  brands_identity_7:
    '<b>Obtenga información sobre</b> el comportamiento, los hábitos y las preferencias de los consumidores para tomar decisiones informadas',
  brands_identity_8:
    'Los expertos de 4Service con más de 20 años de experiencia en atención al cliente crean soluciones únicas que ayudan a hacer crecer su negocio',
  brands_identity_9:
    '<b>Tendrá acceso a un paquete de investigación equipado con todos los datos esenciales.</b> Sirve como una herramienta de diagnóstico, destacando las áreas de fortaleza y las áreas que pueden requerir atención',
  brands_identity_10:
    '<b>Nuestros servicios brindan a las empresas las herramientas necesarias</b> para tomar decisiones informadas, refinar estrategias e impulsar el éxito en sus respectivas industrias.',
  brands_identity_11:
    'Para mejorar la posición de su marca en el mercado competitivo',
  brands_identity_12:
    'Empresas que se esfuerzan por mejorar su presencia en el mercado',
  brands_identity_13: 'Empresas que buscan elevar su estrategia',
  brands_identity_14: 'Identidad de marca',
  brands_identity_15: 'Posicionamiento de marca',
  brands_identity_16:
    '<b>Descubra la percepción de su marca</b> entre los segmentos de consumidores.',
  brands_identity_17:
    '<b>Identifique las cualidades atribuidas</b> a su marca y competidores.',
  brands_identity_18:
    '<b>Evalúe las fortalezas</b> y las debilidades para mejorar estratégicamente.',
  brands_identity_19:
    '<b>Evalúe la alineación</b> de la imagen de su marca con los valores promovidos.',
  brands_identity_20: '<b>Evalúe la percepción</b> de confianza de su marca.',
  brands_identity_21:
    '<b>Explore la percepción</b> arquetípica y simbólica de su marca.',
  brands_identity_22:
    '<b>Identifique los puntos de venta únicos de su marca</b> y los diferenciadores en comparación con la competencia.',
  brands_identity_23:
    '<b>Identifique los atributos clave que influyen</b> en la probabilidad de que los consumidores compren sus productos/servicios.',
  brands_identity_24:
    '<b>Determine cómo se posiciona actualmente su marca</b> en la mente de los consumidores e identifique los aspectos que comunicar para atraer los segmentos de mercado deseados.',
  brands_identity_25:
    'La metodología se selecciona individualmente según la solicitud del cliente',
  brands_identity_26: 'Conciencia y uso de la marca',
  brands_identity_27:
    'Evaluar el nivel de marca implica medir los niveles de conocimiento y consumo, sirviendo como un componente fundamental en la evaluación.',
  brands_identity_28: 'Entrega y confianza de la marca',
  brands_identity_29:
    'Esta métrica mide la defensa del cliente (recomendación) y la capacidad constante de la marca para cumplir promesas, crear experiencias positivas para los clientes y generar confianza.',
  brands_identity_30: 'Rendimiento de la imagen de marca',
  brands_identity_31:
    'La evaluación del desempeño de la marca implica evaluar su asociación con seis valores cruciales (3 racionales y 3 emocionales), su distinción dentro de la categoría y su capacidad para infundir orgullo entre los consumidores.',
  brands_identity_32: 'Puntuación de salud de marca',
  brands_identity_33:
    'La puntuación de salud de marca le muestra una comprensión completa de cómo se está desempeñando su marca en el panorama competitivo.',
  sociological_research_1: 'Investigación sociológica',
  sociological_research_2:
    'Realizamos un análisis en profundidad de los fenómenos y procesos sociales, lo que ayuda a tomar decisiones informadas con la ayuda de conclusiones y recomendaciones analíticas únicas',
  sociological_research_3: 'Contáctenos',
  sociological_research_4: 'Nuestros clientes',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI es un método de recopilación de datos que utiliza una computadora para realizar encuestas por teléfono. El software CATI le permite automatizar los procesos de recopilación de datos, entrada de respuestas y gestión de encuestas. El uso de CATI facilita el control de la encuesta, le permite analizar y procesar rápidamente los datos y garantiza un alto nivel de estandarización de la encuesta.',
  sociological_research_7: 'CATI',
  sociological_research_8:
    'CAWI es un método de recopilación de datos en el que los encuestados completan cuestionarios de encuesta o responden preguntas a través de una interfaz web, y los datos se recopilan y procesan automáticamente utilizando software especializado. El método CAWI le permite realizar encuestas a gran escala, recopilar y analizar datos rápidamente.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI es un método de recopilación de datos para encuestas cara a cara en el que el investigador utiliza una tableta o un teléfono móvil para ingresar las respuestas de los encuestados a las preguntas, lo que permite la recopilación automatizada de datos, reduce los errores de entrada y simplifica el análisis de resultados.',
  sociological_research_11:
    'Nuestros expertos utilizan una variedad de herramientas',
  sociological_research_12: 'Muestra',
  sociological_research_13:
    'Calculamos y aplicamos muestras de cualquier complejidad',
  sociological_research_14:
    'En nuestro trabajo, utilizamos muestras simples, sistemáticas, estratificadas y por conglomerados',
  sociological_research_15:
    'Para encuestas cara a cara representativas a nivel nacional, utilizamos procedimientos de muestreo estratificado de múltiples etapas',
  sociological_research_16:
    'La selección de asentamientos por regiones se lleva a cabo de acuerdo con el tamaño y el tipo de asentamiento, su número se determina en proporción a su volumen en cada región',
  sociological_research_17: 'Llevamos a cabo',
  sociological_research_18: 'Investigación de opinión pública',
  sociological_research_19: 'Investigación socioeconómica',
  sociological_research_20: 'Investigación de escritorio',
  sociological_research_21:
    'Evaluación de la calidad de los servicios sociales',
  sociological_research_22: 'Estudio de caso',
  sociological_research_23: 'Investigación de personal',
  sociological_research_24:
    'Investigación sobre audiencias específicas (grupos vulnerables, incluidos desplazados internos, militares, jóvenes, etc.)',
  sociological_research_25: 'Trabajamos con cada cliente individualmente',
  sociological_research_26: 'Nuestros clientes:',
  sociological_research_27: 'Fundaciones benéficas',
  sociological_research_28: 'Organizaciones públicas y sindicatos',
  sociological_research_29: 'Estructuras estatales',
  sociological_research_30: 'Nuestro enfoque de trabajo',
  sociological_research_31:
    'Utilizamos un enfoque científico en nuestro trabajo y una comprensión profunda de la realidad actual, así como diversos métodos de investigación para recopilar datos.',
  sociological_research_32:
    'Contratamos expertos altamente especializados con la experiencia necesaria para abordar una solicitud específica y contamos con un grupo de expertos en diversos campos.',
  sociological_research_33:
    'Nuestro departamento de análisis interno analiza los datos recopilados utilizando un conjunto único de herramientas según las especificidades de la tarea.',
  sociological_research_34:
    'Generamos informes con conclusiones y recomendaciones detalladas, teniendo en cuenta las necesidades y expectativas de su público objetivo.',
  sociological_research_35:
    'Brindamos asesoramiento y apoyo durante la implementación de las recomendaciones, ayudándolo a realizar cambios positivos.',
  sociological_research_36: 'Nuestra investigación sobre televisión',
  sociological_research_37: 'Echa un vistazo a nuestros informes',
  sociological_research_38:
    'Nuestro enfoque científico en nuestro trabajo garantiza a nuestros clientes datos sociológicos precisos y confiables en toda Ucrania y Europa con un error mínimo',
  sociological_research_39:
    'Trabajamos de conformidad con todos los principios y estándares de ética y normas para realizar investigaciones sociológicas.',
  sociological_research_40:
    'Nos adherimos al Código de Ética Profesional para Sociólogos de la Asociación Sociológica de Ucrania y la Declaración de Helsinki de Principios Éticos para la Investigación. Miembros de ESOMAR, Asociación de Marketing de Ucrania, Asociación de Centros de Contacto de Ucrania.',
  sociological_research_41: 'Campo',
  sociological_research_42: 'panel en línea',
  sociological_research_43: 'entrevistadores regulares',
  sociological_research_44: 'supervisores',
  sociological_research_45: 'Control de calidad de campo',
  sociological_research_46: '100% de fijación de coordenadas GPS (CAPI)',
  sociological_research_47:
    'Validación de cuestionarios (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: '100% de grabación de entrevistas (CAPI, CATI)',
  sociological_research_49: 'Utilizamos software con licencia:',
  sociological_research_50:
    'SPSS para analizar datos estadísticos, procesar conjuntos de datos',
  sociological_research_51:
    'DigSee Sure para CAPI: lógica de cuestionarios, geolocalización, grabación de audio de entrevistas, demostración de materiales de video',
  sociological_research_52:
    'SurveyMonkey para CAWI: lógica de cuestionarios, formato conveniente, visualización',
  sociological_research_53:
    'VoIPTime (centro de contacto): programación de cuestionarios de cualquier complejidad, transferencia de grabaciones de audio al cliente',
  sociological_research_54: 'Muestra',
  sociological_research_55:
    'Calculamos y aplicamos muestras de cualquier complejidad',
  sociological_research_56:
    'En nuestro trabajo, utilizamos muestras simples, sistemáticas, estratificadas y por conglomerados',
  sociological_research_57:
    'Para encuestas cara a cara representativas a nivel nacional, utilizamos procedimientos de muestreo estratificado de múltiples etapas',
  sociological_research_58:
    'La selección de asentamientos por regiones se lleva a cabo de acuerdo con el tamaño y el tipo de asentamiento, su número se determina en proporción a su volumen en cada región',
  sociological_research_59: 'Equipo',
  sociological_research_60:
    'Nuestros sociólogos son miembros de la Asociación de Sociólogos de Ucrania',
  sociological_research_61:
    'Tenemos 12 analistas de tiempo completo con experiencia en proyectos cuantitativos y cualitativos en diversas industrias.',
  sociological_research_62:
    'Tenemos un sólido equipo analítico de profesionales con más de 10 años de experiencia en investigación. Tenemos científicos, PhD, MBI.',
  sociological_research_63: 'Iryna Maksymiuk',
  sociological_research_64:
    'Jefe del Departamento de Investigación. Más de 15 años de experiencia en investigación de mercado, gestión de investigación en todas las etapas, preparación de informes analíticos, presentaciones',
  sociological_research_65: 'Andriy Biloskursky',
  sociological_research_66:
    'Jefe de Programación y Procesamiento de Datos. 20 años de experiencia en marketing, investigación sociológica y de mercado',
  sociological_research_67: 'Olena Somova',
  sociological_research_68:
    'Especialista en programación Estudiante de posgrado en sociología en DonSUU. 5 años de experiencia en investigación de marketing Miembro de la Junta de la Alianza Democrática de Mujeres, Jefa de Recaudación de Fondos.',
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    'Jefa de la División de Investigación Sociológica del Departamento de Investigación, cuenta con 13 años de experiencia en proyectos sociológicos y sociales a nivel internacional y nacional.  Doctora en Sociología, Jefa del Comité de Investigación de la Asociación Ucraniana de Centros de Contacto de Ucrania.',
  ad_hoc_1: 'Ad Hoc',
  ad_hoc_2: 'Descubre nuestra investigación alternativa para casos únicos',
  ad_hoc_3: 'Contáctenos',
  ad_hoc_4: 'Nuestro enfoque',
  ad_hoc_5:
    'Nuestro equipo de expertos analiza meticulosamente los datos recopilados, brindándole soluciones complejas que contribuyen a su crecimiento.',
  ad_hoc_6:
    'Diseñamos una investigación única adaptada a sus requisitos específicos, aprovechando una amplia gama de metodologías, que incluyen encuestas, entrevistas, grupos focales, análisis de datos y más.',
  ad_hoc_7: '¿Por qué elegir la investigación ad hoc?',
  ad_hoc_8: 'Flexibilidad',
  ad_hoc_9:
    'No hay dos proyectos de investigación iguales. Con Ad Hoc Research, tenemos la flexibilidad de adaptar y desarrollar el enfoque único según sea necesario, asegurando que sus ideas sigan siendo relevantes y viables.',
  ad_hoc_10: 'Precisión',
  ad_hoc_11:
    'Al adaptar nuestras metodologías a sus requisitos únicos, entregamos resultados precisos y específicos que brindan información valiosa.',
  ad_hoc_12: 'Innovación',
  ad_hoc_13:
    'Adoptamos la innovación y la creatividad en nuestro proceso de investigación, explorando nuevas técnicas y enfoques para descubrir oportunidades y desafíos ocultos.',
  ad_hoc_14: 'Oportunidad',
  ad_hoc_15:
    'La investigación ad hoc permite una implementación rápida, lo que le permite acceder a información crítica de manera oportuna, manteniéndolo a la vanguardia de su industria.',
  ad_hoc_16: 'Nuestra experiencia',
  ad_hoc_17:
    'Los expertos de 4Service con más de 20 años de experiencia en investigación de mercado y experiencia del cliente crean soluciones únicas que ayudan a hacer crecer su negocio',
  ad_hoc_18:
    'Utilizamos un conjunto distinto de herramientas según sus requisitos específicos',
  ad_hoc_19: 'Ninguna metodología funciona para usted',
  ad_hoc_20: 'Solicitar investigación ad hoc',
  ad_hoc_21: 'Adaptado a un problema específico',
  ad_hoc_22:
    'Utilizamos una combinación de diferentes partes de las metodologías, así como un enfoque completamente nuevo',
  ad_hoc_23: 'Enfoque individual',
  ad_hoc_24: 'Adaptamos cuidadosamente una solución directamente a su problema',
  ad_hoc_25: 'Ir más allá',
  ad_hoc_26:
    'Proporcionamos un plan de acción con recomendaciones para ayudar a su negocio a lograr sus objetivos',
  ad_hoc_27: 'Tiene un problema no estándar',
  ad_hoc_28: 'Ninguna de las metodologías funciona para su problema',
  ad_hoc_29:
    'Le gustaría solicitar diferentes metodologías o partes de ellas a la vez',
  ad_hoc_30: '¿Quién se beneficia de la investigación ad hoc?',
  service_design_1: 'Algunos de nuestros clientes',
  service_design_2: 'Diseño de Servicios',
  service_design_3:
    'Transforma tu negocio de la orientación a productos y ventas a una orientación hacia los servicios y las personas',
  service_design_4: 'Habla con un experto',
  service_design_5: '¡Aprende gratis!',
  service_design_6:
    'Hemos recopilado información práctica y útil en nuestra Biblioteca de Diseño de Servicios',
  service_design_7:
    'La biblioteca contiene descripciones detalladas de cada paso, herramientas y técnicas relevantes, consejos sobre detalles importantes y demostraciones en video.',
  service_design_8: 'Ir a la Biblioteca',
  service_design_9: '¡Bienvenido al Diseño de Servicios!',
  service_design_10:
    'El Diseño de Servicios tiene como objetivo crear y mejorar servicios abordando todo el ecosistema de servicios: personas, procesos y puntos de contacto, para mejorar tanto la satisfacción del usuario como la eficiencia operativa.',
  service_design_11:
    'El diseño de servicios no solo es por qué prefieres un café sobre otro. También es la razón por la que vuelves a ese lugar una y otra vez y le cuentas a todos tus amigos sobre él.',
  service_design_12:
    'Cómo las empresas pueden beneficiarse con el Diseño de Servicios',
  service_design_13: 'Satisfacción del usuario',
  service_design_14:
    'Crea experiencias personalizadas y fluidas que deleiten a los usuarios.',
  service_design_15: 'Eficiencia operativa',
  service_design_16:
    'Optimiza procesos, reduce costos y mejora la prestación de servicios.',
  service_design_17: 'Consistencia de la marca',
  service_design_18:
    'Asegúrate de que todos los puntos de contacto estén alineados con los valores de la marca.',
  service_design_19: 'Adaptabilidad a los cambios',
  service_design_20:
    'Diseña servicios que puedan crecer y evolucionar con las necesidades cambiantes.',
  service_design_21: 'Incremento de la lealtad',
  service_design_22:
    'Construye relaciones más sólidas con los clientes y aumenta la lealtad a través de experiencias de servicio excepcionales.',
  service_design_23: 'Ventaja competitiva',
  service_design_24:
    'Destaca en el mercado con experiencias de servicio únicas e inolvidables.',
  service_design_25: 'Por qué',
  service_design_26: '¿Por qué elegir Diseño de Servicios?',
  service_design_27:
    'Es una cultura, metodología y forma de pensar que ayuda a las empresas en todo el mundo a cambiar de estar orientadas a productos y ventas a enfocarse en las necesidades y problemas humanos.',
  service_design_28: 'Nuestros servicios',
  service_design_29: 'Diseño de Servicio al Cliente',
  service_design_30:
    'Desarrollamos e implementamos proyectos centrados en el cliente para crear nuevos productos o mejorar los existentes. También ofrecemos formación para el trabajo independiente con estos cambios en el futuro.',
  service_design_31: 'Diseño de servicios para empleados',
  service_design_32:
    'Un curso práctico para desarrollar e implementar procesos, estructuras y cambios orientados a los empleados dentro de la empresa.',
  service_design_33: 'Pensamiento de diseño futuro',
  service_design_34:
    'Desarrollo de conceptos futuros y estrategia empresarial, considerando diferentes escenarios e innovaciones posibles.',
  service_design_35: 'Creatividad en los negocios',
  service_design_36:
    'Una formación práctica que ayuda a los participantes a utilizar la creatividad para resolver problemas específicos y desarrollar soluciones únicas para el mercado.',
  service_design_37: 'Nuestros talleres',
  service_design_38:
    'Ofrecemos talleres prácticos con una certificación reconocida a nivel mundial para nuestros participantes.',
  service_design_39:
    'Crea productos, servicios e instalaciones que superen las expectativas',
  service_design_40: 'Implementa procesos empresariales que funcionen',
  service_design_41:
    'Armoniza la relación entre empleados de la empresa y clientes',
  service_design_42: 'Calendario',
  service_design_43: 'Próximamente',
  service_design_44: 'Aprende más sobre nuestros eventos anteriores',
  service_design_45: 'Fundamentos de Diseño de Servicios',
  service_design_46:
    'Crea productos, servicios e instalaciones que superen las expectativas',
  service_design_47: 'EX Diseño de Servicios',
  service_design_48:
    'Crea productos, servicios e instalaciones que superen las expectativas',
  service_design_49: 'Más',
  service_design_50: 'Nuestro enfoque',
  service_design_51:
    '¡4Service es el pilar para tu servicio centrado en las personas!',
  service_design_52:
    'Ayudamos a las empresas a desarrollar la mentalidad necesaria y crear un entorno interno centrado en las personas.',
  service_design_53: 'Herramientas',
  service_design_54:
    'Utilizamos un conjunto distinto de técnicas y herramientas basadas en tus requisitos específicos.',
  service_design_55: 'Investigación de usuarios',
  service_design_56:
    'Entrevistas, encuestas, estudios F2F y etnográficos para comprender las necesidades y comportamientos de los usuarios.',
  service_design_57: 'Personas',
  service_design_58:
    'Personajes ficticios que representan diferentes tipos de usuarios para guiar las decisiones de diseño.',
  service_design_59: 'Mapas de recorrido del cliente',
  service_design_60:
    'Representaciones visuales de la experiencia del usuario a través del servicio, identificando puntos clave de contacto y puntos de dolor.',
  service_design_61: 'Sesiones de lluvia de ideas',
  service_design_62:
    'Sesiones colaborativas con las partes interesadas para generar ideas y soluciones.',
  service_design_63: 'Prototipado',
  service_design_64:
    'Creación de versiones preliminares del servicio para probar y refinar conceptos.',
  service_design_65: 'Planos de servicio',
  service_design_66:
    'Diagramas detallados que muestran las relaciones entre los diferentes componentes y procesos del servicio.',
  service_design_67: 'Socio',
  service_design_68: '4Service & DTA',
  service_design_69:
    '4Service es el representante exclusivo oficial de la Academia de Design Thinking.',
  service_design_70: 'Como parte de nuestra colaboración, nosotros:',
  service_design_71:
    '<b>proporcionamos formación práctica</b> utilizando el método de diseño de servicios en talleres por expertos líderes;',
  service_design_72:
    '<b>formamos especialistas certificados</b> en design thinking;',
  service_design_73:
    '<b>implementamos proyectos de diseño de servicios</b> con facilitadores internacionales para muchas empresas.',
  service_design_74:
    'Hemos establecido Centros de Excelencia en Diseño de Servicios en:',
  service_design_75: 'Ucrania',
  service_design_76: 'Kazajistán',
  service_design_77: 'Turquía',
  service_design_78: 'Georgia',
  service_design_79: 'Azerbaiyán',
  service_design_80: 'Descubre más sobre nuestros casos',
  career: 'Carrera',
};
