import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import img from '../../../assets/images/brandAwarenessUsage/our-method.png';
import { BrandAwarenessUsageClientMethodStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const BrandAwarenessUsageClientMethod = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandAwarenessUsageClientMethodStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'main_14')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_26')}
          </h2>
          <div className="text-wrapper">
            <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_27')}</h3>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_28')}</p>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_29')}</p>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_30')}</p>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_31')}</p>
          </div>
          <div className="text-wrapper">
            <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_32')}</h3>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_33')}</p>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_34')}</p>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_35')}</p>
          </div>
          <div className="btn-container">
            <ButtonWithArrowOnHover
              onClick={() => {
                const elem = document.getElementById('form');
                elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
              }}
              arrowColor="#fff"
              textColor="#000"
              borderColor="#000"
              borderOnHover="#000"
              background="linear-gradient(135.55deg, #BBBAFF 0%, #8E50CC 100.95%)"
              hoverBackground="#000"
              hoverTextColor="#fff"
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'usage_attitude_11',
              )}
            />
          </div>
        </div>
      </div>
    </BrandAwarenessUsageClientMethodStyles>
  );
};
