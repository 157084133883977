import React, { useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CxBestPracticesStyles } from './CxBestPracticesStyles';
import {
  automotive, retail, tabs, tobacco,
} from './utils';
import ArrowRight from '../../../assets/icons/ArrowRight';
import { SmallStar } from '../../../assets/icons/cxSolution';
import { useWindowSize } from 'usehooks-ts';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { useNavigate } from 'react-router-dom';

export const CxBestPractices = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const navigate = useNavigate();

  const { width, height } = useWindowSize();

  const [tab, setTab] = useState(tabs(interfaceLanguage)[0]);

  return (
    <CxBestPracticesStyles>
      <div className="container">
        <h3 style={{ textAlign: 'center' }}>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_82')}
        </h3>
        <div className="tabs-wrapper">
          {tabs(interfaceLanguage).map((el) => {
            const Icon = el.icon;
            return (
              <div
                onClick={() => setTab(el)}
                className={`tabs-item ${el.key === tab.key ? 'active' : ''}`}
              >
                <Icon
                  width={width > 768 ? 50 : 30}
                  color={el.key === tab.key ? '#02ba95' : '#fff'}
                />
                <p style={{ color: el.key === tab.key ? '#02ba95' : '#fff' }}>
                  {el.name}
                </p>
              </div>
            );
          })}
        </div>
        {tab.key === 'automotive' && (
          <div className="automotive-wrapper">
            <div className="tab-content">
              <h6>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_83',
                )}
              </h6>
              <ul>
                {automotive(interfaceLanguage).map((el) => (
                  <li>
                    <SmallStar color="" />
                    <span>{el}</span>
                  </li>
                ))}
              </ul>
            </div>
            <h3>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_84')}
            </h3>
            <div className="cards-wrapper">
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_85',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_86',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_87',
                )}
              </div>
            </div>
          </div>
        )}
        {tab.key === 'tobacco' && (
          <div className="tobacco-wrapper">
            <div className="tab-content">
              <h6>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_88',
                )}
              </h6>
              <ul>
                {tobacco(interfaceLanguage).map((el) => (
                  <li>
                    <SmallStar color="" />
                    <span>{el}</span>
                  </li>
                ))}
              </ul>
            </div>
            <h3>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_84')}
            </h3>
            <div className="cards-wrapper">
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_89',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_90',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_91',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_92',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_93',
                )}
              </div>
            </div>
          </div>
        )}
        {tab.key === 'retail' && (
          <div className="tobacco-wrapper">
            <div className="tab-content">
              <h6>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_94',
                )}
              </h6>
              <ul>
                {retail(interfaceLanguage).map((el) => (
                  <li>
                    <SmallStar color="" />
                    <span>{el}</span>
                  </li>
                ))}
              </ul>
            </div>
            <h3>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_84')}
            </h3>
            <div className="cards-wrapper">
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_95',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_96',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_97',
                )}
              </div>
              <div className="card">
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_98',
                )}
              </div>
            </div>
          </div>
        )}
        {tab.key === 'other' && (
          <div className="other-wrapper">
            <h4>
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_99')}
            </h4>
            <button
              type="button"
              onClick={() => navigate(`/${interfaceLanguage}/cases`)}
              className="submitButton fw700"
            >
              <span>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cx_solution_69',
                )}
              </span>
              <ArrowRight color="#fff" />
            </button>
          </div>
        )}
      </div>
    </CxBestPracticesStyles>
  );
};
