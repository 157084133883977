import React, { useMemo } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesCareerStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const OpportunitiesCareer = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const texts: string[] = useMemo(() => ([
    getTranslationsByLangOrEng(interfaceLanguage, 'career_28'),
    getTranslationsByLangOrEng(interfaceLanguage, 'career_29'),
    getTranslationsByLangOrEng(interfaceLanguage, 'career_30'),
    getTranslationsByLangOrEng(interfaceLanguage, 'career_31'),
    getTranslationsByLangOrEng(interfaceLanguage, 'career_32'),
    getTranslationsByLangOrEng(interfaceLanguage, 'career_33'),
  ]), [interfaceLanguage]);

  return (
    <OpportunitiesCareerStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'career_27')}</h2>
        <div className="cards">
          {texts.map((text) => (
            <div className="cards-item">
              <p
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </OpportunitiesCareerStyles>
  );
};
