import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { СxSolutionStyles } from './СxSolutionStyles';
import {
  CxBestPractices,
  CxComprehensive,
  CxCrafting,
  CxDiscover,
  CxGetStarted,
  CxMethodology,
  CxSolutionIntro,
  CxSolutionPartners,
} from '../../components';

export const СxSolution = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <СxSolutionStyles>
      <CxSolutionIntro />
      <CxDiscover />
      <CxSolutionPartners />
      <CxComprehensive />
      <CxCrafting />
      <CxMethodology />
      <CxGetStarted />
      <CxBestPractices />
    </СxSolutionStyles>
  );
};
