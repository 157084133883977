import styled from 'styled-components';

export const SwiperCenteredSlidesPerViewAutoStyles = styled.div`
  .sliderNavContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1190px;
    margin: 0 auto;

    &.withLink {
      justify-content: space-between !important;
    }
  }

  .buttonsWrapper {
    display: flex;
    gap: 9px;
  }

  .swiper {
    margin-bottom: 58px;
  }

  .linkButton {
    position: relative;
    display: flex;
    align-items: center;

    padding: 0 54px;
    height: 49px;

    border-radius: 100px;
    transition: 0.5s all;
    cursor: pointer;
    color: #fff;
    border: 2px solid #fff;

    &:hover {
      color: #000;
    }

    span {
      transition: 0.3s all;
    }

    svg {
      position: absolute;
      right: 0;

      opacity: 0;
      transition: 0.5s all;
    }

    &:hover {
      background-color: #fff;

      span,
      svg {
        transform: translateX(-20px);
      }

      svg {
        opacity: 1;
      }
    }
  }
`;
