import styled from 'styled-components';

export const CustomerProfilingSegmentationExpertsStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .full-container {
    max-width: 1190px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin: 25px auto 0;
    position: relative;
    gap: 25px;

    @media (max-width: 991px) {
      gap: 15px;
    }
  }

  .cards {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 25px;
    flex-wrap: wrap;

    &-item {
      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 10px;
      max-width: 370px;
      height: 320px;
      border: 5px solid #AE19A4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;
      width: 100%;

      @media (max-width: 768px) {
        height: 280px;
      }

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding: 0 10px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
`;
