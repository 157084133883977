import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesMapStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const OpportunitiesMap = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesMapStyles>
      <div className="container">
        <h1
          dangerouslySetInnerHTML={{
            __html: getTranslationsByLangOrEng(
              interfaceLanguage,
              'opportunities_26',
            ),
          }}
        />
      </div>
    </OpportunitiesMapStyles>
  );
};
