import React, { FC } from 'react';

export const Other: FC<{ color: string; height: number; width: number }> = ({
  color,
  height,
  width,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5 0C11.475 0 0 11.475 0 25.5C0 39.525 11.475 51 25.5 51C39.525 51 51 39.525 51 25.5C51 11.475 39.525 0 25.5 0ZM25.5 46.75C13.8125 46.75 4.25 37.1875 4.25 25.5C4.25 13.8125 13.8125 4.25 25.5 4.25C37.1875 4.25 46.75 13.8125 46.75 25.5C46.75 37.1875 37.1875 46.75 25.5 46.75Z"
      fill={color}
    />
    <path
      d="M13.6 21.4619C11.2625 21.4619 9.5625 23.3744 9.5625 25.4994C9.5625 27.8369 11.475 29.5369 13.6 29.5369C15.9375 29.5369 17.6375 27.6244 17.6375 25.4994C17.6375 23.1619 15.9375 21.4619 13.6 21.4619Z"
      fill={color}
    />
    <path
      d="M25.5001 21.4619C23.1626 21.4619 21.4626 23.3744 21.4626 25.4994C21.4626 27.8369 23.3751 29.5369 25.5001 29.5369C27.8376 29.5369 29.5376 27.6244 29.5376 25.4994C29.5376 23.1619 27.8376 21.4619 25.5001 21.4619Z"
      fill={color}
    />
    <path
      d="M37.3998 21.4619C35.0623 21.4619 33.3623 23.3744 33.3623 25.4994C33.3623 27.8369 35.2748 29.5369 37.3998 29.5369C39.7373 29.5369 41.4373 27.6244 41.4373 25.4994C41.4373 23.1619 39.5248 21.4619 37.3998 21.4619Z"
      fill={color}
    />
  </svg>
);
