import styled from 'styled-components';

export const CxBestPracticesStyles = styled.div`
  padding: 100px 0;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  & .container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  h3 {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    width: 100%;
    margin-bottom: 40px;
  }

  .tabs-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
  }

  .tabs-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    cursor: pointer;
    padding-top: 40px;
    width: 25%;
    border-top: 2px solid #000;

    @media (max-width: 768px) {
      gap: 20px;
      padding-top: 20px;
    }

    &.active {
      border-color: #02ba95;
    }

    p {
      font-weight: 500;
      font-size: 32px;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .tab-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 80px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 28px;
    line-height: 115%;
    text-align: center;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  h6 {
    font-weight: 500;
    font-size: 28px;
    line-height: 115%;
    color: #ffffff;
    max-width: 570px;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 450px;
    flex-shrink: 0;

    li {
      display: flex;
      align-items: flex-start;
      gap: 30px;
      font-weight: 700;
      font-size: 28px;
      line-height: 115%;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 18px;
      }

      svg {
        flex-shrink: 0;
      }
    }
  }

  .cards-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;

    @media (max-width: 768px) {
      justify-content: space-around;
    }
  }

  .card {
    padding: 0 40px;
    background: linear-gradient(
      44.63deg,
      rgba(2, 186, 149, 0.45) -1.71%,
      rgba(2, 186, 149, 0) 105.49%
    );
    border: 4px solid #02ba95;
    border-radius: 10px;
    width: 270px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 115%;
    text-align: center;
    color: #ffffff;
    flex-shrink: 0;

    @media (max-width: 768px) {
      width: 200px;
      font-size: 14px;
      height: 150px;
    }
  }

  .other-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    gap: 50px;

    .submitButton {
      position: relative;
      display: flex;
      align-items: center;

      padding: 0 54px;
      height: 49px;
      background: transparent;
      border: 4px solid #02ba95 !important;
      border-radius: 100px;
      color: #fff;
      transition: 0.5s all;
      cursor: pointer;

      span {
        transition: 0.5s all;
      }

      svg {
        position: absolute;
        right: 0;

        opacity: 0;
        transition: 0.5s all;
      }

      &:hover {
        background-color: #02ba95;

        span,
        svg {
          transform: translateX(-20px);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  .automotive-wrapper,
  .tobacco-wrapper {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
  }
`;
