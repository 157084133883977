import styled from 'styled-components';

export const BrandAwarenessUsageSolutionsStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 28px;
    line-height: 125%;
    text-align: center;
    color: #000000;

    @media (max-width: 991px) {
      font-size: 16px;
    }
  }

  .cards {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &__item {
      width: calc(50% - 15px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      gap: 18px;
      border-radius: 5px;
      border: 2px solid #7E6DE4;

      @media (max-width: 768px) {
        width: 100%;
        padding: 15px;
      }

      img {
        width: 100%;
      }

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 125%;
        color: #0F0E0C;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
