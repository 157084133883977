import styled from 'styled-components';

export const FacilityCheckAuditMethodOneStyles = styled.div`
  .blocks {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    position: relative;

    @media screen and (max-width: 680px) {
      padding: 0 20px;
    }

    h6 {
      color: #0f0e0c;
      font-size: 28px;
      font-weight: 600;
      line-height: 125%;
      margin: 0;

      @media screen and (max-width: 680px) {
        font-size: 20px !important;
      }
    }

    p {
      color: #0f0e0c;
      font-size: 18px !important;
      line-height: 115% !important;
      position: relative;
      padding-left: 15px;

      @media screen and (max-width: 680px) {
        font-size: 14px !important;
      }

      &::before {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: linear-gradient(
          278deg,
          #042a40 4.83%,
          #9e00ff 72.7%
        ) !important;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .absolute {
      color: #fff;
      text-align: center;
      font-size: 28px;
      font-weight: 600;
      line-height: 125%;
      display: flex;
      width: 66px;
      height: 66px;
      flex-direction: column;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      border: 2px solid #000;
      background: linear-gradient(
        278deg,
        #042a40 4.83%,
        #9e00ff 72.7%
      ) !important;
      top: 50%;
      transform: translate(0, -50%);
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      width: 100%;

      @media screen and (max-width: 680px) {
        gap: 5px;
        width: 90%;
      }

      @media screen and (max-width: 400px) {
        width: 70%;
      }

      &:nth-child(2) {
        @media screen and (max-width: 400px) {
          padding-top: 50px;
        }
      }

      &:nth-child(3) {
        @media screen and (max-width: 400px) {
          padding-top: 50px;
        }
      }
    }

    &-textblock {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      z-index: 5;
      left: 60px;
    }

    &-one {
      height: 200px;
      max-width: 458px;
      width: 100%;
      border-radius: 115.5px 0 0 115.5px;
      border-top: 2px solid #000;
      border-left: 2px solid #000;
      border-bottom: 1px solid #000;
      background: linear-gradient(
        270deg,
        #fff 2.03%,
        rgba(255, 255, 255, 0) 100%
      );
      box-shadow: -22px 0px 40px 0px rgba(0, 0, 0, 0.15);
      position: relative;

      .absolute {
        left: -33px;
      }
    }

    &-two {
      height: 200px;
      width: 100%;
      max-width: 458px;
      align-self: flex-end;
      gap: 10px;
      border-radius: 0px 115.5px 115.5px 0px;
      border-top: 1px solid #000;
      border-right: 2px solid #000;
      border-bottom: 1px solid #000;
      background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      box-shadow: 22px 0px 40px 0px rgba(0, 0, 0, 0.15);
      position: relative;

      @media screen and (max-width: 680px) {
        height: 250px;
      }

      .absolute {
        right: -33px;
      }
    }

    &-three {
      height: 200px;
      max-width: 458px;
      width: 100%;
      border-radius: 115.5px 0 0 115.5px;
      border-top: 1px solid #000;
      border-left: 2px solid #000;
      border-bottom: 1px solid #000;
      background: linear-gradient(
        270deg,
        #fff 2.03%,
        rgba(255, 255, 255, 0) 100%
      );
      box-shadow: -22px 0px 40px 0px rgba(0, 0, 0, 0.15);
      position: relative;

      @media screen and (max-width: 680px) {
        height: 250px;
      }

      .absolute {
        left: -33px;
      }
    }

    &-four {
      height: 200px;
      width: 100%;
      max-width: 458px;
      align-self: flex-end;
      border-radius: 0px 115.5px 115.5px 0px;
      border-top: 1px solid #000;
      border-right: 2px solid #000;
      border-bottom: 2px solid #000;
      background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      box-shadow: 22px 0px 40px 0px rgba(0, 0, 0, 0.15);
      position: relative;

      .absolute {
        right: -33px;
      }
    }
  }
`;
