import React from 'react';
import { ButtonWithArrowOnHoverStyles } from './ButtonWithArrowOnHoverStyles';
import { translations } from '../../i18n/translations';
import ArrowRight from '../../assets/icons/ArrowRight';

interface Props {
  text: string;
  background: string;
  hoverBackground: string;
  textColor: string;
  hoverTextColor: string;
  arrowColor: string;
  onClick?: () => void;
  className?: string;
  borderColor?: string;
  borderOnHover?: string;
  link?: string;
  withoutArrow?: boolean;
}

function ButtonWithArrowOnHover({
  arrowColor,
  hoverBackground,
  background,
  hoverTextColor,
  textColor,
  onClick,
  text,
  className,
  borderColor,
  borderOnHover,
  link,
  withoutArrow,
}: Props) {
  return (
    <ButtonWithArrowOnHoverStyles
      borderOnHover={borderOnHover}
      borderColor={borderColor}
      className={className}
      backgroundOnHover={hoverBackground}
      background={background}
      textColorOnHover={hoverTextColor}
      textColor={textColor}
      withoutArrow={withoutArrow}
    >
      {link ? (
        <a href={link}>
          <span className="text16">{text}</span>
          {!withoutArrow && (
            <ArrowRight color={arrowColor} />
          )}
        </a>
      ) : (
        <button type="button" onClick={onClick}>
          <span className="text16">{text}</span>
          {!withoutArrow && (
            <ArrowRight color={arrowColor} />
          )}
        </button>
      )}
    </ButtonWithArrowOnHoverStyles>
  );
}

export default ButtonWithArrowOnHover;
