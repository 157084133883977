import styled from 'styled-components';

export const FacilityCheckAuditReportsStyles = styled.div`
  padding: 100px 0 50px 0;
  width: 100%;

  @media (max-width: 900px) {
    padding: 50px 0;
  }

  .cards {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-width: 1190px;
    margin: 15px auto 0;
    gap: 30px;

    @media (max-width: 900px) {
      flex-direction: column;
      width: 95%;
    }

    .card {
      border-radius: 5px;
      border: 1px solid var(--Gradient-Check-Audit, #9e00ff);
      background: #fff;
      color: #0f0e0c;
      font-size: 20px;
      font-weight: 400;
      line-height: 125%;
      padding: 20px 35px 25px 30px;
      width: 50%;

      @media (max-width: 900px) {
        width: calc(100% - 66px);
      }

      span {
        font-weight: 600;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background: linear-gradient(
            278deg,
            #042a40 4.83%,
            #9e00ff 72.7%
          ) !important;
          left: -15px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
`;
