import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditWhoStyles } from './FacilityCheckAuditWhoStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import FacilityCheckAuditWho1 from '../../../assets/images/FacilityCheckAuditWho1.png';
import FacilityCheckAuditWho2 from '../../../assets/images/FacilityCheckAuditWho2.png';
import FacilityCheckAuditWho3 from '../../../assets/images/FacilityCheckAuditWho3.png';

export const FacilityCheckAuditWho = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditWhoStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_32',
          )}
        </h5>
        <div className="content">
          <h4>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_33',
            )}
          </h4>
          <p>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_34',
            )}
          </p>
        </div>
      </div>
      <div className="cards">
        <div className="card">
          <span>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_35',
            )}
          </span>{' '}
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_36',
          )}
        </div>
        <div className="card">
          <span>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_37',
            )}
          </span>{' '}
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_38',
          )}
        </div>
        <div className="card">
          <span>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_39',
            )}
          </span>{' '}
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_40',
          )}
        </div>
      </div>
      <div className="posts">
        <div className="post">
          <img src={FacilityCheckAuditWho1} alt="" />
          <p>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_41',
            )}
          </p>
        </div>
        <div className="post">
          <img src={FacilityCheckAuditWho2} alt="" />
          <p>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_42',
            )}
          </p>
        </div>
        <div className="post">
          <img src={FacilityCheckAuditWho3} alt="" />
          <p>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_43',
            )}
          </p>
        </div>
      </div>
    </FacilityCheckAuditWhoStyles>
  );
});
