import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';
import { ConsumerJourneyMapIntroStyles } from './styles';
import bg from '../../../assets/images/consumerJourneyMap/intro-bg.png';

export const ConsumerJourneyMapIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ConsumerJourneyMapIntroStyles>
      <Banner
        bgColor="linear-gradient(86.82deg, rgba(253, 162, 114, 0.9) -3.09%, rgba(241, 89, 103, 0.5) 100%)"
        title={getTranslationsByLangOrEng(
          interfaceLanguage,
          'consumer_journey_map_1',
        )}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'consumer_journey_map_2',
        )}
        buttonKey="usage_attitude_11"
        image={bg}
      />
    </ConsumerJourneyMapIntroStyles>
  );
};
