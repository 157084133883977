import styled from 'styled-components';

export const NotFoundCareerStyles = styled.div`
  padding: 50px 0;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    padding: 50px 10px;
    border-top: 1px solid #0000ff;
    border-bottom: 1px solid #0000ff;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }
  }

  .not-found {
    &__text {
      display: flex;
      flex-direction: column;
      max-width: 570px;
      gap: 10px;

      h2 {
        margin: 0;
        font-weight: 600;
        font-size: 60px;
        line-height: 115%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 32px;
        }
      }

      h6 {
        margin: 0;
        font-weight: 600;
        font-size: 34px;
        line-height: 120%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
    }
  }
`;
