import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { Experts4OpinionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import card1 from '../../../assets/images/4opinion/card-1.png';
import card2 from '../../../assets/images/4opinion/card-2.png';
import card3 from '../../../assets/images/4opinion/card-3.png';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { SwiperSlide } from 'swiper/react';
import FourOpinionUser from '../../../assets/icons/FourOpinionUser';

export const Experts4Opinion = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <Experts4OpinionStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_24')}</h2>
      </div>
      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          centeredSlides
          loop
          buttonBorderColor="#0000FF"
          buttonsIconsColor="#000"
        >
          <SwiperSlide className="slide">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <div className="card-user">
                    <FourOpinionUser />
                    <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_25')}</h6>
                  </div>
                  <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_26')}</p>
                </div>
                <div className="card-img">
                  <img src={card1} alt="" />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <div className="card-user">
                    <FourOpinionUser />
                    <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_27')}</h6>
                  </div>
                  <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_28')}</p>
                </div>
                <div className="card-img">
                  <img src={card2} alt="" />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <div className="card-user">
                    <FourOpinionUser />
                    <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_29')}</h6>
                  </div>
                  <p>{getTranslationsByLangOrEng(interfaceLanguage, 'four_opinion_30')}</p>
                </div>
                <div className="card-img">
                  <img src={card3} alt="" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </Experts4OpinionStyles>
  );
};
