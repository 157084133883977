import styled from 'styled-components';

export const ServiceDesignCalendarStyles = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;
  }

  .cards {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 25px;

    @media (max-width: 768px) {
      justify-content: center;
    }

    &-calendar {
      padding: 35px 42px;
      max-width: 370px;
      width: 100%;
      border: 1px solid #ffed0a;
      box-sizing: border-box;

      img {
        width: 100%;
      }
    }

    &-item {
      border: 1px solid #ffed0a;
      max-width: 370px;
      width: 100%;

      img {
        width: 100%;
      }
    }

    &-text {
      padding: 25px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      h6 {
        font-weight: 700;
        font-size: 20px;
        line-height: 125%;
        color: #0f0e0c;
        margin: 0;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        color: #0f0e0c;
        margin: 0;
      }

      a {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          color: #000000;
        }
      }
    }
  }

  .btns {
    margin-top: 25px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;
