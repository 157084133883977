import React from 'react';

function ArrowRightButtonIcon({ width = 18, height = 16, color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill={color}
        d="M1 7a1 1 0 000 2V7zm16.707 1.707a1 1 0 000-1.414L11.343.929A1 1 0 109.93 2.343L15.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM1 9H16.999L17 8V7h-.003-.001-.003-.002-.002-.005-.003-.003-.003-.011H1v2z"
      />
    </svg>
  );
}

export default ArrowRightButtonIcon;
