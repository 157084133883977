import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { UsageAttitudeIntroStyles } from './styles';
import Banner from '../../banner/Banner';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const UsageAttitudeIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <UsageAttitudeIntroStyles>
      <Banner
        bgColor="linear-gradient(278deg, #5498FF 4.83%, #7E6DE4 72.7%)"
        title={getTranslationsByLangOrEng(
          interfaceLanguage,
          'usage_attitude_1',
        )}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'usage_attitude_2',
        )}
        buttonKey="usage_attitude_3"
      />
    </UsageAttitudeIntroStyles>
  );
};
