import styled from 'styled-components';

export const SociologicalResearchSliderStyles = styled.div`
  width: 100%;
  margin-top: 100px !important;

  @media (max-width: 991px) {
    margin-top: 50px !important;
  }

  .sliderWrapper {
    margin-top: 50px;
  }

  .texts {
    max-width: 770px;
    width: 100%;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .slide {
    max-width: 770px;
    height: 550px;
    width: 100%;
    position: relative;
    background: #fff;
    flex-shrink: 0;

    @media (max-width: 768px) {
      max-width: 320px;
      height: 450px;
    }

    &-img {
      height: 50%;
      position: relative;

      border-radius: 10px 10px 0 0;
      border-left: 5px solid #F05A25;
      border-right: 5px solid #F05A25;
      border-top: 5px solid #F05A25;
      overflow: hidden;

      @media (max-width: 768px) {
        height: 30%;
      }

      &::before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(180deg, rgba(250, 175, 59, 0.24) 0%, rgba(240, 90, 37, 0.73) 100%);
        border-radius: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: cover;
        z-index: 1;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      padding: 30px;
      gap: 20px;
      height: calc(50% - 70px);

      border-radius: 0 0 10px 10px;
      border-left: 5px solid #F05A25;
      border-right: 5px solid #F05A25;
      border-bottom: 5px solid #F05A25;

      @media (max-width: 768px) {
        padding: 10px 15px;
        gap: 10px;
        height: calc(70% - 70px);
      }

      h5 {
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        color: #000;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 115%;
        color: #000000;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .btn-wrapper {
    max-width: 1190px;
    margin: 50px auto 0;
  }
`;
