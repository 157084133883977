import React, { useState } from 'react';
import { LangMenuMobileStyles } from './LangMenuMobileStyles';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { LANGUAGES, pageLanguages } from '../../constants/LANGUAGES';
import ILanguage from '../../enteties/ILanguage';
import { setInterfaceLanguage } from '../../state/slices/languageSlice';
import { useLocation, useNavigate } from 'react-router-dom';

function LangMenuMobile() {
  const [langMenuOpen, setLangMenuOpen] = useState<boolean>(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const dispatch = useAppDispatch();

  return (
    <LangMenuMobileStyles className="">
      {/* eslint-disable-next-line react/button-has-type */}
      <button onClick={() => setLangMenuOpen(true)}>
        {LANGUAGES[interfaceLanguage].name}
      </button>

      <ul className={langMenuOpen ? 'langMenuOpen langMenu' : 'langMenu'}>
        <li className="selectedLanguage" onClick={() => setLangMenuOpen(false)}>
          <span>{LANGUAGES[interfaceLanguage].name}</span>
          <img src={LANGUAGES[interfaceLanguage].icon} alt="Flag" />
        </li>

        {Object.values(LANGUAGES)
          .filter((el) => pageLanguages(pathname.split('/')[2]).includes(el.code))
          .map((lang: ILanguage, index) => {
            if (lang.code !== interfaceLanguage) {
              return (
                <li key={lang.name}>
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(setInterfaceLanguage(lang.code));

                      const splittedPathName = pathname.split('/');
                      splittedPathName[1] = lang.code;
                      navigate(`${splittedPathName.join('/')}`);
                      setLangMenuOpen(false);
                    }}
                  >
                    <span>{lang.name}</span>

                    <img src={lang.icon} alt="Flag" />
                  </button>
                </li>
              );
            }

            return '';
          })}
      </ul>
    </LangMenuMobileStyles>
  );
}

export default LangMenuMobile;
