import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { AdHocApproachStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const AdHocApproach = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <AdHocApproachStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_4')}</h1>
        </div>
        <div className="content">
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_5')}</p>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_6')}</p>
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#fff"
            textColor="#fff"
            borderColor="#FCC666"
            borderOnHover="transparent"
            background="transparent"
            className="btn"
            hoverBackground="linear-gradient(6deg, rgba(252, 198, 102, 0.80) -12.03%, rgba(250, 26, 46, 0.60) 51.54%, #5CB7F1 115.1%)"
            hoverTextColor="#fff"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'sociological_research_3',
            )}
          />
        </div>
      </div>
    </AdHocApproachStyles>
  );
};
