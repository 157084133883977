import styled, { css } from 'styled-components';

function getCasesStyles(length: number) {
  let styles = '';

  let counter: number = 1;

  for (let i = 1; i <= length; i += 1) {
    if (counter === 1) {
      counter++;

      styles += `
           div:nth-child(${i}) {
             grid-column: 1/9;
          }
        `;
    } else if (counter === 2) {
      counter++;

      styles += `
           div:nth-child(${i}) {
              grid-column: 9/13;

        .headContainer {
          position: relative;

          .captions {
            padding: 28px 14px;

            @media (max-width: 1000px) {
              padding: 16px 15px;
            }

            @media (max-width: 575px) {
              padding: 19px 20px;
            }
          }
        }

        .textContainer {
          p {
            font-weight: 700;
            font-size: 20px;
            line-height: 115%;

            @media (max-width: 1000px) {
              font-size: 11px;
            }
          }

          @media (max-width: 575px) {
            padding: 19px 20px;
          }
        }
           }
        `;
    } else if (counter === 3) {
      counter++;
      styles += `
        div:nth-child(${i}) {
           grid-column: 1/8;

        .captions {
          padding: 28px 26px;

          @media (max-width: 1000px) {
            padding: 16px 15px;
          }

          @media (max-width: 575px) {
            padding: 19px 20px;
          }
        }

        .textContainer {
          p {
            font-size: 30px;

            @media (max-width: 1000px) {
              font-size: 17px;
            }
          }
        }
        }  
        `;
    } else if (counter === 4) {
      counter = 1;
      styles += `
           div:nth-child(${i}) {
             grid-column: 8/13;

        .captions {
          padding: 28px 17px;

          @media (max-width: 1000px) {
            padding: 16px 15px;
          }

          @media (max-width: 575px) {
            padding: 19px 20px;
          }
        }

        .textContainer {
          p {
            font-size: 24px;

            @media (max-width: 1000px) {
              font-size: 14px;
            }
          }
        }
          }
        `;
    }
  }
  return css`
    ${styles}
  `;
}

export const CasesStyles = styled.div<{ length: number }>`
  background: #000;

  padding: 0 0 215px;

  @media (max-width: 1000px) {
    padding: 0 0 89px;
  }

  @media (max-width: 700px) {
    padding: 0 0 100px;
  }

  .titleWrapper {
    padding-top: 65px;
    margin-bottom: 12px;

    @media (max-width: 700px) {
      padding-top: 35px;
    }

    h1 {
      margin-bottom: 40px;

      font-weight: 600;
      font-size: 45px;
      line-height: 115%;

      text-align: center;

      color: #ffffff;

      @media (max-width: 700px) {
        font-size: 36px;
      }
    }
  }

  .hashtagsWrapper {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 56px;

    button {
      background: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      border: none;
      padding: 0;
      cursor: pointer;
      box-sizing: border-box;

      &.active {
        border-bottom: 2px solid #0000ff;
      }
    }
  }

  .lastItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    color: #fff;
    border: 2px solid #0000ff;
    border-radius: 10px;

    @media (max-width: 699px) {
      display: none;
    }

    button {
      margin-left: auto;

      @media (max-width: 1000px) {
        padding: 0 16px;

        &:hover {
          span {
            transform: translateX(-10px);
          }

          svg {
            transform: translateX(-8px);
          }
        }

        span {
          font-size: 12px;
          line-height: 12px;
        }

        svg {
          width: 20px;
          height: 30px;
        }
      }
    }

    &.small {
      font-weight: 600;
      font-size: 35px;
      line-height: 115%;

      grid-column: 9/13;
      padding: 28px 35px;

      @media (max-width: 1000px) {
        font-size: 14px;
      }
    }

    &.medium {
      font-weight: 600;
      font-size: 50px;
      line-height: 115%;

      grid-column: 8/13;
      padding: 28px 35px;

      @media (max-width: 1000px) {
        font-size: 17px;
      }
    }

    &.large {
      font-weight: 500;
      font-size: 80px;
      line-height: 115%;

      grid-column: 1/13;
      padding: 45px;

      @media (max-width: 1000px) {
        font-size: 40px;
      }
    }
  }
  .moreButton {
    display: flex;
    justify-content: center;
  }

  .casesList {
    margin-bottom: 80px;

    @media (max-width: 1000px) {
      margin-bottom: 45px;
    }

    @media (max-width: 700px) {
      margin-bottom: 65px;
    }
  }

  .loadMoreButtonWrapper {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 700px) {
    .casesList {
      display: grid;
      gap: 30px;
    }
  }

  @media (min-width: 700px) {
    .casesList {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 30px;

      ${(props) => getCasesStyles(props.length)}//.one {
      //  grid-column: 1/9;
      //} 
      //
      //.two {
      //  grid-column: 9/13;
      //
      //  .headContainer {
      //    position: relative;
      //
      //    .captions {
      //      padding: 28px 14px;
      //
      //      @media (max-width: 1000px) {
      //        padding: 16px 15px;
      //      }
      //
      //      @media (max-width: 575px) {
      //        padding: 19px 20px;
      //      }
      //    }
      //  }
      //
      //  .textContainer {
      //    p {
      //      font-weight: 700;
      //      font-size: 20px;
      //      line-height: 115%;
      //
      //      @media (max-width: 1000px) {
      //        font-size: 11px;
      //      }
      //    }
      //
      //    @media (max-width: 575px) {
      //      padding: 19px 20px;
      //    }
      //  }
      //}
      //
      //.three {
      //  grid-column: 1/8;
      //
      //  .captions {
      //    padding: 28px 26px;
      //
      //    @media (max-width: 1000px) {
      //      padding: 16px 15px;
      //    }
      //
      //    @media (max-width: 575px) {
      //      padding: 19px 20px;
      //    }
      //  }
      //
      //  .textContainer {
      //    p {
      //      font-size: 30px;
      //
      //      @media (max-width: 1000px) {
      //        font-size: 17px;
      //      }
      //    }
      //  }
      //}
      //
      //.four {
      //  grid-column: 8/13;
      //
      //  .captions {
      //    padding: 28px 17px;
      //
      //    @media (max-width: 1000px) {
      //      padding: 16px 15px;
      //    }
      //
      //    @media (max-width: 575px) {
      //      padding: 19px 20px;
      //    }
      //  }
      //
      //  .textContainer {
      //    p {
      //      font-size: 24px;
      //
      //      @media (max-width: 1000px) {
      //        font-size: 14px;
      //      }
      //    }
      //  }
      //}
    }
  }
`;
