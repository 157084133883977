import styled from 'styled-components';

export const BrandAwarenessUsageClientMethodStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 125%;
      color: #0F0E0C;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 125%;
      color: #0F0E0C;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
`;
