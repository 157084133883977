import styled from 'styled-components';

export const OurClientsStyles = styled.div`
  padding: 53px 0 144px;

  h2 {
    text-align: center;
    margin-bottom: 45px;
    font-size: 40px;
    line-height: 120%;

    @media (max-width: 1000px) {
      font-weight: 700;
      font-size: 23px;
      line-height: 120%;
      margin-bottom: 22px;
    }

    @media (max-width: 600px) {
      font-size: 26px;
      line-height: 120%;
      margin-bottom: 54px;
    }
  }

  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border: 1px solid #cccccc;
    width: 370px;
    height: 370px;
    border-radius: 100%;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;

    color: #000000;

    margin-left: -50px;

    img {
      width: 100%;
      height: 100%;
    }

    img:nth-child(2) {
      display: none;
    }

    &:hover {
      img:nth-child(1) {
        display: none;
      }

      img:nth-child(2) {
        display: block;
      }
    }

    @media (max-width: 1000px) {
      height: 197px;
      width: 197px;

      margin-left: -30px;
      font-size: 21px;
    }

    @media (max-width: 600px) {
      height: 200px;
      width: 200px;

      margin-left: -30px;
      font-size: 21px;
    }
  }
`;
