import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { AdHocExperienceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import experience from '../../../assets/images/adHoc/experience.png';

export const AdHocExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <AdHocExperienceStyles>
      <div className="container">
        <div className="content">
          <div className="text">
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_16')}</h6>
            <h1>
              {getTranslationsByLangOrEng(interfaceLanguage, 'ad_hoc_17')}
            </h1>
          </div>
          <div className="gradientimg">
            <img src={experience} alt="" />
          </div>
        </div>
      </div>
    </AdHocExperienceStyles>
  );
};
