import styled from 'styled-components';

export const CustomerProfilingSegmentationQuoteStyles = styled.div`
  .container {
    margin-top: 150px !important;

    @media (max-width: 991px) {
      margin-top: 100px !important;
    }
  }

  .quote {
    position: absolute;
    font-weight: 700;
    font-size: 200px;
    line-height: 115%;
    background: linear-gradient(180deg, #FA3DC2 0%, #AE19A4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    transform: translate(-50%, -50%);
    left: -70px;
    top: 50px;

    @media (max-width: 991px) {
      left: 40px;
      top: -10px;
      font-size: 150px;
    }
  }
`;
