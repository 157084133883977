import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { CustomerProfilingSegmentationSolutionStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import solution1 from '../../../assets/images/customerProfilingSegmentation/solution-1.png';
import solution2 from '../../../assets/images/customerProfilingSegmentation/solution-2.png';
import solution3 from '../../../assets/images/customerProfilingSegmentation/solution-3.png';
import solution4 from '../../../assets/images/customerProfilingSegmentation/solution-4.png';

export const CustomerProfilingSegmentationSolution = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <CustomerProfilingSegmentationSolutionStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_8')}
          </h2>
        </div>
      </div>
      <div className="full-container">
        <div className="cards">
          <div className="cards-item">
            <img src={solution1} alt="solution" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_9')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_10')}</p>
          </div>
          <div className="cards-item">
            <img src={solution2} alt="solution" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_11')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_12')}</p>
          </div>
          <div className="cards-item">
            <img src={solution3} alt="solution" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_13')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_14')}</p>
          </div>
          <div className="cards-item">
            <img src={solution4} alt="solution" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_15')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'customer_profiling_segmentation_16')}</p>
          </div>
        </div>
        <div className="btn-container">
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#fff"
            textColor="#000"
            borderColor="#FA3DC2"
            borderOnHover="#000"
            background="linear-gradient(180deg, #FA3DC2 0%, #AE19A4 100%)"
            hoverBackground="#000"
            hoverTextColor="#fff"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'consumer_journey_map_18',
            )}
          />
        </div>
      </div>
    </CustomerProfilingSegmentationSolutionStyles>
  );
};
