import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { SociologicalResearchIntroStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const SociologicalResearchIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchIntroStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_1')}</h1>
        <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_2')}</p>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#000"
          textColor="#FFF"
          borderColor="#FFF"
          borderOnHover="#FFF"
          background="none"
          hoverBackground="#FFF"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_3')}
        />
      </div>
    </SociologicalResearchIntroStyles>
  );
};
