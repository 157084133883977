import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const SociologicalResearchPrinciplesStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 48px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 28px;
      line-height: 125%;
      color: #000000;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .logos {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 768px) {
      gap: 20px;
      flex-direction: column;
    }
  }
`;
