export const az: { [key: string]: string } = {
  subscribe_with_click: 'Bir kliklə abunə olun',
  subscribe: 'Bir kliklə abunə olun',
  minimum_number_characters: 'minimum 2 simvol sayı',
  request_details: 'Təfərrüatları tələb edin',
  form_title: 'Tezliklə sizinlə əlaqə saxlayacağıq',
  form_required: '* ilə işarələnmiş sahələr məcburidir',
  form_name_placeholder: 'ad',
  form_email_placeholder: 'E-poçt',
  form_phone_placeholder: 'Telefon nömrəsi',
  form_company_placeholder: 'Şirkət',
  form_submit: 'təqdim',
  form_1: 'Fərdi məlumatların müvafiq qaydada işlənməsinə razılığımı verirəm',
  form_2: 'Gizlilik Siyasəti',
  validation_privacy_policy: 'Zəhmət olmasa Məxfilik Siyasətini qəbul edin',
  validation_phone: 'Telefon nömrəsi etibarlı deyil',
  validation_email: 'Yanlış e-poçt formatı',
  validation_required: 'Tələb olunan sahələr doldurulmur',
  submit_error: 'Formanı saxlayarkən xəta baş verdi',
  footer_1: 'Bizim tərəfdaşlarımız:',
  footer_2: 'Metodlar',
  footer_3: 'Məhsullar',
  footer_4: 'Əlaqələr:',
  footer_5: 'Çap',
  footer_6: 'Gizlilik Siyasəti',
  footer_7: 'Bazar Araşdırması',
  about_us_1: '4Service Korporativ Sosial Məsuliyyət',
  about_us_2:
    'Komandamızın hər bir üzvünün ürəyindən və ruhundan. Biz hər bir işçimizdə xeyirxahlıq qığılcımı saxlamaq və ətrafımızdakı dünyanı bir az daha yaxşı etmək üçün əlimizdən gələni edirik',
  about_us_3: '4Service Korporativ Sosial Məsuliyyət',
  about_us_4:
    'Komandamızın hər bir üzvünün ürəyindən və ruhundan. Biz hər bir işçimizdə xeyirxahlıq qığılcımı saxlamaq və ətrafımızdakı dünyanı bir az daha yaxşı etmək üçün əlimizdən gələni edirik',
  about_us_5: 'Service Mania biznes oyunu',
  about_us_6: 'Uşaqlar və tələbələr üçün təbliğat təşəbbüslərimiz',
  about_us_7: 'Xeyriyyəçilik / Könüllülük',
  about_us_8:
    'Uşaq evləri, qocalar üçün qayğı evləri və Ukrayna ordusunda könüllülük. Fəaliyyətimizin ən hərəkətli hissəsi',
  about_us_9: 'Heyvanların rifahı',
  about_us_10:
    'Hər kəs ev heyvanı ilə ofisimizə gələ bilər. Biz sığınacaqlara əl uzadırıq, yemək üçün vəsait toplayır və bəzən evsiz heyvanlar üçün ev tapmağa çalışırıq',
  about_us_11: '4Service Qrup',
  about_us_12: 'Müştəri təcrübəsini idarə edən beynəlxalq tədqiqat şirkətidir.',
  about_us_13:
    'Satışları artırmaq və müştəri yönümlü şirkətlərin biznesini inkişaf etdirmək üçün kompleks layihələr həyata keçiririk.',
  about_us_14:
    'Baş ofisi Vyanada, Avstriyada olan beynəlxalq tədqiqat holdinqidir. Şirkət 2001-ci ildə müştərilərinin və bizneslə qarşılıqlı əlaqədə olan, onun xidmətlərindən istifadə edən və məhsullarını istehlak edən insanların həyat keyfiyyətini yaxşılaşdırmaq məqsədi ilə yaradılmışdır. Axı, bu səbəbdən "Forservice" adı müştəri xidməti və yüksək keyfiyyəti təmsil edən bir şirkət üçün seçildi.',
  about_us_15:
    'Bu, sirli alış-veriş xidmətlərinin aparıcı təchizatçısı kimi Ukraynanın Kiyev şəhərində 4Service tarixinin başlanğıcı oldu. Bütün müştəri təcrübəsi zəncirini təhlil etmək və təkmilləşdirmək üçün vahid yanaşmada yeni imkanlara malik 52 ölkədə tədqiqat şirkəti kimi uğurla fəaliyyətini davam etdirmişdir',
  about_us_16: 'xidmət adına doğulmuşdur',
  about_us_17: 'ilk ofis Ukraynadan kənarda yerləşir',
  about_us_18: 'qərargahının Vyanaya köçürülməsi',
  about_us_19: 'elit MSPA üzvü, Avropanın Top-10 agentliyi',
  about_us_20: '9 ölkədə ofislər yaradır',
  about_us_21: 'ilk rəqəmsal layihələr, Voicer',
  about_us_22: 'DesignThinkers Akademiyasının tərəfdaşları',
  about_us_23: 'ilk qlobal CX layihələri',
  about_us_24: 'Missiya',
  about_us_25:
    'Missiyamız bütün dünyada müştəri xidmətlərini təkmilləşdirməkdir! Bizim ilhamımız işlədiyimiz insanlarda və şirkətlərdə gördüyümüz keyfiyyət dəyişiklikləridir.',
  about_us_26: '20+ illik Müştəri Təcrübəsi',
  about_us_27: '95 ölkə',
  about_us_28:
    'Biz dünyanın hər yerindən ən yaxşı sənaye təcrübəsini toplayırıq və paylaşırıq',
  about_us_29: 'Baş ofisi Vyanada',
  about_us_30:
    'ölkələr. Biz dünyanın hər yerindən ən yaxşı sənaye təcrübəsini toplayırıq və paylaşırıq',
  about_us_31: 'xidmət keyfiyyəti reytinqləri',
  about_us_32: 'biz ilk 10 Avropa CX Agentliklərindəyik',
  about_us_33: 'Avstriyadakı nümayəndəliklər / HQ',
  about_us_34: 'aktiv layihələr',
  about_us_35: 'Gələcək iş yeriniz',
  about_us_36: 'Ofislərimiz',
  about_us_37:
    '4Service ™ işçiləri üçün rahat iş sahəsi yaratmaq üçün səy göstərir. Əməkdaşlarımız hər bir işçinin ən yaxşı rahatlığı və məhsuldarlığı üçün yerində və ya uzaqdan və ya hibrid rejimdə işləyirlər. İstirahət üçün otaqlar, pulsuz psixoloji dəstək və qonaqpərvər mühit. Biz insana və ev heyvanlarına dostuq.',
  about_us_38: 'İşıqlandıran komanda',
  about_us_39:
    'Bizim çoxmillətli komandamız dinləmək, müştərilərimizdən gələn rəylərə əsaslanmaq və qarşılıqlı inkişafa sadiqdir. Müştərilərimizin bizimlə olan müştəri səyahət xəritəsi biznesimiz üçün yeni məhsullar çıxarmaq və ya maliyyə hədəflərimizə çatmaq qədər vacibdir. Fəaliyyətlərimiz ətrafımızdakı icmalara müsbət təsir göstərmək və müştərilərimiz üçün innovasiya və insan mərkəzli həllər təqdim etmək məqsədi daşıyır.',
  about_us_40: 'Hamısı',
  about_us_41: 'İdarəetmə',
  about_us_42: 'Qlobal Komanda',
  about_us_43: 'Məhsul sahibləri',
  about_us_44: 'Bizim tərəfdaşlarımız',
  about_us_45:
    'Satışları artırmaq və müştəri yönümlü şirkətlərin bizneslərini inkişaf etdirmək üçün kompleks layihələr həyata keçiririk',
  about_us_46:
    '25 ölkədə təmsil olunan və müsbət dəyişikliyə səbəb olmaq üçün yaradıcı multidissiplinar komandaları və icmaları hazırlayır, inkişaf etdirir və asanlaşdırır. Dizayn Düşüncəsi sizə innovativ ideyalar, dəyişiklik və mürəkkəb problemlərin həlli yollarını inkişaf etdirməyə və çatdırmağa imkan verir.',
  about_us_47:
    'Onların missiyası komandalara və fərdlərə ilham almaq və meydan oxumaq, “etməklə öyrənmək” və yaradıcı problemlərin həlli üçün bacarıqlarını gücləndirmək və təşkilatlara cəsarətli yeniliyə başlamaq üçün lazımi mühiti və düşüncə tərzini yaratmağa kömək etmək üçün yer və vaxt təmin etməkdir.',
  about_us_48:
    'Mystery Shopping sənayesində iştirak edən şirkətlər üçün təmsil olunan Ticarət Assosiasiyasıdır. Assosiasiya Amerika, Avropa/Afrika və Asiya/Sakit okean regionunda yerləşən bölmələri ilə bütün dünyada regional əsasda fəaliyyət göstərir. Üzv şirkətlər birləşmiş səylər və tədbirlər vasitəsilə Mystery Shopping sənayesini gücləndirmək məqsədilə ümumi orqan kimi birləşirlər.',
  about_us_49:
    'qeyri-kommersiya üzvlük təşkilatıdır. Üzvlər cəmiyyətləri, təşkilatları və hər kəsin həyatını yaxşılaşdırmağa kömək etmək üçün məlumat analitikasının, tədqiqatın və fikirlərin potensialına əmin olan icma təşkil edirlər.',
  about_us_50:
    'ESOMAR-ın baş ofisi Hollandiyanın Amsterdam şəhərində yerləşir. ESOMAR 1947-ci ildən bəri üzvləri vasitəsilə 130-dan çox ölkədə fəaliyyət göstərir.',
  about_us_51:
    '(UCCA) əlaqə mərkəzi və müştəri xidmətləri sahəsində inkişaf və qarşılıqlı əlaqə məqsədi ilə birləşmiş özəl ekspertlər və şirkətlərin peşəkar icmasıdır. UCCA-nın fəlsəfəsi müasir texnologiyalara və yüksək standartlara əsaslanan əlaqə mərkəzi sənayesinin mədəniyyətini yüksəldir.',
  about_us_52: 'Ukrayna Əlaqə Mərkəzi Assosiasiyası 2010-cu ildə yaradılıb',
  about_us_53:
    'Ukrayna bazarında uyğunlaşdırmaq və istifadə etmək üçün təhsil sahəsində ən yaxşı dünya təcrübəsini öyrənir. Buna görə də Academy DTEK aparıcı beynəlxalq biznes məktəbləri ilə əməkdaşlıq edir: INSEAD, IE Business School və Stanford Universiteti.',
  about_us_54:
    'Academy DTEK D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, DX School rəqəmsal proqramı, Effektiv Lider: Pre-MBA, Change Energy-də müştəri yönümlü və innovativ mədəniyyətin inkişafına yönəlib.',
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Beynəlxalq satış meneceri',
  bht_1: 'Brend Sağlamlığı',
  bht_2: 'İzləmə',
  bht_3: 'Brendlər inanılmaz dərəcədə güclüdür',
  bht_4:
    'Brendinizin ümumilikdə nə qədər yaxşı performans göstərdiyini öyrənin',
  bht_5: 'Haqqında',
  bht_6: 'Brend Sağlamlığının İzlənməsi nədir?',
  bht_7:
    'Bu, müəyyən bir müntəzəmliklə (iki həftədən bir, hər ay və s.) həyata keçirilən bazar araşdırması növüdür',
  bht_8:
    'məşhurluq, tələbat, imic, onun istehlakçılarının xüsusiyyətləri, brendin sağlamlığı və s. kimi göstəricilərə əsaslanan bir markanın bazardakı vəziyyəti.',
  bht_9: 'Ən tez-tez marka alın',
  bht_10: 'Sadiq',
  bht_11: 'Brend 3 aydır alınıb',
  bht_12: 'Repertuar / daimi müştərilər',
  bht_13: 'Markanı 6 aydır alıb',
  bht_14: 'Qısa müddətdə müştərilər',
  bht_15: 'Alıb marka bir il',
  bht_16: 'Uzun müddətli müştərilər',
  bht_17:
    'Brenddən xəbəriniz varmı? amma heç vaxt istifadə etməmişəm/almamışam',
  bht_18: 'Məlumatlı, lakin müştərilər deyil',
  bht_19: 'Xəbərsiz: brend haqqında heç eşitməmişəm',
  bht_20: 'Xəbərsiz',
  bht_21: 'Brend alıcılarının profili',
  bht_22:
    'Brend yaratmaq üçün uzun müddətli səylərin ardıcıllığının qiymətləndirilməsi',
  bht_23: 'Müştərilərin brend haqqında bilik səviyyəsinin ölçülməsi',
  bht_24: 'Reklam və məlumat kampaniyalarının effektivliyini göstərmək',
  bht_25:
    'Yüksək reklam dövrünün rəqiblərin satış/marketinq fəaliyyətinə təsirinin müəyyən edilməsi',
  bht_26:
    'Davamlı bazar dəyişiklikləri dövründə iş sahibinə daimi nəzarətin təmin edilməsi',
  bht_27: 'BHT hansı vəzifələri həll edə bilər:',
  bht_28: 'Araşdırma tələb edin',
  bht_29: 'Həll etmək üçün tapşırıq',
  bht_30: 'Misal üçün,',
  bht_31:
    'bazar paylarının ölçülməsi zamanı şirkətə rübdə yalnız bir BHT seansına ehtiyac varsa, reklamdan müştərinin brend biliklərini ölçərkən respondentlər arasında sorğu daha tez-tez aparılmalıdır.',
  bht_32: 'CATI',
  bht_33: 'Üzbəüz',
  bht_53: 'Müsahibələr',
  bht_34: 'СAWI',
  bht_35: 'Həll etmək üçün tapşırıq',
  bht_36: 'Məlumat toplamaqla',
  bht_37:
    'müxtəlif üsullarla, hədəf auditoriyadan və onlara çatmağın nə qədər çətin olduğundan asılı olaraq, markanın izlənməsi adətən üç mərhələyə bölünür:',
  bht_38:
    'Məlumat toplamaq üçün həm ənənəvi, həm də müasir üsullardan istifadə edirik:',
  bht_39:
    'Biz iki modeldən istifadə etməklə işləyirik: birincisi sadə göstəricilər dəsti ilə, ikincisi isə qida və xidmət sənayesi kimi daha mürəkkəb sənayeləri hədəfləyir. Daha mürəkkəb model 10-a qədər təxmin edilən indeksi əhatə edən dərin araşdırmanı əhatə edir.',
  bht_40: 'Biz təklif edirik',
  bht_41: 'ilkin məlumatlara nəzarət',
  bht_42: 'əhatə edirik',
  bht_43: 'bütün ölkə',
  bht_44: 'Biz bir həll təqdim edirik',
  bht_45:
    'qeyri-dinamik bazarlar/məhdud sayda oyunçuya malik bazarlar üçün (Brand Point Analyze)',
  bht_46: 'Niyə biz?',
  bht_47: 'Niyə bizi seç?',
  bht_50: 'Zəif nəticə',
  bht_51: 'Yaxşı nəticə',
  bht_52: 'Orta nəticə',
  cases_1:
    'Çağırış: Hyundai salonlarında müştərinin elektrikli avtomobil satın alma təcrübəsini necə təkmilləşdirmək olar?',
  cases_2: 'Müştəri səyahətinin xəritəsi',
  cases_3: 'İdeya',
  cases_4: 'Keyfiyyətli tədqiqat',
  cases_5:
    'Çağırış: Müştərilərin Oppo brendinə marağını artırmaq üçün mağazalarda nəyi dəyişmək lazımdır (həm xidmət prosesində, həm interyerdə, həm də brend qavrayışında)?',
  cases_6: 'Onlayn CJM',
  cases_7: 'UI testi',
  cases_8: 'Müsahibələr',
  cases_9:
    'Çağırış: Müştərilərin Oppo markasına marağını artırmaq üçün mağazalarda nələri dəyişdirmək lazımdır?',
  cases_10: 'Keyfiyyətli hissə',
  cases_11: 'İdeya',
  cases_12: 'Kəmiyyət hissəsi',
  cases_13: 'Müştəri səyahət xəritəsi',
  cases_14: 'Davalar',
  cases_15: 'Hamısı',
  cases_16: 'Gəlin möhtəşəm davanızı birlikdə edək',
  csi_1: 'Müştəri',
  csi_2: 'məmnunluq indeksi',
  csi_3: 'İstehlakçıların səbəblərini müəyyənləşdirin’',
  csi_4: 'məmnunluq və narazılıq',
  csi_5: 'Müştəri məmnuniyyətinin araşdırılması',
  csi_6:
    'müştərinin satın alma qərarına, habelə hədəf auditoriyanın davranışına təsir edən amillərin, səbəblərin və motivlərin müəyyən edilməsidir.',
  csi_7: 'Müştəri məmnuniyyəti sorğusu',
  csi_8:
    'müştərinin xidmətin və ya ümumilikdə məhsulun müəyyən aspektindən nə dərəcədə razı və ya narazı olduğunu göstərir.',
  csi_9: 'Məmnuniyyət sorğusu',
  csi_10:
    'şirkətin mövcud müştəriləri və müəyyən məhsul/xidmətlərin istifadəçiləri arasında aparılır.',
  csi_11: 'CSI nədir?',
  csi_12:
    'Müştəri məmnuniyyəti şirkətiniz tərəfindən təmin edilən məhsul və ya xidmətin alınması ilə bağlı müştərinin gözləntilərinin nə dərəcədə təmin olunduğunu ölçür.',
  csi_13: 'Xoşbəxt adam',
  csi_14: 'Haqqında',
  csi_15: 'Hər hansı gizli faktorları müəyyənləşdirin',
  csi_16: 'müştəri məmnuniyyətinə təsir edə bilər',
  csi_17: 'Səbəbləri müəyyənləşdirin',
  csi_18:
    'mal və xidmətlərə tələbin hər hansı yüksəlişinin/azalmasının arxasında',
  csi_19: 'Şirkəti optimallaşdırın',
  csi_20: 'biznes prosesləri',
  csi_21: 'Onların uyğunlaşdırılması',
  csi_22: 'müasir istehlakçının ehtiyacları üçün',
  csi_23: 'Effektiv reklam mesajı yaradın',
  csi_24: 'və məhsulu müştərinin tələbi əsasında yerləşdirin',
  csi_25: 'Həll etmək üçün tapşırıqlar',
  csi_26: 'Müştəri məmnuniyyəti sorğuları hansı vəzifələri həll edə bilər',
  csi_27: 'Telefonu olan adam',
  csi_28: 'Riyazi model qururuq',
  csi_29:
    'müştəri məmnuniyyətinə/müştəri sədaqətinə təsir edə biləcək amillərin (reqressiya təhlili)',
  csi_30: 'optimallaşdırın',
  csi_31: 'şirkətin iş prosesləri',
  csi_32: 'Hesablanmış müştəri məmnuniyyəti indeksi qururuq',
  csi_33: '(CSI) və deklarativ ilə müqayisə edin',
  csi_34: 'Araşdırma aparırıq',
  csi_35: 'həm B2B, həm də B2C seqmentləri üçün.',
  csi_36: 'Anketlərimiz komanda tərəfindən hazırlanır',
  csi_37: 'Xüsusilə müştərilərimizin fərdi layihələri üçün analitiklər',
  csi_38: 'Niyə biz',
  csi_39: 'Niyə bizi seç?',
  csi_40: 'Məlumat toplama üsulları:',
  main_1:
    'müştərilərimizin təcrübəsindən öyrənməklə öz maliyyə göstəricilərini yaxşılaşdırır',
  main_2: 'SX tədqiqat və konsaltinq xidmətləri: biznesinizin 360° görünüşü',
  main_3:
    'Biznesinizi dəstəkləmək üçün həllər və alətlərimizin çeşidini kəşf edin',
  main_4: 'Məhsullar',
  main_5:
    'Keyfiyyətli tədqiqat anlayışları, fikirləri və ya təcrübələri başa düşmək üçün qeyri-rəqəmsal məlumatların toplanması və təhlilidir',
  main_6:
    'Kəmiyyət tədqiqat metodları sorğular, anketlər və sorğular vasitəsilə toplanmış məlumatların obyektiv ölçülməsi və ədədi təhlilidir',
  main_7:
    'Gizli alış-veriş, əvvəlcədən müəyyən edilmiş bir ssenari zamanı müştəri ilə şirkət və ya təşkilat arasındakı qarşılıqlı əlaqəni ölçmək və izləmək üçün bazar araşdırması üsuludur',
  main_8:
    'Əlaqə İdarəetmə Sistemi. Müştərinizin səsi bir sistemdə Müştəri Əlaqə İdarəetmə Sistemində',
  main_9:
    'Tamaşaçılardan fikirlər təqdim edən süni intellektlə işləyən məzmun kəşfiyyatı platforması',
  main_10:
    'Performans İdarəetmə Proqramı. Satışları artırmaq üçün oyunlaşdırılmış proqram',
  main_11:
    'Qiymət monitorinqi rəqiblərin qiymətlərinin toplanması, işlənməsi və təhlili prosesidir',
  main_12:
    'Xidmət dizaynı məhsul və ya xidmətdən istifadə edəcək insanların ehtiyac və istəklərini onlarla vaxt keçirərək başa düşməkdir',
  main_13:
    'Analitik komandamız bir çox müştəri ilə uğurlu olduğunu sübut etdiyimiz ən müasir və fundamental yanaşmaları tətbiq edir',
  main_14: 'Metodologiyalar',
  main_15: 'Müştəri səyahət xəritəsi',
  main_16:
    'Müştəri Səyahət Xəritəçəkmə (CJM) hədəf auditoriyanı müəyyən etmək, onların ehtiyaclarını və ətraf mühiti anlamaq və sonra cəlbedici təcrübə təqdim etmək üçün plan yaratmaqdır',
  main_17: 'CJM',
  main_18: 'Xalis Tanıtım Hesabı',
  main_19:
    'Net Promoter Score (NPS) bir suala əsaslanan müştəri təcrübəsi ölçülərinin qızıl standartıdır: Təşkilat/Məhsul/Xidməti nə dərəcədə tövsiyə edərdiniz?',
  main_20: 'NPS',
  main_21: 'Brend Sağlamlığının İzlənməsi',
  main_22:
    'Brend Sağlamlığının İzlənməsi (BHT) tədqiqat markanızın məlumatlılıq və istifadə, markanın yerləşdirilməsi və brend performansı üzərində necə performans göstərdiyini ölçə bilər',
  main_23: 'BHT',
  main_24: 'İnsan Resursları Araşdırması',
  main_25:
    'İnsan Resursları Tədqiqatı HR təcrübələrini və performansını qiymətləndirmək üçün istifadə olunur və mövcud inkişaf və idarəetmə ilə bağlı ətraflı təhlil təklif edir',
  main_26: 'HR tədqiqatı',
  main_27: 'Müştəri məmnuniyyəti indeksi',
  main_28:
    'Müştəri Məmnuniyyəti İndeksi (CSI) müştərilərinizin şirkətin məhsullarından/xidmətlərindən nə dərəcədə razı qaldıqlarını göstərən bir baldır.',
  main_29: 'CSI',
  main_30: 'UX testi',
  main_31:
    'Usability (UX) testi real insanların vebsayt, proqram və ya digər məhsulla necə qarşılıqlı əlaqədə olması və onların davranışını və ona reaksiyalarını müşahidə etməsidir',
  main_32: 'Niyə biz',
  main_33:
    'Missiyamız bizneslərə yüksək effektiv müştəri təcrübəsi təqdim etməyə kömək etməkdir',
  main_34: 'Keyfiyyətə diqqət yetirmək',
  main_35: 'Biz keyfiyyətə nəzarət edirik',
  main_36:
    'tədqiqat məlumatlarına gəldikdə. Biz bütün məlumat dəstlərini müştərilərə təqdim edirik',
  main_37: 'Xərc baxımından səmərəli',
  main_38: 'Təcrübəmiz, resurslarımız və sadələşdirilmişdir',
  main_39: 'biznes prosesləri sizə pula və vaxtınıza qənaət etməyə imkan verir',
  main_40: 'Ağıllı həllər',
  main_41: 'Biz müxtəlif texnikalardan istifadə edirik',
  main_42: 'və xüsusi problemlərinizi həll etmək üçün alət dəstləri',
  main_43: 'Keyfiyyətə diqqət yetirmək',
  main_44: 'Keyfiyyətə nəzarət edirik',
  main_45:
    'tədqiqat məlumatlarına gəldikdə. Biz bütün məlumat dəstlərini müştərilərə təqdim edirik',
  main_46: 'Xərc baxımından səmərəli',
  main_47: 'Təcrübəmiz, resurslarımız və sadələşdirilmişdir',
  main_48: 'biznes prosesləri sizə pula və vaxtınıza qənaət etməyə imkan verir',
  main_49: 'Ağıllı həllər',
  main_50: 'Biz müxtəlif texnikalardan istifadə edirik',
  main_51: 'və xüsusi problemlərinizi həll etmək üçün alət dəstləri',
  main_52: 'Cash & Carry',
  main_53: 'Elektronika',
  main_54: 'Lüks/Premium',
  main_55: 'Yemək və Qonaqpərvərlik',
  main_56: 'Pərakəndə',
  main_57: 'Avtomobil',
  main_58: 'Moda',
  main_59: 'Banklar',
  main_60: 'Səyahət və İstirahət',
  main_61: 'Saatlar və Zərgərlik',
  main_62: 'Məişət və Məişət texnikası',
  main_63: 'Bloq',
  main_64: 'Daha maraqlı yazıları oxumaq üçün bloga daxil olun',
  main_65: 'Pərakəndə satış auditi',
  main_66:
    'Mağazanız və ya brendiniz haqqında ətraflı məlumat toplayın, böyümə maneələrini təhlil edin və onları aradan qaldırmaq üçün effektiv strategiyalar yaradın',
  cjm_1: 'Müştəri',
  cjm_2: 'Səyahət xəritəsi',
  cjm_3: 'Müştərilərin dünyasına daxil olun və onların təcrübələrini paylaşın',
  cjm_4: 'Müştəri Səyahət Xəritəsi nədir?',
  cjm_5:
    'CJM müxtəlif kanallar vasitəsilə və müəyyən bir müddət ərzində məhsul, xidmət, şirkət və ya marka ilə istehlakçı qarşılıqlı əlaqəsinin vizuallaşdırılmış tarixini təqdim edir.',
  cjm_6:
    'Bu, şirkətlərə müştərilərlə qarşılıqlı əlaqə təcrübəsini obyektiv təhlil etməyə, yaranan maneələri düzəltməyə və aradan qaldırmağa və məhsulun təkmilləşdirilməsi üçün tövsiyələr təklif etməyə imkan verir.',
  cjm_7: 'TA-nın təyini, seqmentləşdirmə',
  cjm_8: 'Müştərilərlə qarşılıqlı əlaqənin mərhələlərinin müəyyən edilməsi',
  cjm_9: 'Müştərilərlə ilkin qarşılıqlı əlaqə kanallarının müəyyən edilməsi',
  cjm_10: 'Müştəri təcrübəsinin tədqiqi',
  cjm_11: 'Optimallaşdırma üzrə maneələrin və fərziyyələrin təhlili',
  cjm_12: 'Hipotezlərin yerinə yetirilməsi və sınaqdan keçirilməsi',
  cjm_13:
    'Müştəri Səyahət Xəritəsinin tərtib edilməsi aşağıdakı addımlardan ibarətdir:',
  cjm_14: 'Müştəri Səyahət Xəritəsi nümayiş etdiriləcək:',
  cjm_15:
    'CJM müxtəlif kanallar vasitəsilə və müəyyən bir müddət ərzində məhsul, xidmət, şirkət və ya marka ilə istehlakçı qarşılıqlı əlaqəsinin vizuallaşdırılmış tarixini təqdim edir.',
  cjm_16: 'Komanda işini təmin edirik',
  cjm_17: 'analitiklər və dizaynerlər arasında',
  cjm_18: 'Biz fərdi təklif edirik',
  cjm_19: 'hər bir müştəriyə yanaşma',
  cjm_20: 'Ümumilikdə artıra bilərik',
  cjm_21:
    'müştərinin xidmətdən/məhsuldan məmnunluğu və nəticədə onların şirkətə sədaqətini artırır',
  cjm_22: 'Biz zəmanət veririk',
  cjm_23:
    'istehlakçı yolunun optimallaşdırılması və şirkətin iş proseslərinin nəzərə alınması',
  cjm_24: 'Müştərilərimizə təklif edirik',
  cjm_25:
    'birləşdirilmiş yanaşmalardan istifadə etmək imkanı: CATI, CAWI, fokus qrupları, dərin müsahibələr, ekspert müsahibələri, etnoqrafiya və s.',
  cjm_26: 'Niyə bizi seç?',
  methods_hr_1: 'İnsan Resursları Araşdırması',
  methods_hr_2:
    'Komandanız haqqında obyektiv fikir əldə edin və HR performansını necə yaxşılaşdıracağınızı tapın',
  methods_hr_3: 'HR tədqiqatına ehtiyacınız ola biləcək bəzi səbəblər',
  methods_hr_4: 'müsahibə aparan işçi heyəti',
  methods_hr_5: 'hr layihələr həyata keçirilir',
  methods_hr_6: 'kadrlar üçün təlimlər keçirilib',
  methods_hr_7:
    'Müştəri təcrübəsi və işçi araşdırması üzrə aparıcı Avropa holdinqi',
  methods_hr_8: '4service HR tədqiqatları rəqəmlərlə',
  methods_hr_9:
    'Şirkətinizin işçilərinin həyatının hər mərhələsində problemləri və zəif tərəflərini müəyyən etmək üçün: axtarış/işə qəbul/onboarding/karyera inkişafı/yenidən sertifikatlaşdırma',
  methods_hr_10: 'Hansı halların artım və bonuslara layiq olduğunu bilmək',
  methods_hr_11:
    'İşçilərin narazılığını proqnozlaşdırmaq və ya həll yollarını təqdim etmək lazımdır',
  methods_hr_12:
    'HR tədqiqatı vasitəsilə HR siyasətlərinin, proqramlarının və təcrübələrinin qiymətləndirilməsinin yeni və cari üsulları mümkündür',
  methods_hr_13: 'Şirkət işçilərinin psixi sağlamlığını yaxşılaşdırmaq',
  methods_hr_14:
    'Əgər məqsədyönlü yanaşma vasitəsilə məhsuldarlığı artırmaq istəyirsinizsə',
  methods_hr_15:
    'Əgər beynəlxalq və/və ya uzaq komandanız varsa və onu idarə etməlisiniz',
  methods_hr_16:
    'İşəgötürən markanızın potensial işçilər üçün nə qədər cəlbedici olduğunu anlamaq üçün',
  methods_hr_17: 'İşçi Məmnuniyyəti',
  methods_hr_18: 'HR Branding Araşdırma',
  methods_hr_19: 'Mədəni müxtəliflik',
  methods_hr_20: 'Kompensasiya və Əmək haqqı planları',
  methods_hr_21: 'Komanda İşi və Sadiqlik',
  methods_hr_22: 'Qorunan Sinif İşçiləri',
  methods_hr_23: 'Tədqiqat sahələri',
  methods_hr_24: 'Həll 1',
  methods_hr_25: 'eNPS, İşçi Məmnuniyyəti, İşçi Sadiqliyi',
  methods_hr_26: 'Nə ölçə bilərik:',
  methods_hr_27: 'Sadiqlik:',
  methods_hr_28:
    'işçilərin loyallığı, gələcək davranışının proqnozlaşdırılması, sədaqətə təsir edən amillərin çəkisinin müəyyən edilməsi',
  methods_hr_29: 'İştirak:',
  methods_hr_30:
    'işçilərin işə cəlb edilməsi, nəticələrə və nailiyyətlərə maraq',
  methods_hr_31: 'Nişan:',
  methods_hr_32:
    'işçilərin dəyərlərinin və şirkət dəyərlərinin müəyyən edilməsi',
  methods_hr_33: 'Güvən 360:',
  methods_hr_34:
    'rəhbərliyə, iş yoldaşlarına, tabeçiliyinə, işəgötürən strategiyalarına və siyasətlərinə etibar',
  methods_hr_35: 'Məmnuniyyət:',
  methods_hr_36:
    'hər bir şirkət üçün unikal amillərin müəyyən edilməsi, sədaqətə təsir edən hər bir əsas amil üzrə məmnunluğun qiymətləndirilməsi',
  methods_hr_37: 'Həll 2',
  methods_hr_38: 'İşəgötürən markası',
  methods_hr_39: 'Daxili işəgötürən',
  methods_hr_40:
    'brend təhlili (işçilər, maraqlı tərəflər, daxili işəgötürənlər və HR mütəxəssisləri sorğusu)',
  methods_hr_41: 'Xarici işəgötürən təhlili',
  methods_hr_42:
    '(rəqibin EVP təhlili, xarici işə götürən sorğusu, iş axtaran sorğusu)',
  methods_hr_43: 'Brend şüuru və reputasiyası',
  methods_hr_44: 'təhlil',
  methods_hr_45: 'İşəgötürənin iştirakı',
  methods_hr_47: 'İşəgötürən arayışı',
  methods_hr_48: 'kolleksiya',
  methods_hr_49: 'Rəqabətli müqayisə',
  methods_hr_50: 'kimi əsas amillər üzərində',
  methods_hr_51: 'Maaş,',
  methods_hr_52: 'iş mühiti, atmosferi, işin xarakteri',
  methods_hr_53: 'Ehtiyacların və gözləntilərin təhlili',
  methods_hr_54: 'iş axtaranların',
  methods_hr_55: 'Dərin müsahibələr,',
  methods_hr_56: 'stolüstü tədqiqat və kəmiyyət tədqiqatından istifadə edilir',
  methods_hr_57: 'Həll 3',
  methods_hr_58: 'İşçinin səyahət xəritəsi/gizli işçi',
  methods_hr_59: 'müəyyən edən işaxtaran səyahətinin qurulması',
  methods_hr_60:
    'axtarışın əsas mərhələləri, ünsiyyət kanalları, işaxtaranların seçim meyarları və qiymətləndirilməsi',
  methods_hr_61: 'bütün mərhələləri izləmək',
  methods_hr_62:
    'və sirli iş axtaranlarla ünsiyyət kanalları (sadəcə müsahibə üçün görüş təyin etmək, müsahibə almaq, şirkətdə təcrübə keçmək)',
  methods_hr_63: 'İdentifikasiya',
  methods_hr_64: 'işə qəbul və işə qəbul proseslərindəki zəifliklər',
  methods_hr_65: 'Təkmilləşdirmə üçün tövsiyələr,',
  methods_hr_66: 'sənaye liderləri ilə müqayisə',
  mystery_shopping_1: 'Sirr',
  mystery_shopping_2: 'Alış-veriş',
  mystery_shopping_3: 'Xidmətinizi real müştərilərlə qiymətləndirin',
  mystery_shopping_4:
    'Standartları yoxlayın, işçilərinizin müştəriyə daha yaxın olmasını qiymətləndirin',
  mystery_shopping_5:
    'Biz MSPA Europe peşəkar aparıcı Mystery Shopping Providers assosiasiyasının Elit üzvüyik',
  mystery_shopping_6: 'təcrübədən',
  mystery_shopping_7: 'aylıq ziyarətlər',
  mystery_shopping_8: 'dünya üzrə sirli alıcılar',
  mystery_shopping_9: 'Aktiv layihələr',
  mystery_shopping_10: 'ofislər, Austiradakı qərargah',
  mystery_shopping_11: 'Avropa sirli alış-veriş provayderləri',
  mystery_shopping_12: 'xidmət keyfiyyəti reytinqləri',
  mystery_shopping_13: 'Anket',
  mystery_shopping_14: 'Proqram təminatı',
  mystery_shopping_15: 'Alıcının profili',
  mystery_shopping_16: 'Təsdiq və etiraz',
  mystery_shopping_17: 'Analitika',
  mystery_shopping_18:
    'Bu 5 addımla şirkətimiz 4Service sirli alıcı xidmətinin keyfiyyətinə zəmanət verir',
  mystery_shopping_19: 'Proqram',
  mystery_shopping_20:
    '4Service tərəfindən Mystery Shopping proqramının 5 elementi',
  mystery_shopping_21: 'Anket:',
  mystery_shopping_22: 'proqramınızın əsası',
  mystery_shopping_23:
    'Proqramın bu hissəsi bütün maraqlı tərəflər tərəfindən baxıla bilər',
  mystery_shopping_24: 'Top menecerlər üçün.',
  mystery_shopping_25:
    'Prosesin bütün vacib addımlarını və təfərrüatlarını göstərin',
  mystery_shopping_26: 'İşçilər üçün.',
  mystery_shopping_27: 'İşəgötürənlərin onlardan nə gözlədiyini əks etdirin',
  mystery_shopping_28: 'Gizli alıcılar üçün.',
  mystery_shopping_29:
    'Çaşqınlığın və qərəzliyin qarşısını almaq üçün aydın və strukturlaşdırılmışdır',
  mystery_shopping_30: 'Shopmetrics proqram təminatı:',
  mystery_shopping_31: 'istifadəçi dostu və yenilikçi',
  mystery_shopping_32: 'Faydaları:',
  mystery_shopping_33: 'İnteqrasiya edir',
  mystery_shopping_34: 'iş prosesləri və proqram təminatı ilə',
  mystery_shopping_35: 'GDPR-yə əsaslanan',
  mystery_shopping_36: 'Məxfilik İdarəetmə Alətlər dəsti',
  mystery_shopping_37: 'Ətraflı',
  mystery_shopping_38: 'Hüquqi Uyğunluq Paketi',
  mystery_shopping_39: 'Hesabat',
  mystery_shopping_40: 'inşaatçı',
  mystery_shopping_41: 'İdarəetmə',
  mystery_shopping_42: 'giriş səviyyələri',
  mystery_shopping_43: 'Əksər anketlər',
  mystery_shopping_44: 'bir saat ərzində tamamlanır',
  mystery_shopping_45: 'Alıcı profili:',
  mystery_shopping_46: 'Dünya üzrə 200 000-dən çox sirli alıcı',
  mystery_shopping_47:
    'Əsas meyarlar: şirkətiniz və ya rəqiblərinizlə əvvəlki təcrübə ilə hədəf auditoriyanıza maksimum yaxınlıq',
  mystery_shopping_48: 'Alıcıların təlimi:',
  mystery_shopping_49: 'Təqdimat təlim testi',
  mystery_shopping_50: 'Skype/Telefon zəngi',
  mystery_shopping_51: 'Qiymətləndirmə testi (isteğe bağlı)',
  mystery_shopping_52: 'Doğrulama və etirazlar',
  mystery_shopping_53: 'Doğrulama',
  mystery_shopping_54: 'Sistem',
  mystery_shopping_55: '100% hesabat',
  mystery_shopping_56: 'yoxlama qrupu tərəfindən yoxlanılır',
  mystery_shopping_57: 'Əlavə zənglər',
  mystery_shopping_58: 'aydınlaşdırma üçün',
  mystery_shopping_59: 'Audio qeydlər',
  mystery_shopping_60:
    'Ziyarətdən sonra ən əhəmiyyətli mövzuları qeyd etmək lazımdır',
  mystery_shopping_61: 'İşçilərin fırıldaqçılığı',
  mystery_shopping_62: 'qarşısının alınması sistemi',
  mystery_shopping_63: 'Etiraz',
  mystery_shopping_64: 'Sistem',
  mystery_shopping_65: 'İstənilən alıcının qiymətləndirməsi',
  mystery_shopping_66: 'işçi tərəfindən birbaşa saytda etiraz edilə bilər',
  mystery_shopping_67: 'Hər bir etiraz',
  mystery_shopping_68:
    'yerli yoxlama qrupu tərəfindən nəzərdən keçirilir və cavablandırılır',
  mystery_shopping_69: 'Statistikaya baxa bilərsiniz',
  mystery_shopping_70: 'etirazlardan',
  mystery_shopping_71: 'Analitika.',
  mystery_shopping_72: 'Hesabat şəxsən təqdim olunur və özündə ehtiva edir:',
  mystery_shopping_73: 'Şirkətin',
  mystery_shopping_74: 'ağrı nöqtələri və narahatlıqlar',
  mystery_shopping_75: 'Dinamikalar',
  mystery_shopping_76: 'bölmələr və bölmələr üzrə',
  mystery_shopping_77: 'Korrelyasiya',
  mystery_shopping_78:
    'standartlara cavab vermək və müştəri məmnuniyyəti arasında',
  mystery_shopping_79: 'Bençmarkinq',
  mystery_shopping_80: 'rəqiblərə qarşı',
  mystery_shopping_81: 'Praktik',
  mystery_shopping_82: 'xidmətlərin təkmilləşdirilməsinə dair tövsiyələr',
  mystery_shopping_83: 'Bizi fərqli edən nədir?',
  mystery_shopping_84: 'We carry out ideation sessions',
  mystery_shopping_85:
    'Biz təkcə nə etməyi tövsiyə etmirik, həm də sizinlə birlikdə xidmət problemlərini həll etməyin praktik yollarını axtarırıq.',
  mystery_shopping_86:
    'İdeyalaşdırma sessiyaları bizə kömək edən güclü bir vasitədir:',
  mystery_shopping_87:
    'Məhsul və ya xidmətlərin təkmilləşdirilməsi üçün istiqamətlər hazırlamaq',
  mystery_shopping_88:
    'Yeni gəlir axını imkanlarını və biznes strategiyalarını araşdırmaq',
  mystery_shopping_89:
    'Mürəkkəb müştəri mərkəzli problemlərin həlli yollarını tapmaq',
  mystery_shopping_90: 'Müştərilərin ağrı nöqtələrini ləzzətlərə çevirmək',
  mystery_shopping_91: 'Video müsahibələr aparırıq',
  mystery_shopping_92:
    'Biz təkcə sorğu anketləri toplamırıq, həm də alıcılardan canlı şərhlər alırıq',
  mystery_shopping_93: 'Bunu etmək üçün edirik:',
  mystery_shopping_94:
    'Fasilitatorlarımız ətraflı müsahibələr aparacaq və effektiv rəy verəcəklər',
  mystery_shopping_95: 'Təcrübəyə müştərinin nöqteyi-nəzərindən baxın',
  mystery_shopping_96: 'Məzmun marketinq strategiyanızı təkmilləşdirin',
  mystery_shopping_97: 'Müştəri davranışını proqnozlaşdırmaq',
  mystery_shopping_98: 'Biz Müştəri Səyahət Xəritəsini tərtib edirik',
  mystery_shopping_99:
    'Müştəri Səyahət Xəritəsi bizə müştərilərin ehtiyaclarını və problemlərini başa düşmək üçün ayaqqabılarına daxil olmağa kömək edir.',
  mystery_shopping_100: 'Müştəri səyahəti yaratmaq ən yaxşı yoldur:',
  mystery_shopping_101: 'Təcrübəyə müştərinin nöqteyi-nəzərindən baxın',
  mystery_shopping_102: 'Məzmun marketinq strategiyanızı təkmilləşdirin',
  mystery_shopping_103: 'Müştəri davranışını proqnozlaşdırmaq',
  mystery_shopping_104:
    'Xidmət və ya kommunikasiyalardakı boşluqları müəyyənləşdirin',
  mystery_shopping_105:
    'Biz Mystery Shopping-in müxtəlif növlərini təqdim edirik',
  mystery_shopping_106: 'Gizli alış-veriş növləri',
  mystery_shopping_107: 'Sirli Zəng edən',
  mystery_shopping_108:
    'Telefonla göstərilən xidmətin keyfiyyətini müəyyən etmək üçün menecerlərinizə və/və ya zəng mərkəzinə zəng edir',
  mystery_shopping_109: 'Lüks sirli alış-veriş',
  mystery_shopping_110:
    'Gizli alıcıların xüsusi kateqoriyası tərəfindən lüks yerlərdə xidmətin yoxlanılması',
  mystery_shopping_111: 'Rəqabətli səfərlər',
  mystery_shopping_112:
    'Rəqibinizin necə işlədiyini başa düşməyin ən asan yolu',
  mystery_shopping_113: 'Sirli işçi',
  mystery_shopping_114:
    'İşçinizin bütün səyahətini başa düşməyə kömək edəcək (işə qəbul, uyğunlaşma, ofis prosesləri və s.)',
  mystery_shopping_115: 'Motivasiya səfərləri',
  mystery_shopping_116:
    'Əlavə xidmətlərin və/və ya məhsulların təklifini, konkret məhsulların satışını stimullaşdırmaq üçün faydalı vasitədir',
  mystery_shopping_117: 'Mystery Shopper Online',
  mystery_shopping_118:
    'Onlayn mağazanızda, veb saytınızda və ya tətbiqinizdə müştəri təcrübəsini simulyasiya edir və ölçür',
  mystery_shopping_119: 'Satış və promosyonları yoxlayın',
  mystery_shopping_120:
    'Promosyonların bütün yerlərdə necə göstərildiyini və onların nə dərəcədə effektiv işlədiyini müəyyən etməyə kömək edir',
  mystery_shopping_121: 'Satınalma və təkrar ziyarətlər',
  mystery_shopping_122:
    'Müştəri təcrübəsini yaxşılaşdırmaq üçün satınalmadan geriyə qədər bütün prosesi əks etdirməyə kömək edir',
  mystery_shopping_123: '> 20 years',
  nps_1: 'Net Təqdimat',
  nps_2: 'Hesab',
  nps_3: 'Müştəri loyallığını ölçün, izləyin və təkmilləşdirin',
  nps_4: 'Onlar 0-dan 6-ya kimi cavab verirlər.',
  nps_5:
    'Ola bilsin ki, onlar pis təcrübəyə malik olub və bir daha sizdən alış-veriş edə bilməyəcəklər və hətta başqalarını sizdən almaqdan çəkindirə bilərlər.',
  nps_6: 'Bunlar 7 ilə 8 arasında bal toplayan respondentlərdir.',
  nps_7:
    'Onlar xidmətinizdən razıdırlar, lakin promouter sayılacaq qədər xoşbəxt deyillər.',
  nps_8: 'Bunlar biznesinizi 9 və ya 10 kimi qiymətləndirən respondentlərdir.',
  nps_9:
    'Bu o deməkdir ki, onlar sizi dostunuza və ya həmkarınıza tövsiyə edəcəklər və buna görə də sizin adınıza müdafiə edirlər.',
  nps_10: 'NPS nədir?',
  nps_11: 'Bir sadə sual əsasında müştəri qavrayışını ölçür:',
  nps_12:
    'Dostunuza və ya həmkarınıza [Təşkilat/Məhsul/Xidmət] tövsiyə etməyiniz nə dərəcədə mümkündür?',
  nps_13: 'Şirkətimə ehtiyac varmı?',
  nps_14: 'NPS sizin davamlı böyümənizdir',
  nps_15: 'Müştəri loyallığının dinamikasını anlayın',
  nps_16: 'İşçilərinizi motivasiya edin',
  nps_17: 'Müxtəlif məhsullarla əlaqəni anlayın',
  nps_18:
    'Müştərilərin sizə və rəqiblərinizlə necə rəftar etdiyini müqayisə edin',
  nps_19: 'NPS ilə nə edə bilərəm?',
  nps_20: 'NPS hansı iş növünə aiddir?',
  nps_21: 'Məlumat toplama üsulları:',
  nps_22: 'NPS ilə işləyərkən tövsiyələrimiz',
  nps_23:
    'Bu o deməkdir ki, biz Detractorları promouterə çevirmək üçün təlimatlar hazırlayırıq',
  nps_24: 'Biz "qapalı dövrə" üsulu ilə işləyirik',
  nps_25:
    'NPS, müştəri sədaqətindəki dəyişiklikləri izlədiyimiz bir izləmə araşdırmasıdır',
  nps_26: 'Biz dinamikaya nəzarət edirik',
  nps_27:
    'Daha dərin təhlil üçün öz nişinizdəki rəqibləri həmişə qiymətləndirməyi təklif edirik',
  nps_28: 'Rəqiblərin qiymətləndirilməsi',
  price_monitoring_1: 'Qiymət Monitorinqi',
  price_monitoring_2: 'Pərakəndə satış monitorinqi',
  price_monitoring_3:
    'Rəqiblərin qiymət monitorinqi vasitəsilə satış potensialını artırın və qiymət siyasətinizi optimallaşdırın',
  price_monitoring_4:
    '4Service 20 ildən artıqdır ki, bütün dünyada qiymət monitorinqi xidmətləri göstərir',
  price_monitoring_5:
    'Qiymətlər qısa müddət ərzində ixtisaslı daxili auditorlarımız tərəfindən izlənilir və bu, rəqiblərin qiymətlərindəki dəyişikliklərə tez reaksiya verməyə imkan verir',
  price_monitoring_6:
    'Çətinlikləri şirkətimiz qiymət monitorinqi ilə həll etməyə kömək edə bilər',
  price_monitoring_7: 'Rəqiblərin qiymətlərinin qiymətləndirilməsi',
  price_monitoring_8: 'və məhsul siyasəti',
  price_monitoring_9: 'Monitoring promotions',
  price_monitoring_10: 'və xüsusi təkliflər',
  price_monitoring_11: 'Bazar şəraitinin monitorinqi',
  price_monitoring_12: 'və bazar mühiti',
  price_monitoring_13: 'Satış məntəqələrinin yoxlanılması və nəzarəti',
  price_monitoring_14: 'xüsusi avadanlıq və mallar üçün',
  price_monitoring_15: 'Məhsulun idarə edilməsi',
  price_monitoring_16: 'ticarət daxilində axın',
  price_monitoring_17: 'Şərtlərin optimallaşdırılması',
  price_monitoring_18: 'təchizatçılar, dilerlərlə daha yaxşı əməkdaşlıq üçün',
  price_monitoring_19: 'Rəf sahəsinin ölçülməsi,',
  price_monitoring_20:
    'malların yerləşdirilməsinin, ehtiyatın mövcudluğunun qiymətləndirilməsi',
  price_monitoring_21: 'Əsas göstəricilər və monitorinq meyarları',
  price_monitoring_22:
    'Bütün məlumatlar daxil olmaqla bir interfeysdə yığılır:',
  price_monitoring_23: 'ad',
  price_monitoring_24: 'məhsul və marka kateqoriyası',
  price_monitoring_25: 'mövcudluğu',
  price_monitoring_26: 'tanıtım materialları',
  price_monitoring_27: 'qiymət',
  price_monitoring_28: 'və hər hansı promosyon təkliflərinin mövcudluğu',
  price_monitoring_29: 'rəf',
  price_monitoring_30: 'ölçüsü',
  price_monitoring_31: 'məhsul',
  price_monitoring_32: 'göstərin',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'üz-üzə',
  price_monitoring_35: 'növü',
  price_monitoring_36: 'qablaşdırma',
  price_monitoring_37: 'hər hansı digər',
  price_monitoring_38: 'ümumi məlumat',
  price_monitoring_39: 'Layihə öncəsi təhlil:',
  price_monitoring_40: 'Biz öyrənirik',
  price_monitoring_41:
    'şirkətinizin qiymət və məhsul siyasəti, biznes və sənaye təcrübələri',
  price_monitoring_42: 'təmin edirik',
  price_monitoring_43:
    'auditorlar üçün operativ təlim, sınaq və sertifikatlaşdırma',
  price_monitoring_44: 'Biz fərdiləşdiririk',
  price_monitoring_45: 'və proqram təminatını qurun',
  price_monitoring_46: 'Sahə işi:',
  price_monitoring_47: 'Pərakəndə satışa səfərlər',
  price_monitoring_48: 'satış nöqtələri',
  price_monitoring_49: 'Hesabat:',
  price_monitoring_50: 'Analitik komandamız',
  price_monitoring_51:
    'şirkətin şəxsi hesablarında onlayn hesabatlar hazırlayır',
  price_monitoring_52: 'təmin edirik',
  price_monitoring_53:
    'tam foto hesabat, PPT hesabatı, yaşayış yeri, zəncir adı, çıxış ünvanı baxımından Excel hesabatı',
  price_monitoring_54: 'Necə',
  price_monitoring_55: 'işləyir',
  price_monitoring_56: 'fərdi endirimlərin ölçüsü',
  price_monitoring_57: 'xərcin hesablanması',
  price_monitoring_58: 'fərdi bonusların ölçüsü',
  price_monitoring_59: 'loyallıq proqramları',
  price_monitoring_60: 'və s.',
  price_monitoring_61: 'Xüsusi proqram təminatından istifadə edirik:',
  price_monitoring_62:
    'PRADATA, Shopmetrics və hərtərəfli texniki dəstək təklif edir',
  price_monitoring_63: 'Sürətli yerləşdirmə',
  price_monitoring_64: 'və miqyaslama (1-ci gündən)',
  price_monitoring_65: 'Bütün məlumatlar təsdiqləndi',
  price_monitoring_66: 'fotoşəkillər və GPS etiketləri ilə',
  price_monitoring_67: 'Lazım olan nömrəmiz var',
  price_monitoring_68: 'istənilən ölkədə sahə agentləri',
  price_monitoring_69: 'Böyüklərlə işləyə bilərik',
  price_monitoring_70: 'məlumatların miqdarı',
  price_monitoring_71: 'Fərdi qiymətlərin monitorinqi',
  price_monitoring_72:
    'Əgər mürəkkəb məhsullarınız varsa - biz sizə qiymət strategiyanızı müəyyənləşdirməyə kömək edə bilərik',
  price_monitoring_73:
    'Məsələn, biz fərdi rəqiblərin qiymətlərinə nəzarət edə bilərik:',
  price_monitoring_74: 'Aşağı',
  price_monitoring_75: 'dəyəri',
  price_monitoring_76: 'Yüksək',
  price_monitoring_77: 'səmərəlilik',
  price_monitoring_78: 'Excel hesabatı',
  price_monitoring_79: 'PPT hesabatı',
  price_monitoring_80: 'BI hesabatı',
  price_monitoring_81:
    'Rəqiblərin qiymət məlumatları sizə ən yaxşı qiymət qərarlarını verməyə kömək edir',
  price_monitoring_82:
    'Yer növü baxımından tam foto hesabat, PPT hesabatı, Excel hesabatı təqdim edirik',
  qualitative_research_1: 'Keyfiyyətli',
  qualitative_research_2: 'tədqiqatlar aparır',
  qualitative_research_3:
    'Müştərilərinizin hərəkətlərini və sözlərini müşahidə etməklə məlumatları toplayın, təhlil edin və şərh edin',
  qualitative_research_4: 'Dəyərlər',
  qualitative_research_5: 'Davranış',
  qualitative_research_6: 'Fon',
  qualitative_research_7: 'Gözləntilər',
  qualitative_research_8: 'Ətraf mühit',
  qualitative_research_9: 'Qorxular / Maneələr',
  qualitative_research_10: 'Müştərinin seçimində məqsəd nədir?',
  qualitative_research_11: 'Telefonu olan adam',
  qualitative_research_12: 'brend',
  qualitative_research_13: 'kateqoriya',
  qualitative_research_14: 'reklam',
  qualitative_research_15: 'məhsul',
  qualitative_research_16:
    'Keyfiyyətli metodların unikallığı ondan ibarətdir ki, onlar bizə istehlakçı davranışının səbəblərini və motivasiyasını anlamağa imkan verir',
  qualitative_research_17:
    'İstehlakçının müəyyən bir fenomenə münasibətinin təhlili',
  qualitative_research_18: 'Fərdi perspektivlər və təcrübələr',
  qualitative_research_19:
    'Sorğu vasitəsilə araşdırılması mümkün olmayan mövzular',
  qualitative_research_20: 'Həssas mövzular',
  qualitative_research_21: 'Tamaşaçılara çatmaq çətindir',
  qualitative_research_22: 'Kompleks coğrafiya',
  qualitative_research_23: 'Dərin müsahibələr faydalıdır:',
  qualitative_research_24: 'Dərin müsahibələr',
  qualitative_research_25:
    'Dərin müsahibə müəyyən ideya, proqram və ya vəziyyətlə bağlı onların perspektivlərini araşdırmaq üçün respondentlərlə fərdi müsahibələr aparmağı nəzərdə tutan keyfiyyətli tədqiqat metodudur. Dərin müsahibə fərdi təcrübələri / qavrayışları / təcrübələri zəngin təfərrüatlarla araşdırır.',
  qualitative_research_26: 'Məqsədlər:',
  qualitative_research_27: 'Sosial və mədəni normaların səciyyələndirilməsi',
  qualitative_research_28: 'Tamaşaçılara çatmaq çətindir',
  qualitative_research_29:
    'Paylaşma və müqayisə (Morgan) sorğular vasitəsilə araşdırılır',
  qualitative_research_30:
    'Sorğu vasitəsilə araşdırılması mümkün olmayan mövzular',
  qualitative_research_31: 'üçün faydalı olan dərin fokus qrupları:',
  qualitative_research_32: 'Fokus qrupları',
  qualitative_research_33:
    'Qrup moderatorunun təsiri altında fərdi görüşlərin, ortaya çıxan müzakirələrin, təzahürlər arasında müzakirələrin dərindən araşdırılması.',
  qualitative_research_34:
    'Qrup müzakirəsi, fokuslanmış müzakirədə povest məlumatları vasitəsilə paylaşılan təcrübələr və sosial normalar haqqında anlayışların yaradılması.',
  qualitative_research_36: 'Faydaları:',
  qualitative_research_37:
    'Müxtəlif real şəraitdə (evdə, satış yerində, işdə və s.) istehlakçının həyat tərzini və davranışını öyrənmək',
  qualitative_research_38:
    'Yeni məhsulların inkişafı, qablaşdırma, xidmətin təkmilləşdirilməsi və s. üçün anlayışlar axtarın.',
  qualitative_research_39: 'Dərin etnoqrafiya araşdırması faydalıdır:',
  qualitative_research_40: 'Etnoqrafiya tədqiqatı',
  qualitative_research_41:
    'Etnoqrafiya, davranışlarını və qarşılıqlı əlaqələrini yaxından müşahidə etmək üçün özünüzü müəyyən bir cəmiyyətə və ya təşkilata daxil etməyi əhatə edən keyfiyyətli tədqiqat növüdür.',
  qualitative_research_42:
    'Etnoqrafiya bir qrupun ortaq mədəniyyəti, konvensiyaları və sosial dinamikasını dərindən dərk etməyə imkan verən çevik tədqiqat metodudur. Bununla belə, o, bəzi praktiki və etik problemləri də ehtiva edir.',
  qualitative_research_43: 'Metodlar:',
  qualitative_research_44:
    'Etnoqrafiya real həyat şəraitində istehlakçının və məhsul/xidmətlə qarşılıqlı əlaqənin öyrənilməsidir',
  qualitative_research_45: 'Bizimlə işləməyin faydaları',
  qualitative_research_46:
    'İstənilən hədəf auditoriyadan respondentlər tapa bilərik. Biz isə işə qəbulun keyfiyyətinə nəzarət edirik',
  qualitative_research_47: 'İstənilən mürəkkəblikdə işə qəbul',
  qualitative_research_48:
    'Moderatorlarımız çoxdillidir və fasilitasiya bacarıqlarına malikdir',
  qualitative_research_49: 'Ekspert moderatorlar',
  qualitative_research_50: 'Onlayn / oflayn işləyir',
  qualitative_research_51: 'Audio/video təqdim edirik',
  qualitative_research_52: 'qeydlər',
  qualitative_research_53:
    'İstənilən ərazidə keyfiyyətli araşdırma apara bilərik',
  quantitative_research_1: 'Müştəri məmnuniyyətinin öyrənilməsi',
  quantitative_research_2: 'müəyyən bir məhsul və ya xidmətlə (SCI, NPS)',
  quantitative_research_3: 'Seqmentasiyanın aparılması',
  quantitative_research_4: 'müxtəlif bazarlarda sorğular',
  quantitative_research_5: 'Bazar araşdırması',
  quantitative_research_6: 'NPS idarəetməsi',
  quantitative_research_7: 'Rəqib təhlili',
  quantitative_research_8: 'Kəmiyyət',
  quantitative_research_9: 'tədqiqatlar aparır',
  quantitative_research_10:
    'Obyektiv, ədədi və statistik məlumatlara əsaslanan insanların münasibət və davranışları haqqında nəzəriyyələri sınamaq üçün istifadə edən tədqiqat metodologiyası',
  quantitative_research_11: 'illər',
  quantitative_research_12: 'dünya üzrə CX təcrübəsi',
  quantitative_research_13: 'panel',
  quantitative_research_14: 'respondentlərin',
  quantitative_research_15: 'ölkələr',
  quantitative_research_16: 'biz bütün dünyada layihələr həyata keçiririk',
  quantitative_research_17: 'Biz kimik',
  quantitative_research_18:
    '4Service qlobal marketinq araşdırması və CX holdinqidir. Biz müxtəlif bizneslərə ekspert məlumat toplama xidmətləri təklif edirik',
  quantitative_research_19: '4Service Nömrələrə görə',
  quantitative_research_20: 'Kompüter Dəstəyi',
  quantitative_research_21: 'Veb Müsahibə',
  quantitative_research_22:
    'İnternet əsaslı anket texnikası. Respondent elektron sorğu anketini müsahibəçinin köməyi olmadan doldurur. Anket elektron şəkildə göndərilə və ya internet saytında yerləşdirilə bilər.',
  quantitative_research_23: 'Kompüter Dəstəkli Veb Müsahibə',
  quantitative_research_24: 'Əksər hallarda',
  quantitative_research_25:
    'bu üsul material və vaxt xərcləri baxımından ən sərfəli üsuldur',
  quantitative_research_26: 'Bu sorğu təmin edir',
  quantitative_research_27:
    'çatmaq çətin olan auditoriyadan müsahibə almaq imkanı',
  quantitative_research_28: 'Geniş imkanlar təqdim edir',
  quantitative_research_29:
    'video və audio materialların, eləcə də şəkillərin nümayişi üçün',
  quantitative_research_30: 'Respondentlər ola bilər',
  quantitative_research_31: 'dünyanın istənilən yerində yerləşir',
  quantitative_research_32: 'Araşdırmağa imkan verir',
  quantitative_research_33:
    'İnternet auditoriyasının spesifik davranışı. Əsas nəticələrə müştəri real vaxt rejimində veb interfeys vasitəsilə daxil ola bilər',
  quantitative_research_34: 'Eligible for any confidential,',
  quantitative_research_35:
    'Respondentlərin müsahibə verənlə danışarkən cavab vermək istəmədiyi həssas, şəxsi mövzu və ya məsələ',
  quantitative_research_36: '150 000+ panel',
  quantitative_research_37:
    'Bizim bütün dünyada öz respondentlər panelimiz var',
  quantitative_research_38: 'Biz çətin sorğularla işləyirik',
  quantitative_research_39:
    'Tamamlayıcı vasitələrdən (sosial media, reklam) istifadə etməklə biz konkret auditoriya və respondentin mürəkkəb profilini tapa bilərik',
  quantitative_research_40: 'Respondentlərimizi motivasiya edirik',
  quantitative_research_41:
    'Biz maddi həvəsləndirmələr vasitəsilə uzun sorğuların tamamlanmasını həvəsləndirmək üçün çərçivə hazırlamışıq',
  quantitative_research_42: 'Üz-üzə müsahibələr',
  quantitative_research_43: 'planşetdən istifadə etməklə həyata keçirilir',
  quantitative_research_44:
    'Bu, kəmiyyət məlumatlarının toplanmasının əsas üsullarından biridir, bu müddət ərzində müsahibin ciddi şəkildə strukturlaşdırılmış sorğu vərəqəsində respondentlə birbaşa əlaqə saxlayır.',
  quantitative_research_45:
    'Üzbəüz müsahibələr planşetdən istifadə etməklə respondentlərin iş və ya yaşayış yerində, küçədə və ya müəyyən edilmiş satış yerlərində aparıla bilər',
  quantitative_research_46: 'Planşetdən istifadə etməklə üzbəüz müsahibələr',
  quantitative_research_47: 'Səviyyənin müəyyən edilməsi',
  quantitative_research_48: 'populyarlıq və brendin tanınması',
  quantitative_research_49: 'İstehlakçı seqmentasiyası',
  quantitative_research_50: 'alış davranışlarına əsaslanır',
  quantitative_research_51: 'Effektivliyin təhlili',
  quantitative_research_52: 'reklam kampaniyası haqqında',
  quantitative_research_53: 'Müştəri məmnuniyyəti',
  quantitative_research_54: 'ölçü',
  quantitative_research_55: 'Ən yaxşı qiymətin müəyyən edilməsi',
  quantitative_research_56: 'məhsul və ya xidmət üçün',
  quantitative_research_57: 'Təhlil',
  quantitative_research_58: 'istehlakçı üstünlükləri',
  quantitative_research_59: 'Öyrənmək',
  quantitative_research_60: 'istehlakçıların necə satın alması',
  quantitative_research_61: 'Qiymətləndirilməsi',
  quantitative_research_62: 'bazar tutumu',
  quantitative_research_63: 'DigSee Proqramı',
  quantitative_research_64:
    'Biz səs yazısı və GPS ilə xüsusi proqramlardan istifadə edirik',
  quantitative_research_65: 'Müsahiblər və nəzarətçilər',
  quantitative_research_66:
    'biz istənilən dildə müsahibələr apara və müştərinin istədiyi dilə tərcümə edə bilərik',
  quantitative_research_67: 'Nümunə götürmə',
  quantitative_research_68:
    'Analitik şöbə mümkün olan ən yaxşı nəticəni əldə etmək üçün respondentlərin işə qəbul sistemini işləyib hazırlayır',
  quantitative_research_69: 'Kompüter Dəstəyi',
  quantitative_research_70: 'Telefonla Müsahibə',
  quantitative_research_71:
    'Aydın strukturlaşdırılmış sorğu vərəqindən istifadə etməklə telefon müsahibələri vasitəsilə kəmiyyət məlumatlarının toplanması metodologiyası.',
  quantitative_research_72: 'Kompüter dəstəkli telefonla müsahibə',
  quantitative_research_73: 'Bizim öz zəng mərkəzlərimiz var',
  quantitative_research_74:
    'Biz səs yazısı və GPS ilə xüsusi proqramlardan istifadə edirik',
  quantitative_research_75: 'İxtisaslı operatorlar',
  quantitative_research_76:
    'Operatorlarımız müxtəlif dillərdə danışır, təlim keçmiş, yaxşı sınaqdan keçmiş və öz bacarıqlarını daim təkmilləşdirirlər',
  quantitative_research_77: 'Verilənlər bazaları',
  quantitative_research_78:
    'Biz öz verilənlər bazamız və sizin müştəri bazalarınızla da işləyirik',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Biz keyfiyyətə zəmanət veririk və SLA (Xidmət Səviyyəsi Müqaviləsi) imzalayırıq',
  quantitative_research_81: 'Məqsəd təyini',
  quantitative_research_82: 'Metodologiyanın müəyyənləşdirilməsi',
  quantitative_research_83: 'Seqmentləşdirmə və seçmə',
  quantitative_research_84: 'Məlumat toplama kanallarının müəyyən edilməsi',
  quantitative_research_85: 'Respondent üçün məntiqli bir anketin yaradılması',
  quantitative_research_86: 'Məlumat toplama prosesi',
  quantitative_research_87: 'Keyfiyyət təminatı',
  quantitative_research_88: 'Analiz texnikaları',
  quantitative_research_89: 'Verilənlərin emalı',
  quantitative_research_90: 'Sorğu dizaynı',
  quantitative_research_91: 'Müsahiblərin GPS yerinin izlənməsi',
  quantitative_research_92: 'Məlumatların yoxlanılması',
  quantitative_research_93:
    'Anketin proqramlaşdırılması və sınaqdan keçirilməsi',
  quantitative_research_94: 'Məlumatların doğrulanması',
  quantitative_research_95: 'GDPR tələblərinə əməl edərkən səs yazıları',
  quantitative_research_96: 'Tədqiqat işçilərinin hazırlanması və səriştəsi',
  quantitative_research_97: 'Keyfiyyət təminatı',
  quantitative_research_98: 'Faktor təhlili',
  quantitative_research_99: 'Seqmentasiya',
  quantitative_research_100: 'Perseptual xəritəçəkmə',
  quantitative_research_101: 'Korrelyasiya',
  quantitative_research_102: 'Dönüşüm modeli',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Klaster təhlili',
  quantitative_research_105: 'və s.',
  quantitative_research_106: 'Analitik Hesabatlar',
  quantitative_research_107:
    'Biz müxtəlif metodologiyalardan istifadə edərək analitik hesabatlar hazırlamaq üçün SPSS® və digər vasitələrdən istifadə edirik',
  quantitative_research_108: 'Hansı biznes problemlərini həll etmək olar:',
  quantitative_research_109: 'Hansı biznes problemlərini həll etmək olar:',
  social_responsibility_1: '4Service Korporativ Sosial Məsuliyyət',
  social_responsibility_2:
    'Komandamızın hər bir üzvünün ürəyindən və ruhundan. Biz hər bir işçimizdə mehribanlıq qığılcımı saxlamağa və ətrafımızdakı dünyanı bir az da yaxşılaşdırmağa çalışırıq',
  social_responsibility_3: 'Təhsil fəaliyyəti / Gənclərə dəstək',
  social_responsibility_4: 'Uşaqlar və tələbələr üçün təbliğat təşəbbüslərimiz',
  social_responsibility_5: 'Service Mania biznes oyunu',
  social_responsibility_6:
    'Təlim fəaliyyətlərimizin mühüm hissəsi Service Mania biznes oyunudur.',
  social_responsibility_7:
    'Service Mania sizi qeyri-adi vəziyyətlərlə necə məşğul olmaq barədə strateji düşünməyə çağırır.',
  social_responsibility_8: 'Tədqiqat təqdimatı / Diiya. Biznes',
  social_responsibility_9:
    'Xidmət vəziyyəti ilə bağlı biznes və müştəri perspektivi: 4Service Ukraynanın İnkişaf şöbəsinin rəhbəri Viktoriya Skorbota tərəfindən 4Service tədqiqat nəticələrimizin təqdimatı',
  social_responsibility_10: 'Xidmət Dizayn seansları',
  social_responsibility_11:
    'Xidmət Dizaynı metodologiyası 4Service-in mühüm hissəsidir. Bir neçə il əvvəl biz DesignThinkers Academy-nin tərəfdaşı olduq və biz işimizdə təkcə ən yaxşı təcrübələrdən istifadə etmirik, həm də ən yaxşı şirkətlərə fəal şəkildə təlimlər veririk',
  social_responsibility_12: 'Xeyriyyəçilik / Könüllülük',
  social_responsibility_13:
    'Uşaq evləri, qocalar üçün qayğı evləri və Ukrayna ordusu üçün könüllülər. Fəaliyyətimizin ən təsirli hissəsi',
  social_responsibility_14: '#HelpEasyWith4ServiceGroup',
  social_responsibility_15:
    'Biz Blagomai Xeyriyyə Fondu ilə əməkdaşlıq edirik və bayram keçirməyə və çox ehtiyacı olanlara kömək etməyə çalışırıq',
  social_responsibility_16: 'Uşaq evləri və qocalar evləri üçün pul yığılması',
  social_responsibility_17:
    'Biz 4Service-də qocalar evləri və uşaq evləri üçün əsas tələbat mallarının alınması üçün bir neçə ianə toplamağa başladıq',
  social_responsibility_18: '#SaveUkraine',
  social_responsibility_19:
    'Müharibə bir çox əməkdaşlarımıza və yaxın dostlarımıza təsir etdi. Hər birimiz bacardığımız qədər kömək edirik',
  social_responsibility_20: 'Heyvanların rifahı',
  social_responsibility_21:
    'Hər kəs öz ev heyvanı ilə ofisimizə gələ bilər. Biz sığınacaqlara kömək edirik, yemək üçün vəsait toplayır və bəzən evsiz heyvanlar üçün ev axtarırıq',
  social_responsibility_22: 'Heyvan sığınacaqlarına dəstək',
  social_responsibility_23:
    'Evsiz heyvanların cəmiyyətimizin bütün həssas üzvləri kimi köməyə ehtiyacı var.',
  social_responsibility_24:
    'Şirkətimiz Sirius Sahibsiz Heyvanları Sığınacağını (Kiyev vilayəti, Ukrayna) öz himayəmizə götürüb.',
  social_responsibility_25:
    'Sahibsiz heyvanlara kömək etmək üçün yaradıcı təşəbbüslər',
  social_responsibility_26:
    'Heyvan sığınacaqlarının təcili məsələsini müştərilərimizin və tərəfdaşlarımızın diqqətinə necə çatdıra bilərik?',
  social_responsibility_27: 'Heyvan dostu ofis',
  social_responsibility_28:
    'Komanda üzvlərimizin çoxunun ev heyvanları var. Biz hər kəsin öz ev heyvanları ilə ofisə girib rahat hiss edə biləcəyi sağlam mühit yaratmışıq',
  ux_testing_1: 'UX Testi',
  ux_testing_1_1: 'UX Testi',
  ux_testing_2:
    'Veb saytlarınızı və tətbiqlərinizi sınamaq və təkmilləşdirmək üçün insan və AI əsaslı yanaşma',
  ux_testing_3: 'UX testi nədir',
  ux_testing_4:
    'UX testi insanların məhsulunuz, tətbiqiniz və ya veb saytınızla necə qarşılıqlı əlaqədə olduğunu anlamağa kömək edir',
  ux_testing_5: 'Naviqasiya çox aydın deyil',
  ux_testing_6: 'Sifariş vermək üçün heç bir fikrim yoxdur',
  ux_testing_7: 'Kataloqda kifayət qədər foto yoxdur',
  ux_testing_8: 'Sən ... əcəksən:',
  ux_testing_9: 'Təkmilləşdirmə imkanlarını kəşf edin',
  ux_testing_10:
    'Dizaynda və müştərinin qarşılıqlı əlaqəsində problemləri müəyyən edin',
  ux_testing_11:
    'İstifadəçinin saytla əlaqə qurarkən hansı çətinliklərlə üzləşdiyini öyrənin',
  ux_testing_12: 'Müştəri səyahətini müşahidə edin',
  ux_testing_13:
    'Hədəf auditoriyanızın saytı nə vaxt və niyə tərk etdiyini tanıyın',
  ux_testing_14:
    'Hansı bölmələrin ən az və ən yüksək maraq və emosiya doğurduğunu müəyyənləşdirin',
  ux_testing_15: 'İstifadəçi əsaslı UX testi',
  ux_testing_16:
    '4Service sizə keyfiyyət və kəmiyyət üsullarından istifadə edərək müştəri mərkəzli veb-saytlar və proqramlar yaratmaq üçün lazım olan real insan anlayışlarını təqdim edir',
  ux_testing_17:
    'Saytdan necə istifadə edəcəyimi başa düşmürəm. Menyunun gizləndiyi yer?',
  ux_testing_18: 'Məncə hərflər arasında çox boşluq var, mətn oxunmur',
  ux_testing_19: 'Şəkillər bir-biri ilə üst-üstə düşür. Bu belə olmalıdır?',
  ux_testing_20:
    'UX testi insanların məhsulunuz, tətbiqiniz və ya veb saytınızla necə qarşılıqlı əlaqədə olduğunu anlamağa kömək edir',
  ux_testing_21: 'İstifadəçi əsaslı UX test tədqiqat dizaynı:',
  ux_testing_22: 'Təkmilləşdirmə imkanlarını kəşf edin',
  ux_testing_23:
    'Sınaq növü və metodunun müəyyən edilməsi (keyfiyyət/kəmiyyət)',
  ux_testing_24: 'Hipotezlərin və istifadəçi ssenarilərinin tərtibi',
  ux_testing_25: 'Test nəticələrinin təhlili və hesabatı',
  ux_testing_26: 'İki həll yolu',
  ux_testing_27: 'Biz uğurlu UX testinə iki yanaşma təklif edirik',
  ux_testing_28: 'İstifadəçi əsaslı UX testi',
  ux_testing_29: 'AI + insan əsaslı UX testi',
  ux_testing_30: 'Bizim güclü tərəflərimiz:',
  ux_testing_31: 'Rəqabət mənzərəsini təhlil edə bilərik',
  ux_testing_32:
    'Müsahibələr aparmaq və iştirak etmək üçün dar ekspertləri cəlb edə bilərik',
  ux_testing_33:
    'Biz müxtəlif dillərdə müsahibələr apara və onları tələb olunan dilə tərcümə edə bilərik',
  ux_testing_34: 'mövcud istifadə qabiliyyəti ilə',
  ux_testing_35: 'ölçülər və ya standartlar',
  ux_testing_36: 'performansınız',
  ux_testing_37: 'rəqibə qarşı',
  ux_testing_38: 'versiyaları',
  ux_testing_39: 'eyni məhsuldan',
  ux_testing_40: 'Məmnuniyyət reytinqi',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Uğur dərəcələri',
  ux_testing_43: 'Səhv dərəcəsi',
  ux_testing_44: 'Tapşırığın tamamlanma vaxtı',
  ux_testing_45: 'İstifadəçi əsaslı UX testi',
  ux_testing_46: 'Kəmiyyət metodu',
  ux_testing_47:
    'Bu mərhələ üçün UX testində iştirak etmək üçün ən azı 100 nəfərdən istifadə edirik',
  ux_testing_48:
    'Veb saytınızın və ya tətbiqinizin müqayisəsinə ehtiyacınız olduqda müvafiqdir:',
  ux_testing_49: 'Kəmiyyət metodu ölçüsü:',
  ux_testing_50: 'Müşahidə',
  ux_testing_51:
    'Bir istifadəçi və ya istifadəçi qrupu ilə vaxt keçirmək və gündəlik həyatda istifadə etdikləri məhsulla davranışlarını müşahidə etmək',
  ux_testing_52: 'Dərin müsahibə',
  ux_testing_53:
    'Saytınıza gələn istifadəçilərin münasibətini, inanclarını, istəklərini və təcrübələrini öyrənməyə imkan verin. 15-20 müsahibə keçirməyi məsləhət görürük',
  ux_testing_54: 'Etnoqrafik tədqiqat',
  ux_testing_55:
    'Respondentlərin məhsuldan istifadə edəcəkləri mühitdə aparılır. Müşahidə etməklə siz istifadəçinin psixologiyasını, qarşılaşdıqları emosional çətinlikləri yaxşı başa düşməyə başlayırsınız.',
  ux_testing_56: 'İstifadəçi əsaslı UX testi',
  ux_testing_57: 'Keyfiyyət metodu',
  ux_testing_58:
    'Bu üsul istifadəçilərin davranışının motivasiyasını və məntiqini anlamağa kömək edir',
  ux_testing_59: 'istifadə edirik:',
  ux_testing_60:
    'Araşdırmamızın nəticələrini göstərmək üçün müştəri səyahət xəritəsi hazırlayırıq',
  ux_testing_61: '30 iştirakçı (kişi/qadın)',
  ux_testing_62:
    'Respondentlər əvvəlcədən yoxlayır və onları sorğuda iştirak etməyə dəvət edirlər',
  ux_testing_63: 'Wantent platformasında uzaqdan onlayn müsahibə',
  ux_testing_64: 'AI + insan əsaslı',
  ux_testing_65: 'AI + insan əsaslı UX testi',
  ux_testing_66:
    'Unikal süni intellekt texnologiyası Wantent və real istifadəçilərin kombinasiyası',
  ux_testing_67: 'Başlıqdakı böyük şrift diqqəti cəlb edib',
  ux_testing_68:
    'İstifadəçilər “ucadan danışan” nömrələrə baxırlar, lakin onların yanındakı məlumatları oxumurlar',
  ux_testing_69:
    'UX testi insanların məhsulunuz, tətbiqiniz və ya veb saytınızla necə qarşılıqlı əlaqədə olduğunu anlamağa kömək edir',
  ux_testing_70: 'AI + insan əsaslı test tədqiqat dizaynı',
  ux_testing_71: 'Bu necə işləyir',
  ux_testing_72:
    'Wantent süni intellektə əsaslanan məzmun bazarına uyğun həlldir.',
  ux_testing_73:
    'Wantent auditoriyanın emosional reaksiyalarını və nişanlarını təhlil etmək üçün ML texnologiyalarını tətbiq etməklə vebsayt və proqramların səmərəliliyini qiymətləndirir.',
  ux_testing_74:
    'Nəticələri şərh etmək üçün biz performans və böyüməyə təkan vermək üçün hekayə anlatma üsullarından, müxtəliflikdən və empatiyadan istifadə edirik.',
  ux_testing_75:
    'Bölmələr arasında keçid zamanı istifadəçinin diqqəti və duyğuları',
  ux_testing_76:
    'İştirakçılar qrupu üzrə statistika (sessiya boyu diqqət və diqqət)',
  ux_testing_77: 'Səhifə çevrilmələrinin təhlili',
  ux_testing_78: 'Tapşırıqları yerinə yetirərkən diqqət və emosiyalar',
  ux_testing_79: 'Qavraya dair tapıntılar',
  ux_testing_80: 'müxtəlif iştirakçı qrupları tərəfindən veb-sayt',
  ux_testing_81: 'Rahatlığın təhlili',
  ux_testing_82: 'səhifələrdə naviqasiya və vacib elementləri tapmaq asanlığı',
  ux_testing_83: 'Qavrayış təhlili',
  ux_testing_84: 'saytında verilən məlumatlardan',
  ux_testing_85: 'Tərif sahələri',
  ux_testing_86:
    'fərdi səhifələrdə təkmilləşdirmə (naviqasiya, məlumat blokları və s.)',
  ux_testing_87: 'Biz ətraflı təhlil edirik',
  ux_testing_88: 'Tədqiqatın əsas nəticələri',
  ux_testing_89:
    'Veb saytın və ayrı-ayrı səhifələrin strukturunun təkmilləşdirilməsi üçün tövsiyələr',
  ux_testing_90:
    'İstifadə qabiliyyətinin yoxlanılması üçün hədəf auditoriyanı diqqətlə seçirik.',
  ux_testing_91:
    'Biz müxtəlif ölkələrdə, müxtəlif dillərdə və müxtəlif istifadəçi qrupları arasında araşdırma aparırıq',
  ux_testing_92: 'Tətbiqlərdə sınaqdan keçirə bilərik',
  ux_testing_93:
    'və rəqiblərin vebsaytlarında yerləşdirin və müqayisəli təhlillər təqdim edin',
  ux_testing_94: 'Biz müxtəlif tədqiqat üsullarını birləşdiririk.',
  ux_testing_95:
    'Bizim ixtisaslı fasilitatorlarımız test prosesində iştirakçıya rəhbərlik edir.',
  ux_testing_96: 'Sınaq keçiririk',
  ux_testing_97: 'müxtəlif cihazlarda və proqram versiyalarında',
  ux_testing_98: 'Niyə bizi seç?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    '4Service və Wantent-dən unikal tərəfdaş layihəsi © Süni intellekt və məzmun təhlili üçün insan əsaslı yanaşmanın birləşməsi',
  wantent_4: 'məzmun yayımlanmazdan əvvəl uğurun ölçülməsi',
  wantent_5: 'müxtəliflik məsələləri ilə bağlı risklərin azaldılması',
  wantent_6: 'məzmunu təkmilləşdirmək üçün dərin anlayışlar və tövsiyələr',
  wantent_7: 'məzmun və marketinq strategiyası ilə bağlı obyektiv qərarlar',
  wantent_8: 'Wantent süni intellektə əsaslanan məzmun bazarına uyğun həlldir.',
  wantent_9:
    'Wantent, məzmunu bazara uyğunlaşdırmaq üçün süni intellektə əsaslanan bir həlldir. Wantent emosional reaksiya və tamaşaçı cəlbini təhlil etmək üçün ML texnologiyalarını tətbiq etməklə video məzmunun effektivliyini qiymətləndirir.',
  wantent_10:
    'Wantent məzmun bazarına uyğunluğu müəyyənləşdirməyə və məzmunun səmərəliliyini maksimum dərəcədə artırmağa kömək edir',
  wantent_11: 'Ssenari qurulması və iştirakçının işə götürülməsi',
  wantent_12: 'fərdiləşdirilmiş layihə dizaynı və dünya üzrə auditoriya',
  wantent_13: 'Tamaşaçıların reaksiyalarının toplanması',
  wantent_14: 'tamaşaçılardan ətraflı və aydın rəy',
  wantent_15: 'Məzmun qavrayışının təhlili',
  wantent_16: 'AI tərəfindən davranış və emosional reaksiya təhlili',
  wantent_17: 'Son yekun üçün fikirlərin çatdırılması',
  wantent_18: 'təkmilləşdirilməsi üçün tövsiyələr',
  wantent_19: 'Wantent məzmunun səmərəliliyini artırır',
  wantent_20: 'Reklam konsepsiyasının əvvəlcədən sınaqdan keçirilməsi',
  wantent_21: 'Yaradıcı konsepsiya testi',
  wantent_22:
    'Gələcək yaradıcılığın hazırlanmış strategiya ilə uyğunluğunu yoxlamaq və yaradıcı konsepsiyalara/mesajlara mümkün düzəlişlər üçün hədəf auditoriyanın reaksiyalarını başa düşmək',
  wantent_23:
    'Brend elementlərinin görünmə qabiliyyətinin yoxlanılması (istehsal sonrası)',
  wantent_24: 'Reklam/sponsorluq investisiyasının effektivliyinin təsdiqi',
  wantent_25: 'Reklam testi',
  wantent_26: 'A/B testi',
  wantent_27:
    'Yaradıcıların effektivliyinin müqayisəsi və istehsal mərhələsində məşğulluğun və diqqətin artırılması',
  wantent_28: 'Reklam testinə start verildi',
  wantent_29:
    'Mesajların performansını müəyyənləşdirmək və audio və vizualları yaxşılaşdırmaq üçün tövsiyələr yaratmaq',
  wantent_30: 'Televiziya üçün həllər',
  wantent_31:
    'Pilotlar, promolar, yeni formatlar, televiziya aparıcıları testi',
  wantent_32:
    'Ən cəlbedici olanı müəyyən etmək və onu gücləndirmək üçün müqayisəli təhlilin aparılması',
  wantent_33: 'Televiziya şousu və bədii film sınağı',
  wantent_34:
    'Təbii şəraitdə uzun məzmuna baxarkən tamaşaçıların iştirakının, diqqət səviyyəsinin, emosional reaksiyasının təhlili. Video montajını qiymətləndirmək və təkmilləşdirmək üçün avtomatik olaraq tamaşaçıların zirvələrini və diqqəti yayındıran məqamları tapırıq',
  wantent_35: 'UI/UX testi',
  wantent_36:
    'Veb sayt prototiplərinin və mobil proqramların sınaqdan keçirilməsi',
  wantent_37:
    'Wantent texnologiyasından istifadə edərək istifadəçi reaksiyasının və emosiyalarının təhlili:',
  wantent_38: 'diqqət səviyyəsi',
  wantent_39: 'emosional reaksiyalar',
  wantent_40: 'iştirakçıların baxış istiqamətinin istilik xəritələri',
  wantent_41:
    'İştirakçı rəyinə əsaslanan vebsayt və tətbiqin istifadəsinin təhlili:',
  wantent_42: 'Sistemdən istifadə şkalası (SUS)',
  wantent_43: 'Müştəri Səy Balı (Əlaqə Asanlığı)',
  wantent_44: 'Xalis Təşviq Skoru (NPS)',
  wantent_45: 'GDPR uyğunluğu və istifadəçi məxfiliyi',
  wantent_46:
    'Wantent GDPR və CCPA siyasətlərinə tam uyğundur və iştirakçıların şəxsi məlumatlarının toplanması, saxlanması, işlənməsi və qorunması prinsiplərinə cavab verir',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'SDO, Reface (Media və Əyləncə)',
  wantent_49:
    'Biz hər gün maşın öyrənmə texnologiyaları ilə tonlarla yeni məzmun ideyalarını sınaqdan keçiririk. Wantent bizə riskləri və üstünlükləri başa düşməkdə və qiymətləndirməkdə, həllərimizin müxtəlif aspektlərinin səbəbliliyini əldə etmək üçün istifadəçilərin rəylərini, davranışlarını və qavrayışlarını kəşf etməyə kömək edir.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'co-owner TABASCO (Advertising)',
  wantent_52:
    'Wantent həqiqətən unikal xidmət təqdim edir ki, bu da bizə istehlakçıların bəyəndiyi və bəyənmədiyi şeylərin çox kiçik detallarını bilməyə kömək edir. Aldatmaq, yalan danışmağın yolu yoxdur - Wantent həmişə həqiqəti bilir və bununla biz marketinq mütəxəssisləri olaraq kommunikasiyalarımızı necə təkmilləşdirməyə dair qiymətsiz (hahaha - biz dəqiq qiyməti bilirik və əlverişlidir) təlimatları əldə edirik.',
  wantent_53: 'Rəylər',
  terms_1: 'Şərtlər və qaydalar',
  terms_2: 'İstifadə Şərtləri Müqaviləsi',
  terms_3:
    'Zəhmət olmasa vebsaytdan istifadə etməzdən əvvəl bu İstifadə Müqaviləsini (“istifadə şərtləri”, “razılaşma”) diqqətlə oxuyun',
  terms_4:
    '(“veb sayt”) 4Service Holdings GmbH (“4Service”, “biz”, “biz”, “bizim”) tərəfindən idarə olunur.',
  terms_5:
    'İstifadə şərtləri Bu vebsaytdan istifadə etməklə siz bu Müqaviləni oxuduğunuzu və nəzərdən keçirdiyinizi və onun şərtlərinə əməl etməyə razı olduğunuzu təsdiq edirsiniz. Bu Müqavilənin şərtləri ilə bağlı olmaq istəmirsinizsə, müvafiq olaraq veb saytı tərk etməyiniz tövsiyə olunur. 4Service yalnız onun şərtlərini qəbul edənlərə bu vebsaytdan, onun məhsullarından və xidmətlərindən istifadə və giriş imkanı verir.',
  terms_6: 'Gizlilik Siyasəti',
  terms_7:
    'Veb saytımızdan istifadə etməyə davam etməzdən əvvəl istifadəçi məlumatlarının toplanması ilə bağlı Məxfilik Siyasətimizi oxumağınızı məsləhət görürük. Bu, təcrübələrimizi daha yaxşı başa düşməyə kömək edəcək.',
  terms_8: 'Yaş məhdudiyyəti',
  terms_9:
    'Bu veb-saytdan istifadə etməzdən əvvəl ən azı 16 (on altı) yaşınız olmalıdır. Bu vebsaytdan istifadə etməklə siz ən azı 16 (on altı) yaşınız olduğuna və qanuni olaraq bu Müqaviləyə əməl edə biləcəyinizə zəmanət verirsiniz. 4Service yaşın təhrif edilməsi ilə bağlı öhdəliklərə görə heç bir məsuliyyət daşımır.',
  terms_10: 'Əqli mülkiyyət',
  terms_11:
    'Bu veb-saytda təqdim olunan bütün materialların, məhsulların və xidmətlərin bütün müəllif hüquqları, ticarət sirləri, ticarət nişanları, patentlər və digər əqli mülkiyyət daxil olmaqla 4Service, onun filialları, direktorları, məmurları, əməkdaşları, agentləri, təchizatçıları və ya lisenziya verən mülkiyyəti olduğu ilə razılaşırsınız. . Siz həmçinin elektron, rəqəmsal və ya yeni ticarət nişanı qeydiyyatları da daxil olmaqla 4Service-in əqli mülkiyyətini heç bir şəkildə təkrar istehsal etməyəcəyiniz və ya yenidən paylamayacağınızla razılaşırsınız.',
  terms_12: 'Veb saytımıza daxil olmaq',
  terms_13:
    'Saytımıza girişə müvəqqəti olaraq icazə verilir və biz xəbərdarlıq etmədən saytımızda təqdim etdiyimiz xidməti geri götürmək və ya dəyişdirmək hüququnu özümüzdə saxlayırıq. Hər hansı səbəbdən saytımız istənilən vaxt və ya hər hansı bir müddət ərzində əlçatmaz olarsa, biz məsuliyyət daşımayacağıq. Zaman-zaman bizdə qeydiyyatdan keçmiş istifadəçilərin saytımızın bəzi hissələrinə və ya bütün saytımıza girişini məhdudlaşdıra bilərik. Saytımıza daxil olmaq üçün lazım olan bütün tədbirləri, o cümlədən saytımıza uyğun olan avadanlıqdan istifadə etmək üçün məsuliyyət daşıyırsınız. Siz həmçinin internet bağlantınız vasitəsilə saytımıza daxil olan bütün şəxslərin bu şərtlərdən xəbərdar olmasını və onlara əməl etmələrini təmin etmək üçün məsuliyyət daşıyırsınız.',
  terms_14: 'Tətbiq olunan qanun',
  terms_15:
    'Bu vebsayta daxil olmaqla siz razılaşırsınız ki, Avstriya Respublikasının qanunları, konflikt qanunlarının prinsiplərindən asılı olmayaraq, bu şərtləri və ya 4Service ilə sizin aranızda yarana biləcək hər hansı mübahisəni tənzimləyəcək.',
  terms_16: 'Mübahisələr',
  terms_17:
    'Bu veb-sayta səfərinizlə bağlı hər hansı mübahisə Vyana Ticarət Məhkəməsi tərəfindən arbitraj edilir.',
  terms_18: 'Təzminat',
  terms_19:
    'Siz 4Service və onun filiallarına təzminat ödəməyə və veb saytımızdan istifadəniz və ya sui-istifadəniz nəticəsində yarana biləcək hüquqi iddia və tələblərə qarşı 4Service-i zərərsiz saxlamağa razılaşırsınız. Biz öz hüquq müşavirimizi seçmək hüququnu özümüzdə saxlayırıq.',
  terms_20: 'Saxlama müddəti',
  terms_21:
    'Emal məqsədləri nəzərə alınmaqla, İstifadəçilərin şəxsi məlumatlarının saxlanma müddəti (saxlanma müddəti) Sizdən lazımi qaydada məlumatların emalı ilə bağlı razılığın alındığı tarixdən 24 aydır.',
  terms_22: 'Məsuliyyətin məhdudlaşdırılması',
  terms_23:
    '4Service vebsaytımızdan sui-istifadəniz nəticəsində baş verə biləcək hər hansı zərərə görə məsuliyyət daşımır. 4Service xəbərdarlıq etmədən istənilən vaxt bu dərci yeniləməklə bu Müqaviləni redaktə etmək, dəyişdirmək və dəyişdirmək hüququnu özündə saxlayır. Veb saytdan indi və ya hər hansı dəyişiklik və ya modifikasiyanın dərcindən sonra davamlı istifadəniz bu cür dəyişiklikləri və ya dəyişiklikləri qəbul etdiyinizi göstərəcək. Bu müqavilənin hər hansı bir hissəsi qeyri-qanuni, etibarsız və ya icraedilməz elan olunarsa, həmin hissə bölünə bilən hesab olunacaq və qalan müddəaların etibarlılığına və tətbiqinə təsir göstərməyəcək. Saytın qanunsuz məqsədlər üçün istifadə edilməsinə dair mümkün sübutlar hüquq-mühafizə orqanlarına təqdim ediləcək. Bu Müqavilə 4Service ilə istifadəçi arasında anlaşmadır.',
  terms_24:
    'Məxfilik/istifadə ilə bağlı bütün suallarınızı və narahatlıqlarınızı aşağıdakı ünvana göndərin:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Vienna',
  terms_28: 'Austria',
  terms_29: 'Baxış-icmal',
  terms_30:
    'Bizə təqdim etdiyiniz şəxsi məlumatlar qeyri-dəqiq olmadıqda, onları həqiqətən düzəltməmizi tələb etmək hüququnuz var (GDPR Maddə 16).',
  thank_you_1: 'Çox sağ ol!',
  thank_you_2: '24 saat ərzində sizinlə əlaqə saxlayacağıq',
  thank_you_3:
    'P.S. Menecerlərimiz daha yaxşı təklif hazırlamaq üçün sorğunuzu artıq nəzərdən keçirir',
  thank_you_4: 'Menyu',
  thank_you_5: 'Yazan qadın',
  privacy_policy_1:
    'Məlumat subyektləri üçün məxfilik bildiriş forması (veb-saytın istifadəçiləri)',
  privacy_policy_2:
    'Bu Məxfilik Bildirişi Forması (Məxfilik Bildirişi) internet saytına daxil olmaq çərçivəsində GDPR tələblərinə uyğun olaraq şəxsi məlumatları 4Service Holdings GmbH tərəfindən toplanan fiziki şəxslər (Məlumat subyektləri) üçün nəzərdə tutulub',
  privacy_policy_3:
    '(burada müvafiq olaraq Veb-sayt və Veb-saytın İstifadəçiləri/İstifadəçiləri).',
  privacy_policy_4:
    '4Service Holdings GmbH-nin Məlumat Subyektləri (Veb saytının İstifadəçiləri) üçün Məxfilik Bildirişi Forması 4Service Holdings GmbH-nin nəzarəti və ya nəzarəti altında olan qanuni səlahiyyətlər altında olan 4Service Group-un bütün şirkətlərində tətbiq edilir.',
  privacy_policy_5: 'Əlaqələr',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Şirkət/biz/biz),',
  privacy_policy_8: 'Ünvan:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienna, Austria.',
  privacy_policy_10: 'E-poçt:',
  privacy_policy_12: 'Biz kimik?',
  privacy_policy_13:
    'Vebsayta hər hansı məqsədlə daxil olan şəxs (Siz). Bu Sənədin məqsədi üçün Siz həmçinin İstifadəçi kimi göstəriləcəksiniz.',
  privacy_policy_14: 'Sizdən topladığımız şəxsi məlumatlar',
  privacy_policy_15:
    'Veb-saytımıza daxil olduğunuz zaman biz Sizə Məxfilik bildirişi göndəririk və bu bildirişdə aşağıdakı məlumatların işlənməsi üçün bizə razılıq vermənizi xahiş edirik:',
  privacy_policy_16:
    'IP ünvanı, istifadəçinin adı, soyadı, ünvanı, telefon nömrəsi (sabit və ya mobil), e-poçt ünvanı, ünvan, şirkət adı, ölkə, e-poçt ünvanı, ad, soyad, telefon nömrəsi, əyalət, əyalət və ZIP/ Poçt kodu, istifadə məlumatları, xarici sosial şəbəkələr və ya platformalarla qarşılıqlı əlaqə haqqında məlumatlar, veb-saytda qeydiyyat və autifikasiya haqqında məlumatlar',
  privacy_policy_17: 'coğrafi mövqe.',
  privacy_policy_18:
    'Əgər siz öz iradənizi bildirsəniz və bizə belə razılıq versəniz, biz sizdən bu cür məlumatları emal etməyə başlayırıq.',
  privacy_policy_19: 'Qanunilik (razılıq)',
  privacy_policy_20:
    'Veb-saytımıza daxil olduğunuz zaman biz Sizə Məxfilik bildirişi göndəririk və bu bildirişdə aşağıdakı məlumatların işlənməsi üçün bizə razılıq vermənizi xahiş edirik:',
  privacy_policy_21:
    'Şəxsi məlumatların emalının hüquqi əsası aşağıdakı linkdə razılıq formasını doldurmaqla Sizdən alacağımız razılıqdır:',
  privacy_policy_22:
    'Əgər siz razılıq formasını doldurursunuzsa, bu o deməkdir ki, siz bu Məxfilik Bildirişində göstərilən bütün şərtləri başa düşürsünüz və qəbul edirsiniz.',
  privacy_policy_23: 'Razılığın geri götürülməsi',
  privacy_policy_24:
    'İstənilən vaxt bizə əvvəllər verdiyiniz razılığı geri götürmək hüququnuz var. Razılığın geri götürülməsi onun geri götürülməsinə qədər razılığa əsaslanan emalın qanuniliyinə təsir göstərmir. Aşağıdakı elektron poçt ünvanına withdraw@4service-group.com bizə müvafiq sorğu göndərməklə razılığınızı geri götürə bilərsiniz, bununla bağlı sorğunun forması aşağıdakı linkdə mövcuddur:',
  privacy_policy_25: 'İstifadəçi üçün Çıxarma Forması',
  privacy_policy_26: 'Emal məqsədləri',
  privacy_policy_27:
    'Biz sizin şəxsi məlumatlarınızı aşağıdakı məqsədlər üçün emal edirik:',
  privacy_policy_28:
    '– müştəri xidmətlərinin təkmilləşdirilməsi (bu, müştərilərin sorğularına daha effektiv cavab verməyə imkan verir); – İstifadəçilərin təcrübəsinin fərdiləşdirilməsi (xidmətlərdə kimin daha maraqlı olduğunu müəyyən etməyə imkan verir); – Saytı təkmilləşdirmək (məhsul və xidmətlərin keyfiyyətini, onlardan istifadənin rahatlığını yaxşılaşdırmağa, məhsul və xidmətlərimizi təkmilləşdirən yeni Xidmətlər hazırlamağa imkan verir); – İstifadəçi ilə xəbər bülletenləri, marketinq və ya tanıtım materialları və xəbərlərimizi, yenilənmələrimizi, xidmətlər haqqında məlumatları özündə əks etdirən digər məlumatlarla təqib e-poçtlarının alınmasından necə imtina etmək barədə göstərişlə əlaqə saxlamaq; – anonimləşdirilmiş məlumatlar əsasında statistik və digər növ tədqiqat və təhlillər aparmaq; – İstifadəçiyə fərdiləşdirilmiş xidmətlər təqdim etmək və müqavilələr və müqavilələr bağlamaq; – İstifadəçinin Sayt vasitəsilə həyata keçirdiyimiz müxtəlif layihələrdə iştirak etmək, İstifadəçinin Sayt vasitəsilə ünvanladığı sorğulara cavab vermək, araşdırma, hesabların və qeydlərin aparılması və xidmətlərin təşviqi.',
  privacy_policy_29: 'Fərdi məlumatların ötürülə biləcəyi subyektlər',
  privacy_policy_30:
    'Şəxsi məlumatlarınızı emal edərkən biz şəxsi məlumatlarınızı Şirkətin emalçısı kimi çıxış edən qurumlara ötürürük. Şirkətin prosessorları yalnız Şirkətin göstərişləri əsasında fəaliyyət göstərir. Şirkətin prosessorlarının apardığı hərəkətlərin xüsusiyyətləri və belə prosessorların siyahısı aşağıdakı linkdə Məxfilik və Məlumatların Mühafizəsi Siyasətində tapıla bilər: Məxfilik və Məlumatların Mühafizəsi Siyasəti',
  privacy_policy_31: 'Şəxsi məlumatların ötürülə biləcəyi ölkələr',
  privacy_policy_32:
    'Şirkət şəxsi məlumatlarınızı GDPR və Aİ Komissiyası tərəfindən nəzərdə tutulduğu kimi adekvatlıq qərarı əsasında ötürür. ABŞ-a şəxsi məlumatların ötürülməsi ilə bağlı əlavə məlumatı aşağıdakı linkdə Məxfilik və Məlumatların Mühafizəsi Siyasətində tapa bilərsiniz: Məxfilik və Məlumatların Mühafizəsi Siyasəti.',
  privacy_policy_33: 'Saxlama müddəti',
  privacy_policy_34:
    'Emal məqsədləri nəzərə alınmaqla, İstifadəçilərin şəxsi məlumatlarının saxlanma müddəti (saxlanma müddəti) Sizdən lazımi qaydada məlumatların emalı ilə bağlı razılığın alındığı tarixdən 24 aydır.',
  privacy_policy_35: 'Giriş hüququ',
  privacy_policy_36:
    'Sizə aid şəxsi məlumatların emal olunub-olunmadığını bilmək hüququnuz var və 2) əgər belədirsə, GDPR-nin 15-ci maddəsində qeyd olunan bir çox əlavə müddəalarla belə məlumatlara daxil olun.',
  privacy_policy_37: 'Düzəliş etmək hüququ',
  privacy_policy_38:
    'Bizə təqdim etdiyiniz şəxsi məlumatlar qeyri-dəqiq olmadıqda, onları həqiqətən düzəltməmizi tələb etmək hüququnuz var (GDPR Maddə 16).',
  privacy_policy_39: 'Silmək hüququ (unudulmaq hüququ)',
  privacy_policy_40:
    'Sizin şəxsi məlumatlarınızın silinməsini bizdən lazımsız gecikdirmədən əldə etmək hüququnuz var və biz GDPR-nin 17-ci maddəsində göstərilən əsasların tətbiq edildiyi hallarda şəxsi məlumatlarınızı lazımsız gecikdirmədən silmək öhdəliyimiz var.',
  privacy_policy_41: 'Emalın məhdudlaşdırılması hüququ',
  privacy_policy_42:
    'Xüsusilə GDPR-nin 18-ci maddəsində qeyd olunan GDPR çərçivəsində bir neçə istisna olmaqla, şəxsi məlumatlarınızın işlənməsini məhdudlaşdırmaq hüququnuz var.',
  privacy_policy_43:
    'Biz sizə hansı məlumatların toplandığını, necə istifadə edildiyini, nə qədər müddətə saxlanılacağını və üçüncü tərəflərlə paylaşılıb-paylaşmayacağını bildirməyə borcluyuq. Bu məlumat qısa və sadə dildə çatdırılmalıdır.',
  privacy_policy_44: 'Məlumat daşınması hüququ',
  privacy_policy_45:
    'Fərqli xidmətlərdə şəxsi məlumatlarınızı öz məqsədləriniz üçün əldə etməyə və təkrar istifadə etməyə icazə verilir.',
  privacy_policy_46: 'Etiraz etmək hüququ',
  privacy_policy_47:
    'Şirkət tərəfindən emal edilən şəxsi məlumatların emalına etiraz etmək hüququnuz var. Şəxsin maraqlarını, hüquqlarını və azadlıqlarını üstələyən emalı üçün məcburi qanuni əsaslar nümayiş etdirməyənə qədər və ya emal hüquqi iddiaların yaradılması və ya müdafiəsinin həyata keçirilməsi üçün aparılırsa, biz şəxsi məlumatların işlənməsini dayandırmalıyıq.',
  privacy_policy_48:
    'Yalnız avtomatlaşdırılmış emal əsasında qərara tabe olmamaq hüququ',
  privacy_policy_49:
    'Sizin razılıq olmadan baş verən hər hansı avtomatlaşdırılmış profilləşdirməyə etiraz etmək hüququnuz var. Bununla belə, Sizin şəxsi məlumatlarınızın insan iştirakı ilə işlənmək hüququnuz var.',
  privacy_policy_50: 'Şikayətlər',
  privacy_policy_51:
    'Şəxsi məlumatlarınızın Şirkət (yaxud yuxarıda təsvir edilən prosessorlar tərəfindən) tərəfindən necə işləndiyi və ya şikayətinizin necə idarə edildiyi barədə şikayət etmək istədiyiniz halda, birbaşa nəzarət orqanına şikayət vermək hüququnuz var. orqan və Şirkət.',
  privacy_policy_52: 'Bu kontaktların hər biri üçün təfərrüatlar var:',
  privacy_policy_53: 'Nəzarət orqanı:',
  privacy_policy_54: 'Avstriya Məlumatların Mühafizəsi Təşkilatı',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Vienna',
  privacy_policy_58: 'Austria / Europe',
  privacy_policy_59: 'Şirkət:',
  privacy_policy_60: '4Service Holdings GmbH (Company/we/us),',
  privacy_policy_61: 'Ünvan: Tegetthoffstraße 7, 1010 Vienna, Austria.',
  privacy_policy_62: 'E-poçt:',
  privacy_policy_63: 'Məxfilik və məlumatların qorunması siyasəti',
  privacy_policy_64:
    'Məlumatlarınızı necə və niyə istifadə etdiyimiz haqqında ətraflı oxuyun: Məxfilik və Məlumatların Qorunması Siyasəti',
  privacy_policy_65: 'Təsdiq',
  privacy_policy_66:
    'Şirkət, Şirkət daxilində fərdi məlumatların emalı ilə bağlı işçilər arasında rolları müəyyən etmək üçün bütün daxili sənədləri işləyib hazırlamışdır, xüsusən də bu sənədin qanuniliyinin təsdiqlənməsi və yoxlanmasına cavabdeh olan İdarəedici Direktordur.',
  contacts_1: 'Müştərilər üçün',
  contacts_2:
    'Bütün növ bizneslər, bazar araşdırması agentlikləri və böyük şirkətlər, biznes tərəfdaşları və media üçün.',
  contacts_3: 'Tegetthoffstrasse 7, Vienna, Austria',
  contacts_4: 'Alıcılar üçün',
  contacts_5:
    'Yerli sakinlər və səyahətçilər, sürücülər və tələbələr, peşəkar sirli alıcılar və ev sahibləri.',
  contacts_6: 'Ölkənizdən bizimlə əlaqə saxlayın:',
  contacts_7: 'Italy',
  contacts_8: 'USA',
  contacts_9: 'UK',
  contacts_10: 'Switzerland',
  contacts_11: 'Netherlands',
  contacts_12: 'Slovakia',
  contacts_13: 'Slovenia',
  contacts_14: 'Romania',
  contacts_15: 'AZ',
  contacts_16: 'Ukraine',
  contacts_17: 'Kazakhstan',
  contacts_18: 'Dünyanın Qalanı',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'UX testi',
  menu_6: 'HR tədqiqatı',
  menu_7: 'Keyfiyyətli tədqiqat',
  menu_8: 'Kəmiyyət tədqiqatı',
  menu_9: 'Gizli alış-veriş',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Qiymət monitorinqi',
  menu_14: 'Bizim haqqımızda',
  menu_15: 'Əlaqələr',
  menu_16: 'Bloq',
  menu_17: 'Metodlar',
  menu_18: 'Məhsullar',
  menu_19: 'Alış-veriş edənlər \n platforma',
  menu_20: 'CSR',
  menu_21: 'Avtomobil qiymətlərinin təhlili',
  menu_22: 'Obyekt Yoxlama Auditi',
  menu_23: 'Xidmət Dizaynı',
  menu_24: 'CX',
  nps_passives: 'Passivlər',
  nps_detractors: 'Tənqidçilər',
  nps_promoters: 'Promouterlər',
  nps_a_score: 'reytinq',
  area: 'Ərazi',
  products_tabs_1:
    'Maksimum səmərəliliyə nail olmaq üçün biz də kompleks şəkildə həyata keçiririk:',
  products_tabs_2: 'Məhsullar',
  products_tabs_3:
    'Keyfiyyətli tədqiqat anlayışları, rəyləri və ya təcrübələri başa düşmək üçün rəqəmsal olmayan məlumatların toplanması və təhlilidir',
  products_tabs_4:
    'Kəmiyyət tədqiqat metodları sorğular, anketlər və sorğular vasitəsilə toplanmış məlumatların obyektiv ölçülməsi və ədədi təhlilidir',
  our_clients_1: 'Müştərilərimiz',
  preview_cases_section_1: 'hal',
  preview_cases_section_2: 'Daha maraqlı yazıları oxumaq üçün keyslərə keçin',
  blog_1: 'Oyandıran məzmun blogu',
  blog_2: 'Sorğunuz üçün post yoxdur',
  about: 'Haqqında',
  why_us: 'Niyə biz',
  about_us: 'Bizim haqqımızda',
  key_features: 'Əsas Xüsusiyyətlər',
  solutions: 'Həll yolları',
  monitoring: 'Monitorinq',
  how: 'Necə',
  how_it_works: 'bu necə işləyir',
  when_to_use: 'Nə vaxt istifadə etmək',
  mystery_shopping: 'Gizli alış-veriş',
  voicer: 'Müştərinin səsi',
  wantent: 'Video məzmun testi',
  play4sales: 'Play4Sales',
  price_monitoring: 'Qiymət monitorinqi',
  service_design: 'Xidmət dizaynı',
  qualitative_research: 'Keyfiyyətli tədqiqat',
  quantitative_research: 'Kəmiyyət tədqiqatı',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Avtomobil',
  hashtag_retayl: '#Pərakəndə',
  hashtag_all_posts: '#Bütün_yazılar',
  hashtag_feedback: '#rəy',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Cases',
  hashtag_market_research: '#bazar_araşdırması',
  more: 'Daha çox',
  speak_to_an_expert: 'Bir mütəxəssislə danışın',
  book_consultancy: 'Kitab məsləhəti',
  read_all: 'Hamısını oxuyun',
  find_out_more: 'Ətraflı məlumat əldə edin',
  address: 'Ünvan',
  mon_fri: 'Bazar ertəsi-Cümə',
  phone_number: 'Telefon nömrəsi',
  flag: 'bayraq',
  scroll_to_left: 'sola sürüşdürün',
  online: 'onlayn',
  tel: 'Tel',
  email: 'E-poçt',
  light_it_up: 'İşıqlandırın',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Director of business development',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Senior project manager',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Business Development Manager',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Operations Director',
  about_us_roles_1: 'İdarəetmə',
  about_us_roles_2: 'Qlobal Komanda',
  about_us_roles_3: 'Məhsul sahibləri',
  ad_testing_1: 'Reklam Testi',
  ad_testing_2:
    'Bir reklam kampaniyası böyük bir investisiyadır. Reklamlarınızın güclü olduğuna və maksimum ROI təmin etdiyinə əmin olun',
  ad_testing_3: 'bağlamaq',
  ad_testing_4: 'Xidmət etdiyimiz sənayelər',
  ad_testing_5: '4Service qlobal müştəri təcrübəsi araşdırma holdinqidir',
  ad_testing_6:
    '4Service qlobal marketinq araşdırması və CX holdinqidir. Biz müxtəlif bizneslərə ekspert məlumat toplama xidmətləri təklif edirik',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Pərakəndə',
  ad_testing_9: 'Elektronika',
  ad_testing_10: 'Luxure/Premium',
  ad_testing_11: 'Yemək və Qonaqpərvərlik',
  ad_testing_12: 'Avtomobil',
  ad_testing_13: 'Səyahət və İstirahət',
  ad_testing_14: 'Moda',
  ad_testing_15: 'Banklar',
  ad_testing_16: 'Saatlar və Zərgərlik',
  ad_testing_17: 'Məişət və Məişət texnikası',
  ad_testing_18: 'Esomar üzvü',
  ad_testing_19:
    'Biz müxtəlif sahələrdə müxtəlif bizneslər üçün reklamla bağlı Reklam Testi araşdırması aparmışıq',
  ad_testing_20: 'Əgər varsa, Reklam Testinə ehtiyacınız var',
  ad_testing_21: 'Yeni bir məhsul və ya xidmətin təşviqi',
  ad_testing_22: 'Həssas bir mövzuya toxunmaq',
  ad_testing_23: 'Yeni hədəf demoqrafik və ya bazara çatmağa çalışır',
  ad_testing_24: 'Bir neçə fərqli dizayn variantını müzakirə edin',
  ad_testing_25:
    'İdarəetmə qrupları və ya investorlar üçün konsepsiyanın sübutu lazımdır',
  ad_testing_26: 'Suallara cavab alacaqsınız',
  ad_testing_27: 'Hansı reklam kanalları bu reklamlara ən uyğun gəlir?',
  ad_testing_28: 'Mesajı çatdırmaq üçün düzgün vizuallardan istifadə edirikmi?',
  ad_testing_29: 'Reklam mətnləriniz və mesajlarınız rezonans doğursun?',
  ad_testing_30: 'Hansı reklam kanalları bu reklamlara ən uyğun gəlir?',
  ad_testing_31: 'İlk növbədə onların diqqətini çəkən şey?',
  ad_testing_32: 'Reklamın yerləşdirilməsi və tərtibatı asan başa düşülür?',
  ad_testing_33: 'qiymətləndiririk',
  ad_testing_34: 'Brendləşmə',
  ad_testing_35:
    'markanızın reklamla necə əlaqəli olduğunu və nə qədər yadda qalacağını',
  ad_testing_36: 'Əsas Metriklər',
  ad_testing_37: 'İnteqrasiya',
  ad_testing_38: 'Tanınma',
  ad_testing_39: 'Uyğun',
  ad_testing_40: 'Yaradıcı',
  ad_testing_41: 'Reklamınız necə seçilir və insanların diqqətini çəkir',
  ad_testing_42: 'Əsas Metriklər',
  ad_testing_43: 'TANIMA',
  ad_testing_44: 'BƏYƏNMƏK',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTİVASİYA',
  ad_testing_47: 'Müştəri təcrübəsi, emosional əlaqə',
  ad_testing_48:
    'Süni intellekt texnologiyasından və ekspert analitiklərdən istifadə etməklə biz insanların real emosiyalarını təhlil edir və onların reklamla bağlı qavrayışları ilə bağlı fikirlər əldə edirik',
  ad_testing_49: 'Reytinq',
  ad_testing_50: 'Kişi',
  ad_testing_51: 'Qadın',
  ad_testing_52: 'Nişan yüksək',
  ad_testing_53: 'Diqqət',
  ad_testing_54: 'Tamaşaçı nəyi bəyəndi və xatırladı?',
  ad_testing_55: 'Aktrisa',
  ad_testing_56: 'Bərabərlik',
  ad_testing_57: 'Musiqi fonu',
  ad_testing_58: 'Müsbət emosiyalar',
  ad_testing_59: 'Müxtəlif irqlərə və görünüşlərə malik insanlar',
  ad_testing_60: 'Açıq havada çəkilişlər',
  ad_testing_61: 'Tamaşaçı nəyi bəyənmədi və xatırlamadı?',
  ad_testing_62: 'Natiqin səsi ekranda baş verənlərə uyğun gəlmirdi',
  ad_testing_63: 'Məhsula az diqqət',
  ad_testing_64: 'Çox dinamik',
  ad_testing_65: 'Başa düşmək çətindir',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Təəssüratlar',
  ad_testing_68: 'Şoular',
  ad_testing_69: 'Dönüşüm',
  ad_testing_70: 'Media',
  ad_testing_71:
    'Biz müxtəlif kanallara media investisiyalarının monitorinq edilən nəticələrə təsirini hesablayaraq, media fəaliyyəti ilə birlikdə reklam kampaniyasının effektivliyini ölçürük.',
  ad_testing_72:
    'İstənilən formatda, platformada və ya kanalda reklam araşdırması aparırıq',
  ad_testing_73: 'video məzmunu',
  ad_testing_74: 'bayır',
  ad_testing_75: 'TV reklamları',
  ad_testing_76: 'çap edin',
  ad_testing_77: 'rəqəmsal',
  ad_testing_78: 'reklam kampaniyaları',
  ad_testing_79: 'POS ekranı və reklamı',
  ad_testing_80: 'animasiya',
  ad_testing_81:
    'Bizim köməyimizlə siz istənilən mərhələdə reklamınızı sınaqdan keçirə bilərsiniz',
  ad_testing_82: 'Konsepsiya',
  ad_testing_83: 'hansı ideyalar daha güclü qabiliyyətə malikdir?',
  ad_testing_84: 'Erkən mərhələdə istehsal',
  ad_testing_85: 'hansı animasiya istehsalına gedəcək?',
  ad_testing_86: 'İstehsal',
  ad_testing_87: 'diqqəti saxlamaq üçün videonu necə redaktə etmək olar?',
  ad_testing_88: 'Pre-media',
  ad_testing_89:
    'hansı rabitə kanallarının işləyəcəyi və hansı nəticənin gözlənilə biləcəyi?',
  ad_testing_90: 'Reklamdan sonrakı sınaq',
  ad_testing_91: 'İstehsal',
  ad_testing_92:
    'mənim reklamım cəlbedicidir? Yaradıcılığın effektivliyi zaman keçdikcə azalırmı?',
  ad_testing_93: 'Pre-media',
  ad_testing_94:
    'rəqəmlərdəki reklamım nə dərəcədə effektivdir? rəqabətlə necə müqayisə edir?',
  ad_testing_95: 'Reklamdan əvvəl sınaq',
  ad_testing_96:
    'Sizi yüksək keyfiyyətli məlumatlarla təmin etmək üçün istifadə edirik',
  ad_testing_97: 'Kəmiyyət tədqiqat metodologiyası',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Keyfiyyətli tədqiqat metodologiyası',
  ad_testing_100: 'Fokus Qrupları',
  ad_testing_101: 'AI',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Aldığınız analitik hesabatlar',
  ad_testing_104:
    'Analitiklərdən, media ekspertlərindən, marketoloqlardan ibarət komandamız sizə ətraflı tövsiyələrlə hazırlanmış hesabat hazırlayıb təqdim edəcək',
  ad_testing_age: 'yaş',
  ad_testing_ad: 'Elan',
  automotive_industry_price_analysis_1: 'Avtomobil Sənayesi Qiymət Təhlili',
  automotive_industry_price_analysis_2:
    'Rəqabətli qiymət təhlili itirilmiş mənfəətin qarşısını almağa, bazar payını artırmağa və bazar mövqeyinizi izləməyə kömək edə bilər. Uğurlu rəqabət strategiyasını seçməkdə sizə kömək etməyə icazə verin!',
  automotive_industry_price_analysis_3: 'Sitat tələb edin',
  automotive_industry_price_analysis_4: 'Bizim metodologiyamız',
  automotive_industry_price_analysis_5:
    'Qəşəng zərif insanlar avtomobil salonu',
  automotive_industry_price_analysis_6:
    'Mystery Shopping metodu real müştəri təcrübəsini dəqiq əks etdirən və etibarlı icmal təqdim edən yeganə yanaşmadır.',
  automotive_industry_price_analysis_7:
    'Biz dəqiq rəqəmlər və etibarlı çoxtərəfli məlumatlar təklif edirik',
  automotive_industry_price_analysis_8:
    'Dünyanın hər bir ölkəsini əhatə edən qlobal əhatəmiz var',
  automotive_industry_price_analysis_9:
    'Bizim daxili resurslarımıza sirli alıcılar və auditorlar daxildir',
  automotive_industry_price_analysis_10:
    'Biz məlumatlarımızın keyfiyyətini təsdiq edirik',
  automotive_industry_price_analysis_11: 'Bizim üstünlüklərimiz:',
  automotive_industry_price_analysis_12:
    'Xüsusi rəqabətli modellər üçün qiymət monitorinqi (B2B və B2C)',
  automotive_industry_price_analysis_13: 'Endirim səviyyəsinin monitorinqi',
  automotive_industry_price_analysis_14:
    'Brendiniz və rəqibləriniz üçün pərakəndə satış ehtiyatının monitorinqi',
  automotive_industry_price_analysis_15:
    'Elektron ticarətdə avtomobil qiymətlərinin monitorinqi',
  automotive_industry_price_analysis_16: 'Qiymət kommunikasiyasının təhlili',
  automotive_industry_price_analysis_17:
    'Pərakəndə çatdırılma xərclərini izləmək',
  automotive_industry_price_analysis_18:
    'Pərakəndə satış və promosyonların monitorinqi',
  automotive_industry_price_analysis_19:
    'Aylıq ödəniş məbləğlərinin müəyyən edilməsi',
  automotive_industry_price_analysis_20: 'İcarə ödənişlərinin hesablanması',
  automotive_industry_price_analysis_21:
    'Elektrikli nəqliyyat vasitələri (EV) üçün xüsusi qiymət mülahizələrinin təhlili',
  automotive_industry_price_analysis_22:
    'Birbaşa istehlakçıya qarşı pərakəndə qiymət müqayisəsinin aparılması',
  automotive_industry_price_analysis_23:
    'Abunə qiymətləri modellərinin hazırlanması',
  automotive_industry_price_analysis_24:
    '4Service 20 ildən artıq təcrübəyə malik qlobal müştəri təcrübəsi (CX) holdinq şirkətidir',
  automotive_industry_price_analysis_25:
    'Təklifinizi optimallaşdırmaq və hədəf müştəriləriniz üçün ən vacib xüsusiyyətləri müəyyən etmək üçün istehlakçıların müxtəlif funksiyalar üçün ödəniş etmək istəyi haqqında məlumatlardan istifadə edəcəksiniz.',
  automotive_industry_price_analysis_26:
    '4Service-in avtomobil sənayesində qiymətlərin təhlili daxildir:',
  automotive_industry_price_analysis_27:
    'Ən aşağı qiymətlər həmişə avtomobil markaları üçün rəqabət qabiliyyətinin açarı deyil. Gəlin sizə uzunmüddətli qiymət strategiyası yaratmağa kömək edək',
  automotive_industry_price_analysis_28:
    'Müştərilərin əksəriyyəti başa düşür ki, bütün brendlər üzrə qiymətlər artır',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'Müştərilərin çoxu, brend onlara yaxınlaşan qiymət artımı barədə məlumat versə belə, bəyəndikləri avtomobili alacaqlarını bildiriblər,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'insanların çoxu avtomobil almaq üçün lizinq və aylıq ödənişləri ən çox üstünlük verən model hesab edir',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'dilerlərdən biri ünsiyyət/satış prosesi zamanı qiymət artımının mümkünlüyünü qeyd edib',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Əsas rəqəmlər',
  automotive_industry_price_analysis_37:
    'Siz məhsulunuzu sıradakı digər nəqliyyat vasitələrinə nisbətən necə qiymətləndirəcəyinizi anlayacaqsınız.',
  automotive_industry_price_analysis_38:
    'Təklifinizi optimallaşdırmaq və hədəf müştəriləriniz üçün ən vacib xüsusiyyətləri müəyyən etmək üçün istehlakçıların müxtəlif funksiyalar üçün ödəniş etmək istəyi haqqında məlumatlardan istifadə edəcəksiniz.',
  automotive_industry_price_analysis_39:
    'Müştərilərin çoxu, brend onlara yaxınlaşan qiymət artımı barədə məlumat versə belə, bəyəndikləri avtomobili alacaqlarını bildiriblər',
  automotive_industry_price_analysis_40:
    'Siz müvafiq aylıq ödəniş və lizinq dərəcələrini müəyyən edə biləcəksiniz.',
  automotive_industry_price_analysis_41:
    'Brendiniz və ya dileriniz üçün hansı faydaları əldə edəcəksiniz:',
  automotive_industry_price_analysis_42: 'Siz ərizə təqdim edirsiniz',
  automotive_industry_price_analysis_43:
    'Menecerimiz əsas məqsədləri müəyyən etmək üçün sizinlə əlaqə saxlayır',
  automotive_industry_price_analysis_44: 'Marketinq araşdırması aparırıq',
  automotive_industry_price_analysis_45:
    'Biz sizə müntəzəm olaraq onlayn formatda hesabat və tövsiyələr təqdim edirik',
  automotive_industry_price_analysis_46: 'Satılan avtomobil stikeri',
  automotive_industry_price_analysis_47:
    '1 gün ərzində layihəyə start veririk!',
  automotive_industry_price_analysis_48:
    'Biz ərazinin növünə görə seqmentləşdirilmiş foto hesabatlar, PPT hesabatları və Excel hesabatları daxil olmaqla hərtərəfli hesabat dəstini təklif edirik',
  retail_audit_1: 'SAHƏ MARKETİNQİ AUDİT/pərakəndə audit',
  retail_audit_2:
    'Mağazanız və ya brendiniz haqqında ətraflı məlumat toplayın, böyümə maneələrini təhlil edin və onları aradan qaldırmaq üçün effektiv strategiyalar yaradın',
  retail_audit_3: 'Sitat tələb edin',
  retail_audit_4:
    '4Service, nəzərdə tutulan hədəf bazara çatmaqda onların tanıtım strategiyalarının, sahə icra qruplarının və marketinq taktikalarının effektivliyini qiymətləndirmək üçün brendlər adından mağazada yoxlamalar aparır.',
  retail_audit_5:
    'Biz promosyon kampaniyalarının icrasını diqqətlə izləyirik və tanıtım qaydalarına riayət olunmasını təmin edirik.',
  retail_audit_6: 'Ən yaxşı 10',
  retail_audit_7: 'Avropadakı CX agentlikləri',
  retail_audit_8: 'illik təcrübə',
  retail_audit_9: 'ölkələrin əhatə dairəsi',
  retail_audit_10: '1,6 milyon',
  retail_audit_11: 'illik qiymətləndirmələr',
  retail_audit_12: 'Nə izləyirik?',
  retail_audit_13: 'Satış nöqtəsi (POS) materiallarının olması',
  retail_audit_14:
    'Brend elçilərinin olması və aktivləşdirilməsi, nəzərdə tutulan qrafikə riayət olunmasının təmin edilməsi',
  retail_audit_15:
    'Brend səfirlərinin vəkillik kampaniyalarında iştirakının qiymətləndirilməsi',
  retail_audit_16:
    'İstehlakçıların qarşılıqlı əlaqəsinin qiymətləndirilməsi, tanıtım qaydalarına və brend mesajlaşmasına uyğunluğun təmin edilməsi',
  retail_audit_17: 'Fırıldaqçılığın qarşısının alınması tədbirləri',
  retail_audit_18:
    'Biznesiniz sahə marketinq auditinin nəticələrindən istifadə edə bilər:',
  retail_audit_19: 'Büdcə və proseslərə uyğunluğu qiymətləndirin',
  retail_audit_20:
    'Həqiqi nəticələri nəzərdə tutulan məqsədlərlə müqayisə edin',
  retail_audit_21: 'Marketinq kampaniyalarının performansını artırın ',
  retail_audit_22: 'Gələcək üçün strateji planlaşdırın',
  retail_audit_23: 'Xərcləri azaltmaq üçün imkanları müəyyənləşdirin',
  retail_audit_24: 'Satış və dönüşüm nisbətlərini yaxşılaşdırın',
  retail_audit_25: 'İnvestisiya gəlirini optimallaşdırın (ROI)',
  retail_audit_26: '4SERVICE TİCARƏT İNVESTİSİYA TƏRƏFDAŞI KİMİ SEÇDİLER',
  retail_audit_27:
    'Aparıcı beynəlxalq tütün məmulatları istehsalçısı öz brendlərini təkmilləşdirmək və mağazadaxili performansını yaxşılaşdırmaq üçün 4Service ilə əməkdaşlıq edir. Komandalarımız sahə marketinq auditi və istehlakçı təcrübəsinin canlı hesabatını təqdim edir.',
  retail_audit_28:
    'sahə marketinq agentliyinin planlaşdırma dəqiqliyinin artırılması',
  retail_audit_29: 'marka mesajının çatdırılmasında artım',
  retail_audit_30: 'BA nişanının artması (istehlakçının aktivləşdirilməsi)',
  retail_audit_31:
    'məlumat toplama dəqiqliyinin və GDPR uyğunluğunun artırılması',
  retail_audit_32: 'Hansı dəyərli fikirləri tapmağa meylliyik:',
  retail_audit_33:
    'SLA şərtləri arasında ardıcıllığın olmaması və ya hətta ziddiyyət müştəriyə əhəmiyyətli dərəcədə təsdiqlənməmiş ödənişlərin edilməsinə səbəb olur.',
  retail_audit_34:
    'Təqdim olunan xidmət növlərinin qarışdırılması səbəbindən haqların düzgün hesablanmaması',
  retail_audit_35:
    'Sahə xidməti yerinə yetirilmir, o cümlədən heyət planlaşdırılan yerə təyin olunmur | POS',
  retail_audit_36:
    'Ödənişlər faktiki deyil, təxmini hesablama əsasında aparılıb və tutuşdurulmayıb',
  retail_audit_37: 'Saxta məlumat verən promo təlimatının yanlış tətbiqi',
  retail_audit_38: 'Necə işləyirik?',
  retail_audit_39:
    'Auditorumuz agentliyin aktivləşdirmə planına əsasən təyin edilmiş satış nöqtələrinə (POS) baş çəkir.',
  retail_audit_40:
    'Onlar POS-un mövcudluğu, brend səfirin cəlb edilməsi, tanıtım təlimatlarına riayət edilməsi və fırıldaqçılığın qarşısının alınması tədbirləri kimi qısa şəkildə qeyd edildiyi kimi əsas performans göstəricilərini (KPI) qiymətləndirirlər.',
  retail_audit_41: 'Doğrulama:',
  retail_audit_42: 'Əldə etdiyimiz məlumatları toplayırıq və birləşdiririk.',
  retail_audit_43: 'Hərtərəfli hesabat hazırlanır.',
  retail_audit_44:
    'Təsdiqləmə komandamız layihənin qısa məzmunu və əlavə şərhlər daxil olmaqla, bütün sorğuları dəqiqlik üçün hərtərəfli yoxlayır.',
  retail_audit_45: 'Onlayn Hesabat Sistemi:',
  retail_audit_46:
    'Doldurulmuş və təsdiqlənmiş sorğular 36 saat ərzində onlayn portalımıza yüklənir.',
  retail_audit_47:
    'Müştərilərin şəxsi hesab portalına daxil olmaq imkanı var ki, bu da onlara istənilən vaxt tərəqqiyə nəzarət etməyə imkan verir.',
  retail_audit_48:
    'Nəticələr və tövsiyələrdən ibarət yekun hesabat təqdim olunur.',
  retail_audit_49: 'Bir mütəxəssislə danışın',
  retail_audit_50: 'Bizim güclü tərəflərimiz:',
  facility_check_audit_1: 'Təsis',
  facility_check_audit_2: 'Auditi yoxlayın',
  facility_check_audit_3: 'Hər Detalda Brend Təcrübəsini yüksəldin',
  facility_check_audit_4: 'Bizim yanaşmamızı araşdırın',
  facility_check_audit_5: 'Haqqında',
  facility_check_audit_6: 'Biz kimik',
  facility_check_audit_7:
    'Qlobal müştəri təcrübəsi holdinqi olaraq, 4Service brendlərə müştəriləri üçün müstəsna təcrübələr yaratmağa və çatdırmağa kömək etmək üçün hərtərəfli xidmətlər dəsti təqdim edir. ',
  facility_check_audit_8: 'Obyekt Yoxlama Audit xidmətimiz',
  facility_check_audit_9:
    'Fiziki yerlərinizin və pərakəndə satış mağazalarında brend varlığınızın brend standartlarınıza cavab verməsini və müştərilərdə müsbət təəssürat yaratmasını təmin etmək üçün nəzərdə tutulmuşdur.',
  facility_check_audit_10: 'Həll yolları 1',
  facility_check_audit_11: 'Obyekt Yoxlama Auditi',
  facility_check_audit_12:
    'pərakəndə satış yerlərinizin və ya stendlərinizin brendinizin standartlarına uyğunluğunu qiymətləndirən vasvası imtahandır. Biz təkcə fiziki atributları yoxlamırıq, həm də bu elementlərin markanızın dəyərli müştəriləriniz tərəfindən qəbul edilməsinə necə təsir etdiyini ölçürük.',
  facility_check_audit_13: 'İki həll yolu',
  facility_check_audit_14: 'İki İcra Metodu',
  facility_check_audit_15: 'Metod',
  facility_check_audit_16:
    'Ekspert Auditorlar tərəfindən Obyekt Yoxlama Auditi',
  facility_check_audit_17: 'Obyekt Yoxlamasının Təsdiqlənməsi',
  facility_check_audit_18: 'Hesabatlar',
  facility_check_audit_19:
    'Siz 48 saat ərzində əlçatan olan vizual sübut və statistika ilə tamamlanan hesabatlar əldə edəcəksiniz',
  facility_check_audit_20:
    'Yaxşı icra edilmiş Obyekt Yoxlama Auditi brendiniz üçün bir sıra faydalar təmin edə bilər, o cümlədən:',
  facility_check_audit_21: 'Təkmilləşdirilmiş müştəri dönüşümü:',
  facility_check_audit_22:
    'Müsbət müştəri təcrübəsi satışların artmasına və biznesin təkrarlanmasına səbəb ola bilər. Auditlərimiz müştəri məmnuniyyətinə təsir edə biləcək hər hansı sahələri müəyyən etməyə və həll etməyə kömək edə bilər.',
  facility_check_audit_23: 'Təkmilləşdirilmiş brend imici:',
  facility_check_audit_24:
    'Fiziki yerləriniz müştərilər üçün əsas əlaqə nöqtəsidir və onlar brend imicinizin formalaşmasında mühüm rol oynaya bilər. Auditlərimiz sizə yerlərinizin brend dəyərlərinizə uyğun olmasını və müştərilərdə müsbət təəssürat yaratmasını təmin edə bilər.',
  facility_check_audit_25: 'Niyə biz?',
  facility_check_audit_26: 'Obyekt Yoxlama Auditi niyə vacibdir?',
  facility_check_audit_27:
    'Yaxşı icra edilmiş Obyekt Yoxlama Auditi brendiniz üçün bir sıra faydalar təmin edə bilər, o cümlədən:',
  facility_check_audit_28: 'Təkmilləşdirilmiş brend imici:',
  facility_check_audit_29:
    'Fiziki yerləriniz müştərilər üçün əsas əlaqə nöqtəsidir və onlar brend imicinizin formalaşmasında mühüm rol oynaya bilər. Auditlərimiz sizə yerlərinizin brend dəyərlərinizə uyğun olmasını və müştərilərdə müsbət təəssürat yaratmasını təmin edə bilər.',
  facility_check_audit_30: 'Təkmilləşdirilmiş müştəri dönüşümü:',
  facility_check_audit_31:
    'Müsbət müştəri təcrübəsi satışların artmasına və biznesin təkrarlanmasına səbəb ola bilər. Auditlərimiz müştəri məmnuniyyətinə təsir edə biləcək hər hansı sahələri müəyyən etməyə və həll etməyə kömək edə bilər.',
  facility_check_audit_32: 'Kimə lazımdır',
  facility_check_audit_33: 'Obyekt Yoxlama Auditinə kimin ehtiyacı var?',
  facility_check_audit_34:
    'Obyekt Yoxlama Auditi fiziki yerləri və fiziki mövcudluğu olan istənilən brend üçün dəyərli xidmətdir. İstəyən markalar üçün xüsusilə vacibdir:',
  facility_check_audit_35: 'Brend standartlarına uyğunluğu təmin edin:',
  facility_check_audit_36:
    'Auditlərimiz yerlərinizin brend standartlarınızdan kənara çıxa biləcəyi hər hansı sahələri müəyyən etməyə kömək edə bilər.',
  facility_check_audit_37: 'Müştəri təcrübəsini təkmilləşdirin:',
  facility_check_audit_38:
    'Auditlərimiz sizə yerlərinizdə müştəri təcrübəsini artırmaq imkanlarını müəyyən etməyə kömək edə bilər.',
  facility_check_audit_39: 'Daha güclü brend yaradın:',
  facility_check_audit_40:
    'Auditlərimiz sizə fiziki yerlərinizin müştərilərdə müsbət təəssürat yaratmasını təmin etməyə kömək edə bilər.',
  facility_check_audit_41:
    'İşçiniz və ya sirli alıcınız məkanın şəklini çəkir.',
  facility_check_audit_42:
    'Sonra bir anket doldururlar və şəkli əlavə edirlər.',
  facility_check_audit_43:
    'Validator fotonu qəbul edir və onun standartlara uyğunluğunu yoxlayır.',
  facility_check_audit_44:
    'Ekspert Auditorlar tərəfindən Obyekt Yoxlama Auditi',
  facility_check_audit_45: 'Hazırlıq mərhələsi:',
  facility_check_audit_46: 'Audit cədvəlinin birgə təsdiqi',
  facility_check_audit_47:
    'Dilerlər qarşıdan gələn audit haqqında hazırlanır və məlumatlandırılır',
  facility_check_audit_48: 'Auditor Təlimi:',
  facility_check_audit_49:
    'Təcrübəli 4Service təlimçilərimiz auditorları brend qaydaları ilə gücləndirir',
  facility_check_audit_50: 'Obyektin Audit Mərhələsi:',
  facility_check_audit_51:
    'Xüsusi icazə məktubları ilə silahlanmış auditorlar yerlərə baş çəkirlər',
  facility_check_audit_52:
    'Təlimatlara ciddi əməl edərək hər bir bəndin hərtərəfli araşdırılması',
  facility_check_audit_53:
    'Vizual sənədlər, qərargahın ilkin şərtlərinə qarşı qiymətləndirmələrlə yanaşı',
  facility_check_audit_54: 'Yekun PPT Hesabatı',
  facility_check_audit_55:
    'Tapıntıları aydınlaşdıran hər şeyi əhatə edən PowerPoint hesabatı',
  facility_check_audit_56:
    'Yoxlanılan nəticələrin immersiv onlayn/oflayn təqdimatı',
  facility_check_audit_57: 'Validation of Facility Check',
  facility_check_audit_58: 'Addımlar:',
  facility_check_audit_59: 'Obyektin Audit Mərhələsi:',
  facility_check_audit_60:
    'Brend nümayəndələri yerində şəkillər çəkmək üçün 4Service proqramından istifadə edirlər',
  facility_check_audit_61: 'Ciddi vizual və təsviri meyarlara riayət etmək',
  facility_check_audit_62: 'Doğrulama Mərhələsi:',
  facility_check_audit_63:
    'Çalışqan təsdiqləyicilər hər bir hesabatı diqqətlə nəzərdən keçirirlər',
  facility_check_audit_64:
    'Fotoşəkillərin brend standartları ilə hərtərəfli müqayisəsi',
  facility_check_audit_65:
    'Annotasiyalar və anlayışlar aydınlıq üçün hesabata inteqrasiya edilib',
  facility_check_audit_66:
    'Lazım gələrsə, dəqiqləşdirmələr üçün dilerlərlə birbaşa əlaqə',
  facility_check_audit_67: 'Hesabat mərhələsi:',
  facility_check_audit_68:
    'Vizual sübut və statistika ilə tamamlanan hesabatlar iki gün ərzində əldə edilə bilər',
  facility_check_audit_69: 'Bu gün bizimlə əlaqə saxlayın',
  cx_solution_1: 'Biz yalnız anlayışlar tapmırıq,',
  cx_solution_2: 'Biznesinizi yeni zirvələrə qaldırırıq',
  cx_solution_3:
    '20 ildən çox müştəri təcrübəsi olan ekspertlər mürəkkəb həllər tapır və böyümənizə töhfə verən xüsusi fəaliyyət planı təqdim edir',
  cx_solution_4:
    'Gələcəyə davamlı bir həlldə unikal xidmətlər dəstini kəşf edin',
  cx_solution_5: 'Hamısı bir yerdə CX həlli',
  cx_solution_6: 'Reklam testi',
  cx_solution_7: 'AI & Neuromarketing',
  cx_solution_8: 'Müştərinin səsi',
  cx_solution_9: 'Masa üstü araşdırma',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Mystery shopping',
  cx_solution_12: 'CX konsaltinq',
  cx_solution_13: 'Sosial media dinləmə',
  cx_solution_14: 'Kəmiyyət və keyfiyyət tədqiqatları',
  cx_solution_15: 'Xidmət dizaynı',
  cx_solution_16: 'Marketinq strategiyası',
  cx_solution_17: 'UX/UI testing',
  cx_solution_18: 'Tamaşaçılar haqqında məlumat',
  cx_solution_19: 'Uğurunuza töhfə verməkdən şərəf duyuruq',
  cx_solution_20:
    'Fərdi nəticələr əldə etmək üçün biznesinizin məqsəd və məqsədləri əsasında alətlər və xidmətləri fərdiləşdirir və birləşdiririk',
  cx_solution_21: 'Hərtərəfli tədqiqat',
  cx_solution_22:
    'Məlumat mərkəzli bazarda rəqabət üstünlüyü təmin edərək, qərar qəbul etməyi gücləndirin.',
  cx_solution_23:
    'Keyfiyyətli tədqiqat anlayışları, fikirləri və ya təcrübələri başa düşmək üçün qeyri-rəqəmsal məlumatların toplanması və təhlilidir',
  cx_solution_24:
    'Kəmiyyət tədqiqat metodları sorğular, anketlər və sorğular vasitəsilə toplanmış məlumatların obyektiv ölçülməsi və ədədi təhlilidir',
  cx_solution_25:
    'Biz sizin xüsusi tələblərinizə əsaslanan müxtəlif alətlər dəstindən istifadə edirik',
  cx_solution_26: 'Alətlər',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Kompüter Dəstəkli Veb Müsahibə bizim arsenalımızda mühüm alətdir və bizə xüsusi proqram təminatının köməyi ilə onlayn platformalar vasitəsilə sorğular keçirməyə və məlumat toplamağa imkan verir, müştərilərimizin bazar araşdırması ehtiyacları üçün səmərəli və dəqiq məlumatların toplanmasına təminat verir',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Kompüter Dəstəkli Telefon Müsahibəsi sorğular aparmaq və telefonla qiymətli məlumat toplamaq üçün istifadə etdiyimiz üsuldur, prosesi asanlaşdırmaq və bazar araşdırması səylərimizdə dəqiqliyi təmin etmək üçün xüsusi proqram təminatından istifadə edir',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Üz-üzə yanaşma bizim yanaşmamızın ayrılmaz hissəsidir, müştərilər və müştərilərlə birbaşa qarşılıqlı əlaqələri və fərdiləşdirilmiş nişanları asanlaşdırır və bununla da davamlı əlaqələr qurmaq və biznes uğurunu artırmaq üçün vacib olan inamı, anlayışı və effektiv ünsiyyəti gücləndirir',
  cx_solution_33: 'Masa üstü araşdırma',
  cx_solution_34:
    'Masa üstü tədqiqat hərtərəfli məlumatların toplanması və təhlilini əhatə edir, anlayışlar toplamaq və müştərilərimiz üçün strateji qərarların qəbul edilməsini məlumatlandırmaq üçün tədqiqat prosesimizdə təməl addım kimi xidmət edir',
  cx_solution_35: 'SWOT təhlili',
  cx_solution_36:
    'SWOT təhlili müştərilərimizə daxili Güclü və Zəif tərəfləri, eləcə də xarici İmkanlar və Təhdidləri müəyyən etməyə kömək edən, qərarların qəbulu zamanı məlumat vermək və effektiv biznes strategiyalarını inkişaf etdirmək üçün dəyərli fikirlər təqdim edən strateji qiymətləndirmə vasitəsidir',
  cx_solution_37: 'Etnoqrafik tədqiqat',
  cx_solution_38:
    'İnsanları və mədəniyyətləri onların təbii mühitlərində immersiya və müşahidə yolu ilə öyrənmək, çox vaxt davranışlar, inanclar və sosial dinamika haqqında dərin anlayışlara gətirib çıxarır',
  cx_solution_39: 'Dərin müsahibələr',
  cx_solution_40:
    'Dərin müsahibələr, müştərilərimizin tədqiqat məqsədləri və qərar qəbul etmələri üçün zəngin fikir və anlayış təmin edən, onların perspektivlərini, təcrübələrini və fikirlərini dərindən öyrənmək üçün iştirakçılarla hərtərəfli, təkbətək müzakirələri əhatə edən keyfiyyətli tədqiqat metodudur. proseslər',
  cx_solution_41: 'Fikir seansları',
  cx_solution_42:
    'İdeyalaşdırma sessiyaları müştərilərimizin problemləri və ya imkanları üçün yaradıcı ideyalar və həllər yaratmaq, innovasiyaları təşviq etmək və strateji təşəbbüsləri irəli sürmək üçün müxtəlif perspektivləri və təcrübələri bir araya gətirərək asanlaşdırdığımız birgə beyin fırtınası sessiyalarıdır',
  cx_solution_43: 'Alıcı şəxsiyyətləri',
  cx_solution_44:
    'Alıcı personajları tədqiqat və məlumatların təhlili əsasında müştərilərimizin hədəf müştərilərini təmsil edən, onların ehtiyaclarını, üstünlüklərini və davranışlarını daha yaxşı başa düşməyə, onlarla effektiv şəkildə əlaqə saxlamaq və rezonans yaratmaq üçün strategiyalarımızı və kommunikasiyalarımızı uyğunlaşdırmağa imkan verən, inkişaf etdirdiyimiz ətraflı profillərdir. biznes uğuru',
  cx_solution_45: 'Mystery Shopping',
  cx_solution_46:
    'Mystery Shopping, əvvəlcədən müəyyən edilmiş ssenari zamanı müştəri və şirkət arasında qarşılıqlı əlaqəni ölçən və izlədiyimiz, təklif etdiyimiz strateji xidmətdir',
  cx_solution_47: 'Yoxlama müşayiəti',
  cx_solution_48:
    'Müşayiət yoxlaması sirli alış-verişin ixtisaslaşdırılmış formasıdır, burada qiymətləndiricilərimiz bütün xidmət təcrübəsi boyunca müştəriləri müşayiət edir, müştəri səyahətinin hər aspekti ilə bağlı hərtərəfli anlayışlar təqdim edir, müştərilərimizə xidmətlərin göstərilməsini dərindən başa düşməyə və ümumi vəziyyəti yaxşılaşdırmaq üçün məlumatlı təkmilləşdirmələr etməyə imkan verir. müştəri məmnuniyyəti və sadiqlik',
  cx_solution_49: 'Rəylərin toplanması təhlili',
  cx_solution_50:
    'Rəylərin toplanması təhlili sorğular, rəylər və sosial media kimi müxtəlif kanallardan müştəri rəylərinin sistematik şəkildə toplanmasını və təhlilini əhatə edən kritik bir prosesdir və bizə dəyərli fikirlər əldə etməyə, tendensiyaları müəyyən etməyə və müştərilərimiz üçün dataya əsaslanan tövsiyələr verməyə imkan verir. müştərilərin məhsullarını, xidmətlərini və ümumi müştəri təcrübəsini təkmilləşdirmək',
  cx_solution_51: 'Fokus qrupları',
  cx_solution_52:
    'Fokus-qruplar, xüsusi mövzuları və ya məhsulları dərindən müzakirə etmək üçün müxtəlif iştirakçılar qrupunu bir araya gətirərək, keyfiyyət anlayışlarını toplamağa, münasibətləri, üstünlükləri və qavrayışları üzə çıxarmağa, ideya və konsepsiyaları araşdırmağa imkan verən dinamik tədqiqat sessiyalarıdır. müştərilərin qərar qəbul etmə və strategiya inkişaf prosesləri',
  cx_solution_53: '20 illik müstəsna müştəri təcrübəsi yaratmaq',
  cx_solution_54:
    'Mesajınızın auditoriyanızı cəlb etdiyinə və rezonans doğurduğuna əmin olun.',
  cx_solution_55:
    'Biz gözləntilərinizi üstələyən nəticələr təqdim etməyə çalışırıq',
  cx_solution_56: 'Böyümənizə töhfə verən kompleks həllər kəşf edirik',
  cx_solution_57: 'Metodologiyamızla müştəri təcrübənizi yüksəldin ',
  cx_solution_58:
    'Müştəri Səyahət Xəritəçəkmə hədəf auditoriyasını müəyyən etmək, onların ehtiyaclarını və mühitini başa düşmək və sonra cəlbedici təcrübə təqdim etmək üçün plan yaratmaqdır',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'Brend Sağlamlığını İzləmə araşdırması, markanızın məlumatlılıq və istifadə, markanın yerləşdirilməsi və brend performansı üzrə necə performans göstərdiyini ölçə bilər',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'Müştəri Məmnuniyyəti İndeksi, müştərilərinizin şirkətin məhsullarından/xidmətlərindən nə dərəcədə razı qaldıqlarını göstərən bir baldır.',
  cx_solution_63:
    'Qiymət monitorinqi rəqiblərin qiymətlərinin toplanması, işlənməsi və təhlili prosesidir',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Net Promoter Score, bir suala əsaslanan müştəri təcrübəsi ölçülərinin qızıl standartıdır: Təşkilat/Məhsul/Xidməti nə dərəcədə tövsiyə edərdiniz?',
  cx_solution_66: 'HR tədqiqatı',
  cx_solution_67:
    'İnsan Resursları Tədqiqatı HR təcrübələrini və performansını qiymətləndirmək üçün istifadə olunur və mövcud inkişaf və idarəetmə ilə bağlı ətraflı təhlil təklif edir',
  cx_solution_68:
    'İstifadə qabiliyyəti testi real insanların vebsayt, proqram və ya digər məhsulla necə qarşılıqlı əlaqədə olması və onların davranışını və ona reaksiyalarını müşahidə etməsidir',
  cx_solution_69: 'Digər',
  cx_solution_70:
    'Mütəxəssislər biznesiniz üçün ən yaxşı metodologiyanı seçirlər',
  cx_solution_71: 'Başlayaq',
  cx_solution_72: '1 - GÖRÜŞÜN BAŞLAMASI',
  cx_solution_73:
    'Başlanğıc iclası zamanı biz əsas vəzifələri müəyyənləşdiririk və onlara diqqət yetiririk. Nəticə: məqsədi, tədqiqat vəzifələrini, son tarixləri, metodları və məsuliyyət sahələrini başa düşmək.',
  cx_solution_74: '2 - KONSEPTİN İNKİŞAF EDİLMƏSİ',
  cx_solution_75:
    '4Service analitika şöbəsi məlumatları ümumiləşdirir, hesabat strukturunu əlaqələndirir. Daha sonra biz nəticələri müştəriyə təqdim edirik, onu həyata keçirmək üçün anlayışlar və tövsiyələr əlavə edirik.',
  cx_solution_76: '3 - SAHƏ İŞİ',
  cx_solution_77:
    'Tədqiqat aparmaq üçün biz iştirakçıları cəlb edirik, müsahibələr və digər əsas tədqiqat mərhələlərini həyata keçiririk. Müştəri transkripsiya edilmiş audio-video müsahibələr, eləcə də qısa xülasələr alır.',
  cx_solution_78: '4 - ANALİTİKA',
  cx_solution_79:
    '4Service analitika şöbəsi məlumatları ümumiləşdirir, hesabat strukturunu əlaqələndirir. Daha sonra biz nəticələri müştəriyə təqdim edirik, onu həyata keçirmək üçün anlayışlar və tövsiyələr əlavə edirik.',
  cx_solution_80: '5 - WORKSHOP',
  cx_solution_81: 'İcra planı ilə bağlı müştəri ilə müzakirələrin aparılması',
  cx_solution_82: 'Ən yaxşı təcrübələrimiz haqqında ətraflı öyrənin',
  cx_solution_83:
    'Müştəri Səyahət Xəritəsi bütün kommunikasiya kanallarının tez araşdırılmasına, mühüm təmas nöqtələrində potensial çatışmazlıqların müəyyən edilməsinə və planlaşdırılmış kursdan kənara çıxa biləcək sahələrin hərtərəfli başa düşülməsinə imkan verir.',
  cx_solution_84: 'Nə vaxt istifadə etməli?',
  cx_solution_85:
    'Auditoriya qrupları haqqında real məlumatlar əsasında fərdiləşdirilmiş reklam kampaniyası qurun',
  cx_solution_86:
    'Müxtəlif hədəf auditoriya seqmentlərinin tətikləyicilərini tapın və onların ehtiyaclarını ödəyin',
  cx_solution_87:
    'Hər bir qrupu dəqiq hədəfləyin və maksimum dönüşüm əldə edin',
  cx_solution_88:
    'Müşayiət Yoxlama texnikası məqsədlərinizə çatmaq üçün optimal yolların imkanlarını qiymətləndirməyə imkan verir.',
  cx_solution_89:
    'Başlamazdan əvvəl yeni veb-sayt və ya məhsulun sınaqdan keçirilməsi',
  cx_solution_90:
    'Dəyişiklikləri həyata keçirməzdən əvvəl hazırlanmış proseslərin yoxlanılması',
  cx_solution_91:
    'Satışların azalması fonunda vebsayt və ya məhsulun qiymətləndirilməsi',
  cx_solution_92:
    'Müştəri seçimlərinə uyğunlaşdırılmış yeni məhsulun satışa çıxarılması',
  cx_solution_93:
    'Bazarda lider mövqe tutmaq üçün rəqabət aparan şirkətləri öyrənmək',
  cx_solution_94:
    'Biz müştəri yönümlü xidmət modeli yaratmaq üçün hərtərəfli 360° baxış yanaşmasını təklif edirik.',
  cx_solution_95: 'Kadr hazırlığı və inkişafı',
  cx_solution_96: 'Xidmət standartının təkmilləşdirilməsi',
  cx_solution_97: 'Müştəri axınının və satışların artması',
  cx_solution_98: 'İşçilərin KPI və motivasiya sisteminin qurulması',
  cx_solution_99: 'Uğur hekayələrimizi kəşf edin',
  cx_solution_100: 'Avtomobil',
  cx_solution_101: 'Tütün',
  cx_solution_102: 'Pərakəndə',
  cx_solution_103: 'Keyfiyyət mərhələsi',
  cx_solution_104: 'Kəmiyyət mərhələsi (seqmentləşdirmə)',
  cx_solution_105: 'Fokus qrupları',
  cx_solution_106: 'Onlayn dünya miqyasında auditoriya əhatəsi',
  cx_solution_107: 'Müsahibə zamanı texnikaların qarışığı',
  cx_solution_108: 'Yalnız açıq suallar',
  cx_solution_109: 'Respondent dəstəyi 24/7',
  cx_solution_110: 'Əhəmiyyətli detalların qorunması və yolun real mənzərəsi',
  cx_solution_111: 'Bazar araşdırması',
  cx_solution_112: 'Şirkətin və rəqiblərin diaqnostikası',
  cx_solution_113: 'Yenilənmiş xidmət modelləri və yeni iş prosesləri',
  cx_solution_114: 'Müştəri yönümlü xidmətin təşviqi',
  cx_solution_115:
    'Dəyişikliklərin həyata keçirilməsi və xidmət strategiyalarının hazırlanmasına nəzarət',
  usage_attitude_1: 'İstifadə və \n Münasibət (U&A)',
  usage_attitude_2:
    'Hədəf auditoriyasının \n brendinizi necə qavradığı, istifadə etdiyi və onunla əlaqə saxladığına dair hərtərəfli məlumat əldə edin.',
  usage_attitude_3: 'Bir mütəxəssislə danışın',
  usage_attitude_4: 'Haqqında',
  usage_attitude_5:
    '<b>İstehlakçıları araşdırın</b> üstünlükləri və təsirləri dərindən aşkar etmək vərdişləri',
  usage_attitude_6:
    "'Niyəni' kəşf edin</b> ətraflı təhlilimizlə istehlakçı seçimlərinin arxasında",
  usage_attitude_7:
    '<b>Necə başa düş</b> müştərilər strateji anlayışlar üçün məhsullarınızla qarşılıqlı əlaqə qurur',
  usage_attitude_8:
    '<b>haqqında anlayışlar əldə edin</b> istehlakçı davranışı, vərdişləri və məlumatlı qərarlar üçün üstünlükləri',
  usage_attitude_9: 'Təcrübə',
  usage_attitude_10:
    '20 ildən artıq müştəri təcrübəsi olan 4Service mütəxəssisləri biznesinizi inkişaf etdirməyə kömək edən unikal həllər yaradırlar',
  usage_attitude_11: 'Bizimlə əlaqə saxlayın',
  usage_attitude_12: 'Kimin üçün',
  usage_attitude_13: 'Brend İstifadəsi və Münasibətindən kim faydalanır?',
  usage_attitude_14: 'Müxtəlif sənaye və xidmət sektorları maraqlıdır:',
  usage_attitude_15: '<b>Tanımaq</b> müştəri tələbləri və ağrı nöqtələri',
  usage_attitude_16:
    '<b>Müəyyənləşdirilməsi</b> müştəri istehlakını artırmaq imkanları',
  usage_attitude_17:
    '<b>İnkişaf edir</b> yeni məhsul kateqoriyaları və ya marketinq strategiyaları',
  usage_attitude_18: '<b>Qazanmaq</b> rəqabət mənzərəsini dərindən başa düşmək',
  usage_attitude_19:
    'Metodologiya müştərinin istəyi əsasında fərdi olaraq seçilir',
  usage_attitude_20:
    '<b>Keyfiyyətli tədqiqat</b> anlayışları, rəyləri və ya təcrübələri qavramaq üçün qeyri-ədəd məlumatların toplanması və təhlili daxildir.',
  usage_attitude_21:
    '<b>Kəmiyyət tədqiqatı</b> metodlar sorğular, anketlər və sorğular vasitəsilə əldə edilən məlumatların obyektiv ölçülməsini və ədədi təhlilini nəzərdə tutur.',
  usage_attitude_22:
    '<b>Məlumat topladıqdan sonra</b>, ekspertlər sizə ən yaxşı tövsiyələri vermək üçün məlumatları təhlil edir və nəticə çıxarır.',
  usage_attitude_23: 'Kimə lazımdır',
  usage_attitude_24: 'Uğur Hekayəniz Buradan Başlayır',
  usage_attitude_25:
    '<b>Bir araşdırma alacaqsınız</b> strateji qərarların qəbul edilməsini gücləndirən dəyərli anlayışlarla, hərəkətlərin istehlakçı davranışının dərin dərkinə əsaslanmasını təmin etmək.',
  usage_attitude_26:
    '<b>Tədqiqat emalı üçün möhkəm zəmin yaradır</b> və brend strategiyalarını optimallaşdırmaq, onları istehlakçı seçimləri və münasibətləri ilə daha yaxından uyğunlaşdırmaq.',
  usage_attitude_27: 'Həll yolları',
  usage_attitude_28: 'Həllərimizlə çoxsaylı imkanları araşdırın',
  usage_attitude_29: 'Kateqoriya daxilində məhsula üstünlük verilməsi',
  usage_attitude_30:
    'Qida, içki, geyim, elektronika və s. daxil olmaqla müxtəlif kateqoriyalar üzrə istehlakçıların əsas üstünlüklərini müəyyən edin.',
  usage_attitude_31: 'İstehlak/istifadə davranışı',
  usage_attitude_32:
    'Xüsusi məhsul/xidmətlərin istehlak və ya istifadə tezliyini təhlil edin və onların istifadəsi ilə bağlı halları və məqamları müəyyənləşdirin.',
  usage_attitude_33: 'Satınalma davranışı',
  usage_attitude_34:
    'Müştərilərin alışları üçün istifadə etdikləri kanalları araşdırın və etibar etdikləri məlumat mənbələrini müəyyənləşdirin.',
  usage_attitude_35: 'İstehlakçı motivasiyaları və ehtiyacları',
  usage_attitude_36:
    'İstehlaka təsir edən motivləri və maneələri araşdırın. İstehlakçıların satınalma qərarlarına təsir edən amilləri qiymətləndirin. Məhsul kateqoriyasının qavranılmasını araşdırın və qiymətin necə qəbul edildiyini təhlil edin.',
  usage_attitude_37: 'Bizim yanaşmamız',
  usage_attitude_38: 'Brend istifadəsi və münasibət öyrənilməsi',
  usage_attitude_39: 'Məlumat',
  usage_attitude_40:
    'Həm kəmiyyət, həm də keyfiyyət metodlarından istifadə etməklə sorğuların aparılması',
  usage_attitude_41: 'Başlayın',
  usage_attitude_42:
    'Fərdi müraciətlər əsasında məqsədlərin müəyyən edilməsi və metodların müəyyən edilməsi',
  usage_attitude_43: 'Təhlil',
  usage_attitude_44:
    'Mütəxəssislərimiz tərəfindən istifadə olunan mürəkkəb texnikalar',
  usage_attitude_45:
    'Biz sizin xüsusi tələblərinizə əsaslanan müxtəlif alətlər dəstindən istifadə edirik',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Kompüter Dəstəkli Telefon Müsahibəsi sorğular aparmaq və telefonla qiymətli məlumat toplamaq üçün istifadə etdiyimiz üsuldur, prosesi asanlaşdırmaq və bazar araşdırması səylərimizdə dəqiqliyi təmin etmək üçün xüsusi proqram təminatından istifadə edir.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Kompüter Dəstəkli Veb Müsahibə bizim arsenalımızda mühüm alətdir və bizə xüsusi proqram təminatının köməyi ilə onlayn platformalar vasitəsilə sorğular keçirməyə və məlumat toplamağa imkan verir, müştərilərimizin bazar araşdırması ehtiyacları üçün səmərəli və dəqiq məlumatların toplanmasına təminat verir.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Kompüter Dəstəkli Şəxsi Müsahibə məlumat toplama proseslərini optimallaşdırmaq, müsahibələri daha hamar etmək və biznes ehtiyaclarınız üçün dəqiq başa düşülən nəticələri təmin etmək üçün istifadə etdiyimiz üsuldur.',
  usage_attitude_52: 'Fokus qrupları',
  usage_attitude_53:
    'Fokus-qruplar, xüsusi mövzuları və ya məhsulları dərindən müzakirə etmək üçün müxtəlif iştirakçılar qrupunu bir araya gətirərək, keyfiyyət anlayışlarını toplamağa, münasibətləri, üstünlükləri və qavrayışları üzə çıxarmağa, ideya və konsepsiyaları araşdırmağa imkan verən dinamik tədqiqat sessiyalarıdır. müştərilərin qərar qəbul etmə və strategiya inkişaf prosesləri.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Üz-üzə yanaşma bizim yanaşmamızın ayrılmaz hissəsidir, müştərilər və müştərilərlə birbaşa qarşılıqlı əlaqələri və fərdiləşdirilmiş nişanları asanlaşdırır və bununla da davamlı əlaqələr qurmaq və biznes uğurunun fokus qruplarını idarə etmək üçün vacib olan inam, anlayış və effektiv ünsiyyəti gücləndirir.',
  usage_attitude_56: 'Etnoqrafik tədqiqat',
  usage_attitude_57:
    'İnsanları və mədəniyyətləri onların təbii mühitlərində immersiya və müşahidə yolu ilə öyrənmək, çox vaxt davranışlar, inanclar və sosial dinamika haqqında dərin anlayışlara gətirib çıxarır.',
  thank_you_page:
    'Vaxt ayırdığınız üçün təşəkkür edirik! Menecerimiz tezliklə sizinlə əlaqə saxlayacaq.',
  back_main: 'Əsas səhifəyə qayıt',
  opportunities_1: 'İşlərimiz haqqında daha çox məlumat əldə edin',
  opportunities_2: 'Kəşf etmək',
  opportunities_3: 'özünüz üçün',
  opportunities_4: 'imkanlar',
  opportunities_5: '4Service tədqiqat holdinqi ilə birlikdə beynəlxalq biznes',
  opportunities_6:
    'Biznesinizi hansı <span>məhsullarla</span> gücləndirə bilərik?',
  opportunities_7: 'CX',
  opportunities_8: 'Biznes üçün hərtərəfli fərdi həllər',
  opportunities_9: 'Bütün növ Mystery Shopping',
  opportunities_10:
    'Biz sirr işçisi, sirli zəng edən, lüks sirr alıcısı, avtomobil sirri alıcısı və digər növləri həyata keçiririk',
  opportunities_11: 'Biznes üçün fərdi İT həllər',
  opportunities_12:
    'AI ilə İT həlləri, rəy analitikası və zəng transkripsiyası üçün platformalar',
  opportunities_13: 'Bazar araşdırması',
  opportunities_14:
    'İstənilən biznes sorğuları üçün (Brend Sağlamlığının İzlənməsi, Seqmentasiya, NPS, eNPS, CJM, Məhsul və reklam testi və s.)',
  opportunities_15: 'Auditlər',
  opportunities_16:
    'Biz müxtəlif xidmət auditləri, eləcə də qiymət monitorinqi (oflayn, xüsusi nişlər üçün və s.) həyata keçiririk',
  opportunities_17: 'Xidmət Dizaynı',
  opportunities_18:
    '4Service - DesignThinkers Academy-nin sertifikatlı tərəfdaşları, komandalar üçün korporativ təlimlər təmin edir və mütəmadi olaraq biznes üçün seminarlar keçirir',
  opportunities_19:
    'Biz təcrübəmizi bölüşürük, lakin biznes prosesinizə <span>hörmət edirik və onu pozmuruq</span>.',
  opportunities_20:
    'Biz biznesin inkişaf yolunda üzləşdiyi çətinlikləri bilirik və beynəlxalq uğurlu təcrübə sayəsində bu maneələri necə aradan qaldıracağımızı bilirik. Siz təkcə təcrübə deyil, həm də resurslar əldə edirsiniz.',
  opportunities_21: 'Sizi əməkdaşlığa dəvət edirik!',
  opportunities_22:
    'Biznesiniz üçün <span>tərəfdaşlıq proqramı</span> təklif edirik',
  opportunities_23:
    'Beynəlxalq səviyyədə tanınan 4Service brendi altında siz yeni müştərilərin cəlb edilməsinə müsbət təsir göstərə bilərsiniz.',
  opportunities_24:
    'Tərəfdaşlarımız təlim, marketinq materialları və əməliyyat yardımı da daxil olmaqla hərtərəfli dəstək alırlar.',
  opportunities_25:
    'Bizimlə əməkdaşlıq cari iş prosesinizi pozmadan planlarınızı həyata keçirəcək.',
  opportunities_26: 'Bizim <span>ofislər</span>',
  opportunities_27: 'Müştərilərimiz',
  opportunities_28: 'CX-də 20 ildən çox təcrübəmiz var',
  opportunities_29: 'Əhatə olunan ölkələr',
  opportunities_30: 'min',
  opportunities_31: 'Unikal layihələr',
  opportunities_32: 'milyon',
  opportunities_33: 'Gizli alıcıların ziyarətləri',
  opportunities_34: 'TOP 10 Avropa CX agentliklərinə daxildir',
  opportunities_35: '4Service ilə imkanlarınız',
  opportunities_36: 'Mənfəət artımı',
  opportunities_37: 'Beynəlxalq sifarişlərə giriş əldə edəcəksiniz',
  opportunities_38: 'Unikal CX yanaşması',
  opportunities_39: '20 illik təcrübəyə malik mütəxəssislərin dəstəyi',
  opportunities_40: 'Dəstək',
  opportunities_41: 'Komandamız nəticə əldə etmək üçün sizə dəstək olacaq',
  opportunities_42: 'Satış',
  opportunities_43: 'Biz sizə satış sistemi qurmağa kömək edəcəyik',
  opportunities_44: 'Proqram təminatına giriş',
  opportunities_45:
    'Siz bütün sertifikatlaşdırılmış İT alətlərinə giriş əldə edəcəksiniz',
  opportunities_46: 'Təlim',
  opportunities_47:
    'Biz təkcə sizin personalınıza təlim keçməyəcək, həm də məsləhət xidmətləri göstərməyə davam edəcəyik',
  opportunities_48: 'Kim ortaqlığa uyğun gəlir?',
  opportunities_49: 'Biznesiniz marketinq araşdırması ilə bağlıdır?',
  opportunities_50: 'Yoxsa özünüzü yeni bir sahədə sınamağı planlaşdırırsınız',
  opportunities_51: 'planlaşdırırsınız:',
  opportunities_52: 'Yenilikləri həyata keçirin',
  opportunities_53: 'Beynəlxalq brendlərlə işləmək',
  opportunities_54: 'Məhsul portfelinizi genişləndirin',
  opportunities_55: 'Bazar mövqeyinizi gücləndirin?',
  opportunities_56: 'İşçilərinizi öyrədin',
  opportunities_57: 'Biznesinizi miqyaslandırın',
  opportunities_58: 'Resurslarımız = Sizin resurslarınız',
  opportunities_59:
    'Geniş təcrübəmizi nəzərə alaraq, əməkdaşlığın ilk mərhələsində bunu sizinlə bölüşməyə hazırıq',
  opportunities_60: 'Məlumatların təhlili:',
  opportunities_61: 'Beynəlxalq analitik qrupu',
  opportunities_62: 'Məlumatların emalı üsulları - SPSS, Power BI',
  opportunities_63:
    'İnnovativ metodologiyalar və geniş spektrli metodologiyalar',
  opportunities_64: 'İstənilən formatda hesabat',
  opportunities_65: 'Proqram təminatı:',
  opportunities_66: 'Öz fərdi İT inkişafları',
  opportunities_67: 'CAPI üçün DigSee Sure',
  opportunities_68: 'CAWI üçün SurveyMonkey',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (Çağrı Mərkəzi)',
  opportunities_71: 'Marketinq dəstəyi:',
  opportunities_72: 'PR dəstəyi',
  opportunities_73: 'Təbliğat və məzmunun yaradılmasında dəstək',
  opportunities_74: 'Qurğunun yaradılması proseslərinin qurulması',
  opportunities_75: 'İT dəstəyi:',
  opportunities_76:
    'Proqram təminatımızın quraşdırılması, konfiqurasiyası və istifadəsi ilə bağlı texniki dəstək.',
  opportunities_77: 'Uzaqdan yardım',
  opportunities_78: 'Təhlükəsizlik nəzarəti',
  opportunities_79: 'Əməliyyat dəstəyi:',
  opportunities_80: 'Sahə proseslərinin qurulmasında dəstək',
  opportunities_81: 'Fırıldaqçılıq əleyhinə sistem',
  opportunities_82: 'İstənilən mürəkkəblikdə işə qəbul',
  opportunities_83:
    'Layihə və sahə şöbələri komandasının operativ idarə edilməsi',
  opportunities_84: 'İşə qəbulun keyfiyyətinə nəzarət',
  opportunities_85: 'UpWork',
  opportunities_86: 'Hansı bonuslar alacaqsınız?',
  opportunities_87: 'Müştəri bazasında artım',
  opportunities_88: 'Bazar imicinin gücləndirilməsi',
  opportunities_89: 'Müştəri itkisi risklərinin şaxələndirilməsi',
  opportunities_90: 'Texnoloji proseslərin optimallaşdırılması',
  opportunities_91: 'Beynəlxalq təcrübə',
  opportunities_92: 'Cari müştərilərə daha çox satış',
  main_67:
    'Rəqabətli qiymət təhlili təklifləri optimallaşdırır, mənfəəti artırır və əsas xüsusiyyətləri müəyyən etmək üçün istehlakçının ödəməyə hazır olması məlumatlarından istifadə etməklə bazar payını artırır',
  main_68:
    'Pərakəndə satış yerlərinizin brend standartlarına uyğun olmasını təmin edir, fiziki atributları və onların müştəri qavrayışına təsirini qiymətləndirir',
  main_69: 'CX həlli',
  main_70:
    'Unikal 360 dərəcə CX həllimizi kəşf edin: biz biznesinizi idarə etmək üçün məqsədlərinizə əsasən alətlər və xidmətləri fərdiləşdiririk',
  main_71: 'Nitq analitikası',
  main_72:
    'Qabaqcıl nitq analitikası alətimiz danışıq dilini mətnə ​​köçürür, bizneslərə müştəri təcrübəsini artırmaq, performansa nəzarət etmək, uyğunluğu təmin etmək və böyüməyə təkan vermək üçün əvəzolunmaz fikirlər təqdim edir',
  parthership: 'Tərəfdaşlıq',
  banner_1: 'SX konsaltinqi və xidmətləri biznesinizin 360° görünüşü',
  banner_2: 'Ən son araşdırmalardan xəbərdar olun!',
  banner_3:
    'Mən Məxfilik Siyasətinə uyğun olaraq şəxsi məlumatların emalına razılığımı verirəm',
  banner_4: '*Bazar seçin',
  banner_5: 'Abunə olduğunuz üçün təşəkkür edirik!',
  banner_6: 'Biz yalnız ən maraqlı məzmunu sizinlə paylaşacağıq!',
  banner_7: 'təqdim',
  banner_8: 'Ukrainian',
  banner_9: 'Kazakhstan',
  brand_awareness_1: 'Brend məlumatlılığı',
  brand_awareness_2: 'and Usage',
  brand_awareness_3:
    'Biznes uğurunuz üçün marka məlumatlılığının və istifadənizin potensialını açın',
  brand_awareness_4: 'Bir mütəxəssislə danışın',
  brand_awareness_5: 'Haqqında',
  brand_awareness_6:
    '<b>dərəcəsini qiymətləndirin</b> markanızın tanınması və tanışlığı',
  brand_awareness_7:
    '<b>Brend səviyyəsini müəyyənləşdirin </b> hədəf bazarınızda məlumatlılıq',
  brand_awareness_8:
    '<b>Keep a close watch</b> saxlama və sadiqlik dərəcələri haqqında',
  brand_awareness_9:
    '<b>Optimal strategiyanı seçin</b> zəruri göstəriciləri təkmilləşdirmək',
  brand_awareness_10: 'Həllərimizlə çoxsaylı imkanları araşdırın',
  brand_awareness_11: 'Brend məlumatlılığı',
  brand_awareness_12: 'Brend İstifadəsi',
  brand_awareness_13:
    '<b>Kəşf etmək</b> istehlak seqmentləri arasında markanızın tanınması',
  brand_awareness_14:
    '<b>Monitor</b> auditoriyanız arasında məlumatlılıq səviyyəsi',
  brand_awareness_15:
    '<b>Öyrənmək</b> istehlakçıların alış-veriş etmək haqqında spontan düşüncələri',
  brand_awareness_16:
    '<b>Anla</b> marka məlumatlılığınıza töhfə verən əsas mənbələr',
  brand_awareness_17:
    '<b>düşünün</b> markanızın məhsul və ya xidmətləri ilə tanış olan istehlakçıların sayı',
  brand_awareness_18:
    '<b>Müəyyən etmək</b> ilkin olaraq sınadıqdan sonra markanızdan yapışmış istehlakçılar',
  brand_awareness_19: '<b>Kilidi aç</b> markanızın tövsiyə olunma ehtimalı',
  brand_awareness_20:
    '<b>Açmaq</b> markanızı gələcək istifadə üçün hesab edən istehlakçıların faizi',
  brand_awareness_21: 'Who benefits from Brand Awareness and Usage?',
  brand_awareness_22: 'Yeni məhsul buraxan müəssisələr',
  brand_awareness_23: 'Qurulmuş brendi canlandıran müəssisələr',
  brand_awareness_24:
    'Brendinin istehlakçılar tərəfindən necə tanındığını və istifadə edildiyini anlamaq və inkişaf etdirmək istəyən hər hansı bir iş',
  brand_awareness_25:
    'Metodologiyalarımız brend məlumatlılığınızı və istifadənizi optimallaşdırmaq üçün dəyərli məlumatlar təqdim edir',
  brand_awareness_26:
    'Metodologiya müştərinin istəyi əsasında fərdi olaraq seçilir',
  brand_awareness_27: 'Brenddən istifadə hunisi metodologiyası',
  brand_awareness_28:
    'Sınaq dərəcəsi brenddən xəbərdar olan və onun məhsul və ya xidmətlərini sınamış auditoriyanın nisbətini göstərir.',
  brand_awareness_29:
    'Təkrarlanma dərəcəsi brendlə tanış olan və onun məhsul və ya xidmətlərini sınaqdan keçirmiş auditoriyanın son 12 ayda alış-veriş etməyə və ya təkliflərdən istifadə etməyə davam edən faizini göstərir.',
  brand_awareness_30:
    'Saxlama dərəcəsi, son 6 ayda bu təkliflərlə məşğul olanların faizini göstərməklə, brendin məhsul və ya xidmətlərindən ardıcıl istifadə edən şəxslərin nisbətini ortaya qoyur.',
  brand_awareness_31:
    'Saxlama dərəcəsi, son 6 ayda brendin məhsullarından/xidmətlərindən istifadə edən müştərilərin faizini göstərir və zamanla brendə sadiqlik nümayiş etdirməyə davam edir.',
  brand_awareness_32: 'Brend məlumatlılığı metodologiyası',
  brand_awareness_33:
    'Top of mind (TOM) respondent tərəfindən kortəbii qeyd olunan ilk brendi təmsil edir.',
  brand_awareness_34:
    'Ümumi spontan şüur ​​TOM daxil olmaqla, spontan qeydlərin cəmini təmsil edir.',
  brand_awareness_35:
    'Yardımlı Awareness istənildikdə brendinizin istifadəçilər tərəfindən nə qədər yaxşı tanındığını göstərir.',
  brand_awareness_36: 'Brend atributunun performans xəritəsi',
  brand_awareness_37:
    'Bu alət müxtəlif marka atributlarının əhəmiyyətini qiymətləndirir və vizual olaraq təmsil edir, hər birinin brendinizin ümumi performansına və istehlakçı qavrayışına necə töhfə verdiyinə dair anlayışlar təqdim edir.',
  brand_awareness_38:
    'Hərtərəfli təhlilimizlə brendinizin təsirini artırmaq üçün açarları kəşf edin.',
  brand_awareness_39: 'Brendlərin qavrayış xəritəsi',
  brand_awareness_40:
    'Bu alət rəqabətli bazar mənzərəsində istehlakçı qavrayışlarının vizual təqdimatını təklif edir. Bu yanaşma əsas bazar atributlarını vurğulamaqla brendlər arasında fərqləri aydın şəkildə müəyyən etmək üçün çox vacibdir.',
  brand_awareness_41:
    'O, brendlər və bu müəyyənedici xüsusiyyətlər arasındakı münasibətlərin müşahidəsini və şərhini asanlaşdırır.',
  brand_awareness_42:
    'Konkret brendin müəyyən atributlara yaxınlığı onun bu xüsusiyyətlərlə güclü əlaqəsini bildirir. Eynilə, iki marka arasındakı yaxınlıq onların oxşarlıqlarını, paylaşılan şəkilləri və nəticədə eyni bazar seqmentində mənsubiyyətini göstərir.',
  consumer_journey_map_1: 'İstehlakçı səyahət xəritəsi',
  consumer_journey_map_2:
    'İstehlakçı Səyahət Xəritəmizlə biznesinizdəki maneələri kəşf edin və onları dəf edin',
  consumer_journey_map_3: 'TRUSTED BY',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'İstehlakçılarınız və markanız arasında qarşılıqlı əlaqəni optimallaşdırmaq üçün CJM potensialını açın',
  consumer_journey_map_6:
    'İstehlakçının məhsulu almazdan əvvəl hansı addımları atacağını müəyyənləşdirin',
  consumer_journey_map_7:
    'Xəyali deyil, real müştərilərinizdən fikirlər əldə edin',
  consumer_journey_map_8:
    'Müştərilərinizin təcrübələri haqqında hərtərəfli anlayış əldə edin',
  consumer_journey_map_9:
    'Buraxılmış fürsətləri müəyyənləşdirin və rəqiblərinizlə müqayisə edin',
  consumer_journey_map_10: 'CJM yanaşmamızı kəşf edin',
  consumer_journey_map_11:
    'Biz müxtəlif kanallar vasitəsilə və müəyyən müddət ərzində məhsul, xidmət, şirkət və ya marka ilə istehlakçı qarşılıqlı əlaqə yolunu yaradırıq.',
  consumer_journey_map_12:
    'Biz istehlakçılarınızın satın almadan əvvəl keçdiyi mərhələləri, hər mərhələdə müştərinin gözləntilərini, emosiyalarını və motivasiyalarını təsvir edirik.',
  consumer_journey_map_13: 'Bizimlə faydalanın',
  consumer_journey_map_14:
    'Biz qlobal biznes uğurunun incəliklərini dərk edən təcrübəli peşəkarlardan ibarət komanda təqdim edirik',
  consumer_journey_map_15:
    'Biz gözləntilərinizi üstələyən nəticələr təqdim etməyə çalışırıq',
  consumer_journey_map_16: 'Böyümənizə töhfə verən kompleks həllər kəşf edirik',
  consumer_journey_map_17:
    'Biz fərdiləşdirilmiş CJM həlləri təqdim etməkdə ixtisaslaşmışıq',
  consumer_journey_map_18: 'Konsultasiya sifariş edin',
  consumer_journey_map_19: 'Məlumata əsaslanan qərarlar',
  consumer_journey_map_20:
    'Qərarlarınızı fərziyyələrə deyil, real məlumatlara əsaslandırın',
  consumer_journey_map_21: 'CJM-inizi optimallaşdırın',
  consumer_journey_map_22:
    'Səyahətlərinin hər mərhələsində müştərilərin ehtiyaclarını və gözləntilərini nəzərə alaraq onlarla daha güclü əlaqələr qurun',
  consumer_journey_map_23: 'Yol göstərin',
  consumer_journey_map_24:
    'Özünüzü rəqiblərinizlə müqayisə edin və bazarda irəlilənin',
  consumer_journey_map_25: 'Tövsiyələr',
  consumer_journey_map_26:
    'Uğurun kilidini açmaq, müştəri loyallığını artırmaq, satışları artırmaq və biznes potensialınızı artırmaq üçün CJM tövsiyələrimizi həyata keçirin',
  consumer_journey_map_27:
    'İstehlakçı Səyahət Xəritəmizlə real istehlakçı səyahətini kəşf edin',
  consumer_journey_map_28:
    '20+ illik təcrübəyə malik ekspertlər hərtərəfli araşdırmalar aparırlar',
  consumer_journey_map_29:
    'Keyfiyyətli tədqiqat anlayışları, rəyləri və ya təcrübələri qavramaq üçün qeyri-rəsmi məlumatların toplanması və təhlilini əhatə edir.',
  consumer_journey_map_30:
    'Kəmiyyət tədqiqat metodları sorğular, anketlər və sorğular vasitəsilə əldə edilən məlumatların obyektiv ölçülməsini və ədədi təhlilini nəzərdə tutur.',
  consumer_journey_map_31:
    'Məlumat topladıqdan sonra ekspertlər məlumatları təhlil edir və sizə ən yaxşı tövsiyələri təqdim etmək üçün nəticələr çıxarırlar.',
  boarding_1: 'Müştəri səyahət xəritəsi',
  boarding_2:
    'Müştəri Səyahət Xəritəçəkmə (CJM) hədəf auditoriyanı müəyyən etmək, onların ehtiyaclarını və ətraf mühiti anlamaq və sonra cəlbedici təcrübə təqdim etmək üçün plan yaratmaqdır',
  boarding_3: 'Xalis Tanıtım Hesabı',
  boarding_4:
    'Net Promoter Score (NPS) bir suala əsaslanan müştəri təcrübəsi ölçülərinin qızıl standartıdır: Təşkilat/Məhsul/Xidməti nə dərəcədə tövsiyə edərdiniz?',
  boarding_5: 'Brend Sağlamlığının İzlənməsi',
  boarding_6:
    'Brend Sağlamlığının İzlənməsi (BHT) araşdırması markanızın məlumatlılıq və istifadə, markanın yerləşdirilməsi və brend performansı üzrə necə performans göstərdiyini ölçə bilər',
  boarding_7: 'İnsan Resursları Araşdırması',
  boarding_8:
    'İnsan Resursları Tədqiqatı HR təcrübələrini və performansını qiymətləndirmək üçün istifadə olunur və mövcud inkişaf və idarəetmə ilə bağlı ətraflı təhlil təklif edir',
  boarding_9: 'UX testi',
  boarding_10:
    'Usability (UX) testi real insanların vebsayt, proqram və ya digər məhsulla necə qarşılıqlı əlaqədə olması və onların davranışlarını və ona reaksiyalarını müşahidə etməsidir',
  boarding_11: 'Müştəri məmnuniyyəti indeksi',
  boarding_12:
    'Müştəri məmnuniyyəti şirkətiniz tərəfindən təmin edilən məhsul və ya xidmətin alınması ilə bağlı müştərinin gözləntilərinin nə dərəcədə təmin olunduğunu ölçür',
  boarding_13: 'Reklam testi',
  boarding_14:
    'Bir reklam kampaniyası böyük bir investisiyadır. Reklamlarınızın güclü olduğuna və maksimum ROI təmin etdiyinə əmin olun',
  boarding_15: 'Sahə auditi/Pərakəndə audit',
  boarding_16:
    'Mağazanız və ya brendiniz haqqında ətraflı məlumat toplayın, böyümə maneələrini təhlil edin və onları aradan qaldırmaq üçün effektiv strategiyalar yaradın',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Performans İdarəetmə Proqramı. Satışları artırmaq üçün oyunlaşdırılmış proqram',
  boarding_19: 'Voicer',
  boarding_20:
    'Əlaqə İdarəetmə Sistemi. Müştərinizin səsi bir sistemdə. Müştəri Əlaqə İdarəetmə Sistemi',
  boarding_21: 'Keyfiyyətli tədqiqat',
  boarding_22:
    'Keyfiyyətli tədqiqat anlayışları, rəyləri və ya təcrübələri başa düşmək üçün rəqəmsal olmayan məlumatların toplanması və təhlilidir',
  boarding_23: 'Kəmiyyət tədqiqatı',
  boarding_24:
    'Kəmiyyət tədqiqat metodları sorğular, anketlər və sorğular vasitəsilə toplanmış məlumatların obyektiv ölçülməsi və ədədi təhlilidir',
  boarding_25: 'Sirli Alış-veriş',
  boarding_26:
    'Gizli alış-veriş, əvvəlcədən müəyyən edilmiş bir ssenari zamanı müştəri ilə şirkət və ya təşkilat arasındakı qarşılıqlı əlaqəni ölçmək və izləmək üçün bazar araşdırması üsuludur',
  boarding_27: 'Wantent',
  boarding_28:
    'Tamaşaçılardan fikirlər təqdim edən süni intellektlə işləyən məzmun kəşfiyyatı platforması',
  boarding_29: 'Qiymət monitorinqi ',
  boarding_30:
    'Qiymət monitorinqi rəqiblərin qiymətlərinin toplanması, işlənməsi və təhlili prosesidir',
  boarding_31: 'Xidmət dizaynı',
  boarding_32:
    'Xidmət dizaynı məhsul və ya xidmətdən istifadə edəcək insanların ehtiyaclarını və istəklərini onlarla vaxt keçirərək başa düşməkdir',
  boarding_33: 'Avtomobil Sənayesi Qiymət Təhlili',
  boarding_34:
    'Rəqabətli qiymət təhlili təklifləri optimallaşdırır, mənfəəti artırır və əsas xüsusiyyətləri müəyyən etmək üçün istehlakçının ödəməyə hazır olması məlumatlarından istifadə etməklə bazar payını artırır',
  boarding_35: 'Obyekt Yoxlama Auditi',
  boarding_36:
    'Pərakəndə satış yerlərinizin brend standartlarına uyğun olmasını təmin edir, fiziki atributları və onların müştəri qavrayışına təsirini qiymətləndirir',
  boarding_37: 'CX həlli',
  boarding_38:
    'Unikal 360 dərəcə CX həllimizi kəşf edin: biz biznesinizi idarə etmək üçün məqsədlərinizə əsasən alətlər və xidmətləri fərdiləşdiririk',
  boarding_39: 'Nitq analitikası',
  boarding_40:
    'Qabaqcıl nitq analitikası alətimiz danışıq dilini mətnə ​​köçürür, bizneslərə müştəri təcrübəsini artırmaq, performansa nəzarət etmək, uyğunluğu təmin etmək və böyüməyə təkan vermək üçün əvəzolunmaz fikirlər təqdim edir',
  boarding_41: 'İstifadə və münasibət',
  boarding_42:
    'İstifadə və Münasibət praktiki istifadə nümunələrini və psixoloji münasibətləri təhlil edərək, uyğunlaşdırılmış marketinq və məhsul strategiyalarını təmin etməklə, izləyicilərin brendinizlə necə əlaqə saxladığını anlamaq üçün yanaşmamızdır',
  boarding_43: 'Brend Məlumatı və İstifadəsi',
  boarding_44:
    'Brend Şüurluluğu və İstifadəsi, markanın görünməsini və əlaqəsini artırmaq üçün hədəf bazarınızda tanınma, tanışlıq, qarşılıqlı əlaqə tezliyi və saxlanmanın qiymətləndirilməsini əhatə edir',
  boarding_45: 'Brend Kimliyi və Mövqeləşdirmə',
  boarding_46:
    'Brend identifikasiyası və yerləşdirmə istehlakçı qavrayışlarını, müxtəlif auditoriya baxışlarını və markanızın necə qəbul edildiyinə dair fikirləri üzə çıxaran hərtərəfli təhlildir',
  boarding_47: 'Müştəri Profili və Seqmentasiya',
  boarding_48:
    'Müştəri profilinin yaradılması və seqmentasiyası hədəf bazarınızı ümumi xüsusiyyətlər əsasında fərqli qruplara bölməyi əhatə edir və xüsusi auditoriya seqmentləri ilə rezonans yaradan fərdiləşdirilmiş strategiyaların yaradılmasına imkan verir',
  boarding_49: 'İstehlakçı səyahət xəritəsi',
  boarding_50:
    'İstehlakçı Səyahət Xəritəsi istehlakçının məhsulunuz və ya markanızla qarşılıqlı əlaqəsi boyunca yaşadığı mərhələlərin, təmas nöqtələrinin, emosiyaların və motivasiyaların vizual təsviridir',
  boarding_51:
    'Biznesiniz üçün təsirli nəticələr əldə etmək və həyata keçirilə bilən fikirlər təqdim etmək öhdəliyimizi dəstəkləyən innovativ texnikalar haqqında məlumat əldə edin',
  boarding_52:
    'Sizə məlumatlı qərarlar qəbul etməyə və irəlidə qalmağa imkan verən, sənayenizə uyğunlaşdırılmış məlumatlara əsaslanan zəka zənginliyi ilə məhsullar dəstimizi araşdırın',
  boarding_53:
    'Hərtərəfli bazar araşdırması həllərimizlə bazar tendensiyalarına, istehlakçı davranışlarına və rəqabətli mənzərələrə dərindən baxın',
  boarding_54: 'Ad Hoc Araşdırma',
  boarding_55:
    'Ad Hoc Tədqiqat mövcud metodologiyaların qarışığından və ya uyğunlaşdırılmış həll yolu yaratmaq üçün yeni yanaşmadan istifadə edərək, müəyyən bir problemi həll etmək üçün nəzərdə tutulmuş fərdi yanaşmadır.',
  boarding_56: 'Sosioloji Tədqiqatlar',
  boarding_57:
    'Sosioloji Tədqiqatlar yenilikçi strategiyalar yaratmaq və hədəf auditoriya ilə mənalı əlaqələr yaratmaq üçün dəyərli anlayışlar təqdim edərək, insan davranışı və sosial qarşılıqlı əlaqə nümunələrini ortaya qoyur.',
  not_found_1: 'Vay! Həmin səhifəni tapa bilmirik.',
  not_found_2: 'Niyə məhsullarımızı nəzərdən keçirməyək?',
  not_found_3: 'Kitab məsləhəti',
  customer_profiling_segmentation_1:
    'Müştəri profilinin yaradılması və seqmentasiyası',
  customer_profiling_segmentation_2:
    'Strategiyanızı daha effektiv etmək üçün hədəf auditoriyanızı müəyyənləşdirməyə kömək edəcəyik',
  customer_profiling_segmentation_3:
    'Mesajlarınızın həmişə hədəf istehlakçı qruplarınıza effektiv şəkildə çatmasını təmin etmək üçün bazar seqmentasiyası gücündən istifadə edin',
  customer_profiling_segmentation_4: 'İstehlakçıları daha dərindən anlayın',
  customer_profiling_segmentation_5:
    'Məhsulunuz üçün ən yaxşı hədəf auditoriyasını tapın',
  customer_profiling_segmentation_6:
    'Cəlb etmək üçün yeni seqmentləri araşdırın',
  customer_profiling_segmentation_7: 'Satınalma üçün maneələri kəşf edin',
  customer_profiling_segmentation_8:
    'Biz uyğunlaşdırılmış seqmentləşdirmə həlləri təqdim etməkdə ixtisaslaşmışıq',
  customer_profiling_segmentation_9: 'Unikal yanaşma',
  customer_profiling_segmentation_10:
    'Ehtiyaclarınız üçün xüsusi olaraq hazırlanmış unikal, fərdiləşdirilmiş seqmentləşdirmə həllini təcrübədən keçirin',
  customer_profiling_segmentation_11: 'Hədəf İnsanlar',
  customer_profiling_segmentation_12:
    'Dərin bazar təhlili və hərtərəfli araşdırma əsasında hazırlanmış Hədəf Şəxsiyyətləri',
  customer_profiling_segmentation_13: 'Yeni seqmentlər',
  customer_profiling_segmentation_14:
    'Ən dəyərli auditoriya seqmentlərinizi müəyyənləşdirin və uyğunlaşdırılmış hədəfləmənin üstünlüklərini açın',
  customer_profiling_segmentation_15: 'Tövsiyələr',
  customer_profiling_segmentation_16:
    'Tövsiyələrimizi həyata keçirin və uğur qazanın, müştəri sədaqətini artırın, satışları artırın və biznes potensialınızı maksimum dərəcədə artırın',
  customer_profiling_segmentation_17:
    '20+ illik təcrübəyə malik ekspertlər hərtərəfli araşdırmalar aparırlar',
  customer_profiling_segmentation_18:
    'Keyfiyyətli tədqiqat anlayışları, rəyləri və ya təcrübələri qavramaq üçün qeyri-rəsmi məlumatların toplanması və təhlilini əhatə edir.',
  customer_profiling_segmentation_19:
    'Kəmiyyət tədqiqat metodları sorğular, anketlər və sorğular vasitəsilə əldə edilən məlumatların obyektiv ölçülməsini və ədədi təhlilini nəzərdə tutur.',
  customer_profiling_segmentation_20:
    'Məlumat topladıqdan sonra ekspertlər məlumatları təhlil edir və sizə ən yaxşı tövsiyələri təqdim etmək üçün nəticələr çıxarırlar.',
  customer_profiling_segmentation_21:
    'Biz təkcə ölçmə aparmırıq, həm də sizə aydın addım-addım tədbirlərlə fəaliyyət planı təqdim edirik',
  customer_profiling_segmentation_22: 'Bizim metodologiyamız',
  customer_profiling_segmentation_23: 'Jaccard İndeksi',
  customer_profiling_segmentation_24:
    'Respondentlərə birbaşa sual verildikdə, onların şirkət üstünlükləri ilə bağlı cavabları onların faktiki davranışları ilə üst-üstə düşməyə bilər. Birbaşa sorğu-sual etmək əvəzinə, şirkət atributları və istehlakçı seçimi arasındakı əlaqəni anlamaq üçün statistik Jaccard analizindən istifadə edirik.',
  customer_profiling_segmentation_25: 'Drivers',
  customer_profiling_segmentation_26:
    'Biz sürücüləri (müəyyən nəticəyə təsir edən amillər) müəyyən edir və əhəmiyyətinə görə onları əsas, orta və əhəmiyyətsiz sürücülərə təsnif edirik.',
  customer_profiling_segmentation_27: 'Seqmentasiya',
  customer_profiling_segmentation_28:
    'Müştəri seçimlərinin aydın xüsusiyyətləri ilə seqmentasiya əldə edirsiniz.',
  customer_profiling_segmentation_29: 'Seqmentləşdirməyə yanaşmamız',
  customer_profiling_segmentation_30: 'Demoqrafik',
  customer_profiling_segmentation_31: 'yaş',
  customer_profiling_segmentation_32: 'cins',
  customer_profiling_segmentation_33: 'gəlir',
  customer_profiling_segmentation_34:
    'O, məhsulları, xidmətləri və marketinqi xüsusi qruplara uyğunlaşdırmağa kömək edir, uyğunluğu və effektivliyi artırır',
  customer_profiling_segmentation_35: 'Psixoqrafik',
  customer_profiling_segmentation_36: 'həyat tərzi',
  customer_profiling_segmentation_37: 'dəyərlər',
  customer_profiling_segmentation_38: 'davranışlar',
  customer_profiling_segmentation_39:
    'Bu, fərdi marketinq, müştərilərlə daha dərin əlaqələr və şirkət üçün artan loyallıq və satış imkanı verir',
  customer_profiling_segmentation_40: 'Davranış',
  customer_profiling_segmentation_41: 'tədbirlər',
  customer_profiling_segmentation_42: 'motivasiya',
  customer_profiling_segmentation_43: 'alış tezliyi',
  customer_profiling_segmentation_44:
    'Bu, məqsədyönlü marketinq, inkişaf etmiş müştəri məmnuniyyəti, artan saxlama və şirkət üçün yaxşılaşdırılmış gəlir əldə etməyə imkan verir',
  customer_profiling_segmentation_45: 'Coğrafi',
  customer_profiling_segmentation_46: 'location',
  customer_profiling_segmentation_47:
    ' Bu, məqsədyönlü marketinq, uyğunlaşdırılmış məhsullar, səmərəli paylama və şirkət üçün bazara daha çox nüfuz etməyə imkan verir.',
  brands_identity_1: 'Brend kimliyi və yerləşdirmə',
  brands_identity_2:
    'Dərin təhlilimizlə brendinizi dəyişdirin, istehlakçıların qavrayışlarına dair anlayışları üzə çıxarın və uğur üçün strategiyanızı dəqiqləşdirin',
  brands_identity_3: 'Bir mütəxəssislə danışın',
  brands_identity_4: '<b>Hərtərəfli kəşf edin</b> marka şəxsiyyətinin təhlili',
  brands_identity_5:
    '<b>Anlayışları araşdırın</b> İstehlakçı qavrayışları ilə bağlı tədqiq edilmişdir',
  brands_identity_6: '<b>Müxtəlif anlayın</b> hədəf auditoriya baxışları',
  brands_identity_7:
    '<b>haqqında anlayışlar əldə edin</b> istehlakçı davranışı, vərdişləri və məlumatlı qərarlar üçün üstünlükləri',
  brands_identity_8:
    '20 ildən artıq müştəri təcrübəsi olan 4Service mütəxəssisləri biznesinizi inkişaf etdirməyə kömək edən unikal həllər yaradırlar',
  brands_identity_9:
    '<b>Bütün əsas məlumatlarla təchiz edilmiş tədqiqat paketinə giriş əldə edəcəksiniz.</b> O, güc sahələrini və diqqət tələb edə bilən sahələri vurğulayan diaqnostik vasitə kimi xidmət edir',
  brands_identity_10:
    '<b>Xidmətlərimiz müəssisələri lazımi alətlərlə təmin edir</b> məlumatlı qərarlar qəbul etmək, strategiyaları təkmilləşdirmək və müvafiq sənayelərində uğur qazanmaq.',
  brands_identity_11: 'Brendinizin rəqabətli bazarda mövqeyini artırmaq',
  brands_identity_12: 'Bazarda mövcudluğunu artırmağa çalışan müəssisələr',
  brands_identity_13: 'Strategiyalarını yüksəltmək istəyən müəssisələr',
  brands_identity_14: 'Brend Kimliyi',
  brands_identity_15: 'Brend Yerləşdirmə',
  brands_identity_16:
    '<b>Brendinizi ortaya çıxarın</b> istehlak seqmentləri arasında qavrayış.',
  brands_identity_17:
    '<b>Təsdiq olunan keyfiyyətləri müəyyənləşdirin</b> markanıza və rəqiblərinizə.',
  brands_identity_18:
    '<b>Güclü tərəfləri qiymətləndirin</b> və strateji təkmilləşdirmə üçün zəif tərəflər.',
  brands_identity_19:
    '<b>Adüzülməsini qiymətləndirin</b> təşviq edilmiş dəyərlərlə brend imicinizin.',
  brands_identity_20:
    '<b>Hiss olunanı qiymətləndirin</b> markanızın etibarlılığı.',
  brands_identity_21:
    '<b>Arxetipi araşdırın</b> və markanızın simvolik qavrayışı.',
  brands_identity_22:
    '<b>Brendinizi müəyyənləşdirin</b> rəqiblərlə müqayisədə unikal satış nöqtələri və fərqləndirici xüsusiyyətlər.',
  brands_identity_23:
    '<b>Təsir edən əsas atributları dəqiq müəyyənləşdirin</b> məhsullarınızı/xidmətlərinizi istehlakçıların satın alma ehtimalı.',
  brands_identity_24:
    '<b>Brendinizin hazırda necə yerləşdiyini müəyyənləşdirin</b> istehlakçıların şüurunda və istədiyiniz bazar seqmentlərini cəlb etmək üçün ünsiyyət üçün aspektləri müəyyənləşdirin.',
  brands_identity_25:
    'Metodologiya müştərinin istəyi əsasında fərdi olaraq seçilir',
  brands_identity_26: 'Brend məlumatlılığı və istifadəsi',
  brands_identity_27:
    'Brend səviyyəsinin qiymətləndirilməsi qiymətləndirmədə əsas komponent kimi xidmət edən məlumatlılıq və istehlak səviyyələrinin ölçülməsini əhatə edir.',
  brands_identity_28: 'Brend Çatdırılması və Güvən',
  brands_identity_29:
    'Bu metrik müştəri müdafiəsi (tövsiyə) və brendin vədləri yerinə yetirmək, müsbət müştəri təcrübəsi yaratmaq və etibar yaratmaq qabiliyyətini ölçür.',
  brands_identity_30: 'Brend Image Performansı',
  brands_identity_31:
    'Brendin performansının qiymətləndirilməsi onun altı mühüm dəyərlə (3 rasional və 3 emosional), kateqoriya daxilində fərqliliyi və istehlakçılar arasında qürur hissi aşılamaq qabiliyyəti ilə əlaqəsini qiymətləndirməyi əhatə edir.',
  brands_identity_32: 'Brend Sağlamlıq Hesabı',
  brands_identity_33:
    'Brend Sağlamlığı Skoru sizə markanızın rəqabət mühitində necə performans göstərdiyinə dair hərtərəfli anlayışı göstərir.',
  sociological_research_1: 'Sosioloji tədqiqat',
  sociological_research_2:
    'Biz sosial hadisələrin və proseslərin dərin təhlilini aparırıq ki, bu da unikal analitik nəticələr və tövsiyələrin köməyi ilə əsaslandırılmış qərarlar qəbul etməyə kömək edir',
  sociological_research_3: 'Bizimlə əlaqə saxlayın',
  sociological_research_4: 'Müştərilərimiz',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI - telefon sorğuları aparmaq üçün kompüterdən istifadə edən məlumat toplama üsuludur. CATI proqramı sizə məlumatların toplanması, cavabların daxil edilməsi və sorğunun idarə olunması proseslərini avtomatlaşdırmağa imkan verir. CATI-nin istifadəsi sorğuya nəzarəti asanlaşdırır, alınan məlumatların tez təhlili və işlənməsinə imkan verir, həmçinin sorğunun yüksək səviyyədə standartlaşdırılmasını təmin edir.',
  sociological_research_7: 'СATI',
  sociological_research_8:
    'CAWI - respondentlərin veb-interfeys vasitəsilə sorğu vərəqələrini doldurduğu və ya suallara cavab verdiyi məlumatların toplanması üsuludur və xüsusi proqram təminatı vasitəsilə məlumat avtomatik olaraq toplanaraq emal olunur. CAWI metodu geniş miqyaslı sorğular keçirməyə, məlumatları tez toplamaq və təhlil etməyə imkan verir.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI - tədqiqatçının respondentlərin suallarına cavablarını daxil etmək üçün planşet və ya mobil telefondan istifadə etdiyi, məlumatların toplanmasının avtomatlaşdırılmasına, daxiletmə xətalarının azaldılmasına və nəticələrin təhlilinin sadələşdirilməsinə imkan verən üz-üzə sorğu məlumatlarının toplanması üsuludur.',
  sociological_research_11:
    'Mütəxəssislərimiz müxtəlif vasitələrdən istifadə edirlər',
  sociological_research_12: 'Nümunə',
  sociological_research_13:
    'İstənilən mürəkkəblikdə nümunələri hesablayırıq və tətbiq edirik',
  sociological_research_14:
    'İşimizdə sadə, sistemli, təbəqəli və klaster nümunələrindən istifadə edirik',
  sociological_research_15:
    'Milli miqyasda təmsil olunan üz-üzə sorğular üçün biz təbəqələşdirilmiş çoxmərhələli seçim prosedurlarından istifadə edirik',
  sociological_research_16:
    'Yaşayış məntəqələrinin rayonlar üzrə seçilməsi yaşayış məntəqəsinin ölçüsünə və növünə uyğun olaraq həyata keçirilir, onların sayı hər bir rayon üzrə onların həcminə mütənasib olaraq müəyyən edilir',
  sociological_research_17: 'xərcləyirik',
  sociological_research_18: 'İctimai rəyin tədqiqi',
  sociological_research_19: 'Sosial-iqtisadi tədqiqatlar',
  sociological_research_20: 'Ofis işi',
  sociological_research_21:
    'Sosial xidmətlərin keyfiyyətinin qiymətləndirilməsi',
  sociological_research_22: 'Case study',
  sociological_research_23: 'Kadr araşdırması',
  sociological_research_24:
    'Xüsusi auditoriyaların (həssas qruplar, o cümlədən məcburi köçkünlər, hərbçilər, gənclər və s.) tədqiqatı',
  sociological_research_25: 'Hər bir müştəri ilə fərdi işləyirik',
  sociological_research_26: 'Müştərilərimiz:',
  sociological_research_27: 'Xeyriyyə fondları',
  sociological_research_28: 'İctimai təşkilatlar və birliklər',
  sociological_research_29: 'Dövlət strukturları',
  sociological_research_30: 'İşə yanaşmamız',
  sociological_research_31:
    'Biz işə elmi yanaşmadan və mövcud reallığı dərindən dərk etməkdən, həmçinin məlumat toplamaq üçün müxtəlif tədqiqat metodlarından istifadə edirik.',
  sociological_research_32:
    'Biz konkret sorğunu həll etmək üçün lazımi təcrübəyə malik dar profilli ekspertləri cəlb edirik və müxtəlif sahələrdən ekspertlər hovuzuna sahib oluruq.',
  sociological_research_33:
    'Bizim daxili analitika şöbəmiz tapşırığın xüsusiyyətlərindən asılı olaraq unikal alətlər dəstindən istifadə edərək toplanmış məlumatları təhlil edir.',
  sociological_research_34:
    'Hədəf auditoriyanızın ehtiyac və gözləntilərini nəzərə alaraq təfərrüatlı nəticələr və tövsiyələrlə hesabatlar hazırlayırıq.',
  sociological_research_35:
    'Tövsiyələri həyata keçirərkən müsbət dəyişikliklər etməyə kömək edən məsləhət və dəstək veririk.',
  sociological_research_36: 'Televiziyadakı araşdırmamız',
  sociological_research_37: 'Hesabatlarımızı nəzərdən keçirin',
  sociological_research_38:
    'İşə elmi yanaşmamız müştərilərimizə bütün Ukrayna və Avropada minimal səhvlə dəqiq və etibarlı sosioloji məlumatlara zəmanət verir',
  sociological_research_39:
    'Biz bütün etik prinsip və standartlara və sosioloji tədqiqatların aparılması qaydalarına uyğun işləyirik.',
  sociological_research_40:
    'Biz Ukrayna Sosioloji Assosiasiyasının sosioloqunun peşə etikası kodeksinə və Tədqiqatların aparılması üçün Etik Prinsiplərin Helsinki Bəyannaməsinə əməl edirik. ESOMAR, Ukrayna Marketinq Assosiasiyası, Ukrayna Ümumkrayna Əlaqə Mərkəzləri Assosiasiyasının üzvləri.',
  sociological_research_41: 'Sahə',
  sociological_research_42: 'onlayn panel',
  sociological_research_43: 'daimi müsahibəçilər',
  sociological_research_44: 'nəzarətçilər',
  sociological_research_45: 'Sahə keyfiyyətinə nəzarət',
  sociological_research_46: 'GPS koordinatlarının 100% fiksasiyası (CAPI)',
  sociological_research_47: 'Anketlərin təsdiqi (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: '100% müsahibə qeydi (CAPI, CATI)',
  sociological_research_49: 'Lisenziyalı proqram təminatından istifadə edirik:',
  sociological_research_50:
    'Statistik məlumatların təhlili, verilənlər massivinin emalı üçün SPSS',
  sociological_research_51:
    'CAPI üçün DigSee Sure: sorğu məntiqi, geolokasiya, müsahibələrin audio yazısı, video materialların nümayişi',
  sociological_research_52:
    'CAWI üçün SurveyMonkey: sorğu məntiqi, rahat format, vizuallaşdırma',
  sociological_research_53:
    'VoIPTime (əlaqə mərkəzi): istənilən mürəkkəblikdə anketlərin proqramlaşdırılması, audio yazıların müştəriyə ötürülməsi',
  sociological_research_54: 'Nümunə',
  sociological_research_55:
    'İstənilən mürəkkəblikdə nümunələri hesablayırıq və tətbiq edirik',
  sociological_research_56:
    'İşimizdə sadə, sistemli, təbəqəli və klaster nümunələrindən istifadə edirik',
  sociological_research_57:
    'Milli miqyasda təmsil olunan üz-üzə sorğular üçün biz təbəqələşdirilmiş çoxmərhələli seçim prosedurlarından istifadə edirik',
  sociological_research_58:
    'Yaşayış məntəqələrinin rayonlar üzrə seçilməsi yaşayış məntəqəsinin ölçüsünə və növünə uyğun olaraq həyata keçirilir, onların sayı hər bir rayon üzrə onların həcminə mütənasib olaraq müəyyən edilir',
  sociological_research_59: 'Komanda',
  sociological_research_60:
    'Bizim sosioloqlarımız Ukrayna Sosioloqlar Assosiasiyasının üzvüdürlər',
  sociological_research_61:
    'Müxtəlif sənaye sahələrində həm kəmiyyət, həm də keyfiyyət layihələrində təcrübəsi olan 12 tam ştatlı analitikimiz var.',
  sociological_research_62:
    '10 ildən çox tədqiqat təcrübəsi olan güclü analitik mütəxəssislər komandamız. Heyətimizdə alimlər, elmlər namizədləri, MBI var.',
  sociological_research_63: 'İrina Maksyuk',
  sociological_research_64:
    'Tədqiqat şöbəsinin müdiri. Marketinq tədqiqatları, bütün mərhələlərdə tədqiqatların idarə edilməsi, analitik hesabatların hazırlanması, təqdimatların keçirilməsi sahəsində 15 ildən artıq təcrübə',
  sociological_research_65: 'Andrey Biloskurski',
  sociological_research_66:
    'Proqramlaşdırma və məlumatların emalı şöbəsinin müdiri. Marketinq, sosioloji və marketinq tədqiqatlarında 20 illik təcrübə',
  sociological_research_67: 'Olena Somova',
  sociological_research_68:
    'Proqramlaşdırma mütəxəssisi, DonSUU-da sosiologiya üzrə aspirant. Marketinq tədqiqatlarında 5 il iş Demokratik Qadınlar Alyansı İdarə Heyətinin üzvü, vəsait toplama şöbəsinin müdiri.',
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    'Tədqiqat şöbəsinin Sosioloji tədqiqatlar şöbəsinin müdiri, beynəlxalq və milli səviyyədə sosioloji və sosial layihələrlə işləmək üzrə 13 illik təcrübəyə malikdir.  sosiologiya elmləri namizədi, Ukrayna Ümumkrayna Əlaqə Mərkəzləri Assosiasiyasının Tədqiqat Komitəsinin rəhbəri.',
  ad_hoc_1: 'Ad Hoc',
  ad_hoc_2: 'Unikal hallar üçün alternativ araşdırmamızı kəşf edin',
  ad_hoc_3: 'Bizimlə əlaqə saxlayın',
  ad_hoc_4: 'Bizim yanaşmamız',
  ad_hoc_5:
    'Mütəxəssislərdən ibarət komandamız toplanmış məlumatları diqqətlə təhlil edərək, böyümənizə töhfə verən kompleks həllər tapmaq imkanı verir.',
  ad_hoc_6:
    'Biz sorğular, müsahibələr, fokus qrupları, məlumatların təhlili və s. daxil olmaqla, müxtəlif metodologiyalardan istifadə edərək, sizin xüsusi tələblərinizə uyğunlaşdırılmış unikal tədqiqat dizayn edirik.',
  ad_hoc_7: 'Niyə Ad Hoc Araşdırmanı Seçməlisiniz?',
  ad_hoc_8: 'Çeviklik',
  ad_hoc_9:
    'Heç bir iki tədqiqat layihəsi eyni deyil. Ad Hoc Tədqiqat ilə biz lazım gəldikdə unikal yanaşmanı uyğunlaşdırmaq və təkmilləşdirmək çevikliyinə malikik, bununla da fikirlərinizin aktual və icra oluna bilən qalmasını təmin edirik.',
  ad_hoc_10: 'Dəqiqlik',
  ad_hoc_11:
    'Metodologiyalarımızı unikal tələblərinizə uyğunlaşdırmaqla biz dəyərli anlayışlar təmin edən dəqiq və hədəflənmiş nəticələr təqdim edirik.',
  ad_hoc_12: 'Yenilik',
  ad_hoc_13:
    'Biz tədqiqat prosesimizdə innovasiya və yaradıcılığı qəbul edirik, gizli imkanları və problemləri aşkar etmək üçün yeni texnika və yanaşmaları araşdırırıq.',
  ad_hoc_14: 'Vaxtlılıq',
  ad_hoc_15:
    'Ad Hoc Tədqiqat sürətlə həyata keçirməyə imkan verir, kritik anlayışlara vaxtında daxil olmağa imkan verir və sizi sənayenizdə əyri qabaqda saxlayır.',
  ad_hoc_16: 'Ekspertizamız',
  ad_hoc_17:
    '20 ildən çox bazar araşdırması və müştəri təcrübəsi olan 4Service mütəxəssisləri biznesinizi inkişaf etdirməyə kömək edən unikal həllər yaradırlar',
  ad_hoc_18:
    'Biz sizin xüsusi tələblərinizə əsaslanan müxtəlif alətlər dəstindən istifadə edirik',
  ad_hoc_19: 'Heç bir metodologiya sizin üçün işləmir?',
  ad_hoc_20: 'Ad Hoc Tədqiqatı tələb edin',
  ad_hoc_21: 'Xüsusi məsələ üçün hazırlanmışdır',
  ad_hoc_22:
    'Biz həm metodologiyaların müxtəlif hissələrinin qarışığından, həm də tamamilə yeni yanaşmadan istifadə edirik',
  ad_hoc_23: 'Fərdi yanaşma',
  ad_hoc_24: 'Probleminizə birbaşa həlli diqqətlə uyğunlaşdırırıq',
  ad_hoc_25: 'Yuxarıdan və kənara çıxın',
  ad_hoc_26:
    'Biznesinizin məqsədlərinə çatmasına kömək etmək üçün tövsiyələrlə birlikdə fəaliyyət planı təqdim edirik',
  ad_hoc_27: 'Qeyri-standart probleminiz var',
  ad_hoc_28: 'Metodologiyaların heç biri probleminiz üçün işləmir',
  ad_hoc_29:
    'Siz eyni anda müxtəlif metodologiyaları və ya onların hissələrini tələb etmək istərdiniz',
  ad_hoc_30: 'Ad Hoc tədqiqatından kim faydalanır?',
  career: 'Karyera',
};
