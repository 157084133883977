import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ConsumerJourneyMapTrustedByStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { partnersWhiteTheme } from '../../../constants';
import Marquee from 'react-fast-marquee';

export const ConsumerJourneyMapTrustedBy = () => {
  const [data, setData] = useState<{ static: string; hover: string; alt: string }[]>();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    setData([...partnersWhiteTheme, ...partnersWhiteTheme]);
  }, []);

  return (
    <ConsumerJourneyMapTrustedByStyles>
      <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_3')}</h2>

      {data && (
        <Marquee
          speed={window.innerWidth > 1000 ? 100 : 120}
        >
          {data.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
            <div className="logoWrapper" key={index}>
              <img src={item.static} alt={item.alt} />
              <img src={item.hover} alt={item.alt} />
            </div>
          ))}
        </Marquee>
      )}
    </ConsumerJourneyMapTrustedByStyles>
  );
};
