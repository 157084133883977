import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesCXStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const OpportunitiesCX = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesCXStyles>
      <h1>
        {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_27')}
      </h1>
      <div className="container">
        <div className="count">
          <h2>+90</h2>
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_29')}
          </p>
        </div>
        <div className="count">
          <h2>
            +6{' '}
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_30')}
          </h2>
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_31')}
          </p>
        </div>
        <div className="count">
          <h2>
            +10{' '}
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_32')}
          </h2>
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_33')}
          </p>
        </div>
      </div>
    </OpportunitiesCXStyles>
  );
};
