import styled from 'styled-components';

export const HoldingCareerStyles = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px 10px;
    gap: 50px;

    @media (max-width: 768px) {
      padding: 30px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0;
      font-weight: 600;
      font-size: 60px;
      line-height: 115%;
      color: #ffffff;
      max-width: 870px;

      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
  }

  .cards {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    &-item {
      border: 1px solid #0000ff;
      width: 100%;
      max-width: 370px;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    &-text {
      height: 160px;
      padding: 15px 20px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      h6 {
        margin: 0;
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }

    &-utils {
      width: 100%;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        background: rgba(255, 255, 255, 0.2);
        width: 21px;
        height: 21px;
        border-radius: 50%;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
      }

      a {
        margin: 0;
        padding: 5px 20px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100px;
        text-align: center;
        font-weight: 700;
        font-size: 13px;
        line-height: 115%;
        color: #ffffff;
      }
    }
  }
`;
