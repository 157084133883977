import React from 'react';
import { mainPageMarketResearchData } from '../../../constants';
import MainTabsSection from '../mainTabsSection/MainTabsSection';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { MainMethodologiesStyles } from '../mainMethodologies/MainMethodologiesStyles';

export const MainMarketResearch = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <MainMethodologiesStyles>
      <MainTabsSection
        colorTheme="darkTheme"
        tabsData={mainPageMarketResearchData(interfaceLanguage)}
        description={getTranslationsByLangOrEng(interfaceLanguage, 'boarding_53')}
        signature={getTranslationsByLangOrEng(interfaceLanguage, 'footer_7')}
        link="market-research"
      />
    </MainMethodologiesStyles>
  );
};
