import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesCasesStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { IPost } from '../../../enteties/IPost';
import workWithResponse from '../../../functions/workWithResponse';
import { AdminApi } from '../../../api/adminApi';
import Loader from '../../loader/Loader';
import { Link } from 'react-router-dom';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import { SwiperSlide } from 'swiper/react';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import ArrowRightSmall from '../../../assets/icons/ArrowRightSmall';
import defaultImage from '../../../assets/images/image-not-found.jpg';

export const OpportunitiesCases = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [posts, setPosts] = useState<IPost[] | null>(null);

  async function getPosts() {
    setIsLoading(true);

    if (loadingError) {
      setLoadingError(false);
    }

    try {
      await workWithResponse(() => AdminApi.getPosts(interfaceLanguage, { type: '1' }, 3, 0)).then(({ error, data: postsData }) => {
        if (!error && postsData) {
          setPosts(postsData.data);
        }
      });
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getPosts();
  }, [interfaceLanguage]);

  return (
    <OpportunitiesCasesStyles>
      <h1>
        {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_1')}
      </h1>

      {!isLoading && posts ? (
        <SwiperCenteredSlidesPerViewAuto
          loop={posts.length > 0}
          buttonsIconsColor="#FFF"
        >
          {posts.map((item, index) => (
            <SwiperSlide key={item.slug} className="slide">
              <Link
                to={`/${interfaceLanguage}/cases${
                  item.slug[0] === '/' ? '' : '/'
                }${item.slug}`}
              >
                <div className="headContainer">
                  <img
                    src={item.coverImage || defaultImage}
                    loading="lazy"
                    alt={item.contentTitle}
                  />
                </div>

                <div className="textContainer">
                  <p>{item.contentTitle}</p>
                  <div className="hashtagAndButtonWrapper">
                    <div className="button">
                      <span>
                        {getTranslationsByLangOrEng(interfaceLanguage, 'more')}
                      </span>
                      <ArrowRightSmall color="#FFF" height={12} width={12} />
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
          <SwiperSlide className="lastSlide">
            <span className="description">
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'preview_cases_section_2',
              )}
            </span>
            <Link to={`/${interfaceLanguage}/cases`}>
              <ButtonWithArrowOnHover
                textColor="#000000"
                onClick={() => {}}
                arrowColor="#000000"
                background="#CBCBCB"
                hoverBackground="#FFF"
                hoverTextColor="#000000"
                text={getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'find_out_more',
                )}
                className="lastSlideButton"
              />
            </Link>
          </SwiperSlide>
        </SwiperCenteredSlidesPerViewAuto>
      ) : (
        <div className="container">
          <Loader />
        </div>
      )}
    </OpportunitiesCasesStyles>
  );
};
