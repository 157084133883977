import styled from 'styled-components';

export const NavigationButtonsStyles = styled.div`
  display: flex;
  gap: 8px;

  //@media (max-width: 1000px) {
  //  margin: auto;
  //}

  button {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 39px;
    height: 39px;

    background: none;
    border-radius: 100%;
    border: 1px solid #0000ff;
    cursor: pointer;

    &:nth-child(1) {
      transform: rotate(180deg);
    }

    &.disabled {
      //border: 1px solid rgba(0, 0, 255, 0.5);

      cursor: unset;
      opacity: 0.5;
    }
  }
`;
