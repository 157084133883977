import styled from 'styled-components';
import line from '../../../assets/images/cxSolution/cx-line.png';

export const CxGetStartedStyles = styled.div`
  padding: 100px 0;
  width: 100%;

  & .container {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h3 {
    color: #fff;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 700;
    line-height: 115%;
  }

  .cx-elapse {
    position: absolute;
    border-radius: 344px;
    background: rgba(2, 186, 149, 0.5);
    filter: blur(118.05000305175781px);
    width: 344px;
    height: 344px;

    &-first {
      left: 50%;
      transform: translate(-50%);
      top: 0;
    }

    &-second {
      left: 40%;
      transform: translate(-50%);
      top: 50%;
    }
  }

  .cx-plan {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: flex-end;
    position: relative;

    @media (max-width: 768px) {
      gap: 30px;
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 28px;
      left: 45%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url(${line}) no-repeat;
      background-size: contain;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 580px;
      position: relative;
      z-index: 5;

      @media (max-width: 768px) {
        gap: 20px;
      }

      h2 {
        color: #02ba95;
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 700;
        line-height: 115%;

        @media (max-width: 768px) {
          font-size: 28px;
        }
      }

      p {
        color: #fff;
        font-family: Montserrat;
        font-size: 28px;
        font-weight: 500;
        line-height: 115%;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
`;
