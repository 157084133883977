import styled from 'styled-components';
import banner from '../../../assets/images/career/banner.png';

export const CVBannerStyles = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }

  form {
    height: 100%;
  }

  .banner {
    width: 70%;
    height: 100%;
    max-width: 970px;
    max-height: 700px;
    display: flex;
    position: relative;
    background: #0000ff;

    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      max-width: 360px;
      max-height: 500px;
      width: 100%;
    }

    form {
      width: 100%;
      height: 100%;
      background: url(${banner});
      background-position: center center;
      background-size: cover;
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding: 60px;
      box-sizing: border-box;

      @media screen and (max-width: 991px) {
        padding: 30px;
      }

      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            105.96deg,
            #0000ff 0%,
            rgba(0, 0, 255, 0.4) 18.24%,
            rgba(0, 0, 255, 0) 100%
          ),
          rgba(0, 0, 0, 0.28);
      }
    }

    &__form {
      position: relative;
      z-index: 5;
      max-width: 500px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 100%;
      color: #ffffff;
      max-width: 400px;

      @media screen and (max-width: 991px) {
        font-size: 26px;
        margin-top: 35px;
      }
    }

    &__fields {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 10px;

      input {
        border: none;
        outline: none;
        border-bottom: 2px solid #fff;
        padding: 10px;
        background: transparent;

        &::placeholder {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 100%;
          color: rgba(255, 255, 255, 0.5);

          @media screen and (max-width: 991px) {
            font-size: 18px;
          }
        }

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 100%;
        color: rgba(255, 255, 255, 1);

        @media screen and (max-width: 991px) {
          font-size: 18px;
        }
      }
    }

    &__close {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 5;
      cursor: pointer;

      @media screen and (max-width: 991px) {
        top: 15px;
        left: 15px;
        right: inherit;

        svg {
          width: 13px;
          height: 13px;
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;

      button {
        border: none;
        outline: none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px 25px;
        width: 100%;
        border: 2px solid #ffffff;
        border-radius: 100px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #ffffff;
        cursor: pointer;

        @media screen and (max-width: 991px) {
          padding: 12px 15px;
        }

        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }
`;
