import styled from 'styled-components';
import approach1 from '../../../assets/images/usageAttitude/approach1.png';
import approach2 from '../../../assets/images/usageAttitude/approach2.png';
import approach3 from '../../../assets/images/usageAttitude/approach3.png';

export const UsageAttitudeApproachStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .cards {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'first second third'
      'fourth fifth sixth';

    max-width: 1190px;
    width: calc(100% - 10px);
    margin: 50px auto 0;
    gap: 30px;
    padding: 0 5px;

    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 18px;

      p {
        font-size: 20px;

        @media (max-width: 1200px) {
          font-size: 18px;
        }
      }
    }

    &-item {
      display: flex;
      padding: 30px;
      gap: 18px;
      height: calc(180px - 60px);
      border-radius: 5px;
      border: 1px solid #5498ff;

      &:nth-child(1) {
        grid-area: first;
        background: url(${approach1}) no-repeat;
        background-position: center center;
        background-size: cover;
      }
      &:nth-child(2) {
        grid-area: second;
      }
      &:nth-child(3) {
        grid-area: third;
        background: url(${approach2}) no-repeat;
        background-position: center center;
        background-size: cover;
      }
      &:nth-child(4) {
        grid-area: fourth;
      }
      &:nth-child(5) {
        grid-area: fifth;
        background: url(${approach3}) no-repeat;
        background-position: center center;
        background-size: cover;
      }
      &:nth-child(6) {
        grid-area: sixth;
      }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6) {
        display: flex;
        flex-direction: column;
        gap: 18px;

        p {
          font-size: 20px;

          @media (max-width: 1200px) {
            font-size: 18px;
          }
        }
      }
    }
  }
`;
