import React, { useEffect, useRef, useState } from 'react';
import { YearGoodDeedsStyles } from './YearGoodDeedsStyles';
import { useWindowSize } from 'usehooks-ts';
import intro from './assets/intro2.png';
import introMob from './assets/intro2mob.png';
import prefooterDesc from './assets/prefooter2desc.png';
import prefooterMob from './assets/prefooter2mob.png';
import item1 from './assets/item1.png';
import item2 from './assets/item2.png';
import item3 from './assets/item3.png';
import item4 from './assets/item4.png';
import item5 from './assets/item5.png';
import item6 from './assets/item6.png';
import item7 from './assets/item7.png';
import item8 from './assets/item8.png';
import item9 from './assets/item9.png';
import item10 from './assets/item10.png';
import item11 from './assets/item11.png';
import item12 from './assets/item12.png';
import item13 from './assets/item13.png';
import item14 from './assets/item14.png';
import item15 from './assets/item15.png';
import item16 from './assets/item16.png';
import item17 from './assets/item17.png';
import item18 from './assets/item18.png';
import item19 from './assets/item19.png';
import item20 from './assets/item20.png';
import item21 from './assets/item21.png';
import item22 from './assets/item22.png';
import item23 from './assets/item23.png';

export const YearGoodDeeds = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [isIntroVisible, setIsIntroVisible] = useState(true);

  const { width } = useWindowSize();

  const footerWrapperRef = useRef<HTMLDivElement | null>(null);
  const introWrapperRef = useRef<HTMLDivElement | null>(null);

  const isHTMLDivElement = (
    element: Element | null,
  ): element is HTMLDivElement => element instanceof HTMLDivElement;

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;
      const scrollY = window.scrollY;
      const scrollPercent = (scrollY / documentHeight) * 100;

      setScrollPercentage(scrollPercent * 1.05);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!footerWrapperRef.current) return;
      if (isHTMLDivElement(footerWrapperRef.current)) {
        const footerWrapperTop = footerWrapperRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        setIsFooterVisible(footerWrapperTop > windowHeight);
      }
    };
    window.addEventListener('scroll', handleScroll);
    const footerWrapperElement = document.querySelector('.footerWrapper');
    if (footerWrapperElement && isHTMLDivElement(footerWrapperElement)) {
      footerWrapperRef.current = footerWrapperElement;
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (introWrapperRef.current) {
        const introPosition = introWrapperRef.current.getBoundingClientRect();
        const isVisible = introPosition.bottom > 0 && introPosition.top < window.innerHeight;
        setIsIntroVisible(isVisible);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <YearGoodDeedsStyles>
      <div
        className="progressBar"
        style={{ display: isFooterVisible ? 'block' : 'none' }}
      >
        <div className="months">
          <span>Січень</span>
          <span>Лютий</span>
          <span>Березень</span>
          <span>Квітень</span>
          <span>Травень</span>
          <span>Червень</span>
          <span>Липень</span>
          <span>Серпень</span>
          <span>Вересень</span>
          <span>Жовтень</span>
          <span>Листопад</span>
          <span>Грудень</span>
        </div>
        <div className="bar">
          <div className="fill" style={{ width: `${scrollPercentage}%` }} />
        </div>
      </div>

      <section className="intro" ref={introWrapperRef}>
        <div className="intro__container _container">
          <img src={width > 800 ? intro : introMob} alt="" />
        </div>
      </section>
      <section className="content">
        <div
          className="content__container _container"
          style={{ backgroundAttachment: isIntroVisible ? 'initial' : 'fixed' }}
        >
          <div className="card">
            <img src={item1} alt="" />
            <div className="card__text">
              <b>Philip Morris International</b> виділила $10 млн на підтримку
              гуманітарних заходів, придбала 23 автомобілі швидкої допомоги,
              надає допомогу у виготовленні реанімобілей та будівництві
              тимчасового житла для переселенців в Україні. Крім того, компанія
              фінансує відновлення соціальної інфраструктури та закупівлю
              медичного обладнання для лікарень у декількох областях країни.
            </div>
          </div>
          <div className="card">
            <img src={item2} alt="" />
            <div className="card__text">
              <b>Vodafone</b> надала{' '}
              <b>безкоштовні гаджети та послуги на 1,54 млрд гривень</b>, а
              також перерахувала{' '}
              <b>
                152 млн гривень на соціальні та гуманітарні потреби, включаючи
                швидку допомогу
              </b>
              , медичне обладнання та будівництво укриттів для шкіл. Крім того,
              компанія підтримує гарячу лінію з профілактики самогубств та
              здоров’я українців, а також взяла участь у проєктах Lifeline
              Ukraine та «Ліга тепла», забезпечивши енергонезалежність
              пологового будинку в Боярці.
            </div>
          </div>
          <div className="card">
            <img src={item3} alt="" />
            <div className="card__text">
              <b>Акція "Разом ми сильніші".</b> Українці допомагають сплатити за
              електроенергію тим, хто в умовах війни не мають змоги зробити це
              самостійно. Разом з брендом @tattooshka_ua створили благодійний
              набір із 10 тимчасових татуювань, що світяться в темряві.
            </div>
          </div>
          <div className="card">
            <img src={item4} alt="" />
            <div className="card__text">
              Протягом останніх місяців в Україні реалізовано ряд мілітарних
              проєктів, включаючи закупівлю техніки та обладнання для військових
              потреб. Основні досягнення включають придбання і ремонт
              транспортних засобів, закупівлю медичного обладнання для
              офтальмологічних місій та організацію програми{' '}
              <b>"Діагностика за донат"</b>. Оглянуто та надано допомогу понад
              тисячі пацієнтам.
            </div>
          </div>
          <div className="card">
            <img src={item5} alt="" />
            <div className="card__text">
              <p>
                <b>Направив більше 1 млн грн для Superhumans</b>{' '}
                (всеукраїнського центру реабілітації) Нещодавно Банк Восток та
                БФ "Милосердя Віктор" надали допомогу військовим із 126-ї
                окремої бригади територіальної оборони Одеси.
              </p>
              <p>
                Захисники отримали вже 8-й за рахунком пікап та запчастини для
                автівок — у тому числі для транспорту евакуації поранених.
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item6} alt="" />
            <div className="card__text">
              <p>
                <b>
                  Протягом року реалізовано широкий спектр благодійних проєктів
                  в Україні:
                </b>
              </p>
              <ul>
                <li>
                  <b>Збір коштів для протезування</b> та реабілітації українців,
                  які втратили кінцівки
                </li>
                <li>
                  <b>Надано понад 5 мільйонів літрів</b> питної води для
                  гуманітарних потреб у різних містах.
                </li>
                <li>
                  <b>Підтримка культурних</b> та медичних ініціатив, таких як
                  музей "Львіварня" та медичні місії "Життєлюб".
                </li>
                <li>
                  <b>Реставрація університетських корпусів</b> та підтримка
                  розвитку соціальних ініціатив, включаючи створення велохабу та
                  психологічний хаб "Опліч ХАБ".
                </li>
                <li>
                  <b>Підтримка різних проєктів,</b> включаючи відновлення
                  об'єктів культурної спадщини, фінансову допомогу для
                  розмінування територій та відбудову житлових будинків.
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <img src={item7} alt="" />
            <div className="card__text">
              <p>
                Благодійна діяльність мережі аптек "Подорожник" у 2023 році.
                Надала допомогу на понад 35 мільйонів гривень. Передано 52 авто
                на потреби ЗСУ
              </p>
              <p>
                <b>Допомога військовим:</b>
              </p>
              <ul>
                <li>
                  Starlink, мобільні шпиталі, дрони, крісла колісні, протимінні
                  костюми, генератори, медикаменти, продукти харчування тощо.
                </li>
                <li>Долучилася до МЕГАЗБОРУ "Бонусна броня" Сергія Притули.</li>
              </ul>
              <p>
                <b>Допомога цивільним:</b>
              </p>
              <ul>
                <li>
                  Продуктові та гігієнічні набори для постраждалих від війни.
                </li>
                <li>Допомога футбольній команді Тростянця.</li>
                <li>Медичні аптечки для дітей-переселенців.</li>
                <li>Свято для дітей у Парку Історії Землі.</li>
                <li>Психологічна допомога постраждалим українцям.</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <img src={item8} alt="" />
            <div className="card__text">
              <b>Надання авто</b> для благодійної організації repair.together та
              регулярна фінансова допомога для БО «Фундація Дім Рональда
              МакДональда в Україні»
            </div>
          </div>
          <div className="card">
            <img src={item9} alt="" />
            <div className="card__text">
              <p>
                <b>Suziria Charity</b> – благодійний проєкт компанії Suziria
                Group вже <b>надав допомогу 1000 песикам</b>, які пліч-о-пліч
                стоять з нашими бійцями, захищають та допомагають ЗСУ,{' '}
                <b>
                  забезпечивши 6425 кг кормів та 10 000 одиниць ветпрепаратів
                </b>
                . Проєкт також підтримав 70 тисяч тварин,{' '}
                <b>надаючи 252 тони кормів</b> і допоміг у адопції 50 котиків
                через мережу MasterZoo.
              </p>
              <p>
                У зв'язку з руйнуванням Каховської ГЕС, Suziria Group вже
                відвантажила <b>8000 кг допомоги волонтерам</b> та військовим в
                Херсонській області.
              </p>
              <p>
                В рамках проєкту відбудовано <b>ветеринарний комплекс</b> для
                притулку Best Friends на <b>суму 3 млн грн</b> і передано
                військовим два дрони, тепловізори та інше обладнання.
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item10} alt="" />
            <div className="card__text">
              У червні відбулося швидке закриття збору на пікап для підрозділу,
              де служить колишній колега. У жовтні компанія «Версуні Україна»
              передала гуманітарну допомогу наборами техніки для дому Фонду{' '}
              <b>
                Притули та Фундації Олени Зеленської на загальну суму близько 1
                млн грн
              </b>
              . Це включало в себе пилососи, праски, блендери, мультиварки та
              інше. У листопаді працівники організували благодійні активності,
              збираючи <b>350 тис. грн на підтримку ЗСУ та захисників</b>, з
              яких було придбано планшети, дрони, прилади нічного бачення та
              інше обладнання. Кожного місяця працівники надають персональні
              донати на підтримку ЗСУ та відмовляються від привітань із Днем
              Народження на користь зборів на ЗСУ.
            </div>
          </div>
          <div className="card">
            <img src={item11} alt="" />
            <div className="card__text">
              <p>
                <b>На сьогодні надано допомоги на понад 11 млн. грн</b>, яка
                включає допомога ЗСУ та багатодітним родинам
              </p>
              <p>
                Також встановлені велопарковки для клієнтів та був ініційований
                благодійний збір батарейок
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item12} alt="" />
            <div className="card__text">
              <ul>
                <li>
                  <b>Підтримка ЗСУ</b>: SOVA перерахувала Фонду «Повернись
                  живим» 2 400 000 та спільно з Благодійним фондом «Квітна»
                  випустила лімітований браслет, кошти від продажу якого були
                  спрямовані на підтримку Пересувної медичної платформи, яка
                  надає медичну допомогу саперним групам Сил підтримки ЗСУ
                  (зібрано 300 тисяч гривень)
                </li>
                <li>
                  <b>Підтримка дітей</b>: SOVA спільно з кондитерською HONEY
                  випустила подарунковий набір печива для «Міста добра». SOVA і
                  FROLOV створили піни для для благодійної кампанії “Небайдужі
                  рятують серця дітей” від TVORCHI × VISA X UNITED24. Ці піни
                  були продані на аукціоні, а виручені кошти були спрямовані на
                  допомогу дітям із вродженими вадами серця.
                </li>
                <li>
                  <b>Підтримка парамедиків «Госпітальєри»</b>: SOVA та бренд
                  FROLOV презентували продовження колаборації For Love заради
                  благодійної мети
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <img src={item13} alt="" />
            <div className="card__text">
              Загальний <b>внесок компанії - 20,5 млн грн на потреби ЗСУ,</b>{' '}
              включаючи техніку для оборони, логістичні послуги та інші
              ініціативи. Компанія шефує 15 військовим частинам, співпрацює з
              органами влади та підтримує протиповітряну оборону.{' '}
              <b>
                За 650 днів війни Фокстрот обробив 1170 запитів та передав понад
                18 млн грн техніки та гаджетів для різних груп захисників та
                постраждалих.
              </b>{' '}
              Залучив 715 000 євро на гуманітарну допомогу та майже 1 млн грн
              для тварин.
            </div>
          </div>
          <div className="card">
            <img src={item14} alt="" />
            <div className="card__text">
              <p>Акція "Гігапоміч": нарахування бонусних гігабайтів донорам</p>
              <p>
                Регулярне нарахування пакетів зв'язку військовим без додаткової
                плати.
              </p>
              <p>
                <b>
                  Передача обладнання для зв'язку на потреби ЗСУ, сумарною
                  вартістю близько 40 млн грн станом на кінець 2023.
                </b>
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item15} alt="" />
            <div className="card__text">
              <p>
                У 2023 році компанія Київстар реалізувала низку благодійних та
                інвестиційних проєктів, спрямованих на допомогу Україні та її
                громадянам.
              </p>
              <p>
                <b>
                  У січні Київстар разом з волонтерами перерахував 1 мільйон
                  гривень на теплі речі літнім людям,
                </b>{' '}
                які постраждали від війни. У лютому оператор відкрив «Пункти
                незламності» в Ірпені, Миколаєві та Запоріжжі, де люди могли
                зарядити свої пристрої, отримати гарячий чай та каву та
                дізнатися актуальну інформацію про ситуацію в Україні. У березні
                компанія виділила 7 млн грн та 5000 сім-карт з тарифом
                пільгового доступу до мобільного інтернету на рік для учнів
                шкіл, частково або повністю зруйнованих внаслідок бойових дій.
              </p>
              <p>
                <b>Було запущено два масштабних проєкти:</b>
              </p>
              <p>
                <b>«Дитяча надія»</b> – програма забезпечення медичним
                обладнанням онко-, кардіо- та опікових відділень українських
                лікарень. Загалом для ініціативи зібрано майже 29 мільйонів
                гривень, зокрема 19 мільйонів зібрано абонентами Київстар та 10
                виділено самостійно компанією. Обладнання придбано до 69
                відділень.
              </p>
              <p>
                <b>«Нам тут жити»</b> – проєкт забезпечення транспортом та
                обладнанням 146 мобільних груп розмінування у Збройних силах
                України. Наразі зібрано понад 152, 3 млн грн. Ця сума дозволяє
                забезпечити транспортом та саперним спорядженням 127
                інженерно-саперних груп Сил підтримки ЗСУ. Станом на сьогодні 96
                груп отримали спорядження.
              </p>
              <p>
                У вересні співробітники Київстару взяли участь у традиційному
                корпоративному благодійному заході <b>«Тиждень донорства»</b>. У
                жовтні компанія передала другу партію медичного обладнання до
                блоку дитячих опіків Національного реабілітаційного центру
                «Незламні» у Львові.
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item16} alt="" />
            <div className="card__text">
              <p>
                METRO Україна надає гуманітарну допомогу жителям України,
                постраждалим від війни
              </p>
              <p>
                <b>
                  З початку повномасштабного вторгнення РФ компанія надала
                  допомогу на суму понад 58 млн грн.
                </b>
              </p>
              <p>
                Військам були передані продукти харчування, засоби гігієни,
                медикаменти, спорядження. Протитуберкульозним закладам були
                передані продукти харчування, засоби гігієни, побутову хімію.
                Людям, які були вимушені переїхати з зони бойових дій, були
                передані продукти харчування, предмети першої необхідності.
                Дітям були передані іграшки, солодощі, шкільне приладдя.
                Ветеранам були передані книги, канцтовари. Молодим людям з
                інвалідністю була надана підтримка для відкриття соціального
                кафе-пекарні. Дітям, які втратили батьків на війні, були
                передані подарунки на День Святого Миколая. Компанія METRO
                Україна продовжує надавати гуманітарну допомогу жителям України,
                постраждалим від війни.
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item17} alt="" />
            <div className="card__text">
              <p>
                <b>
                  Від початку 2023 року передала військовим по окремих запитах
                  техніки на загальну суму 13 млн гривень та понад 40 автівок за
                  ініціативи СЕО компанії Ігоря Хижняка.{' '}
                </b>
              </p>
              <p>
                <b>Також реалізовано:</b>
              </p>
              <ul>
                <li>
                  <b>Благодійна акція "КРАЩ"</b>: компанія перерахувала фонду
                  "Повернись живим" 5,9 мільйона гривень.
                </li>
                <li>
                  <b>Підтримка Збору сили від United24</b>, Monobank та Ігоря
                  Лаченкова: компанія перерахувала 1 мільйон гривень на дрони.
                </li>
                <li>
                  <b>Підтримка</b> новоствореного підрозділу комплексної
                  розвідки <b>ТРО "Очі яструба"</b>: компанія перерахувала 1
                  мільйон гривень.
                </li>
                <li>
                  <b>Облаштування тимчасового житла</b> для постраждалих від
                  підриву Каховської ГЕС: компанія передала три вантажівки
                  побутової техніки та генераторів на загальну суму у 3 мільйони
                  гривень.
                </li>
                <li>
                  <b>Перерахування бонусів</b> від покупок на потреби ЗСУ:
                  компанія перерахувала 345 тисяч гривень.
                </li>
                <li>
                  <b>Передача військовим техніки</b> на загальну суму 13
                  мільйонів гривень та понад 40 автівок.
                </li>
              </ul>
              <p>
                Компанія Comfy продовжує підтримувати Збройні Сили України та
                постраждалих від війни.
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item18} alt="" />
            <div className="card__text">
              <p>
                <b>Благодійні активності Аквантіс 2023</b>
              </p>
              <p>
                Забезпечення і встановлення питного апарату для реабілітаційного
                дитячого центру в м. Дніпро. Закупівля радіостанції та
                тактичного одягу для батальйону тероборони. Збір на джип у
                Бахмут. Забезпечення 65 захисників спальними мішками
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item19} alt="" />
            <div className="card__text">
              <p>
                SOCAR Energy Ukraine надає гуманітарну допомогу жителям України,
                постраждалим від війни.
              </p>
              <p>
                <b>
                  З початку повномасштабного вторгнення РФ компанія надала
                  допомогу на суму понад 100 млн грн.
                </b>
              </p>
              <p>
                Військам було передано пальне, дизель та газ. Постраждалим від
                підриву Каховської ГЕС було передано пальне, продукти
                харчування, місця для відпочинку та безкоштовно годували
                постраждалих. Було зібрано{' '}
                <b>
                  3 200 млн грн на будинок для сім’ї, яка виховує дітей-сиріт.
                </b>{' '}
                Саперам ДСНС і тваринам, які постраждали під час війни, було
                передано кошти від продажу Travel Box від Патрона. Ліцею №12
                імені Заріфи Алієвої в Ірпені було утеплено будівлю. Поліклініці
                в Ірпені було проведено ремонт фасаду та даху. Українцям, які
                постраждали від війни, було зібрано кошти на високофункціональні
                протези. Дітям, які втратили батьків на війні, було передано
                подарунки на День Святого Миколая. Компанія продовжує надавати
                гуманітарну допомогу жителям України, постраждалим від війни.
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item20} alt="" />
            <div className="card__text">
              <b>Акордбанк у 2023 році передав Збройним Силам України:</b> 4
              автомобілі (2 нових і 2 б/в); 12 комплектів гуми; 5 ремонтів
              автомобілів; 189 ремонтів дронів; 275 дронів-камікадзе; 14 нових
              дронів Mavic 3; 8 нових дронів Mavic 3T; 8 рацій; 7 пультів та
              антен для дронів; 14 ПНБ та тепловізорів; 8 Starlink; 16
              генераторів/зарядні станції; 40 комплектів спорядження до зброї;
              18 розкладних ліжок; 19 планшетів та ноутбуків. Окрім того
              Акордбанк, перерахував 7-значні суми на програму морських дронів
              від UNITED24, та на Армію дронів до Благодійних фондів Сергія
              Притули та Повернись живим. Разом до ПЕРЕМОГИ!
            </div>
          </div>
          <div className="card">
            <img src={item21} alt="" />
            <div className="card__text">
              <p>
                У 2023 році компанія Union Group реалізувала ряд благодійних
                проектів, спрямованих на допомогу Україні в умовах війни.
              </p>
              <p>
                <b>Компанія надала гуманітарну допомогу</b> постраждалим від
                війни, допомогу Збройним Силам України та допомогу дітям, які
                постраждали від війни.
              </p>
              <p>
                <b>Найбільш значущими проектами були:</b>
              </p>
              <p>
                <b>Закупівля гуманітарної допомоги</b> для постраждалих від
                ракетного обстрілу Бабиного Яру в Києві.
              </p>
              <p>
                <b>Долучилися до збору коштів UNITED24</b> «Допоможемо
                рятувальникам» на порятунок Херсонщини для подолання наслідків
                підриву Каховської ГЕС.{' '}
              </p>
              <p>
                <b>Разом з благодійним фондом "Дике поле"</b> зібрали
                найнеобхідніші речі та продукти для допомоги жителям
                постраждалих районів півдня.
              </p>
              <p>
                <b>Дарування золотих обручок</b> на весілля Захисниць.
              </p>
              <p>
                <b>Закупівля спеціалізованих реанімобілів</b> для Збройних Сил
                України.
              </p>
              <p>
                Допомога Сумському <b>дитячому будинку.</b>
              </p>
              <p>
                <b>Надання подарункових</b> боксів Захисниць.
              </p>
              <p>
                <b>Створення унікальної золотої прикраси "Нептун"</b> для
                підтримки Збройних Сил України.
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item22} alt="" />
            <div className="card__text">
              <p>
                Протягом усього 2023 року{' '}
                <b>АЛЛО надавала військовим знижки до 30% на весь асортимент</b>
                , а також регулярно надавала техніку та інші товари за запитом.
                Загалом, за весь час війни компанія надала військовим товарів та
                знижок на суму <b>понад 95 мільйонів гривень</b>, з яких тільки
                впродовж 2023 року – на суму понад 57 мільйонів гривень.
              </p>
              <p>
                На початку року компанія <b>АЛЛО придбала медичне обладнання</b>{' '}
                для лікарні у Краматорську на 500 тисяч гривень. У лютому
                компанія подвоїла внески слухачів благодійного радіомарафону для
                допомоги дітям з онкозахворюваннями, придбала позашляховик для
                спецпідрозділу ЗСУ та підтримала у Трускавці захід «Рік
                Незламності».
              </p>
              <p>
                У червні-вересні{' '}
                <b>
                  компанія провела благодійну акцію «Маленькі кроки до великого
                  руху»
                </b>
                , в рамках якої з кожного проданого смартфона переказувалося 100
                грн на протезування військових та цивільних. Зібрано та передано
                15 мільйонів гривень.
              </p>
              <p>
                Також компанія{' '}
                <b>
                  АЛЛО надала 3 мільйони гривень на купівлю для Сил Оборони
                  гусеничного медико-евакуаційного бронетранспортера.
                </b>{' '}
                У грудні компанія перерахувала 10% з кожної купівлі у застосунку
                АЛЛО товарів для дітей на 1000 планшетів для дітей ВПО.
              </p>
            </div>
          </div>
          <div className="card">
            <img src={item23} alt="" />
            <div className="card__text">
              <p>
                Компанія{' '}
                <b>
                  ASBISc Enterprises PLC передала 5 000 Apple iPad для 20 шкіл
                </b>
                , що постраждали від бойових дій в різних областях України.
                Проєкт дозволяє школярам отримати доступ до якісної освіти за
                допомогою спеціально встановлених навчальних програм.
              </p>
              <p>
                Також{' '}
                <b>
                  компанія забезпечила навчання вчителів від тренерів Apple для
                  успішної інтеграції технологій у навчальний процес.
                </b>{' '}
                Реалізація проєкту стала можливою завдяки співпраці державного,
                громадського сектору та бізнесу.
              </p>
              <p>
                Крім того,{' '}
                <b>
                  ASBIS Enterprises PLC активно співпрацює з волонтерськими та
                  громадськими організаціями,
                </b>{' '}
                надаючи гуманітарну допомогу та медичне обладнання для підтримки
                українських захисників.
              </p>
            </div>
          </div>
          <div className="bottomImg">
            <img src={width > 800 ? prefooterDesc : prefooterMob} alt="" />
          </div>
        </div>
      </section>
    </YearGoodDeedsStyles>
  );
};
