import styled from 'styled-components';

export const VacancyStyles = styled.div`
  font-family: 'Montserrat';
  position: relative;
  overflow: hidden;

  .container {
    max-width: 1190px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .vacancy {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 100px 0;
    position: relative;
    gap: 50px;
    box-sizing: border-box;

    @media (max-width: 768px) {
      padding: 50px 10px;
      gap: 25px;
    }

    &::before {
      position: absolute;
      content: '';
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background: #fff;
      top: 100px;
      right: 0;

      @media (max-width: 768px) {
        top: 50px;
        right: 50px;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 25px;

      @media (max-width: 768px) {
        gap: 15px;
      }

      h1 {
        margin: 0;
        font-weight: 600;
        font-size: 60px;
        line-height: 110%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 36px;
        }
      }
    }

    &__utils {
      display: flex;
      align-items: flex-end;
      gap: 25px;
      margin-top: 25px;

      @media (max-width: 768px) {
        margin-top: 15px;
        gap: 10px;
      }

      p {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 125%;
        color: rgba(255, 255, 255, 0.6);

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 50px;
      max-width: 870px;
      width: 100%;
      align-self: flex-end;
      box-sizing: border-box;

      @media (max-width: 768px) {
        gap: 25px;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 15px;

      h6 {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #ffffff;
        width: 25%;
        flex-shrink: 0;
        width: 100%;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      ul {
        margin: 0;
        width: 100%;
        padding-left: 25px;
        box-sizing: border-box;

        li {
          margin: 0;
          font-weight: 400;
          font-size: 18px;
          line-height: 120%;
          color: #ffffff;
          list-style-type: disc;
          max-width: 780px;

          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
