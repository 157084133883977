import styled from 'styled-components';

export const CxDiscoverStyles = styled.div`
  padding: 100px 0;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  & .container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  h3 {
    max-width: 770px;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 115%;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  h5 {
    font-weight: 500;
    font-size: 28px;
    line-height: 115%;
    color: rgb(2, 186, 149);

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .discover-wrapper {
    position: relative;
    width: 100%;
    height: 800px;

    @media (max-width: 768px) {
      height: 600px;
    }

    .discover-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: 700;
      font-size: 80px;
      line-height: 115%;
      z-index: 10;

      @media (max-width: 768px) {
        font-size: 40px;
      }
    }

    .discover-fill-5 {
      position: absolute;
      max-width: 200px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 20%;
      height: 15%;
      border: 2px solid rgb(2, 186, 149);
      border-radius: 30px;
      z-index: 9;
    }
    .discover-fill-4 {
      max-width: 400px;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 40%;
      height: 30%;
      transform: translate(-50%, -50%);
      border: 2px solid rgb(2, 186, 149);
      border-radius: 30px;
      z-index: 8;
      background: linear-gradient(
        rgba(2, 186, 149, 0.2),
        rgba(0, 255, 163, 0.1)
      );
    }
    .discover-fill-3 {
      max-width: 600px;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 60%;
      height: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid rgb(2, 186, 149);
      border-radius: 30px;
      z-index: 7;
      background: linear-gradient(
        rgba(2, 186, 149, 0.2),
        rgba(0, 255, 163, 0.05)
      );
    }
    .discover-fill-2 {
      max-width: 800px;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 80%;
      height: 67%;
      transform: translate(-50%, -50%);
      border: 2px solid rgb(2, 186, 149);
      border-radius: 30px;
      z-index: 6;
      background: linear-gradient(
        rgba(2, 186, 149, 0.2),
        rgba(0, 255, 163, 0.05)
      );
    }
    .discover-fill-1 {
      max-width: 1000px;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 85%;
      transform: translate(-50%, -50%);
      border: 2px solid rgb(2, 186, 149);
      border-radius: 30px;
      z-index: 5;
      background: transparent;
    }

    .discover-item {
      position: absolute;
      z-index: 11;
      border-radius: 30px;
      border: 2px solid #02ba95;
      max-width: 240px;
      width: fit-content;
      background: #000;
      padding: 24px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      font-size: 20px;
      line-height: 115%;
      transition: all 0.3s ease-in-out;

      @media (max-width: 768px) {
        font-size: 14px;
        padding: 15px;
        max-width: 160px;
      }

      &-one {
        width: 100%;
        right: 0;
        top: 5%;
      }
      &-two {
        left: 45%;
        transform: translate(-50%);
        top: 10%;
        max-width: 180px;
      }
      &-three {
        left: 0;
        top: 25%;
        max-width: 180px;
      }
      &-four {
        right: 15%;
        top: 25%;
      }
      &-five {
        left: 45%;
        transform: translate(-50%);
        top: 28%;
      }
      &-six {
        right: 23%;
        top: 40%;
      }
      &-seven {
        left: 18%;
        transform: translate(-50%);
        top: 44%;
      }
      &-eight {
        right: 0;
        top: 50%;
        max-width: 180px;
      }
      &-nine {
        left: 28%;
        transform: translate(-50%);
        top: 57%;
        max-width: 200px;
      }
      &-ten {
        left: 65%;
        transform: translate(-50%);
        top: 70%;
      }
      &-eleven {
        left: 40%;
        transform: translate(-50%);
        top: 79%;
      }
      &-twelve {
        left: 0;
        bottom: 5%;
      }
      &-thirteen {
        left: 70%;
        transform: translate(-50%);
        bottom: 2%;
      }
    }
  }
`;
