import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesIntroStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const OpportunitiesIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesIntroStyles>
      <div className="container">
        <h1 className="top">
          {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_2')}
        </h1>
        <h1 className="middle">
          {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_3')}
        </h1>
        <h1 className="bottom">
          {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_4')}
        </h1>
        <h2>
          {getTranslationsByLangOrEng(interfaceLanguage, 'opportunities_5')}
        </h2>
      </div>
    </OpportunitiesIntroStyles>
  );
};
