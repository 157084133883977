import styled from 'styled-components';

export const CxMethodologyStyles = styled.div`
  padding: 100px 0;
  /* display: flex;
    flex-direction: column;
    gap: 60px; */

  @media (max-width: 768px) {
    padding: 50px 0;
    gap: 30px;
  }

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  h3 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 115%;
  }

  .sliderWrapper {
    margin-top: 50px;
  }

  .slide {
    max-width: 770px;
    height: 550px;
    width: 100%;
    border-radius: 10px 10px;
    border: 4px solid #02ba95;
    position: relative;
    overflow: hidden;
    background: #fff;
    flex-shrink: 0;

    @media (max-width: 768px) {
      max-width: 320px;
      height: 250px;
    }

    &-img {
      height: 50%;
      position: relative;

      @media (max-width: 768px) {
        height: 30%;
      }

      &::before {
        position: absolute;
        content: '';
        z-index: 2;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          100deg,
          rgba(2, 186, 149, 0.7) 0%,
          rgba(2, 186, 149, 0) 90.42%
        );
      }

      img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      padding: 33px 45px;
      gap: 20px;
      height: 50%;

      @media (max-width: 768px) {
        padding: 10px 15px;
        gap: 10px;
      }

      h5 {
        color: #000;
        font-size: 40px;
        font-weight: 700;
        line-height: 115%;

        @media (max-width: 768px) {
          font-size: 22px;
        }
      }

      p {
        color: #000;
        font-size: 28px;
        font-weight: 500;
        line-height: 115%;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
`;
