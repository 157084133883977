import React, { FC } from 'react';

type TCloseIcon = {
  width?: number
  height?: number
  color?: string
}

export const CloseIcon: FC<TCloseIcon> = ({ width = 27, height = 27, color = '#fff' }) => (
  <svg width={width} height={height} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 25L24.6855 2.31443" stroke={color} strokeWidth="3" strokeLinecap="round" />
    <path d="M2 2L24.6855 24.6856" stroke={color} strokeWidth="3" strokeLinecap="round" />
    <path d="M2.31451 2.31439L25 25" stroke={color} strokeWidth="3" strokeLinecap="round" />
  </svg>
);
