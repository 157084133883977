import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { SociologicalResearchControlStyles } from './styles';

export const SociologicalResearchControl = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchControlStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_45')}</h1>
        </div>
        <div className="cards">
          <div className="cards__item">
            <h1>01</h1>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_46')}</p>
          </div>
          <div className="cards__item">
            <h1>02</h1>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_47')}</p>
          </div>
          <div className="cards__item">
            <h1>03</h1>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_48')}</p>
          </div>
        </div>
      </div>
    </SociologicalResearchControlStyles>
  );
};
