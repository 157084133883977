import styled from 'styled-components';

export const BoardingStyles = styled.div`
  font-family: 'Montserrat';
  position: relative;
  overflow: hidden;
  background: #000;

  .container {
    max-width: 1190px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;
  }

  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0 150px;
    gap: 50px;

    @media (max-width: 768px) {
      padding: 60px 0 50px;
      gap: 25px;
    }
  }

  h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 80px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 45px;
    }
  }

  h2 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;

    @media (max-width: 768px) {
      font-size: 26px;
    }
  }

  .cards {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 25px;
    margin-bottom: 50px;

    @media (max-width: 991px) {
      justify-content: center;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: 991px) {
      width: 100%;
    }

    &-img {
      height: 230px;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 400px;

        @media (max-width: 768px) {
          max-width: 290px;
        }
      }
    }

    &-text {
      width: calc(100% - 50px);
      border-radius: 0px 0px 10px 10px;
      border-left: 1px solid #0000ff;
      border-right: 1px solid #0000ff;
      border-bottom: 1px solid #0000ff;
      padding: 25px;
      display: flex;
      flex-direction: column;
      gap: 35px;
      min-height: 190px;

      h3 {
        font-weight: 700;
        font-size: 27px;
        line-height: 115%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 22px;
        }
      }

      p {
        font-weight: 400;
        font-size: 21px;
        line-height: 120%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
`;
