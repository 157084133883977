import principles1 from '../../../assets/images/sociologicalResearch/sociological-1.png';
import principles2 from '../../../assets/images/sociologicalResearch/sociological-2.png';
import principles3 from '../../../assets/images/sociologicalResearch/sociological-3.png';
import principles4 from '../../../assets/images/sociologicalResearch/sociological-4.png';
import principles5 from '../../../assets/images/sociologicalResearch/sociological-5.png';
import principles6 from '../../../assets/images/sociologicalResearch/sociological-6.png';
import principles7 from '../../../assets/images/sociologicalResearch/sociological-7.png';
import principles8 from '../../../assets/images/sociologicalResearch/sociological-8.png';
import principles9 from '../../../assets/images/sociologicalResearch/sociological-9.png';
import principles10 from '../../../assets/images/sociologicalResearch/sociological-10.png';
import principles11 from '../../../assets/images/sociologicalResearch/sociological-11.png';
import principles12 from '../../../assets/images/sociologicalResearch/sociological-12.png';
import principles13 from '../../../assets/images/sociologicalResearch/sociological-13.png';
import principles14 from '../../../assets/images/sociologicalResearch/sociological-14.png';
import principles15 from '../../../assets/images/sociologicalResearch/sociological-15.png';
import principles16 from '../../../assets/images/sociologicalResearch/sociological-16.png';
import principles17 from '../../../assets/images/sociologicalResearch/sociological-17.png';
import principles18 from '../../../assets/images/sociologicalResearch/sociological-18.png';
import principles19 from '../../../assets/images/sociologicalResearch/sociological-19.png';
import principles20 from '../../../assets/images/sociologicalResearch/sociological-20.png';
import principles21 from '../../../assets/images/sociologicalResearch/sociological-21.png';
import principles22 from '../../../assets/images/sociologicalResearch/sociological-22.png';
import principles23 from '../../../assets/images/sociologicalResearch/sociological-23.png';
import principles24 from '../../../assets/images/sociologicalResearch/sociological-24.png';
import principles25 from '../../../assets/images/sociologicalResearch/sociological-25.png';
import principles26 from '../../../assets/images/sociologicalResearch/sociological-26.png';
import principles27 from '../../../assets/images/sociologicalResearch/sociological-27.png';
import principles28 from '../../../assets/images/sociologicalResearch/sociological-28.png';
import principles29 from '../../../assets/images/sociologicalResearch/sociological-29.png';
import principles30 from '../../../assets/images/sociologicalResearch/sociological-30.png';
import principles31 from '../../../assets/images/sociologicalResearch/sociological-31.png';
import principles32 from '../../../assets/images/sociologicalResearch/sociological-32.png';

export const sliderData = (
  lang: string,
): { link: string; img: string }[] => [
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2023/05/Vyklyk1.pdf',
    img: principles1,
  },
  {
    link: 'https://www.fg.gov.ua/storage/files/zvit-rezultati-doslidzhennya-2021.pdf',
    img: principles2,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2023/02/Qualitative_Report_-MedicWice.pdf',
    img: principles3,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/12/Novor%D1%96chn%D1%96-svyata_ukra%D1%97nsk%D1%96-tradits%D1%96%D1%97_4Service.pdf',
    img: principles4,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/11/Black-Friday_2022-1.pdf',
    img: principles5,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/11/Report_4Service_WH_kz-1.pdf',
    img: principles6,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/11/1.pdf',
    img: principles7,
  },
  {
    link: 'https://docs.google.com/document/d/1l1VlE-HrJZSA7IuY1HvNqIOv85Ud3y3SZhAp2pX4mlA/edit',
    img: principles8,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Ukrajnsk%D1%96-b%D1%96zhenc%D1%96-v-Evrop%D1%96-2022_1-hvilja.pdf',
    img: principles9,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Molod_sport.pdf',
    img: principles10,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Naftogaz.pdf',
    img: principles11,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Molod_Ukraine.pdf',
    img: principles12,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/MedicWise.pdf',
    img: principles13,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Centr_gromadskogo_zdorovja.pdf',
    img: principles14,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Nacionalna_Policija.pdf',
    img: principles15,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Nastroi_naselennia_Covid_Mentalhealth-UA.pdf',
    img: principles16,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Biznes_v_pandemiu_B2B-B2C.pdf',
    img: principles17,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Jk_zminuvsij_runok_praci.pdf',
    img: principles18,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Black-Friday_2020.pdf',
    img: principles19,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Black-Friday_2020_1.pdf',
    img: principles20,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Happy_work_MentalHealth_full.pdf',
    img: principles21,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Prazdnyut_li_Den_Valentina.pdf',
    img: principles22,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Vera_v_Deda_Moroza.pdf',
    img: principles23,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/%D0%9D%D0%90%D0%9C%D0%95%D0%A0%D0%95%D0%9D%D0%98%D0%95-%D0%A1%D0%9E%D0%92%D0%95%D0%A0%D0%A8%D0%90%D0%A2%D0%AC-%D0%9F%D0%9E%D0%9A%D0%A3%D0%9F%D0%9A%D0%98-%D0%92-%D0%A7%D0%95%D0%A0%D0%9D%D0%A3%D0%AE-%D0%9F%D0%AF%D0%A2%D0%9D%D0%98%D0%A6%D0%A3.pdf',
    img: principles24,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/%D0%9F%D0%9E%D0%9A%D0%A3%D0%9F%D0%9A%D0%98-%D0%92-%D0%A7%D0%95%D0%A0%D0%9D%D0%A3%D0%AE-%D0%9F%D0%AF%D0%A2%D0%9D%D0%98%D0%A6%D0%A3-1.pdf',
    img: principles25,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Prazdnyut_li_Den_Valentina.pdf',
    img: principles26,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/%D0%A7%D1%82%D0%BE-%D0%B6%D0%B4%D1%83%D1%82-%D0%BD%D0%B0-8-%D0%BC%D0%B0%D1%80%D1%82%D0%B0.pdf',
    img: principles27,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Vakcinacija_KZ_2021.pdf',
    img: principles28,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Covid_KZ.pdf',
    img: principles29,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Indeks_happy_KZ.pdf',
    img: principles30,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2022/05/Prazdnyut_li_Den_Valentina_v_KZ.pdf',
    img: principles31,
  },
  {
    link: 'https://4service.group/ukr/wp-content/uploads/2023/05/Vyklyk2.pdf',
    img: principles32,
  },
];
