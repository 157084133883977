import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignHeadStyles } from './styles';
import head from '../../../assets/images/serviceDesign/head.png';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const ServiceDesignHead = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ServiceDesignHeadStyles>
      <div className="container">
        <div className="section">
          <img src={head} alt="Head" />
          <div className="content">
            <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_5')}</h2>
            <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_6')}</h3>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_7')}</p>
            <ButtonWithArrowOnHover
              onClick={() => {
                window.open('https://library.4service.group');
              }}
              arrowColor="#fff"
              textColor="#000"
              borderColor="#000"
              borderOnHover="#000"
              background="transparent"
              className="btn"
              hoverBackground="#000"
              hoverTextColor="#fff"
              text={getTranslationsByLangOrEng(
                interfaceLanguage,
                'service_design_81',
              )}
            />
          </div>
        </div>
      </div>
    </ServiceDesignHeadStyles>
  );
};
