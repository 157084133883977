import React, { FC } from 'react';

export const Geo: FC<{
  color?: string;
  height?: number;
  width?: number;
}> = ({ color, height, width }) => (
  <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1912 27.6961C10.385 27.8633 10.6252 27.9469 10.8654 27.9469C11.1047 27.9469 11.3441 27.8639 11.5375 27.6979C11.955 27.3392 21.7686 18.8177 21.7686 10.5732C21.7686 3.63183 16.2932 0 10.8843 0C5.47542 0 0 3.63183 0 10.5732C0 18.803 9.77496 27.3368 10.1912 27.6961ZM10.8843 2.06314C15.1327 2.06314 19.7054 4.72629 19.7054 10.5732C19.7054 16.7115 13.0389 23.4775 10.8674 25.5185C8.70267 23.4737 2.06314 16.701 2.06314 10.5732C2.06314 4.72629 6.63593 2.06314 10.8843 2.06314Z" fill="white" fillOpacity="0.6" />
    <path d="M15.2219 10.5663C15.2219 8.17492 13.2765 6.22925 10.8849 6.22925C8.49335 6.22925 6.54785 8.17492 6.54785 10.5663C6.54785 12.9576 8.49335 14.9033 10.8849 14.9033C13.2765 14.9033 15.2219 12.9576 15.2219 10.5663ZM8.61099 10.5663C8.61099 9.31245 9.63089 8.29239 10.8849 8.29239C12.1389 8.29239 13.1588 9.31245 13.1588 10.5663C13.1588 11.8201 12.1389 12.8402 10.8849 12.8402C9.63089 12.8402 8.61099 11.8201 8.61099 10.5663Z" fill="white" fillOpacity="0.6" />
  </svg>
);
