import styled from 'styled-components';

export const Experts4OpinionStyles = styled.div`
  background: #fff;
  width: 100%;
  padding-bottom: 40px;

  .container {
    display: block;
    gap: 50px;
    padding: 70px 10px;
    z-index: 5;

    @media (max-width: 768px) {
      gap: 30px;
      padding: 30px 10px;
    }
  }

  .swiper {
    overflow: visible;
  }

  h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 125%;
    text-align: center;
    color: #0f0e0c;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    height: auto;
    width: 670px;
    box-sizing: border-box;
    text-align: center;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .card {
    background: linear-gradient(
      52.5deg,
      rgba(0, 0, 255, 0.06) 0%,
      rgba(0, 0, 255, 0) 102.74%
    );
    border-radius: 10px;
    max-width: 670px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      width: 100%;
    }

    &::before {
      box-sizing: border-box;
      position: absolute;
      content: '';
      background: linear-gradient(
        52.5deg,
        rgba(0, 0, 255, 0.8) 0%,
        rgba(0, 0, 255, 0) 102.74%
      );
      border-radius: 10px;
      width: calc(100% + 4px);
      height: calc(100% + 3px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &-body {
      box-sizing: border-box;
      border-radius: 10px;
      padding: 20px 35px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      position: relative;
      gap: 30px;
      flex: 1;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      &::before {
        box-sizing: border-box;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: linear-gradient(
          52.5deg,
          rgba(0, 0, 255, 0.06) 0%,
          rgba(0, 0, 255, 0) 102.74%
        );
        border-radius: 10px;
        left: 0;
        top: 0;
      }
    }

    &-user {
      display: flex;
      align-items: flex-end;
      width: 100%;
      gap: 20px;

      @media (max-width: 768px) {
        gap: 15px;

        svg {
          max-width: 20px;
        }
      }

      h6 {
        font-weight: 700;
        font-size: 24px;
        line-height: 115%;
        color: #000000;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    &-img {
      position: relative;

      @media (max-width: 768px) {
          max-width: 200px;
        }

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(
          25.01deg,
          #0000ff -8.71%,
          rgba(115, 115, 115, 0) 100.94%
        );
        border-radius: 10px;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 15px;

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 115%;
        color: #000000;
        text-align: start;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
