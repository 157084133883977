import styled from 'styled-components';

export const UsageAttitudeSolutionsStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .cards {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'first second second'
      'third third fourth';

    max-width: 1190px;
    width: calc(100% - 10px);
    margin: 50px auto 0;
    gap: 30px;
    padding: 0 5px;

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 18px;

      p {
        font-size: 20px;

        @media (max-width: 1200px) {
          font-size: 18px;
        }
      }
    }

    &-item {
      display: flex;
      padding: 30px;
      gap: 18px;
      height: calc(330px - 60px);
      border-radius: 5px;
      border: 1px solid #5498ff;

      @media (max-width: 1200px) {
        padding: 20px;
      }

      @media (max-width: 768px) {
        align-items: center;
        flex-direction: column;
        height: fit-content;
      }

      img {
        max-width: 370px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &:nth-child(1) {
        grid-area: first;
      }
      &:nth-child(2) {
        grid-area: second;
      }
      &:nth-child(3) {
        grid-area: third;
      }
      &:nth-child(4) {
        grid-area: fourth;
      }
    }
  }
`;
