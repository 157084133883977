import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { SociologicalResearchAboutStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import partner1 from '../../../assets/images/sociologicalResearch/about-partner-1.png';
import partner2 from '../../../assets/images/sociologicalResearch/about-partner-2.png';
import partner3 from '../../../assets/images/sociologicalResearch/about-partner-3.png';
import partner4 from '../../../assets/images/sociologicalResearch/about-partner-4.png';

export const SociologicalResearchAbout = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const partners: {img: string; url: string}[] = [
    {
      img: partner1,
      url: 'https://www.fg.gov.ua/storage/files/zvit-rezultati-doslidzhennya-2021.pdf',
    },
    {
      img: partner2,
      url: 'https://4service.group/ukr/wp-content/uploads/2022/05/Centr_gromadskogo_zdorovja.pdf',
    },
    {
      img: partner3,
      url: 'https://4service.group/ukr/wp-content/uploads/2022/05/Ukrajnsk%D1%96-b%D1%96zhenc%D1%96-v-Evrop%D1%96-2022_1-hvilja.pdf',
    },
    {
      img: partner4,
      url: 'https://eef.org.ua/wp-content/uploads/2023/10/Full-report_Study-of-the-Ukrainian-Civil-Society-Sector-in-a-Time-of-War_UKR-version.pdf',
    },
  ];

  return (
    <SociologicalResearchAboutStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_17')}</h1>
        <div className="content">
          <div className="partners">
            {partners.map((el) => (
              <a href={el.url}>
                <img src={el.img} alt="" />
              </a>
            ))}
          </div>
          <div className="cards">
            <div className="cards-item">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_18')}</p>
            </div>
            <div className="cards-item">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_19')}</p>
            </div>
            <div className="cards-item">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_20')}</p>
            </div>
            <div className="cards-item">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_21')}</p>
            </div>
            <div className="cards-item">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_22')}</p>
            </div>
            <div className="cards-item">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_23')}</p>
            </div>
            <div className="cards-item">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_24')}</p>
            </div>
          </div>
        </div>
      </div>
    </SociologicalResearchAboutStyles>
  );
};
