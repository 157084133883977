import React from 'react';
import { BrandIdentityOpportunitiesStyles } from './styles';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const BrandIdentityOpportunities = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BrandIdentityOpportunitiesStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_27')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_10')}
          </h2>
          <div className="opportunities">
            <div className="opportunities__list">
              <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_14')}</h3>
              <ul>
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_16',
                    ),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_17',
                    ),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_18',
                    ),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_19',
                    ),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_20',
                    ),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_21',
                    ),
                  }}
                />
              </ul>
            </div>
            <div className="opportunities__list">
              <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'brands_identity_15')}</h3>
              <ul>
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_22',
                    ),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_23',
                    ),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'brands_identity_24',
                    ),
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BrandIdentityOpportunitiesStyles>
  );
};
