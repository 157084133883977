import React, { FC } from 'react';

export const SmallStar: FC<{ color: string }> = ({ color }) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.74765L21.5899 15.0928L22.0266 16.5H23.5H32L24.8 21.9L23.6719 22.7461L24.0899 24.0928L26.6931 32.481L20.2326 27.425L19 26.4603L17.7674 27.425L11.3069 32.481L13.9101 24.0928L14.3281 22.7461L13.2 21.9L6 16.5H14.5H15.9734L16.4101 15.0928L19 6.74765Z"
      stroke="#02BA95"
      strokeWidth="4"
    />
  </svg>
);
