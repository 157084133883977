import partner1 from '../../../assets/images/sociologicalResearch/partner-1.png';
import partner2 from '../../../assets/images/sociologicalResearch/partner-2.png';
import partner3 from '../../../assets/images/sociologicalResearch/partner-3.png';
import partner4 from '../../../assets/images/sociologicalResearch/partner-4.png';
import partner5 from '../../../assets/images/sociologicalResearch/partner-5.png';
import partner6 from '../../../assets/images/sociologicalResearch/partner-6.png';
import partner7 from '../../../assets/images/sociologicalResearch/partner-7.png';
import partner8 from '../../../assets/images/sociologicalResearch/partner-8.png';
import partner9 from '../../../assets/images/sociologicalResearch/partner-9.png';
import partner10 from '../../../assets/images/sociologicalResearch/partner-10.png';
import partner11 from '../../../assets/images/sociologicalResearch/partner-11.png';
import partner12 from '../../../assets/images/sociologicalResearch/partner-12.png';
import partner14 from '../../../assets/images/sociologicalResearch/partner-14.png';
import partner15 from '../../../assets/images/sociologicalResearch/partner-15.png';
import partner16 from '../../../assets/images/sociologicalResearch/partner-16.png';
import partner17 from '../../../assets/images/sociologicalResearch/partner-17.png';
import partner18 from '../../../assets/images/sociologicalResearch/partner-18.png';
import partner19 from '../../../assets/images/sociologicalResearch/partner-19.png';
import partner20 from '../../../assets/images/sociologicalResearch/partner-20.png';
import partner21 from '../../../assets/images/sociologicalResearch/partner-21.png';
import partner22 from '../../../assets/images/sociologicalResearch/partner-22.png';
import partner23 from '../../../assets/images/sociologicalResearch/partner-23.png';

export const partnersGreenTheme: {
  static: string;
  alt: string;
}[] = [
  {
    static: partner1,
    alt: '',
  },
  {
    static: partner2,
    alt: '',
  },
  {
    static: partner3,
    alt: '',
  },
  {
    static: partner4,
    alt: '',
  },
  {
    static: partner5,
    alt: '',
  },
  {
    static: partner6,
    alt: '',
  },
  {
    static: partner7,
    alt: '',
  },
  {
    static: partner8,
    alt: '',
  },
  {
    static: partner9,
    alt: '',
  },
  {
    static: partner10,
    alt: '',
  },
  {
    static: partner11,
    alt: '',
  },
  {
    static: partner12,
    alt: '',
  },
  {
    static: partner14,
    alt: '',
  },
  {
    static: partner15,
    alt: '',
  },
  {
    static: partner16,
    alt: '',
  },
  {
    static: partner17,
    alt: '',
  },
  {
    static: partner18,
    alt: '',
  },
  {
    static: partner19,
    alt: '',
  },
  {
    static: partner20,
    alt: '',
  },
  {
    static: partner21,
    alt: '',
  },
  {
    static: partner22,
    alt: '',
  },
  {
    static: partner23,
    alt: '',
  },
];
