import styled from 'styled-components';
import bg from '../../../assets/images/adHoc/intro-bg.png';

export const AdHocIntroStyles = styled.div`
  width: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(${bg}) no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(102deg, rgba(252, 198, 102, 0.80) 2.31%, rgba(250, 26, 46, 0.60) 52.97%, rgba(92, 183, 241, 0.40) 103.64%);
    z-index: 2;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    padding: 140px 10px !important;
    z-index: 3;

    @media (max-width: 991px) {
      padding: 100px 10px !important;
    }

    @media (max-width: 768px) {
      gap: 25px;
      padding: 50px 10px !important;
    }

    h1 {
      color: #fff;
      text-align: center;
      font-size: 80px;
      font-weight: 700;
      line-height: 97%;

      @media (max-width: 991px) {
        font-size: 54px;
      }

      @media (max-width: 768px) {
        font-size: 34px;
      }
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 36px;
      font-weight: 400;

      @media (max-width: 991px) {
        font-size: 26px;
      }

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
`;
