import React from 'react';
import { useAppSelector } from '../../state/hooks';
import { AdHocStyles } from './styles';
import {
  AdHocApproach,
  AdHocBenefit,
  AdHocExperience,
  AdHocIntro,
  AdHocMethodology,
  AdHocSample,
  AdHocSlider,
} from '../../components';
import ProductsTabs from '../../components/productsTabs/ProductsTabs';
import { productsTabsData } from '../../constants';
import MetaFromApi from '../../components/metaFromApi/MetaFromApi';

export const AdHocMain = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <AdHocStyles>
      <MetaFromApi slug="adHoc" />
      <AdHocIntro />
      <AdHocMethodology />
      <AdHocExperience />
      <AdHocBenefit />
      <AdHocSlider />
      <AdHocSample />
      <AdHocApproach />
      <ProductsTabs
        data={productsTabsData(interfaceLanguage)}
        theme="darkTheme"
      />
    </AdHocStyles>
  );
};
