import styled from 'styled-components';

export const OpportunitiesProgramStyles = styled.div`
  padding: 100px 0;
  background: #000;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    align-self: flex-end;
    color: #ffffff;
    max-width: 970px;
    margin: 0 auto;

    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
    }

    span {
      color: #0000ff;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .content {
      display: flex;
      align-items: center;
      gap: 50px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      img {
        flex-shrink: 0;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 35px;
        width: 100%;

        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 125%;
          color: #ffffff;
          max-width: 370px;
        }

        .top {
        }
        .middle {
          align-self: center;
        }
        .bottom {
          align-self: flex-end;
        }
      }
    }
  }
`;
