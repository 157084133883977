import styled from 'styled-components';

export const BrandIdentityChartsStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .charts {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 100%;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 32px;
      width: 100%;

      @media (max-width: 768px) {
        flex-direction: column;
      }


      &:nth-child(2), &:nth-child(4) {
        h6, p {
          text-align: end;

          @media (max-width: 768px) {
            text-align: start;
          }
        }

        .charts-content {
          align-items: flex-end;

          @media (max-width: 768px) {
            align-items: flex-start;
          }
        }
      }

      img {
        max-width: 270px;
        flex-shrink: 0;

        @media (max-width: 768px) {
          order: 1;
          max-width: 200px;
        }
      }
    }

    &-legend {
      display: flex;
      align-items: center;
      gap: 15px;

      &-item {
        padding-left: 20px;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        position: relative;

        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background: #000;
        }

        &:nth-child(2) {
          &::before {
            background: #707070;
          }
        }

        &:nth-child(3) {
          &::before {
            background: linear-gradient(225deg, #FFBD14 0%, #DB9000 100%);
          }
        }
      }
    }

    &-stats {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 5px 25px;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      &-item {
        color: #0F0E0C;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;

        b {
          font-weight: 700;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 768px) {
        order: 2;
      }
      
      h6 {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 125%;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      p {
        color: #0F0E0C;
        font-size: 20px;
        font-weight: 400;
        line-height: 125%; 
        margin: 0;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
