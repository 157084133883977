import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const SociologicalResearchSampleStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .texts {
    width: 100%;
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .cards {
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: space-around;
    flex-wrap: wrap;

    &__item {
      max-width: 260px;
      width: 100%;
      border: 5px solid #f05a25;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        height: 130px;
      }

      &-text {
        height: 205px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
`;
