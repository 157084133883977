import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BannerCareerStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const BannerCareer = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <BannerCareerStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'career_1')}</h1>
        <h1 className="left">{getTranslationsByLangOrEng(interfaceLanguage, 'career_2')}</h1>
        <div className="description">
          <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'career_3')}</h3>
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('vacancies');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#fff"
            textColor="#fff"
            borderColor="#fff"
            borderOnHover="#fff"
            background="transparent"
            hoverBackground="transparent"
            hoverTextColor="#fff"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'career_4',
            )}
          />
        </div>
      </div>
    </BannerCareerStyles>
  );
};
