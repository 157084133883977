import React, { useEffect } from 'react';
import { CareerStyles } from './styles';
import {
  BannerCareer,
  CVBanner,
  HoldingCareer,
  NotFoundCareer,
  OpportunitiesCareer,
  VacanciesCareer,
} from '../../components';

export const Career = () => {
  useEffect(() => {}, []);

  return (
    <CareerStyles>
      <BannerCareer />
      <HoldingCareer />
      <OpportunitiesCareer />
      <VacanciesCareer />
      <NotFoundCareer />
    </CareerStyles>
  );
};
