import styled from 'styled-components';

export const BrandAwarenessUsageOpportunitiesStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .opportunities {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;

    &__list {
      width: calc(50% - 10px);
      display: flex;
      flex-direction: column;
      gap: 25px;

      h3 {
        font-weight: 600;
        font-size: 28px;
        line-height: 125%;
        color: #0F0E0C;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      ul {
        width: calc(100% - 10px);

        li {
          font-weight: 400;
          font-size: 20px;
          line-height: 125%;
          color: #0F0E0C;
          padding: 15px 15px 15px 25px;
          height: 100px;
          width: calc(100% - 50px);
          border-top: 1px solid #8E50CC;
          position: relative;

          @media (max-width: 768px) {
            font-size: 14px;
            padding: 10px 10px 10px 15px;
            height: 100px;
            width: calc(100% - 15px);
          }

          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #000;
            left: 10px;
            top: 23px;

            @media (max-width: 768px) {
              left: 5px;
            }
          }

          &:last-child {
            border-bottom: 1px solid #8E50CC;
          }

          b {
            font-weight: 600;
          }
        }
      }
    }
  }
`;
