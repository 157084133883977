import styled from 'styled-components';
import map from '../../../assets/images/opportunities/map.png';

export const OpportunitiesMapStyles = styled.div`
  padding: 450px 0 250px;
  background: url(${map}) no-repeat;
  background-position: center center;
  background-size: 100%;

  @media (max-width: 991px) {
    padding: 350px 0 180px;
  }
  @media (max-width: 768px) {
    padding: 200px 0 100px;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    align-self: flex-end;
    color: #000;
    max-width: 270px;

    @media (max-width: 991px) {
      font-size: 40px;
      margin-left: 80px;
    }
    @media (max-width: 768px) {
      font-size: 28px;
      max-width: 170px;
    }

    span {
      color: #0000ff;
    }
  }
`;
