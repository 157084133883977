import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { FacilityCheckAuditSolutionTwoStyles } from './FacilityCheckAuditSolutionTwoStyles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const FacilityCheckAuditSolutionTwo = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <FacilityCheckAuditSolutionTwoStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(
            interfaceLanguage,
            'facility_check_audit_13',
          )}
        </h5>

        <div className="content">
          <h4>
            {getTranslationsByLangOrEng(
              interfaceLanguage,
              'facility_check_audit_14',
            )}
          </h4>

          <div className="circlesContainer">
            <div className="circle">
              <span>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'facility_check_audit_16',
                )}
              </span>
            </div>
            <div className="circle">
              <span>
                {getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'facility_check_audit_17',
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </FacilityCheckAuditSolutionTwoStyles>
  );
});
