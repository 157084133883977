import styled from 'styled-components';

export const OpportunitiesBonusesStyles = styled.div`
  padding: 100px 0;
  background: #fff;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 115%;
    color: #0f0e0c;
    max-width: 500px;

    @media (max-width: 991px) {
      font-size: 40px;
      text-align: center;
    }

    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  .container {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    .bonuses-wrapper {
      flex-shrink: 0;
      position: relative;

      .bonuses-bg {
        @media (max-width: 768px) {
          max-width: 360px;
        }
      }

      .bonuses-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        max-width: 166px;
        position: absolute;

        @media (max-width: 768px) {
          max-width: 100px;
          gap: 5px;
        }

        img {
          @media (max-width: 768px) {
            max-width: 40px;
          }
        }

        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 115%;
          text-align: center;
          color: #000000;

          @media (max-width: 768px) {
            font-size: 11px;
          }
        }

        &:nth-child(2) {
          top: 100px;
          left: 150px;

          @media (max-width: 768px) {
            top: 40px;
            left: 75px;
          }
        }
        &:nth-child(3) {
          top: 100px;
          right: 150px;

          @media (max-width: 768px) {
            top: 40px;
            right: 75px;
          }
        }
        &:nth-child(4) {
          bottom: 100px;
          right: 150px;

          @media (max-width: 768px) {
            bottom: 40px;
            right: 75px;
          }
        }
        &:nth-child(5) {
          top: 270px;
          right: 50px;

          @media (max-width: 768px) {
            top: 130px;
            right: 20px;
          }
        }
        &:nth-child(6) {
          bottom: 100px;
          left: 150px;

          @media (max-width: 768px) {
            bottom: 40px;
            left: 75px;
          }
        }
        &:nth-child(7) {
          top: 270px;
          left: 50px;

          @media (max-width: 768px) {
            top: 130px;
            left: 20px;
          }
        }
      }
    }
  }
`;
