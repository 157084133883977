import styled from 'styled-components';

export const SociologicalResearchVideosliderStyles = styled.div`
  width: 100%;
  padding: 100px 0;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  .sliderWrapper {
    margin-top: 50px;
  }

  .texts {
    max-width: 770px;
    width: 100%;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .slide {
    position: relative;
    background: #fff;
    flex-shrink: 0;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      max-width: 320px;

      iframe {
        width: auto;
        height: auto;
      }
    }
  }

  .btn-wrapper {
    max-width: 1190px;
    margin: 50px auto 0;
  }
`;
