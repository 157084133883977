import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const SociologicalResearchAboutStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 55px;
  }

  h1 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    line-height: 115%;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 25px;
    }
  }

  .partners {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media (max-width: 768px) {
      width: 100%;
      align-items: center;
      order: 2;
    }

    a {
      img {
        transition: filter 0.5s ease;
      }

      &:hover {
        img {
          filter: grayscale(100%);
        }
      }
    }
  }

  .cards {
    width: 66%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      order: 1;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px;
      background: #ffffff;
      border-radius: 10px;
      border: 3px solid #f05a25;

      &:not(:last-child) {
        width: calc(50% - 15px);

        @media (max-width: 768px) {
          width: 100%;
        }
      }
      &:last-child {
        width: 100%;
      }

      p {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 115%;
      }
    }
  }
`;
