import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CxCraftingStyles } from './CxCraftingStyles';
import EmptyStar from '../../../assets/icons/EmptyStar';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CxCrafting = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CxCraftingStyles>
      <div className="container">
        <h3>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_53')}
        </h3>
        <div className="crafting-list">
          <div className="crafting-item">
            <EmptyStar />
            <div className="crafting-text">
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_54')}
            </div>
          </div>
          <div className="crafting-item">
            <EmptyStar />
            <div className="crafting-text">
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_55')}
            </div>
          </div>
          <div className="crafting-item">
            <EmptyStar />
            <div className="crafting-text">
              {getTranslationsByLangOrEng(interfaceLanguage, 'cx_solution_56')}
            </div>
          </div>
        </div>
      </div>
    </CxCraftingStyles>
  );
};
