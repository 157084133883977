import styled from 'styled-components';

export const UsageAttitudeSliderStyles = styled.div`
  width: 100%;
  margin-top: 100px !important;

  @media (max-width: 991px) {
    margin-top: 50px !important;
  }

  .sliderWrapper {
    margin-top: 50px;
  }

  .slide {
    max-width: 470px;
    height: 370px;
    width: 100%;
    border-radius: 10px 10px;
    border: 1px solid #5498ff;
    position: relative;
    overflow: hidden;
    background: #fff;
    flex-shrink: 0;

    @media (max-width: 768px) {
      max-width: 320px;
      height: 300px;
    }

    &-img {
      height: 25%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      padding: 30px;
      gap: 20px;
      height: 70%;

      @media (max-width: 768px) {
        padding: 10px 15px;
        gap: 10px;
      }

      h5 {
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        color: #5b91fa;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      p {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #000000;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .btn-wrapper {
    max-width: 1190px;
    margin: 50px auto 0;
  }
`;
