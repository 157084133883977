import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ConsumerJourneyMapSolutionStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import solution1 from '../../../assets/images/consumerJourneyMap/solution-1.png';
import solution2 from '../../../assets/images/consumerJourneyMap/solution-2.png';
import solution3 from '../../../assets/images/consumerJourneyMap/solution-3.png';
import solution4 from '../../../assets/images/consumerJourneyMap/solution-4.png';

export const ConsumerJourneyMapSolution = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ConsumerJourneyMapSolutionStyles>
      <div className="container">
        <h5> </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_17')}
          </h2>
        </div>
      </div>
      <div className="full-container">
        <div className="cards">
          <div className="cards-item">
            <img src={solution1} alt="solution" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_19')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_20')}</p>
          </div>
          <div className="cards-item">
            <img src={solution2} alt="solution" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_21')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_22')}</p>
          </div>
          <div className="cards-item">
            <img src={solution3} alt="solution" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_23')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_24')}</p>
          </div>
          <div className="cards-item">
            <img src={solution4} alt="solution" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_25')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'consumer_journey_map_26')}</p>
          </div>
        </div>
        <div className="btn-container">
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#fff"
            textColor="#000"
            borderColor="#FDA272"
            borderOnHover="#000"
            background="linear-gradient(180deg, #FDA272 0%, #F15967 100%)"
            hoverBackground="#000"
            hoverTextColor="#fff"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'consumer_journey_map_18',
            )}
          />
        </div>
      </div>
    </ConsumerJourneyMapSolutionStyles>
  );
};
