import styled from 'styled-components';

export const AdHocApproachStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .texts {
    max-width: 770px;
    align-self: flex-end;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .content {
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 50px;

    p {
      color: #FFF;
      font-size: 28px;
      font-weight: 400;
      line-height: 125%;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (max-width: 768px) {
      gap: 25px;
    }
  }
`;
