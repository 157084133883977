import styled from 'styled-components';

export const FacilityCheckAuditAboutStyles = styled.div`
  padding: 138px 0 50px 0;
  width: 100%;

  @media (max-width: 900px) {
    padding: 50px 0;
  }
`;
