import { Field, useField } from 'formik';
import React, { FC, useRef } from 'react';

type TCustomRadio = {
    name: string;
    value: string;
    onChange?: (e: React.ChangeEvent<any>) => void;
    label: string;
    selected: string;
  }

export const CustomRadio: FC<TCustomRadio> = ({
  name, value, onChange, label, selected,
}) => {
  const inputRef = useRef(null);
  return (
    <div className="radio">
      <label className="radio__wrapper" htmlFor={name}>
        {selected === value && <div className="radio__mark" />}
        <input
          className="radio__default"
          type="checkbox"
          id={name}
          checked={selected === value}
          ref={inputRef}
          onChange={onChange}
        />
      </label>
      <span>{label}</span>
    </div>
  );
};
