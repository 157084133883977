import styled from 'styled-components';
import banner from '../../../assets/images/career/banner.png';

export const BannerCareerStyles = styled.div`
  background: url(${banner}) no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(105.96deg, #0000FF 0%, rgba(0, 0, 255, 0.4) 18.24%, rgba(0, 0, 255, 0) 100%), rgba(0, 0, 0, 0.28);
  }

  .container {
    padding: 120px 10px 70px !important;
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;

    @media (max-width: 768px) {
        padding: 40px 10px 40px !important;
        gap: 30px;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 110px;
    line-height: 97%;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 800px;
    align-self: flex-start;

    @media (max-width: 768px) {
        font-size: 50px;
    }

    &.left {
      align-self: flex-end;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 97%;
    color: #ffffff;

    @media (max-width: 768px) {
        font-size: 24px;
    }
  }

  .description {
    max-width: 560px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 70px;

    @media (max-width: 768px) {
        gap: 50px;
    }
  }
`;
