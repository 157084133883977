import React, { useRef, useState } from 'react';
import { UploadFileButtonStyles } from './UploadFileButtonStyles';

interface UploadFileButtonProps {
  onChange?: (file: File | undefined) => void;
  formats: string;
  fieldName?: string;
  label: string;
  btnClass?: string;
  fileIndicator?: boolean;
}

export const UploadFileButton = ({
  onChange,
  formats,
  fieldName,
  label,
  btnClass,
  fileIndicator,
}: UploadFileButtonProps) => {
  const fileInput = useRef<any>(null);
  const [file, setFile] = useState<File | null>(null);

  const handleClick = () => {
    if (fileInput.current) {
      // @ts-ignore
      fileInput.current.click();
    }
  };

  return (
    <UploadFileButtonStyles>
      <input
        name={fieldName}
        autoComplete="off"
        ref={fileInput}
        type="file"
        accept={formats}
        onChange={(event) => {
          if (onChange && event.currentTarget.files) {
            onChange(event.currentTarget.files[0]);
            setFile(event.currentTarget.files[0]);
            event.currentTarget.value = '';
          } else if (onChange) {
            onChange(undefined);
            setFile(null);
          }
        }}
      />
      <button type="button" className={btnClass} onClick={handleClick}>
        {label}
      </button>

      {(fileIndicator && file) && (
        <div className="indicator">1</div>
      )}
    </UploadFileButtonStyles>
  );
};
