import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const SociologicalResearchLicenseStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    max-width: 770px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 25px;

    h1 {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: 115%;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 15px;

      li {
        padding-left: 35px;
        font-weight: 400;
        font-size: 28px;
        line-height: 125%;
        color: #000000;
        position: relative;

        @media (max-width: 768px) {
          font-size: 18px;
        }

        &::before {
          position: absolute;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: linear-gradient(180deg, #f05a25 0%, #faaf3b 100%);
          transform: translate(-50%);
          left: 15px;
          top: 10px;
        }
      }
    }
  }
`;
