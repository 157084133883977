import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import img from '../../../assets/images/brandAwarenessUsage/brands.png';
import { BrandAwarenessUsageBrandsStyles } from './styles';

export const BrandAwarenessUsageBrands = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <BrandAwarenessUsageBrandsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_9')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_39')}
          </h2>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_40')}</p>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_41')}</p>
          <img src={img} alt="attribute" className="attribute-img" />
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_42')}</p>
        </div>
      </div>
    </BrandAwarenessUsageBrandsStyles>
  );
};
