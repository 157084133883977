import React from 'react';

function EmptyStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
    >
      <path
        d="M38 3.37382L46.0449 29.2964L46.2633 30H47H73L51.4 46.2L50.836 46.623L51.0449 47.2964L59.0966 73.2405L38.6163 57.2125L38 56.7302L37.3837 57.2125L16.9034 73.2405L24.9551 47.2964L25.164 46.623L24.6 46.2L3 30H29H29.7367L29.9551 29.2964L38 3.37382Z"
        stroke="#02BA95"
        strokeWidth="2"
      />
    </svg>
  );
}

export default EmptyStar;
