import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { OpportunitiesProgramStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import program from '../../../assets/images/opportunities/program.png';

export const OpportunitiesProgram = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <OpportunitiesProgramStyles>
      <div className="container">
        <h1
          dangerouslySetInnerHTML={{
            __html: getTranslationsByLangOrEng(
              interfaceLanguage,
              'opportunities_22',
            ),
          }}
        />
        <div className="content">
          <img src={program} loading="lazy" alt="program" />
          <div className="text">
            <p className="top">
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_23',
              )}
            </p>
            <p className="middle">
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_24',
              )}
            </p>
            <p className="bottom">
              {getTranslationsByLangOrEng(
                interfaceLanguage,
                'opportunities_25',
              )}
            </p>
          </div>
        </div>
      </div>
    </OpportunitiesProgramStyles>
  );
};
