import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { SociologicalResearchPrinciplesStyles } from './styles';

export const SociologicalResearchField = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <SociologicalResearchPrinciplesStyles>
      <div className="container">
        <div className="texts">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_41')}</h1>
        </div>
        <div className="content">
          <div className="content-item">
            <h1>150,000+</h1>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_42')}</p>
          </div>
          <div className="content-item">
            <h1>450+</h1>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_43')}</p>
          </div>
          <div className="content-item">
            <h1>25</h1>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'sociological_research_44')}</p>
          </div>
        </div>
      </div>
    </SociologicalResearchPrinciplesStyles>
  );
};
